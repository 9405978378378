import type { ActionTree } from "vuex";
import * as Sentry from "@sentry/vue";
import type { SeverityLevel } from "@sentry/types";
import type { PdfMode } from "../signup-navigation/types";
import { UserMutation } from "../user/mutations";
import type {
  AuthorizedSignatory,
  CompanySignupState,
} from "./types";
import { CompanySignupMutation, getCompanyPrincipalInitialState } from "./mutations";
import type {
  BoardMember,
  CompanyPrincipal,
  FortnoxCodeResponse,
  Lead,
  RegisterCompanyResponse,
  RiskEvaluation,
  RoaringCompanySearchResponse,
  SigningPerson,
  StrategyFocus,
  TimeToWithdraw,
} from "@/clients";
import {
  ApplicationCompaniesClient,
  AuthorizedSignatory as AuthorizedSignatoryBackend,
  CompanyRiskEvaluationParameters,
  CreateCompanyAutogiroPdf,
  CreateFrontendStateRequest,
  CreateLead,
  FrontendStateClient,
  LeadClient,
  OpeningDocumentsInsuranceRequest,
  PdfClient,
  CompanyPrincipal as RequestCompanyPrincipal,
  RiskClient,
  RoaringClient,
  Role,
} from "@/clients";
import type { RootState } from "@/types";
import { baseUrl } from "@/clients/config";
import { downloadFileResponse } from "@/utils/downloader";
import {
  getPortfolioSettings,
  getRegisterCompanyParameters,
} from "@/mappers/register-company-parameters";
import getAlternativePrincipalRole from "@/utils/principal-role";

const companyClient = new ApplicationCompaniesClient(baseUrl);
const riskClient = new RiskClient(baseUrl);
const roaringClient = new RoaringClient(baseUrl);
const leadClient = new LeadClient(baseUrl);
const pdfClient = new PdfClient(baseUrl);
const stateClient = new FrontendStateClient(baseUrl);

export const CompanySignupAction = {
  registerCompany: "registerCompany",
  evaluateCompanyRisk: "evaluateCompanyRisk",
  getCompany: "getCompany",
  getPrincipals: "getPrincipals",
  getCompanyFinancialInfo: "getCompanyFinancialInfo",
  getSigningCombinations: "getSigningCombinations",
  getBoardMembers: "getBoardMembers",
  getPortfolioInvestmentStrategyCompanyPdfSignup: "getPortfolioInvestmentStrategyCompanyPdfSignup",
  getPortfolioManagementCompanyPdfSignup: "getPortfolioManagementCompanyPdfSignup",
  getAutogiroCompanyPdf: "getAutogiroCompanyPdf",
  getIdunKycPdf: "getIdunKycPdf",
  getInsuranceApplicationPdf: "getInsuranceApplicationPdf",
  createLead: "createLead",
  updateLeadWithOrgNumber: "updateLeadWithOrgNumber",
  persistCompanyStateToBackend: "persistCompanyStateToBackend",
  validateAffiliateCode: "validateAffiliateCode",
  getAlternativeCompanyPrincipal: "getAlternativeCompanyPrincipal",
  validateFortnoxCode: "validateFortnoxCode",
  getOpeningDocumentsInsurance: "getOpeningDocumentsInsurance",
};

export const actions: ActionTree<CompanySignupState, RootState> = {
  async [CompanySignupAction.evaluateCompanyRisk]({
    state,
    commit,
  }): Promise<RiskEvaluation | null> {
    const riskParameters = new CompanyRiskEvaluationParameters({
      chosenRiskWillingness: state.riskWillingness as number,
      strategyFocus: state.focus as StrategyFocus,
      liquidAssets: state.economy.liquidAssets as number,
      averageOperatingProfit: state.economy.averageOperatingProfitAmount as number,
      forecastResult: state.economy.forecastResultThisYear as number,
      timeToWithdraw: state.timeToWithdraw as TimeToWithdraw,
      isActiveCompany: !!state.isActive,
      liquidityPercentage: state.economy.liquidityPercentage,
      solidityPercentage: state.economy.solidityPercentage,
      hasShortTermDebt: state.economy.hasShortTermDebt,
    });
    const riskEvaluation = await riskClient.evaluateCompanyRisk(riskParameters);
    if (riskEvaluation) {
      commit(CompanySignupMutation.setCalculatedRiskLevel, riskEvaluation.riskLevel);
    }
    return riskEvaluation;
  },
  async [CompanySignupAction.registerCompany]({ state, rootState }): Promise<RegisterCompanyResponse | null> {
    const request = getRegisterCompanyParameters(state, rootState.signupStore);
    return companyClient.registerCompany(request);
  },
  async [CompanySignupAction.getCompany]({
    state,
    commit,
  }): Promise<RoaringCompanySearchResponse | null> {
    let result: RoaringCompanySearchResponse | null;
    try {
      result = await roaringClient.getCompany(state.orgNumber as string);
      if (result && result.records) {
        if (result.records.length > 1) {
          throw new Error("More than one record");
        }
        commit(CompanySignupMutation.setCompanyName, result.records[0].companyName);
        if (result.records[0].address) {
          commit(CompanySignupMutation.setCompanyAddress, result.records[0].address);
        } else {
          commit(CompanySignupMutation.setCompanyAddress, result.records[0].visitAddress);
        }
        commit(CompanySignupMutation.setCompanyZipCode, result.records[0].zipCode);
        commit(CompanySignupMutation.setCompanyCity, result.records[0].town);
      }
    } catch (error) {
      console.error(error);
      return null;
    }
    return result;
  },
  async [CompanySignupAction.getPrincipals]({ state, commit }): Promise<void> {
    try {
      const result = await roaringClient.getBeneficialOwners(state.orgNumber as string);

      if (result) {
        commit(
          CompanySignupMutation.setCompanyPrincipals,
          [...Array(result.length)].map((_: any) => getCompanyPrincipalInitialState()),
        );
        commit(CompanySignupMutation.setNumberOfPrincipals, result.length);
        result.forEach((x: CompanyPrincipal, i: number) => {
          commit(CompanySignupMutation.setPrincipalCitizenship1, {
            index: i,
            value: x.citizenship1,
          });
          commit(CompanySignupMutation.setPrincipalFirstName, {
            index: i,
            value: x.firstName,
          });
          commit(CompanySignupMutation.setPrincipalLastName, {
            index: i,
            value: x.lastName,
          });
          commit(CompanySignupMutation.setPrincipalCountry, {
            index: i,
            value: x.countryResidence,
          });
          commit(CompanySignupMutation.setPrincipalRegisteredSwedishResident, {
            index: i,
            value: x.registeredSwedishResident,
          });
          commit(CompanySignupMutation.setPrincipalPercentageShares, {
            index: i,
            value: x.percentageShares,
          });
          commit(CompanySignupMutation.setPrincipalPersonalNumber, {
            index: i,
            value: x.personalNumber,
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
  async [CompanySignupAction.getCompanyFinancialInfo]({ state, commit }): Promise<void> {
    try {
      const result = await roaringClient.getCompanyFinancialInfo(state.orgNumber as string);
      if (result) {
        commit(CompanySignupMutation.setRevenue, result.revenueSpan);
        commit(
          CompanySignupMutation.setAverageOperatingProfitAmount,
          result.averageOperatingProfit,
        );
        commit(CompanySignupMutation.setHasShortTermDebt, result.hasShortTermDebt);
        commit(CompanySignupMutation.setHasLongTermDebt, result.hasLongTermDebt);
        commit(CompanySignupMutation.setSolidityPercentage, result.solidityPercent?.toFixed(3));
        commit(CompanySignupMutation.setLiquidAssets, result.liquidAssets);
        commit(CompanySignupMutation.setLiquidityPercentage, result.liquidityPercent?.toFixed(3));
        commit(CompanySignupMutation.setEmployeeCount, result.employeeCountSpan);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async [CompanySignupAction.getSigningCombinations]({ state, commit }): Promise<boolean> {
    let success = false;
    try {
      const response = await roaringClient.getCompanySigningCombinations(state.orgNumber as string);
      if (response && response.persons && response.persons.length > 0) {
        commit(
          CompanySignupMutation.setAuthorizedSignatories,
          response.persons.map(
            (x: SigningPerson) =>
              <AuthorizedSignatory>{
                personalIdentificationNumber: x.personalIdentificationNumber,
                fullName: x.name,
                email: undefined,
                emailVerify: undefined,
                hasToSign: false,
                roles: x.roles,
              },
          ),
        );
        commit(CompanySignupMutation.setAllowedSigningCombinations, response.combinations);
        success = true;
      } else {
        success = false;
      }
    } catch (error) {
      console.error(error);
      success = false;
    }

    return success;
  },
  async [CompanySignupAction.getBoardMembers]({ state, commit }): Promise<void> {
    try {
      const result = await roaringClient.getBoardMembers(state.orgNumber as string);
      if (result && result.boardMembers) {
        commit(
          CompanySignupMutation.setAuthorizedSignatories,
          result.boardMembers.map(
            (x: BoardMember) =>
              <AuthorizedSignatory>{
                personalIdentificationNumber: x.personalIdentityNumber,
                fullName: x.fullName,
                email: undefined,
                emailVerify: undefined,
                hasToSign: false,
              },
          ),
        );
        commit(CompanySignupMutation.setAllowedSigningCombinations, undefined);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async [CompanySignupAction.getAlternativeCompanyPrincipal]({ state, commit }): Promise<void> {
    try {
      const result = await roaringClient.getBoardMembers(state.orgNumber as string);
      if (result && result.boardMembers) {
        const alternativePrincipalRole = getAlternativePrincipalRole(result.boardMembers);
        const alternative = result.boardMembers.find(x =>
          x.roles?.some(y => y === alternativePrincipalRole),
        );

        if (alternative) {
          commit(CompanySignupMutation.setCompanyPrincipals, [getCompanyPrincipalInitialState()]);
          commit(CompanySignupMutation.setPrincipalOther, {
            index: 0,
            value: true,
          });
          commit(CompanySignupMutation.setPrincipalFirstName, {
            index: 0,
            value: alternative.firstName,
          });
          commit(CompanySignupMutation.setPrincipalLastName, {
            index: 0,
            value: alternative.lastName,
          });
          commit(CompanySignupMutation.setPrincipalPersonalNumber, {
            index: 0,
            value: alternative.personalIdentityNumber,
          });
          commit(CompanySignupMutation.setPrincipalRole, {
            index: 0,
            value: alternativePrincipalRole,
          });
        } else {
          Sentry.captureMessage(
            "[Alert] Could not find suitable alternative principal",
            "info" as SeverityLevel,
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  async [CompanySignupAction.getPortfolioManagementCompanyPdfSignup](
    { state, commit, rootState },
    { pdfId }: { pdfId: number; pdfMode: PdfMode },
  ): Promise<void> {
    commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: true });
    const registerUserParameters = getRegisterCompanyParameters(state, rootState.signupStore);

    await pdfClient
      .getPortfolioManagementCompanyPdfFromSignup(registerUserParameters)
      .then((response: any) => {
        downloadFileResponse(response);
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [CompanySignupAction.getPortfolioInvestmentStrategyCompanyPdfSignup](
    { state, rootState, commit },
    { pdfId }: { pdfId: number; pdfMode: PdfMode },
  ): Promise<void> {
    commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: true });
    const registerUserParameters = getRegisterCompanyParameters(state, rootState.signupStore);

    await pdfClient
      .getPortfolioInvestmentStrategyCompanyPdfFromSignup(registerUserParameters)
      .then((response: any) => {
        downloadFileResponse(response);
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [CompanySignupAction.getAutogiroCompanyPdf](
    { commit, state },
    { pdfId }: { pdfId: number; pdfMode: PdfMode },
  ) {
    commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: true });
    const request = new CreateCompanyAutogiroPdf({
      companyName: state.companyName,
      organizationNumber: state.orgNumber,
      portfolioSettings: getPortfolioSettings(state),
      legalEntityId: undefined,
      portfolioExternalReference: undefined,
    });
    await pdfClient
      .getAutogiroCompanyPdfSignup(request)
      .then((response: any) => {
        downloadFileResponse(response);
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [CompanySignupAction.getInsuranceApplicationPdf](
    { commit, state, rootState },
    { pdfId }: { pdfId: number; pdfMode: PdfMode },
  ) {
    commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: true });
    const registerUserParameters = getRegisterCompanyParameters(state, rootState.signupStore);
    await pdfClient
      .getInsuranceApplicationPdfSignup(registerUserParameters)
      .then((response: any) => {
        downloadFileResponse(response);
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [CompanySignupAction.getOpeningDocumentsInsurance](
    { commit, state },
    { pdfId }: { pdfId: number; pdfMode: PdfMode },
  ) {
    commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: true });
    const request = new OpeningDocumentsInsuranceRequest({
      bricknodeLegalEntityNumber: "",
      companyName: state.companyName as string,
      organizationNumber: state.orgNumber as string,
      previousInsuranceExperience: state.hasPreviousInsuranceExperience as boolean,
      securitiesOwnerKnowledge: state.hasSecuritiesOwnerKnowledge as boolean,
    });
    await pdfClient
      .getOpeningDocumentsInsurance(request)
      .then((response: any) => {
        downloadFileResponse(response);
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [CompanySignupAction.getIdunKycPdf](
    { commit, state, rootState },
    { pdfId }: { pdfId: number; pdfMode: PdfMode },
  ) {
    commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: true });
    const registerUserParameters = getRegisterCompanyParameters(state, rootState.signupStore);
    await pdfClient
      .getIdunKycPdfSignup(registerUserParameters)
      .then((response: any) => {
        downloadFileResponse(response);
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(CompanySignupMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [CompanySignupAction.createLead]({ state, commit }): Promise<Lead | null> {
    const leadParams = new CreateLead({
      email: state.companyRepresentatives[0].email as string,
      phoneNumber: state.companyRepresentatives[0].phoneNumber,
      isCompany: true,
      trackingGuid: undefined,
      organizationNumber: undefined,
    });
    const lead = await leadClient.create(leadParams);
    if (lead) {
      commit(CompanySignupMutation.setCompanyTrackingGuid, lead.trackingGuid);
    }
    return lead;
  },
  async [CompanySignupAction.updateLeadWithOrgNumber]({ state }): Promise<Lead | null> {
    const leadParams = new CreateLead({
      email: state.companyRepresentatives[0].email as string,
      phoneNumber: state.companyRepresentatives[0].phoneNumber,
      isCompany: true,
      organizationNumber: state.orgNumber,
      trackingGuid: state.trackingGuid,
    });
    return leadClient.update(leadParams);
  },
  async [CompanySignupAction.validateFortnoxCode](
    { commit },
    code: string,
  ): Promise<FortnoxCodeResponse | null> {
    const codeResponse = await companyClient.fortnoxAuthCallback(code);
    if (codeResponse) {
      commit(
        CompanySignupMutation.setOrgNumber,
        codeResponse.fortnoxCompanySettings.organizationNumber,
      );
      commit(CompanySignupMutation.setCompanyName, codeResponse.fortnoxCompanySettings.name);
      commit(CompanySignupMutation.setCompanyEmail, codeResponse.fortnoxCompanySettings.email);
      commit(UserMutation.setToken, codeResponse.token);
    }
    return codeResponse;
  },
  async [CompanySignupAction.persistCompanyStateToBackend]({ state }): Promise<void> {
    await stateClient.create(new CreateFrontendStateRequest({ state: JSON.stringify(state) }));
  },
};
