<template>
  <swedish-tax-residence @next="next" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SwedishTaxResidence from "@/views/common-signup/questions/aml/swedish-tax-residence.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupFlowModificationType } from "@/config/signup-modification";

export default defineComponent({
  components: { SwedishTaxResidence },
  methods: {
    async next(key: string) {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.CitizenshipDenial,
        include: key !== "hasSwedishTaxResidence"
      });
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.swedish-tax-residence {
  height: 100%;
  width: 100%;
}
</style>
