import { createI18n } from "vue-i18n";
import { languages } from ".";

export const i18n = createI18n({
  locale: "sv",
  fallbackLocale: "sv",
  globalInjection: true,
  messages: {
    sv: languages.sv
  },
  legacy: false
});
