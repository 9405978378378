<template>
  <div
    class="information-link"
    tabindex="0"
    @click="linkClick"
  >
    <div class="information-link__icon-wrapper">
      <icon :icon="['fal', 'info']" />
    </div>
    <a class="information-link__text">
      {{ text }}
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    text: {
      type: String,
      default: undefined
    }
  },
  methods: {
    linkClick(event: MouseEvent) {
      (this as any).$emit("click", event);
    }
  }
});
</script>

<style lang="scss">
.information-link {
  color: $dusty-green;
  display: inline-flex;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  transition: box-shadow 0.1s ease-in-out;
  border-radius: 0.25rem;
  outline: none;
  &__icon-wrapper {
    background: white;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: grid;
    justify-items: center;
    align-items: center;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.08);
  }
  &__text {
    padding-left: 0.5rem;
    color: $dusty-green !important;
    font-family: $body-font-family;
    cursor: pointer;
  }
  &.focus-visible {
    box-shadow:
      0 0 0.0625rem 0.0625rem $aqua,
      0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
  }
}
</style>
