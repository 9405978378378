<template>
  <theme
    :portfolio-themes="portfolioThemes"
    :selected-portfolio-theme="portfolioTheme"
    @change="(event) => (portfolioTheme = event)"
  />
</template>

<script lang="ts">
import { TiedAgentPortfolioTheme } from "@/clients";
import { defineComponent } from "vue";
import Theme from "@/views/common-signup/questions/preferences/theme.vue";
import { SignupMutation } from "@/store/signup/mutations";

export default defineComponent({
  components: { Theme },
  computed: {
    portfolioTheme: {
      set(theme: TiedAgentPortfolioTheme): void {
        this.$store.commit(SignupMutation.setPortfolioTheme, theme);
      },
      get(): TiedAgentPortfolioTheme {
        return this.$store.state.signupStore.portfolioTheme;
      }
    },
    portfolioThemes(): TiedAgentPortfolioTheme[] {
      if (this.$store.state.signupStore.takeSustainabilityIntoAccount) {
        return this.$store.state.userStore.currentLegalEntity.tiedAgent.portfolioThemes.filter(
          (t: TiedAgentPortfolioTheme) => t.isSustainablePortfolio
        );
      }
      return this.$store.state.userStore.currentLegalEntity.tiedAgent.portfolioThemes;
    }
  }
});
</script>
