<template>
  <div class="transactions">
    <SectionHeader
      v-if="$vuetify.display.mdAndUp"
      :header="$t('transactions')"
      :sub-header="$store.getters.portfolioName || ''"
    >
      <template #icon>
        <icon
          :icon="['fal', 'receipt']"
          size="lg"
        />
      </template>
      <HeaderButton
        :icon="['fal', 'times']"
        :input-value="true"
        :tooltip-text="$t('close')"
        @click="closeTransactions"
      />
    </SectionHeader>

    <div class="transactions__data-manipulation-wrapper">
      <DateRange
        v-if="$vuetify.display.mdAndUp"
        v-model="dateRange"
      />
      <TransactionFilterComponent
        ref="transactionFilterComponent"
        v-model:search-query="search"
        :date-range="dateRange"
        @update-date-range="(newDateRange) => (dateRange = newDateRange)"
        @update-filter="(filter) => updateFilterParameters(filter)"
      />
      <v-text-field
        v-if="$vuetify.display.mdAndUp"
        v-model="search"
        :label="$t('search')"
        single-line
        variant="solo-filled"
        class="transactions__search"
        hide-details
      >
        <template #append-inner>
          <icon :icon="['fal', 'search']" />
        </template>
      </v-text-field>
      <v-btn
        variant="flat"
        icon
        height="3rem"
        width="3rem"
        color="white"
        class="transactions__reload-fab"
        @click="() => resetDataManipulation()"
      >
        <icon :icon="['fal', 'redo']" />
      </v-btn>
    </div>
    <v-data-table
      v-if="$vuetify.display.mdAndUp && !transactionsError"
      ref="transactionDataTable"
      :custom-sort="tableSort"
      :items="filteredTransactionList"
      :headers="headers"
      :page="currentPage"
      :loading="showTransactionLoader"
      :items-per-page="showAll ? -1 : 10"
      :search="search"
      item-key="name"
      @current-items="() => changePagination()"
      @update:sort-by="() => (currentPage = 1)"
      @update:sort-desc="() => (currentPage = 1)"
    >
      <template #bottom>
        <!-- Hide footer hack, feature request https://github.com/vuetifyjs/vuetify/issues/17651 -->
      </template>
      <template #body="{ items }">
        <tr
          v-for="(item, index) in items"
          :key="index"
          class="transactions__table-row"
        >
          <td class="transactions__table-data">
            {{ formatDate(item.date) }}
          </td>
          <td class="transactions__table-data transactions__table-data_left">
            {{ formatTransactionType(item.transactionType) }}
          </td>
          <td class="transactions__table-data transactions__table-data_left">
            <div class="transactions__icon-wrapper">
              <img
                v-if="item.marketPlace"
                class="transactions__table-icon"
                :src="getFlagUrl(getMarketIcon(item.marketPlace))"
              >
              <span v-if="item.name">{{ item.name }}</span>
            </div>
          </td>
          <td
            v-if="item.transactionType !== TransactionType.CashDividend"
            class="transactions__table-data"
          >
            {{ formatQuantity(item.quantity, item.transactionType) }}
            {{ $t("unit") }}
          </td>
          <td
            v-else
            class="transactions__table-data"
          />
          <td
            v-if="showRate(item.transactionType)"
            class="transactions__table-data"
          >
            {{ formatRate(item.value, undefined, item.transactionType) }}
            {{ formatCurrency(item.currency) }}
          </td>
          <td
            v-else
            class="transactions__table-data"
          />
          <td
            v-if="
              item.transactionType !== TransactionType.InstrumentDividend
                && item.transactionType !== TransactionType.Merger
                && item.transactionType !== TransactionType.TransferInInstrumentToIsk
                && item.transactionType !== TransactionType.TransferOutInstrumentFromIsk
            "
            class="transactions__table-data transactions__value-text"
            :class="{
              'transactions__value-text--negative': !isNetPositiveTransactionType(
                item.transactionType,
              ),
            }"
          >
            {{
              formatSum(
                item.value * item.quantity,
                isNetPositiveTransactionType(item.transactionType),
              )
            }}
            {{ formatCurrency(item.currency) }}
          </td>
          <td
            v-else
            class="transactions__table-data"
          />
          <td
            class="transactions__table-data"
            style="text-align: right"
          >
            <div
              v-if="
                item.transactionType === TransactionType.Buy
                  || item.transactionType === TransactionType.Sell
              "
            >
              <v-progress-circular
                v-if="isLoadingNote(item.businessEventId)"
                style="margin-right: 0.475rem"
                :size="20"
                indeterminate
                color="#8aacb6"
              />
              <v-btn
                v-else
                icon
                variant="flat"
                @click="downloadNote(item)"
              >
                <icon :icon="['fal', 'receipt']" />
              </v-btn>
            </div>
            <div
              v-else-if="
                item.transactionType === TransactionType.CurrencyExchange
              "
            >
              <v-progress-circular
                v-if="isLoadingNote(item.orderId)"
                style="margin-right: 0.475rem"
                :size="20"
                indeterminate
                color="#8aacb6"
              />
              <v-btn
                v-else-if="item.settledAndFeePaid"
                icon
                variant="flat"
                @click="downloadExchangeNote(item)"
              >
                <icon :icon="['fal', 'receipt']" />
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-data-table
      v-else-if="$vuetify.display.smAndDown && !transactionsError"
      ref="transactionDataTable"
      :custom-sort="tableSort"
      :items="filteredTransactionList"
      :headers="headers"
      :page="currentPage"
      :search="search"
      :loading="showTransactionLoader"
      item-key="name"
      @current-items="() => changePagination()"
      @update:sort-by="() => (currentPage = 1)"
      @update:sort-desc="() => (currentPage = 1)"
    >
      <template #bottom>
        <!-- Hide footer hack, feature request https://github.com/vuetifyjs/vuetify/issues/17651 -->
      </template>
      <template #headers>
        <!-- Hide headers hack -->
      </template>
      <template #body="{ items }">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(item, index) in items"
            :key="index"
            elevation="0"
            class="transactions__expansion-panel"
            collapse-icon="undefined"
          >
            <v-expansion-panel-title
              expand-icon=""
              class="transactions__expansion-panel-header"
            >
              <div
                class="transactions__expansion-panel-data-wrapper transactions__expansion-panel-data-wrapper--margin-bottom"
              >
                <span class="transactions__title-text">{{
                  formatTransactionType(item.transactionType)
                }}</span>
                <span class="transactions__note-text">{{ formatDate(item.date) }}</span>
              </div>
              <div class="transactions__expansion-panel-data-wrapper">
                <div class="transactions__icon-wrapper">
                  <img
                    v-if="item.marketPlace"
                    class="transactions__table-icon"
                    :src="getFlagUrl(getMarketIcon(item.marketPlace))"
                  >
                  <span v-if="item.name">{{ item.name }}</span>
                </div>
                <span
                  class="transactions__value-text"
                  :class="{
                    'transactions__value-text--negative': !isNetPositiveTransactionType(
                      item.transactionType,
                    ),
                  }"
                >{{
                   item.transactionType !== TransactionType.InstrumentDividend
                     ? formatSum(
                       item.value * item.quantity,
                       isNetPositiveTransactionType(item.transactionType),
                     )
                     : `${formatQuantity(item.quantity, item.transactionType)} ${$t("unit")}`
                 }}
                  {{ formatCurrency(item.currency) }}</span>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="transactions__expansion-panel-content">
              <div class="transactions__expansion-panel-content-row">
                <span class="transactions__data-text">{{ $t("amount") }}</span>
                <span class="transactions__expansion-panel-dotted-line" />
                <span class="transactions__data-text">{{ formatQuantity(item.quantity, item.transactionType) }} {{ $t("unit") }}</span>
              </div>
              <div class="transactions__expansion-panel-content-row">
                <span class="transactions__data-text">{{
                  $t("transactions.transactions.rate")
                }}</span>
                <span class="transactions__expansion-panel-dotted-line" />
                <span class="transactions__data-text">{{ formatRate(item.value, item.currency) }}
                  {{ formatCurrency(item.currency) }}</span>
              </div>
              <div
                v-if="
                  item.transactionType === TransactionType.Buy
                    || item.transactionType === TransactionType.Sell
                "
                class="transactions__expansion-panel-content-row"
              >
                <span class="transactions__data-text">{{ $t("note") }}</span>
                <span class="transactions__expansion-panel-dotted-line" />
                <span
                  class="transactions__data-text transactions__data-text--underline"
                  style="cursor: pointer"
                  @click="downloadNote(item)"
                ><v-progress-linear
                  v-if="isLoadingNote(item.businessEventId)"
                  indeterminate
                  color="#8aacb6"
                  style="width: 1rem"
                /><span v-else>PDF</span></span>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-data-table>
    <div
      v-else
      class="transactions__error"
    >
      <ErrorLoading :body="$t('transactions.transactions.cannotLoadTransactions')" />
    </div>
    <div
      v-if="showEmptyState"
      class="transactions__empty-state"
    >
      <icon
        :icon="['fal', 'info-circle']"
        class="transactions__empty-state-icon"
      />
      <h2 class="transactions__empty-state-heading">
        {{ $t("transactions.transactions.emptyStateHeading") }}
      </h2>
      <p class="transactions__empty-state-description">
        {{ $t("transactions.transactions.emptyStateDescription") }}
      </p>
    </div>
    <div
      v-if="!showEmptyState"
      class="transactions__footer"
    >
      <v-btn
        v-if="$vuetify.display.mdAndUp"
        height="3rem"
        color="white"
        class="transactions__footer-button"
        @click="toggleShowAll"
      >
        {{ showAll ? $t("showFewer") : $t("showAll") }}
      </v-btn>
      <Pagination
        v-show="!showAll"
        v-model="currentPage"
        :pagination-length="paginationLength"
      />
      <TransactionsCsvExport :transactions="csvTransactionRows" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";
import TransactionFilterComponent from "./transaction-filter.vue";
import Pagination from "./pagination.vue";

import type { CsvTransactionRow } from "./transactions-csv-export.vue";
import TransactionsCsvExport from "./transactions-csv-export.vue";

import { PortfolioMutation } from "@/store/portfolio/mutations";
import { MarketPlace, Transaction, TransactionType } from "@/clients";
import { NavigationMutation } from "@/store/navigation/mutations";
import { PortfolioAction } from "@/store/portfolio/actions";
import { UserAction } from "@/store/user/action-definitions";
import type { DateTimeRange, TransactionFilter } from "@/types/transaction";
import SectionHeader from "@/components/section-header.vue";
import ErrorLoading from "@/components/error-loading.vue";
import HeaderButton from "@/components/header-button.vue";
import DateRange from "@/components/date-range.vue";

class TransactionWithSum extends Transaction {
  sum?: number;
}

export default defineComponent({
  components: {
    Pagination,
    DateRange,
    SectionHeader,
    TransactionFilterComponent,
    HeaderButton,
    ErrorLoading,
    TransactionsCsvExport,
  },
  data: () => ({
    currentPage: 1,
    search: "",
    paginationLength: 1,
    filterParameters: undefined as undefined | TransactionFilter,
    userRequestedShowAll: false,
    sortBy: ["date"],
    sortDesc: true,
    dateRange: {
      fromDate: undefined,
      toDate: undefined,
    } as DateTimeRange,
    TransactionType,
    notesLoading: [] as string[],
  }),
  head() {
    return {
      title: this.$t("transactions"),
    };
  },
  computed: {
    ...mapGetters(["transactionsError"]),
    showAll: {
      get(): boolean {
        return this.$vuetify.display.mdAndUp && this.userRequestedShowAll;
      },
      set(showAll: boolean) {
        this.userRequestedShowAll = showAll;
      },
    },
    showEmptyState(): boolean {
      return (
        this.transactionList && this.transactionList.length === 0 && !this.showTransactionLoader
      );
    },
    showTransactionLoader(): boolean {
      const { isLoadingTransactions } = this.$store.state.portfolioStore;
      return isLoadingTransactions;
    },
    transactionList(): any[] {
      const { transactionList } = this.$store.state.portfolioStore;
      if (!transactionList) {
        return transactionList;
      }

      const list = transactionList
        .map((t: Transaction) => ({
          ...t,
          name: t.name === null ? "" : t.name,
          sum:
            t.quantity * (this.isNetPositiveTransactionType(t.transactionType) ? t.value : -t.value),
        }))
        .sort((a: any, b: any) => {
          const aTimeStamp = a.date.getTime();
          const bTimeStamp = b.date.getTime();
          return bTimeStamp - aTimeStamp;
        });

      return list;
    },
    filteredTransactionList(): Transaction[] {
      if (!this.transactionList || this.transactionList.length === 0) {
        return [];
      }

      if (!this.filterParameters) {
        return this.transactionList;
      }

      const {
        includeBuySellType,
        includeDepositAndWithdrawsType,
        includeDividendType,
        includeFeesAndTaxesType,
        includeCurrencyExchanges,
        includeBonusType,
      } = this.filterParameters;

      const filters = [
        { shouldInclude: includeBonusType, predicate: this.isBonusType },
        { shouldInclude: includeBuySellType, predicate: this.isBuySellType },
        { shouldInclude: includeCurrencyExchanges, predicate: this.isCurrencyExchangeType },
        { shouldInclude: includeDepositAndWithdrawsType, predicate: this.isDepositAndWithdrawType },
        { shouldInclude: includeDividendType, predicate: this.isDividendType },
        { shouldInclude: includeFeesAndTaxesType, predicate: this.isFeesAndTaxesType },
      ];

      return this.transactionList.filter((transaction) => {
        // Check if any filter should exclude the transaction
        return filters.every(filter => filter.shouldInclude || !filter.predicate(transaction));
      });
    },
    headers(): Array<any> {
      return [
        {
          title: this.$t("date"),
          key: "date",
          class: "transactions__header-text--larger",
          sortable: true,
          align: "start",
        },
        {
          title: this.$t("transactions.transactions.event"),
          key: "transactionType",
          class: "transactions__header-text",
          sortable: true,
          align: "start",
        },
        {
          title: this.$t("transactions.transactions.description"),
          key: "name",
          class: "transactions__header-text",
          sortable: true,
          align: "start",
        },
        {
          title: this.$t("amount"),
          key: "quantity",
          class: "transactions__header-text",
          filterable: false,
          sortable: true,
          align: "end",
        },
        {
          title: this.$t("transactions.transactions.rate"),
          key: "value",
          class: "transactions__header-text",
          filterable: false,
          sortable: true,
          align: "end",
        },
        {
          title: this.$t("sum"),
          key: "sum",
          class: "transactions__header-text",
          filterable: false,
          sortable: true,
          align: "end",
        },
        {
          title: this.$t("note"),
          key: "notes",
          class: "transactions__header-text",
          filterable: false,
          sortable: false,
          align: "start",
        },
      ];
    },
    csvTransactionRows(): CsvTransactionRow[] {
      const result: CsvTransactionRow[] = [];

      if (!this.filteredTransactionList || this.filteredTransactionList.length === 0) {
        return [];
      }
      const signFactor = (transactionType: TransactionType): number => (this.isNetPositiveTransactionType(transactionType) ? 1 : -1);

      this.filteredTransactionList.forEach((obj) => {
        result.push({
          date: obj.date.toLocaleDateString("sv-SE"),
          value: obj.value * signFactor(obj.transactionType),
          quantity: obj.quantity,
          sum: obj.value * obj.quantity * signFactor(obj.transactionType),
          marketPlace: obj.marketPlace !== MarketPlace.Unknown ? MarketPlace[obj.marketPlace] : "",
          name: obj.name,
          transactionType: this.formatTransactionType(obj.transactionType),
          currency: this.formatCurrency(obj.currency as string),
        });
      });
      return result;
    },
  },
  watch: {
    dateRange: {
      deep: true,
      handler(newVal: { fromDate?: DateTime; toDate?: DateTime }) {
        if (newVal.fromDate && newVal.toDate) {
          this.fetchTransactions(newVal.fromDate, newVal.toDate);
        }
      },
    },
    filterParameters() {
      this.currentPage = 1;
    },
  },
  mounted() {
    this.resetDateRange();
    this.$store.commit(NavigationMutation.setBackRoute, {
      name: "portfolio",
      params: { id: this.$route.params.id as string },
    });
    this.$store.commit(NavigationMutation.setMobileHeading, this.$t("transactions"));
  },
  methods: {
    getFlagUrl(flagFileName: string | undefined): string | undefined {
      if (flagFileName) {
        return new URL(`/assets/images/flags/${flagFileName}`, import.meta.url).href;
      }
      return undefined;
    },
    formatQuantity(quantity: number, transactionType: TransactionType): string {
      const localeStringOptions = {
        maximumFractionDigits: transactionType === TransactionType.CurrencyExchange ? 2 : 0,
      };
      return `${quantity.toLocaleString("sv-SE", localeStringOptions)}`;
    },
    formatRate(
      rate: number,
      currency = "",
      transactionType: TransactionType = TransactionType.Unknown,
    ) {
      if (currency === "GBX") {
        rate /= 1000;
      }
      const localeStringOptions
        = transactionType === TransactionType.CurrencyExchange
          ? {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            }
          : {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            };
      return `${rate.toLocaleString("sv-SE", localeStringOptions)}`;
    },
    formatDate(date: Date): string {
      if (date)
        return DateTime.fromISO(date.toISOString()).toFormat("yyyy.MM.dd");
      return "";
    },
    formatCurrency(currency: string): string {
      if (currency === "GBX") {
        // People don't know what GBX is, so put GBP instead (and convert value)
        return "GBP";
      }
      return currency;
    },
    formatSum(sum: number, isNetPositiveTransactionType: boolean): string {
      const localeStringOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return `${isNetPositiveTransactionType ? "+" : "-"} ${Math.abs(sum).toLocaleString(
        "sv-SE",
        localeStringOptions,
      )}`;
    },
    showRate(transactionType: TransactionType) {
      return (
        transactionType !== TransactionType.Deposit
        && transactionType !== TransactionType.Withdrawal
        && transactionType !== TransactionType.CashDividend
        && transactionType !== TransactionType.InstrumentDividend
        && transactionType !== TransactionType.Merger
        && transactionType !== TransactionType.TransferInInstrumentToIsk
        && transactionType !== TransactionType.TransferOutInstrumentFromIsk
      );
    },
    resetDateRange() {
      const createdDate = this.$store.getters.portfolioCreatedDate as DateTime;
      const threeMonthsAgo = DateTime.local().minus({ months: 3 });

      if (createdDate) {
        this.dateRange = {
          fromDate: createdDate < threeMonthsAgo ? threeMonthsAgo : createdDate,
          toDate: DateTime.local(),
        };
      }
    },
    resetDataManipulation() {
      this.search = "";
      this.filterParameters = undefined;
      this.resetDateRange();
      (this.$refs.transactionFilterComponent as any).resetToInitial();
    },
    updateFilterParameters(filter: TransactionFilter) {
      this.filterParameters = filter;
    },
    tableSort(items: TransactionWithSum[], index: string[], isDesc: boolean[]) {
      return items.sort((a, b) => {
        if (index[0] === "date") {
          const aTimeStamp = a.date.getTime();
          const bTimeStamp = b.date.getTime();
          if (isDesc[0]) {
            return bTimeStamp - aTimeStamp;
          }
          return aTimeStamp - bTimeStamp;
        }
        if (isDesc[0]) {
          return b[index[0]] < a[index[0]] ? -1 : 1;
        }
        return a[index[0]] < b[index[0]] ? -1 : 1;
      });
    },
    changePagination() {
      if (this.$refs.transactionDataTable) {
        // This solution is in order to retrieve all items of the table. Existing feature request: https://github.com/vuetifyjs/vuetify/issues/8731.
        const tableLength = (this.$refs.transactionDataTable as any).$children[0].filteredItems
          .length;
        this.paginationLength = tableLength > 0 ? Math.ceil(tableLength / 10) : 1;
      }
    },
    async fetchTransactions(startDate: DateTime, endDate: DateTime) {
      await this.$store.dispatch(PortfolioAction.loadTransactions, {
        start: startDate.toJSDate(),
        end: endDate.toJSDate(),
      });
      if (this.filteredTransactionList) {
        this.paginationLength = Math.ceil(this.filteredTransactionList.length / 10);
      } else {
        this.paginationLength = 1;
      }
    },
    formatTransactionType(transactionNumber: number): string {
      return this.$t(
        `transactions.transactions.transactionType.${TransactionType[transactionNumber]}`,
      );
    },
    getMarketIcon(marketPlace: number): string | undefined {
      const marketPlaceKey = MarketPlace[marketPlace];
      if (marketPlaceKey) {
        return `flag-${marketPlaceKey.toLowerCase()}.svg`;
      }
      return undefined;
    },
    isDividendType(item: Transaction) {
      return (
        item.transactionType === TransactionType.CashDividend
        || item.transactionType === TransactionType.InstrumentDividend
      );
    },
    isDepositAndWithdrawType(item: Transaction) {
      return (
        item.transactionType === TransactionType.Withdrawal
        || item.transactionType === TransactionType.Deposit
        || item.transactionType === TransactionType.SwishDeposit
        || item.transactionType === TransactionType.FinsharkDeposit
        || item.transactionType === TransactionType.TransferInCashToIsk
        || item.transactionType === TransactionType.TransferOutCashFromIsk
      );
    },
    isBuySellType(item: Transaction) {
      return (
        item.transactionType === TransactionType.Buy
        || item.transactionType === TransactionType.Sell
      );
    },
    isFeesAndTaxesType(item: Transaction) {
      return (
        item.transactionType === TransactionType.ManagementFee
        || item.transactionType === TransactionType.WithholdingTax
        || item.transactionType === TransactionType.TaxRepayment
        || item.transactionType === TransactionType.IdunFee
        || item.transactionType === TransactionType.IdunTax
      );
    },
    isCurrencyExchangeType(item: Transaction) {
      return item.transactionType === TransactionType.CurrencyExchange;
    },
    isBonusType(item: Transaction) {
      return item.transactionType === TransactionType.ReferralBonus;
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
      if (!this.showAll) {
        // Not using smooth animation because its not supported in Safari
        window.scrollTo(0, 0);
      }
    },
    closeTransactions(): void {
      this.$router.push({ name: "portfolio", params: { id: this.$route.params.id as string } });
    },
    isNetPositiveTransactionType(type: TransactionType): boolean {
      switch (type) {
        case TransactionType.CashDividend:
        case TransactionType.InstrumentDividend:
        case TransactionType.Deposit:
        case TransactionType.Unknown:
        case TransactionType.Sell:
        case TransactionType.ReferralBonus:
        case TransactionType.SwishDeposit:
        case TransactionType.Merger:
        case TransactionType.FinsharkDeposit:
        case TransactionType.TaxRepayment:
        case TransactionType.TransferInCashToIsk:
        case TransactionType.TransferInInstrumentToIsk:
          return true;
        case TransactionType.Buy:
        case TransactionType.ManagementFee:
        case TransactionType.Withdrawal:
        case TransactionType.CurrencyExchange:
        case TransactionType.WithholdingTax:
        case TransactionType.IdunFee:
        case TransactionType.IdunTax:
        case TransactionType.TransferOutCashFromIsk:
        case TransactionType.TransferOutInstrumentFromIsk:
          return false;
        default:
          Sentry.captureMessage(`[Alert] Unknown transaction list type: ${type}`, "warning");
          return true;
      }
    },
    async downloadNote(transaction: Transaction) {
      this.notesLoading.push(transaction.businessEventId);
      try {
        await this.$store.dispatch(PortfolioAction.getNote, {
          businessEventId: transaction.businessEventId,
          date: transaction.date,
        });
      } catch (error) {
        this.$store.dispatch(
          UserAction.addSnackbarMessage,
          this.$t("transactions.transactions.downloadNoteError"),
        );
      } finally {
        this.notesLoading = this.notesLoading.filter(id => id !== transaction.businessEventId);
      }
    },
    async downloadExchangeNote(transaction: Transaction) {
      this.notesLoading.push(transaction.orderId);
      try {
        await this.$store.dispatch(PortfolioAction.getExchangeNote, {
          orderId: transaction.orderId,
          date: transaction.date,
        });
      } catch (error) {
        console.error(error);
        this.$store.dispatch(
          UserAction.addSnackbarMessage,
          this.$t("transactions.transactions.downloadNoteError"),
        );
      } finally {
        this.notesLoading = this.notesLoading.filter(id => id !== transaction.orderId);
      }
    },
    isLoadingNote(id: string) {
      return this.notesLoading.includes(id);
    },
  },
});
</script>

<style lang="scss" scoped>
.transactions {
  max-width: 71rem;
  width: 100%;
  @include medium-up {
    min-width: 47.1875rem;
  }
  @include small-down {
    padding: 1rem;
  }
  &__data-manipulation-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 1rem;
    align-items: center;
    @include medium-up {
      grid-template-columns: auto 1fr 1fr auto;
    }
  }
  &__header-text {
    padding: 0 !important;
    color: $nearly-black;
    border-bottom: thin solid #fafafa !important;
    font-size: 0.875rem !important;
    font-weight: 300;
    &--larger {
      font-size: 1rem !important;
      border-bottom: thin solid #fafafa !important;
      font-weight: 500;
    }
  }
  &__empty-state {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem 1rem 1rem;
    text-align: center;
  }
  &__empty-state-heading {
    color: $soft-blue;
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  &__empty-state-icon {
    color: $soft-blue;
    font-size: 1.5rem;
    margin-bottom: 0.375rem;
  }
  &__empty-state-description {
    font-weight: 300;
  }
  &__reload-fab {
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16) !important;
  }
  &__footer {
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
    justify-content: center;
    @include medium-up {
      justify-content: space-between;
    }
  }
  &__footer-button {
    box-shadow: 0 0.125rem 0.625rem rgba($nearly-black, 0.16);
    font-size: 1rem !important;
    font-family: $heading-font-family;
    letter-spacing: normal;
    text-transform: none;
    font-weight: 500;
    width: 8.375rem;
  }
  &__table-row {
    background-color: white;
    box-shadow: 0 0.1875rem 1.25rem rgba(96, 96, 96, 0.06);
    position: relative;
    @include medium-up {
      &:hover {
        background: #fff !important;
      }
    }
  }
  &__table-data {
    padding: 0 0.875rem !important;
    width: 3rem;
    border-bottom: thin solid white !important;
    &:not(:first-child) {
      text-align: right;
      padding-left: 0;
    }
    @include medium-up {
      &:first-child {
        width: 14%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 18%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 12%;
      }
      &:nth-child(6) {
        width: 18%;
      }
      &:nth-child(7) {
        width: 8%;
      }
    }
  }
  &__table-data_left {
    border-bottom: thin solid white !important;
    text-align: left !important;
    padding-left: 0 !important;
  }
  &__icon-wrapper {
    display: flex;
    align-items: center;
  }
  &__table-icon {
    margin-right: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
  &__title-text {
    font-weight: 500;
  }
  &__note-text {
    opacity: 0.63;
  }
  &__data-text {
    opacity: 0.8;
    &--underline {
      text-decoration: underline;
    }
  }
  &__value-text {
    color: #4f637b;
  }
  &__value-text--negative {
    color: #a21717;
  }
  &__expansion-panel {
    margin-bottom: 1rem;
    box-shadow: 0 0.1875rem 1.25rem rgba(96, 96, 96, 0.06);
    font-size: 0.875rem;
    font-weight: 300;
  }
  &__expansion-panel-header {
    font-size: 0.875rem;
    font-weight: 300;
    display: grid;
    grid-template-columns: 1fr;
    box-shadow: 0 0.1875rem 1.25rem rgba(96, 96, 96, 0.06);
  }
  &__expansion-panel-data-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &--margin-bottom {
      margin-bottom: 0.875rem;
    }
  }
  &__expansion-panel-content {
    width: 100%;
    padding-top: 1rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  &__expansion-panel-content-row {
    display: flex;
    align-items: baseline;
  }
  &__expansion-panel-dotted-line {
    flex: 1;
    border-bottom: 0.125rem dotted $nearly-black;
    opacity: 0.21;
  }
  &__search {
    font-weight: 300;
    font-family: $heading-font-family;
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16) !important;
    :deep(.v-field__overlay) {
      background-color: white;
    }
    :deep(.v-field__field) {
      height: 3rem;
    }
  }
  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16rem;
    @include medium-up {
      height: 22rem;
    }
  }
  .active {
    font-weight: 550 !important;
  }
  .v-data-table-header__icon,
  :deep(.v-expansion-panel-title__icon) {
    visibility: hidden;
  }
  :deep(.v-data-table__wrapper) {
    overflow-x: hidden !important;
  }
  :deep(.v-data-table) {
    background: transparent;
  }
  :deep(table) {
    border-collapse: separate;
    border-spacing: 0 1rem !important;
  }
}
</style>
