<template>
  <div class="system-health">
    <div class="system-health__logo-container">
      <img
        class="welcome__logo"
        :src="logoUrl"
      />
    </div>
    <br />
    <br />
    <br />
    <div class="system-health__text-body">{{ text }}</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SystemHealth } from "@/clients";

export default defineComponent({
  components: {},
  data(): any {
    return {
      logoUrl: new URL("/assets/images/logos/sigmastocks-logo-text.svg", import.meta.url).href
    };
  },
  props: {
    systemHealth: {
      type: Number,
      default: 0
    }
  },
  computed: {
    text(): string {
      switch (this.systemHealth) {
        case SystemHealth.Maintenance:
          return this.$t("components.system-health.maintenance");
        case SystemHealth.Down:
          return this.$t("components.system-health.down");
        default:
          return "";
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.system-health {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  @include small-up {
    padding: 0;
    padding-top: 5rem;
    width: 25rem;
  }
  &__logo-container {
    width: 100%;
    text-align: center;
    height: 4.0625rem;
  }
  &__text-body {
    text-align: center;
  }
}
</style>
