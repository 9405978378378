<template>
  <div class="tink-info">
    <iframe
      id="tink-iframe"
      ref="iframe"
      class="tink-iframe"
      :src="tinkPath"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as Sentry from "@sentry/vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import Question from "@/views/signup/templates/question";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupAction } from "@/store/signup/actions";
import { E2E_KEY } from "@/config/general";

export default defineComponent({
  extends: Question,
  data: () => ({
    tinkPath: "",
    callbackFunc: null as any,
  }),
  unmounted() {
    window.removeEventListener("message", this.callbackFunc);
  },
  async mounted() {
    if (sessionStorage.getItem(E2E_KEY)) {
      await this.$store.dispatch(SignupAction.getAccountDetails, "12345");
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      return;
    }
    const webUrl = encodeURIComponent(import.meta.env.VITE_WEB_BASE_URL as string);

    this.tinkPath =
          import.meta.env.VITE_ENV === "production"
            ? `https://link.tink.com/1.0/account-check?client_id=eab901e187df45c3b470145117eaf4de&redirect_uri=${webUrl}&market=SE&locale=sv_SE&iframe=true&refreshable_items=CHECKING_ACCOUNTS`
            : `https://link.tink.com/1.0/account-check?client_id=b55f3076cf2c4e26a6b655b6b23217bc&redirect_uri=${webUrl}&market=SE&locale=sv_SE&test=true&input_provider=se-demobank-password&input_username=u51613239&iframe=true&refreshable_items=CHECKING_ACCOUNTS`;

    this.$store.commit(SignupMutation.setTinkError, false);

    this.callbackFunc = async (event: any) => {
      if (event.origin !== "https://link.tink.com") {
        return;
      }

      // Read more about the message format here: https://docs.tink.com/api/#tink-link-response-iframe-mode
      const { type, data, error } = JSON.parse(event.data);

      if (type === "account_verification_report_id") {
        this.$store.commit(SignupMutation.setTinkError, false);
        await this.$store.dispatch(SignupAction.getAccountDetails, data);

        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      } else if (type === "error") {
        // Handle error response from Tink Link
        if (error.status === "USER_CANCELLED") {
          // Hacky to just reload iframe
          (document as any).getElementById("tink-iframe").src += "";
        } else {
          console.error(
            `Tink returned with error status: ${error.status} and error message: ${error.message}.`,
          );
          Sentry.captureMessage(`Tink error: ${error.status}, ${error.message}`, "error");
          this.goToFallbackQuestion();
        }
      } else if (type === "credentials") {
        // Handle credentials error response from Tink Link
        const credentialsId = data;

        console.error(
          `Tink authentication failed with credentials identifier: ${credentialsId} with error status: ${error.status} and error message: ${error.message}.`,
        );
        Sentry.captureMessage(`Tink credentials error: ${error.status}, ${error.message}`, "error");
        this.goToFallbackQuestion();
      } else if (data.screen === "PROVIDER_TEMPORARY_DISABLED_SCREEN") {
        // The check for this case is perhaps not meant to be used, but it's better than nothing
        this.goToFallbackQuestion();
      }
    };
    window.addEventListener("message", this.callbackFunc);
    (this.$refs.iframe as any).addEventListener("message", this.callbackFunc);
  },
  methods: {
    goToFallbackQuestion() {
      this.$store.commit(SignupMutation.setTinkError, true);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
  },
});
</script>

<style lang="scss" scoped>
.tink-info {
  width: 100%;
  height: 100%;
  @include medium-up {
    max-height: 700px;
  }
}
.tink-iframe {
  padding-top: 1rem;
  border-style: none;
  width: 100%;
  height: 100%;
}
</style>
