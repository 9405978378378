import { RootState } from "@/types";
import { GetterTree, MutationTree } from "vuex";
import { actions } from "./actions";
import { mutations, getBankIdInitialState } from "./mutations";
import { getters } from "./getters";
import { BankIdState } from "./types";

const initialState = getBankIdInitialState();

export default {
  state: initialState,
  mutations,
  actions,
  getters
};
