import { getSignupNavigationInitialState, mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

const state = getSignupNavigationInitialState();

export default {
  state,
  mutations,
  actions,
  getters
};
