<template>
  <side-dialog
    :show-back-button="showArchive"
    :model-value="modelValue"
    :title="dialogTitle"
    data-jest="dialog"
    @update:model-value="(value) => {
      showArchive = false;
      $emit('update:model-value', value);
    }
      "
    @back="(value) => {
      showArchive = false;
    }
      "
  >
    <div class="agreements__wrapper">
      <div>
        <div>
          <template v-if="!showArchive">
            <v-expansion-panels
              :multiple="true"
              variant="accordion"
            >
              <v-expansion-panel
                v-for="(ownerAgreements, key) in agreements"
                :key="key"
                elevation="0"
              >
                <v-expansion-panel-title expand-icon="fas fa-chevron-down">
                  <span class="agreements__company-header-text">{{
      ownerAgreements.pdfOwnerName
    }}</span>
                </v-expansion-panel-title>
                <v-expansion-panel-text class="pe-side-dialog__paragraph">
                  <div
                    v-for="(pdf, pdfKey) in ownerAgreements.agreements"
                    :key="pdfKey"
                    class="agreements__row"
                    @click="loadPdf(pdf, pdfKey)"
                  >
                    <div>{{ $t(pdf.displayName!) }}</div>
                    <div class="agreements__icon">
                      <loading-spinner
                        v-if="isLoading[pdfKey]"
                        data-jest="loading-spinner"
                        class="agreements__loading"
                      />
                      <icon
                        v-else
                        fixed-width
                        :icon="['fal', 'arrow-to-bottom']"
                      />
                    </div>
                  </div>
                  <a
                    v-for="pdf in ownerAgreements.staticAgreements"
                    :key="pdf.path"
                    :href="baseUrl + encodeURI(pdf.path ? pdf.path : '')"
                    class="agreements__row agreements__row--no-hyperlink"
                    target="_blank"
                  >{{ $t(pdf.name!) }}</a>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <div
              v-if="!showArchive"
              class="agreements__archive"
              @click="clickedArchive"
            >
              {{ $t("portfolio.overview.current-agreements-side-dialog.archive") }}
              <icon
                fixed-width
                :icon="['fas', 'chevron-right']"
              />
            </div>
          </template>
          <template v-else>
            <div>
              <v-expansion-panels
                v-model="panels"
                :multiple="true"
              >
                <v-expansion-panel
                  v-for="(pdfsOfType, key) in archivePdfList"
                  :key="key"
                  elevation="0"
                >
                  <v-expansion-panel-title expand-icon="fas fa-chevron-down">
                    <span class="agreements__archive-header-text">{{
      pdfsOfType.displayName
    }}</span>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="pe-side-dialog__paragraph">
                    <div
                      v-for="pdf in pdfsOfType.files"
                      :key="pdf.brickId"
                      class="agreements__row"
                      @click="loadArchivedPdf(pdf)"
                    >
                      <div>
                        {{ pdfsOfType.displayName }} - {{ formattedDate(pdf.creationDate) }}
                      </div>
                      <div class="agreements__icon">
                        <icon
                          fixed-width
                          :icon="['fal', 'arrow-to-bottom']"
                        />
                      </div>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel
                  v-if="archivedStaticAgreements.length > 0"
                  elevation="0"
                >
                  <v-expansion-panel-title expand-icon="fas fa-chevron-down">
                    <span class="agreements__archive-header-text"> Bilagor </span>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="pe-side-dialog__paragraph">
                    <a
                      v-for="pdf in archivedStaticAgreements"
                      :key="pdf.path"
                      :href="baseUrl + encodeURI(pdf.path ? pdf.path : '')"
                      class="agreements__row agreements__row--no-hyperlink"
                      target="_blank"
                    >{{ pdf.name }}</a>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </template>
        </div>
      </div>
    </div>
  </side-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import {
  Agreement,
  ArchivedPdfs,
  GroupedPortfolioAgreements,
  LegalEntity,
  OwnerWithAgreements,
  PdfListData,
  PdfType,
  PortfolioBase,
  StaticAgreement
} from "@/clients";
import { UserAction } from "@/store/user/action-definitions";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { baseUrl } from "@/clients/config";

interface PdfArea {
  [key: number]: Array<PdfListData>;
}
interface PdfItem {
  id: number;
  name: string;
  action: string;
  previewAction: string;
  pdfType: PdfType;
}
export default defineComponent({
  components: { SideDialog, LoadingSpinner },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    legalEntity: {
      type: LegalEntity,
      default: undefined
    },
    portfolio: {
      type: PortfolioBase,
      required: true
    }
  },
  emits: ["update:model-value", "archive"],
  data: () => ({
    showArchive: false,
    baseUrl,
    panels: []
  }),
  computed: {
    dialogTitle(): string {
      if (!this.showArchive) return this.$t("agreementsTitle");
      return this.$t("portfolio.overview.current-agreements-side-dialog.archiveTitle");
    },
    agreements(): OwnerWithAgreements[] {
      if (this.$store.state.portfolioStore.agreements?.ownerWithAgreements) {
        return this.$store.state.portfolioStore.agreements?.ownerWithAgreements;
      }
      return [];
    },
    isLoading(): Array<boolean> {
      const { loadingPdf } = this.$store.state.userStore;
      return loadingPdf;
    },
    archivedStaticAgreements(): StaticAgreement[] {
      if (this.$store.state.userStore.staticAgreements?.archivedAgreements) {
        return this.$store.state.userStore.staticAgreements.archivedAgreements;
      }
      return [];
    },
    archivePdfList(): ArchivedPdfs[] | undefined {
      const { archivePdfList } = this.$store.state.userStore;
      if (archivePdfList) {
        return archivePdfList;
      }
      return undefined;
    }
  },
  watch: {
    showArchive: {
      immediate: true,
      handler(newValue: boolean, oldValue: boolean) {
        if (newValue) {
          const portfolio = this.$store.state.portfolioStore.activePortfolioBase;
          this.$store.dispatch(UserAction.loadPdfList, portfolio.id);
          this.$store.dispatch(UserAction.loadStaticAgreements, portfolio.id);
        }
      }
    }
  },
  async created() {
    await this.$store.dispatch(UserAction.loadPortfolioAgreements, this.portfolio.id);
  },
  methods: {
    formattedDate(date: Date) {
      if (date) {
        return date.toLocaleDateString("sv-SE");
      }
      return "";
    },
    loadPdf(pdf: Agreement, pdfKey: number) {
      this.$store.dispatch(UserAction.getCurrentAgreementPdf, {
        pdfId: pdfKey,
        portfolioId: this.portfolio.id,
        pdfType: pdf.pdfType
      });
    },
    loadArchivedPdf(pdf: PdfListData) {
      this.$store.dispatch(UserAction.loadPdf, pdf);
    },
    clickedArchive() {
      this.showArchive = true;
      this.$emit("archive", true);
    }
  }
});
</script>

<style lang="scss">
.agreements {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  &__icon {
    margin-left: 0.6rem;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5rem;
    cursor: pointer;

    &--no-hyperlink {
      text-decoration: none;
      color: inherit !important;
    }
  }

  &__icon {
    margin-left: 0.6rem;
  }

  &__loading {
    height: 1rem !important;
    width: 1rem !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  &__company-header-text {
    font-weight: bold;
  }

  &__archive {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    &-header-icon {
      font-size: 1rem !important;
    }

    &-header-text {
      font-weight: bold;
    }
  }
}

:deep(.v-expansion-panel-title__overlay) {
  opacity: 0 !important;
}
</style>
