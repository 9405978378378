<template>
  <v-dialog
    v-model="showDialog"
    content-class="sign-dialog"
    @click:outside="signCancel"
  >
    <v-card class="sign-dialog__card">
      <div v-if="preparing"><loading-spinner /></div>
      <div v-else-if="isSigning">
        <bank-id
          :sign-location="SignLocation.PORTFOLIO_SETTINGS"
          @complete="saveSettigns"
          @canceled="signCancel"
          @failed="signFailed"
        />
      </div>
      <div v-else>
        <p class="sign-dialog__header">
          {{ errors ? $t("signCompletionHeaderFailed") : $t("completionHeader") }}
        </p>
        <p
          v-if="errors"
          class="sign-dialog__body"
        >
          {{ $t("completionBodyFailed") }}
        </p>
        <slot v-else />
        <v-card-actions class="sign-dialog__actions">
          <common-button
            class="sign-dialog__cancel-button"
            @click="close"
          >
            {{ $t("close") }}
          </common-button>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { BankIdAction } from "@/store/bankid/actions";
import CommonButton from "@/components/button.vue";
import BankId from "@/components/bank-id.vue";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { SignLocation } from "@/store/bankid/types";

export default defineComponent({
  emits: ["prepare", "save", "cancel", "update:model-value", "cancel"],
  components: {
    CommonButton,
    BankId,
    LoadingSpinner
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    signComplete: {
      type: Boolean,
      required: true
    },
    settingToSave: {
      type: String,
      required: true
    },
    updateSettingsError: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    preparing: true,
    isSigning: false,
    isSaving: false,
    signSettingsFailed: false,
    SignLocation
  }),
  computed: {
    errors(): boolean {
      return this.signSettingsFailed || this.updateSettingsError;
    },
    showDialog: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:model-value", value);
      }
    }
  },
  async created() {
    if (this.signComplete) {
      this.saveSettigns();
      this.isSigning = false;
      this.preparing = false;
    } else {
      this.signSettingsFailed = false;
      await new Promise((resolve) => this.$emit("prepare", this.settingToSave, resolve));
      this.isSigning = true;
      this.preparing = false;
      await this.$store.dispatch(BankIdAction.sign);
    }
  },
  methods: {
    async save() {
      // To be able to know if the save action was successful or not the async update call to the API must complete before we continue.
      // It is up to the observer of this event to resolve the promise upon completion.
      await new Promise((resolve) => this.$emit("save", this.settingToSave, resolve));
    },
    async close() {
      if (this.errors) {
        this.$emit("cancel", this.settingToSave);
      } else {
        this.$emit("update:model-value", false);
      }
    },
    async saveSettigns() {
      await this.save();
      this.isSigning = false;
    },
    signCancel() {
      this.isSigning = false;
      this.signSettingsFailed = true;
      this.$emit("cancel", this.settingToSave);
    },
    signFailed() {
      this.signSettingsFailed = true;
    }
  }
});
</script>

<style lang="scss" scoped>
.sign-dialog {
  display: flex;
  justify-content: center;
  &__card {
    padding: 1.5rem 1rem 0rem 1rem;
    min-height: 22rem;
  }
  &__header {
    font-family: $heading-font-family;
    font-size: 1.125rem;
    font-weight: 500;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;
  }
  &__body {
    font-family: $heading-font-family;
    font-size: 1rem;
    font-weight: 300;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;
  }
  &__actions {
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    @include small-up {
      flex-wrap: unset;
    }
  }
  &__cancel-button {
    margin-top: 0.5rem;
    @include xsmall-up {
      margin-top: 0rem;
    }
  }
}
:deep(.sign-dialog) {
  max-width: 30rem;
}
.v-card-actions .v-btn ~ .v-btn:not(.v-btn-toggle .v-btn) {
  margin-left: 0;
}
</style>
