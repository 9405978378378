<template>
  <div>
    <card-header
      :title="$t('marketDistribution')"
      :icon="['fal', 'globe-americas']"
      @click="() => (showDialog = true)"
    />
    <div class="market-column-chart-wrapper">
      <div class="market-column-chart-value-wrapper">
        <template
          v-for="market in marketShares"
          :key="market.market"
        >
          <market-column-chart
            :model-value="market.value"
            :highest-value="highestMarketShare"
            :color="market.color"
          />
        </template>
      </div>
      <market-column-chart-legend :legends="marketShares" />
    </div>
    <loading-spinner-overlay :model-value="showLoadingSpinner" />
    <side-dialog
      v-model="showDialog"
      :title="$t('marketDistribution')"
    >
      <div class="market-shares__side-dialog">
        <p class="market-shares__side-dialog-description">
          {{ $t("portfolio.analysis.market-shares.infoBodyOne") }}
        </p>
        <p class="market-shares__side-dialog-description">
          {{ $t("portfolio.analysis.market-shares.infoBodyTwo") }}
        </p>
        <common-button
          class="market-shares__side-dialog__button"
          @click="() => (showDialog = false)"
        >
          {{ $t("ok") }}
        </common-button>
      </div>
    </side-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MarketDistribution, MarketShareKey } from "@/types/portfolio";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import { softBlue } from "@/styles/colors";
import CommonButton from "@/components/button.vue";
import CardHeader from "./card-header.vue";
import MarketColumnChart from "./market-column-chart.vue";
import MarketColumnChartLegend from "./market-column-chart-legend.vue";

interface MarketShareData {
  market: MarketShareKey;
  value?: number;
  color?: string;
}

const marketShareKeys: MarketShareKey[] = ["Sweden", "Us", "Europe"];

export default defineComponent({
  components: {
    CardHeader,
    MarketColumnChart,
    MarketColumnChartLegend,
    LoadingSpinnerOverlay,
    SideDialog,
    CommonButton
  },
  data: () => ({
    showDialog: false
  }),
  computed: {
    showLoadingSpinner(): boolean {
      const { isLoadingHoldingSummary } = this.$store.state.portfolioStore;
      return isLoadingHoldingSummary;
    },
    colorAlternatives(): Array<string> {
      return ["rgba(138, 172, 182, 0.60)", softBlue, "rgba(138, 172, 182, 0.80)"];
    },
    marketShares(): Array<MarketShareData> {
      const { marketShares } = this.$store.getters;
      let formattedMarketShares = this.getUndefinedMarketShares;
      if (marketShares) {
        formattedMarketShares = marketShares.map((x: MarketDistribution, index: number) => ({
          market: this.$t(`markets.${x.market}`),
          value: x.share,
          color: this.colorAlternatives[index]
        }));
      }
      return formattedMarketShares;
    },
    highestMarketShare(): number {
      return Math.max(
        ...this.marketShares.map((marketShareObject) => marketShareObject.value as number)
      );
    },
    getUndefinedMarketShares(): Array<MarketShareData> {
      return marketShareKeys.map((x) => ({
        market: this.$t(`markets.${x}`) as MarketShareKey
      }));
    }
  }
});
</script>

<style lang="scss" scoped>
.market-shares {
  &__side-dialog {
    padding: 2rem;
    &-description {
      font-weight: 300;
    }
    &__button {
      display: flex;
      margin: 2rem auto;
      border-radius: 0.5rem;
      font-weight: 500;
      font-family: $heading-font-family;
      text-transform: none;
      font-size: 1.125rem;
      box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16);
    }
  }
}
.market-column-chart-wrapper {
  padding: 1rem 0;
  @include large-up {
    display: grid;
    grid-template-columns: auto auto;
    padding: 2rem 0;
  }
}

.market-column-chart-value-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @include large-up {
    margin-left: 2.3rem;
    border-bottom: 0.1rem solid rgba($nearly-black, 0.15);
  }
}
</style>
