<template>
  <div class="welcome">
    <single-choice
      class="welcome__single-choice"
      :alternatives="alternatives"
      @click="buttonClick"
    >
      <information-text-section
        :heading="heading"
        :body="body1"
        :body2="body2"
      >
        <icon />
      </information-text-section>
      <div
        style="
          display: flex;
          justify-content: center;
          margin: 2rem auto 0 auto;
          max-width: 21.25rem;
        "
      >
        <!-- TODO: Move this from first signup to here -->
        <!-- <affiliate-code-input v-if="!hasPortfolios" ref="codeInput" v-model="affiliateCode" /> -->
      </div>
    </single-choice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import AffiliateCodeInput from "@/components/affiliate-code-input.vue";
import { SignupMutation } from "@/store/signup/mutations";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Question from "@/views/signup/templates/question";
import { readFileSync } from "node:fs";

import Icon from "@/assets/images/signup/briefcase-light.svg";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    AffiliateCodeInput,
    Icon
  },
  extends: Question,
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    },
    hasPortfolios(): boolean {
      return (
        this.$store.state.navigationStore.portfolios &&
        this.$store.state.navigationStore.portfolios.length > 0
      );
    },
    heading(): string {
      if (this.hasPortfolios) {
        return this.$t("create-portfolio.introduction.welcome.hasPortfoliosHeading");
      }
      return this.$t("create-portfolio.introduction.welcome.noPortfoliosHeading");
    },
    body1(): string {
      if (this.hasPortfolios) {
        return this.$t("create-portfolio.introduction.welcome.hasPortfoliosBody1");
      }
      return this.$t("create-portfolio.introduction.welcome.noPortfoliosBody1");
    },
    body2(): string {
      if (this.hasPortfolios) {
        return "";
      }
      return this.$t("create-portfolio.introduction.welcome.noPortfoliosBody2");
    },
    affiliateCode: {
      get(): string {
        return this.$store.state.signupStore.affiliateReferralCode;
      },
      set(value: string): void {
        this.$store.commit(SignupMutation.setAffiliateReferralCode, value);
      }
    }
  },
  methods: {
    async buttonClick() {
      if (this.affiliateCode && !(await (this.$refs.codeInput as any).validate())) {
        return;
      }
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.welcome {
  height: 100%;
}
</style>
