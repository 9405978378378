<template>
  <div class="principals">
    <section-header
      v-if="$vuetify.display.mdAndUp"
      :header="$t('principal')"
      class="principals__header"
    >
      <template #icon>
        <icon
          :icon="['fal', 'user']"
          size="lg"
        />
      </template>
    </section-header>
    <div class="principals__cards">
      <v-card
        v-for="(principal, i) in principals"
        :key="i"
        ref="principalsList"
      >
        <principal
          @save="save"
          :index="i"
        />
      </v-card>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SectionHeader from "@/components/section-header.vue";
import { CompanyPrincipal } from "@/clients";
import Principal from "./principal.vue";

export default defineComponent({
  emits: ["save"],
  components: {
    SectionHeader,
    Principal
  },
  computed: {
    principals(): CompanyPrincipal[] {
      if (this.$store.state.userStore.currentLegalEntity.companyKnowYourCustomerAnswers) {
        return this.$store.state.userStore.currentLegalEntity.companyKnowYourCustomerAnswers
          .companyPrincipals;
      }
      return [];
    }
  },
  methods: {
    save(index: number) {
      this.$emit("save", index);
    }
  }
});
</script>
<style lang="scss" scoped>
.principals {
  max-width: 71rem;
  padding-top: 1rem;
  width: 100%;
  @include medium-up {
    padding: unset;
  }
  &__header {
    width: 100%;
  }
  &__cards {
    display: grid;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    grid-template-rows: auto;
    @include small-up {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto auto;
    }
    @include medium-up {
      margin-top: 2rem;
      grid-row-gap: 1.5rem;
      grid-column-gap: 1.5rem;
    }
  }
}
.v-menu__content {
  border-radius: 0.5rem;
  box-shadow: 0 0.1875rem 2rem rgba(0, 0, 0, 0.32);
  max-width: 15.625rem;
  @include small-down {
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
  }
}
</style>
