<template>
  <div class="holdings-table">
    <div
      v-for="table in tables"
      :key="table.key"
    >
      <v-data-table
        v-if="table.data.length > 0"
        :headers="getHoldingHeaders()"
        :items="table.data"
        :items-per-page="table.data.length"
        :mobile-breakpoint="0"
        class="holdings-list"
        :data-jest="`holdings-list-${table.key}`"
      >
        <template #bottom>
          <!-- Hide footer hack, feature request https://github.com/vuetifyjs/vuetify/issues/17651 -->
        </template>
        <template #top>
          <div
            class="holdings-list__heading-row"
            @click="expandTable(table.key)"
          >
            <div>
              <icon
                v-if="table.icon"
                class="holdings-list__sector-icon"
                :icon="table.icon"
              />
              {{ table.title }}
            </div>
            <icon
              class="button__caret"
              :icon="['fal', isTableExpanded(table.key) ? 'chevron-up' : 'chevron-down']"
            />
          </div>
        </template>
        <template #item="props">
          <tr
            v-if="isTableExpanded(table.key)"
            class="holdings-list__table-row holdings-list__table-row--clickable"
            @click="goToHoldingDetail(props.item)"
          >
            <td class="holdings-list__table-data">
              <div class="holdings-list__table-data-container">
                <div class="holdings-list__flag-icon-container">
                  <img
                    v-if="hasMarketAndMarketPlace(props.item)"
                    class="holdings-list__flag-icon"
                    :src="getFlagUrl(props.item.icon)"
                  >
                </div>
                <div class="holdings-list__table-data-container-item">
                  {{ props.item.name }}
                </div>
                <LabelNew
                  v-if="props.item.isNew || props.item.additionalBought"
                  :label="
                    props.item.isNew
                      ? $t('portfolio.holdings.holdings-list.new')
                      : $t('portfolio.holdings.holdings-list.more')
                  "
                  class="holdings-list__label-new"
                  :data-jest="`${props.item.name}-newLabel`"
                />
              </div>
            </td>
            <td
              v-if="$vuetify.display.smAndUp"
              class="holdings-list__table-data"
              data-jest="table-data-amount"
            >
              {{ roundedNumberFormat(props.item.amount, $t("unit")) }}
            </td>
            <td
              v-if="$vuetify.display.mdAndUp"
              class="holdings-list__table-data"
              data-jest="table-data-latest-price"
            >
              {{ numberFormat(props.item.marketValuePerItem, $t("currency")) }}
            </td>
            <td
              v-if="$vuetify.display.mdAndUp"
              class="holdings-list__table-data"
              data-jest="table-data-purchase-price"
            >
              {{ numberFormat(props.item.acquisitionValuePerItem, $t("currency")) }}
            </td>
            <td
              class="holdings-list__table-data"
              data-jest="table-data-value"
            >
              {{ roundedNumberFormat(props.item.marketValue, $t("currency")) }}
            </td>
            <td
              class="holdings-list__table-data holdings-list__value"
              :class="{
                'holdings-list__value--negative': props.item.developmentSinceAcquisition < 0,
              }"
              data-jest="table-data-development"
            >
              {{ fractionToPercentage(props.item.developmentSinceAcquisition) }}
            </td>
          </tr>

          <tr
            v-if="props.index === table.data.length - 1"
            class="holdings-list__table-row holdings-list__table-row--clickable"
            :class="{
              'holdings-list__collapsed-table-row': !isTableExpanded(table.key),
            }"
            @click="expandTable(table.key)"
          >
            <td class="holdings-list__table-data">
              <div class="holdings-list__table-data-container">
                <div
                  v-if="
                    holdingSortBy !== 'sector' && table.key !== 'shares' && table.key !== 'etfs'
                  "
                  class="holdings-list__flag-icon-container"
                >
                  <img
                    class="holdings-list__flag-icon"
                    :src="getFlagUrl(getHoldingIcon(undefined, props.item.market))"
                  >
                </div>
                <div
                  class="holdings-list__table-data-container-item"
                  :class="[
                    {
                      'holdings-list__table-data-container-item--no-left-margin':
                        holdingSortBy === 'sector' || table.key === 'shares' || table.key === 'etfs',
                    },
                  ]"
                >
                  {{ $t("total") }}
                </div>
              </div>
            </td>
            <td
              v-if="$vuetify.display.smAndUp"
              class="holdings-list__table-data"
              data-jest="table-data-total-amount"
            >
              {{
                roundedNumberFormat(
                  table.data.length,
                  table.key === "etfs"
                    ? $t("unit")
                    : $t("portfolio.holdings.holdings-list.companiesUnit"),
                )
              }}
            </td>
            <td
              v-if="$vuetify.display.mdAndUp"
              class="holdings-list__table-data"
              data-jest="table-data-total-latest-price"
            >
              -
            </td>
            <td
              v-if="$vuetify.display.mdAndUp"
              class="holdings-list__table-data"
              data-jest="table-data-total-purchase-price"
            >
              -
            </td>
            <td
              class="holdings-list__table-data"
              data-jest="table-data-market-value"
            >
              {{ numberFormat(table.totalMarketValue, $t("currency")) }}
            </td>
            <td
              data-jest="table-data-total-development"
              class="holdings-list__table-data holdings-list__value"
              :class="{
                'holdings-list__value--negative': table.totalDevelopmentSinceAcquisition < 0,
              }"
            >
              {{ fractionToPercentage(table.totalDevelopmentSinceAcquisition) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-data-table
        v-else
        :headers="getHoldingHeaders()"
        :items="[{}]"
        :mobile-breakpoint="0"
        class="holdings-list"
        :data-jest="`holdings-list-${table.key}`"
      >
        <template #bottom>
          <!-- Hide footer hack, feature request https://github.com/vuetifyjs/vuetify/issues/17651 -->
        </template>
        <template #top>
          <div class="holdings-list__heading-row">
            <div>
              <icon
                v-if="table.icon"
                class="holdings-list__sector-icon"
                :icon="table.icon"
              />
              {{ table.title }}
            </div>
          </div>
        </template>
        <template #body>
          <tr class="holdings-list__table-row">
            <td class="holdings-list__table-data">
              <div class="holdings-list__table-data-container">
                <div
                  v-if="table.market"
                  class="holdings-list__flag-icon-container"
                >
                  <img
                    class="holdings-list__flag-icon"
                    :src="getFlagUrl(getHoldingIcon(undefined, table.market))"
                  >
                </div>
                <div
                  class="holdings-list__table-data-container-item"
                  :class="[
                    {
                      'holdings-list__table-data-container-item--no-left-margin':
                        holdingSortBy === 'sector' || table.key === 'shares' || table.key === 'etfs',
                    },
                  ]"
                >
                  {{ $t("total") }}
                </div>
              </div>
            </td>
            <td
              v-if="$vuetify.display.smAndUp"
              class="holdings-list__table-data"
              data-jest="table-data-total-amount"
            >
              {{ roundedNumberFormat(0, $t("unit")) }}
            </td>
            <td
              v-if="$vuetify.display.mdAndUp"
              class="holdings-list__table-data"
              data-jest="table-data-total-latest-price"
            >
              -
            </td>
            <td
              v-if="$vuetify.display.mdAndUp"
              class="holdings-list__table-data"
              data-jest="table-data-total-purchase-price"
            >
              -
            </td>
            <td
              class="holdings-list__table-data"
              data-jest="table-data-market-value"
            >
              {{ numberFormat(0, $t("currency")) }}
            </td>
            <td
              data-jest="table-data-total-development"
              class="holdings-list__table-data holdings-list__value"
              :class="{
                'holdings-list__value--negative': table.totalDevelopmentSinceAcquisition < 0,
              }"
            >
              {{ fractionToPercentage(0) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <v-data-table
      :headers="[]"
      :items="balanceValue"
      :mobile-breakpoint="0"
      class="holdings-list holdings-list--no-margin-bottom"
      data-jest="holdings-list-balance"
    >
      <template #bottom>
        <!-- Hide footer hack, feature request https://github.com/vuetifyjs/vuetify/issues/17651 -->
      </template>
      <template #top>
        <div
          class="holdings-list__heading-row holdings-list__heading-row--no-hover holdings-list__heading-row--no-click"
        >
          {{ $t("portfolio.holdings.holdings-list.balance") }}
          <InformationButton
            data-jest="button"
            @click="showBalanceSideDialog = true"
          />
        </div>
      </template>
      <template #item="props">
        <tr class="holdings-list__table-data holdings-list__heading-row--no-hover">
          <td>{{ $t("amount") }}</td>
          <td class="holdings-list__table-row-balance--right">
            {{ numberFormat(props.item.value, $t("currency")) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <SideDialog
      v-model="showBalanceSideDialog"
      :title="$t('portfolio.holdings.holdings-list.balanceDialogHeading')"
    >
      <div class="holdings-list__side-dialog">
        <p class="holdings-list__side-dialog--heading">
          {{ $t("portfolio.holdings.holdings-list.balanceInformationHeading1") }}
        </p>
        <p class="holdings-list__side-dialog--text">
          {{ $t("portfolio.holdings.holdings-list.balanceInformation1") }}
        </p>
        <p class="holdings-list__side-dialog--heading">
          {{ $t("portfolio.holdings.holdings-list.balanceInformationHeading2") }}
        </p>
        <p class="holdings-list__side-dialog--text">
          {{ $t("portfolio.holdings.holdings-list.balanceInformation2") }}
        </p>
      </div>
    </SideDialog>
    <DetailedHoldingSideDialog
      v-model="showDetailedHolding"
      :selected-holding="selectedHolding"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DetailedHoldingSideDialog from "./detailed/side-dialog.vue";
import LabelNew from "./label-new.vue";
import type {
  Holding,
  IHolding,
} from "@/clients";
import {
  InstrumentMarket,
  InstrumentType,
  MarketPlace,
  RiskLevel,
  Sector,
} from "@/clients";
import SideDialog from "@/components/dialog/side-dialog.vue";
import InformationButton from "@/components/information-button.vue";
import { sectorIcons } from "@/config/sectors";
import { fractionToPercentage, numberFormat, roundedNumberFormat } from "@/utils/holdings-format";

interface DisplayHoldingData extends IHolding {
  icon: string | undefined;
  marketValuePerItem: number;
  acquisitionValuePerItem: number;
  developmentSinceAcquisition: number;
  [key: string]: any;
}

type HoldingHeaderType =
  | "name"
  | "marketValue"
  | "developmentSinceAcquisition"
  | "amount"
  | "marketValuePerItem"
  | "acquisitionValuePerItem";

interface HoldingHeader {
  title: string;
  key: HoldingHeaderType;
  sortable: boolean;
  class: string;
  align: string;
}

interface Table {
  key: string;
  title: string;
  data: DisplayHoldingData[];
  icon?: string[];
  market?: InstrumentMarket;
  totalMarketValue: number;
  totalDevelopmentSinceAcquisition: number;
}

export default defineComponent({
  components: { LabelNew, SideDialog, InformationButton, DetailedHoldingSideDialog },
  props: {
    holdingSortBy: {
      type: String,
      required: true,
      default: "stock",
    },
  },
  data: (): any => ({
    showBalanceSideDialog: false,
    selectedHolding: undefined,
    showDetailedHolding: false,
    expandedKeys: [] as string[],
  }),
  computed: {
    balanceValue() {
      const { currentHoldingSummary } = this.$store.getters;
      if (
        currentHoldingSummary === undefined
        || currentHoldingSummary.cash === undefined
        || currentHoldingSummary.cash.amount === undefined
      ) {
        return [{ value: "-" }];
      }
      return [
        {
          value: currentHoldingSummary.cash.amount,
        },
      ];
    },
    holdings(): DisplayHoldingData[] {
      const { holdings } = this.$store.getters;
      if (holdings && holdings.length > 0) {
        const formattedHoldings = this.getFormattedHoldings(holdings);
        return formattedHoldings;
      }
      return [];
    },
    tables(): Table[] {
      const res: Table[] = [];
      const etfs = this.holdings.filter(
        (h: DisplayHoldingData) => h.instrumentType === InstrumentType.EtFs,
      );
      const shares = this.holdings.filter(
        (h: DisplayHoldingData) => h.instrumentType === InstrumentType.Shares,
      );
      if (this.holdingSortBy === "stock") {
        res.push({
          key: "shares",
          title: this.$t("stocks"),
          data: shares,
          totalMarketValue: shares.reduce(
            (sum: number, val: DisplayHoldingData) => sum + val.marketValue,
            0,
          ),
          totalDevelopmentSinceAcquisition: this.getDevelopmentSinceAcquisitionFraction(shares),
        });
      } else if (this.holdingSortBy === "market") {
        Object.keys(InstrumentMarket)
          .filter(key => !Number.isNaN(Number(InstrumentMarket[key])))
          .forEach((key: string, value: number) => {
            if (value !== InstrumentMarket.Unknown) {
              const data = shares.filter((h: DisplayHoldingData) => h.market === value);
              res.push({
                key,
                title: this.$t(`markets.${key}`),
                data,
                totalMarketValue: data.reduce(
                  (sum: number, val: DisplayHoldingData) => sum + val.marketValue,
                  0,
                ),
                totalDevelopmentSinceAcquisition: this.getDevelopmentSinceAcquisitionFraction(data),
                market: value,
              });
            }
          });
      } else if (this.holdingSortBy === "sector") {
        Object.keys(Sector)
          .filter(key => !Number.isNaN(Number(Sector[key])))
          .forEach((key: string, value: number) => {
            if (value !== Sector.Undefined) {
              const data = shares.filter((h: DisplayHoldingData) => h.sector === value);
              if (data.length > 0 || shares.length === 0) {
                res.push({
                  key,
                  title: this.$t(`sectors.${Sector[value]}`),
                  data,
                  icon: sectorIcons.get(Sector[value.toString()]),
                  totalMarketValue: data.reduce(
                    (sum: number, val: DisplayHoldingData) => sum + val.marketValue,
                    0,
                  ),
                  totalDevelopmentSinceAcquisition:
                    this.getDevelopmentSinceAcquisitionFraction(data),
                });
              }
            }
          });
        res.sort((o1, o2) => (o1.totalMarketValue > o2.totalMarketValue ? -1 : 1));
      }
      if (
        etfs.length > 0
        || (shares.length === 0 && etfs.length === 0 && this.riskLevel !== RiskLevel.Five)
      ) {
        res.push({
          key: "etfs",
          title: this.$t("bonds"),
          data: etfs,
          totalMarketValue: etfs.reduce(
            (sum: number, val: DisplayHoldingData) => sum + val.marketValue,
            0,
          ),
          totalDevelopmentSinceAcquisition: this.getDevelopmentSinceAcquisitionFraction(etfs),
        });
      }
      return res;
    },
    riskLevel(): RiskLevel {
      const { strategy } = this.$store.state.portfolioStore;
      if (strategy) {
        const { chosenRiskLevel } = strategy;
        return chosenRiskLevel;
      }
      return RiskLevel.Invalid;
    },
  },
  methods: {
    getFlagUrl(flagFileName: string): string {
      return new URL(`/assets/images/flags/${flagFileName}`, import.meta.url).href;
    },
    numberFormat(value: number, currency: string) {
      return numberFormat(value, currency);
    },
    roundedNumberFormat(value: number, unit: string) {
      return roundedNumberFormat(value, unit);
    },
    fractionToPercentage(value: number) {
      return fractionToPercentage(value);
    },
    // User interaction methods.
    getDevelopmentSinceAcquisitionFraction(list: Array<DisplayHoldingData>): number {
      const totalAcquisitionValue = list.reduce(
        (result, currentHolding: DisplayHoldingData) => result + currentHolding.acquisitionValue,
        0,
      );
      const totalMarketValue = list.reduce(
        (result, currentHolding: DisplayHoldingData) => result + currentHolding.marketValue,
        0,
      );
      return totalMarketValue / totalAcquisitionValue - 1;
    },
    getHoldingHeaders(): HoldingHeader[] {
      const allHoldingHeaders = this.getAllHoldingHeaders();
      switch (this.$vuetify.display.name) {
        case "xs": {
          const xsHoldingHeaders = allHoldingHeaders.filter(
            (item: HoldingHeader) =>
              item.key !== "amount"
              && item.key !== "marketValuePerItem"
              && item.key !== "acquisitionValuePerItem",
          );
          return xsHoldingHeaders;
        }
        case "sm": {
          const smHoldingHeaders = allHoldingHeaders.filter(
            (item: HoldingHeader) =>
              item.key !== "marketValuePerItem" && item.key !== "acquisitionValuePerItem",
          );
          return smHoldingHeaders;
        }
        default:
          return allHoldingHeaders;
      }
    },
    getAllHoldingHeaders(): Array<HoldingHeader> {
      return [
        {
          title: this.$t("name"),
          key: "name",
          sortable: true,
          align: "start",
          class: "holdings-list__header-text",
        },
        {
          title: this.$t("amount"),
          key: "amount",
          sortable: true,
          align: "end",
          class: "holdings-list__header-text",
        },
        {
          title: this.$t("portfolio.holdings.holdings-list.pastPrice"),
          key: "marketValuePerItem",
          sortable: true,
          align: "end",
          class: "holdings-list__header-text",
        },
        {
          title: this.$t("portfolio.holdings.holdings-list.purchasePrice"),
          key: "acquisitionValuePerItem",
          sortable: true,
          align: "end",
          class: "holdings-list__header-text",
        },
        {
          title: this.$t("portfolio.holdings.holdings-list.value"),
          key: "marketValue",
          sortable: true,
          align: "end",
          class: "holdings-list__header-text",
        },
        {
          title: this.$t("portfolio.holdings.holdings-list.developmentSinceAcquisition"),
          key: "developmentSinceAcquisition",
          sortable: true,
          align: "end",
          class: "holdings-list__header-text",
        },
      ];
    },
    getFormattedHoldings(holdings: Holding[]): DisplayHoldingData[] {
      return holdings.map((x: IHolding) => ({
        acquisitionDate: x.acquisitionDate,
        acquisitionValue: x.acquisitionValue,
        acquisitionValuePerItem: x.acquisitionValue / x.amount,
        additionalBought: x.additionalBought,
        amount: x.amount,
        asset: x.asset,
        description: x.description,
        descriptionWikiLink: x.descriptionWikiLink,
        developmentSinceAcquisition: x.developmentSinceAcquisition,
        extId: x.extId,
        historicAmount: x.historicAmount,
        icon: this.getHoldingIcon(x.marketPlace, x.market),
        instrumentType: x.instrumentType,
        isin: undefined,
        isNew: x.isNew,
        market: x.market,
        marketPlace: x.marketPlace,
        marketValue: x.marketValue,
        marketValuePerItem: x.marketValue / x.amount,
        mic: undefined,
        name: x.name,
        sector: x.sector,
        subSector: x.subSector,
      }));
    },
    getHoldingIcon(marketPlace: MarketPlace, market: InstrumentMarket): string | undefined {
      const marketPlaceKey = MarketPlace[marketPlace];
      if (marketPlaceKey) {
        return `flag-${marketPlaceKey.toLowerCase()}.svg`;
      }
      const marketKey = InstrumentMarket[market];
      if (marketKey) {
        return `flag-${marketKey.toLowerCase()}.svg`;
      }
      return undefined;
    },
    // Data manipulation methods.
    filterKeys(disallowedKeys: Array<string>, filterObject: DisplayHoldingData): Object {
      const filtered = Object.keys(filterObject)
        .filter((key: string) => !disallowedKeys.includes(key))
        .reduce((object: any, key: string) => {
          object[key] = filterObject[key];
          return object;
        }, {});
      return filtered;
    },
    hasMarketAndMarketPlace(item: DisplayHoldingData): boolean {
      return item.market !== InstrumentMarket.Unknown && item.marketPlace !== MarketPlace.Unknown;
    },
    goToHoldingDetail(holding: any) {
      this.selectedHolding = holding;
      this.showDetailedHolding = true;
    },
    expandTable(key: string) {
      if (this.expandedKeys.includes(key)) {
        this.expandedKeys = [...this.expandedKeys.filter((k: string) => k !== key)];
      } else {
        this.expandedKeys.push(key);
      }
    },
    isTableExpanded(key: string): boolean {
      return this.expandedKeys.includes(key);
    },
  },
});
</script>

<style lang="scss" scoped>
.holdings-table {
  position: relative;
  width: 100%;
}
:deep(.v-data-table-header__sort-icon) {
  visibility: hidden !important;
}
:deep(.v-data-table__th--sorted) {
  font-weight: 500 !important;
  opacity: 1 !important;
}
:deep(.v-data-table__th--sortable) {
  opacity: 0.67;
  &:hover {
    opacity: 1;
  }
}
:deep(.v-data-table__th) {
  font-size: 0.875rem;
}
.holdings-list {
  border-radius: 0.5rem;
  position: relative;
  margin-bottom: 2.125rem;
  box-shadow: 0 0.1875rem 1.25rem rgba(96, 96, 96, 0.06);
  table {
    table-layout: fixed;
    width: 100%;
  }
  &__sector-icon {
    margin-right: 0.275rem;
  }
  :deep(.v-data-table__wrapper) {
    overflow: visible;
  }

  &__value {
    color: $grayish-blue;
    &--negative {
      color: $deep-red;
    }
  }
  &__flag-icon {
    height: 1rem;
  }
  &__flag-icon-container {
    width: 1rem;
    display: flex;
  }
  &__header-text {
    padding: 0 !important;
    color: $nearly-black;
    border-bottom: thin solid #fafafa !important;
    font-size: 0.875rem !important;
    font-family: $heading-font-family;
    font-weight: 300;
  }

  &__collapsed-table-row {
    .holdings-list__table-data {
      background-color: white;
    }
    &:hover {
      .holdings-list__table-data {
        background-color: #eeeeee;
      }
    }
  }
  &__table-row {
    background-color: #fafafa;
    &:nth-child(even) {
      background-color: white;
      position: relative;
    }
    &--clickable {
      cursor: pointer;
    }
    &-balance--right {
      text-align: right;
    }
    &:hover {
      background-color: #eeeeee;
    }
  }
  &__heading-row {
    background-color: white;
    width: 100%;
    cursor: pointer;
    height: 3rem;
    font-family: $heading-font-family;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.875rem;
    border-radius: 0.5rem;
    &:hover {
      background-color: #eeeeee;
    }
    &--no-hover {
      &:hover {
        background-color: inherit !important;
      }
    }
    &--no-click {
      cursor: unset;
    }
  }
  &__table-data {
    padding: 0 0.875rem !important;
    border-bottom: thin solid #fafafa !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 6.375rem;
    font-size: 0.875rem;

    &:not(:first-child) {
      padding-left: 0 !important;
      text-align: right;
    }
    &:nth-child(1) {
      width: 48%;
    }
  }
  &__table-data-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  &__table-data-container-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0.375rem;
    margin-right: 0.625rem;

    &--no-left-margin {
      margin-left: 0;
    }
  }
  &__label-new {
    position: relative;
    @include xsmall-down {
      right: 0;
      width: 2.1rem;
    }
  }
  &__side-dialog {
    padding: 2rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
  &__button-info {
    cursor: pointer;
  }
  &--no-margin-bottom {
    margin-bottom: 0;
  }
}
</style>
