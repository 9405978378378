z-
<template>
  <div class="settings">
    <SectionHeader
      v-show="$vuetify.display.mdAndUp"
      :header="$t('settings')"
      :sub-header="$store.getters.portfolioName || ''"
    >
      <template #icon>
        <icon
          class="settings__header-icon"
          fixed-width
          :icon="['fal', 'cog']"
        />
      </template>
      <template #extra-sub-header>
        <div
          v-if="portfolio.isClosing"
          class="settings__text-wrapper"
        >
          <span class="settings__status"> {{ $t("closingPortfolio") }}</span>
        </div>
      </template>
      <HeaderButton
        :icon="['fal', 'times']"
        :input-value="true"
        :tooltip-text="$t('close')"
        @click="closeSettings"
      />
    </SectionHeader>
    <div class="settings__cards">
      <v-card
        v-if="!isPension && legalEntityApproved"
        class="settings__savings-card"
      >
        <Savings
          ref="savings"
          :disabled="portfolio.isClosing"
          @change-account="(event) => openAgreementsDialog('change-account')"
          @change-savings="(event) => openSignDialog('change-savings')"
        />
      </v-card>
      <v-card class="settings__backcast-card">
        <PortfolioPerformance />
      </v-card>
      <v-card class="settings__strategy-card">
        <EditStrategy
          :disabled="portfolio.isClosing"
          @save="(area) => openAgreementsDialog(area)"
        />
      </v-card>
      <v-card class="settings__esg-card">
        <EsgSettings @save="(area) => openAgreementsDialog(area)" />
      </v-card>
      <v-card
        class="settings__excluded-companies-card"
        :class="[
          { 'settings__excluded-companies-card--pension': isPension },
        ]"
      >
        <ExcludedCompanies
          :disabled="portfolio.isClosing"
          @save="(event) => openAgreementsDialog('excludedCompanies')"
        />
      </v-card>
      <v-card
        v-if="isPension"
        class="settings__risk-profile-card"
      >
        <RiskProfile
          ref="riskProfile"
          :disabled="portfolio.isClosing"
          @save="(event) => openAgreementsDialog('riskProfile')"
        />
      </v-card>
      <v-card
        v-else
        class="settings__risk-level-card"
      >
        <RiskLevel
          ref="riskLevel"
          :disabled="portfolio.isClosing"
          @save="(event) => openAgreementsDialog('riskLevel')"
        />
      </v-card>
      <v-card
        v-if="isPension"
        class="settings__pension-plan"
      >
        <PensionPlan ref="pension-plan" />
      </v-card>
      <v-card
        v-if="!isPension"
        class="settings__timetowithdraw-card"
      >
        <TimeToWithdraw
          ref="timeToWithdraw"
          :disabled="portfolio.isClosing"
          @save="(event) => openAgreementsDialog('timeToWithdraw')"
        />
      </v-card>
    </div>
    <WarnDialog
      :model-value="showWarningDialog"
      @cancel="showWarningDialog = false"
      @discard="closeSettingsWithoutWarning"
    />
    <UpdateAgreementsDialog
      v-if="showUpdateAgreementsDialog"
      :model-value="showUpdateAgreementsDialog"
      :setting-to-save="settingToSave"
      :update-settings-error="saveSettingsError"
      :signing-complete="signComplete"
      :sign-location="SignLocation.PORTFOLIO_SETTINGS"
      @update:model-value="showUpdateAgreementsDialog = false"
      @prepare="handleAgreementDialogPrepare"
      @save="handleAgreementDialogSave"
      @cancel="handleAgreementDialogCancel"
      @agreements="showAgreementsDialog = true"
    />
    <SignDialog
      v-if="showSignDialog"
      :sign-complete="signComplete"
      :model-value="showSignDialog"
      :setting-to-save="settingToSave"
      :update-settings-error="saveSettingsError"
      @update:model-value="showSignDialog = false"
      @prepare="handleAgreementDialogPrepare"
      @save="handleAgreementDialogSave"
      @cancel="handleAgreementDialogCancel"
      @agreements="showAgreementsDialog = true"
    >
      <div>
        <p class="settings__sign-dialog-body">
          {{ $t("components.sign-dialog.completionBody") }}
        </p>
        <div>
          <p
            v-if="!couldUpdateMonthlySavingsThisMonth"
            class="settings__sign-dialog-body"
          >
            <span class="settings__sign-dialog-body--bold">{{
              $t("portfolio-settings.savings.obs")
            }}</span>
            {{ $t("portfolio-settings.savings.updateNextMonth") }}
          </p>
        </div>
      </div>
    </SignDialog>
    <AgreementsSideDialog
      v-if="showAgreementsDialog"
      v-model="showAgreementsDialog"
      :preview-mode="true"
      :portfolio="editedPortfolio"
      :use-prepared-documents="true"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import PensionPlan from "../portfolio/pension-plan.vue";
import Savings from "./savings.vue";
import EsgSettings from "./esg/esg-settings.vue";
import EditStrategy from "./strategy/strategy.vue";
import RiskLevel from "./risk-level.vue";
import RiskProfile from "./risk-profile.vue";
import PortfolioPerformance from "./portfolio-performance.vue";
import TimeToWithdraw from "./strategy/time-to-withdraw.vue";
import ExcludedCompanies from "./exluded-companies/excluded-companies.vue";
import SectionHeader from "@/components/section-header.vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { NavigationMutation } from "@/store/navigation/mutations";
import WarnDialog from "@/components/unsaved-edits-dialog.vue";
import UpdateAgreementsDialog from "@/components/update-agreements-dialog.vue";
import HeaderButton from "@/components/header-button.vue";
import { PortfolioAction } from "@/store/portfolio/actions";
// TODO: Move this file
import SignDialog from "@/components/sign-dialog.vue";
import type {
  PortfolioBase,
  UpdatePortfolioSettingsResult,
} from "@/clients";
import {
  ApplicationUser,
  PdfType,
  PortfolioSettings,
  Strategy,
} from "@/clients";
import { UserMutation } from "@/store/user/mutations";

import { BankIdMutation } from "@/store/bankid/mutations";
import AgreementsSideDialog from "@/components/preview-agreements-side-dialog.vue";
import { UserAction } from "@/store/user/action-definitions";

import { SignLocation } from "@/store/bankid/types";

const EDITED_STRATEGY_KEY = "EDITED_STRATEGY";
const EDITED_PORTFOLIO_SETTINGS_KEY = "EDITED_PORTFOLIO_SETTINGS";
const SETTINGS_TO_SAVE_KEY = "SETTINGS_TO_SAVE";

export default defineComponent({
  components: {
    PortfolioPerformance,
    SectionHeader,
    EditStrategy,
    Savings,
    RiskLevel,
    RiskProfile,
    WarnDialog,
    TimeToWithdraw,
    HeaderButton,
    UpdateAgreementsDialog,
    AgreementsSideDialog,
    SignDialog,
    ExcludedCompanies,
    PensionPlan,
    EsgSettings,
  },
  beforeRouteLeave(to, from, next) {
    this.nextRoute = to;
    if (this.hasUnsavedEdits && !this.discardEdits) {
      this.showWarningDialog = true;
      // Abort the route change
      next(false);
    }
 else {
      // Let the user continue
      next();
    }
  },
  data: () => ({
    showWarningDialog: false,
    discardEdits: false,
    nextRoute: {},
    showDialog: false,
    showUpdateAgreementsDialog: false,
    showSignDialog: false,
    settingToSave: "",
    showAgreementsDialog: false,
    saveSettingsFailed: false,
    signComplete: false,
    SignLocation,
    couldUpdateMonthlySavingsThisMonth: false,
  }),
  head() {
    return {
      title: this.$t("settings"),
    };
  },
  computed: {
    ...mapGetters([
      "hasUnsavedEdits",
      "editedPortfolio",
      "isPension",
      "isCompletingSignatureSuccess",
    ]),
    portfolio(): PortfolioBase {
      return this.$store.state.portfolioStore.activePortfolioBase;
    },
    saveSettingsError(): boolean {
      return this.$store.state.portfolioStore.saveSettingsError;
    },
    legalEntityApproved(): boolean | undefined {
      if (this.$store.state.userStore.currentLegalEntity) {
        return this.$store.state.userStore.currentLegalEntity.passedIsApprovedCheck;
      }
      return false;
    },
  },
  mounted() {
    this.$store.commit(NavigationMutation.setBackRoute, {
      name: "portfolio",
      params: { id: this.$route.params.id as string },
    });
    this.$store.commit(NavigationMutation.setMobileHeading, this.$t("settings"));
    this.$store.commit(PortfolioMutation.resetStrategy);
    this.$store.commit(PortfolioMutation.resetPortfolioSettings);

    this.$store.commit(PortfolioMutation.resetUnsavedEdits);
    if (this.$store.getters.isCompletingSignatureSuccess(SignLocation.PORTFOLIO_SETTINGS)) {
      this.settingToSave = sessionStorage.getItem(SETTINGS_TO_SAVE_KEY)!;
      if (this.settingToSave === "change-savings") {
        this.$store.commit(
          PortfolioMutation.setEditedPortfolioSettings,
          PortfolioSettings.fromJS(
            JSON.parse(sessionStorage.getItem(EDITED_PORTFOLIO_SETTINGS_KEY)!),
          ),
        );
        this.showSignDialog = true;
      } else {
        this.$store.commit(
          PortfolioMutation.setEditedStrategy,
          Strategy.fromJS(JSON.parse(sessionStorage.getItem(EDITED_STRATEGY_KEY)!)),
        );
        this.showUpdateAgreementsDialog = true;
      }
      this.signComplete = true;

      this.$store.commit(BankIdMutation.completeRedirectSignature);
    } else if (this.$store.getters.isCompletingSignatureError(SignLocation.PORTFOLIO_SETTINGS)) {
      this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("signError"));
      this.signComplete = false;
      this.$store.commit(BankIdMutation.completeRedirectSignature);
    }
  },
  methods: {
    closeSettingsWithoutWarning(): void {
      this.discardEdits = true;
      this.$router.push(this.nextRoute);
    },
    closeSettings(): void {
      this.$router.push({ name: "portfolio", params: { id: this.$route.params.id as string } });
    },
    openAgreementsDialog(type: string) {
      sessionStorage.setItem(SETTINGS_TO_SAVE_KEY, type);
      if (type === "change-account") {
        this.$store.commit(UserMutation.setAgreementsToSign, [PdfType.Autogiro]);
      } else {
        this.$store.commit(UserMutation.setAgreementsToSign, [PdfType.PortfolioInvestmentStrategy]);
      }

      sessionStorage.setItem(
        EDITED_STRATEGY_KEY,
        JSON.stringify(this.$store.state.portfolioStore.editedStrategy),
      );

      this.showUpdateAgreementsDialog = true;
      this.settingToSave = type;
    },
    openSignDialog(type: string) {
      sessionStorage.setItem(
        EDITED_PORTFOLIO_SETTINGS_KEY,
        JSON.stringify(this.$store.state.portfolioStore.editedPortfolioSettings),
      );
      sessionStorage.setItem(SETTINGS_TO_SAVE_KEY, type);
      this.showSignDialog = true;
      this.settingToSave = type;
    },
    async saveRiskLevel() {
      await this.$store.dispatch(PortfolioAction.saveEditedRiskLevel);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    async saveRiskProfile() {
      await this.$store.dispatch(PortfolioAction.saveEditedRiskProfile);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    async saveEthicalChoices() {
      await this.$store.dispatch(PortfolioAction.saveEditedDiscardedSectors);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    async saveFocus() {
      await this.$store.dispatch(PortfolioAction.saveEditedFocus);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    async saveEsg() {
      await this.$store.dispatch(PortfolioAction.saveEditedEsgFilters);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    async saveMarket() {
      await this.$store.dispatch(PortfolioAction.saveEditedMarket);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    async saveTimeToWithdraw() {
      await this.$store.dispatch(PortfolioAction.saveEditedTimeToWithdraw);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    async saveAccount() {
      await (this.$refs.savings as any).save();

      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    async saveExcludedCompanies() {
      await this.$store.dispatch(PortfolioAction.saveEditedExcludedCompanies);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    resetRiskLevel() {
      (this.$refs.riskLevel as any).reset();
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    resetRiskProfile() {
      (this.$refs.riskProfile as any).reset();
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    resetEthicalChoices() {
      this.$store.commit(PortfolioMutation.resetDiscardedSectors);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    resetFocus() {
      this.$store.commit(PortfolioMutation.resetFocus);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    resetMarket() {
      this.$store.commit(PortfolioMutation.resetMarket);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    resetEsg() {
      this.$store.commit(PortfolioMutation.resetEsgFilters);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    resetTimeToWithdraw() {
      (this.$refs.timeToWithdraw as any).reset();
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    resetAccount() {
      this.$store.commit(
        PortfolioMutation.setEditedPortfolioSettings,
        this.$store.state.portfolioStore.portfolioSettings,
      );
      (this.$refs.savings as any).resetAllInputs();
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    resetExcludedCompanies() {
      this.$store.commit(PortfolioMutation.resetExcludedCompanies);
      this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
    },
    closeAgreementDialog() {
      this.showUpdateAgreementsDialog = false;
    },
    async handleAgreementDialogSave(event: string, resolve: () => void) {
      this.signComplete = false;
      await this.handleAgreementDialogEvent("save", event);
      resolve();
    },
    async handleAgreementDialogPrepare(event: string, resolve: () => void) {
      await this.handleAgreementDialogEvent("prepare", event);
      resolve();
    },
    handleAgreementDialogCancel(event: string) {
      this.signComplete = false;
      this.handleAgreementDialogEvent("cancel", event);
    },
    async handleAgreementDialogEvent(type: string, event: string) {
      if (type === "prepare") {
        switch (event) {
          case "riskLevel":
            await this.saveRiskLevel();
            break;
          case "riskProfile":
            await this.saveRiskProfile();
            break;
          case "ethicalChoices":
            await this.saveEthicalChoices();
            break;
          case "focus":
            await this.saveFocus();
            break;
          case "market":
            await this.saveMarket();
            break;
          case "esg":
            await this.saveEsg();
            break;
          case "timeToWithdraw":
            await this.saveTimeToWithdraw();
            break;
          case "change-account":
            await this.saveAccount();
            break;
          case "change-savings":
            await this.saveAccount();
            break;
          case "excludedCompanies":
            await this.saveExcludedCompanies();
            break;
          default:
            break;
        }
      } else if (type === "save") {
        if (event === "change-account" || event === "change-savings") {
          const result: UpdatePortfolioSettingsResult = await this.$store.dispatch(
            PortfolioAction.completeUpdatePortfolioSettings,
          );
          this.couldUpdateMonthlySavingsThisMonth = result.couldUpdateMonthlySavingsThisMonth;
          (this.$refs.savings as any).completedUpdate();
        } else {
          await this.$store.dispatch(PortfolioAction.completeUpdateStrategy);
        }
      } else if (type === "cancel") {
        this.showUpdateAgreementsDialog = false;
        this.showSignDialog = false;
        this.$store.commit(PortfolioMutation.setSaveSettingsError, false);
        switch (event) {
          case "riskLevel":
            this.resetRiskLevel();
            break;
          case "riskProfile":
            this.resetRiskProfile();
            break;
          case "sustainability":
            this.resetEthicalChoices();
            break;
          case "focus":
            this.resetFocus();
            break;
          case "market":
            this.resetMarket();
            break;
          case "esg":
            this.resetEsg();
            break;
          case "timeToWithdraw":
            this.resetTimeToWithdraw();
            break;
          case "change-account":
            this.resetAccount();
            break;
          case "change-savings":
            this.resetAccount();
            break;
          case "excludedCompanies":
            this.resetExcludedCompanies();
            break;
          default:
            break;
        }
        this.$store.commit(BankIdMutation.resetBankId);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.settings {
  max-width: 71rem;
  width: 100%;
  &__header-icon {
    color: $nearly-black;
    font-size: 1.5rem;
  }
  &__sign-dialog-body {
    font-family: $heading-font-family;
    font-size: 1rem;
    font-weight: 300;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;
    &--bold {
      font-weight: 600;
    }
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
    width: 100%;
    @include small-up {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;
      grid-template-rows: auto auto 1fr 1fr;
    }
    @include medium-up {
      margin-top: 1rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto auto;
      grid-row-gap: 1.5rem;
    }
    @include large-up {
      grid-column-gap: 1.5rem;
    }
  }
  &__timetowithdraw-card {
    max-height: 7rem;
    @include small-up {
      grid-column: 2;
    }
  }
  &__backcast-card {
    @include small-up {
      grid-row: 1;
      grid-column: 1;
    }
    @include medium-up {
      grid-column: 1;
      grid-row: 1/2;
    }
  }
  &__strategy-card {
    @include small-up {
      grid-column: 1;
      grid-row: 2/3;
    }
  }
  &__esg-card {
    @include small-up {
      grid-row: 1/3;
      grid-column: 2;
    }
  }
  &__excluded-companies-card {
    @include small-up {
      grid-column: 2;
    }
    &--pension {
      @include medium-up {
        grid-column: 2;
        grid-row: 4/5;
      }
    }
  }
  &__risk-profile-card {
    @include small-up {
      grid-column: 2;
      grid-row: 3;
    }
  }
  &__risk-level-card {
    @include small-up {
      grid-column: 2;
      grid-row: 3;
    }
  }
  &__savings-card {
    @include small-up {
      grid-column: 1;
      grid-row: 3/6;
    }
  }
  &__pension-plan {
    padding: 1.25rem;
    @include small-up {
      grid-column: 1;
      grid-row: 3;
    }
  }
  &__status {
    color: red;
  }
  &__text-wrapper {
    display: flex;
    align-self: center;
    align-items: baseline;
    padding: 1.125rem;
  }
}
</style>
