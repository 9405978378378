<template>
  <div class="taxable-country">
    <single-choice-template
      :alternatives="alternatives"
      :disabled="!isFormValid"
      @click="goToNext"
    >
      <question-text-section
        :heading="$t('create-portfolio.questions.aml.taxable-country.heading')"
        :body="$t('create-portfolio.questions.aml.taxable-country.body')"
      />
      <!-- @submit.prevent to keep the page from reloading, see https://vuejs.org/v2/guide/events.html -->
      <div class="taxable-country__citizenship-wrapper">
        <v-form
          ref="form"
          v-model="isFormValid"
          class="taxable-country__form"
          @submit.prevent="goToNext"
        >
          <country-selector
            v-model="taxableCountry"
            style="margin-top: 2rem"
            class="taxable-country__selector"
            exclude-sweden
            exclude-us
          />
          <v-text-field
            v-model="taxIdentificationNumber"
            style="margin-top: 2rem"
            variant="underlined"
            :label="$t('foreignTaxIdentificationNumber')"
            :rules="[rules.required]"
            name="taxIdentificationNumber"
            data-cy="taxIdentificationNumber"
            validate-on="blur"
            @update:model-value="validateForm"
          />
        </v-form>
      </div>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import CountrySelector from "@/components/country-selector.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    QuestionTextSection,
    CountrySelector
  },
  extends: Question,
  data() {
    return {
      taxIdentificationNumber: undefined,
      isFormValid: true,
      rules: {
        required: (value: string) => !!value || this.$t("required")
      },
      taxableCountry: undefined
    };
  },
  computed: {
    alternatives(): any {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    async validateForm() {
      (await (this.$refs.form as any).validate()).valid;
    },
    async goToNext() {
      if ((await (this.$refs.form as any).validate()).valid) {
        this.$store.commit(SignupMutation.setTaxIdentificationNumber, this.taxIdentificationNumber);
        this.$store.commit(SignupMutation.setTaxableOtherCountry, this.taxableCountry);
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.taxable-country {
  height: 100%;
  width: 100%;

  &__form {
    .v-input {
      font-size: 1.25rem;
      border-radius: 0.5rem;
    }
    :deep(input) {
      line-height: 1.25;
    }
    .v-input--is-focused {
      :deep(.v-input__slot) {
        box-shadow: none !important;
      }
    }
  }
  &__citizenship-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 21.25rem;
    margin: 0 auto;
  }
}
</style>
