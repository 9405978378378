import { GetterTree } from "vuex";

import { BankIdState, SignLocation } from "./types";
import { RootState } from "../../types";

export const getters: GetterTree<BankIdState, RootState> = {
  isCompletingSignatureSuccess: (state) => (location: SignLocation) => {
    const { signLocation, redirectSignStatus } = state;
    return signLocation === location && redirectSignStatus === "success";
  },
  isCompletingSignatureError: (state) => (location: SignLocation) => {
    const { signLocation, redirectSignStatus } = state;
    return signLocation === location && redirectSignStatus === "error";
  },
  isCompletingSignature: (state) => (location: SignLocation) => {
    const { signLocation, redirectSignStatus } = state;
    return signLocation === location && redirectSignStatus;
  }
};
