<template>
  <div class="information">
    <div
      v-if="hasImage"
      class="information__image-wrapper"
      :style="style"
    >
      <slot />
    </div>
    <div class="information__text">
      <h1
        v-if="heading"
        class="information__heading"
        data-jest="heading"
      >
        {{ heading }}
      </h1>
      <p
        v-if="body"
        :class="['information__body', { 'information__body--left-mobile': leftAlignBodyMobile }]"
      >
        {{ body }}
      </p>
      <p
        v-if="body2"
        :class="['information__body', { 'information__body--left-mobile': leftAlignBodyMobile }]"
      >
        {{ body2 }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    heading: {
      type: String,
      default: undefined
    },
    body: {
      type: String,
      default: undefined
    },
    body2: {
      type: String,
      default: undefined
    },
    imageSource: {
      type: String,
      default: undefined
    },
    svgPath: {
      type: String,
      default: undefined
    },
    leftAlignBodyMobile: {
      type: Boolean,
      default: false
    },
    imagePadding: {
      type: String
    }
  },
  computed: {
    style() {
      const style = { fill: this.primaryColor };
      if (this.imagePadding) {
        (style as any).padding = this.imagePadding;
      }
      return style;
    },
    hasImage(): boolean {
      return this.$slots.default !== undefined;
    },
    primaryColor(): string {
      return this.$store.state.signupStore.primaryColor;
    }
  }
});
</script>

<style lang="scss" scoped>
.information__image-wrapper :deep(svg) {
  width: 100%;
  height: auto;
}
.information__image-wrapper :deep(svg path) {
  fill: inherit;
}
.information {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 21.25rem;
  margin: 0 auto;
  @include medium-up {
    max-width: 44.5rem;
  }
  &__image {
    width: 100%;
    height: 100%;
    text-align: center;
    height: 4.0625rem;
  }
  &__image-wrapper {
    padding: 1.125rem;
    background-color: white;
    border-radius: 50%;
    margin: 0 auto 6vh auto;
    height: 6.5rem;
    width: 6.5rem;
    box-shadow: 0 0.125rem 1rem rgba(0, 0, 0, 0.08);
    @include medium-up {
      height: 6rem;
      width: 6rem;
      padding: 1.625rem;
    }
  }
  &__invisible-image-wrapper {
    padding: 0rem;
    border-radius: 50%;
    margin: 0 auto 6vh auto;
    height: 2rem;
    width: 2rem;
    @include medium-up {
      height: 2rem;
      width: 2rem;
      padding: 0rem;
    }
  }
  &__body {
    font-size: 1rem;
    font-weight: 300;
    margin: 1rem 0 0 0;
    text-align: center;
    &--left-mobile {
      text-align: left;
    }
    @include medium-up {
      text-align: center;
    }
  }
  &__heading {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
    @include medium-up {
      font-size: 1.5rem;
    }
  }
}
</style>
