import type { GetterTree } from "vuex";
import type { AdminState } from "./types";
import type {
  AdminLegalEntity,
  AdminLegalEntityUser,
  AdminPortfolioSummary,
  ApplicationUser,
  KnowYourCustomerAnswers,
  LegalEntity,
  Message,
  PortfolioSettings,
  Strategy,
  TradingDay,
  TransactionEvent,
} from "@/clients";
import type { RootState } from "@/types";

export const getters: GetterTree<AdminState, RootState> = {
  adminLegalEntities(state): AdminLegalEntity[] | undefined {
    return state.legalEntities;
  },
  activeUser(state): ApplicationUser | undefined {
    return state.activeUser;
  },
  activeLegalEntity(state): LegalEntity | undefined {
    return state.activeLegalEntity;
  },
  activePortfolioSettings(state): PortfolioSettings[] | undefined {
    return state.activePortfolioSettings;
  },
  activeStrategies(state): Strategy[] | undefined {
    return state.activeStrategies;
  },
  loadingUsers(state): boolean | undefined {
    return state.loadingUsers;
  },
  loadingWithdrawals(state): boolean | undefined {
    return state.loadingWithdrawals;
  },
  activeKyc(state): KnowYourCustomerAnswers | undefined {
    if (state.activeLegalEntity) {
      return state.activeLegalEntity.knowYourCustomerAnswers;
    }
    return undefined;
  },
  activePortfolioSummaries(state): AdminPortfolioSummary[] | undefined {
    return state.activePortfolioSummaries;
  },
  tradingDays(state): TradingDay[] {
    return state.tradingDays;
  },
  activePortfolioTransactions(state): TransactionEvent[] | undefined {
    return state.activePortfolioTransactions;
  },
  activeMessages(state): Message[] | undefined {
    return state.activeMessages;
  },
  fullNameAdmin(state): string {
    if (state.activeLegalEntity) {
      return `${state.activeLegalEntity.firstName} ${state.activeLegalEntity.lastName}`;
    }
    return "-";
  },
};
