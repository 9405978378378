<template>
  <v-list class="settings-list">
    <v-list-item
      v-for="(item, index) in listItems.filter((x) => !x.excluded)"
      :key="index"
      :data-cy="`settingsItem${index}`"
      :disabled="item.disabled"
      @click="item.callback"
    >
      <template #prepend>
        <icon
          fixed-width
          :icon="item.icon"
          size="lg"
          style="margin-right: 0.5rem"
        />
      </template>
      <v-list-item-title>{{ item.text }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import type { PortfolioBase } from "@/clients";

interface ListItem {
  icon: string[];
  text: string;
  callback: () => void;
  disabled?: boolean;
  excluded?: boolean;
}

export default defineComponent({
  emits: [
    "click:settings",
    "click:transactions",
    "click:rename",
    "click:agreements",
    "click:withdraw",
    "click:delete",
    "click:reports",
  ],
  computed: {
    ...mapGetters(["isPension"]),
    activePortfolio(): PortfolioBase {
      return this.$store.state.portfolioStore.activePortfolioBase;
    },
    listItems(): ListItem[] {
      const items = [
        {
          icon: ["fal", "cog"],
          text: this.$t("settings"),
          callback: this.clickSettings,
        },
        {
          icon: ["fal", "receipt"],
          text: this.$t("transactions"),
          callback: this.clickTransactions,
        },
        {
          icon: ["fal", "file-alt"],
          text: this.$t("reports"),
          callback: this.clickReports,
        },
        {
          icon: ["fal", "pen"],
          text: this.$t("rename"),
          callback: this.clickRename,
        },
        {
          icon: ["fal", "file-signature"],
          text: this.$t("agreementsTitle"),
          callback: this.clickAgreements,
        },
        {
          icon: ["fal", "money-bill-wave"],
          text: this.$t("withdraw"),
          callback: this.clickWithdraw,
          excluded: this.isPension,
          disabled: this.activePortfolio.isClosing,
        },
        {
          icon: ["fal", "trash-alt"],
          text: this.$t("removePortfolio"),
          callback: this.clickDeletePortfolio,
          excluded: this.isPension,
          disabled: this.activePortfolio.isClosing,
        },
      ];
      return items;
    },
    hasMultiplePortfolios(): boolean {
      if (this.$store.state.navigationStore.portfolios) {
        return this.$store.state.navigationStore.portfolios.length > 1;
      }
      return false;
    },
  },
  methods: {
    clickSettings() {
      this.$emit("click:settings");
    },
    clickTransactions() {
      this.$emit("click:transactions");
    },
    clickRename() {
      this.$emit("click:rename");
    },
    clickAgreements() {
      this.$emit("click:agreements");
    },
    clickWithdraw() {
      this.$emit("click:withdraw");
    },
    clickDeletePortfolio() {
      this.$emit("click:delete");
    },
    clickReports() {
      this.$emit("click:reports");
    },
  },
});
</script>

<style lang="scss" scoped>
.settings-list {
  min-width: 15.625rem;
}

:deep(.v-list-item) {
  padding: 0 1.75rem;
}
</style>
