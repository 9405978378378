<template>
  <Transition
    name="fade-header"
    mode="out-in"
  >
    <slot></slot>
    <!-- pass down slot content -->
  </Transition>
</template>

<style lang="scss">
.fade-header-enter-active {
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: all 0.2s;
}
.fade-header-leave-active {
  transition: all 0.2s;
}
.fade-header-enter-from,
.fade-header-leave-to {
  opacity: 0;
}
</style>
