import type { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from "vue-router";
import store from "@/store";

import { CURRENT_PENSION_PATH_KEY } from "@/config/pension";

const Intro = () => import("../views/pension/find-pensions/intro.vue");
const Move = () => import("../views/pension/find-pensions/move.vue");

const Pension = () => import("../views/pension/pension.vue");
const Where = () => import("../views/pension/find-pensions/where.vue");
const Search = () => import("../views/pension/find-pensions/search.vue");
const SearchResult = () => import("../views/pension/find-pensions/search-result.vue");
const ActivePayment = () => import("../views/pension/find-pensions/active-payment.vue");
const ActivePaymentWarning = () =>
  import("../views/pension/find-pensions/active-payment-warning.vue");
const MergePensions = () => import("../views/pension/find-pensions/merge-pensions.vue");
const SoleOwner = () => import("../views/pension/find-pensions/sole-owner.vue");
const SoleOwnerConfirm = () => import("../views/pension/find-pensions/sole-owner-confirm.vue");
const InsuranceEmail = () => import("../views/pension/find-pensions/insurance-email.vue");
const CompleteInfo = () => import("../views/pension/find-pensions/complete-info.vue");
const OrganizationNumber = () => import("../views/pension/find-pensions/organization-number.vue");

const InsuranceIntro = () => import("../views/pension/insurance/insurance-intro.vue");
const Payout = () => import("../views/pension/insurance/payout.vue");
const PayoutWarning = () => import("../views/pension/insurance/payout-warning.vue");
const PayoutTime = () => import("../views/pension/insurance/payout-time.vue");
const Summary = () => import("../views/pension/insurance/summary.vue");
const SummaryAgreements = () => import("../views/pension/insurance/summary-agreements.vue");
const Done = () => import("../views/pension/insurance/done.vue");

const EconomyIntro = () => import("../views/pension/economy/intro.vue");
const PrivateSavings = () => import("../views/pension/economy/private-savings.vue");
const OtherPension = () => import("../views/pension/economy/other-pension.vue");

const PreferencesIntroduction = () => import("../views/pension/preferences/introduction.vue");
const Sectors = () => import("../views/pension/preferences/sectors.vue");
const MarketFocus = () => import("../views/pension/preferences/market-focus.vue");
const Focus = () => import("../views/pension/preferences/focus.vue");

const Sustainability = () =>
  import("../views/common-signup/questions/preferences/sustainability.vue");
const SustainableInvestment = () =>
  import("../views/common-signup/questions/preferences/sustainable-investment.vue");
const SustainableMethod = () =>
  import("../views/common-signup/questions/preferences/sustainable-method.vue");
const SustainableInfo = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice-info.vue");
const SustainableChoice = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice.vue");
const SustainableChoiceVerify = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice-verify.vue");
const NegativeImpact = () => import("../views/common-signup/questions/preferences/negative-impact.vue");

const Proposal = () => import("../views/pension/savings-proposal/proposal.vue");
const Profile = () => import("../views/pension/savings-proposal/profile.vue");

const SummaryAgreementsSigmastocks = () =>
  import("../views/pension/summary/summary-agreements.vue");
const DoneSigmastocks = () => import("../views/pension/summary/done.vue");

const SignSummary = () => import("../views/pension/sign/summary.vue");

const SummarySign = () => import("../views/pension/sign/summary-sign.vue");
const MoveStarted = () => import("../views/pension/sign/move-started.vue");
const Congratulations = () => import("../views/pension/sign/congratulations.vue");

const LawAbout = () => import("../views/create-portfolio/questions/aml/law-about.vue");
const Occupation = () => import("../views/create-portfolio/questions/aml/occupation.vue");
const Interval = () => import("../views/create-portfolio/questions/aml/interval.vue");
const WithdrawalInterval = () =>
  import("../views/create-portfolio/questions/aml/withdrawal-interval.vue");
const WithdrawalIntervalInformation = () =>
  import("../views/create-portfolio/questions/aml/withdrawal-interval-information.vue");
const Amount = () => import("../views/create-portfolio/questions/aml/amount.vue");
const Purpose = () => import("../views/create-portfolio/questions/aml/purpose.vue");
const InvestmentBackground = () =>
  import("../views/create-portfolio/questions/aml/investment-background.vue");

const PoliticallyExposed = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed.vue");
const PoliticallyExposedAssociated = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-associated.vue");
const PoliticallyExposedAssociatedName = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-associated-name.vue");
const PoliticallyExposedAssociatedRelationshipRelative = () =>
  import(
    "../views/create-portfolio/questions/aml/politically-exposed-associated-relationship-relative.vue"
  );
const PoliticallyExposedAssociatedRelationshipCoworker = () =>
  import(
    "../views/create-portfolio/questions/aml/politically-exposed-associated-relationship-coworker.vue"
  );
const PoliticallyExposedAssociatedOrganization = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-associated-organization.vue");

const PoliticallyExposedCountry = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-country.vue");
const PoliticallyExposedOngoing = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-ongoing.vue");
const PoliticallyExposedSavings = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-savings.vue");
const PoliticallyExposedTitle = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-title.vue");
const PoliticallyExposedTerminated = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-terminated.vue");

const YourMoney = () => import("@/views/common-signup/questions/aml/your-money.vue");

const EconomyIntroduction = () =>
  import("../views/create-portfolio/questions/economy/introduction.vue");
const TimeToWithdraw = () =>
  import("../views/create-portfolio/questions/economy/time-to-withdraw.vue");
const TimeToWithdrawWarning = () =>
  import("../views/create-portfolio/questions/economy/time-to-withdraw-warning.vue");
const Income = () => import("../views/create-portfolio/questions/economy/income.vue");
const MoneyLeft = () => import("../views/create-portfolio/questions/economy/money-left.vue");
const ExpensesWarning = () =>
  import("../views/create-portfolio/questions/economy/expenses-warning.vue");
const MonthlyRemainder = () => import("../views/create-portfolio/questions/economy/remainder.vue");
const CurrentSavings = () =>
  import("../views/create-portfolio/questions/economy/current-savings.vue");
const CurrentSavingsWarning = () =>
  import("../views/create-portfolio/questions/economy/current-savings-warning.vue");

const BoundCapital = () => import("../views/create-portfolio/questions/economy/bound-capital.vue");
const Loans = () => import("../views/create-portfolio/questions/economy/loans.vue");
const LoansWarning = () => import("../views/create-portfolio/questions/economy/loans-warning.vue");
const LoanedAmount = () => import("../views/create-portfolio/questions/economy/loaned-amount.vue");
const LoanedAmountWarning = () =>
  import("../views/create-portfolio/questions/economy/loaned-amount-warning.vue");
const SwedishCitizenship = () =>
  import("../views/create-portfolio/questions/aml/swedish-citizenship.vue");
const CitizenShipDenial = () =>
  import("../views/create-portfolio/questions/aml/citizenship-denial.vue");
const TaxLiableUsa = () => import("../views/create-portfolio/questions/aml/tax-liable-usa.vue");
const TaxLiableDenial = () =>
  import("../views/create-portfolio/questions/aml/tax-liable-denial.vue");
const TaxLiableOther = () => import("../views/create-portfolio/questions/aml/tax-liable-other.vue");

const Citizenship = () => import("../views/create-portfolio/questions/aml/citizenship.vue");
const SwedishTaxResidence = () =>
  import("../views/create-portfolio/questions/aml/swedish-tax-residence.vue");
const SwedishTaxResidenceDenial = () =>
  import("../views/create-portfolio/questions/aml/swedish-tax-residence-denial.vue");
const TaxableCountry = () => import("../views/create-portfolio/questions/aml/taxable-country.vue");

const PreviousExperience = () =>
  import("../views/create-portfolio/questions/risk/previous-experience.vue");
const PreviousExperienceAnswer = () =>
  import("../views/create-portfolio/questions/risk/previous-experience-answer.vue");
const StockInformation = () =>
  import("../views/common-signup/questions/risk/stock-information.vue");
const PortfolioManagement = () =>
  import("../views/common-signup/questions/risk/portfolio-management.vue");
const RiskIntroduction = () => import("../views/create-portfolio/questions/risk/introduction.vue");
const PortfolioSelection = () =>
  import("../views/create-portfolio/questions/risk/portfolio-selection.vue");
const PortfolioConfirmation = () =>
  import("../views/create-portfolio/questions/risk/portfolio-confirmation.vue");

const ThemeInfo = () => import("../views/pension/preferences/theme-info.vue");
const Theme = () => import("../views/pension/preferences/theme.vue");

export default [
  {
    path: "pension",
    name: "pension",
    component: Pension,

    // Always allow the user to go to return to a page they have previously visited.
    // If they try to go to any other question, put them on the last visited path.
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (path?: RouteLocationRaw) => void,
    ) => {
      const { getters } = store as any;
      if (to.path.endsWith("/pension")) {
        // This check is here to avoid infinite recursion.
        next();
      } else {
        const latestVisitedPath = sessionStorage.getItem(CURRENT_PENSION_PATH_KEY);
        if (latestVisitedPath) {
          const latestVisitedQuestion = getters.questionProgress(latestVisitedPath);
          const toQuestion = getters.questionProgress(to.path);
          const hasVisitedToQuestion
            = latestVisitedQuestion
            && toQuestion
            && (toQuestion.partNumber < latestVisitedQuestion.partNumber
            || (toQuestion.partNumber === latestVisitedQuestion.partNumber
            && toQuestion.questionNumber <= latestVisitedQuestion.questionNumber));

          if (hasVisitedToQuestion) {
            next();
          } else {
            // The user tries to access a question which doesn't exist or which
            // they haven't reached using the normal flow. Put them on the latest visited path.
            next({ params: to.params, path: latestVisitedPath, replace: true });
          }
        }
      }
    },
    children: [
      { path: "intro", component: Intro },
      { path: "move", component: Move },
      { path: "search/where", component: Where },
      { path: "search/search", component: Search },
      { path: "search/search-result", component: SearchResult },
      { path: "search/active-payment", component: ActivePayment },
      { path: "search/active-payment-warning", component: ActivePaymentWarning },
      { path: "search/merge-pensions", component: MergePensions },
      { path: "search/sole-owner", component: SoleOwner },
      { path: "search/sole-owner-confirm", component: SoleOwnerConfirm },
      { path: "search/insurance-email", component: InsuranceEmail },
      { path: "search/complete-info/:externalId", component: CompleteInfo },
      { path: "search/organization-number/:externalId", component: OrganizationNumber },

      { path: "insurance/intro", component: InsuranceIntro },
      { path: "insurance/payout", component: Payout },
      { path: "insurance/payout-warning", component: PayoutWarning },
      { path: "insurance/payout-time", component: PayoutTime },
      { path: "insurance/summary", component: Summary },
      { path: "insurance/summary-agreements", component: SummaryAgreements },
      { path: "insurance/done", component: Done },

      { path: "economy/intro", component: EconomyIntro },
      { path: "economy/private-savings", component: PrivateSavings },
      { path: "economy/other-pension", component: OtherPension },

      { path: "preferences/introduction", component: PreferencesIntroduction },
      { path: "preferences/sectors", component: Sectors },
      { path: "preferences/market", component: MarketFocus },
      { path: "preferences/sustainability", component: Sustainability },
      {
        path: "preferences/sustainable-investment",
        component: SustainableInvestment,
        name: "sustainable-investment-pension",
      },
      { path: "preferences/sustainable-method", component: SustainableMethod },
      { path: "preferences/sustainable-info", component: SustainableInfo },
      { path: "preferences/sustainable-choice", component: SustainableChoice },
      { path: "preferences/sustainable-choice-verify", component: SustainableChoiceVerify },
      { path: "preferences/negative-impact", component: NegativeImpact },
      { path: "preferences/focus", component: Focus },

      { path: "preferences/theme-info", component: ThemeInfo },
      { path: "preferences/theme", component: Theme },

      { path: "savings-proposal/proposal", component: Proposal },
      { path: "savings-proposal/profile", component: Profile },

      { path: "sign/summary", component: SignSummary },

      { path: "sign/summary-sign", component: SummarySign },
      { path: "sign/move-started", component: MoveStarted },
      { path: "sign/congratulations", component: Congratulations },
      { path: "economy/time-to-withdraw", component: TimeToWithdraw },
      {
        path: "economy/time-to-withdraw-warning",
        component: TimeToWithdrawWarning,
        name: "time-to-withdraw-warning-pension",
      },
      { path: "economy/income", component: Income },
      { path: "economy/money-left", component: MoneyLeft },
      {
        path: "economy/expenses-warning",
        component: ExpensesWarning,
        name: "expenses-warning-pension",
      },
      { path: "economy/remainder", component: MonthlyRemainder },
      { path: "economy/introduction", component: EconomyIntroduction },
      { path: "economy/current-savings", component: CurrentSavings },
      {
        path: "economy/current-savings-warning",
        component: CurrentSavingsWarning,
        name: "current-savings-warning-pension",
      },
      { path: "economy/bound-capital", component: BoundCapital },
      { path: "economy/loans", component: Loans },
      {
        path: "economy/loans-warning",
        component: LoansWarning,
        name: "loans-warning-pension",
      },
      { path: "economy/loaned-amount", component: LoanedAmount },
      {
        path: "economy/loaned-amount-warning",
        component: LoanedAmountWarning,
        name: "loaned-amount-warning-pension",
      },
      { path: "aml/law-about", component: LawAbout },
      { path: "aml/occupation", component: Occupation },
      { path: "aml/interval", component: Interval },
      { path: "aml/amount", component: Amount },
      { path: "aml/purpose", component: Purpose },
      { path: "aml/investment-background", component: InvestmentBackground },
      { path: "aml/withdrawal-interval", component: WithdrawalInterval },
      {
        path: "aml/withdrawal-interval-information",
        component: WithdrawalIntervalInformation,
        name: "withdrawal-interval-information-pension",
      },
      { path: "aml/politically-exposed", component: PoliticallyExposed },
      { path: "aml/politically-exposed-title", component: PoliticallyExposedTitle },
      { path: "aml/politically-exposed-country", component: PoliticallyExposedCountry },
      { path: "aml/politically-exposed-ongoing", component: PoliticallyExposedOngoing },
      { path: "aml/politically-exposed-savings", component: PoliticallyExposedSavings },
      {
        path: "aml/politically-exposed-terminated",
        component: PoliticallyExposedTerminated,
      },
      {
        path: "aml/politically-exposed-associated",
        component: PoliticallyExposedAssociated,
      },
      {
        path: "aml/politically-exposed-associated-name",
        component: PoliticallyExposedAssociatedName,
      },
      {
        path: "aml/politically-exposed-associated-relationship-relative",
        component: PoliticallyExposedAssociatedRelationshipRelative,
      },
      {
        path: "aml/politically-exposed-associated-relationship-coworker",
        component: PoliticallyExposedAssociatedRelationshipCoworker,
      },
      { path: "aml/politically-exposed-associated-title", component: PoliticallyExposedTitle },
      { path: "aml/politically-exposed-associated-country", component: PoliticallyExposedCountry },
      { path: "aml/politically-exposed-associated-ongoing", component: PoliticallyExposedOngoing },
      { path: "aml/politically-exposed-associated-savings", component: PoliticallyExposedSavings },
      {
        path: "aml/politically-exposed-associated-organization",
        component: PoliticallyExposedAssociatedOrganization,
      },
      {
        path: "aml/politically-exposed-associated-terminated",
        component: PoliticallyExposedTerminated,
      },
      { path: "aml/your-money", component: YourMoney },
      { path: "aml/swedish-citizenship", component: SwedishCitizenship },
      {
        path: "aml/citizenship-information",
        component: CitizenShipDenial,
        name: "citizenship-denial-pension",
      },
      { path: "aml/tax-liable-usa", component: TaxLiableUsa },
      { path: "aml/tax-liable-other", component: TaxLiableOther },
      {
        path: "aml/tax-liable-denial",
        component: TaxLiableDenial,
        name: "tax-liable-denial-pension",
      },
      { path: "aml/citizenship", component: Citizenship },
      { path: "aml/swedish-tax-residence", component: SwedishTaxResidence },
      {
        path: "aml/swedish-tax-residence-denial",
        component: SwedishTaxResidenceDenial,
        name: "swedish-tax-residence-denial-pension",
      },
      { path: "aml/taxable-country", name: "taxable-country-pension", component: TaxableCountry },
      { path: "risk/introduction", component: RiskIntroduction },
      { path: "risk/portfolio-selection", component: PortfolioSelection },
      { path: "risk/portfolio-confirmation", component: PortfolioConfirmation },
      { path: "summary/summary-agreements", component: SummaryAgreementsSigmastocks },
      { path: "summary/done", component: DoneSigmastocks },
      { path: "previous-experience", component: PreviousExperience },
      { path: "previous-experience-answer", component: PreviousExperienceAnswer },
      { path: "risk/stock-information", component: StockInformation },
      { path: "portfolio-management", component: PortfolioManagement },
      {
        // Catch-all
        path: "*",
      },
    ],
  },
] as RouteRecordRaw[];
