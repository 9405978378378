<template>
  <side-dialog
    class="pe-side-dialog"
    :model-value="modelValue"
    :title="$t('components.politically-exposed-side-dialog.pepTitle')"
    @update:model-value="close"
  >
    <v-expansion-panels
      v-model="panels"
      variant="accordion"
      :multiple="true"
    >
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title expand-icon="fas fa-chevron-down pe-side-dialog__icon">
          {{ $t("components.politically-exposed-side-dialog.whatIsPepHeader") }}
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pe-side-dialog__paragraph">
          <p
            v-for="(item, index) in whatIsPep"
            :key="index"
          >
            {{ item }}
          </p>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title expand-icon="fas fa-chevron-down pe-side-dialog__icon">
          {{ $t("components.politically-exposed-side-dialog.whoIsPepHeader") }}
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pe-side-dialog__paragraph">
          <p>{{ $t("components.politically-exposed-side-dialog.whoIsPepIngress") }}</p>
          <p
            v-for="(item, index) in whoIsPep"
            :key="index"
          >
            · {{ item }}
          </p>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title expand-icon="fas fa-chevron-down pe-side-dialog__icon">
          {{ $t("components.politically-exposed-side-dialog.whoIsACloseRelativeHeader") }}
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pe-side-dialog__paragraph">
          <p>{{ $t("components.politically-exposed-side-dialog.whoIsACloseRelativeIngress") }}</p>
          <p
            v-for="(item, index) in whoIsCloseRelative"
            :key="index"
          >
            · {{ item }}
          </p>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title expand-icon="fas fa-chevron-down pe-side-dialog__icon">
          {{ $t("components.politically-exposed-side-dialog.whoIsACloseCoworkerHeader") }}
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pe-side-dialog__paragraph">
          <p>{{ $t("components.politically-exposed-side-dialog.whoIsACloseCoworkerIngress") }}</p>
          <p
            v-for="(item, index) in whoIsCloseCoworker"
            :key="index"
          >
            · {{ item }}
          </p>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="pe-side-dialog__button-container">
      <close-button
        size-large
        :dark="dark"
        @click="close"
      >
        {{ $t("ok") }}
      </close-button>
    </div>
  </side-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CloseButton from "@/components/button.vue";

export default defineComponent({
  emits: ["update:model-value"],
  components: { SideDialog, CloseButton },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      panels: [0]
    };
  },
  computed: {
    whatIsPep(): Array<string> {
      return Array(2)
        .fill(0)
        .map((_, i) => this.$t(`components.politically-exposed-side-dialog.whatIsPep.${i}`));
    },
    whoIsCloseCoworker(): Array<string> {
      return Array(3)
        .fill(0)
        .map((_, i) =>
          this.$t(`components.politically-exposed-side-dialog.whoIsCloseCoworker.${i}`)
        );
    },
    whoIsCloseRelative(): Array<string> {
      return Array(5)
        .fill(0)
        .map((_, i) =>
          this.$t(`components.politically-exposed-side-dialog.whoIsCloseRelative.${i}`)
        );
    },
    whoIsPep(): Array<string> {
      return Array(8)
        .fill(0)
        .map((_, i) => this.$t(`components.politically-exposed-side-dialog.whoIsPep.${i}`));
    }
  },
  methods: {
    close(): void {
      this.$emit("update:model-value", false);
    }
  }
});
</script>
<style lang="scss">
.pe-side-dialog {
  &__paragraph {
    font-weight: 300;
  }

  &__button-container {
    text-align: center;
    padding: 2rem;
  }
}

.pe-side-dialog__icon {
  font-size: 1rem !important;
}

:deep(.v-expansion-panel-title__overlay) {
  opacity: 0 !important;
}
</style>
