export default {
  "se-amf": "Amf",
  "se-alecta": "Alecta",
  "se-avanza": "Avanza",
  "se-folksam": "Folksam",
  "se-futur": "Futur",
  "se-handelsbanken": "Handelsbanken",
  "se-idunliv": "Idun Liv",
  "se-lansforsakringar": "Länsförsakringar",
  "se-movestic": "Movestic",
  "se-nordea": "Nordea",
  "se-nordnet": "Nordnet",
  "se-seb": "SEB",
  "se-skandia": "Skandia",
  "se-spp": "SPP",
  "se-sfb": "SFB",
  "se-swedbank": "Swedbank"
};
