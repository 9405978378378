import { Market } from "@/clients";

export default function getMarketDistribution(market: Market) {
  switch (market) {
    case Market.Standard:
      return {
        [Market.Sweden]: 50,
        [Market.Europe]: 20,
        [Market.Us]: 30
      };
    case Market.Sweden:
      return {
        [Market.Sweden]: 100,
        [Market.Europe]: 0,
        [Market.Us]: 0
      };
    case Market.SwedenOld:
      return {
        [Market.Sweden]: 70,
        [Market.Europe]: 10,
        [Market.Us]: 20
      };
    case Market.Europe:
      return {
        [Market.Sweden]: 40,
        [Market.Europe]: 40,
        [Market.Us]: 20
      };
    case Market.Us:
      return {
        [Market.Sweden]: 40,
        [Market.Europe]: 10,
        [Market.Us]: 50
      };
    default:
      return 0;
  }
}
