<script setup lang="ts">
import { useRouter } from "vue-router";
import CommonButton from "@/components/button.vue";

const router = useRouter();
</script>

<template>
  <VOverlay
    :model-value="true"
    contained
    scrim="white"
    persistent
    :opacity="0.8"
    no-click-animation
    class="align-center justify-center"
  >
    <div
      style="text-align: center"
    >
      <p style="font-weight: 400; font-family: sofia-pro">
        {{ $t("portfolio-settings.strategy.esg.body1") }}
      </p>
      <p>
        {{ $t("portfolio-settings.strategy.esg.body2") }}
      </p>

      <CommonButton
        class="esg__first-time--button"
        @click="router.push({ name: 'esg' })"
      >
        Starta
      </CommonButton>
    </div>
  </VOverlay>
</template>
