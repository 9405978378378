import "@fortawesome/fontawesome-free/css/all.css";
import "vuetify/styles";

import type { ThemeDefinition } from "vuetify";
import { createVuetify } from "vuetify";
import { aliases, fa } from "vuetify/iconsets/fa-svg";

const lightTheme: ThemeDefinition = {
  colors: {
    "primary": "#4F637B", // Soft blue
    "secondary": "#9ACFC3", // Dusty green
    "accent": "#00704A", // Just green
    "error": "#FF5252",
    "info": "#2196F3",
    "success": "#4CAF50",
    "warning": "#FFC107",
    "on-surface": "#2B2B2B",
  },
  variables: {
    // TODO value?
    "medium-emphasis-opacity": 0.8,
  },
};
const evidaTheme: ThemeDefinition = {
  colors: {
    "primary": "#13276c",
    "secondary": "#9ACFC3", // Dusty green
    "accent": "#00704A", // Just green
    "error": "#FF5252",
    "info": "#2196F3",
    "success": "#4CAF50",
    "warning": "#FFC107",
    "on-surface": "#2B2B2B",
  },
  variables: {
    // TODO value?
    "medium-emphasis-opacity": 0.8,
  },
};

export default createVuetify({
  display: {
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1264,
    },
  },
  theme: {
    defaultTheme: "light",
    themes: { light: lightTheme, evida: evidaTheme },
  },
  icons: {
    defaultSet: "fa",
    aliases: {
      ...aliases,
      checkboxOff: "fal fa-square",
      checkboxOn: "fal fa-check-square",
      clear: "fal fa-times",
    },
    sets: {
      fa,
    },
  },
});
