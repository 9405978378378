<template>
  <side-dialog
    v-model="showDialog"
    :title="$t('referralTitle')"
    @click:outside="() => (showDialog = false)"
    @update:model-value="(value) => (showDialog = value)"
  >
    <div>
      <div
        v-if="!showTerms"
        class="referrals referrals__main"
      >
        <icon
          class="referrals__icon"
          :icon="['fal', 'gift']"
        />
        <p class="referrals__text">
          {{ $t("referrals.referrals-side-dialog.description") }}
        </p>
        <div class="referrals__link-wrapper">
          <p class="referrals__text">
            {{ $t("referrals.referrals-side-dialog.yourLink") }}
          </p>
          <div
            v-if="canCopy"
            class="referrals__link-box"
          >
            <span class="referrals__link">
              <span class="referrals__text--light">{{
                formatReferralCode(referralCodeLink)
              }}</span></span
            >
            <v-tooltip
              v-model="showCopiedTooltip"
              location="top"
            >
              <template #activator="{}">
                <v-btn
                  class="referrals__copy"
                  variant="text"
                  @click="copy"
                >
                  {{ $t("referrals.referrals-side-dialog.copy") }}
                </v-btn>
              </template>
              <span>{{ $t("referrals.referrals-side-dialog.linkCopied") }}</span>
            </v-tooltip>
          </div>
          <span
            v-if="!canCopy"
            class="referrals__text--light"
            >{{ referralCodeLink }}</span
          >
          <common-button
            v-if="canShare"
            class="referrals__button"
            @click="share"
          >
            {{ $t("referrals.referrals-side-dialog.share") }}
          </common-button>
        </div>
        <div class="referrals__invited">
          <p
            class="referrals__header"
            style="text-align: left"
          >
            {{ $t("referrals.referrals-side-dialog.invited") }}
          </p>
          <div class="referrals__invited-row">
            <p>{{ $t("ongoing") }}</p>
            <div class="referrals__invited-row--right">
              <div>{{ ongoingInvitations }}</div>
              <icon
                fixed-width
                :icon="['fas', 'gift']"
                class="referrals__invited__icon referrals__invited__icon--grayed"
              />
            </div>
          </div>
          <div class="referrals__invited-row">
            <p>{{ $t("referrals.referrals-side-dialog.finished") }}</p>
            <div class="referrals__invited-row--right">
              <div>{{ finishedInvitations }}</div>
              <icon
                fixed-width
                :icon="['fas', 'gift']"
                class="referrals__invited__icon"
              />
            </div>
          </div>
        </div>
        <div class="referrals__paidbonus">
          <p class="referrals__header">
            {{ $t("referrals.referrals-side-dialog.paidBonusThisYear") }}
          </p>
          <p class="referrals__paidbonus-number">{{ paidBonusThisYear }} / 1000 kr</p>
        </div>
        <p
          class="referrals__terms-link"
          @click="showTerms = true"
        >
          {{ $t("referrals.referrals-side-dialog.terms") }}
        </p>
      </div>
      <div
        v-else
        class="referrals referrals__terms"
      >
        <div style="align-items: left">
          <p class="referrals__header">
            {{ $t("referrals.referrals-side-dialog.infoHeader1") }}
          </p>
          <p class="referrals__description">
            {{ $t("referrals.referrals-side-dialog.infoBody1") }}
          </p>
          <p class="referrals__description">
            {{ $t("referrals.referrals-side-dialog.infoBody2") }}
          </p>
          <p class="referrals__description">
            {{ $t("referrals.referrals-side-dialog.infoBody3") }}
          </p>
          <p class="referrals__description">
            {{ $t("referrals.referrals-side-dialog.infoBody4") }}
          </p>
          <p class="referrals__header">
            {{ $t("referrals.referrals-side-dialog.infoHeader2") }}
          </p>
          <p class="referrals__description">
            {{ $t("referrals.referrals-side-dialog.infoBody5") }}
          </p>
        </div>
        <br />
        <common-button
          class="sector-distribution__side-dialog__button"
          @click="() => (showTerms = false)"
        >
          {{ $t("ok") }}
        </common-button>
      </div>
    </div>
  </side-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { CustomWindow } from "@/types";
import { UserMutation } from "@/store/user/mutations";

declare const window: CustomWindow;

export default defineComponent({
  components: {
    SideDialog,
    CommonButton
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showCopiedTooltip: false,
      showTerms: false
    };
  },
  computed: {
    referralCode(): string | undefined {
      return this.$store.state.userStore.currentLegalEntity?.referralCode;
    },
    canShare(): boolean {
      return window.navigator.share !== undefined;
    },
    canCopy(): boolean {
      return window.navigator.clipboard !== undefined;
    },
    showDialog: {
      get(): boolean {
        const { showReferralsDialog } = this.$store.state.userStore;
        return showReferralsDialog;
      },
      set(value: boolean): void {
        this.$store.commit(UserMutation.setShowReferralsDialog, value);
      }
    },
    referralCodeLink(): string {
      const { locale } = this.$store.state.userStore;
      return `${import.meta.env.VITE_WEB_BASE_URL as string}/${locale}/signup?referral=${
        this.referralCode ? this.referralCode : ""
      }`;
    },
    ongoingInvitations(): string {
      if (
        this.$store.state.userStore.currentLegalEntity?.invitedCount !== undefined &&
        this.$store.state.userStore.currentLegalEntity?.finishedInvitationsCount !== undefined
      ) {
        return (
          this.$store.state.userStore.currentLegalEntity?.invitedCount -
          this.$store.state.userStore.currentLegalEntity?.finishedInvitationsCount
        ).toString();
      }
      return "-";
    },
    finishedInvitations(): string {
      if (this.$store.state.userStore.currentLegalEntity?.finishedInvitationsCount !== undefined) {
        return (this.$store.state.userStore.currentLegalEntity
          ?.finishedInvitationsCount).toString();
      }
      return "-";
    },
    paidBonusThisYear(): string {
      if (
        this.$store.state.userStore.currentLegalEntity?.finishedInvitationsCountThisYear !==
        undefined
      ) {
        return (
          this.$store.state.userStore.currentLegalEntity?.finishedInvitationsCountThisYear * 100
        ).toString();
      }
      return "-";
    }
  },
  methods: {
    formatReferralCode(code: string) {
      if (code && code.length > 26) {
        let value = code.replace("https://", "");
        value = value.substring(0, 26);
        value += "...";
        return value;
      }
      return code;
    },
    // navigator.clipboard has full support of modern browsers. See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/clipboard#browser_compatibility
    async copy() {
      await window.navigator.clipboard.writeText(this.referralCodeLink);
      this.showCopiedTooltip = true;
      setTimeout(() => {
        this.showCopiedTooltip = false;
      }, 2000);
    },
    // navigator.share has limited support in browsers. See https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share#browser_compatibility
    async share() {
      if (this.canShare) {
        await window.navigator.share({
          /* IMPORTANT: Only include text property here, don't change.
          If you add the title and/or url properties (as MDN docs say you probably should), the
          text will not be prefilled when sharing to Facebook Messenger on iOS. Works in all other apps though. Don't ask me why,
          can't find any info on this online. */
          text:
            this.$t("referrals.referrals-side-dialog.testSigmastocksBody") + this.referralCodeLink
        });
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.referrals {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  &__main {
    row-gap: 1rem;
  }
  &__terms {
    text-align: left;
  }
  &__icon {
    width: 4.125rem;
    height: 4.125rem;
    color: $soft-blue;
  }
  &__text {
    font-family: $heading-font-family;
    font-weight: 100;
    &--light {
      font-weight: 100;
    }
  }
  &__link-wrapper {
    width: 100%;
    text-align: left;
  }
  &__link {
    padding-left: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__button {
    width: 100%;
  }
  &__link-box {
    width: 100%;
    height: 3.5rem;
    border-radius: 0.5rem;
    border: 1px solid $nearly-black;
    font-family: $heading-font-family;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__copy {
    text-transform: none;
    margin-right: 1rem;
  }
  &__terms-link {
    text-decoration: underline;
    cursor: pointer;
    color: $nearly-black;
    opacity: 0.63;
  }
  &__description {
    margin-bottom: 1rem;
  }
  &__header {
    font-weight: bold;
    font-size: 1.125rem;
  }
  &__invited {
    width: 100%;
    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &--right {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-basis: 3rem;
      }
    }
    &__icon {
      font-size: 1.5rem;
      margin-left: 0.5rem;
      color: $soft-blue;
      &--grayed {
        opacity: 0.3;
      }
    }
  }
  &__paidbonus {
    width: 100%;
    text-align: left;
    &-number {
      font-weight: bold;
      font-size: 2rem;
    }
  }
}
</style>
