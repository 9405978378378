<template>
  <single-choice
    :alternatives="alternatives"
    @click="buttonClick"
  >
    <question-text-section
      progress
      :heading="$t('create-portfolio.questions.economy.bound-capital.heading')"
      :body="$t('create-portfolio.questions.economy.bound-capital.body')"
    />

    <range-template
      v-model="modelValue"
      :heading="$t('create-portfolio.questions.economy.bound-capital.heading')"
      :body="$t('create-portfolio.questions.economy.bound-capital.body')"
      :values="values"
      :label="$t('currency')"
    />
  </single-choice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import RangeTemplate from "@/views/signup/templates/range.vue";

export default defineComponent({
  components: {
    RangeTemplate,
    QuestionTextSection,
    SingleChoice
  },
  extends: Question,
  computed: {
    values() {
      const values: number[] = [];
      for (let i = 0; i < 100000; i += 10000) {
        values.push(i);
      }
      for (let i = 100000; i < 1000000; i += 50000) {
        values.push(i);
      }
      for (let i = 1000000; i < 5000000; i += 100000) {
        values.push(i);
      }
      for (let i = 5000000; i < 15000000; i += 500000) {
        values.push(i);
      }
      for (let i = 15000000; i <= 20000000; i += 1000000) {
        values.push(i);
      }
      return values;
    },
    modelValue: {
      get(): number {
        return this.$store.state.signupStore.boundCapital;
      },
      set(value: number) {
        this.$store.commit(SignupMutation.setBoundCapital, value);
      }
    },
    alternatives(): any {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    buttonClick() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped></style>
