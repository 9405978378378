<template>
  <div class="politically-exposed-title">
    <single-choice
      :alternatives="navigationAlternatives"
      :disabled="!savingsDescription"
      @click="goToNext"
    >
      <question-text-section
        :heading="$t('create-portfolio.questions.aml.politically-exposed-savings.heading')"
      />
      <div class="politically-exposed-title__dropdown">
        <v-textarea v-model="savingsDescription" variant="solo" no-resize label="Beskrivning" />
      </div>
    </single-choice>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { NavigationAlternative } from "@/types/signup";
import { softBlue } from "@/styles/colors";
import Question from "@/views/signup/templates/question";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice
  },
  extends: Question,
  data() {
    return {
      showErrorMessage: false,
      associated: this.$router.currentRoute.value.path.includes("associated"),
      softBlue
    };
  },
  computed: {
    savingsDescription: {
      get(): string | undefined {
        return this.associated
          ? this.$store.state.signupStore.associatedPepInfo.savingsDescription
          : this.$store.state.signupStore.pepInfo.savingsDescription;
      },
      set(value: string) {
        this.associated
          ? this.$store.commit(SignupMutation.setAssociatedPepSavingsDescription, value)
          : this.$store.commit(SignupMutation.setPepSavingsDescription, value);
      }
    },
    navigationAlternatives(): Array<NavigationAlternative> {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
<style lang="scss">
.politically-exposed-title {
  height: 100%;
  width: 100%;
  &__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 18.75rem;
    width: 100%;
    margin: 6vh auto 0 auto;
    &--text {
      color: white;
      font-weight: 600;
    }
    &--icon {
      color: white;
    }
  }
}
</style>
