<template>
  <div class="error-loading">
    <icon fixed-width :icon="['far', 'frown-open']" class="error-loading__smiley" />
    <span class="error-loading__header">{{ $t("signCompletionHeaderFailed") }}</span>
    <span class="error-loading__body">{{ body }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    body: {
      type: String,
      default: ""
    }
  }
});
</script>

<style lang="scss">
.error-loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 16rem;
  height: 7rem;
  align-items: center;
  justify-content: space-evenly;
  &__smiley {
    font-size: 1.375rem;
    // height: 2rem;
  }
  &__header {
    font-size: 1.25rem;
    font-weight: bold;
  }
  &__body {
    text-align: center;
  }
}
</style>
