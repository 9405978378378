<template>
  <div class="asset-allocation-legend">
    <div class="asset-allocation-legend__pair">
      <span class="asset-allocation-legend__key"> {{ $t("stocks") }} </span>
      <span
        class="asset-allocation-legend__value"
        data-jest="stocks"
      >
        {{ stockString }}
      </span>
    </div>
    <div class="asset-allocation-legend__pair">
      <span
        class="asset-allocation-legend__value"
        data-jest="bonds"
      >
        {{ bondString }}
      </span>
      <span class="asset-allocation-legend__key"> {{ $t("bonds") }} </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    stockFraction: {
      type: Number,
      default: 0
    },
    bondFraction: {
      type: Number,
      default: 0
    }
  },
  computed: {
    stockString(): string {
      if (this.stockFraction === 0 && this.bondFraction === 0) {
        return "- %";
      }
      return this.formatFraction(this.stockFraction);
    },
    bondString(): string {
      if (this.stockFraction === 0 && this.bondFraction === 0) {
        return "- %";
      }
      return this.formatFraction(this.bondFraction);
    },
    percentageFormatter(): any {
      const localeStringOptions = {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
      };
      return Intl.NumberFormat("sv-SE", localeStringOptions);
    }
  },
  methods: {
    formatFraction(fraction: number): string {
      return `${this.percentageFormatter.format(fraction * 100)} %`;
    }
  }
});
</script>

<style lang="scss" scoped>
.asset-allocation-legend {
  display: flex;
  justify-content: space-between;
  max-width: 14rem;
  width: 100%;
  @include large-up {
    margin-left: 0.5rem;
    flex-direction: column;
    width: auto;
  }
  &__key {
    font-weight: 300;
    font-family: $heading-font-family;
  }
  &__pair {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    &:first-child {
      @include large-up {
        margin-bottom: 0.5rem;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }
  }
  &__value {
    font-weight: 500;
    margin: 0 0.5rem;
    color: $nearly-black;
    @include large-up {
      font-weight: 600;
      text-align: right;
      width: 4rem;
      margin-left: 0;
      font-size: 1.125rem;
    }
  }
}
</style>
