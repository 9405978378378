import type { EsgPais } from "@/types/signup";

export interface PaiDict { [key: string]: boolean }

export function paiFromState(esgPais: EsgPais | undefined): PaiDict {
  return {
    1: !!esgPais?.pai1 || false,
    2: !!esgPais?.pai2 || false,
    3: !!esgPais?.pai3 || false,
    4: !!esgPais?.pai4 || false,
    5: !!esgPais?.pai5 || false,
    7: !!esgPais?.pai7 || false,
    9: !!esgPais?.pai9 || false,
    10: !!esgPais?.pai10 || false,
    11: !!esgPais?.pai11 || false,
    13: !!esgPais?.pai13 || false,
    14: !!esgPais?.pai14 || false,
  };
}
