<template>
  <div class="pagination">
    <v-btn
      class="pagination__arrow-button"
      variant="flat"
      icon
      elevation="0"
      :disabled="currentPage === 1"
      data-jest="start"
      @click="() => (currentPage = 1)"
    >
      <icon
        fixed-width
        :icon="['fal', 'chevron-double-left']"
        class="pagination__arrow-icon"
      />
    </v-btn>
    <v-btn
      class="pagination__arrow-button"
      variant="flat"
      icon
      elevation="0"
      :disabled="currentPage === 1"
      data-jest="back"
      @click="() => (currentPage -= 1)"
    >
      <icon
        fixed-width
        :icon="['fal', 'chevron-left']"
        class="pagination__arrow-icon"
      />
    </v-btn>

    <v-btn
      v-for="page in displayedPages"
      :key="page"
      :value="currentPage === page"
      icon
      elevation="0"
      variant="flat"
      :data-jest="`page-${page}`"
      selected-class="pagination__page-button--active"
      :class="[
        'pagination__page-button',
        { 'pagination__page-button--active': currentPage === page }
      ]"
      height="2.5rem"
      width="2.5rem"
      @click="() => (currentPage = page)"
    >
      {{ page }}
    </v-btn>
    <v-btn
      class="pagination__arrow-button"
      variant="flat"
      icon
      elevation="0"
      :disabled="currentPage === paginationLength"
      data-jest="forward"
      @click="() => (currentPage += 1)"
    >
      <icon
        fixed-width
        :icon="['fal', 'chevron-right']"
        class="pagination__arrow-icon"
      />
    </v-btn>
    <v-btn
      class="pagination__arrow-button"
      variant="flat"
      icon
      elevation="0"
      :disabled="currentPage === paginationLength"
      data-jest="end"
      @click="() => (currentPage = paginationLength)"
    >
      <icon
        fixed-width
        :icon="['fal', 'chevron-double-right']"
        class="pagination__arrow-icon"
      />
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { nearlyBlack } from "@/styles/colors";

export default defineComponent({
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    paginationLength: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      nearlyBlack,
      itemsPerPage: 10
    };
  },
  computed: {
    // 4 is the maximum number that will fit on a 320px screen.
    totalVisible(): number {
      return this.$vuetify.display.smAndUp ? 7 : 4;
    },
    currentPage: {
      get(): number {
        return this.modelValue;
      },
      set(page: number) {
        this.$emit("update:model-value", page);
      }
    },
    displayedPages(): number[] {
      const arrayLength =
        this.totalVisible > this.paginationLength ? this.paginationLength : this.totalVisible;

      let start = this.currentPage - Math.floor(this.totalVisible / 2);

      // Start at earliest at 1.
      const minimumPage = 1;
      start = Math.max(start, minimumPage);

      // Don't start later than that the whole array can fit in the original pagination array, i.e.
      // make sure that the max value in the array <= this.paginationLength while keeping with the set
      // total visible pages.
      const largestStartPage = minimumPage + this.paginationLength - arrayLength;
      start = Math.min(start, largestStartPage);

      // Create an array [1, 2, 3, ..., arrayLength].
      const pageArray = Array.from(Array(arrayLength).keys());

      // Add the start value.
      return pageArray.map((x) => x + start);
    }
  }
});
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  &__arrow-icon {
    font-size: 1rem;
  }
  &__page-button {
    font-size: 1.125rem !important;
    background-color: transparent;

    @include medium-up {
      margin: 0 0.5rem;
    }
    &--active {
      background-color: $french-grey;
    }
  }
  &__arrow-button {
    background-color: transparent !important;
  }
}
</style>
