<template>
  <div>
    <card-header
      :title="$t('portfolio.analysis.sector-distribution.sectorDistribution')"
      :icon="['fal', 'tools']"
      @click="() => (showDialog = true)"
    />

    <div class="sector-distribution">
      <div
        v-for="sector in sortSectors(sectors)"
        :key="sector.text"
      >
        <bar-chart-list-item
          :icon="sector.icon"
          :text="sector.text"
          :data-label="sector.dataLabel"
          :relative-value="sector.relativeValue"
        />
      </div>
    </div>
    <loading-spinner-overlay :model-value="showLoadingSpinner" />
    <side-dialog
      v-model="showDialog"
      :title="$t('portfolio.analysis.sector-distribution.sectorDistribution')"
    >
      <div class="sector-distribution__side-dialog">
        <p class="sector-distribution__side-dialog-description">
          {{ $t("portfolio.analysis.sector-distribution.infoBodyOne") }}
        </p>
        <p class="sector-distribution__side-dialog-description">
          {{ $t("portfolio.analysis.sector-distribution.infoBodyTwo") }}
        </p>
        <common-button
          class="sector-distribution__side-dialog__button"
          @click="() => (showDialog = false)"
        >
          {{ $t("ok") }}
        </common-button>
      </div>
    </side-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Sector } from "@/clients";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { sectorIcons } from "@/config/sectors";
import CardHeader from "./card-header.vue";
import BarChartListItem from "../bar-chart-list-item.vue";

interface SectorData {
  text: string;
  icon: string[] | undefined;
  dataLabel: string;
  relativeValue: number;
}

export default defineComponent({
  components: {
    CardHeader,
    BarChartListItem,
    LoadingSpinnerOverlay,
    SideDialog,
    CommonButton
  },
  data: () => ({
    showDialog: false
  }),
  computed: {
    showLoadingSpinner(): boolean {
      const { isLoadingHoldingSummary } = this.$store.state.portfolioStore;
      return isLoadingHoldingSummary;
    },
    sectors(): Array<SectorData> {
      const { sectorRatio } = this.$store.getters;
      if (sectorRatio) {
        return this.getFormattedSectors(sectorRatio);
      }
      return this.getUndefinedSectors();
    }
  },
  methods: {
    // Formatting methods.
    fractionToPercent(fraction: number | undefined): string {
      if (fraction === undefined) {
        return "- %";
      }
      const percent = (fraction * 100).toLocaleString("sv-SE", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
      });
      return `${percent} %`;
    },
    formatRelativeValue(
      distributionFraction: number | undefined,
      highestDistributionFraction: number
    ): number {
      const formattedValue = 0;
      if (distributionFraction) {
        return (distributionFraction / highestDistributionFraction) * 100;
      }
      return formattedValue;
    },
    // Getter methods.
    getHighestDistributionFraction(sectorRatio: { [key in keyof typeof Sector]?: number }): number {
      const validValues = Object.values(sectorRatio).filter(
        (value) => value !== undefined
      ) as number[];
      return Math.max(...validValues);
    },
    getSectorIcon(sector: keyof typeof Sector): string[] | undefined {
      return sectorIcons.get(sector);
    },
    getUndefinedSectors() {
      return Array.from(sectorIcons.keys(), (key: keyof typeof Sector, _: any) => key).map((x) => ({
        text: this.$t(`sectors.${x}`) as string,
        icon: this.getSectorIcon(x),
        dataLabel: "- %",
        relativeValue: 0
      }));
    },
    getFormattedSectors(sectorRatio: { [key in keyof typeof Sector]?: number }): Array<SectorData> {
      const highestDistributionFraction = this.getHighestDistributionFraction(sectorRatio);

      return (Object.keys(sectorRatio) as (keyof typeof Sector)[]).map(
        (sectorKey: keyof typeof Sector) => ({
          text: this.$t(`sectors.${sectorKey}`),
          icon: this.getSectorIcon(sectorKey),
          dataLabel: this.fractionToPercent(sectorRatio[sectorKey]),
          relativeValue: this.formatRelativeValue(
            sectorRatio[sectorKey],
            highestDistributionFraction
          )
        })
      );
    },
    // Manipulation methods.
    sortSectors(sectorArray: Array<SectorData>): Array<SectorData> {
      const sortedArray = sectorArray.sort((objectA, objectB) =>
        objectA.relativeValue > objectB.relativeValue ? -1 : 1
      );
      return sortedArray;
    }
  }
});
</script>

<style lang="scss" scoped>
.sector-distribution {
  display: grid;
  padding: 1rem 0;
  grid-row-gap: 1.125rem;
  @include medium-up {
    grid-row-gap: 1rem;
  }
  &__side-dialog {
    padding: 2rem;
    &-description {
      font-weight: 300;
    }
    &__button {
      display: flex;
      margin: 2rem auto;
      border-radius: 0.5rem;
      font-weight: 500;
      font-family: $heading-font-family;
      text-transform: none;
      font-size: 1.125rem;
      box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16);
    }
  }
}
</style>
