<template>
  <div class="summary">
    <SingleChoiceTemplate
      :alternatives="alternatives"
      information
      @show-information="() => (showDialog = true)"
      @click="goToNext"
    >
      <QuestionTextSection
        :heading="$t('summary')"
        style="margin-bottom: 2rem"
      />
      <div class="summary__summary-wrapper">
        <div class="summary__summary-wrapper-safari-fix">
          <div
            v-if="accountNumber !== undefined"
            class="summary__summary-upper-grid"
          >
            <template
              v-for="(alternative, i) in amountAlternatives"
              :key="alternative.key"
            >
              <p class="summary__summary-wrapper-label">
                {{ alternative.label }}
              </p>
              <p
                v-if="!alternative.hintText"
                :key="i"
                class="summary__summary-wrapper-value"
              >
                {{ `${alternative.value} ${alternative.unit}` }}
              </p>
              <div
                v-else
                :key="alternative.hinttext"
                class="summary__summary-wrapper-value"
              >
                <span class="summary__summary-wrapper-value">
                  {{ alternative.value }}
                </span>
                <br>
                <span class="summary__summary-wrapper-hinttext">
                  {{ alternative.hintText }}
                </span>
              </div>
            </template>
          </div>

          <div class="summary__summary-bottom-grid">
            <span class="summary__summary-wrapper-label">Förvaltningsavgift per år</span>
            <span class="summary__summary-right">{{ managementFee() }} %</span>
            <span class="summary__summary-wrapper-label">{{ $t("transactionFee") }}</span>
            <span class="summary__summary-right">{{ transactionFee() }} %</span>
            <span style="font-size: 0.875rem; opacity: 0.63; font-weight: 300; justify-self: left">{{
              $t("create-portfolio.questions.account.summary.transactionFeeInfo")
            }}</span><div />
            <template v-if="shouldShowFxFee(legalEntity.feeType)">
              <span class="summary__summary-wrapper-label">Växlingsavgift</span>
              <span class="summary__summary-right">{{ getFxFee(legalEntity.feeType) }} %</span>
              <span style="font-size: 0.875rem; opacity: 0.63; font-weight: 300; justify-self: left">Växlingsavgiften dras vid transaktioner som kräver valutaväxling.</span><div />
            </template>
          </div>
        </div>
        <div class="summary__price-text">
          {{ $t("create-portfolio.questions.account.summary.priceText") }}
        </div>
      </div>
      <SideDialog
        v-model="showDialog"
        :title="$t('ourFee')"
      >
        <div class="summary__side-dialog">
          <div
            v-for="(section, i) in infoSections"
            :key="i"
          >
            <p class="summary__side-dialog--heading">
              {{ section.heading }}
            </p>
            <p class="summary__side-dialog--text">
              {{ section.text }}
            </p>
          </div>
        </div>
      </SideDialog>
    </SingleChoiceTemplate>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import type { SingleChoice } from "../../../../types/signup";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import type { InfoSection } from "@/types";
import SideDialog from "@/components/dialog/side-dialog.vue";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import { getFxFee, getManagementFeeForMarketFocus, getTransactionFee, shouldShowFxFee } from "@/utils/fees";
import type { UserState } from "@/store/user/types";
import type { TiedAgent, TiedAgentPortfolioTheme } from "@/clients";

export default defineComponent({
  components: {
    QuestionTextSection,
    SingleChoiceTemplate,
    SideDialog,
  },
  extends: Question,
  data: () => ({
    showDialog: false,
    getFxFee,
    shouldShowFxFee,
  }),
  computed: {
    ...mapGetters(["chosenRiskLevel", "legalEntity"]),
    tiedAgent(): TiedAgent {
      return this.$store.state.signupStore.tiedAgent;
    },
    portfolioTheme(): TiedAgentPortfolioTheme {
      return this.$store.state.signupStore.portfolioTheme;
    },
    yearlyFee(): string {
      if (this.chosenRiskLevel === 3) {
        return "0,39%";
      }
      if (this.chosenRiskLevel === 4) {
        return "0,37%";
      }
      return "0,35%";
    },
    etfFee(): string {
      if (this.chosenRiskLevel === 3) {
        return "0,04%";
      }
      if (this.chosenRiskLevel === 4) {
        return "0,02%";
      }
      return "";
    },
    alternatives(): Array<SingleChoice> {
      return [
        {
          text: this.$t("next"),
          key: "next",
        },
      ];
    },
    amountAlternatives(): Array<any> {
      return [
        {
          label: this.$t("one_time_deposit"),
          key: "one_time_deposit",
          value: this.firstDeposit,
          unit: this.$t("currency"),
        },
        {
          label: this.$t("monthlySaving"),
          key: "monthly_savings",
          value: this.monthlySavingsAmount,
          unit: this.$t("currency"),
        },
        {
          label: this.$t("from"),
          key: "account_from",
          value: this.bankAccountName,
          hintText: this.fullBankAccountNumber,
        },
        {
          label: this.$t("to"),
          key: "account_to",
          value: this.$t("my_portfolio"),
          hintText: this.$t("create-portfolio.questions.account.summary.evida_isk"),
        },
      ];
    },
    accountNumber(): string {
      return this.$store.state.signupStore.bankAccountNumber;
    },
    firstDeposit(): number {
      return this.$store.state.signupStore.firstDeposit !== undefined
        ? this.$store.state.signupStore.firstDeposit.toLocaleString("sv-SE")
        : 0;
    },
    monthlySavingsAmount(): number {
      return this.$store.state.signupStore.monthlySavingsAmount !== undefined
        ? this.$store.state.signupStore.monthlySavingsAmount.toLocaleString("sv-SE")
        : 0;
    },
    clearingNumber(): string {
      return this.$store.state.signupStore.clearingNumber;
    },
    fullBankAccountNumber(): string {
      return this.$store.state.signupStore.fullBankAccountNumber;
    },
    bankAccountName(): string {
      if (this.$store.state.signupStore.bankAccountName) {
        return this.$store.state.signupStore.bankAccountName;
      }
      return this.$t("accountNamePlaceholder");
    },
    infoSections(): InfoSection[] {
      return [
        {
          heading: this.$t("includedInManagementFee"),
          text: this.$t("includedInManagementFeeExplanation"),
        },
        {
          heading: this.$t("includedInTransactionFee"),
          text: this.$t("includedInTransactionFeeExplanation"),
        },
        {
          heading: this.$t("serviceWellPriced"),
          text: this.$t("serviceWellPricedExplanation"),
        },
        {
          text: this.$t("lowestFeeInfo"),
        },
      ];
    },
  },
  methods: {
    goToAgreements() {
      this.$router.push({ name: "agreements" });
    },
    goToNext() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    managementFee(): string {
      if (this.portfolioTheme) {
        return (this.portfolioTheme.managementFeeFraction * 100).toLocaleString("sv-SE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }

      const { currentLegalEntity } = this.$store.state.userStore as UserState;
      if (currentLegalEntity) {
        return getManagementFeeForMarketFocus(
          currentLegalEntity.feeType,
          this.$store.state.signupStore.marketFocus,
        );
      }
      return "-";
    },
    transactionFee(): string {
      const { currentLegalEntity } = this.$store.state.userStore as UserState;
      if (currentLegalEntity) {
        return getTransactionFee(currentLegalEntity.feeType);
      }
      return "-";
    },
  },
});
</script>

<style lang="scss" scoped>
.summary {
  width: 100%;
  height: 100%;
  &__summary-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__summary-wrapper-safari-fix {
    // Fixes grid row height overflowing in safari (https://stackoverflow.com/a/63197330/2152511)
    display: grid;
    align-items: center;
    justify-items: center;
  }
  &__summary-upper-grid {
    width: 100%;
    max-width: 21.75rem;
    display: grid;
    grid-template-columns: 5fr 4fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0.5rem;
    @include medium-up {
      max-width: 32.25rem;
      grid-template-columns: 1fr 1fr;
    }
  }
  &__summary-bottom-grid {
    width: 100%;
    max-width: 21.75rem;
    margin: 2vh auto 0 auto;
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 0.5rem;
    @include medium-up {
      max-width: 32.25rem;
      grid-template-columns: 1fr 1fr;
    }
  }
  &__summary-wrapper-label {
    margin: 0;
    text-align: left;
    font-size: 1.25rem;
  }
  &__summary-wrapper-value {
    height: 100%;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
    font-family: $heading-font-family;
    text-align: right;
  }
  &__summary-wrapper-hinttext {
    font-weight: 300;
    font-size: 1rem;
    opacity: 0.63;
    white-space: nowrap;
  }
  &__price-text {
    margin-top: 1rem;
    font-weight: 300;
    opacity: 0.63;
    text-align: center;
    font-size: 0.875rem;
    @include medium-up {
      font-weight: normal;
      width: 24rem;
    }
  }
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
  &__summary-bold {
    font-size: 1.15rem;
    font-weight: bold;
    white-space: nowrap;
  }
  &__summary-right {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: right;
  }
  &__summary-right-small {
    font-size: 0.9rem;
    text-align: right;
  }
  &__summary-hinttext {
    font-size: 0.9rem;
    font-weight: 300;
    opacity: 0.63;
  }
  &__summary-label {
    margin: 0;
    text-align: left;
    font-size: 0.9rem;
    white-space: nowrap;
  }
  &__summary-line {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
</style>
