<template>
  <div class="country-selector-wrapper">
    <v-autocomplete
      v-if="$vuetify.display.mdAndUp"
      v-model="citizenship"
      :items="items"
      :menu-props="{
        contentClass: 'country-selector-wrapper__menu'
      }"
      :label="$t('components.country-selector.select_country')"
      :no-data-text="$t('components.country-selector.no_data')"
      variant="solo"
      menu-icon="fas fa-chevron-down"
      :disabled="disabled"
      hide-details
      :rules="rules"
    />
    <v-select
      v-else
      v-model="citizenship"
      :items="items"
      :menu-props="{
        contentClass: 'country-selector-wrapper__menu'
      }"
      :label="$t('components.country-selector.select_country')"
      :no-data-text="$t('components.country-selector.no_data')"
      variant="solo"
      menu-icon="fas fa-chevron-down"
      :disabled="disabled"
      hide-details
      :rules="rules"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { countryCodes } from "@/config/countries";
import { CountryCode } from "@/types/signup";

interface VSelectItem {
  title: string;
  value: string;
}

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: undefined
    },
    preselectSweden: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    excludeSweden: {
      type: Boolean,
      default: false
    },
    excludeUs: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array as any,
      default: () => []
    }
  },
  emits: ["update:model-value"],
  computed: {
    items(): VSelectItem[] {
      let filteredCountryCodes = countryCodes.slice();
      if (this.excludeSweden) {
        filteredCountryCodes = filteredCountryCodes.filter((x) => x !== "SE");
      }
      if (this.excludeSweden) {
        filteredCountryCodes = filteredCountryCodes.filter((x) => x !== "US");
      }
      return filteredCountryCodes.map((x: CountryCode) => ({
        title: this.$t(`countries.${x}`),
        value: x
      }));
    },
    citizenship: {
      get(): string | undefined {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:model-value", value);
      }
    }
  },
  created() {
    if (this.preselectSweden) this.citizenship = "SE";
  }
});
</script>

<style lang="scss">
.country-selector-wrapper {
  width: 21rem;
  height: 3rem;
  max-width: 100%;

  &__menu {
    border-radius: 0.5rem !important;
    box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.14) !important;
  }

  .v-input {
    font-size: 1.125rem;
    border-radius: 0.5rem;
  }

  .v-input--is-focused {
    .v-input__slot {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%) !important;
    }
  }

  .v-input__slot {
    box-shadow: 0 0.1875rem 0.75rem rgba(0, 0, 0, 0.08) !important;
  }

  .v-icon {
    font-size: 1rem;
  }
}
</style>
