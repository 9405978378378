<template>
  <div class="analysis">
    <SectionHeader
      v-if="$vuetify.display.mdAndUp"
      :header="$t('analysis')"
      :sub-header="$store.getters.portfolioName || ''"
      class="analysis__header"
    >
      <template #icon>
        <icon
          :icon="['fal', 'chart-pie']"
          size="lg"
        />
      </template>
    </SectionHeader>
    <div class="analysis__cards">
      <v-card
        v-if="$vuetify.display.smAndDown"
        class="analysis__card"
      >
        <Savings :performance="performance" />
      </v-card>
      <v-card class="analysis__card">
        <AssetAllocation />
      </v-card>
      <v-card class="analysis__card">
        <MarketShares />
      </v-card>
      <v-card class="analysis__card">
        <SectorDistribution />
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Savings from "../savings.vue";
import MarketShares from "./market-shares.vue";
import SectorDistribution from "./sector-distribution.vue";
import AssetAllocation from "./asset-allocation.vue";
import SectionHeader from "@/components/section-header.vue";
import type { PerformanceResponse } from "@/clients";
import type { OverviewState } from "@/store/overview/types";

export default defineComponent({
  components: {
    Savings,
    MarketShares,
    AssetAllocation,
    SectorDistribution,
    SectionHeader,
  },
  computed: {
    performance(): PerformanceResponse | undefined {
      if (this.$store.state.portfolioStore.activePortfolioBase) {
        return (this.$store.state.overviewStore as OverviewState).portfolioPerformances[
          this.$store.state.portfolioStore.activePortfolioBase.id
        ];
      }
      return undefined;
    },
  },
});
</script>

<style lang="scss" scoped>
.analysis {
  &__cards {
    display: grid;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    @include small-up {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
    }
    @include medium-up {
      margin-top: 2rem;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: repeat(2fr);
      grid-column-gap: 1.5rem;
      grid-row-gap: 1rem;
    }
    @include large-up {
      grid-row-gap: 1.5rem;
      grid-template-columns: 27.875rem 1fr;
    }
  }
  &__card {
    padding: 0.75rem 1.25rem;
    @include small-up {
      &:first-child,
      &:last-child {
        grid-column: 1 / 3;
      }
    }
    @include medium-up {
      &:first-child {
        grid-row: 1;
        grid-column: 1;
      }
      &:nth-child(2) {
        grid-row: 2;
        grid-column: 1;
      }
      &:last-child {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column: 2;
      }
    }
  }
}
</style>
