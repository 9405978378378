<template>
  <vue-apex-charts
    width="100%"
    :height="$vuetify.display.mdAndUp ? '100%' : 'auto'"
    type="line"
    :options="options"
    :series="series"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ApexOptions } from "apexcharts";
import { baseUrl } from "@/clients/config";
import VueApexCharts from "vue3-apexcharts";

type ChartData = {
  x: string | number;
  y: number;
}[];

type series = {
  name: string;
  data: ChartData;
}[];

export default defineComponent({
  components: {
    VueApexCharts
  },
  props: {
    selectedSeries: {
      type: Number,
      default: undefined
    },
    numberOfYears: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {
      chartData: undefined as ChartData[] | undefined
    };
  },
  computed: {
    options(): ApexOptions {
      return {
        chart: {
          id: "risk-chart",
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          fontFamily: "sofia-pro', sans-serif"
        },
        legend: {
          show: false
        },
        annotations: {
          yaxis: []
        },
        xaxis: {
          labels: {
            formatter: this.formatXLabel,
            style: {
              fontSize: "0.875rem",
              colors: "rgba(43, 43, 43, 0.63)"
            },
            rotate: 0,
            trim: false
          },
          type: "numeric",
          tooltip: {
            enabled: false
          },
          tickAmount: this.xTickAmount
        },
        yaxis: {
          floating: true,
          min: this.minY,
          max: this.maxY,
          tickAmount: this.yTickAmount,
          labels: {
            formatter: this.formatYlabel,
            offsetX: 60,
            offsetY: -8,
            align: "right",
            style: {
              fontSize: "0.875rem",
              colors: "rgba(43, 43, 43, 0.63)"
            }
          },
          opposite: true
        },

        colors: this.colors,
        stroke: {
          width: 2
        },
        tooltip: {
          x: {
            show: false
          },
          style: {
            fontSize: "0.875rem"
          }
        }
      };
    },
    yTickAmount(): number {
      switch (this.numberOfYears) {
        case 8:
          return 6;
        case 16:
          return 4;
        default:
          return 4;
      }
    },
    minY(): number {
      switch (this.numberOfYears) {
        case 8:
          return -30;
        case 16:
          return 0;
        default:
          return -30;
      }
    },
    maxY(): number {
      switch (this.numberOfYears) {
        case 8:
          return 150;
        case 16:
          return 400;
        default:
          return 30;
      }
    },
    colors(): string[] {
      const inactiveOpacity = 0.3;
      let opacities;
      const index = this.selectedSeries as number | undefined;

      if (index !== undefined) {
        opacities = new Array(5).fill(inactiveOpacity);
        opacities[index] = 1;
      } else {
        opacities = new Array(5).fill(1);
      }
      return [
        `rgba(208,118,46,${opacities[0]})`,
        `rgba(52,177,166,${opacities[1]})`,
        `rgba(138,172,182,${opacities[2]})`,
        `rgba(19,38,60,${opacities[3]})`
      ];
    },
    xTickAmount(): number {
      return this.numberOfYears === 2 ? 2 : 4;
    },
    weeksPerTick(): number {
      switch (this.numberOfYears) {
        case 8:
          return 4;
        case 16:
          return 13;
        default:
          return 1;
      }
    },
    filteredChartData(): ChartData[] {
      if (this.chartData) {
        const originalLength = this.chartData[0].length;
        const filteredData = [[], [], [], []] as ChartData[];
        const delta = this.weeksPerTick;

        /*
         * Convert to percentage to make it easier to place y axis
         * ticks
         */
        for (let i = 0; i < 4; i += 1) {
          for (let j = 0; j < originalLength; j += delta) {
            filteredData[i].push({
              x: new Date(this.chartData[i][j].x).getTime(),
              y: (this.chartData[i][j].y - 1) * 100
            });
          }
        }

        return filteredData;
      }
      return [];
    },
    /* This is made into its own function instead of running in the
     * for loop of filteredChartData(), since the for loop otherwise
     * would need to re-run everytime numberOfYears is updated
     */
    cutFilteredChartData(): ChartData[] {
      if (this.filteredChartData && this.filteredChartData.length > 0) {
        const cutData = [[], [], [], []] as ChartData[];
        const maxValues = (this.numberOfYears * 52) / this.weeksPerTick;
        for (let i = 0; i < 4; i += 1) {
          cutData[i] = this.filteredChartData[i].slice(0, maxValues);
        }
        return cutData;
      }
      return [];
    },
    series(): series | undefined {
      return [
        {
          name: this.$t("riskWillingnessOptions.0"),
          data:
            this.cutFilteredChartData && this.cutFilteredChartData.length > 0
              ? this.cutFilteredChartData[0]
              : []
        },
        {
          name: this.$t("riskWillingnessOptions.1"),
          data:
            this.cutFilteredChartData && this.cutFilteredChartData.length > 0
              ? this.cutFilteredChartData[1]
              : []
        },
        {
          name: this.$t("riskWillingnessOptions.2"),
          data:
            this.cutFilteredChartData && this.cutFilteredChartData.length > 0
              ? this.cutFilteredChartData[2]
              : []
        },
        {
          name: this.$t("riskWillingnessOptions.3"),
          data:
            this.cutFilteredChartData && this.cutFilteredChartData.length > 0
              ? this.cutFilteredChartData[3]
              : []
        }
      ];
    }
  },
  async created(): Promise<void> {
    const res = await fetch(`${baseUrl}/data/chart-data.json`);
    const data = await res.json();
    this.chartData = data as ChartData[];
  },
  methods: {
    formatXLabel(value: string, timestamp?: number | undefined): string {
      if (this.chartData) {
        const startDate = new Date(this.chartData[0][0].x);
        const difference = new Date(value).getTime() - startDate.getTime();
        const msInYear = 60 * 60 * 24 * 1000 * 365;
        const differenceInYears = Math.round(difference / msInYear);

        if (differenceInYears === 0) {
          return this.$t("common-signup.questions.risk.risk-chart.today");
        }
        return `${differenceInYears} ${this.$t("years")}`;
      }
      return "";
    },

    formatYlabel(value: number): string {
      return `${value.toFixed(0)} %`;
    }
  }
});
</script>

<style lang="scss" scoped>
:deep(.apexcharts-tooltip) {
  display: flex;
  // Put high risk on the top in the tooltip
  flex-direction: column-reverse;
}
:deep(.apexcharts-svg) {
  // To make room for longer x-label, i.e. "Idag"
  overflow: visible;
}
</style>
