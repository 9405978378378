<template>
  <div class="economy">
    <card-header
      class="economy__header"
      :title="$t('economy')"
      :icon="['fal', 'coins']"
    >
      <edit-button
        :edit="edit"
        @click="toggleEdit"
      />
    </card-header>
    <v-form ref="form">
      <v-text-field
        ref="monthlySalaryInput"
        v-model="displayMonthlySalary"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        :label="$t('profile.economy.monthlySalary')"
        :rules="[rules.required, rules.number]"
        validate-on="input"
        :error="monthlySalaryWarning !== undefined"
        :error-messages="monthlySalaryWarning"
        :suffix="displayMonthlySalary !== undefined ? $t('currency') : ''"
      />
      <v-text-field
        ref="monthlyRemainingInput"
        v-model="displayMonthlyRemaining"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        :label="$t('profile.economy.monthlyRemaining')"
        :rules="[rules.required, rules.number]"
        validate-on="input"
        :error="monthlyRemainingWarning !== undefined"
        :error-messages="monthlyRemainingWarning"
        :suffix="displayMonthlyRemaining !== undefined ? $t('currency') : ''"
      />
      <v-text-field
        ref="totalSavingInput"
        v-model="displayTotalSavings"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('profile.economy.totalSavings')"
        :rules="[rules.required, rules.number]"
        :suffix="displayTotalSavings !== undefined ? $t('currency') : ''"
        :prefix="displayTotalSavings !== '0' ? '~' : ''"
      />
      <v-text-field
        ref="boundCapitalInput"
        v-model="displayBoundCapital"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('profile.economy.boundCapital')"
        :rules="[rules.required, rules.number]"
        :suffix="displayBoundCapital !== undefined ? $t('currency') : ''"
        :prefix="displayBoundCapital !== '0' ? '~' : ''"
      />
      <v-text-field
        ref="totalLoansInput"
        v-model="displayTotalLoans"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('profile.economy.totalLoans')"
        :rules="[rules.required, rules.number]"
        :suffix="displayTotalLoans !== undefined ? $t('currency') : ''"
        :prefix="displayTotalLoans !== '0' ? '~' : ''"
      />
    </v-form>
  </div>
</template>
<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { mapGetters } from "vuex";
import { UserMutation } from "@/store/user/mutations";
import EditButton from "@/components/edit-button.vue";
import CardHeader from "@/components/card-header.vue";
import { Economy } from "@/types/user";

export default defineComponent({
  emits: ["save"],
  components: { CardHeader, EditButton },
  data: () => ({
    edit: false
  }),
  computed: {
    ...mapGetters(["legalEntity", "errorLoadingUser"]),
    monthlySalaryWarning(): string | undefined {
      if (!this.edit) {
        return undefined;
      }
      if (this.monthlySalary === 0) {
        return this.$t("profile.economy.monthlySalaryWarning");
      }
      return undefined;
    },
    monthlyRemainingWarning(): string | undefined {
      if (!this.edit) {
        return undefined;
      }
      if (!this.monthlyRemaining) {
        return undefined;
      }
      if (!this.monthlySalary || this.monthlyRemaining > this.monthlySalary) {
        return this.$t("profile.economy.monthlyRemainingWarning");
      }
      return undefined;
    },
    aggregatedInputs(): string[] {
      return [
        this.displayMonthlySalary,
        this.displayMonthlyRemaining,
        this.displayTotalSavings,
        this.displayBoundCapital,
        this.displayTotalLoans
      ];
    },
    editedEconomy(): Economy | undefined {
      return this.$store.getters.editedEconomy;
    },
    displayMonthlySalary: {
      get(): string {
        if (this.monthlySalary !== undefined && this.monthlySalary !== null) {
          return this.monthlySalary.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        if (value !== "") {
          this.monthlySalary = parseInt(value, 10);
        } else {
          this.monthlySalary = 0;
        }
      }
    },
    monthlySalary: {
      get(): number | undefined | undefined {
        return this.editedEconomy?.monthlySalary;
      },
      set(monthlySalary: number): void {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          monthlySalary
        });
      }
    },
    displayMonthlyRemaining: {
      get(): string {
        if (this.monthlyRemaining) {
          return this.monthlyRemaining.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string): void {
        value = this.removeWhiteSpace(value);
        this.monthlyRemaining = parseInt(value, 10);
      }
    },
    monthlyRemaining: {
      get(): number | undefined {
        return this.editedEconomy?.monthlyRemaining;
      },
      set(monthlyRemaining: number): void {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          monthlyRemaining
        });
      }
    },
    displayTotalSavings: {
      get(): string {
        if (this.totalSavings !== undefined) {
          return this.totalSavings.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string): void {
        value = this.removeWhiteSpace(value);
        if (value === "") {
          value = "0";
        }
        this.totalSavings = parseInt(value, 10);
      }
    },
    totalSavings: {
      get(): number | undefined {
        return this.editedEconomy?.totalSavings;
      },
      set(totalSavings: number): void {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          totalSavings
        });
      }
    },
    displayBoundCapital: {
      get(): string {
        if (this.boundCapital !== undefined) {
          return this.boundCapital.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string): void {
        value = this.removeWhiteSpace(value);
        if (value === "") {
          value = "0";
        }
        this.boundCapital = parseInt(value, 10);
      }
    },
    boundCapital: {
      get(): number | undefined {
        return this.editedEconomy?.boundCapital;
      },
      set(boundCapital: number): void {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          boundCapital
        });
      }
    },
    displayTotalLoans: {
      get(): string {
        if (this.totalLoans !== undefined) {
          return this.totalLoans.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string): void {
        value = this.removeWhiteSpace(value);
        if (value === "") {
          value = "0";
        }
        this.totalLoans = parseInt(value, 10);
      }
    },
    totalLoans: {
      get(): number | undefined {
        return this.editedEconomy?.totalLoans;
      },
      set(totalLoans: number): void {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          totalLoans
        });
      }
    },
    rules(): any {
      return {
        required: (value: string) => (!!value && value !== "") || this.$t("required"),
        number: (value: string) => {
          if (value) {
            value = value.toString().replace(/\s/g, "");
            const pattern = /^\d+$/;
            return pattern.test(value) || this.$t("notANumber");
          }
          return true;
        }
      };
    }
  },
  watch: {
    // I dont like this at all but i seem to not found any other solution.
    // Need to watch all inputs to resize them and not duplicate same watcher five times to get the suffix to append properly instead of anchored to the right
    aggregatedInputs: {
      immediate: true,
      handler() {
        nextTick().then(() => {
          const refs = [
            "monthlySalaryInput",
            "monthlyRemainingInput",
            "boundCapitalInput",
            "totalLoansInput",
            "totalSavingInput"
          ];
          refs.forEach((r: string) => {
            this.resizeInputToCharacters((this.$refs[r] as any).$el.querySelector("input"));
          });
        });
      }
    }
  },

  methods: {
    async toggleEdit() {
      if (this.edit) {
        const val = await (this.$refs.form as any).validate();
        if (val.valid) {
          this.$emit("save");
          this.$store.commit(UserMutation.decreaseUnsavedEdits);
          this.edit = false;
        }
      } else {
        this.edit = true;
        this.$store.commit(UserMutation.increaseUnsavedEdits);
      }
    },
    resizeInputToCharacters(input: HTMLInputElement | null): void {
      if (input) {
        input.style.width = `${input.value.length}ch`;
      }
    },
    removeWhiteSpace(value: string): string {
      return value.toString().replace(/\s/g, "");
    }
  }
});
</script>
<style lang="scss" scoped>
.economy {
  padding: 1.5rem 1.5rem 1.625rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__description {
    padding: 0.5rem 0;
  }
  &__input {
    color: black;

    :deep(.v-text-field__slot) {
      max-width: 100%;
    }
    :deep(input) {
      min-width: unset;
      flex: unset;
      max-width: 100%;
    }
    :deep(.v-text-field__suffix) {
      flex: 1;
      text-align: left;
      cursor: text;
    }
    :deep(.v-text-field__prefix) {
      align-self: flex-end;
    }

    :deep(label) {
      color: #707070 !important;
      font-size: 1.2rem;
      font-weight: 300;
    }
    :deep(.v-text-field) {
      margin-top: 0;
    }
    &--bottom {
      grid-column: 1 / 3;
      grid-row: 3;
    }
    &--disabled {
      :deep(.v-text-field__suffix) {
        color: inherit !important;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-label) {
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
  }
}
</style>
