<template>
  <div class="link" @click="$emit('click')">
    {{ text }}
    <icon fixed-width :icon="['fal', 'angle-right']" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	emits: ["click"],
  props: {
    text: {
      type: String,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  text-decoration: underline;
  margin-top: 1rem;
  text-align: center;
}
</style>
