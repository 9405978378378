<template>
  <div class="overview">
    <SettingsHeader
      class="overview__header"
      :title="$t('portfolio-settings.strategy.overview.strategy')"
      :icon="['fal', 'sliders-h']"
    >
      <template #action>
        <InformationButton @click="() => (showDialog = true)" />
      </template>
    </SettingsHeader>
    <v-divider />
    <v-list>
      <v-list-item
        v-for="(item, i) in listItems"
        :key="i"
        class="overview__list-item"
      >
        <v-list-item-title class="overview__item">
          {{ item.key }}
        </v-list-item-title>
        <template #append>
          <span class="overview__item overview__item--medium overview__item--right-aligned">{{
            item.value
          }}</span>
        </template>
      </v-list-item>
    </v-list>
    <SideDialog v-model="showDialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SettingsHeader from "../header.vue";
import SideDialog from "./side-dialog.vue";
import type { Strategy } from "@/clients";
import { Market, StrategyFocus } from "@/clients";
import InformationButton from "@/components/information-button.vue";

interface listItem {
  key: string;
  value: string;
}

export default defineComponent({
  components: {
    SideDialog,
    SettingsHeader,
    InformationButton,
  },
  data: () => ({
    showDialog: false,
  }),
  computed: {
    strategy(): Strategy {
      return this.$store.state.portfolioStore.strategy;
    },
    ethicalValue(): string {
      if (!this.strategy) {
        return "-";
      }
      const discardedSectors = [
        this.strategy.defenseDiscarded,
        this.strategy.alcoholTobaccoDiscarded,
        this.strategy.gamblingDiscarded,
        this.strategy.fossilFuelsDiscarded,
      ];
      const numberOfDiscardedSectors = discardedSectors.filter(isDiscarded => isDiscarded).length;
      return this.$t("portfolio-settings.strategy.overview.discarded", numberOfDiscardedSectors);
    },
    focusValue(): string {
      if (!this.strategy) {
        return "-";
      }
      switch (this.strategy.focus as unknown as StrategyFocus) {
        case StrategyFocus.Dividend:
          return this.$t("focusDividendTitle");
        case StrategyFocus.Growth:
          return this.$t("focusGrowthTitle");
        default:
          return this.$t("focusStandardTitle");
      }
    },
    marketValue(): string {
      if (!this.strategy) {
        return "-";
      }
      switch (this.strategy.marketFocus) {
        case Market.Sweden:
          return this.$t("sweden");
        case Market.SwedenOld:
          return this.$t("swedenOld");
        case Market.Europe:
          return this.$t("marketFocusEurope");
        case Market.Us:
          return this.$t("usa");
        default:
          return this.$t("focusStandardTitle");
      }
    },
    listItems(): listItem[] {
      return [
        {
          value: this.ethicalValue,
          key: this.$t("ethicalSelection"),
        },
        {
          value: this.focusValue,
          key: this.$t("theme"),
        },
        {
          value: this.marketValue,
          key: this.$t("marketFocus"),
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.overview {
  &__header {
    padding: 1.125rem 1.25rem;
    max-height: 3.75rem;
  }
  &__list-item {
    padding: 0 1.25rem;
  }
  &__item {
    font-size: 1rem;
    font-family: $heading-font-family;
    line-height: 1.3em;
    font-weight: 300;
    &--medium {
      font-weight: 500;
    }
    &--right-aligned {
      text-align: right;
    }
  }
}
</style>
