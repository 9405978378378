<template>
  <single-choice
    :alternatives="alternatives"
    @click="buttonClick"
  >
    <information-text-section
      :heading="$t('create-portfolio.questions.account.connect-bank.monthlySavingQuestion')"
      :body="$t('create-portfolio.questions.account.connect-bank.body1')"
      :body2="$t('create-portfolio.questions.account.connect-bank.body2')"
    >
      <icon />
    </information-text-section>
  </single-choice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Question from "@/views/signup/templates/question";
import { SignupFlowModificationType } from "@/config/signup-modification";
import { SignupMutation } from "@/store/signup/mutations";
import Icon from "@/assets/images/signup/wallet-light.svg";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    Icon
  },
  extends: Question,
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("finishLater"),
          key: "finishLater",
          secondary: true
        },
        { text: this.$t("connectBankAccount"), key: "connectBankAccount" }
      ];
    }
  },
  methods: {
    async buttonClick(key: string): Promise<void> {
      if (key === "connectBankAccount") {
        await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
          include: true,
          modification: SignupFlowModificationType.Savings
        });
        // If we have bank accounts to choose from we let UseSameAccount take care of showing Tink stuff
        await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
          include:
            this.$store.state.signupStore.bankAccountAlternatives &&
            this.$store.state.signupStore.bankAccountAlternatives.length > 0,
          modification: SignupFlowModificationType.UseSameAccount
        });
        await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
          include:
            this.$store.state.signupStore.bankAccountAlternatives === undefined ||
            this.$store.state.signupStore.bankAccountAlternatives === null ||
            this.$store.state.signupStore.bankAccountAlternatives.length === 0,
          modification: SignupFlowModificationType.Tink
        });
      } else {
        await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
          include: false,
          modification: SignupFlowModificationType.Savings
        });
        // If we have bank accounts to choose from we let UseSameAccount take care of showing Tink stuff
        await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
          include: false,
          modification: SignupFlowModificationType.UseSameAccount
        });
        await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
          include: false,
          modification: SignupFlowModificationType.Tink
        });
      }

      this.$store.commit(SignupMutation.setBankAccountNumber, undefined);
      this.$store.commit(SignupMutation.setClearingNumber, undefined);
      this.$store.commit(SignupMutation.setBank, undefined);
      this.$store.commit(SignupMutation.setFullBankAccountNumber, undefined);
      this.$store.commit(SignupMutation.setFirstDeposit, undefined);
      this.$store.commit(SignupMutation.setMonthlySavingsAmount, undefined);
      this.$store.commit(SignupMutation.setBankAccountName, undefined);

      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
