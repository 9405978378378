export default (a: any, d: string, r: string, orderId: string | undefined = undefined) => {
  (window as any).adrecord = {
    programID: 1264,
    orderID: orderId
  } as any;

  const e = a.createElement(d);
  e.async = !0;
  e.src = r;
  const c = a.getElementsByTagName(d)[0];
  c.parentNode.insertBefore(e, c);
};
