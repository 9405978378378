<template>
  <div class="referral-intro">
    <div class="referral-intro__logo-container">
      <div class="referral-intro__logo-wrapper">
        <img
          class="referral-intro__logo"
          :src="logoUrl"
        />
      </div>
    </div>
    <single-choice
      :alternatives="alternatives"
      @click="buttonClick"
    >
      <information-text-section
        invisible-image
        left-align-body-mobile
        :heading="$t('signup.introduction.referral-intro.heading')"
        :body="$t('signup.introduction.referral-intro.body')"
      />
    </single-choice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import InformationTextSection from "@/views/signup/information-text-section.vue";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice
  },
  extends: Question,
  data(): any {
    return {
      logoUrl: new URL("/assets/images/logos/sigmastocks-logo-text.svg", import.meta.url).href
    };
  },
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("createAccount"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    buttonClick() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.referral-intro {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__logo-container {
    margin-top: 1rem;
    height: 3rem;
    display: block;
    margin-top: 1rem;
  }
  &__logo {
    path {
      fill: $soft-blue;
      opacity: 1;
    }
  }
  &__logo-wrapper {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
}
</style>
