<template>
  <single-choice
    :alternatives="navigationAlternatives"
    :error="showErrorMessage"
    :error-message="$t('errorAtLeastOneAlternative')"
    @click="goToNext"
  >
    <question-text-section
      progress
      :heading="$t('create-portfolio.questions.aml.purpose.heading')"
    />
    <multiple-choice-section
      :alternatives="alternatives"
      :error="showErrorMessage"
      @select="setSavingPurpose"
    />
  </single-choice>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SavingAlternative } from "@/types/signup";
import Question from "@/views/signup/templates/question";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import { SignupState } from "@/store/signup/types";

export default defineComponent({
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice
  },
  extends: Question,
  data() {
    return {
      showErrorMessage: false,
      navigationAlternatives: [
        {
          text: this.$t("next"),
          key: "next"
        }
      ]
    };
  },
  computed: {
    hasSelectedSavingsPurpose(): boolean {
      const { savingPurpose } = this.$store.state.signupStore as SignupState;
      return Object.keys(savingPurpose).some((key) => savingPurpose[key]);
    },
    alternatives() {
      return [
        {
          label: this.$t("create-portfolio.questions.aml.purpose.economicSafetySaving"),
          key: "savingForEconomicSafety",
          checked: this.isChecked("savingForEconomicSafety")
        },
        {
          label: this.$t("create-portfolio.questions.aml.purpose.pensionSaving"),
          key: "savingForRetirement",
          checked: this.isChecked("savingForRetirement")
        },
        {
          label: this.$t("create-portfolio.questions.aml.purpose.relativeSaving"),
          key: "savingForRelative",
          checked: this.isChecked("savingForRelative")
        },
        {
          label: this.$t("create-portfolio.questions.aml.purpose.consumptionSaving"),
          key: "savingForConsumption",
          checked: this.isChecked("savingForConsumption")
        }
      ];
    }
  },
  methods: {
    goToNext(): void {
      if (!this.hasSelectedSavingsPurpose) {
        this.showErrorMessage = true;
      } else {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    },
    setSavingPurpose(key: SavingAlternative, value: boolean): void {
      this.showErrorMessage = false;
      this.$store.commit(SignupMutation.setSavingPurpose, { key, value });
    },
    isChecked(savingAlternative: SavingAlternative): boolean {
      return (this.$store.state.signupStore as SignupState).savingPurpose[savingAlternative];
    }
  }
});
</script>
