<template>
  <div class="loans">
    <single-choice
      :alternatives="[
        {
          text: $t('next'),
          key: 'next'
        }
      ]"
      @click="buttonClick"
    >
      <question-text-section
        progress
        :heading="$t('common-signup.questions.economy.loans.heading')"
      />
      <multiple-choice-section
        :alternatives="alternatives"
        @select="setHasLoan"
      />
    </single-choice>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { MultipleChoiceAlternative, Loan } from "@/types/signup";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import SingleChoice from "../../../signup/templates/single-choice.vue";

export default defineComponent({
  emits: ["next"],
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice
  },
  extends: Question,
  computed: {
    alternatives(): MultipleChoiceAlternative[] {
      return [
        {
          label: this.$t("common-signup.questions.economy.loans.mortgageLoans"),
          key: "mortgage",
          checked: this.hasLoan("mortgage")
        },
        {
          label: this.$t("common-signup.questions.economy.loans.vehicleLoans"),
          key: "auto",
          checked: this.hasLoan("auto")
        },
        {
          label: this.$t("common-signup.questions.economy.loans.relativeLoans"),
          key: "relatives",
          checked: this.hasLoan("relatives")
        },
        {
          label: this.$t("common-signup.questions.economy.loans.otherLoans"),
          sublabel: this.$t("common-signup.questions.economy.loans.otherLoansExplanation"),
          key: "other",
          checked: this.hasLoan("other")
        }
      ];
    }
  },
  methods: {
    buttonClick(value: string): void {
      if (!this.$store.getters.hasRelevantLoans) {
        this.$store.commit(SignupMutation.setTotalLoans, 0);
      }
      this.$emit("next");
    },
    setHasLoan(key: Loan, value: boolean): void {
      this.$store.commit(SignupMutation.setHasLoan, { key, value });
    },
    hasLoan(loanType: Loan): boolean {
      return this.$store.state.signupStore.hasLoans[loanType];
    }
  }
});
</script>

<style lang="scss" scoped>
.loans {
  height: 100%;
  width: 100%;
}
</style>
