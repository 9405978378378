<template>
  <div class="loaned-amount-warning">
    <SingleChoice
      :alternatives="informationAlternatives"
      @click="selectInformation"
    >
      <InformationTextSection
        :heading="$t('create-portfolio.questions.economy.loaned-amount-warning.informationHeading')"
        :body="$t('create-portfolio.questions.economy.loaned-amount-warning.informationBody')"
        imagePadding="1.125rem 1.625rem 1.125rem 1.625rem"
      >
        <icon />
      </InformationTextSection>
    </SingleChoice>
  </div>
</template>

<script setup lang="ts">
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Icon from "@/assets/images/signup/file-invoice-dollar-light.svg";
import { SingleChoice as SingleChoiceType } from "@/types/signup";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const i18n = useI18n();
const router = useRouter();
const store = useStore();

const informationAlternatives: SingleChoiceType[] = [
  {
    text: i18n.t("quit"),
    key: "quit"
  }
];
function selectInformation(key: string): void {
  if (key === "quit") {
    router.push({ name: "login" });
  } else {
    store.dispatch(SignupNavigationAction.goToNextStep, router);
  }
}
</script>

<style lang="scss" scoped>
.loaned-amount-warning {
  height: 100%;
  width: 100%;
}
</style>
