import type { AdminState } from "./types";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { LegalEntity } from "@/clients";

export const getAdminInitialState = (): AdminState => ({
  users: undefined,
  legalEntities: undefined,
  companies: undefined,
  activeUser: undefined,
  activeLegalEntity: undefined,
  activePortfolios: [],
  activePortfolioSettings: [],
  activeStrategies: [],
  loadingUsers: undefined,
  activePortfolioSummaries: [],
  tradingDays: [],
  activePortfolioTransactions: [],
  activePortfolioAgreements: [],
  activeMessages: [],
  withdrawals: [],
  loadingWithdrawals: undefined,
  affiliateCampaigns: [],
  mifidAlerts: [],
  portfolioPerformances: [],
  portfolioPerformancesBricknode: [],
  loadingPortfolioPerformances: [],
  loadingPortfolioPerformancesBricknode: [],
});

const state = getAdminInitialState();

export default {
  state,
  mutations,
  getters,
  actions,
};
