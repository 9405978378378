<template>
  <div class="profile">
    <single-choice-template
      :alternatives="editRiskLevel ? editAlternatives : alternatives"
      @click="
        (key) => {
          editRiskLevel ? editSelect(key) : select(key);
        }
      "
    >
      <div class="profile__content">
        <v-carousel
          v-if="$vuetify.display.smAndDown"
          v-model="carouselIndex"
          class="profile__carousel"
          height="auto"
          data-cy="risk_carousel"
          :show-arrows="false"
          :continous="false"
          hide-delimiter-background
        >
          <v-carousel-item class="profile__risk-carousel-item">
            <v-card class="profile__card">
              <risk-level
                :calculated-risk-level="calculatedRiskLevel"
                :chosen-risk-level="chosenRiskLevel"
                :stocks-fraction="stocksFraction"
                :market-focus="marketFocus"
                :included-sectors="includedSectors"
                :edit-risk-level="editRiskLevel"
                :body-custom="bodyCustom"
                @setCustomRiskLevel="setCustomRiskLevel"
              />
            </v-card>
          </v-carousel-item>
          <v-carousel-item class="profile__choices-carousel-item">
            <v-card>
              <choices
                :market-focus="marketFocus"
                :included-sectors="includedSectors"
                :focus="focus"
              />
            </v-card>
          </v-carousel-item>
        </v-carousel>
        <v-card
          v-else
          class="profile__presentation"
        >
          <risk-level
            :calculated-risk-level="calculatedRiskLevel"
            :chosen-risk-level="chosenRiskLevel"
            :stocks-fraction="stocksFraction"
            :market-focus="marketFocus"
            :included-sectors="includedSectors"
            :edit-risk-level="editRiskLevel"
            :body-custom="bodyCustom"
            @setCustomRiskLevel="setCustomRiskLevel"
          />
          <v-divider
            v-if="!tiedAgent"
            vertical
          />
          <choices
            v-if="!tiedAgent"
            :market-focus="marketFocus"
            :included-sectors="includedSectors"
            :focus="focus"
          />
        </v-card>
        <question-text-section
          :heading="heading"
          :body="editRiskLevel ? bodyCustom : body"
          progress
        />
      </div>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SingleChoice } from "@/types/signup";
import InformationLink from "@/views/signup/information-link.vue";
import { TiedAgent } from "@/clients";
import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import RiskLevel from "./risk-level.vue";
import Choices from "./choices.vue";

export default defineComponent({
  emits: ["resetRiskLevel", "setCustomRiskLevel"],
  components: {
    SingleChoiceTemplate,
    QuestionTextSection,
    InformationLink,
    RiskLevel,
    Choices
  },
  extends: Question,
  props: {
    calculatedRiskLevel: { type: Number, required: true },
    chosenRiskLevel: { type: Number, required: true },
    stocksFraction: { type: Number, required: true },
    marketFocus: { type: Number, required: false },
    focus: { type: Number, required: false },
    includedSectors: { type: Object, required: true },
    body: { type: String },
    bodyCustom: { type: String },
    heading: { type: String }
  },
  data: () => ({
    carouselIndex: 0,
    editRiskLevel: false
  }),
  computed: {
    tiedAgent(): TiedAgent {
      return this.$store.state.signupStore.tiedAgent;
    },
    alternatives(): SingleChoice[] {
      return [
        {
          text: this.$t("change_risk_level"),
          key: "change",
          secondary: true
        },
        {
          text: this.$t("looks_good"),
          key: "ok"
        }
      ];
    },
    editAlternatives(): SingleChoice[] {
      return [
        {
          text: this.$t("common-signup.questions.profile.profile.cancel"),
          key: "cancel",
          secondary: true
        },
        {
          text: this.$t("select_and_continue"),
          key: "ok"
        }
      ];
    }
  },
  methods: {
    editSelect(key: string): void {
      if (key === "cancel") {
        this.$emit("resetRiskLevel");
        this.editRiskLevel = false;
      } else {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    },
    select(key: string): void {
      if (key === "change") {
        this.editRiskLevel = true;
        this.carouselIndex = 0;
      } else {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    },
    setCustomRiskLevel(value: number) {
      this.$emit("setCustomRiskLevel", value);
    }
  }
});
</script>

<style lang="scss" scoped>
$text-max-width: 21.25rem;
.profile {
  width: 100%;
  height: 100%;
  &__carousel {
    display: flex;
    justify-content: flex-end;
    flex-direction: column-reverse;
    height: 12rem !important;
    margin-bottom: 3vh;
    &:deep(.v-carousel__controls) {
      margin: 0 0.1rem;
      .v-btn {
        width: 0.75rem;
        height: 0.75rem;
        opacity: 0.5;
        &--active {
          opacity: 1;
        }
      }
      .v-icon {
        font-size: 0.5rem !important;
        width: 0.75rem !important;
        height: 0.75rem !important;
      }
    }
    &:deep(.v-carousel__controls) {
      color: $soft-blue;
      position: relative;
      height: auto;
    }
    &:deep(.v-carousel__item) {
      margin: 1rem;
      box-shadow: 0 0.1875rem 1.25rem rgba(96, 96, 96, 0.1);
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__presentation {
    display: flex;
    margin-bottom: 2rem;
  }
  &__risk-carousel-item {
    width: 12rem;
    margin: auto;
  }
  &__choices-carousel-item {
    width: $text-max-width;
    max-width: 100%;
    margin: auto;
  }
  &__card {
    width: 12rem;
    margin: 0 auto;
  }
}
</style>
