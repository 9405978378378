import type { GetterTree } from "vuex";
import type { RootState } from "../../types";
import type { SignupState } from "./types";
import { formatPersonalIdentityNumber } from "@/utils/formatters";
import type {
  DepositFrequency,
  DepositSize,
  Occupation,
  WithdrawalFrequency,
} from "@/clients";
import {
  CreatePersonLegalEntity,
  KnowYourCustomerAnswers,
  LegalEntity,
  RiskWillingness,
} from "@/clients";

export const getters: GetterTree<SignupState, RootState> = {
  // Any loans except student loans
  hasRelevantLoans(state): boolean {
    const { hasLoans } = state;
    return hasLoans.auto || hasLoans.mortgage || hasLoans.other || hasLoans.relatives;
  },
  totalAssets(state): number {
    return state.totalSavings + state.boundCapital;
  },
  solidity(state, signupGetters): number {
    return (signupGetters.totalAssets - state.totalLoans) / signupGetters.totalAssets;
  },
  calculatedRiskLevel(state): number | undefined {
    return state.calculatedRiskLevel;
  },
  chosenRiskLevel(state, signupGetters): number {
    return state.customRiskLevel !== undefined
      ? state.customRiskLevel
      : signupGetters.calculatedRiskLevel;
  },
  maximumRiskLevel(): number {
    return 5; // TODO Erik räkna ut detta
  },
  stocksFraction(_, signupGetters): number {
    return signupGetters.chosenRiskLevel * 0.2; // TODO Erik räkna ut detta
  },

  numberOfSustainabilityChoices(): number {
    return 6; // TODO Erik räkna ut detta
  },
  numberOfSelectedSustainabilityChoices(): number {
    return 4; // TODO Erik räkna ut detta
  },
  /* Allowed formats:
   * - "YYMMDDXXXX"
   * - "YYMMDD-XXXX"
   * - "YYMMDD+XXXX"
   * - "YYYYMMDDXXXX"
   * - "YYYYMMDD-XXXX"
   * - YYMMDDXXXX
   *
   * Returns undefined or the input formatted as "YYYYMMDDXXXX"
   */
  formattedPersonalIdentityNumber(state): string | undefined {
    return formatPersonalIdentityNumber(state.personalIdentityNumber);
  },
  signupKyc(state) {
    return new KnowYourCustomerAnswers({
      occupation: state.occupation as Occupation,
      depositFrequency: state.depositFrequency as DepositFrequency,
      withdrawalFrequency: state.withdrawalFrequency as WithdrawalFrequency,
      depositSize: state.depositAmount as DepositSize,
      savingForEconomicSafety: state.savingPurpose.savingForEconomicSafety,
      savingForRetirement: state.savingPurpose.savingForRetirement,
      savingForRelative: state.savingPurpose.savingForRelative,
      savingForConsumption: state.savingPurpose.savingForConsumption,
      incomeFromInvestment: state.investmentBackground.incomeFromInvestment,
      incomeFromSalaryPensionAllowance: state.investmentBackground.incomeFromSalaryPensionAllowance,
      incomeFromGambling: state.investmentBackground.incomeFromGambling,
      incomeFromResidence: state.investmentBackground.incomeFromResidence,
      incomeFromCompanySale: state.investmentBackground.incomeFromCompanySale,
      incomeFromGiftOrHeritage: state.investmentBackground.incomeFromGiftOrHeritage,
      incomeFromOther: state.investmentBackground.incomeFromOther,
      pep: state.pep as boolean,
      pepTitle: state.pep ? state.pepInfo.title : undefined,
      pepCountry: state.pep ? state.pepInfo.country : undefined,
      pepOrganization: state.pep ? state.pepInfo.organization : undefined,
      pepTerminatedYear: state.pep ? state.pepInfo.terminatedYear : undefined,
      pepTerminatedMonth: state.pep ? state.pepInfo.terminatedMonth : undefined,
      pepSavingsDescription: state.pep ? state.pepInfo.savingsDescription : undefined,
      pepPosition: undefined,
      associatedPepRelative: !!state.associatedPepRelative,
      associatedPepCoworker: !!state.associatedPepCoworker,
      associatedPepTitle:
        state.associatedPepRelative || state.associatedPepCoworker
          ? state.associatedPepInfo.title
          : undefined,
      associatedPepCountry:
        state.associatedPepRelative || state.associatedPepCoworker
          ? state.associatedPepInfo.country
          : undefined,
      associatedPepOrganization: state.associatedPepCoworker
        ? state.associatedPepInfo.organization
        : undefined,
      associatedPepOrganizationNumber: state.associatedPepCoworker
        ? state.associatedPepInfo.organizationNumber
        : undefined,
      associatedPepRelationship:
        state.associatedPepRelative || state.associatedPepCoworker
          ? state.associatedPepInfo.relationship
          : undefined,
      associatedPepName:
        state.associatedPepRelative || state.associatedPepCoworker
          ? `${state.associatedPepInfo.firstName} ${state.associatedPepInfo.lastName}`
          : undefined,
      associatedPepDateOfBirthOrSsn:
        state.associatedPepRelative || state.associatedPepCoworker
          ? state.associatedPepInfo.dateOfBirthOrSsn
          : undefined,
      associatedPepSavingsDescription:
        state.associatedPepRelative || state.associatedPepCoworker
          ? state.associatedPepInfo.savingsDescription
          : undefined,
      associatedPepTerminatedYear:
        state.associatedPepRelative || state.associatedPepCoworker
          ? state.associatedPepInfo.terminatedYear
          : undefined,
      associatedPepTerminatedMonth:
        state.associatedPepRelative || state.associatedPepCoworker
          ? state.associatedPepInfo.terminatedMonth
          : undefined,
      associatedPepPosition: undefined,
      livingInSweden: true, // TODO: Already answered in first signup, should handle this better
      monthlyIncome: state.monthlyIncome,
      taxableUsa: state.taxableUsa as boolean,
      citizenship1: state.citizenship1 as string,
      citizenship2: state.citizenship2 as string,
      citizenship3: state.citizenship3 as string,
      taxableOtherCountry: state.taxableOtherCountry as string,
      taxIdentificationNumber: state.taxIdentificationNumber as string,
      usaSsn: undefined,
      usaTaxIdentificationNumber: undefined,
      incomeFromOtherText: state.incomeFromOtherText,
      swedishTaxResidence: state.swedishTaxResidence as boolean,
    });
  },
  signupLegalEntity(state, signupGetters): CreatePersonLegalEntity {
    const { formattedPersonalIdentityNumber } = signupGetters;
    if (state.riskWillingness === undefined) {
      throw new Error("no chosenRiskWillingness in state");
    }
    return new CreatePersonLegalEntity({
      id: 0,
      email: state.contactDetails.email,
      phoneNumber: state.contactDetails.phoneNumber,
      personalIdentityNumber: formattedPersonalIdentityNumber,
      boundCapital: state.boundCapital,
      monthlyRemaining: state.monthlyRemainder,
      monthlySalary: state.monthlyIncome,
      totalLoans: state.totalLoans,
      totalSavings: state.totalSavings,
      principal: state.principal as boolean,
      chosenRiskWillingness: state.riskWillingness,
      firstName: state.firstName,
      lastName: state.lastName,
      address: state.address,
      zipCode: state.zipCode,
      city: state.city,
      brickId: "00000000-0000-0000-0000-000000000000",
      // Can only be called at the end due to default values
      // of pep, associatedPep and swedishTaxable (the last step is unreachable
      // if the values are different from the ones below). YAGNI.
      knowYourCustomerAnswers: signupGetters.signupKyc,
      minimizeNegativeSustainabilityImpact: !!state.sustainabilityImpact?.minimizeNegativeImpact,
      excludeNegativeSustainabilityImpact: !!state.sustainabilityImpact?.excludeNegativeImpact,
      takeSustainabilityImpactIntoAccount: !!state.takeSustainabilityIntoAccount,
      affiliateCampaign: undefined,
    });
  },
  yearlyKycLegalEntity(state, getters, rootState): LegalEntity {
    return new LegalEntity({
      ...(rootState as any).userStore.currentLegalEntity,
      boundCapital: state.boundCapital,
      totalLoans: state.totalLoans,
      totalSavings: state.totalSavings,
      monthlySalary: state.monthlyIncome,
      monthlyRemaining: state.monthlyRemainder,
      isNaturalPerson: true,
      takeSustainabilityImpactIntoAccount: state.takeSustainabilityIntoAccount,
      minimizeNegativeSustainabilityImpact: state.sustainabilityImpact?.minimizeNegativeImpact,
      excludeNegativeSustainabilityImpact: state.sustainabilityImpact?.excludeNegativeImpact,
      // TODO: taxonomyRegulation: state.sustainabilityImpact?.taxonomyRegulation,
      // TODO: disclosureRegulation: state.sustainabilityImpact?.disclosureRegulation,
      knowYourCustomerAnswers: new KnowYourCustomerAnswers({
        ...(rootState as any).userStore.currentLegalEntity.knowYourCustomerAnswers,
        monthlyIncome: state.monthlyIncome,
        occupation: state.occupation as Occupation,
        depositFrequency: state.depositFrequency as DepositFrequency,
        withdrawalFrequency: state.withdrawalFrequency as WithdrawalFrequency,
        depositSize: state.depositAmount as DepositSize,
        savingForEconomicSafety: state.savingPurpose.savingForEconomicSafety,
        savingForRetirement: state.savingPurpose.savingForRetirement,
        savingForRelative: state.savingPurpose.savingForRelative,
        savingForConsumption: state.savingPurpose.savingForConsumption,
        incomeFromInvestment: state.investmentBackground.incomeFromInvestment,
        incomeFromSalaryPensionAllowance:
          state.investmentBackground.incomeFromSalaryPensionAllowance,
        incomeFromGambling: state.investmentBackground.incomeFromGambling,
        incomeFromResidence: state.investmentBackground.incomeFromResidence,
        incomeFromCompanySale: state.investmentBackground.incomeFromCompanySale,
        incomeFromGiftOrHeritage: state.investmentBackground.incomeFromGiftOrHeritage,
        incomeFromOther: state.investmentBackground.incomeFromOther,
        pep: state.pep as boolean,
        pepTitle: state.pep ? state.pepInfo.title : undefined,
        pepCountry: state.pep ? state.pepInfo.country : undefined,
        pepOrganization: state.pep ? state.pepInfo.organization : undefined,
        pepTerminatedYear: state.pep ? state.pepInfo.terminatedYear : undefined,
        pepTerminatedMonth: state.pep ? state.pepInfo.terminatedMonth : undefined,
        pepSavingsDescription: state.pep ? state.pepInfo.savingsDescription : undefined,
        associatedPep: state.associatedPepRelative || (state.associatedPepCoworker as boolean),
        associatedPepTitle:
          state.associatedPepRelative || state.associatedPepCoworker
            ? state.associatedPepInfo.title
            : undefined,
        associatedPepCountry:
          state.associatedPepRelative || state.associatedPepCoworker
            ? state.associatedPepInfo.country
            : undefined,
        associatedPepOrganization: state.associatedPepCoworker
          ? state.associatedPepInfo.organization
          : undefined,
        associatedPepOrganizationNumber: state.associatedPepCoworker
          ? state.associatedPepInfo.organizationNumber
          : undefined,
        associatedPepRelationship:
          state.associatedPepRelative || state.associatedPepCoworker
            ? state.associatedPepInfo.relationship
            : undefined,
        associatedPepName:
          state.associatedPepRelative || state.associatedPepCoworker
            ? `${state.associatedPepInfo.firstName} ${state.associatedPepInfo.lastName}`
            : undefined,
        associatedPepDateOfBirthOrSsn: state.associatedPepRelative
          ? state.associatedPepInfo.dateOfBirthOrSsn
          : undefined,
        associatedPepSavingsDescription:
          state.associatedPepRelative || state.associatedPepCoworker
            ? state.associatedPepInfo.savingsDescription
            : undefined,
        associatedPepTerminatedYear:
          state.associatedPepRelative || state.associatedPepCoworker
            ? state.associatedPepInfo.terminatedYear
            : undefined,
        associatedPepTerminatedMonth:
          state.associatedPepRelative || state.associatedPepCoworker
            ? state.associatedPepInfo.terminatedMonth
            : undefined,
        livingInSweden: state.livingInSweden as boolean,
        taxableUsa: state.taxableUsa as boolean,
        citizenship1: state.citizenship1 as string,
        citizenship2: state.citizenship2 as string,
        citizenship3: state.citizenship3 as string,
        taxableOtherCountry: state.taxableOtherCountry as string,
        taxIdentificationNumber: state.taxIdentificationNumber as string,
        usaTaxIdentificationNumber: state.usaiTin as string,
        usaSsn: state.usaSsn as string,
        incomeFromOtherText: state.incomeFromOtherText,
        swedishTaxResidence: state.swedishTaxResidence as boolean,
      }),
    });
  },
  needsManualCheck(state): boolean {
    const { pep, associatedPepRelative, associatedPepCoworker } = state;
    return !!pep || !!associatedPepRelative || !!associatedPepCoworker;
  },
};
