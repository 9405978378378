import { actions } from "./actions";
import { getters } from "./getters";
import { mutations, getOverviewInitialState } from "./mutations";

const initialState = getOverviewInitialState();

export default {
  state: initialState,
  mutations,
  actions,
  getters
};
