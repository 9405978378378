<template>
  <div>
    <template v-if="state === DialogState.Info">
      <div
        v-if="validationAction === legalEntityValidationAction.VulnerableEconomy"
        class="le-validation"
      >
        <div class="le-validation__header-text">
          {{
            $t(
              `components.legal-entity-validation-change.vulnerableEconomyHeader${
                isCompany ? "Company" : ""
              }`
            )
          }}
        </div>

        <div class="le-validation__body-text">
          {{
            $t(
              `components.legal-entity-validation-change.vulnerableEconomyBody${
                isCompany ? "Company" : ""
              }`
            )
          }}
        </div>
        <br />
        <div class="le-validation__body-text">
          <span class="le-validation__body-text--bold"
            >{{
              $t(
                `components.legal-entity-validation-change.vulnerableEconomyHint${
                  isCompany ? "Company" : ""
                }`
              )
            }} </span
          >&nbsp;{{ $t("components.legal-entity-validation-change.vulnerableEconomyHint2") }}
          <span class="le-validation__body-text--bold"
            >{{
              $t("components.legal-entity-validation-change.vulnerableEconomyHint3")
            }}&nbsp;</span
          >{{
            $t(
              `components.legal-entity-validation-change.vulnerableEconomyHint4${
                isCompany ? "Company" : ""
              }`
            )
          }}
        </div>
        <div :class="['le-validation__button-wrapper', { 'le-validation__bottom': buttonBottom }]">
          <common-button @click="close">
            {{ $t("components.legal-entity-validation-change.ok") }}
          </common-button>
        </div>
      </div>
      <div
        v-else-if="validationAction === legalEntityValidationAction.TerminateCustomer"
        class="le-validation"
      >
        <div class="le-validation__header-text">
          {{
            $t(
              `components.legal-entity-validation-change.terminateCustomerHeader${
                isCompany ? "Company" : ""
              }`
            )
          }}
        </div>

        <div class="le-validation__body-text">
          {{
            $t(
              `components.legal-entity-validation-change.terminateCustomerBody1${
                isCompany ? "Company" : ""
              }`
            )
          }}
        </div>
        <br />
        <div class="le-validation__body-text">
          {{
            $t(
              `components.legal-entity-validation-change.terminateCustomerBody2${
                isCompany ? "Company" : ""
              }`
            )
          }}
        </div>
        <div :class="['le-validation__button-wrapper', { 'le-validation__bottom': buttonBottom }]">
          <common-button @click="close">
            {{ $t("components.legal-entity-validation-change.ok") }}
          </common-button>
        </div>
      </div>
      <div
        v-else-if="currentPortfolio !== undefined"
        class="le-validation"
      >
        <div class="le-validation__portfolio-header">
          <icon
            style="margin-right: 0.5rem"
            fixed-width
            :icon="['fal', 'briefcase']"
          />{{ currentPortfolio.name }}
        </div>

        <div class="le-validation__body-text">
          {{ $t("components.legal-entity-validation-change.newRecommendedRiskLevel") }}:
          <span class="le-validation__portfolio-header">{{
            currentPortfolio.recommendedRiskLevel
          }}</span>
        </div>
        <br />
        <div class="le-validation__body-text">
          {{
            $t(
              `components.legal-entity-validation-change.newRecommendedRiskLevelBody${
                isCompany ? "Company" : ""
              }`
            )
          }}
        </div>
        <br />
        <div class="le-validation__body-text">
          {{
            $t(
              `components.legal-entity-validation-change.newRecommendedRiskLevelQuestion${
                isCompany ? "Company" : ""
              }`
            )
          }}
          {{ currentPortfolio.recommendedRiskLevel }}?
        </div>
        <div :class="['le-validation__button-wrapper', { 'le-validation__bottom': buttonBottom }]">
          <common-button
            class="le-validation__button"
            secondary
            @click="changePortfolioOrClose"
          >
            {{ $t("components.legal-entity-validation-change.no") }}
          </common-button>
          <common-button @click="sign">
            {{ $t("components.legal-entity-validation-change.yes") }}
          </common-button>
        </div>
      </div></template
    >
    <template v-if="state === DialogState.Error">
      <div class="le-validation">
        <div class="le-validation__body-text">
          <error-loading
            style="margin: 0 auto"
            :body="$t('components.legal-entity-validation-change.errorBody')"
          />
        </div>
        <br />
        <br />
        <div :class="['le-validation__button-wrapper', { 'le-validation__bottom': buttonBottom }]">
          <common-button @click="() => (state = DialogState.Info)">
            {{ $t("components.legal-entity-validation-change.tryAgain") }}
          </common-button>
        </div>
      </div></template
    >
    <template v-if="state === DialogState.Preparing"
      ><div class="le-validation"><loading-spinner :model-value="true" /></div
    ></template>
    <template v-if="state === DialogState.Signing">
      <div class="le-validation">
        <bank-id
          :sign-location="SignLocation.LEGAL_ENTITY_VALIDATION_CHANGE"
          @complete="signComplete"
          @canceled="signCancel"
          @failed="signFailed"
        /></div
    ></template>
    <template v-if="state === DialogState.ChangingRiskLevel"
      ><div class="le-validation"><loading-spinner :model-value="true" /></div
    ></template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";
import { LegalEntityValidationAction, PdfType, PortfolioRecommendedRiskLevel } from "@/clients";
import { PortfolioAction } from "@/store/portfolio/actions";
import BankId from "@/components/bank-id.vue";
import { BankIdAction } from "@/store/bankid/actions";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import ErrorLoading from "@/components/error-loading.vue";
import { SignLocation } from "@/store/bankid/types";

enum DialogState {
  Info,
  Preparing,
  Signing,
  ChangingRiskLevel,
  Error
}

const legalEntityValidationAction = LegalEntityValidationAction;

const PROFILE_VALIDATION_CHANGE_CURRENT_PORTFOLIO_KEY =
  "PROFILE_VALIDATION_CHANGE_CURRENT_PORTFOLIO";
const PROFILE_VALIDATION_CHANGE_HANDLED_PORTFOLIO_IDS_KEY =
  "PROFILE_VALIDATION_CHANGE_HANDLED_PORTFOLIO_IDS";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    CommonButton,
    BankId,
    LoadingSpinner,
    ErrorLoading
  },
  props: {
    validationAction: {
      type: Number,
      required: true,
      default: LegalEntityValidationAction.NoChange
    },
    portfolios: {
      type: Array as () => PortfolioRecommendedRiskLevel[],
      required: true,
      default: () => [] as PortfolioRecommendedRiskLevel[]
    },
    isCompany: {
      type: Boolean,
      default: false
    },
    buttonBottom: {
      type: Boolean,
      required: false,
      default: false
    },
    signingComplete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      legalEntityValidationAction,
      handledPortfoliosIds: [] as string[],
      currentPortfolio: undefined as PortfolioRecommendedRiskLevel | undefined,
      state: DialogState.Info,
      DialogState,
      SignLocation
    };
  },
  watch: {
    portfolios: {
      immediate: true,
      handler(newValue) {
        if (newValue.length > 0) {
          const [next] = this.portfolios;
          this.currentPortfolio = next;
        }
      }
    }
  },
  created() {
    if (this.signingComplete) {
      this.currentPortfolio = PortfolioRecommendedRiskLevel.fromJS(
        JSON.parse(sessionStorage.getItem(PROFILE_VALIDATION_CHANGE_CURRENT_PORTFOLIO_KEY)!)
      );
      this.handledPortfoliosIds =
        JSON.parse(sessionStorage.getItem(PROFILE_VALIDATION_CHANGE_HANDLED_PORTFOLIO_IDS_KEY)!) ??
        [];

      this.signComplete();
    } else {
      sessionStorage.setItem(
        PROFILE_VALIDATION_CHANGE_HANDLED_PORTFOLIO_IDS_KEY,
        JSON.stringify([])
      );
    }
  },
  methods: {
    async sign() {
      this.state = DialogState.Preparing;
      sessionStorage.setItem(
        PROFILE_VALIDATION_CHANGE_CURRENT_PORTFOLIO_KEY,
        JSON.stringify(this.currentPortfolio)
      );
      if (this.currentPortfolio) {
        try {
          const { portfolioId, recommendedRiskLevel } = this.currentPortfolio;
          await this.$store.dispatch(PortfolioAction.prepareUpdateStrategyRiskLevel, {
            portfolioId,
            riskLevel: recommendedRiskLevel
          });
          this.state = DialogState.Signing;
          await this.$store.dispatch(BankIdAction.sign);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          this.state = DialogState.Error;
        }
      } else {
        this.state = DialogState.Error;
      }
    },
    async signComplete() {
      this.state = DialogState.ChangingRiskLevel;
      try {
        await this.changeRiskLevel();
      } catch (error) {
        this.state = DialogState.Error;
        throw error;
      }
    },
    signCancel() {
      this.state = DialogState.Info;
    },
    signFailed() {
      this.state = DialogState.Error;
    },
    async changeRiskLevel() {
      if (this.currentPortfolio) {
        const { portfolioId } = this.currentPortfolio;
        await this.$store.dispatch(PortfolioAction.completeUpdateStrategy, portfolioId);
        this.changePortfolioOrClose();
      }
    },
    changePortfolioOrClose() {
      if (this.currentPortfolio) {
        this.handledPortfoliosIds.push(this.currentPortfolio.portfolioId);
        sessionStorage.setItem(
          PROFILE_VALIDATION_CHANGE_HANDLED_PORTFOLIO_IDS_KEY,
          JSON.stringify(this.handledPortfoliosIds)
        );
        const unhandledPortfolios = this.portfolios.filter(
          (p) => this.handledPortfoliosIds.indexOf(p.portfolioId) === -1
        );
        if (unhandledPortfolios.length > 0) {
          const [next] = unhandledPortfolios;
          this.currentPortfolio = next;
          this.state = DialogState.Info;
        } else {
          this.close();
        }
      } else {
        this.close();
      }
    },
    close(): void {
      this.$emit("update:model-value", false);
    }
  }
});
</script>

<style lang="scss" scoped>
.le-validation {
  padding: 2rem 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 30rem;
  margin: 0 auto;
  &__header-text {
    font-size: 1.125rem;
    color: rgba($nearly-black, 0.8);
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  &__body-text {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    font-weight: 300;
    &--bold {
      font-weight: 400;
    }
  }
  &__button-wrapper {
    display: grid;
    grid-row-gap: 1rem;
    width: 100%;
    margin-top: 1rem;
    @include medium-up {
      display: flex;
      justify-content: space-around;
    }
    @include small-only {
      button {
        width: 100%;
      }
    }
  }
  &__portfolio-header {
    color: $nearly-black;
    font-size: 1.375rem;
    font-weight: 600;
  }
  &__bottom {
    margin-top: auto;
  }
}
</style>
