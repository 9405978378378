<template>
  <div class="staging">
    <v-form
      ref="form"
      class="staging__form"
      @submit.prevent="submit"
    >
      <v-text-field
        v-model="username"
        :rules="[rules.required]"
        type="text"
        :label="$t('staging-login.username')"
      />
      <v-text-field
        v-model="password"
        :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
        :rules="[rules.required]"
        :type="showPassword ? 'text' : 'password'"
        :label="$t('staging-login.password')"
        @click:append="showPassword = !showPassword"
      />
      <v-btn
        type="submit"
        @click="submit"
      >
        {{ $t("login") }}
      </v-btn>
      <p
        v-if="loginFailed"
        class="staging__error-message"
      >
        {{ $t("staging-login.loginError") }}
      </p>
    </v-form>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { baseUrl } from "@/clients/config";
import { StagingClient, StagingLoginRequest } from "@/clients";
import { StagingUser } from "@/types";

const stagingClient = new StagingClient(baseUrl);
const cacheKey = "stagingUser";

export default defineComponent({
  components: {},
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
      loginFailed: false,
      returnUrl: "",
      rules: {
        required: (value: string) => !!value || this.$t("required")
      }
    };
  },
  created() {
    this.returnUrl = (this.$route.query.returnUrl as string) || "/";
  },
  methods: {
    async submit() {
      this.loginFailed = false;
      if ((await (this.$refs.form as any).validate()).valid) {
        const request = new StagingLoginRequest({
          username: this.username,
          password: this.password
        });
        stagingClient
          .login(request)
          .then((loginResponse) => {
            if (loginResponse) {
              const user = {
                authData: window.btoa(`${request.username}:${request.password}`)
              } as StagingUser;
              sessionStorage.setItem(cacheKey, JSON.stringify(user));
              this.$router.push(this.returnUrl);
            }
          })
          .catch((e) => {
            this.loginFailed = true;
            sessionStorage.removeItem(cacheKey);
          });
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.staging {
  padding: 2rem;
  &__form {
    display: grid;
    justify-content: center;
    row-gap: 1rem;
    grid-template-rows: repeat(3, min-content);
  }

  &__error-message {
    margin-top: 1rem;
    color: red;
  }
}
</style>
