<template>
  <single-choice
    :alternatives="alternatives"
    @click="buttonClick"
  >
    <information-text-section
      :heading="$t('themeIntroHeading')"
      :body="$t('themeIntroBody')"
    >
      <icon />
    </information-text-section>
  </single-choice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import Icon from "@/assets/images/signup/sliders-light.svg";

export default defineComponent({
  components: {
    InformationTextSection,
    QuestionTextSection,
    SingleChoice,
    Icon
  },
  extends: Question,
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    buttonClick() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
