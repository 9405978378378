<template>
  <div class="account-input">
    <span
      class="account-input__title"
      data-jest="title"
    >
      {{ title }}
    </span>
    <div class="account-input__account-information">
      <span
        class="account-input--bold"
        data-jest="account-name"
      >
        {{ accountName }}
      </span>
      <span
        class="account-input__account-number-label"
        data-jest="account-number-label"
      >
        {{ accountNumber }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    accountName: { type: String, required: true },
    accountNumber: { type: String, default: "" }
  }
});
</script>

<style lang="scss" scoped>
.account-input {
  margin-bottom: 1.75rem;
  display: grid;
  grid-template-columns: auto auto;
  &__account-information {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
  &__account-title {
    font-weight: 300;
  }
  &__account-number-label {
    color: $nearly-black;
    opacity: 0.63;
    font-weight: 300;
  }
  &--bold {
    font-weight: 600;
  }
}
</style>
