<template>
  <div class="excluded">
    <excluded-header
      :title="$t('excludedCompanies')"
      :icon="['fal', 'ban']"
    >
      <template #action>
        <edit-button
          :disabled="disabled"
          :edit="edit"
          @click="toggleEdit"
        />
      </template>
      <template #info>
        <information-button @click="() => (showDialog = true)" />
      </template>
    </excluded-header>
    <div class="excluded__container">
      <search-companies
        v-if="edit && editedExcludedCompanies.length < 10"
        :filtered-companies="editedExcludedCompanies"
        @selected="addCompany"
      />

      <div
        v-if="edit && editedExcludedCompanies.length >= 10"
        class="excluded__warning"
      >
        <p>
          {{ $t("portfolio-settings.exluded-companies.excluded-companies.alreadyExcludedLimit") }}
        </p>
      </div>
      <v-list
        v-if="editedExcludedCompanies.length > 0"
        variant="flat"
        class="excluded__list"
      >
        <v-list-item
          v-for="(item, i) in expanded
            ? editedExcludedCompanies
            : editedExcludedCompanies.slice(0, 4)"
          :key="i"
          class="excluded__list-item"
        >
          <v-list-item-title class="excluded__item-title">{{ item.name }}</v-list-item-title>
          <template #prepend>
            <img
              v-if="item.countryFullName"
              style="margin-right: 1rem; width: 1.2rem"
              :src="getFlagUrl(item.countryFullName)"
            />
          </template>
          <template #append>
            <v-btn
              v-if="edit"
              style="margin-right: -0.75rem"
              icon
              variant="flat"
              @click="() => removeCompany(i)"
            >
              <icon
                style="font-size: 1.25rem"
                fixed-width
                :icon="['fal', 'times']"
              />
            </v-btn>
          </template>
        </v-list-item>
        <v-list-item
          v-if="editedExcludedCompanies.length > 4"
          class="excluded__list-item--expandable"
          @click="() => (expanded = !expanded)"
        >
          <v-list-item-title class="excluded__item-title">
            {{
              expanded
                ? $t("portfolio-settings.exluded-companies.excluded-companies.viewLess")
                : $t("portfolio-settings.exluded-companies.excluded-companies.viewMore")
            }}
          </v-list-item-title>
          <template v-slot:prepend>
            <div>
              <icon
                fixed-width
                :icon="['fal', expanded ? 'chevron-up' : 'chevron-down']"
              />
            </div>
          </template>
        </v-list-item>
      </v-list>
      <span
        v-if="editedExcludedCompanies.length === 0 && !edit"
        class="excluded__empty-state-text"
        >{{ $t("portfolio-settings.exluded-companies.excluded-companies.emptyStateText") }}</span
      >
    </div>
    <side-dialog
      v-model="showDialog"
      @update:model-value="(val) => (showDialog = val)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EditButton from "@/components/edit-button.vue";
import { Company, ExcludedCompany } from "@/clients";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import InformationButton from "@/components/information-button.vue";
import ExcludedHeader from "../header.vue";
import SearchCompanies from "./search.vue";
import SideDialog from "./side-dialog.vue";
import { getFlagUrl } from "@/config/flags";

export default defineComponent({
  emits: ["save"],
  components: { ExcludedHeader, EditButton, SearchCompanies, InformationButton, SideDialog },
  data: () => ({
    edit: false,
    expanded: false,
    showDialog: false,
    getFlagUrl
  }),
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    editedExcludedCompanies: {
      get(): ExcludedCompany[] {
        if (this.$store.state.portfolioStore.editedStrategy) {
          return this.$store.state.portfolioStore.editedStrategy?.excludedCompanies;
        }
        return [];
      },
      set(excludedCompanies: ExcludedCompany[]) {
        this.$store.commit(PortfolioMutation.editExludedCompanies, excludedCompanies);
      }
    },
    strategyId(): number | undefined {
      if (this.$store.state.portfolioStore.strategy) {
        return this.$store.state.portfolioStore.strategy?.strategyId;
      }
      return undefined;
    }
  },

  methods: {
    async toggleEdit() {
      if (this.edit) {
        this.$emit("save", { excludedCompanies: this.editedExcludedCompanies });
      } else {
        this.$store.commit(PortfolioMutation.increaseUnsavedEdits);
      }
      this.edit = !this.edit;
    },
    addCompany(company: Company) {
      this.editedExcludedCompanies = [
        ...this.editedExcludedCompanies,
        new ExcludedCompany({
          infrontCompanyCode: company.infrontCompanyCode,
          name: company.name,
          strategyId: this.strategyId as number,
          countryFullName: company.countryFullName
        })
      ];
      if (!this.expanded) this.expanded = !this.expanded;
    },
    removeCompany(idx: number) {
      this.editedExcludedCompanies = this.editedExcludedCompanies.filter((_, i) => i !== idx);
    }
  }
});
</script>

<style lang="scss" scoped>
.excluded {
  padding: 1rem 1.5rem 1.625rem;
  &__container {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
  }
  &__list {
    padding: 0;
  }
  &__item-title {
    font-size: 1rem;
    font-family: $heading-font-family;
    line-height: 1.3em;
    font-weight: 300;
  }

  &__list-item {
    padding: 0 0.35rem;
    border-radius: 0.5rem;
    &--expandable {
      padding: 0 4rem;
      border-radius: 0.5rem;
      &:hover {
        background-color: $whisper;
      }
    }
  }
  &__flag {
    width: 1.25rem;
  }
  &__warning {
    text-align: center;
    color: $deep-red;
    font-size: 1rem;
    font-weight: 100;
  }
  &__empty-state-text {
    font-family: $heading-font-family;
    font-weight: 100;
  }
}
</style>
