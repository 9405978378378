<template>
  <loans @next="next" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Loans from "@/views/common-signup/questions/economy/loans.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { Loan } from "@/types/signup";
import { SignupFlowModificationType } from "@/config/signup-modification";

export default defineComponent({
  components: { Loans },
  methods: {
    hasLoan(loanType: Loan): boolean {
      return this.$store.state.signupStore.hasLoans[loanType];
    },
    async next() {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.HasLoans,
        include: this.$store.getters.hasRelevantLoans
      });
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.ExpensiveLoans,
        include: this.hasLoan("other")
      });
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
