import { MutationTree } from "vuex";
import { RouteLocation } from "vue-router";
import { AutogiroStatus, PortfolioBase } from "@/clients";
import { NavigationState } from "./types";

export const NavigationMutation = {
  setMobileHeading: "setMobileHeading",
  setBackRoute: "setBackRoute",
  clearBackRoute: "clearBackRoute",
  setPortfolios: "setPortfolios",
  setNavigationState: "setNavigationState",
  updatePortfolioName: "updatePortfolioName",
  setPortfolioTransferReceiverAsCreated: "setPortfolioTransferReceiverAsCreated"
};

export const mutations: MutationTree<NavigationState> = {
  [NavigationMutation.setMobileHeading](state, payload: string) {
    state.mobileHeading = payload;
  },
  [NavigationMutation.setBackRoute](state, route: RouteLocation) {
    state.backRoute = route;
  },
  [NavigationMutation.clearBackRoute](state) {
    state.backRoute = undefined;
  },
  [NavigationMutation.setPortfolios](state, portfolios: PortfolioBase[]) {
    state.portfolios = portfolios;
  },
  [NavigationMutation.setNavigationState](state, stateChange: NavigationState) {
    Object.assign(state, stateChange);
  },
  [NavigationMutation.updatePortfolioName](state, portfolio: PortfolioBase) {
    if (state.portfolios) {
      const item = state.portfolios.find((p) => p.id === portfolio.id);
      if (item) {
        item.name = portfolio.name;
      }
    }
  },
  [NavigationMutation.setPortfolioTransferReceiverAsCreated](state, portfolio: PortfolioBase) {
    if (state.portfolios) {
      const item = state.portfolios.find((p) => p.id === portfolio.id);
      if (item) {
        item.autogiroStatus = AutogiroStatus.Created;
        item.transferReceiverId = portfolio.transferReceiverId;
      }
    }
  }
};
