<template>
  <v-dialog
    v-model="showDialog"
    persistent
    content-class="logged-out"
  >
    <v-card class="logged-out__card">
      <p class="logged-out__header">
        {{ $t("components.logged-out-dialog.header") }}
      </p>
      <p class="logged-out__body">
        {{ $t("components.logged-out-dialog.body") }}
      </p>
      <v-card-actions class="logged-out__actions">
        <common-button
          class="logged-out__confirm"
          @click="confirm"
        >
          {{ $t("ok") }}
        </common-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";

export default defineComponent({
  emits: ["update:model-value", "confirm"],
  components: {
    CommonButton
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:model-value", value);
      }
    }
  },
  methods: {
    confirm(): void {
      this.$emit("confirm");
    }
  }
});
</script>

<style lang="scss" scoped>
.logged-out {
  &__card {
    padding: 2rem 2rem 1rem 2rem;
  }
  &__header {
    font-family: $heading-font-family;
    font-size: 1.125rem;
    font-weight: 500;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;
    @include medium-up {
      text-align: left;
    }
  }
  &__body {
    font-family: $heading-font-family;
    font-size: 1rem;
    font-weight: 300;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;
    @include medium-up {
      text-align: left;
    }
  }
  &__actions {
    justify-content: center;
  }
  &__confirm {
    margin-top: 0.5rem;
    @include xsmall-up {
      margin-top: 0rem;
    }
  }
}
:deep(.logged-out) {
  max-width: 30rem;
}
</style>
