import type { PortfolioState } from "./types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { DepositState } from "@/types/portfolio";

export const getPortfolioInitialState = (): PortfolioState => ({
  unsavedEdits: 0,
  withdrawAmount: undefined,
  portfolioSettings: undefined,
  editedPortfolioSettings: undefined,
  deposits: undefined,
  savingsGoal: undefined,
  dismissedHoldingOverlay: false,
  isLoadingPortfolioPerformance: false,
  isLoadingTransactions: false,
  portfolioPerformance: undefined,
  transactionEvents: undefined,
  nextDateEvent: undefined,
  activePortfolioBase: undefined,
  strategy: undefined,
  transactionList: undefined,
  editedStrategy: undefined,
  holdingDetails: [],
  holdingSummaries: {},
  isLoadingHoldingSummary: false,
  dividend: [],
  isLoadingBackcast: true,
  isLoadingPortfolioDividend: false,
  backcast: undefined,
  performanceError: false,
  holdingsError: false,
  transactionsError: false,
  dividendError: false,
  updateAgreementsDialogCallback: undefined,
  saveSettingsError: false,
  saveSettingsNoChange: false,
  ongoingWithdrawalSum: undefined,
  hasSellWithdrawal: false,
  hasWithdrawalOrderSent: false,
  showDepositDialog: false,
  showWithdrawalDialog: false,
  showDividendDialog: false,
  showSavingsGoalSideDialog: false,
  showEventsDialog: false,
  isLoadingHoldingDetails: false,
  loadingHoldingDetailsError: false,
  nextTradingDay: undefined,
  currentTab: undefined,
  yesterdaysTradingDay: undefined,
  finsharkPaymentId: undefined,
  swishPaymentReference: undefined,
  swishTransactionStatus: undefined,
  swishToken: undefined,
  depositState: DepositState.CHOOSING_AMOUNT,
  allPortfolios: [],
  availableCashForWithdrawal: undefined,
  amountCanBeWithdrawn: undefined,
  agreements: undefined,
  showPortfolioCreatedDialog: false,
  showConnectBankSideDialog: false,
  loadingEvents: false,
  ongoingWithdrawals: undefined,
  loadingOngoingWithdrawals: false,
  paiStats: undefined,
});
const state = getPortfolioInitialState();
export default {
  state,
  getters,
  mutations,
  actions,
};
