import type { SignupState } from "./types";
import { getPepInitialState, mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";
import { softBlue } from "@/styles/colors";
import { RegistrationSignState } from "@/types/signup";

export const getSignUpInitialState = (): SignupState => ({
  trackingGuid: undefined,
  showProgressBar: false,
  contactDetails: {
    email: "",
    phoneNumber: "",
  },
  acceptedPersonalDataPolicy: true,
  timeToWithdraw: undefined,
  monthlyIncome: 20000,
  monthlyRemainder: 1000,
  totalSavings: 50000,
  boundCapital: 0,
  includedSectors: {
    fossilFuel: true,
    alcoholTobacco: true,
    weaponsAndDefense: true,
    casinosAndGambling: true,
  },
  focus: undefined,
  riskWillingness: undefined,
  hasLoans: {
    auto: false,
    relatives: false,
    mortgage: false,
    other: false,
  },
  totalLoans: 100000,
  customRiskLevel: undefined,
  bankName: undefined,
  occupation: undefined,
  withdrawalFrequency: undefined,
  personalIdentityNumber: "",
  depositAmount: undefined,
  savingPurpose: {
    savingForRetirement: false,
    savingForRelative: false,
    savingForConsumption: false,
    savingForEconomicSafety: false,
  },
  investmentBackground: {
    incomeFromInvestment: false,
    incomeFromSalaryPensionAllowance: false,
    incomeFromGambling: false,
    incomeFromResidence: false,
    incomeFromCompanySale: false,
    incomeFromGiftOrHeritage: false,
    incomeFromOther: false,
  },
  clearingNumber: "",
  firstDeposit: undefined,
  monthlySavingsAmount: undefined,
  chosenPaymentMethod: undefined,
  selectedBankAccount: undefined,
  marketFocus: undefined,
  calculatedRiskLevel: undefined,
  completedSignUp: false,
  depositFrequency: undefined,
  pep: undefined,
  associatedPepRelative: undefined,
  associatedPepCoworker: undefined,
  livingInSweden: undefined,
  taxableUsa: undefined,
  highExpenses: undefined,
  loadingPdf: [],
  citizenship1: undefined,
  citizenship2: undefined,
  citizenship3: undefined,
  swedishTaxResidence: undefined,
  taxableOtherCountry: undefined,
  taxIdentificationNumber: undefined,
  usaiTin: undefined,
  usaSsn: undefined,
  principal: undefined,
  bankAccountNumber: undefined,
  bankAccountName: undefined,
  loadingAccountDetails: false,
  fullBankAccountNumber: undefined,
  clearingNumberParsingError: false,
  tinkError: false,
  bankAccountAlternatives: [],
  selectedBankAccountAlternative: undefined,
  registrationSignState: RegistrationSignState.ENTER_SSN,
  registeredUserId: undefined,
  referralCode: undefined,
  affiliateReferralCode: undefined,
  affiliateReferralName: undefined,
  firstName: undefined,
  lastName: undefined,
  address: undefined,
  zipCode: undefined,
  city: undefined,
  pepInfo: getPepInitialState(),
  associatedPepInfo: getPepInitialState(),
  legalEntityValidationChange: undefined,
  incomeFromOtherText: undefined,
  takeSustainabilityIntoAccount: false,
  esgFilter: {
    esgBiodiversityFilter: false,
    esgEmissionsFilter: false,
    esgGovernanceFilter: false,
    esgHumanRightsFilter: false,
    esgResourcesFilter: false,
  },
  sustainabilityImpact: {
    minimizeNegativeImpact: false,
    noPreference: false,
    excludeNegativeImpact: false,
    taxonomyRegulation: undefined,
    disclosureRegulation: undefined,
    minimizeNegativeImpactChanged: false,
  },
  editedPortfolios: [],
  registrationError: undefined,
  registeredUserApproved: undefined,
  tiedAgent: undefined,
  portfolioTheme: undefined,
  primaryColor: softBlue,
  esgPais: {
    pai1: false,
    pai2: false,
    pai3: false,
    pai4: false,
    pai5: false,
    pai7: false,
    pai9: false,
    pai10: false,
    pai11: false,
    pai13: false,
    pai14: false,
  },
  colors: undefined,
  showEvidaLogoHeader: false,
});

const state = getSignUpInitialState();

export default {
  state,
  mutations,
  actions,
  getters,
};
