<template>
  <focus
    :heading="$t('chooseFocus')"
    :body="$t('create-portfolio.questions.preferences.focus.body')"
    @select="select"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { StrategyFocus } from "@/clients";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import Focus from "@/views/common-signup/questions/preferences/focus.vue";

export default defineComponent({
  components: { Focus },
  computed: {
    riskWillingness() {
      return this.$store.state.signupStore.riskWillingness;
    }
  },
  methods: {
    async select(key: StrategyFocus) {
      this.$store.commit(SignupMutation.setFocus, key);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
