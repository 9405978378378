<template>
  <v-list-item
    v-bind="$attrs"
    class="dropdown-item"
    :class="[{ 'dropdown-item--disabled': disabled }]"
    @click="(event) => $emit('click', event)"
  >
    <template #prepend>
      <div class="dropdown-item__icon">
        <slot name="prepended-icon" />
      </div>
    </template>
    <v-badge
      v-if="badgeInline"
      :model-value="badgeInline"
      inline
      color="transparent"
    >
      <template #badge>
        <slot name="badge-icon" />
      </template>
      <v-list-item-title
        class="dropdown-item__title"
      >
        {{ title }}
      </v-list-item-title>
    </v-badge>
    <v-badge
      v-else
      :model-value="badge"
      class="dropdown-item__badge"
      dot
      color="#FF0000"
    >
      <v-list-item-title
        class="dropdown-item__title"
      >
        {{ title }}
      </v-list-item-title>
    </v-badge>
    <template #append>
      <icon
        v-if="indicateClickabilityIcon"
        :icon="['fal', 'chevron-right']"
      />
    </template>
  </v-list-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    indicateClickabilityIcon: {
      type: Boolean,
      required: false,
    },
    badge: {
      type: Boolean,
      required: false,
      default: false,
    },
    badgeInline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["click"],
});
</script>

<style lang="scss" scoped>
.dropdown-item {
  height: 3.5rem;
  background-color: $nearly-black;
  padding: 0 0.5rem 0 2rem;
  &--disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  &__icon {
    margin: 0 !important;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  &__title {
    padding-bottom: 0.1rem;
    padding-right: 0.5rem;
    margin-left: 0.5rem;
    text-overflow: ellipsis;
    font-size: 400;
    font-family: $heading-font-family;
  }
}
</style>
