<template>
  <div class="personal-data">
    <card-header
      class="personal-data__header"
      :title="$t('profile.personal-data.personalData')"
      :icon="['fal', 'address-book']"
    >
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-btn
            v-if="!sparDisabled"
            v-bind="props"
            variant="flat"
            icon
            class="personal-data__update-button"
            @click="update"
          >
            <icon
              class="personal-data__update-button-icon"
              :icon="['fal', 'sync']"
            />
          </v-btn> </template
        ><span> {{ $t("profile.personal-data.updateButtonHint") }}</span>
      </v-tooltip>
    </card-header>
    <v-form
      ref="form"
      class="personal-data__form"
    >
      <v-text-field
        v-model="firstName"
        :class="'personal-data__input'"
        disabled
        variant="underlined"
        type="text"
        :label="$t('name')"
      />
      <v-text-field
        v-model="lastName"
        :class="'personal-data__input'"
        disabled
        variant="underlined"
        type="text"
        :label="$t('lastName')"
      />
      <v-text-field
        v-model="address"
        :class="'personal-data__input'"
        disabled
        variant="underlined"
        type="text"
        :label="$t('address')"
      />
      <v-text-field
        v-model="zipCodeAndCity"
        :class="'personal-data__input'"
        disabled
        variant="underlined"
        type="tel"
        :label="$t('zipCodeAndCity')"
      />
    </v-form>
    <loading-spinner-overlay :model-value="loading" />
  </div>
</template>
<script lang="ts">
import { DateTime } from "luxon";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { UserAction } from "@/store/user/action-definitions";
import { LATEST_SPAR_FETCH } from "@/config/general";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import CardHeader from "@/components/card-header.vue";

export default defineComponent({
  components: { CardHeader, LoadingSpinnerOverlay },
  data: () => ({
    sparDisabled: false,
    loading: false
  }),
  computed: {
    ...mapGetters(["legalEntity"]),
    firstName(): string | undefined {
      if (this.legalEntity) {
        return this.legalEntity.firstName;
      }
      return "-";
    },
    lastName(): string | undefined {
      if (this.legalEntity) {
        return this.legalEntity.lastName;
      }
      return "-";
    },
    address(): string | undefined {
      if (this.legalEntity) {
        return this.legalEntity.address;
      }
      return "-";
    },
    zipCode(): string | undefined {
      if (this.legalEntity) {
        return this.legalEntity.zipCode;
      }
      return "-";
    },
    city(): string | undefined {
      if (this.legalEntity) {
        return this.legalEntity.city;
      }
      return "-";
    },
    zipCodeAndCity(): string | undefined {
      if (this.zipCode && this.city) {
        return `${this.zipCode}, ${this.city}`;
      }
      if (this.zipCode && !this.city) {
        return this.zipCode;
      }
      if (this.city && !this.zipCode) {
        return this.city;
      }
      return "-";
    }
  },
  created() {
    const latestFetch = localStorage.getItem(LATEST_SPAR_FETCH);
    if (latestFetch) {
      const date = DateTime.fromISO(JSON.parse(latestFetch));
      if (DateTime.local().startOf("day").toISODate() === date.startOf("day").toISODate()) {
        this.sparDisabled = true;
      }
    }
  },

  methods: {
    async update() {
      this.loading = true;
      try {
        await this.$store.dispatch(UserAction.updateLegalEntitySpar);
        localStorage.setItem(LATEST_SPAR_FETCH, JSON.stringify(DateTime.local()));
        this.sparDisabled = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.personal-data {
  padding: 1.5rem 1.5rem 0.825rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__form {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
  }
  &__input {
    color: black;
    @include small-down {
      width: 100%;
    }
    :deep(label) {
      color: #707070 !important;
      font-size: 1.2rem;
      font-weight: 300;
    }
    :deep(.v-text-field) {
      margin-top: 0;
    }
    :deep(input) {
      color: inherit !important;
    }
    :deep(.v-field__outline:before) {
      border: none !important;
      border-color: transparent !important;
    }
    :deep(.v-label) {
      max-width: 133%;
    }
    :deep(.v-label) {
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
  }
  &__update-button {
    margin: -1.25rem -0.75rem -1.25rem 0;
    width: 2.75rem !important;
    height: 2.75rem !important;
  }
  &__update-button-icon {
    color: $nearly-black;
    font-size: 1.25rem;
    opacity: 0.63;
    &--edit {
      opacity: 1;
    }
  }
}
:deep(.v-field--disabled) {
  color: $nearly-black;
  opacity: 1;
}
</style>
