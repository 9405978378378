<template>
  <div class="citizenship">
    <single-choice-template :alternatives="alternatives" :two-columns="true" @click="select">
      <question-text-section
        :heading="$t('swedishResidentHeading')"
        progress
        :body="$t('swedishResidentBody')"
      />
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";

import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";

export default defineComponent({
	emits: ["next"],
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("no"),
          key: "noSwedishCitizenship",
          secondary: true
        },
        {
          text: this.$t("yes"),
          key: "hasSwedishCitizenship"
        }
      ];
    }
  },
  methods: {
    select(key: string): void {
      this.$store.commit(SignupMutation.setLivingInSweden, key === "hasSwedishCitizenship");
      this.$emit("next", key);
    }
  }
});
</script>

<style lang="scss" scoped>
.citizenship {
  height: 100%;
  width: 100%;
}
</style>
