<template>
  <div
    class="market-column-chart"
    :style="{ height: barHeightRem + 2 + 'rem' }"
  >
    <div class="market-column-chart__value--align-middle">
      <span
        v-if="!$vuetify.display.lgAndUp"
        class="market-column-chart__value"
        data-jest="value"
      >
        {{ formatFraction(modelValue) }}
      </span>
      <div
        v-if="displayValue"
        class="market-column-chart__chart"
        data-jest="chart"
        :style="{
          height: formatChartValue,
          backgroundColor: color
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    highestValue: {
      type: Number,
      default: undefined
    },
    modelValue: {
      type: Number,
      default: undefined
    },
    legend: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: "#000"
    }
  },
  data: (): any => ({
    displayValue: 0,
    barHeightRem: 6.75
  }),
  computed: {
    percentageFormatter(): any {
      const localeStringOptions = {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
      };
      return Intl.NumberFormat("sv-SE", localeStringOptions);
    },
    formatChartValue(): string {
      const relativePercent = this.displayValue / this.highestValue;
      const relativeHeight = relativePercent * this.barHeightRem;
      return `${relativeHeight}rem`;
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newVal: number | undefined, oldVal: number | undefined) {
        if (newVal !== undefined) {
          // Animate the column chart value.
          this.$animejs({
            targets: this,
            displayValue: this.modelValue,
            duration: 300,
            easing: "easeInOutSine"
          });
        }
      }
    }
  },
  methods: {
    formatFraction(fraction: number): string {
      let formattedPercentage = "-";
      if (fraction !== undefined) {
        formattedPercentage = this.percentageFormatter.format(fraction * 100);
      }
      return `${formattedPercentage} %`;
    }
  }
});
</script>

<style lang="scss" scoped>
.market-column-chart {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.25rem;
  @include large-up {
    margin-bottom: 0rem;
  }
  &__value {
    text-align: center;
    font-weight: bold;
    color: $nearly-black;
    &--align-middle {
      line-height: 0.7rem;
      margin: 0 auto;
    }
  }
  &__chart {
    margin: 0.75rem auto 0 auto;
    width: 1.5rem;
    box-shadow: 0 0.1875rem 0.625rem rgba(0, 0, 0, 0.1);
    border-radius: 0.125rem;
  }
}
</style>
