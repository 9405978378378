<template>
  <div class="swish-deposit">
    <label class="swish-deposit__subheader">{{
      $t(state === State.Complete ? "completed" : "portfolio.overview.deposit.swish-deposit.title")
    }}</label>
    <template v-if="state === State.Form">
      <account-input
        :title="$t('to')"
        :account-name="portfolioName"
      />
      <v-form
        ref="form"
        @submit.prevent="deposit"
      >
        <label
          v-if="$vuetify.display.mdAndUp"
          class="swish-deposit__from-number"
          >{{ $t("portfolio.overview.deposit.swish-deposit.fromNumber") }}</label
        >
        <phone-number-input
          v-if="$vuetify.display.mdAndUp"
          :initial-phone-number="phoneNumber"
          disable-lang
          hide-phone-number-label
          form-name="phone"
          required
          @update:model-value="(val) => (phoneNumber = val)"
        />
        <v-text-field
          v-model="displayDepositAmount"
          type="tel"
          variant="underlined"
          :label="$t('sum')"
          :suffix="$t('currency')"
          :rules="[...(isTestUser ? [] : [rules.depositMinimum]), rules.leadingZeros, rules.number]"
          name="deposit"
          :disabled="error || isLockedBecauseOfEconomy"
          validate-on="blur"
          class="swish-deposit__text-field"
        />
      </v-form>
      <common-button
        v-if="$vuetify.display.smAndDown"
        class="swish-deposit__button"
        @click="deposit"
      >
        {{ $t("portfolio.overview.deposit.swish-deposit.openSwish") }}
      </common-button>
      <common-button
        v-else
        class="swish-deposit__button"
        @click="deposit"
      >
        {{ $t("depositTitle") }}
      </common-button>
    </template>
    <template v-if="state === State.Swish">
      <swish
        :amount="depositAmount"
        :phone-number="phoneNumber"
        @reset="() => (state = State.Form)"
        @complete="swishComplete"
      />
    </template>
    <template v-if="state === State.Complete">
      <div class="swish-deposit__complete">
        <label>{{ $t("portfolio.overview.deposit.swish-deposit.completeBody") }}</label>
        <label>{{ $t("cashInvestedNextTrade") }}</label>
      </div>
      <common-button
        class="swish-deposit__button"
        @click="resetAndClose"
      >
        {{ $t("ok") }}
      </common-button>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { softBlue, nearlyBlack } from "@/styles/colors";
import CommonButton from "@/components/button.vue";
import { DepositState } from "@/types/portfolio";
import PhoneNumberInput from "@/components/phone-number-input.vue";
import Swish from "@/components/swish.vue";
import AccountInput from "./account-input.vue";

enum State {
  Form,
  Swish,
  Complete,
  Error
}

export default defineComponent({
  emits: ["close"],
  components: {
    CommonButton,
    AccountInput,
    PhoneNumberInput,
    Swish
  },
  data() {
    return {
      DepositState,
      State,
      softBlue,
      nearlyBlack,
      error: false,
      state: State.Form,
      depositAmount: 0,
      phoneNumber: undefined as string | undefined,
      rules: {
        leadingZeros: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^(0|[1-9][0-9]*)$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        number: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        depositMinimum: (value: string) => {
          value = value.replace(/\s/g, "");
          const numericValue = Number(value);
          return numericValue >= 500 || this.$t("numberNotLargeEnough");
        }
      }
    };
  },
  computed: {
    ...mapGetters(["portfolioName", "isTestUser"]),
    displayDepositAmount: {
      get(): string {
        if (this.depositAmount) {
          return this.depositAmount.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        this.depositAmount = parseInt(value, 10);
      }
    },
    isLockedBecauseOfEconomy(): boolean {
      if (this.$store.state.userStore.currentLegalEntity) {
        return this.$store.state.userStore.currentLegalEntity.isLockedBecauseOfEconomy;
      }
      return false;
    }
  },
  mounted() {
    this.phoneNumber = this.$store.state.userStore.currentLegalEntity.phoneNumber;
  },
  methods: {
    removeWhiteSpace(value: string): string {
      return value.replace(/\s/g, "");
    },
    async deposit() {
      if ((await (this.$refs.form as any).validate()).valid) {
        this.state = State.Swish;
      }
    },
    swishComplete() {
      this.state = State.Complete;
    },
    resetAndClose() {
      this.$emit("close");
    }
  }
});
</script>

<style lang="scss" scoped>
.swish-deposit {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: 100%;
  &__subheader {
    font-size: 1.125rem;
    text-align: center;
    font-family: $heading-font-family;
  }
  &__text-field {
    font-size: 1.25rem;
    font-weight: bold;
  }
  &__from-number {
    font-size: 1rem;
    font-family: $heading-font-family;
    opacity: 0.63;
  }
  &__complete {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    text-align: center;
    opacity: 0.7;
    font-size: 1rem;
    font-family: $heading-font-family;
  }
  &__button {
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto !important;
    width: 100%;
  }
}
</style>
