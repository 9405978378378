import type { countryCodes } from "@/config/countries";

export type IconString = string | Array<string>; // Used for FontAwesome icons

export interface NumberMap {
  [key: number]: number;
}

export interface SingleChoice {
  text: string;
  key: string | number;
  icon?: IconString;
  image?: string;
  secondary?: boolean;
  disabled?: boolean;
  hintValue?: string;
  unit?: string;
  bypassDisableCheck?: boolean;
}

export interface MultipleChoiceAlternative {
  label: string;
  key: string | number;
  checked: boolean;
  sublabel?: string;
  icon?: IconString;
  asFreeText?: boolean;
  freeTextValue?: string;
  disabled?: boolean;
  radioIcon?: boolean;
  readonly?: boolean;
}

export interface ContactDetails {
  email: string;
  phoneNumber?: string;
}

const sectors = [
  "fossilFuel",
  "alcoholTobacco",
  "weaponsAndDefense",
  "casinosAndGambling",
] as const;
export type Sector = (typeof sectors)[number];
export type IncludedSectors = { [key in Sector]: boolean };

export type RiskWillingness = 0 | 1 | 2 | 3;

const loans = ["auto", "relatives", "mortgage", "other"] as const;
export type Loan = (typeof loans)[number];
export type HasLoans = { [key in Loan]: boolean };

export type CountryCode = (typeof countryCodes)[number];

export const banksWithTransactionAccounts = [
  "Danske Bank",
  "Ekobanken",
  "Handelsbanken",
  "ICA Banken",
  "JAK Medlemsbank",
  "Länsförsäkringar Bank",
  "Marginalen Bank",
  "Nordea",
  "Nordea Plusgirot",
  "SEB",
  "Skandiabanken",
  "Sparbanken Syd",
  "Swedbank",
  "Ålandsbanken",
  "Svea Bank",
];
export type BankOptions = (typeof banksWithTransactionAccounts)[number];

const savingAlternatives = [
  "savingForEconomicSafety",
  "savingForRetirement",
  "savingForRelative",
  "savingForConsumption",
] as const;
export type SavingAlternative = (typeof savingAlternatives)[number];
export type SavingPurpose = { [key in SavingAlternative]: boolean };

const backgroundAlternatives = [
  "incomeFromInvestment",
  "incomeFromSalaryPensionAllowance",
  "incomeFromGambling",
  "incomeFromResidence",
  "incomeFromCompanySale",
  "incomeFromGiftOrHeritage",
  "incomeFromOther",
] as const;
export type BackgroundAlternative = (typeof backgroundAlternatives)[number];
export type InvestmentBackground = { [key in BackgroundAlternative]: boolean };

export interface NavigationAlternative {
  text: string;
  key: string;
  secondary?: boolean;
}

export enum RegistrationSignState {
  PREPARING_AGREEMENTS,
  CONFIRM_AGREEMENTS,
  ENTER_SSN,
  OPEN_BANKID,
  PENDING,
  REGISTERING,
  COMPLETE,
  FAILED,
  FAILED_CONFLICT,
  NONE,
}

const companySavingAlternatives = [
  "savingShortTerm",
  "savingLongTerm",
  "savingPension",
  "savingInsurance",
  "savingDiscretionaryManagement",
] as const;
export type CompanySavingAlternative = (typeof companySavingAlternatives)[number];
export type CompanySavingPurpose = { [key in CompanySavingAlternative]: boolean };

const companyBackgroundAlternatives = [
  "incomeExcessCapital",
  "incomeSaleAssets",
  "incomeDomesticSavings",
  "incomeForeignSavings",
  "incomeExternalCapital",
] as const;
export type CompanyBackgroundAlternative = (typeof companyBackgroundAlternatives)[number];
export type CompanyInvestmentBackground = { [key in CompanyBackgroundAlternative]: boolean };

export interface SustainabilityImpact {
  minimizeNegativeImpact: boolean;
  minimizeNegativeImpactChanged: boolean;
  excludeNegativeImpact: boolean;
  noPreference: boolean;
  taxonomyRegulation?: boolean | undefined;
  disclosureRegulation?: boolean | undefined;
}

export interface PdfPanel {
  heading: string;
  dynamicAgreements: any[];
  staticAgreements: any[];
}

export interface SignupSectionPaths {
  header: string;
  paths: string[];
}

export interface EsgPais {
  pai1: boolean | undefined;
  pai2: boolean | undefined;
  pai3: boolean | undefined;
  pai4: boolean | undefined;
  pai5: boolean | undefined;
  pai7: boolean | undefined;
  pai9: boolean | undefined;
  pai10: boolean | undefined;
  pai11: boolean | undefined;
  pai13: boolean | undefined;
  pai14: boolean | undefined;
}
