<template>
  <div class="create-report">
    <template v-if="state === createReportState.form">
      <p class="create-report__subheader">
        {{ $t("portfolio.overview.reports.create-report.setDateText") }}
      </p>
      <date-range
        v-model="dateRange"
        show-labels
      />
      <div class="create-report__button-wrapper">
        <common-button
          class="create-report__button"
          @click="createReport"
        >
          {{ $t("createReport") }}
        </common-button>
      </div>
    </template>
    <template v-else-if="state === createReportState.loading">
      <p class="create-report__subheader">
        {{ $t("portfolio.overview.reports.create-report.creatingReport") }}
      </p>
      <loading-spinner />
    </template>
    <template v-else-if="state === createReportState.error">
      <error-loading style="max-width: 22rem" />
      <p class="create-report__body-text">
        {{ $t("portfolio.overview.reports.create-report.errorHintText") }}
      </p>
      <div class="create-report__button-wrapper">
        <common-button
          class="create-report__button"
          @click="() => $emit('back')"
        >
          {{ $t("back") }}
        </common-button>
      </div>
    </template>
    <template v-else>
      <p class="create-report__subheader">
        {{ $t("portfolio.overview.reports.create-report.reportCreated") }}
      </p>
      <p class="create-report__body-text">
        <icon
          class="create-report__icon"
          fixed-width
          :icon="['fal', 'check']"
        />{{ reportName }}
      </p>
      <div class="create-report__button-wrapper">
        <common-button
          class="create-report__button"
          @click="() => $emit('back')"
        >
          {{ $t("back") }}
        </common-button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DateTime } from "luxon";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import ErrorLoading from "@/components/error-loading.vue";
import DateRange from "@/components/date-range.vue";
import { DateTimeRange } from "@/types/transaction";
import CommonButton from "@/components/button.vue";
import { UserAction } from "@/store/user/action-definitions";

enum createReportState {
  form,
  loading,
  completed,
  error
}

export default defineComponent({
  emits: ["back"],
  components: { LoadingSpinner, ErrorLoading, DateRange, CommonButton },
  data: () => ({
    createReportState,
    state: createReportState.form,
    dateRange: {
      fromDate: DateTime.local().minus({ months: 3 }),
      toDate: DateTime.local()
    } as DateTimeRange,
    reportName: undefined as string | undefined
  }),
  created() {
    const createdDate = this.$store.getters.portfolioCreatedDate as DateTime;
    if (createdDate) {
      const threeMonthsAgo = DateTime.local().minus({ months: 3 });
      this.dateRange.fromDate = createdDate < threeMonthsAgo ? threeMonthsAgo : createdDate;
    }
  },
  methods: {
    async createReport() {
      if (!this.dateRange.fromDate || !this.dateRange.toDate) {
        this.state = createReportState.error;
      } else {
        this.state = createReportState.loading;
        try {
          this.dateRange.fromDate = this.dateRange.fromDate.set({ hour: 12 });
          this.dateRange.toDate = this.dateRange.toDate.set({ hour: 12 });
          const fileName = await this.$store.dispatch(UserAction.createCustomReport, {
            startDate: this.dateRange.fromDate.toJSDate(),
            endDate: this.dateRange.toDate.toJSDate()
          });
          this.reportName = fileName;
          this.state = createReportState.completed;
        } catch (error) {
          this.state = createReportState.error;
        }
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.create-report {
  margin: 0 auto;
  text-align: center;
  &__subheader {
    font-size: 1.125rem;
    font-family: $heading-font-family;
  }
  &__body-text {
    font-size: 1rem;
    font-weight: 100;
  }
  &__form {
    display: flex;
    justify-content: center;
  }
  &__button-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-column-gap: 1rem;
    width: 100%;
    grid-template-rows: 4rem;
  }
  &__button {
    width: 13.375rem;
  }
  &__icon {
    margin: 1rem 0.5rem 0 0;
    color: $soft-blue;
  }
}
</style>
