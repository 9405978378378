import type { ActionTree } from "vuex";
import type { RootState } from "../../types";
import type { NavigationState } from "./types";
import { NavigationMutation } from "./mutations";
import { baseUrl } from "@/clients/config";
import type { PortfolioBase } from "@/clients";
import { Client } from "@/clients";

export const NavigationAction = {
  loadPortfolios: "loadPortfolios",
};

const portfoliosClient = new Client(baseUrl);

export const actions: ActionTree<NavigationState, RootState> = {
  async [NavigationAction.loadPortfolios](
    { state, commit, rootState },
    payload: { noCache: boolean; forceReload: boolean } = { noCache: false, forceReload: false },
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { portfolios } = state;
    if (!portfolios || payload.forceReload) {
      portfoliosClient.noCache = payload.noCache;
      return portfoliosClient
        .getApiLegalEntitiesPortfoliosAll(rootState.userStore.currentLegalEntity.brickId)
        .then((portfoliosResult) => {
          if (portfoliosResult) {
            portfoliosResult.sort((p1: PortfolioBase, p2: PortfolioBase) => {
              if (p1.createdAt > p2.createdAt) {
                return 1;
              }
              if (p1.createdAt < p2.createdAt) {
                return -1;
              }
              return 0;
            });
          }
          commit(NavigationMutation.setPortfolios, portfoliosResult);
        })
        .catch((error) => {
          // TODO Sätt error state, Task: https://trello.com/c/Wz3utvHt.

          console.error(error.message);
        });
    }
    return Promise.resolve();
  },
};
