<template>
  <div class="congratulations">
    <single-choice
      v-if="!needsManualCheck"
      :alternatives="alternatives"
      @click="goToLogin"
    >
      <information-text-section
        :heading="$t('congratulationsHeader')"
        :body="$t('signup.questions.congratulations.congratulationsBody')"
      >
        <icon />
      </information-text-section>
      <p class="congratulations__second-body">
        {{ $t("signup.questions.congratulations.secondBody") }}
      </p>
    </single-choice>
    <single-choice
      v-else
      :alternatives="manualAlternatives"
      @click="goToStart"
    >
      <information-text-section
        :heading="$t('congratulationsHeader')"
        :body="$t('manualRegistration')"
      >
        <icon />
      </information-text-section>
      <p class="congratulations__second-body">
        {{ $t("signup.questions.congratulations.secondBody") }}
      </p>
    </single-choice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { CustomWindow } from "@/types";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Question from "@/views/signup/templates/question";
import { SignupNavigationMutation } from "@/store/signup-navigation/mutations";
import Icon from "@/assets/images/signup/rocket-launch-light.svg";
import trackAdRecord from "../../../utils/adrecord-snippet";
import trackAdTraction from "../../../utils/adtraction-snippet";

declare const window: CustomWindow;

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    Icon
  },
  extends: Question,
  data: () => ({
    adtractionScriptLoadInterval: undefined as number | undefined
  }),
  computed: {
    manualAlternatives(): { text: string; key: string }[] {
      return [
        {
          text: this.$t("toStartPage"),
          key: "toStartPage"
        }
      ];
    },
    alternatives(): { text: string; key: string }[] {
      return [
        {
          text: this.$t("login"),
          key: "login"
        }
      ];
    },
    needsManualCheck(): boolean {
      return this.$store.state.signupStore.registeredUserApproved === false;
    }
  },
  created() {
    this.$store.commit(SignupMutation.setCompletedSignUp, true);
    this.$store.commit(SignupMutation.showProgressBar, false);
  },
  methods: {
    async goToStart() {
      this.$store.commit(SignupNavigationMutation.setProgressHeadings, undefined);
      (window as any).location = "https://www.sigmastocks.com";
    },
    async goToLogin() {
      this.$store.commit(SignupNavigationMutation.setProgressHeadings, undefined);
      this.$router.push({ name: "login" });
    }
  }
});
</script>

<style lang="scss" scoped>
.congratulations {
  height: 100%;
  &__information-body {
    max-width: 44.5rem;
    margin-top: 1rem;
    font-weight: 300;
  }
  &__second-body {
    text-align: center;
    margin-top: 1rem;
    font-weight: 300;
  }
}
</style>
