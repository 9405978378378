export function createBankIdRedirectParameterValue() {
  //   let returnUrl = "null";
  //   if (isIOS()) {
  //     if (isChrome()) {
  //       returnUrl = "googlechromes://";
  //     } else if (isFirefox()) {
  //       returnUrl = "firefox://";
  //     } else if (!(window.navigator as any).standalone) {
  //       const redirectHash = "#swishRedirect";
  //       if (!window.location.href.endsWith(redirectHash)) {
  //         // If we have already done the redirect once and come back to the frontend, we will have
  //         // still have the hash applied. If we apply it again by making another payment,
  //         // Swish app rejects the redirect with an error message, saying there was a technical error.
  //         returnUrl = `${window.location.href}${redirectHash}`;
  //       }
  //     }
  //   }
  //   if (returnUrl === "null") {
  //     Sentry.captureMessage(
  //       "returnUrl was null. This is OK if platform is android",
  //       "info" as SeverityLevel
  //     );
  //   }
  //   const callbackUrl = `${encodeURIComponent(returnUrl)}`;

  //   Not using return url for now because of issues. Keeping it here if we want to reconsider.
  //   This only affects iOS as Android will redirect back even with null
  return null;
}
