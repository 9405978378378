import { MutationTree } from "vuex";
import { PerformanceResponse } from "@/clients";
import Vue from "vue";
import { OverviewState } from "./types";

export const getOverviewInitialState = (): OverviewState => ({
  portfolioPerformances: {},
  performanceLoading: {}
});

export const OverviewMutation = {
  setPerformance: "setPerformance",
  setPerformanceLoading: "setPerformanceLoading"
};
export const mutations: MutationTree<OverviewState> = {
  [OverviewMutation.setPerformance](
    state,
    payload: { portfolioId: string; performance: PerformanceResponse }
  ) {
    state.portfolioPerformances[payload.portfolioId] = payload.performance;
  },
  [OverviewMutation.setPerformanceLoading](
    state,
    payload: { portfolioId: string; loading: boolean }
  ) {
    state.performanceLoading[payload.portfolioId] = payload.loading;
  }
};
