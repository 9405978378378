import { LoginRequest, ApplicationUsersClient, LoginResponse } from ".";
import { baseUrl } from "./config";
import { setJwtToken } from "./authentication";

const applicationUserClient = new ApplicationUsersClient(baseUrl);

export async function validateAffiliateCode(
  affiliateCode: string | undefined,
  affiliateName: string | undefined,
  isNaturalPerson: boolean
): Promise<boolean> {
  return applicationUserClient.validateAffiliateCode(
    affiliateCode || null,
    affiliateName || null,
    isNaturalPerson
  );
}
