<template>
  <single-choice
    :alternatives="alternatives"
    @click="buttonClick"
  >
    <div class="loaned-amount">
      <question-text-section
        :heading="$t('common-signup.questions.economy.loaned-amount.heading')"
        :body="$t('common-signup.questions.economy.loaned-amount.body')"
      />

      <range-template
        v-model="modelValue"
        :values="values"
        :label="$t('currency')"
      />
    </div>
  </single-choice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { SignupMutation } from "@/store/signup/mutations";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import RangeTemplate from "../../../signup/templates/range.vue";

export default defineComponent({
  emits: ["next"],
  components: {
    RangeTemplate,
    QuestionTextSection,
    SingleChoice
  },
  extends: Question,
  computed: {
    ...mapGetters(["totalAssets", "solidity"]),
    values() {
      const values: number[] = [];
      for (let i = 0; i < 100000; i += 10000) {
        values.push(i);
      }
      for (let i = 100000; i < 1000000; i += 50000) {
        values.push(i);
      }
      for (let i = 1000000; i < 5000000; i += 100000) {
        values.push(i);
      }
      for (let i = 5000000; i < 15000000; i += 500000) {
        values.push(i);
      }
      for (let i = 15000000; i <= 20000000; i += 1000000) {
        values.push(i);
      }
      return values;
    },
    modelValue: {
      get(): number {
        return this.$store.state.signupStore.totalLoans;
      },
      set(value: number) {
        this.$store.commit(SignupMutation.setTotalLoans, value);
      }
    },
    alternatives(): any {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    buttonClick(value: string): void {
      this.$emit("next", value);
    }
  }
});
</script>

<style lang="scss" scoped>
.loaned-amount {
  height: 100%;
  width: 100%;
}
</style>
