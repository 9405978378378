import { GetterTree } from "vuex";
import { AutogiroStatus, PortfolioBase, PortfolioType } from "@/clients";
import { NavigationState } from "./types";
import { RootState } from "../../types";

export const getters: GetterTree<NavigationState, RootState> = {
  isTopLevel(state): boolean {
    return state.backRoute === undefined;
  },
  firstPortfolio(state): PortfolioBase | undefined {
    if (state.portfolios === undefined || state.portfolios.length === 0) {
      return undefined;
    }
    const [firstPortfolio] = state.portfolios;
    return firstPortfolio;
  },
  portfolioBase:
    (state) =>
      (portfolioId: string): PortfolioBase | undefined => {
        if (state.portfolios === undefined) {
          return undefined;
        }
        return state.portfolios.find((portfolio) => portfolio.id === portfolioId);
      },
  pensionPortfolios(state): PortfolioBase[] {
    if (state.portfolios) {
      return (state.portfolios as PortfolioBase[]).filter(
        (x) => x.portfolioType === PortfolioType.OccupationalPension
      );
    }
    return [];
  },
  hasPensionPortfolio(state, getters): boolean {
    return getters.pensionPortfolios && getters.pensionPortfolios.length > 0;
  }
};
