<template>
  <div class="personal-info">
    <card-header
      class="personal-info__header"
      :title="$t('personalInfo')"
      :icon="['fal', 'user']"
    >
      <edit-button
        :edit="edit"
        @click="toggleEdit"
      />
    </card-header>
    <v-form
      v-if="!errorLoadingKyc"
      ref="form"
      class="personal-info__form"
    >
      <select-list
        v-model="occupation"
        :edit="edit"
        :items="occupationAlternatives"
        :label="$t('occupation')"
        @change="(value) => (occupation = value)"
      />
      <select-list
        v-model="editedPepOption"
        :edit="edit"
        :items="pepAlternatives"
        :label="$t('profile.personal-info.pep')"
        @change="(value) => (editedPepOption = value)"
      />
      <select-list
        v-model="livingInSweden"
        :edit="edit"
        :items="yesNoOptions"
        :label="$t('profile.personal-info.livingInSweden')"
        @change="(value) => (livingInSweden = value)"
      />
      <select-list
        v-model="swedishTaxResidence"
        :edit="edit"
        :items="yesNoOptions"
        :label="$t('profile.personal-info.swedishTaxResidence')"
        @change="(value) => (swedishTaxResidence = value)"
      />
      <select-list
        v-model="taxableUsa"
        :edit="edit"
        :items="yesNoOptions"
        :label="$t('profile.personal-info.taxableUsa')"
        @change="(value) => (taxableUsa = value)"
      />
      <v-autocomplete
        v-if="edit || taxableOtherCountry"
        v-model="taxableOtherCountry"
        :menu-props="menuProps"
        variant="underlined"
        :items="countryItems"
        :class="[
          'personal-info__autocomplete',
          {
            'personal-info__autocomplete--disabled': !edit
          }
        ]"
        :disabled="!edit"
        :label="$t('profile.personal-info.taxableOtherCountry')"
      >
        <template #append-inner>
          <icon
            style="font-size: 1.25rem"
            :icon="['fal', 'search']"
          />
        </template>
      </v-autocomplete>
      <v-text-field
        v-model="taxIdentificationNumber"
        variant="underlined"
        :class="['personal-info__input', { 'personal-info__input--disabled': !edit }]"
        type="text"
        :label="$t('foreignTaxIdentificationNumber')"
        :disabled="!edit"
      />
      <div class="personal-info__citizenship">
        <v-autocomplete
          v-model="citizenship1"
          :menu-props="menuProps"
          :items="countryItems"
          variant="underlined"
          :menu-icon="undefined"
          :class="[
            'personal-info__autocomplete',
            {
              'personal-info__autocomplete--disabled': !edit
            }
          ]"
          :disabled="!edit"
          :label="$t('citizenship')"
        >
          <template #append-inner>
            <icon
              style="font-size: 1.25rem"
              :icon="['fal', 'search']"
            />
          </template>
        </v-autocomplete>
        <v-btn
          v-if="edit && citizenshipCount < 3"
          class="personal-info__add-btn"
          variant="text"
          @click="citizenshipCount += 1"
        >
          {{ $t("profile.personal-info.addNew") }}
        </v-btn>
      </div>
      <div
        v-if="citizenshipCount > 1"
        class="personal-info__citizenship"
      >
        <v-autocomplete
          v-model="citizenship2"
          :menu-props="menuProps"
          variant="underlined"
          :items="countryItems"
          :class="[
            'personal-info__autocomplete',
            {
              'personal-info__autocomplete--disabled': !edit
            }
          ]"
          :disabled="!edit"
          :label="$t('citizenship') + ' 2'"
        >
          <template #append-inner>
            <icon
              style="font-size: 1.25rem"
              :icon="['fal', 'search']"
            />
          </template>
        </v-autocomplete>
        <v-btn
          v-if="edit"
          class="personal-info__add-btn"
          variant="text"
          @click="
            citizenshipCount -= 1;
            citizenship2 = citizenship3;
            citizenship3 = undefined;
          "
        >
          {{ $t("delete") }}
        </v-btn>
      </div>
      <div
        v-if="citizenshipCount == 3"
        class="personal-info__citizenship"
      >
        <v-autocomplete
          v-model="citizenship3"
          :menu-props="menuProps"
          :items="countryItems"
          :class="[
            'personal-info__autocomplete',
            {
              'personal-info__autocomplete--disabled': !edit
            }
          ]"
          :disabled="!edit"
          variant="underlined"
          :label="$t('citizenship') + ' 3'"
        >
          <template #append-inner>
            <icon
              style="font-size: 1.25rem"
              :icon="['fal', 'search']"
            />
          </template>
        </v-autocomplete>
        <v-btn
          v-if="edit"
          variant="text"
          class="personal-info__add-btn"
          @click="
            citizenshipCount -= 1;
            citizenship3 = undefined;
          "
        >
          {{ $t("delete") }}
        </v-btn>
      </div>
    </v-form>
    <div
      v-if="errorLoadingKyc"
      class="personal-info__error-text"
    >
      {{ $t("couldNotGetData") }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { Occupation, KnowYourCustomerAnswers } from "@/clients";
import { UserMutation } from "@/store/user/mutations";
import { CountryCode } from "@/types/signup";
import { countryCodes } from "@/config/countries";
import EditButton from "@/components/edit-button.vue";
import CardHeader from "@/components/card-header.vue";
import { UserState } from "@/store/user/types";
import SelectList from "./select.vue";

enum PepOption {
  No,
  Yes,
  YesRelative,
  YesCoworker
}
interface VSelectItem {
  title: string;
  value: string;
}

export default defineComponent({
  emits: ["save"],
  components: { CardHeader, SelectList, EditButton },
  data: () => ({
    edit: false,
    editedPepOption: PepOption.No,
    citizenshipCount: 1,
    menuProps: {
      contentClass: "autocomplete__menu",
      nudgeBottom: 8
    }
  }),
  computed: {
    ...mapGetters(["errorLoadingKyc"]),
    kyc(): KnowYourCustomerAnswers {
      return this.$store.state.userStore.editedLegalEntity.knowYourCustomerAnswers;
    },
    countryItems(): VSelectItem[] {
      return countryCodes.map((x: CountryCode) => ({
        title: this.$t(`countries.${x}`),
        value: x
      }));
    },
    livingInSweden: {
      get(): boolean {
        if (this.kyc) return this.kyc.livingInSweden;
        return false;
      },
      set(value: boolean) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            livingInSweden: value
          })
        );
      }
    },
    taxableUsa: {
      get(): boolean {
        if (this.kyc) return this.kyc.taxableUsa;
        return false;
      },
      set(value: boolean) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            taxableUsa: value
          })
        );
      }
    },
    pep: {
      get(): boolean {
        if (this.kyc) return this.kyc.pep;
        return false;
      },
      set(value: boolean) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            pep: value
          })
        );
      }
    },
    associatedPepRelative: {
      get(): boolean {
        if (this.kyc) return this.kyc.associatedPepRelative;
        return false;
      },
      set(value: boolean) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            associatedPepRelative: value
          })
        );
      }
    },
    associatedPepCoworker: {
      get(): boolean {
        if (this.kyc) return this.kyc.associatedPepCoworker;
        return false;
      },
      set(value: boolean) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            associatedPepCoworker: value
          })
        );
      }
    },
    occupation: {
      get(): Occupation | undefined {
        if (this.kyc) return this.kyc.occupation;
        return undefined;
      },
      set(value: Occupation) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            occupation: value
          })
        );
      }
    },
    taxIdentificationNumber: {
      get(): string | undefined {
        if (this.kyc) {
          return this.kyc.taxIdentificationNumber;
        }
        return undefined;
      },
      set(value: string | undefined) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            taxIdentificationNumber: value === "" ? undefined : value
          })
        );
      }
    },
    swedishTaxResidence: {
      get(): boolean {
        if (this.kyc) return this.kyc.swedishTaxResidence;
        return false;
      },
      set(value: boolean) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            swedishTaxResidence: value
          })
        );
      }
    },
    taxableOtherCountry: {
      get(): string | undefined {
        if (this.kyc) return this.kyc.taxableOtherCountry;
        return undefined;
      },
      set(value: string) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            taxableOtherCountry: value
          })
        );
      }
    },
    citizenship1: {
      get(): string | undefined {
        if (this.kyc) return this.kyc.citizenship1;
        return undefined;
      },
      set(value: string | undefined) {
        if (!value) {
          throw new Error("citizenship1 must be defined when saving profile kyc settings");
        }
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            citizenship1: value
          })
        );
      }
    },
    citizenship2: {
      get(): string | undefined {
        if (this.kyc) return this.kyc.citizenship2;
        return undefined;
      },
      set(value: string | undefined) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            citizenship2: value
          })
        );
      }
    },
    citizenship3: {
      get(): string | undefined {
        if (this.kyc) return this.kyc.citizenship3;
        return undefined;
      },
      set(value: string | undefined) {
        this.$store.commit(
          UserMutation.editKyc,
          new KnowYourCustomerAnswers({
            ...this.kyc,
            citizenship3: value
          })
        );
      }
    },
    yesNoOptions(): any[] {
      return [
        {
          value: true,
          key: true,
          title: this.$t("yes")
        },
        {
          value: false,
          key: false,
          title: this.$t("no")
        }
      ];
    },
    pepAlternatives(): any[] {
      return [
        {
          value: PepOption.No,
          key: PepOption.No,
          title: this.$t("noPep")
        },
        {
          value: PepOption.Yes,
          key: PepOption.Yes,
          title: this.$t("yesiAmPep")
        },
        {
          value: PepOption.YesRelative,
          key: PepOption.YesRelative,
          title: this.$t("profile.personal-info.yesAssociatedPepRelative")
        },
        {
          value: PepOption.YesCoworker,
          key: PepOption.YesCoworker,
          title: this.$t("profile.personal-info.yesAssociatedPepCoworker")
        }
      ];
    },
    occupationAlternatives(): any[] {
      return Object.keys(Occupation)
        .filter((k) => typeof Occupation[k as any] === "number")
        .map((x: string, index: number) => ({
          title: this.$t(`occupations.${x}`),
          value: index,
          key: index
        }));
    }
  },
  watch: {
    editedPepOption: {
      handler(newValue: PepOption) {
        this.pep = newValue === PepOption.Yes;
        this.associatedPepRelative = newValue === PepOption.YesRelative;
        this.associatedPepCoworker = newValue === PepOption.YesCoworker;
      }
    }
  },
  mounted() {
    this.editedPepOption = this.getCurrentPepOption();
    if (this.citizenship2) this.citizenshipCount += 1;
    if (this.citizenship3) this.citizenshipCount += 1;
  },
  methods: {
    async toggleEdit() {
      if (this.edit) {
        this.$store.commit(UserMutation.decreaseUnsavedEdits);
        this.$emit("save");
        this.edit = false;
      } else {
        this.$store.commit(UserMutation.increaseUnsavedEdits);
        this.edit = true;
      }
    },
    getCurrentPepOption(): PepOption {
      if (this.pep) return PepOption.Yes;
      if (this.associatedPepRelative) return PepOption.YesRelative;
      if (this.associatedPepCoworker) return PepOption.YesCoworker;
      return PepOption.No;
    }
  }
});
</script>
<style lang="scss" scoped>
.personal-info {
  padding: 1.5rem 1.5rem 0.825rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__form {
    padding-top: 1rem;
  }

  &__error-text {
    padding-top: 1rem;
    text-align: center;
  }
  &__citizenship {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .v-input {
      max-width: 21rem;
    }
  }
  &__input {
    color: black;

    :deep(.v-text-field__slot) {
      max-width: 100%;
    }
    :deep(input) {
      min-width: unset;
      flex: unset;
      max-width: 100%;
    }
    :deep(.v-text-field__suffix) {
      flex: 1;
      text-align: left;
      cursor: text;
    }
    :deep(.v-text-field__prefix) {
      align-self: flex-end;
    }
    :deep(.v-field__outline:before) {
      border: none !important;
      border-color: transparent !important;
    }
    :deep(.v-text-field) {
      margin-top: 0;
    }
    &--disabled {
      :deep(.v-text-field__suffix) {
        color: inherit !important;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-label) {
      color: #707070 !important;
      font-size: 1.2rem;
      font-weight: 300;
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
  }
  &__add-btn {
    text-transform: none;
  }
  &__autocomplete {
    :deep(label) {
      color: #707070 !important;
      font-size: 1.2rem;
      font-weight: 300;
    }
    :deep(input) {
      color: black !important;
    }
    &--disabled {
      :deep(.v-field__append-inner) {
        opacity: 0;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-label) {
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
  }
}
</style>
