<template>
  <div class="portfolio-overview">
    <SectionHeader
      v-show="$vuetify.display.mdAndUp"
      :header="$t('performance')"
      :sub-header="$store.getters.portfolioName || ''"
      class="portfolio-overview__header"
    >
      <template #extra-sub-header>
        <div
          v-if="portfolio?.isClosing"
          class="portfolio-overview__text-wrapper"
        >
          <span class="portfolio-overview__status"> {{ $t("closingPortfolio") }}</span>
        </div>
      </template>
      <template #icon>
        <icon
          :icon="['fal', 'chart-line']"
          size="lg"
        />
      </template>
      <!-- Can't use simply the header button component due to a bug in Vuetify; https://github.com/vuetifyjs/vuetify/issues/9933 -->
      <v-menu location="bottom">
        <template #activator="{ props }">
          <HeaderButton
            v-bind="props"
            data-cy="overviewSettingsButton"
            :icon="['fal', 'cog']"
            :tooltip-text="$t('portfolio.overview.portfolio-overview.showSettings')"
            :input-value="true"
          />
        </template>
        <SettingsList
          @click:delete="$emit('click:delete')"
          @click:withdraw="$emit('click:withdraw')"
          @click:rename="$emit('click:rename')"
          @click:agreements="$emit('click:agreements')"
          @click:settings="clickedSettings"
          @click:transactions="$emit('click:transactions')"
          @click:reports="$emit('click:reports')"
        />
      </v-menu>
    </SectionHeader>

    <div class="portfolio-overview__cards">
      <div
        v-if="isPension"
        class="portfolio-overview__pension"
        style="display: flex; flex-direction: column; justify-content: space-between"
      >
        <v-card
          v-for="pension in notCompletedPensions"
          :key="pension.insuranceNumber"
          class="portfolio-overview__card"
        >
          <PensionMoveStatus :in-progress-pension="pension" />
        </v-card>
      </div>
      <v-card
        class="portfolio-overview__card portfolio-overview__card--no-padding portfolio-overview__card--primary"
      >
        <Development
          :performance-error="performanceError"
          :performance="performance"
          :is-loading-performance="isLoadingPerformance"
          :portfolio-start-date="portfolioStartDate"
        />
      </v-card>
      <v-card
        class="portfolio-overview__card portfolio-overview__card--top-right portfolio-overview__card--clickable"
        @click="() => ($refs.events as any).setShowDialog(true)"
      >
        <Events ref="events" />
      </v-card>
      <v-card
        v-if="!isPension && legalEntityApproved && !portfolio?.isClosing"
        class="portfolio-overview__card portfolio-overview__card--clickable"
        style="display: flex"
        @click="navigateToDeposit()"
      >
        <Deposit data-cy="depositButton" />
      </v-card>
      <v-card
        v-if="!isCompany && $vuetify.display.smAndDown && !isPension"
        class="portfolio-overview__card portfolio-overview__card--clickable"
        @click="showReferralsDialog()"
      >
        <Referral />
      </v-card>
      <!-- Don't show either of these views until we have loaded portfolio settings.
        This solution is not good but I couldn't think of another one. -->
      <template v-if="portfolioSettings">
        <v-card
          v-if="!isPension && !hasBankAccount"
          class="portfolio-overview__card portfolio-overview__card--clickable"
          @click="openConnectBankSideDialog()"
        >
          <ConnectBank />
        </v-card>
        <v-card
          v-else-if="!isPension && !hasMonthlySaving && !portfolio?.isClosing"
          class="portfolio-overview__card portfolio-overview__card--bottom-left portfolio-overview__card--clickable"
          @click="() => $router.push({ name: 'portfolio-settings' })"
        >
          <StartMonthlySaving />
        </v-card>
        <v-card
          v-else-if="!isCompany && !hasPensionPortfolio"
          class="portfolio-overview__card portfolio-overview__card--bottom-left portfolio-overview__card--clickable"
          @click="() => $router.push('/pension')"
        >
          <MovePension />
        </v-card>
        <template v-else>
          <v-card
            v-if="!isPension"
            class="portfolio-overview__card portfolio-overview__card--bottom-left portfolio-overview__card--clickable"
            @click="() => showGoalDialog()"
          >
            <Goal ref="goal" />
          </v-card>
        </template>
      </template>
      <v-card
        v-if="!notCompletedPensions || (notCompletedPensions && notCompletedPensions.length < 2)"
        class="portfolio-overview__card portfolio-overview__card--bottom-right portfolio-overview__card--clickable"
        @click="showDividendDialog()"
      >
        <Dividend />
      </v-card>
      <v-card
        v-if="isPension && !inProgressPensions"
        class="portfolio-overview__card portfolio-overview__card--bottom-right portfolio-overview__card--clickable"
        @click="clickedSettings"
      >
        <PensionPlan />
      </v-card>
    </div>
    <PortfolioCreatedDialog v-model="showPortfolioCreatedDialog" />
    <DepositSideDialog />
    <WithdrawSideDialog />
    <DividendSideDialog />
    <GoalSideDialog />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import type { DateTime } from "luxon";
import Referral from "../../../components/referral.vue";
import PensionMoveStatus from "../pension-move-status.vue";
import PensionPlan from "../pension-plan.vue";
import MovePension from "../../../components/move-pension.vue";
import Goal from "./savings-goal/goal.vue";
import Deposit from "./deposit/deposit.vue";
import Development from "./development/development.vue";
import Events from "./events.vue";
import SettingsList from "./settings-list.vue";
import Dividend from "./dividend/dividend.vue";
import ConnectBank from "./connect-bank.vue";
import StartMonthlySaving from "./start-monthly-saving.vue";
import DepositSideDialog from "./deposit/side-dialog.vue";
import WithdrawSideDialog from "./withdraw/side-dialog.vue";
import GoalSideDialog from "./savings-goal/side-dialog.vue";
import DividendSideDialog from "./dividend/side-dialog.vue";
import type { PortfolioState } from "@/store/portfolio/types";
import PortfolioCreatedDialog from "@/components/portfolio-created-dialog.vue";
import type {
  InProgressPension,
  PerformanceResponse,
  PortfolioBase,
  PortfolioSettings,
} from "@/clients";
import { UserMutation } from "@/store/user/mutations";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import HeaderButton from "@/components/header-button.vue";
import SectionHeader from "@/components/section-header.vue";
import type { OverviewState } from "@/store/overview/types";

export default defineComponent({
  components: {
    Deposit,
    Events,
    Dividend,
    Development,
    SectionHeader,
    SettingsList,
    HeaderButton,
    Referral,
    PensionMoveStatus,
    PensionPlan,
    MovePension,
    Goal,
    PortfolioCreatedDialog,
    ConnectBank,
    StartMonthlySaving,
    DepositSideDialog,
    WithdrawSideDialog,
    DividendSideDialog,
    GoalSideDialog,
  },
  emits: [
    "click:delete",
    "click:withdraw",
    "click:rename",
    "click:agreements",
    "click:transactions",
    "click:reports",
    "click:settings",
  ],
  computed: {
    ...mapGetters([
      "isCompany",
      "isPension",
      "notCompletedPension",
      "hasBankAccount",
      "hasPensionPortfolio",
      "isTestUser",
    ]),
    performance(): PerformanceResponse | undefined {
      const portfolioId = this.$store.state.portfolioStore.activePortfolioBase.id;
      const performance = (this.$store.state.overviewStore as OverviewState).portfolioPerformances[
        portfolioId
      ];
      return performance;
    },
    portfolioStartDate(): DateTime | undefined {
      if (this.$store.state.portfolioStore.activePortfolioBase) {
        return this.$store.state.portfolioStore.activePortfolioBase.createdAt;
      }
      return undefined;
    },
    isLoadingPerformance(): boolean {
      const portfolioId = this.$store.state.portfolioStore.activePortfolioBase.id;
      if (portfolioId) {
        return (
          (this.$store.state.overviewStore as OverviewState).performanceLoading[portfolioId]
          === true
        );
      }
      return false;
    },
    performanceError(): boolean {
      return this.$store.state.portfolioStore.performanceError;
    },
    portfolioSettings(): PortfolioSettings | undefined {
      return (this.$store.state.portfolioStore as PortfolioState).portfolioSettings;
    },
    portfolio(): PortfolioBase {
      return this.$store.state.portfolioStore.activePortfolioBase;
    },
    hasMonthlySaving(): boolean | undefined {
      const { portfolioSettings } = this.$store.state.portfolioStore as PortfolioState;
      if (portfolioSettings) {
        return (
          portfolioSettings.monthlySaving !== undefined
          && portfolioSettings.monthlySaving !== null
          && portfolioSettings.monthlySaving > 0
        );
      }
      return undefined;
    },
    legalEntityApproved(): boolean | undefined {
      if (this.$store.state.userStore.currentLegalEntity) {
        return this.$store.state.userStore.currentLegalEntity.passedIsApprovedCheck;
      }
      return false;
    },
    showPortfolioCreatedDialog: {
      get(): boolean {
        return this.$store.state.portfolioStore.showPortfolioCreatedDialog;
      },
      set(value: boolean): void {
        this.$store.commit(PortfolioMutation.setShowPortfolioCreatedDialog, value);
      },
    },
    notCompletedPensions(): InProgressPension[] | undefined {
      if (this.inProgressPensions) {
        return this.inProgressPensions.filter((x: InProgressPension) =>
          this.notCompletedPension(x.insuranceNumber),
        );
      }
      return undefined;
    },
    inProgressPensions(): InProgressPension[] | undefined {
      return this.$store.state.portfolioStore.activePortfolioBase?.inProgressPensions;
    },
  },
  methods: {
    navigateToDeposit(): void {
      this.$store.commit(PortfolioMutation.setShowDepositDialog, true);
    },
    showDividendDialog(): void {
      this.$store.commit(PortfolioMutation.setShowDividendDialog, true);
    },
    showGoalDialog(): void {
      this.$store.commit(PortfolioMutation.setShowSavingsGoalSideDialog, true);
    },
    showReferralsDialog() {
      this.$store.commit(UserMutation.setShowReferralsDialog, true);
    },
    openConnectBankSideDialog() {
      this.$store.commit(PortfolioMutation.setShowConnectBankSideDialog, true);
    },
    clickedSettings() {
      this.$emit("click:settings");
    },
  },
});
</script>

<style lang="scss" scoped>
.portfolio-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
    width: 100%;
    @include small-up {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
      grid-template-rows: auto auto 1fr auto;
    }
    @include medium-up {
      margin-top: 2rem;
      grid-template-columns: 1fr 18rem;
      grid-template-rows: repeat(4, auto);
      grid-row-gap: 1.5rem;
    }
    @include large-up {
      grid-column-gap: 1.5rem;
      grid-template-columns: 1fr 22rem;
    }
  }
  &__card {
    overflow: hidden;
    padding: 1.25rem 1.25rem;
    &--bottom-left {
      @include small-only {
        grid-column: 1;
        grid-row: 3/5;
      }
    }
    &--no-padding {
      padding: 0;
    }
    &--primary {
      @include small-up {
        grid-row: 1;
        grid-column: 1 / 3;
      }
      @include medium-up {
        grid-column: 1;
        grid-row: 1 / 5;
      }
    }
    &--top-right {
      @include small-only {
        grid-column: 2;
        grid-row: 2/4;
      }
    }
    &--bottom-right {
      @include small-only {
        grid-column: 2;
        grid-row: 4/5;
      }
    }
  }
  &__pension {
    @include medium-up {
      grid-column: 2;
      grid-row: 3/4;
    }
  }
  &__card--clickable {
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: $whisper;
    }
  }
  &__header {
    width: 100%;
  }

  &__status {
    color: $alert-red;
  }
  &__text-wrapper {
    display: flex;
    align-self: center;
    align-items: baseline;
    padding: 1.125rem;
  }
}

.v-menu__content {
  border-radius: 0.5rem;
  box-shadow: 0 0.1875rem 2rem rgba(0, 0, 0, 0.32);
  max-width: 15.625rem;
  @include small-down {
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
  }
}
</style>
