<template>
  <portfolio-selection
    v-model="riskWillingness"
    :heading="$t('create-portfolio.questions.risk.portfolio-selection.heading')"
    :body="$t('create-portfolio.questions.risk.portfolio-selection.body')"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import PortfolioSelection from "@/views/common-signup/questions/risk/portfolio-selection.vue";
import { RiskWillingness } from "@/types/signup";
import { SignupState } from "@/store/signup/types";

export default defineComponent({
  components: { PortfolioSelection },
  computed: {
    riskWillingness: {
      get(): number {
        return this.$store.state.signupStore.riskWillingness as number;
      },
      set(value: RiskWillingness) {
        this.$store.commit(SignupMutation.setRiskWillingness, value);
      }
    }
  }
});
</script>
