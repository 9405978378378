<template>
  <time-to-withdraw-component
    :heading="$t('create-portfolio.questions.economy.time-to-withdraw.heading')"
    warning-name="time-to-withdraw-warning"
    @select="select"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TimeToWithdrawComponent from "@/views/common-signup/questions/economy/time-to-withdraw.vue";
import { SignupMutation } from "@/store/signup/mutations";
import { TimeToWithdraw } from "@/clients";

export default defineComponent({
  components: { TimeToWithdrawComponent },
  methods: {
    async select(key: TimeToWithdraw) {
      this.$store.commit(SignupMutation.setTimeToWithdraw, key);
    }
  }
});
</script>
