<template>
  <v-dialog
    v-model="showDialog"
    content-class="update-agreements"
    @click:outside="cancel"
  >
    <v-card class="update-agreements__card">
      <template v-if="progress === 0">
        <p class="update-agreements__header">
          {{ $t("components.update-agreements-dialog.changeHeader") }}
        </p>
        <p class="update-agreements__body">
          {{ $t("components.update-agreements-dialog.changeBody") }}
        </p>
        <v-card-actions class="update-agreements__actions">
          <common-button
            secondary
            class="update-agreements__change-button"
            @click="cancel"
          >
            {{ $t("components.update-agreements-dialog.no") }}
          </common-button>
          <common-button
            data-cy="yesUpdateAgreementsButton"
            class="update-agreements__change-button"
            @click="goToNext"
          >
            {{ $t("components.update-agreements-dialog.yes") }}
          </common-button>
        </v-card-actions>
      </template>
      <template v-if="progress === 1 && preparingDocuments">
        <p class="update-agreements__header">
          {{ $t("signExplanationHeader") }}
        </p>
        <p class="update-agreements__body--explanation">
          {{ $t("components.update-agreements-dialog.preparingDocuments") }}
        </p>
        <loading-spinner />
      </template>
      <template v-else-if="progress === 1">
        <p class="update-agreements__header">
          {{ $t("signExplanationHeader") }}
        </p>
        <p class="update-agreements__body--explanation">
          {{ $t("components.update-agreements-dialog.signExplanationBody1-1") }}
          <a
            style="color: inherit; text-decoration: underline"
            @click="(e) => $emit('agreements')"
          >
            {{ $t("components.update-agreements-dialog.signExplanationBody1-2") }}</a
          >
        </p>
        <p class="update-agreements__body--explanation">
          {{ $t("components.update-agreements-dialog.signExplanationBody2") }}<br />{{
            $t("components.update-agreements-dialog.signExplanationBody3")
          }}
        </p>
        <v-card-actions class="update-agreements__actions">
          <common-button
            class="update-agreements__cancel-button"
            data-cy="goToSignButton"
            @click="goToNext"
          >
            {{ $t("signBankId") }}
          </common-button>
        </v-card-actions>
      </template>
      <template v-if="progress === 2">
        <bank-id
          :sign-location="signLocation"
          @complete="signComplete"
          @canceled="cancelSign"
          @failed="signFailed"
        />
      </template>
      <template v-else-if="progress === 3">
        <p class="update-agreements__header">
          {{ $t("components.update-agreements-dialog.savingSettings") }}
        </p>
        <loading-spinner />
      </template>
      <template v-else-if="progress === 4">
        <p class="update-agreements__header">
          {{ errors ? $t("signCompletionHeaderFailed") : $t("completionHeader") }}
        </p>
        <p class="update-agreements__body">
          {{
            errors
              ? $t("completionBodyFailed")
              : $t("components.update-agreements-dialog.completionBody1")
          }}
        </p>
        <v-card-actions class="update-agreements__actions">
          <common-button
            class="update-agreements__cancel-button"
            data-cy="closeDialogButton"
            @click="close"
          >
            {{ $t("close") }}
          </common-button>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";
import { BankIdAction } from "@/store/bankid/actions";
import { SignLocation } from "@/store/bankid/types";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";

import BankId from "./bank-id.vue";

export default defineComponent({
  emits: ["agreements", "save", "cancel", "cancel", "update:model-value", "prepare"],
  components: {
    CommonButton,
    BankId,
    LoadingSpinner
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    signingComplete: {
      type: Boolean,
      default: false
    },
    settingToSave: {
      type: String,
      required: true
    },
    updateSettingsError: {
      type: Boolean,
      required: true
    },
    signLocation: {
      type: Number as () => SignLocation,
      default: undefined
    }
  },
  data: () => ({
    progress: 0,
    isSigning: false,
    signSettingsFailed: false,
    preparingDocuments: false,
    SignLocation
  }),
  computed: {
    errors(): boolean {
      return this.signSettingsFailed || this.updateSettingsError;
    },
    showDialog: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:model-value", value);
      }
    }
  },
  created() {
    if (this.signingComplete) {
      this.signComplete();
    }
  },
  methods: {
    async save() {
      // To be able to know if the save action was successful or not the async update call to the API must complete before we continue.
      // It is up to the observer of this event to resolve the promise upon completion.
      await new Promise((resolve) => this.$emit("save", this.settingToSave, resolve));
    },
    cancelSign(): void {
      this.resetProgress();
    },
    cancel(): void {
      this.resetProgress();
      this.$emit("cancel", this.settingToSave);
    },
    async close() {
      this.cancelSign();
      this.resetProgress();
      if (this.errors) {
        this.$emit("cancel", this.settingToSave);
      } else {
        this.$emit("update:model-value", false);
      }
    },
    async goToNext() {
      this.progress += 1;
      if (this.progress === 1) {
        this.preparingDocuments = true;
        try {
          await new Promise((resolve) => this.$emit("prepare", this.settingToSave, resolve));
        } catch (error: any) {
          console.error(error);
          throw error;
        }
        this.preparingDocuments = false;
        if (this.errors) {
          this.progress = 4;
        }
      }
      if (this.progress === 2) {
        this.sign();
      }
    },
    async sign() {
      this.isSigning = true;
      this.signSettingsFailed = false;
      try {
        await this.$store.dispatch(BankIdAction.sign);
      } catch (error: any) {
        console.error(error);
      }
    },
    resetProgress() {
      this.progress = 0;
    },
    async signComplete() {
      this.progress = 3;
      await this.save();
      this.goToNext();
    },
    signFailed() {
      this.goToNext();
      this.signSettingsFailed = true;
    }
  }
});
</script>

<style lang="scss" scoped>
.update-agreements {
  &__card {
    padding: 1.5rem 1rem 0rem 1rem;
    min-height: 22rem;
  }
  &__header {
    font-family: $heading-font-family;
    font-size: 1.125rem;
    font-weight: 500;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;
  }
  &__body {
    font-family: $heading-font-family;
    font-size: 1rem;
    font-weight: 300;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;

    &--explanation {
      text-align: center;
    }
  }
  &__actions {
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    @include small-up {
      flex-wrap: unset;
    }
  }
  &__cancel-button {
    margin-top: 0.5rem;
    @include xsmall-up {
      margin-top: 0rem;
    }
  }
  &__change-button {
    width: 15rem;
    margin-top: 0.8rem;
  }
}
:deep(.update-agreements) {
  max-width: 30rem;
}
.v-card-actions .v-btn ~ .v-btn:not(.v-btn-toggle .v-btn) {
  margin-left: 0;
}
</style>
