<template>
  <two-action-dialog
    :model-value="modelValue"
    :primary-color="dialogColor"
    :heading="$t('components.create-portfolio-dialog.header')"
    :body-sections="[$t('components.create-portfolio-dialog.body')]"
    :button1-text="$t('components.create-portfolio-dialog.pension')"
    :button2-text="$t('createPortfolio')"
    @button-1-click="$router.push({ name: 'pension' })"
    @button-2-click="$router.push({ name: 'create-portfolio' })"
    @update:model-value="inputChange"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TwoActionDialog from "@/components/two-action-dialog.vue";
import { softBlue } from "@/styles/colors";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    TwoActionDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dialogColor() {
      return this.$store.state.userStore.currentLegalEntity?.tiedAgent?.color ?? softBlue;
    }
  },
  methods: {
    inputChange(value: boolean) {
      if (!value) {
        this.$emit("update:model-value", false);
      }
    }
  }
});
</script>
