<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SingleChoice from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import { useSignupType } from "../../signup-type";
import SustainabilityCarouselCard from "./sustainability-carousel-card.vue";
import { useQuestion } from "@/views/signup/templates/question-comp";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import InfoCarousel from "@/components/info-carousel.vue";
import type { RootState } from "@/types";
import { SignupMutation } from "@/store/signup/mutations";
import type { SustainabilityImpact } from "@/types/signup";
import { SignupFlowModificationType } from "@/config/signup-modification";
import type { NestedCheckbox } from "@/components/nested-checkboxes.vue";
import NestedCheckboxes from "@/components/nested-checkboxes.vue";
import { nearlyBlack } from "@/styles/colors";

defineProps<{ buttonColor?: string | undefined }>();
const store = useStore();
const router = useRouter();
useQuestion(store, router);
const { maybeTextToThirdPerson } = useSignupType();

const alternatives = ref([
  {
    text: "Nej, avsluta",
    key: "quit",
    secondary: true,
    bypassDisableCheck: true,
  },
  {
    text: maybeTextToThirdPerson("Ja, ändra mitt val"),
    key: "next",
  },
]);
const sustainabilityImpact = computed(() => store.state.signupStore.sustainabilityImpact);

const taxonomyRegulation = ref(sustainabilityImpact.value?.taxonomyRegulation);
const disclosureRegulation = ref<boolean>(sustainabilityImpact.value?.disclosureRegulation);

const nested = computed<NestedCheckbox[]>(() =>
  [
    {
      heading: "Enligt EU:s taxonomi.",
      body1: "Miljömässigt hållbara bolag.",
      checked: taxonomyRegulation.value,
      index: 0,
    },
    {
      heading: "Enligt SFDR (Disclosureförordningen)",
      body1: "Miljömässigt och socialt hållbara bolag.",
      checked: disclosureRegulation.value,
      index: 1,
    },
  ]);

const seenAllInfo = ref(false);

const minimizeNegativeImpact = computed({
  get(): boolean {
    return (
      (store.state as RootState).signupStore.sustainabilityImpact?.minimizeNegativeImpact ?? false
    );
  },
  set(value: boolean) {
    const impact = (store.state as RootState).signupStore.sustainabilityImpact;

    store.commit(SignupMutation.setSustainabilityImpact, {
      ...impact,
      minimizeNegativeImpact: value,
    } as SustainabilityImpact);
  },
});
const initialNegativeImpact = minimizeNegativeImpact.value;

function clickNested(index: number, val: boolean | undefined) {
  if (index === 0) {
    taxonomyRegulation.value = !!val;
  } else if (index === 1) {
    disclosureRegulation.value = !!val;
  }
}

async function buttonClick(key: string) {
  if (key === "quit") {
    router.push({ name: "overview" });
  } else {
    store.commit(SignupMutation.setSustainabilityImpact, {
      ...(store.state as RootState).signupStore.sustainabilityImpact,
      // Cannot continue without checking box so set false
      // Set to false rather than undefined (as on sustainable-method)
      // to indicate that we selected on this screen
      taxonomyRegulation: false,
      disclosureRegulation: false,
      minimizeNegativeImpactChanged: initialNegativeImpact !== minimizeNegativeImpact.value,
    } as SustainabilityImpact);

    await store.dispatch(SignupNavigationAction.modifySignupFlow, {
      include: minimizeNegativeImpact.value,
      modification: SignupFlowModificationType.MinimizedNegativeImpact,
    });
    store.dispatch(SignupNavigationAction.goToNextStep, router);
  }
}
</script>

<template>
  <SingleChoice
    :alternatives="alternatives"
    class="sustainable-choice"
    :color="buttonColor"
    :disabled="taxonomyRegulation || disclosureRegulation || !seenAllInfo"
    max-height="60rem"
    @click="buttonClick"
  >
    <QuestionTextSection
      :heading="maybeTextToThirdPerson('Vill du ändra dina preferenser?')"
      progress
    />
    <div
      style="
        margin: 0 auto;
        margin-top: 2rem;
        font-weight: 300;
        display: flex;
        flex-direction: column;
      "
    >
      <InfoCarousel
        :items="2"
        @done="seenAllInfo = true"
      >
        <v-carousel-item class="sustainability__carousel_item">
          <SustainabilityCarouselCard>
            <div>Vi kan tyvärr inte erbjuda portföljer med hållbara bolag i dagsläget.</div>
            <br>
            <div>
              <b>Varför?</b> Det korta svaret är att det finns för få bolag som får klassas som
              hållbara enligt de nya reglerna på de listor vi handlar aktier på.
            </div>
          </SustainabilityCarouselCard>
        </v-carousel-item>
        <v-carousel-item class="sustainability__carousel_item">
          <SustainabilityCarouselCard>
            <div>
              <b>Vad kan vi erbjuda?</b> Vi kan erbjuda portföljer med minskade negativa konsekvenser
              för hållbarhet.
            </div>
            <br>
            <div>
              {{ maybeTextToThirdPerson('Du väljer vilka områden inom hållbarhet du bryr dig om, och de sämsta bolagen inom dem utesluts för dig.') }}
            </div>
          </SustainabilityCarouselCard>
        </v-carousel-item>
      </InfoCarousel>
      <div class="sustainable-choice__checkboxes">
        <h2
          style="margin-left: 4rem;
              margin-top: 2rem;
              font-weight: 300;
              font-size: 0.875rem;"
        >
          {{ maybeTextToThirdPerson('Jag vill investera i') }}
        </h2>
        <NestedCheckboxes
          :header="{ heading: 'Hållbara bolag', body1: 'Välj bolag som tydligt bidrar till ett hållbart miljö- eller socialt mål.' }"
          :nested="nested"
          :indeterminate="taxonomyRegulation || disclosureRegulation"
          @click-nested="clickNested"
          @click-top="(val) => { taxonomyRegulation = !!val; disclosureRegulation = !!val }"
        >
          <template #body1="{ text }">
            <div
              v-if="text"
              :style="{ color: nearlyBlack, fontSize: '0.9375rem', opacity: 0.87, fontWeight: 300, fontFamily: 'proxima-nova' }"
            >
              {{ text }}
            </div>
          </template>
        </NestedCheckboxes>
        <h2
          style="
              margin-left: 4rem;
              margin-bottom: 0.5rem;
              margin-top: 1rem;
              font-weight: 300;
              font-size: 0.875rem;
            "
        >
          {{ maybeTextToThirdPerson('Jag vill minska') }}
        </h2>
        <div style="margin-left: 1.4rem; display: flex; flex-direction: row; color: #2f2f2f">
          <div>
            <v-checkbox-btn
              v-model="minimizeNegativeImpact"
              style="color: #2f2f2f; opacity: 0.87;font-size: 1.125rem "
              data-test="negativeImpact"
              @click="(e: Event) => e.stopPropagation()"
            />
          </div>
          <div style="display: flex; flex-direction: column; row-gap: 0.5rem">
            <h2 style="font-weight: 400; font-size: 1.25rem; opacity: 0.87">
              Negativa konsekvenser för hållbarhet
            </h2>
            <div style="font-weight: 300; font-size: 0.875rem">
              Undvik bolag som har negativa effekter på hållbarhet.
            </div>
          </div>
        </div>
      </div>
    </div>
  </SingleChoice>
</template>

<style lang="scss" scoped>
.sustainable-choice {
  &__checkboxes {
    @include medium-up() {
      margin: 0 auto;
      width: 26rem;
    }
  }
}
</style>
