<template>
  <div>
    <LoadingSpinnerOverlay :model-value="loadingEvents" />
    <div class="events">
      <icon
        fixed-width
        :icon="['fal', 'calendar']"
        class="events__icon"
      />
      <h2 class="events__title">
        {{ $t("portfolio.overview.events.title") }}
      </h2>
      <icon
        :icon="['fas', 'chevron-right']"
        class="events__forward-icon"
      />
    </div>
    <div class="events__wrapper">
      <div
        v-if="portfolio?.isClosing"
        class="events__event events__status"
      >
        <span class="events__subheader"> {{ $t("closingPortfolio") }}</span>
        <span class="events__value"> - </span>
      </div>
      <div
        v-for="(transaction, index) in transactionEventsSummary"
        :key="index"
        class="events__event"
      >
        <span class="events__subheader">
          {{
            transaction
              ? getTextFromTransactionTypeAndStatus(transaction.type, transaction.status)
              : "-"
          }}</span>
        <span
          v-if="transaction && transaction.fullWithdrawal"
          class="events__value"
        >{{ $t("portfolio.overview.events.fullWithdrawal") }}</span>
        <span
          v-else
          class="events__value"
          data-jest="transactionAmount"
        >
          {{ formatAmount(transaction ? transaction.amount : undefined, $t("currency")) }}
        </span>
      </div>
      <div
        v-if="transactionEventsSummary.length === 0"
        class="events__event"
      >
        {{ $t("portfolio.overview.events.noTransactions") }}
      </div>
      <v-divider class="events__divider" />
      <div class="events__event">
        <span class="events__subheader"> {{ $t("portfolio.overview.events.nextTradingDay") }}</span>
        <span
          class="events__value"
          data-jest="transactionAmount"
        >
          {{
            nextTradingDay === undefined
              ? "-"
              : nextTradingDay === null
                ? "TBA"
                : nextTradingDay.atDate.toLocaleDateString("sv-SE")
          }}
        </span>
      </div>
      <div
        v-if="hintText !== undefined"
        class="events__hint"
      >
        <icon
          class="events__hint-icon"
          fixed-width
          :icon="['fal', 'info-circle']"
        /><span class="events__hint-text">{{ hintText }}</span>
      </div>
    </div>
    <SideDialog
      v-if="showDialog"
      v-model="showDialog"
      :title="$t('transactions')"
      data-jest="dialog"
      :show-back-button="true"
      @update:model-value="setShowDialog(false)"
      @back="goBack"
    >
      <div class="events-dialog">
        <div v-if="selectedTransaction">
          <div class="events-dialog__transaction-detail-row">
            <span class="events__subheader">
              {{ getDescriptionFromTransactionType(selectedTransaction.type) }}</span>
            <span
              class="events-dialog__value-bold"
              data-jest="transactionAmount"
            >
              {{
                selectedTransaction.fullWithdrawal
                  && selectedTransaction.status === TransactionEventStatus.Planned
                  ? $t("portfolio.overview.events.fullWithdrawal")
                  : formatAmount(selectedTransaction.amount, $t("currency"))
              }}
            </span>
          </div>
          <div class="events-dialog__transaction-detail-row">
            <span class="events__subheader"> {{ $t("portfolio.overview.events.status") }}</span>
            <span
              class="events-dialog__value-bold"
              data-jest="transactionAmount"
            >
              {{ getStatusText(selectedTransaction) }}
            </span>
          </div>
          <div class="events-dialog__transaction-detail-row">
            <span
              v-if="selectedTransaction.expectedCompletionDate"
              class="events__subheader"
            >
              {{ $t("earliestInAccount") }}</span>
            <span
              v-if="selectedTransaction.expectedCompletionDate"
              class="events-dialog__value-bold"
              data-jest="transactionAmount"
            >
              {{ getEstimatedDateString(selectedTransaction) }}
            </span>
            <span
              v-else
              class="events-dialog__value-centered"
              data-jest="transactionAmount"
            >
              {{ getEstimatedDateString(selectedTransaction) }}
            </span>
          </div>
          <div
            v-if="
              selectedTransaction.type === TransactionEventType.DepositOneTimeAutogiro
                && selectedTransaction.status == TransactionEventStatus.Planned
            "
            class="events-dialog__transaction-detail-row"
          >
            <span class="events__subheader">
              {{ $t("portfolio.overview.events.expectedDate") }}</span>
            <span
              class="events-dialog__value-bold"
              data-jest="transactionAmount"
            >
              {{ selectedTransaction.expectedExecutionDate.toLocaleDateString("sv-SE") }}
            </span>
          </div>
          <div
            v-if="
              selectedTransaction.fullWithdrawal
                && selectedTransaction.status === TransactionEventStatus.Planned
            "
            class="events-dialog__transaction-detail-row"
          >
            {{ $t("portfolio.overview.events.waitForSell") }}
          </div>
          <span
            v-if="
              selectedTransaction.type === TransactionEventType.DepositRecurringAutogiro
                && selectedTransaction.status !== TransactionEventStatus.Ongoing
            "
            class="events-dialog__explanation"
          >{{ $t("portfolio.overview.events.monthlySavingExplanation") }}</span>
          <span
            v-if="selectedTransaction.status === TransactionEventStatus.Ongoing"
            class="events-dialog__explanation"
          >{{ getOngoingTransactionText(selectedTransaction.type) }}</span>
          <div class="events-dialog__buttons-wrapper">
            <CommonButton
              :disabled="
                selectedTransaction.status === TransactionEventStatus.Ongoing
                  || selectedTransaction.type === TransactionEventType.DepositRecurringAutogiro
                  || (selectedTransaction.type === TransactionEventType.WithdrawalAutogiro
                    && portfolio.isClosing)
              "
              :secondary="true"
              @click="
                selectedTransaction.type === TransactionEventType.DepositOneTimeAutogiro
                  ? cancelDeposit(selectedTransaction.depositExternalId)
                  : cancelWithdrawal(selectedTransaction.withdrawalId)
              "
            >
              {{ $t("portfolio.overview.events.cancelTransaction") }}
            </CommonButton>
            <CommonButton @click="deselectTransaction">
              {{ $t("ok") }}
            </CommonButton>
          </div>
        </div>
        <div
          v-else
          class="events-dialog__overview"
        >
          <div>
            <div class="events-dialog__divider">
              {{ $t("ongoing") }}
            </div>
            <div
              v-for="(transaction, index) in ongoingTransactionEvents"
              :key="index"
              class="events-dialog__clickable-transaction"
              @click="selectTransaction(transaction)"
            >
              <div class="events-dialog__clickable-transaction-content">
                <span class="events__subheader">
                  {{
                    transaction ? getDescriptionFromTransactionType(transaction.type) : "-"
                  }}</span>
                <span
                  class="events-dialog__value"
                  data-jest="transactionAmount"
                >
                  {{ formatAmount(transaction ? transaction.amount : undefined, $t("currency")) }}
                </span>
              </div>
            </div>
            <div
              v-if="ongoingTransactionEvents.length === 0"
              class="events-dialog__no-transactions"
            >
              {{ $t("portfolio.overview.events.noOngoingTransactions") }}
            </div>
            <div class="events-dialog__divider">
              {{ $t("planned") }}
            </div>
            <div
              v-for="(transaction, index) in plannedTransactionEvents"
              :key="`_${index}`"
              class="events-dialog__clickable-transaction"
              @click="selectTransaction(transaction)"
            >
              <div class="events-dialog__clickable-transaction-content">
                <span class="events__subheader">
                  {{ getDescriptionFromTransactionType(transaction.type) }}</span>
                <span
                  class="events-dialog__value"
                  data-jest="transactionAmount"
                >
                  {{
                    transaction.fullWithdrawal
                      ? $t("portfolio.overview.events.fullWithdrawal")
                      : formatAmount(transaction ? transaction.amount : undefined, $t("currency"))
                  }}
                </span>
              </div>
            </div>
            <div
              v-if="plannedTransactionEvents.length === 0"
              class="events-dialog__no-transactions"
            >
              {{ $t("portfolio.overview.events.noPlannedTransactions") }}
            </div>
          </div>
          <CommonButton
            class="events-dialog__button"
            @click="setShowDialog(false)"
          >
            {{ $t("ok") }}
          </CommonButton>
        </div>
      </div>
    </SideDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DateTime } from "luxon";
import type {
  PortfolioBase,
  TradingDay,
  TransactionEvent,
} from "@/clients";
import {
  LogLevel,
  TransactionEventStatus,
  TransactionEventType,
  WithdrawalProcessState,
} from "@/clients";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { PortfolioAction } from "@/store/portfolio/actions";
import log from "@/clients/log";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";

const logger = "events.vue";

export default defineComponent({
  components: { SideDialog, CommonButton, LoadingSpinnerOverlay },
  filters: {
    formatAmount(value: number, currency: string) {
      if (value !== undefined) {
        return `${value.toLocaleString("sv-SE")} ${currency}`;
      }
      return "-";
    },
    formatDate(value: Date) {
      if (value !== undefined) {
        return DateTime.fromJSDate(value).toFormat("yy.MM.dd");
      }
      return "-";
    },
  },
  data() {
    return {
      showDialog: false,
      detailedView: false,
      selectedTransaction: undefined as TransactionEvent | undefined,
      TransactionEventType,
      TransactionEventStatus,
      DateTime,
    };
  },
  computed: {
    loadingEvents(): boolean {
      return this.$store.state.portfolioStore.loadingEvents;
    },
    portfolio(): PortfolioBase {
      return this.$store.state.portfolioStore.activePortfolioBase;
    },
    transactionEvents(): TransactionEvent[] | undefined {
      const { transactionEvents } = this.$store.state.portfolioStore;
      if (transactionEvents) {
        const depositsOneTimeAutogiro = transactionEvents.filter(
          (t: TransactionEvent) => t.type === TransactionEventType.DepositOneTimeAutogiro,
        );
        const withdrawalsAutogiro = transactionEvents.filter(
          (t: TransactionEvent) => t.type === TransactionEventType.WithdrawalAutogiro,
        );
        const depositsRecurringAutogiro = transactionEvents.filter(
          (t: TransactionEvent) => t.type === TransactionEventType.DepositRecurringAutogiro,
        );
        const finsharkDeposits = transactionEvents.filter(
          (t: TransactionEvent) => t.type === TransactionEventType.DepositOneTimeFinshark,
        );
        return [
          ...withdrawalsAutogiro,
          ...depositsOneTimeAutogiro,
          ...depositsRecurringAutogiro,
          ...finsharkDeposits,
        ];
      }
      return undefined;
    },
    transactionEventsSummary(): (TransactionEvent | undefined)[] {
      if (this.transactionEvents) {
        return this.transactionEvents.slice(0, this.portfolio.isClosing ? 1 : 2);
      }
      return [undefined, undefined];
    },
    plannedTransactionEvents(): TransactionEvent[] {
      return this.transactionEvents
        ? this.transactionEvents.filter(e => e && e.status === TransactionEventStatus.Planned)
        : [];
    },
    ongoingTransactionEvents(): TransactionEvent[] {
      return this.transactionEvents
        ? this.transactionEvents.filter(e => e && e.status === TransactionEventStatus.Ongoing)
        : [];
    },
    nextTradingDay(): TradingDay | undefined {
      return this.$store.state.portfolioStore.nextTradingDay;
    },
    yesterdaysTradingDay(): TradingDay | undefined {
      return this.$store.state.portfolioStore.yesterdaysTradingDay;
    },
    hintText(): string | undefined {
      if (this.nextTradingDay) {
        const date = DateTime.fromJSDate(this.nextTradingDay.atDate);
        if (date.hasSame(DateTime.local(), "day")) {
          return this.$t("portfolio.overview.events.ongoingTradeHint");
        }
      }
      if (this.yesterdaysTradingDay) {
        return this.$t("portfolio.overview.events.yesterdayTradeHint");
      }
      return undefined;
    },
  },
  methods: {
    async cancelDeposit(depositExternalId: string | undefined) {
      await this.$store.dispatch(PortfolioAction.cancelDeposit, depositExternalId);
      this.deselectTransaction();
    },
    async cancelWithdrawal(withdrawalId: number | undefined) {
      await this.$store.dispatch(PortfolioAction.cancelWithdrawal, withdrawalId);
      this.deselectTransaction();
    },
    formatAmount(value: number | undefined, currency: string) {
      if (value !== undefined) {
        return `${value.toLocaleString("sv-SE")} ${currency}`;
      }
      return "-";
    },
    deselectTransaction() {
      this.selectedTransaction = undefined;
    },
    selectTransaction(transaction: TransactionEvent) {
      if (transaction.withdrawalId) {
        // Temporary to see whether customers asking about their withdrawals actaully look here first
        log(
          logger,
          `view event details withdrawal id: ${transaction.withdrawalId}`,
          LogLevel.Information,
        );
      }
      this.selectedTransaction = transaction;
    },
    setShowDialog(showDialog: boolean) {
      this.deselectTransaction();
      this.showDialog = showDialog;
    },
    getTextFromTransactionTypeAndStatus(
      type: TransactionEventType,
      status: TransactionEventStatus,
    ): string {
      if (type === TransactionEventType.WithdrawalAutogiro) {
        return status === TransactionEventStatus.Planned
          ? this.$t("portfolio.overview.events.plannedWithdrawal")
          : this.$t("ongoingWithdrawal");
      }
      if (
        type === TransactionEventType.DepositOneTimeAutogiro
          || type === TransactionEventType.DepositRecurringAutogiro
          || type === TransactionEventType.DepositOneTimeFinshark
      ) {
        return status === TransactionEventStatus.Planned
          ? this.$t("portfolio.overview.events.plannedDeposit")
          : this.$t("portfolio.overview.events.ongoingDeposit");
      }
      return "-";
    },
    getDescriptionFromTransactionType(type: TransactionEventType): string {
      switch (type) {
        case TransactionEventType.WithdrawalAutogiro:
          return this.$t("withdrawals");
        case TransactionEventType.DepositOneTimeAutogiro:
        case TransactionEventType.DepositOneTimeFinshark:
          return this.$t("deposit");
        case TransactionEventType.DepositRecurringAutogiro:
          return this.$t("monthlySaving");
        default:
          return "-";
      }
    },
    getStatusText(transaction: any) {
      if (transaction.type === TransactionEventType.WithdrawalAutogiro) {
        return this.getDetailedStatusText(transaction.withdrawalProcessState!);
      }
      if (transaction.status === TransactionEventStatus.Planned) {
        return this.$t("planned");
      }
      return this.$t("ongoing");
    },
    getDetailedStatusText(withdrawalProcessState: WithdrawalProcessState) {
      switch (withdrawalProcessState) {
        case WithdrawalProcessState.SentToBgc:
          return this.$t("portfolio.overview.events.withdrawalProcessState.sentToBgc");
        case WithdrawalProcessState.SentToBricknode:
          return this.$t("portfolio.overview.events.withdrawalProcessState.sentToBricknode");
        case WithdrawalProcessState.WaitingForSale:
          return this.$t("portfolio.overview.events.withdrawalProcessState.waitingForSale");
        case WithdrawalProcessState.WaitingForSaleManageRunning:
          return this.$t(
            "portfolio.overview.events.withdrawalProcessState.waitingForSaleManageRunning",
          );
        case WithdrawalProcessState.WaitingForSaleToComplete:
          return this.$t(
            "portfolio.overview.events.withdrawalProcessState.waitingForSaleToComplete",
          );
        case WithdrawalProcessState.WaitingForExchangeOrOther:
          return this.$t(
            "portfolio.overview.events.withdrawalProcessState.waitingForExchangeOrOther",
          );
        case WithdrawalProcessState.WaitingForSettlementThisWithdrawal:
          return this.$t(
            "portfolio.overview.events.withdrawalProcessState.waitingForSettlementThisWithdrawal",
          );
        case WithdrawalProcessState.WaitingForSettlementOther:
          return this.$t(
            "portfolio.overview.events.withdrawalProcessState.waitingForSettlementOther",
          );
        case WithdrawalProcessState.ExtraWithdrawalAwaitingSale:
          return this.$t(
            "portfolio.overview.events.withdrawalProcessState.extraWithdrawalAwaitingSale",
          );
        default:
          return this.$t("portfolio.overview.events.withdrawalProcessState.unknown");
      }
    },
    getEstimatedDateString(transaction: any) {
      if (transaction.expectedCompletionDate) {
        return DateTime.fromISO(transaction.expectedCompletionDate.toISOString()).toFormat(
          "yyyy-MM-dd",
        );
      }
      if (transaction.withdrawalProcessState === WithdrawalProcessState.WaitingForSale) {
        return this.$t("payouAfterNextTradingDay");
      }
      return "";
    },
    getOngoingTransactionText(type: TransactionEventType) {
      switch (type) {
        case TransactionEventType.DepositOneTimeAutogiro:
        case TransactionEventType.DepositRecurringAutogiro:
        case TransactionEventType.WithdrawalAutogiro:
          return this.$t("portfolio.overview.events.ongoingAutogiro");
        case TransactionEventType.DepositOneTimeFinshark:
          return this.$t("portfolio.overview.events.ongoingFinshark");
        default:
          return "";
      }
    },
    goBack() {
      if (this.selectedTransaction) {
        this.selectedTransaction = undefined;
      }
      else {
        this.setShowDialog(false);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.events {
  font-family: $heading-font-family;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto auto 1fr;
  align-items: center;
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.25rem;
    margin-right: 0.75rem;
  }
  &__event {
    font-family: $heading-font-family;
    padding-top: 0.8125rem;
    display: flex;
    justify-content: space-between;
  }
  &__status {
    color: $alert-red;
  }
  &__subheader {
    white-space: nowrap;
    font-weight: 300;
  }
  &__forward-icon {
    margin: auto 0 auto auto;
    color: $french-grey;
  }
  &__divider {
    margin-top: 0.8125rem;
  }
  &__hint {
    display: flex;
    flex-direction: row;
    color: $nearly-black;
    margin-top: 0.5rem;
  }
  &__hint-text {
    padding-left: 0.5rem;
    opacity: 0.8;
    font-size: 0.8125rem;
    font-family: $heading-font-family;
  }
  &__hint-icon {
    margin-top: 0.15rem;
  }
}
.events-dialog {
  padding-top: 2rem;
  height: 100%;

  &__overview {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__divider {
    display: flex;
    padding: 1rem 2rem 0 2rem;
    font-weight: 600;
  }
  &__divider:after {
    border-bottom: 1px dotted black;
    margin-bottom: 6px;
    margin-left: 0.8rem;
    content: "";
    flex: 1;
  }
  &__transaction-detail-row {
    padding: 1rem 2rem 0rem 2rem;
    display: flex;
    justify-content: space-between;
  }
  &__button {
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto !important;
  }
  &__explanation {
    position: absolute;
    bottom: 12rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
    width: 100%;
  }
  &__buttons-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-column-gap: 1rem;
    width: 100%;
    grid-template-rows: 4rem;
  }
  &__no-transactions {
    font-weight: 300;
    margin: 0 2rem 0 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  &__clickable-transaction {
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: $whisper;
    }
  }
  &__clickable-transaction-content {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    margin: 0 2rem 0 2rem;
  }
  &__value-bold {
    font-weight: 600;
  }
  &__value-centered {
    font-weight: 300;
    text-align: center;
  }
  &__value {
    font-weight: 300;
  }
}
</style>
