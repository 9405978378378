export const HIDE_BANNER_KEY = "hide_banner";
export const HIDE_SIGN_AGREEMENTS_DIALOG_KEY = "hide_sign_agreements_dialog";
export const LATEST_IOS_PWA_PROMPT = "latest_ios_pwa_prompt";
export const SIGNUP_REFERRAL = "signup_referral";
export const SIGNUP_AFFILIATE_REFFERAL_CODE = "signup_affiliate_referral_code";
export const SIGNUP_AFFILIATE_REFFERAL_NAME = "signup_affiliate_referral_name";
export const COMPANY_SIGNUP_AFFILIATE_REFFERAL_CODE = "company_signup_affiliate_referral_code";
export const COMPANY_SIGNUP_AFFILIATE_REFFERAL_NAME = "company_signup_affiliate_referral_name";
export const LATEST_SPAR_FETCH = "latest_spar_fetch";
export const LATEST_UTM_COOKIE_NAME = "latest_utm_source";
export const CURRENT_LEGAL_ENTITY = "current_legal_entity";
export const HIDE_CANCELLED_AUTOGIRO_DIALOG_KEY = "hide_cancelled_autogiro_dialog_key";
export const HIDE_YEARLY_KYC_UPDATE_KEY = "hide_yearly_kyc_update_key";
export const HIDE_ESG_PAI_DIALOG_KEY = "hide_esg_pai_dialog_key";
export const HIDE_PENSION_DIALOG_KEY = "hide_pension_dialog_key";
export const E2E_KEY = "e2e";
export const TRANSITION_DURATION_MILLIS = "transition_duration_millis";

export const constants = {
  bankIdPollingDelay: 2000,
  swishPollingDelay: 4000,
  insurelyStatusPollingDelay: 2000,
  maxNumberCompanyRepresentatives: 3
};
