<template>
  <div class="insurance-info">
    <card-header
      class="insurance-info__header"
      :title="$t('company-profile.insurance-info.insurance-info')"
      :icon="['fal', 'user']"
    >
    </card-header>
    <v-form
      ref="form"
      class="insurance-info__form"
    >
      <v-text-field
        v-model="owner"
        :class="['insurance-info__input insurance-info__input--disabled']"
        disabled
        validate-on="blur"
        variant="underlined"
        :label="$t('insuranceOwner')"
      />
      <v-text-field
        model-value="Idun Liv Försäkring AB (556740-1590)"
        :class="['insurance-info__input insurance-info__input--disabled']"
        disabled
        validate-on="blur"
        variant="underlined"
        :label="$t('insuranceCompany')"
      />
      <v-text-field
        v-model="insured"
        :class="['insurance-info__input insurance-info__input--disabled']"
        disabled
        validate-on="blur"
        variant="underlined"
        :label="$t('insured')"
      />
      <v-text-field
        v-model="insuranceNumber"
        :class="['insurance-info__input insurance-info__input--disabled']"
        disabled
        validate-on="blur"
        variant="underlined"
        :label="$t('insuranceNumber')"
      />
    </v-form>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import EditButton from "@/components/edit-button.vue";
import { LegalEntity } from "@/clients";
import { addDashToPersonalIdentityNumber } from "@/utils/formatters";
import { UserAction } from "@/store/user/action-definitions";
import CardHeader from "@/components/card-header.vue";

export default defineComponent({
  components: { CardHeader, EditButton },
  data: () => ({
    edit: false
  }),
  computed: {
    ...mapGetters(["errorLoadingUser"]),
    currentLegalEntity(): LegalEntity {
      return this.$store.state.userStore.currentLegalEntity;
    },
    owner(): string | undefined {
      if (this.currentLegalEntity) {
        return `${this.currentLegalEntity.firstName} (${this.currentLegalEntity.organizationNumber})`;
      }
      return "-";
    },
    insured(): string | undefined {
      if (
        this.currentLegalEntity &&
        this.currentLegalEntity.companyKnowYourCustomerAnswers &&
        this.currentLegalEntity.companyKnowYourCustomerAnswers.insuredPersonalNumber
      ) {
        return addDashToPersonalIdentityNumber(
          this.currentLegalEntity.companyKnowYourCustomerAnswers.insuredPersonalNumber
        );
      }
      return "-";
    },
    insuranceNumber(): string | undefined {
      return this.$store.state.userStore.insuranceNumbers
        ? this.$store.state.userStore.insuranceNumbers[0].insuranceNumber
        : undefined;
    }
  },
  async created() {
    if (!this.$store.state.userStore.insuranceNumbers) {
      await this.$store.dispatch(UserAction.loadInsuranceNumbers);
    }
  }
});
</script>
<style lang="scss" scoped>
.insurance-info {
  padding: 1.5rem 1.5rem 0.825rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__form {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
  }
  &__input {
    color: black;
    @include small-down {
      width: 100%;
    }
    :deep(label) {
      color: #707070 !important;
      font-size: 1.2rem;
      font-weight: 300;
    }
    :deep(.v-text-field) {
      margin-top: 0;
    }
    &--bottom {
      grid-column: 1 / 3;
      grid-row: 3;
    }
    &--disabled {
      :deep(.v-text-field__suffix) {
        border-style: inherit !important;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-label) {
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
  }
}
</style>
