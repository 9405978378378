import { SignupFlowModificationType } from "./signup-modification";

export const additionalQuestions = {
  [SignupFlowModificationType.TooYoung]: ["/signup/too-young"],
  [SignupFlowModificationType.NoAddressFound]: [
    "/signup/swedish-resident",
    "/signup/verify-personal-data"
  ],
  [SignupFlowModificationType.SwedishResidentError]: ["/signup/swedish-resident-error"],
  [SignupFlowModificationType.ReferralIntro]: ["/signup/referral-intro"],
  [SignupFlowModificationType.LeadsIntro]: ["/signup/leads-intro"],
  [SignupFlowModificationType.FamiljekortetIntro]: ["/signup/familjekortet-intro"]
};

export const questionPaths = [
  {
    header: "Ny användare",
    paths: [
      "/signup/welcome",
      "/signup/checklist",
      "/signup/contact",
      "/signup/bankid",
      "/signup/register",
      "/signup/congratulations"
    ]
  }
];

export const CURRENT_SIGNUP_PATH_KEY = "current_signup_path";
