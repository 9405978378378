<template>
  <div class="header">
    <div class="header__section">
      <icon
        v-if="icon && !edit"
        fixed-width
        class="header__icon"
        :icon="icon"
      />
      <h2 :class="['header__title', { 'header__title--edit': edit }]">
        {{ title }}
      </h2>
    </div>
    <div class="header__actions">
      <div class="header__section">
        <slot name="info" />
      </div>
      <div class="header__section">
        <slot name="action" />
      </div>
      <div
        v-if="hasAction2"
        class="header__section"
      >
        <slot name="action2" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: Array,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasAction2(): boolean {
      return !!this.$slots.action2;
    }
  }
});
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  &__icon {
    color: $nearly-black;
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  &__section {
    display: flex;
    align-items: center;
  }
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.125rem;
    &--edit {
      font-size: 1rem;
      font-weight: 300;
    }
  }
  &__actions {
    display: flex;
    column-gap: 0.5rem;
  }
}
</style>
