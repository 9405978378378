import type { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { CURRENT_KYC_PATH_KEY } from "@/config/kyc";

const Kyc = () => import("../views/kyc/kyc.vue");

const Income = () => import("../views/kyc/economy/income.vue");
const MoneyLeft = () => import("../views/kyc/economy/money-left.vue");
const MonthlyRemainder = () => import("../views/kyc/economy/remainder.vue");
const CurrentSavings = () => import("../views/kyc/economy/current-savings.vue");

const BoundCapital = () => import("../views/create-portfolio/questions/economy/bound-capital.vue");
const Loans = () => import("../views/kyc/economy/loans.vue");
const LoanedAmount = () => import("../views/kyc/economy/loaned-amount.vue");

const Sustainability = () =>
  import("../views/common-signup/questions/preferences/sustainability.vue");
const SustainableInvestment = () =>
  import("../views/common-signup/questions/preferences/sustainable-investment.vue");
const SustainableMethod = () =>
  import("../views/common-signup/questions/preferences/sustainable-method.vue");
const SustainableInfo = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice-info.vue");
const SustainableChoice = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice.vue");
const SustainableChoiceVerify = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice-verify.vue");
const NegativeImpact = () => import("../views/common-signup/questions/preferences/negative-impact.vue");
const WarnChangeSustinabilityPreference = () =>
  import("../views/kyc/preferences/warn-change-sustainability-preference.vue");

const Occupation = () => import("../views/create-portfolio/questions/aml/occupation.vue");
const Interval = () => import("../views/create-portfolio/questions/aml/interval.vue");
const WithdrawalInterval = () => import("../views/kyc/aml/withdrawal-interval.vue");
const Amount = () => import("../views/create-portfolio/questions/aml/amount.vue");
const Purpose = () => import("../views/create-portfolio/questions/aml/purpose.vue");
const InvestmentBackground = () =>
  import("../views/create-portfolio/questions/aml/investment-background.vue");

const PoliticallyExposed = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed.vue");
const PoliticallyExposedAssociated = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-associated.vue");
const PoliticallyExposedAssociatedName = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-associated-name.vue");
const PoliticallyExposedAssociatedRelationshipRelative = () =>
  import(
    "../views/create-portfolio/questions/aml/politically-exposed-associated-relationship-relative.vue"
  );
const PoliticallyExposedAssociatedRelationshipCoworker = () =>
  import(
    "../views/create-portfolio/questions/aml/politically-exposed-associated-relationship-coworker.vue"
  );
const PoliticallyExposedAssociatedOrganization = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-associated-organization.vue");

const PoliticallyExposedCountry = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-country.vue");
const PoliticallyExposedOngoing = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-ongoing.vue");
const PoliticallyExposedSavings = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-savings.vue");
const PoliticallyExposedTitle = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-title.vue");
const PoliticallyExposedTerminated = () =>
  import("../views/create-portfolio/questions/aml/politically-exposed-terminated.vue");

const SwedishCitizenship = () => import("../views/kyc/aml/swedish-citizenship.vue");
const TaxLiable = () => import("../views/kyc/aml/tax-liable.vue");
const Citizenship = () => import("../views/create-portfolio/questions/aml/citizenship.vue");
const SwedishTaxResidence = () => import("../views/kyc/aml/swedish-tax-residence.vue");

const UsaTaxIdentificationNumber = () =>
  import("../views/kyc/aml/usa-tax-identification-number.vue");
const TaxLiableUsa = () => import("../views/kyc/aml/tax-liable-usa.vue");

const TaxableCountry = () => import("../views/create-portfolio/questions/aml/taxable-country.vue");

const KycSign = () => import("../views/kyc/kyc-sign.vue");
const KycValidation = () => import("../views/kyc/kyc-validation.vue");

export default [
  {
    path: "kyc",
    name: "kyc",
    component: Kyc,

    // Always allow the user to go to return to a page they have previously visited.
    // If they try to go to any other question, put them on the last visited path.
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (path?: RouteLocationRaw) => void,
    ) => {
      const { getters } = store as any;
      if (to.path.endsWith("/kyc")) {
        // This check is here to avoid infinite recursion.
        next();
      } else {
        const latestVisitedPath = sessionStorage.getItem(CURRENT_KYC_PATH_KEY);

        if (latestVisitedPath) {
          const latestVisitedQuestion = getters.questionProgress(latestVisitedPath);
          const toQuestion = getters.questionProgress(to.path);

          const hasVisitedToQuestion
            = latestVisitedQuestion
            && toQuestion
            && (toQuestion.partNumber < latestVisitedQuestion.partNumber
            || (toQuestion.partNumber === latestVisitedQuestion.partNumber
            && toQuestion.questionNumber <= latestVisitedQuestion.questionNumber));

          if (hasVisitedToQuestion) {
            next();
          } else {
            // The user tries to access a question or introduction which doesn't exist or which
            // they haven't reached using the normal flow. Put them on the latest visited path.
            next({ params: to.params, path: latestVisitedPath, replace: true });
          }
        } else {
          // The user has not yet started the signup flow. Put them on the signup start page.
          next({ params: to.params, name: "kyc", replace: true });
        }
      }
    },
    children: [
      {
        path: "economy/income",
        component: Income,
      },
      {
        path: "economy/money-left",
        component: MoneyLeft,
      },
      {
        path: "economy/remainder",
        component: MonthlyRemainder,
      },
      {
        path: "economy/current-savings",
        component: CurrentSavings,
      },
      {
        path: "economy/bound-capital",
        component: BoundCapital,
      },
      {
        path: "economy/loans",
        component: Loans,
      },
      {
        path: "economy/loaned-amount",
        component: LoanedAmount,
      },
      { path: "preferences/sustainability", component: Sustainability },
      { path: "preferences/sustainable-investment", component: SustainableInvestment },
      { path: "preferences/sustainable-method", component: SustainableMethod },
      { path: "preferences/sustainable-info", component: SustainableInfo },
      { path: "preferences/sustainable-choice", component: SustainableChoice },
      { path: "preferences/sustainable-choice-verify", component: SustainableChoiceVerify },
      { path: "preferences/negative-impact", component: NegativeImpact },
      {
        path: "preferences/warn-change-sustainability-preference",
        component: WarnChangeSustinabilityPreference,
      },
      { path: "preferences/sustainable-choice-verify", component: SustainableChoiceVerify },
      { path: "aml/occupation", component: Occupation },
      { path: "aml/interval", component: Interval },
      { path: "aml/amount", component: Amount },
      { path: "aml/purpose", component: Purpose },
      { path: "aml/investment-background", component: InvestmentBackground },
      { path: "aml/withdrawal-interval", component: WithdrawalInterval },

      { path: "aml/politically-exposed", component: PoliticallyExposed },
      { path: "aml/politically-exposed-title", component: PoliticallyExposedTitle },
      { path: "aml/politically-exposed-country", component: PoliticallyExposedCountry },
      { path: "aml/politically-exposed-ongoing", component: PoliticallyExposedOngoing },
      { path: "aml/politically-exposed-savings", component: PoliticallyExposedSavings },
      {
        path: "aml/politically-exposed-terminated",
        component: PoliticallyExposedTerminated,
      },
      {
        path: "aml/politically-exposed-associated",
        component: PoliticallyExposedAssociated,
      },
      {
        path: "aml/politically-exposed-associated-name",
        component: PoliticallyExposedAssociatedName,
      },
      {
        path: "aml/politically-exposed-associated-relationship-relative",
        component: PoliticallyExposedAssociatedRelationshipRelative,
      },
      {
        path: "aml/politically-exposed-associated-relationship-coworker",
        component: PoliticallyExposedAssociatedRelationshipCoworker,
      },
      { path: "aml/politically-exposed-associated-title", component: PoliticallyExposedTitle },
      { path: "aml/politically-exposed-associated-country", component: PoliticallyExposedCountry },
      { path: "aml/politically-exposed-associated-ongoing", component: PoliticallyExposedOngoing },
      { path: "aml/politically-exposed-associated-savings", component: PoliticallyExposedSavings },
      {
        path: "aml/politically-exposed-associated-organization",
        component: PoliticallyExposedAssociatedOrganization,
      },
      {
        path: "aml/politically-exposed-associated-terminated",
        component: PoliticallyExposedTerminated,
      },
      {
        path: "aml/swedish-citizenship",
        component: SwedishCitizenship,
      },
      {
        path: "aml/tax-liable",
        component: TaxLiable,
      },
      {
        path: "aml/tax-liable-information",
        component: TaxLiable,
      },
      {
        path: "aml/citizenship",
        component: Citizenship,
      },
      {
        path: "aml/swedish-tax-residence",
        component: SwedishTaxResidence,
      },
      {
        path: "aml/tax-liable-usa",
        component: TaxLiableUsa,
      },
      {
        path: "aml/tin",
        component: UsaTaxIdentificationNumber,
      },
      {
        path: "aml/taxable-country",
        component: TaxableCountry,
      },
      {
        path: "sign",
        component: KycSign,
      },
      {
        path: "validation",
        component: KycValidation,
      },
      {
        // Catch-all
        path: "*",
      },
    ],
  },
] as RouteRecordRaw[];
