<template>
  <div class="asset-allocation-wrapper">
    <card-header
      :title="$t('assetType')"
      :icon="['fal', 'sliders-h']"
      @click="() => (showDialog = true)"
    />
    <div class="asset-allocation">
      <vue-apex-charts
        class="asset-allocation__chart"
        height="300px"
        width="320px"
        type="radialBar"
        :options="chartOptions"
        :series="chartSeries"
      />
      <chart-legend
        :stock-fraction="legendStockFraction"
        :bond-fraction="legendBondFraction"
      />
    </div>
    <loading-spinner-overlay :model-value="showLoadingSpinner" />
    <side-dialog
      v-model="showDialog"
      :title="$t('assetType')"
    >
      <div class="asset-allocation__side-dialog">
        <p class="asset-allocation__side-dialog-description">
          {{ $t("portfolio.analysis.asset-allocation.infoBodyOne") }}
        </p>
        <p class="asset-allocation__side-dialog-description">
          {{ $t("portfolio.analysis.asset-allocation.infoBodyTwo") }}
        </p>
        <common-button
          class="asset-allocation__side-dialog__button"
          @click="() => (showDialog = false)"
        >
          {{ $t("ok") }}
        </common-button>
      </div>
    </side-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { ApexOptions } from "apexcharts";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import { softBlue } from "@/styles/colors";
import CommonButton from "@/components/button.vue";
import CardHeader from "./card-header.vue";

import ChartLegend from "./asset-allocation-legend.vue";
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  components: {
    CardHeader,
    ChartLegend,
    LoadingSpinnerOverlay,
    SideDialog,
    CommonButton,
    VueApexCharts
  },
  data: (): any => ({
    legendStockFraction: 0,
    legendBondFraction: 0,
    showDialog: false
  }),
  computed: {
    ...mapGetters(["stockFraction", "etfFraction"]),
    showLoadingSpinner(): boolean {
      const { isLoadingHoldingSummary } = this.$store.state.portfolioStore;
      return isLoadingHoldingSummary;
    },
    chartSeries(): number[] {
      if (this.stockFraction !== undefined) {
        return [this.stockFraction * 100, this.etfFraction * 100];
      }
      return [0, 0];
    },
    chartOptions(): ApexOptions {
      return {
        chart: {
          animations: {
            easing: "easeinout"
          }
        },
        plotOptions: {
          radialBar: {
            offsetY: -45,
            offsetX: -50,
            hollow: {
              size: "21%"
            },
            track: {
              margin: 15,
              background: "#F1F5F6"
            },
            startAngle: -90,
            endAngle: 90,
            dataLabels: {
              name: {
                show: false
              },
              value: {
                show: false
              }
            }
          }
        },
        legend: {
          show: false
        },
        colors: [softBlue, "#BDD0D5"],
        grid: {
          padding: {
            top: 10,
            bottom: 20
          }
        },
        states: {
          hover: {
            filter: {
              type: "none"
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        }
      };
    }
  },
  watch: {
    stockFraction: {
      immediate: true,
      handler(newVal: number | undefined, oldVal: number | undefined) {
        if (newVal !== undefined) {
          // Reset the animated percentages if they are previously undefined.
          this.legendStockFraction = this.legendStockFraction || 0;

          // Animate the legendStockFraction and legendBondFraction values.
          this.$animejs({
            targets: this,
            legendStockFraction: this.stockFraction,
            legendBondFraction: this.etfFraction,
            round: 1000,
            duration: 300,
            easing: "easeInOutSine"
          });
        }
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.asset-allocation-wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}
.asset-allocation {
  padding: 2rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  @include small-only {
    padding: 1rem 0;
  }
  @include large-up {
    justify-content: center;
    flex-direction: row;
  }
  &__chart {
    min-height: 125px !important;
    width: 220px;
    overflow: hidden;
    margin-bottom: -15px;
    :deep(.apexcharts-canvas) {
      height: 125px !important;
    }
  }
  &__side-dialog {
    padding: 2rem;
    &-description {
      font-weight: 300;
    }
    &__button {
      display: flex;
      margin: 2rem auto;
      border-radius: 0.5rem;
      font-weight: 500;
      font-family: $heading-font-family;
      text-transform: none;
      font-size: 1.125rem;
      box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16);
    }
  }
}
</style>
