import { MutationTree } from "vuex";
import {
  Occupation,
  DepositFrequency,
  WithdrawalFrequency,
  DepositSize,
  Market,
  TimeToWithdraw,
  StrategyFocus,
  SparData,
  LegalEntityValidationResponse,
  Portfolio,
  RegisterUserError,
  TiedAgentPortfolioTheme,
  TiedAgent
} from "@/clients";

import { EsgFilters } from "@/types/portfolio";
import { BankAccountAlternative, PoliticallyExposedInfo, SignupState } from "./types";
import {
  Sector,
  RiskWillingness,
  Loan,
  SavingAlternative,
  BackgroundAlternative,
  CountryCode,
  RegistrationSignState,
  SustainabilityImpact
} from "../../types/signup";

export const SignupMutation = {
  setTrackingGuid: "setTrackingGuid",
  showProgressBar: "showProgressBar",
  setEmail: "setEmail",
  setPhoneNumber: "setPhoneNumber",
  setAcceptedPersonalDataPolicy: "setAcceptedPersonalDataPolicy",
  setTimeToWithdraw: "setTimeToWithdraw",
  setMonthlyIncome: "setMonthlyIncome",
  setMonthlyRemainder: "setMonthlyRemainder",
  setTotalSavings: "setTotalSavings",
  setBoundCapital: "setBoundCapital",
  includeSector: "includeSector",
  setFocus: "setFocus",
  setRiskWillingness: "setRiskWillingness",
  setPaymentMethod: "setPaymentMethod",
  setHasLoan: "setHasLoan",
  setTotalLoans: "setTotalLoans",
  setCustomRiskLevel: "setCustomRiskLevel",
  resetRiskLevel: "resetRiskLevel",
  setCitizenship1: "setCitizenship1",
  setCitizenship2: "setCitizenship2",
  setCitizenship3: "setCitizenship3",
  setOccupation: "setOccupation",
  setBank: "setBank",
  setPersonalIdentityNumber: "setPersonalIdentityNumber",
  setDepositFrequency: "setDepositFrequency",
  setWithdrawalFrequency: "setWithdrawalFrequency",
  setDepositAmount: "setDepositAmount",
  setSavingPurpose: "setSavingPurpose",
  setInvestmentBackground: "setInvestmentBackground",
  setClearingNumber: "setClearingNumber",
  setAccountNumber: "setAccountNumber",
  setFirstDeposit: "setFirstDeposit",
  setMonthlySavingsAmount: "setMonthlySavingsAmount",
  setMarketFocus: "setMarketFocus",
  setCalculatedRiskLevel: "setCalculatedRiskLevel",
  setCompletedSignUp: "setCompletedSignUp",
  setState: "setState",
  setPep: "setPep",
  setAssociatedPepRelative: "setAssociatedPepRelative",
  setAssociatedPepCoworker: "setAssociatedPepCoworker",
  setLivingInSweden: "setLivingInSweden",
  setTaxableUsa: "setTaxableUsa",
  setTaxableOtherCountry: "setTaxableOtherCountry",
  setHasHighExpenses: "setHasHighExpenses",
  setLoadingPdf: "setLoadingPdf",
  setHasSwedishTaxResidence: "setHasSwedishTaxResidence",
  setTaxIdentificationNumber: "setTaxIdentificationNumber",
  setUsaiTin: "setUsaiTin",
  setUsaSsn: "setUsaSsn",
  setPrincipal: "setPrincipal",
  setBankAccountNumber: "setBankAccountNumber",
  setBankAccountName: "setBankAccountName",
  setLoadingAccountDetails: "setLoadingAccountDetails",
  setFullBankAccountNumber: "setFullBankAccountNumber",
  setClearingNumberParsingError: "setClearingNumberParsingError",
  setTinkError: "setTinkError",
  setMode: "setMode",
  setBankAccountAlternatives: "setBankAccountAlternatives",
  setSelectedBankAccountAlternative: "setSelectedBankAccountAlternative",
  setRegistrationSignState: "setRegistrationSignState",
  setRegistrationError: "setRegistrationError",
  setRegisteredUserId: "setRegisteredUserId",
  setRegisteredUserApproved: "setRegisteredUserApproved",
  setReferralCode: "setReferralCode",
  setAffiliateReferralCode: "setAffiliateReferralCode",
  setAffiliateReferralName: "setAffiliateReferralName",
  setSparData: "setSparData",
  setPepTitle: "setPepTitle",
  setPepCountry: "setPepCountry",
  setPepOrganization: "setPepOrganization",
  setPepTerminated: "setPepTerminated",
  setPepTerminatedYear: "setPepTerminatedYear",
  setPepTerminatedMonth: "setPepTerminatedMonth",
  setPepSavingsDescription: "setPepSavingsDescription",
  setAssociatedPepTitle: "setAssociatedPepTitle",
  setAssociatedPepCountry: "setAssociatedPepCountry",
  setAssociatedPepOrganization: "setAssociatedPepOrganization",
  setAssociatedPepOrganizationNumber: "setAssociatedPepOrganizationNumber",
  setAssociatedPepTerminated: "setAssociatedPepTerminated",
  setAssociatedPepSavingsDescription: "setAssociatedPepSavingsDescription",
  setAssociatedPepFirstName: "setAssociatedPepFirstName",
  setAssociatedPepLastName: "setAssociatedPepLastName",
  setAssociatedPepRelationship: "setAssociatedPepRelationship",
  setAssociatedPepDateOfBirthOrSsn: "setAssociatedPepDateOfBirthOrSsn",
  setAssociatedPepTerminatedYear: "setAssociatedPepTerminatedYear",
  setAssociatedPepTerminatedMonth: "setAssociatedPepTerminatedMonth",
  resetPepInfo: "resetPepInfo",
  resetAssociatedPepInfo: "resetAssociatedPepInfo",
  setLegalEntityValidationChange: "setLegalEntityValidationChange",
  setIncomeFromOtherText: "setIncomeFromOtherText",
  setEsgFilters: "setEsgFilters",
  setTakeSustainabilityIntoAccount: "setTakeSustainabilityIntoAccount",
  setSustainabilityImpact: "setSustainabilityImpact",
  setEditedPortfolios: "setEditedPortfolios",
  setPortfolioTheme: "setPortfolioTheme",
  setTiedAgent: "setTiedAgent",
  setPrimaryColor: "setPrimaryColor",
  setEsgPai: "setEsgPai",
  setColors: "setColors",
  setShowEvidaLogoHeader: "setShowEvidaLogoHeader"
};
export const getPepInitialState = (): PoliticallyExposedInfo => ({
  title: undefined,
  country: undefined,
  organization: undefined,
  organizationNumber: undefined,
  terminated: undefined,
  terminatedYear: undefined,
  terminatedMonth: undefined,
  savingsDescription: undefined,
  relationship: undefined,
  firstName: undefined,
  lastName: undefined,
  dateOfBirthOrSsn: undefined
});
export const mutations: MutationTree<SignupState> = {
  [SignupMutation.setSelectedBankAccountAlternative](state, payload: BankAccountAlternative) {
    state.selectedBankAccountAlternative = payload;
  },
  [SignupMutation.setBankAccountAlternatives](state, payload: BankAccountAlternative[]) {
    state.bankAccountAlternatives = payload;
  },
  [SignupMutation.setTrackingGuid](state, trackingGuid: string) {
    state.trackingGuid = trackingGuid;
  },
  [SignupMutation.showProgressBar](state, payload: boolean) {
    state.showProgressBar = payload;
  },
  [SignupMutation.setEmail](state, payload: string) {
    state.contactDetails.email = payload;
  },
  [SignupMutation.setPhoneNumber](state, payload: string) {
    state.contactDetails.phoneNumber = payload;
  },
  [SignupMutation.setAcceptedPersonalDataPolicy](state, payload: boolean) {
    state.acceptedPersonalDataPolicy = payload;
  },
  [SignupMutation.setTimeToWithdraw](state, payload: TimeToWithdraw) {
    state.timeToWithdraw = payload;
  },
  [SignupMutation.setMonthlyIncome](state, payload: number) {
    state.monthlyIncome = payload;
  },
  [SignupMutation.setMonthlyRemainder](state, payload: number) {
    state.monthlyRemainder = payload;
  },
  [SignupMutation.setTotalSavings](state, payload: number) {
    state.totalSavings = payload;
  },
  [SignupMutation.setBoundCapital](state, payload: number) {
    state.boundCapital = payload;
  },
  [SignupMutation.includeSector](state, payload: { key: Sector; value: boolean }) {
    state.includedSectors[payload.key] = payload.value;
  },
  [SignupMutation.setFocus](state, payload: StrategyFocus) {
    state.focus = payload;
  },
  [SignupMutation.setRiskWillingness](state, payload: RiskWillingness) {
    state.riskWillingness = payload;
  },
  [SignupMutation.setHasLoan](state, payload: { key: Loan; value: boolean }) {
    state.hasLoans[payload.key] = payload.value;
  },
  [SignupMutation.setTotalLoans](state, payload: number) {
    state.totalLoans = payload;
  },
  [SignupMutation.setCustomRiskLevel](state, payload: number) {
    state.customRiskLevel = payload;
  },
  [SignupMutation.resetRiskLevel](state) {
    state.customRiskLevel = undefined;
  },
  [SignupMutation.setOccupation](state, chosenOccupation: Occupation) {
    state.occupation = chosenOccupation;
  },
  [SignupMutation.setDepositFrequency](state, chosenInterval: DepositFrequency) {
    state.depositFrequency = chosenInterval;
  },
  [SignupMutation.setWithdrawalFrequency](state, chosenInterval: WithdrawalFrequency) {
    state.withdrawalFrequency = chosenInterval;
  },
  [SignupMutation.setDepositAmount](state, chosenAmount: DepositSize) {
    state.depositAmount = chosenAmount;
  },
  [SignupMutation.setPersonalIdentityNumber](state, personalIdentityNumber: string) {
    state.personalIdentityNumber = personalIdentityNumber;
  },
  [SignupMutation.setClearingNumber](state, clearingNumber: string) {
    state.clearingNumber = clearingNumber;
  },
  [SignupMutation.setPaymentMethod](state, paymentMethod: string) {
    state.chosenPaymentMethod = paymentMethod;
  },
  [SignupMutation.setFirstDeposit](state, firstDeposit: number | undefined) {
    state.firstDeposit = firstDeposit;
  },
  [SignupMutation.setMonthlySavingsAmount](state, amount: number) {
    state.monthlySavingsAmount = amount;
  },
  [SignupMutation.setSavingPurpose](
    state,
    savingAlternative: { key: SavingAlternative; value: boolean }
  ) {
    state.savingPurpose[savingAlternative.key] = savingAlternative.value;
  },
  [SignupMutation.setInvestmentBackground](
    state,
    backgroundAlternative: { key: BackgroundAlternative; value: boolean }
  ) {
    state.investmentBackground[backgroundAlternative.key] = backgroundAlternative.value;
  },
  [SignupMutation.setMarketFocus](state, market: Market) {
    state.marketFocus = market;
  },
  [SignupMutation.setCalculatedRiskLevel](state, riskLevel: number) {
    state.calculatedRiskLevel = riskLevel;
  },
  [SignupMutation.setCompletedSignUp](state, completed: boolean) {
    state.completedSignUp = completed;
  },
  [SignupMutation.setState](state, stateChange: SignupState) {
    Object.assign(state, stateChange);
  },
  [SignupMutation.setPep](state, isPep: boolean) {
    state.pep = isPep;
  },
  [SignupMutation.setAssociatedPepRelative](state, value: boolean) {
    state.associatedPepRelative = value;
  },
  [SignupMutation.setAssociatedPepCoworker](state, value: boolean) {
    state.associatedPepCoworker = value;
  },
  [SignupMutation.setLivingInSweden](state, isLivingInSweden: boolean) {
    state.livingInSweden = isLivingInSweden;
  },
  [SignupMutation.setTaxableUsa](state, isTaxableInUsa: boolean) {
    state.taxableUsa = isTaxableInUsa;
  },
  [SignupMutation.setTaxableOtherCountry](state, value: string) {
    state.taxableOtherCountry = value;
  },
  [SignupMutation.setHasHighExpenses](state, value: boolean) {
    state.highExpenses = value;
  },
  [SignupMutation.setLoadingPdf](state, { pdfId, loading }) {
    const { loadingPdf } = state;
    const loadingPdfCopy = loadingPdf.slice();
    loadingPdfCopy[pdfId] = loading;
    state.loadingPdf = loadingPdfCopy;
  },
  [SignupMutation.setCitizenship1](state, citizenship: CountryCode) {
    state.citizenship1 = citizenship;
  },
  [SignupMutation.setCitizenship2](state, citizenship: CountryCode) {
    state.citizenship2 = citizenship;
  },
  [SignupMutation.setCitizenship3](state, citizenship: CountryCode) {
    state.citizenship3 = citizenship;
  },
  [SignupMutation.setHasSwedishTaxResidence](state, value: boolean) {
    state.swedishTaxResidence = value;
  },
  [SignupMutation.setTaxIdentificationNumber](state, taxIdentificationNumber: string) {
    state.taxIdentificationNumber = taxIdentificationNumber;
  },
  [SignupMutation.setUsaiTin](state, tin: string) {
    state.usaiTin = tin;
  },
  [SignupMutation.setUsaSsn](state, ssn: string) {
    state.usaSsn = ssn;
  },
  [SignupMutation.setPrincipal](state, value: boolean) {
    state.principal = value;
  },
  [SignupMutation.setBank](state, chosenBank: string) {
    state.bankName = chosenBank;
  },
  [SignupMutation.setBankAccountNumber](state, value: string) {
    state.bankAccountNumber = value;
  },
  [SignupMutation.setFullBankAccountNumber](state, value: string) {
    state.fullBankAccountNumber = value;
  },
  [SignupMutation.setBankAccountName](state, value: string) {
    state.bankAccountName = value;
  },
  [SignupMutation.setLoadingAccountDetails](state, value: boolean) {
    state.loadingAccountDetails = value;
  },
  [SignupMutation.setClearingNumberParsingError](state, value: boolean) {
    state.clearingNumberParsingError = value;
  },
  [SignupMutation.setTinkError](state, value: boolean) {
    state.tinkError = value;
  },
  [SignupMutation.setRegistrationSignState](state, value: RegistrationSignState) {
    state.registrationSignState = value;
  },
  [SignupMutation.setRegistrationError](state, value: RegisterUserError) {
    state.registrationError = value;
  },
  [SignupMutation.setRegisteredUserId](state, value: number) {
    state.registeredUserId = value;
  },
  [SignupMutation.setRegisteredUserApproved](state, value: boolean) {
    state.registeredUserApproved = value;
  },
  [SignupMutation.setReferralCode](state, value: string) {
    state.referralCode = value;
  },
  [SignupMutation.setAffiliateReferralCode](state, value: string) {
    state.affiliateReferralCode = value;
  },
  [SignupMutation.setAffiliateReferralName](state, value: string) {
    state.affiliateReferralName = value;
  },
  [SignupMutation.setSparData](state, value: SparData) {
    state.firstName = value.firstName;
    state.lastName = value.lastName;
    state.address = value.address;
    state.zipCode = value.zipCode;
    state.city = value.city;
  },
  [SignupMutation.setPepTitle](state, value: string) {
    state.pepInfo.title = value;
  },
  [SignupMutation.setPepCountry](state, payload: string) {
    state.pepInfo.country = payload;
  },
  [SignupMutation.setPepOrganization](state, payload: string) {
    state.pepInfo.organization = payload;
  },
  [SignupMutation.setPepTerminated](state, payload: boolean) {
    state.pepInfo.terminated = payload;
  },
  [SignupMutation.setPepTerminatedYear](state, payload: number) {
    state.pepInfo.terminatedYear = payload;
  },
  [SignupMutation.setPepTerminatedMonth](state, payload: number) {
    state.pepInfo.terminatedMonth = payload;
  },
  [SignupMutation.setPepSavingsDescription](state, payload: string) {
    state.pepInfo.savingsDescription = payload;
  },
  [SignupMutation.setAssociatedPepTitle](state, value: string) {
    state.associatedPepInfo.title = value;
  },
  [SignupMutation.setAssociatedPepCountry](state, payload: string) {
    state.associatedPepInfo.country = payload;
  },
  [SignupMutation.setAssociatedPepOrganization](state, payload: string) {
    state.associatedPepInfo.organization = payload;
  },
  [SignupMutation.setAssociatedPepOrganizationNumber](state, payload: string) {
    state.associatedPepInfo.organizationNumber = payload;
  },
  [SignupMutation.setAssociatedPepTerminated](state, payload: boolean) {
    state.associatedPepInfo.terminated = payload;
  },
  [SignupMutation.setAssociatedPepSavingsDescription](state, payload: string) {
    state.associatedPepInfo.savingsDescription = payload;
  },
  [SignupMutation.setAssociatedPepFirstName](state, payload: string) {
    state.associatedPepInfo.firstName = payload;
  },
  [SignupMutation.setAssociatedPepLastName](state, payload: string) {
    state.associatedPepInfo.lastName = payload;
  },
  [SignupMutation.setAssociatedPepDateOfBirthOrSsn](state, payload: string) {
    state.associatedPepInfo.dateOfBirthOrSsn = payload;
  },
  [SignupMutation.setAssociatedPepRelationship](state, payload: string) {
    state.associatedPepInfo.relationship = payload;
  },
  [SignupMutation.setAssociatedPepTerminatedYear](state, payload: number) {
    state.associatedPepInfo.terminatedYear = payload;
  },
  [SignupMutation.setAssociatedPepTerminatedMonth](state, payload: number) {
    state.associatedPepInfo.terminatedMonth = payload;
  },
  [SignupMutation.resetPepInfo](state) {
    state.pepInfo = getPepInitialState();
  },
  [SignupMutation.resetAssociatedPepInfo](state) {
    state.associatedPepInfo = getPepInitialState();
  },
  [SignupMutation.setLegalEntityValidationChange](state, payload: LegalEntityValidationResponse) {
    state.legalEntityValidationChange = payload;
  },
  [SignupMutation.setIncomeFromOtherText](state, value: string) {
    state.incomeFromOtherText = value;
  },
  [SignupMutation.setEsgFilters](state, value: EsgFilters) {
    state.esgFilter = value;
  },
  [SignupMutation.setTakeSustainabilityIntoAccount](state, value: boolean) {
    state.takeSustainabilityIntoAccount = value;
  },
  [SignupMutation.setSustainabilityImpact](state, value: SustainabilityImpact) {
    state.sustainabilityImpact = value;
  },
  [SignupMutation.setEditedPortfolios](state, value: Portfolio[]) {
    state.editedPortfolios = value;
  },
  [SignupMutation.setPortfolioTheme](state, value: TiedAgentPortfolioTheme) {
    state.portfolioTheme = value;
  },
  [SignupMutation.setTiedAgent](state, value: TiedAgent) {
    state.tiedAgent = value;
  },
  [SignupMutation.setPrimaryColor](state, value: string) {
    state.primaryColor = value;
  },
  [SignupMutation.setEsgPai](state, payload: { index: number; value: boolean }) {
    switch (payload.index) {
      case 1:
        state.esgPais.pai1 = payload.value;
        break;
      case 2:
        state.esgPais.pai2 = payload.value;
        break;
      case 3:
        state.esgPais.pai3 = payload.value;
        break;
      case 4:
        state.esgPais.pai4 = payload.value;
        break;
      case 5:
        state.esgPais.pai5 = payload.value;
        break;
      case 7:
        state.esgPais.pai7 = payload.value;
        break;
      case 9:
        state.esgPais.pai9 = payload.value;
        break;
      case 10:
        state.esgPais.pai10 = payload.value;
        break;
      case 11:
        state.esgPais.pai11 = payload.value;
        break;
      case 13:
        state.esgPais.pai13 = payload.value;
        break;
      case 14:
        state.esgPais.pai14 = payload.value;
        break;
      default:
        throw new Error("Invalid index");
    }
  },
  [SignupMutation.setColors](state, value: any) {
    state.colors = value;
  },
  [SignupMutation.setShowEvidaLogoHeader](state, value: boolean) {
    state.showEvidaLogoHeader = value;
  }
};
