<template>
  <div class="range__slider-wrapper">
    <p
      class="range__value"
      data-jest="value"
      :style="{ color: colors?.text || undefined }"
    >
      {{ displayValue }}
    </p>
    <v-slider
      :model-value="index"
      :min="0"
      :max="values.length - 1"
      :color="computedSliderColor"
      :track-color="computedSliderColor"
      data-jest="slider"
      data-cy="range-slider"
      :step="1"
      v-bind="$attrs"
      @update:model-value="sliderChange"
    />
    <p
      class="range__label"
      :style="{ color: colors?.text || undefined }"
    >
      {{ label }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { Colors } from "@/styles/colors";
import { softBlue } from "@/styles/colors";
import type { SignupState } from "@/store/signup/types";

export default defineComponent({
  props: {
    values: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    boundedMaximum: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Number,
      required: true,
    },
    sliderColor: {
      type: String,
      default: undefined,
    },
  },
  emits: ["update:model-value"],
  data() {
    return {
      softBlue,
    };
  },
  computed: {
    colors(): Colors | undefined {
      const { colors } = this.$store.state.signupStore as SignupState;
      return colors || undefined;
    },
    computedSliderColor() {
      if (this.colors) {
        return this.colors?.accent;
      } else {
        if (!this.sliderColor) {
          return softBlue;
        }
        return this.sliderColor;
      }
    },
    displayValue(): string {
      if (this.modelValue) {
        if (this.index < this.values.length - 1) {
          return this.modelValue.toLocaleString("sv-SE");
        } else {
          return `${this.modelValue.toLocaleString("sv-SE")}${this.boundedMaximum ? "" : "+"}`;
        }
      } else {
        return "0";
      }
    },
    index(): number {
      const initialIndex = this.values.findIndex(element => element === this.modelValue);
      return initialIndex > -1
        ? initialIndex
        : this.findClosestIndex(this.values as number[], this.modelValue);
    },
    alternatives() {
      return [
        {
          text: this.$t("next"),
          key: "next",
        },
      ];
    },
  },
  methods: {
    sliderChange(index: number) {
      this.$emit("update:model-value", this.values[index]);
    },
    findClosestIndex(values: number[], value: number) {
      let closestFloor = -1;
      for (let i = 0; i < values.length && values[i] < value; i += 1) {
        closestFloor = i;
      }
      if (closestFloor === values.length - 1) {
        return closestFloor;
      }

      return Math.abs(values[closestFloor] - value) < Math.abs(values[closestFloor + 1] - value)
        ? closestFloor
        : closestFloor + 1;
    },
  },
});
</script>

<style lang="scss" scoped>
.range {
  &__label {
    margin: 0;
    font-family: $heading-font-family;
    color: rgba($nearly-black, 0.63);
    @include medium-up {
      font-size: 1.25rem;
    }
  }
  &__slider-wrapper {
    text-align: center;
    min-height: 10.5rem;
    margin-top: 6vh;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include small-down {
      margin-top: 0;
    }
    @include medium-up {
      max-width: 25rem;
      min-height: auto;
    }
  }
  &__upper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__value {
    font-size: 3.125rem;
    color: rgba($nearly-black, 0.8);
    font-family: $heading-font-family;
    font-weight: bold;
    margin: 0;
    @include medium-up {
      font-size: 4.5rem;
    }
  }
}

:deep(.v-slider) {
  cursor: pointer;
}
/** need some base css of v-slider to get this to work. Does not work to just set width and height increase. This will probably be included in future version of vuetify*/
:deep(.v-slider__thumb:before) {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  content: "";
  color: inherit;
  width: 200%;
  height: 200%;
  border-radius: 50%;
  background: currentColor;
  opacity: 0.3;
  position: absolute;
  left: -50%;
  top: -50%;
  transform: scale(0.2);
  pointer-events: none;
}
:deep(.v-slider__thumb:after) {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  content: "";
  color: inherit;
  width: 600%;
  height: 600%;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  left: -150%;
  top: -150%;
}
</style>
