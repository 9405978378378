<template>
  <div class="loans-warning">
    <single-choice
      :alternatives="informationAlternatives"
      @click="goToNext"
    >
      <information-text-section
        :heading="$t('create-portfolio.questions.economy.loans-warning.informationHeading')"
        :body="$t('create-portfolio.questions.economy.loans-warning.informationBody')"
      >
        <icon />
      </information-text-section>
    </single-choice>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SingleChoice as SingleChoiceModel } from "@/types/signup";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Icon from "@/assets/images/signup/file-invoice-dollar-light.svg";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    Icon
  },
  computed: {
    informationAlternatives(): SingleChoiceModel[] {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.loans-warning {
  height: 100%;
  width: 100%;
}
</style>
