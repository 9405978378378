<template>
  <div class="tax-liable">
    <single-choice-template :alternatives="alternatives" :two-columns="true" @click="select">
      <question-text-section :heading="$t('taxableOtherBody')" :body="$t('taxableBody')" progress />
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import { SignupMutation } from "@/store/signup/mutations";

import { SignupFlowModificationType } from "@/config/signup-modification";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import InformationLink from "@/views/signup/information-link.vue";
import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";

export default defineComponent({
	emits: ["next"],
  components: {
    SingleChoiceTemplate,
    InformationLink,
    SideDialog,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("yes"),
          key: "isTaxLiable",
          secondary: true
        },
        {
          text: this.$t("no"),
          key: "notTaxLiable"
        }
      ];
    }
  },
  methods: {
    async select(key: string): Promise<void> {
      const isTaxLiable = key === "isTaxLiable";
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.TaxableCountry,
        include: isTaxLiable
      });
      if (!isTaxLiable) {
        this.$store.commit(SignupMutation.setTaxIdentificationNumber, undefined);
        this.$store.commit(SignupMutation.setTaxableOtherCountry, undefined);
      }
      this.$emit("next");
    }
  }
});
</script>

<style lang="scss">
.tax-liable {
  height: 100%;
  width: 100%;
}
</style>
