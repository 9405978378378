<template>
  <SingleChoice
    :alternatives="alternatives"
    :disabled="!!errors"
    @click="buttonClick"
  >
    <QuestionTextSection
      progress
      :heading="$t('common-signup.questions.economy.remainder.heading')"
      :body="$t('common-signup.questions.economy.remainder.body')"
    />
    <RangeTemplate
      v-model="modelValue"
      :values="values"
      :label="$t('currency')"
      :error="!!errors"
      :error-messages="errors"
    />
  </SingleChoice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RangeTemplate from "../../../signup/templates/range.vue";
import { SignupMutation } from "@/store/signup/mutations";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import type { SignupState } from "@/store/signup/types";

export default defineComponent({
  components: {
    RangeTemplate,
    QuestionTextSection,
    SingleChoice,
  },
  extends: Question,
  props: {
    disableIfLowerThanIncome: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["next"],
  computed: {
    errors() {
      if (this.modelValue === 0) {
        return this.$t("common-signup.questions.economy.remainder.zeroError");
      }

      const { monthlyIncome } = this.$store.state.signupStore as SignupState;
      if (this.disableIfLowerThanIncome && (!monthlyIncome || this.modelValue > this.$store.state.signupStore.monthlyIncome)) {
        return this.$t("common-signup.questions.economy.remainder.highError");
      }

      return undefined;
    },
    values() {
      const values = [0, 500];
      for (let i = 1000; i < 60000; i += 1000) {
        values.push(i);
      }
      for (let i = 60000; i <= 100000; i += 5000) {
        values.push(i);
      }
      return values;
    },
    modelValue: {
      get(): number {
        return this.$store.state.signupStore.monthlyRemainder;
      },
      set(value: number) {
        this.$store.commit(SignupMutation.setMonthlyRemainder, value);
      },
    },
    alternatives(): any {
      return [
        {
          text: this.$t("next"),
          key: "next",
        },
      ];
    },
  },
  methods: {
    buttonClick() {
      this.$emit("next", this.modelValue);
    },
  },
});
</script>
