<template>
  <v-form
    class="form"
    method="POST"
    :action="actionUrl"
  >
    <h2>Formulär för Customer.io</h2>
    <v-text-field
      id="id_input"
      ref="form"
      v-model="id"
      style="width: 20rem"
      name="id"
      type="id"
      label="Customer.io ID"
    />
    <common-button
      :disabled="!id || !isValidUUID(id)"
      type="submit"
      >Skicka</common-button
    >
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { validate as isValidUUID } from "uuid";
import CommonButton from "@/components/button.vue";

export default defineComponent({
  components: {
    CommonButton
  },
  data: () => ({
    isValidUUID,
    id: undefined as string | undefined
  }),
  computed: {
    actionUrl() {
      const webUrl = encodeURIComponent(import.meta.env.VITE_WEB_BASE_URL as string);
      return `https://eu.customerioforms.com/forms/submit_action?site_id=3248133ff973356324ab&form_id=972d416c4de04ad&success_url=${webUrl}/cio-form-success`;
    }
  }
});
</script>

<style lang="scss" scoped>
.form {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
  flex-direction: column;
  row-gap: 2rem;
}
</style>
