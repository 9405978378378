<template>
  <div class="delete-portfolio">
    <SideDialog
      :model-value="modelValue"
      :title="$t('deleteAccountTitle')"
      @update:model-value="manageDialogEvent()"
    >
      <div class="delete-portfolio__dialog">
        <p class="delete-portfolio__title">
          {{ $t("portfolio.overview.delete-portfolio.user-delete-portfolio.bodyTitle") }}
        </p>
        <p class="delete-portfolio__body">
          {{ $t("portfolio.overview.delete-portfolio.user-delete-portfolio.manualBody1") }}
        </p>
        <p class="delete-portfolio__body">
          {{ $t("portfolio.overview.delete-portfolio.user-delete-portfolio.body2") }}<br>
          <span class="delete-portfolio__body--bold">{{ $t("supportEmail") }}</span>
        </p>
        <div class="delete-portfolio__actions">
          <CommonButton
            class="delete-portfolio__button"
            @click="() => manageDialogEvent()"
          >
            {{ $t("ok") }}
          </CommonButton>
        </div>
      </div>
    </SideDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";

export default defineComponent({
  components: {
    SideDialog,
    CommonButton,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:model-value"],
  methods: {
    manageDialogEvent(): void {
      this.$emit("update:model-value", false);
    },
  },
});
</script>

<style lang="scss" scoped>
.delete-portfolio {
  &__dialog {
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    grid-template-columns: 1fr;
  }
  &__title {
    font-weight: 600;
  }
  &__body {
    &--bold {
      font-weight: 600;
    }
  }
  &__actions {
    text-align: center;
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__button {
    display: flex;
    margin: 2rem auto;
    border-radius: 0.5rem;
    font-weight: 500;
    font-family: $heading-font-family;
    text-transform: none;
    font-size: 1.125rem;
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16);
  }
}
</style>
