<template>
  <div
    :class="[
      'percentage-development',
      positiveDevelopment ? 'percentage-development__blue' : 'percentage-development__red'
    ]"
    data-jest="development-percentage"
  >
    {{ developmentPercentageString }} %
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PerformanceResponse, PortfolioBase } from "@/clients";
import { NavigationState } from "@/store/navigation/types";
import { OverviewState } from "@/store/overview/types";
import {
  calculateTotalDevelopmentPercentage,
  formatTotalDevelopmentPercentage
} from "@/utils/development";

export default defineComponent({
  props: {
    portfolioId: {
      type: String,
      required: false
    },
    performance: { type: Object as () => PerformanceResponse | undefined, required: false }
  },
  computed: {
    portfolio(): PortfolioBase | undefined {
      const portfolios = (this.$store.state.navigationStore as NavigationState).portfolios;
      if (portfolios && portfolios.length > 0 && this.portfolioId) {
        return portfolios.find((x) => x.id === this.portfolioId);
      }
      return undefined;
    },
    performanceWithDataPoints(): PerformanceResponse | undefined {
      if (this.performance && Object.keys(this.performance).length > 0) {
        return this.performance;
      }
      return undefined;
    },
    developmentPercentageString(): string {
      if (!this.performanceWithDataPoints) {
        return "-";
      }
      return formatTotalDevelopmentPercentage(this.performanceWithDataPoints);
    },
    positiveDevelopment(): boolean {
      if (this.performance) {
        const developmentAbsolute = calculateTotalDevelopmentPercentage(this.performance);
        if (developmentAbsolute) {
          return developmentAbsolute >= 0;
        }
      }
      return true;
    }
  }
});
</script>

<style lang="scss" scoped>
.percentage-development {
  font-weight: 600;
  font-family: $heading-font-family;
  &__blue {
    color: $grayish-blue;
  }
  &__red {
    color: $deep-red;
  }
}
</style>
