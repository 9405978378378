export enum SignupFlowModificationType {
  // Private
  Pep,
  AssociatedPepRelative,
  AssociatedPepCoworker,
  PepTerminated,
  AssociatedPepTerminated,
  ReferralIntro,
  SteplerIntro,
  LeadsIntro,
  FamiljekortetIntro,
  TaxableCountry,
  SustainablePortfolio,
  SustainablePortfolioChoice,
  SustainablePortfolioVerify,
  ExcludedNegativeImpact,
  MinimizedNegativeImpact,
  PersonalIdentityNumber,
  StockInformation,
  TimeToWithdrawWarning,
  ExpensesWarning,
  CurrentSavingsWarning,
  LoanedAmountWarning,
  WithdrawalIntervalInformationPrivate,
  CitizenshipDenial,
  TaxLiableDenial,
  SustainablePortfolioTiedAgent,
  UseSameAccount,
  Tink,
  Savings,
  NoIncome,

  // Company
  CompanyPrincipalForeign,
  CompanyPrincipal,
  AlternativeCompanyPrincipal,
  AuthorizedSignatoriesToSign,
  CompanyActive,
  CapitalInsuranceWarning,
  RepresentativeWarning,
  WithdrawalIntervalWarningCompany,
  PrincipalTaxableUsaWarning,
  ForbiddenCountryWarning,
  LowSolidity,
  LowLiquidity,
  LowLiquidAssets,
  OtherCompanyPrincipalForeign,
  HighDepositAmount,
  ShortTermDebt,
  PrincipalTaxableCountryWarning,
  OneManCompany,
  MultipleManCompany,
  OneManPrincipalTaxableOtherCountry,
  CompanyTaxableOtherCountry,
  PrincipalTaxableOtherCountry,
  OtherPrincipalTaxableOtherCountry,
  ChooseOneManCompany,
  OneManCompanySigning,
  MultipleManCompanySigning,
  WantsMultipleRepresentatives,
  MultipleRepresentatives,
  FortnoxMultipleManCompany,

  // KYC
  KycTaxableCountry,
  HasCompanyPrincipals,
  HasNoCompanyPrincipals,
  KycWarnChangeSustinabilityPreference,
  KycKeepSustainabilityPreference,
  KycTaxLiableUsa,

  UseOtherAccount,
  HasLoans,
  ExpensiveLoans,
  KycHasLoans,

  // Pension
  ActivePayments,
  MergePensions,
  SoleOwner,
  ShowInsuranceHolderEmail,
  PayoutWarning,
  CompleteInfo,
  OrganizationNumber,

  // New signup
  TooYoung,
  SwedishResidentError,
  NoAddressFound
}
