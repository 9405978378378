<script setup lang="ts">
import SectionedSideDialog from "@/components/dialog/sectioned-side-dialog.vue";
import { InfoSectionMultiple } from "@/types";

const modelValue = defineModel<boolean>({ required: true });

const infoSections: InfoSectionMultiple[] = [
  {
    heading: "Sigmastocks kategorier och EU:s hållbarhetsindikatorer (PAI:s)",
    subSections: [
      {
        subHeading: "Koldioxid & utsläpp",
        texts: [
          "Handlar om bolagens koldioxidutsläpp, utsläpp av andra gaser, flytande avfall eller spillprodukter. Både utsläpp från bolagets verksamhet och från produkterna tas i beräkning.",
          "· PAI 1 - Utsläpp av växthusgaser",
          "· PAI 2 - Koldioxidavtryck",
          "· PAI 3 - Utsläpp av växthusgaser i relation till storlek på företagets intäkter",
          "· PAI 4 - Exponering mot fossila bränslen",
          "· PAI 8 - Utsläpp i vatten",
          "· PAI 9 - Farligt avfall och radioaktivt avfall"
        ]
      },
      {
        subHeading: "Biologisk mångfald",
        texts: [
          "Handlar om hur bolaget använder land- eller vattenområden och hur det påverkar den biologiska mångfalden i miljöerna de verkar. Både bolagets egen verksamhet och deras logistikkedja tas i beaktning.",
          "· PAI 7 - Verksamhet som negativt påverkar områden med känslig biologisk mångfald"
        ]
      },
      {
        subHeading: "Resurseffektivitet",
        texts: [
          "Handlar om hur effektivt bolaget använder resurser så som råvaror, material eller energi. Bolagets egen verksamhet, deras logistikkedja och deras produkter tas med i beräkning.",
          "· PAI 5 - Andel icke-förnybar energiförbrukning och energiproduktion",
          "· PAI 6 - Energiförbrukningsintensitet med stor klimatpåverkan"
        ]
      },
      {
        subHeading: "Socialt ansvar & rättigheter",
        texts: [
          "En omfattande kategori som berör hur bolaget behandlar sina anställda och andra människor det kommer i kontakt med. Det rör bl a mänskliga rättigheter och arbetsrättigheter, jämställdhet och diskriminering, anti-korruptionsarbete, samt exponering mot kontroversiella vapen.",
          "· PAI 10 - Brott mot FN:s globala överenskommelse och OECD:s riktlinjer för multinationella företag",
          "· PAI 11 - Brist på styrdokument för att granska efterlevnad av bland annat mänskliga rättigheter",
          "· PAI 12 - Ojusterade löneklyftor mellan könen",
          "· PAI 13 - Jämnare könsfördelning i styrelserna ",
          "· PAI 14 - Exponering mot kontroversiella vapen"
        ]
      },
      {
        subHeading: "Varför kan jag inte välja alla PAIs?",
        texts: [
          "Vissa PAIs har i dagsläget dålig datatäckning, dvs att få bolag har rapporterat data på det området. Om det är för få bolag som har rapporterat data kan vi inte använda området för att filtrera bort bolag då, det hade tagit bort alldeles för stor andel av alla bolag vi har att välja på."
        ]
      }
    ]
  }
];
</script>

<template>
  <SectionedSideDialog
    v-model="modelValue"
    title="Hållbarhetsfaktorer"
    :info-sections="infoSections"
  />
</template>
