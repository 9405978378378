import { SignupNavigationMutation } from "@/store/signup-navigation/mutations";
import { SignupMutation } from "@/store/signup/mutations";
import { evida, sigmastocks } from "@/styles/colors";
import { Router } from "vue-router";

export function useQuestion(store: any, router: Router) {
  const progress = store.getters.questionProgress(router.currentRoute.value.path);

  store.commit(
    SignupNavigationMutation.setQuestionProgress,
    store.getters.questionProgress(router.currentRoute.value.path)
  );
  store.commit(SignupMutation.showProgressBar, true);
  sessionStorage.setItem(
    store.state.signupNavigationStore.currentPathKey,
    router.currentRoute.value.path
  );
  if (
    (router.currentRoute.value.path.includes("pension") && progress.partNumber < 5) ||
    (router.currentRoute.value.path.includes("company-signup") && progress.partNumber < 5)
  ) {
    store.commit(SignupMutation.setPrimaryColor, evida.accent);
    store.commit(SignupMutation.setColors, evida);
  } else {
    store.commit(SignupMutation.setPrimaryColor, sigmastocks.accent);
    store.commit(SignupMutation.setColors, sigmastocks);
  }
}
