<template>
  <single-choice-template
    class="previous-experience"
    :alternatives="questionAlternatives"
    :two-columns="false"
    @click="select"
  >
    <question-text-section
      progress
      :heading="heading"
    />
  </single-choice-template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  props: { heading: { type: String, required: true } },
  data: () => ({
    showAnswer: false
  }),
  computed: {
    questionAlternatives() {
      return [
        {
          text: this.$t("common-signup.questions.risk.previous-experience.noCorrect"),
          key: "noCorrect"
        },
        {
          text: this.$t("yesCorrect"),
          key: "yesCorrect"
        }
      ];
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    select(key: string): void {
      if (key) {
        this.goToNext();
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.previous-experience {
  height: 100%;
  width: 100%;
}
</style>
