import Vue from "vue";
import { RouteRecordRaw, START_LOCATION } from "vue-router";

import { StagingUser } from "@/types";
import SignRedirector from "@/components/sign-redirector.vue";
import store from "@/store";
import { UserMutation } from "@/store/user/mutations";
import CompanySignupRoutes from "./company-signup-routes";
import CompanyKycRoutes from "./company-kyc-routes";
import AppRoutes from "./app-routes";
import Login from "../views/login.vue";
import Dev from "../views/dev.vue";
import KycRoutes from "./kyc-routes";
import EsgRoutes from "./esg-routes";
import AdminRoutes from "./admin-routes";
import PensionRoutes from "./pension-routes";
import CreatePortfolioRoutes from "./create-portfolio-routes";
import SignupRoutes from "./signup-routes";
import FormRoutes from "./form-routes";
import LocaleComponent from "./locale.vue";
import PageNotFound from "../views/error-pages/page-not-found.vue";
import StagingLogin from "../views/staging-login.vue";

import { createRouter, createWebHistory } from "vue-router";

const authRoutes = [
  { path: "", name: "login", component: Login },
  { path: "dev", component: Dev }
] as RouteRecordRaw[];
const signRoutes = [
  {
    path: "signComplete",
    component: SignRedirector
  }
] as RouteRecordRaw[];

const allRoutes = [
  ...authRoutes,
  ...AppRoutes,
  ...authRoutes,
  ...AdminRoutes,
  ...KycRoutes,
  ...CompanyKycRoutes,
  ...CompanySignupRoutes,
  ...EsgRoutes,
  ...PensionRoutes,
  ...CreatePortfolioRoutes,
  ...SignupRoutes,
  ...FormRoutes,
  ...signRoutes,
  ...(import.meta.env.VITE_ENV === "staging" || import.meta.env.VITE_ENV === "production"
    ? [
        {
          name: "staging-login",
          path: "staging-login",
          component: StagingLogin
        }
      ]
    : []),
  {
    path: ":pathMatch(.*)*",
    component: PageNotFound
  }
] as RouteRecordRaw[];

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:locale(se|no)",
      component: LocaleComponent,
      children: allRoutes
    },
    {
      path: "/:pathMatch(.*)*",
      component: PageNotFound
    }
  ],
  // This returns a promise since there seems to be a bug with synchronous function not properly setting view pos to 0 when pressing back button
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 50);
    });
  }
});

router.beforeEach((to, from, next) => {
  if (!to.params.locale) {
    const locale = store.state.userStore.locale ?? "se";
    return next({ path: `/${locale}${to.path}`, query: to.query });
  }
  store.commit(UserMutation.setLocale, to.params.locale ?? "se");

  if (from.path.endsWith("/signup/welcome") && to.path.endsWith("/signup")) {
    return next({ name: "login" });
  }
  if (
    from.path.endsWith("/create-portfolio/introduction") &&
    to.path.endsWith("/create-portfolio")
  ) {
    window.history.go(-1);
  }
  if (
    from.path.endsWith("/signup/referral-intro") &&
    to.path.endsWith("/signup") &&
    to.query.referral
  ) {
    return next({ name: "login" });
  }
  if (import.meta.env.VITE_ENV === "staging") {
    const publicPages = [`/${store.state.userStore.locale ?? "se"}/staging-login`];
    const authRequired = !publicPages.includes(to.path);
    let loggedIn = false;
    const userCache = sessionStorage.getItem("stagingUser");
    if (userCache) {
      try {
        const stagingUser = JSON.parse(userCache) as StagingUser;
        loggedIn = !!stagingUser.authData;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    if (authRequired && !loggedIn) {
      const param = {
        name: "staging-login",
        params: { locale: store.state.userStore.locale ?? "se" },
        query: { returnUrl: to.path }
      };
      return next(param);
    }
  }

  // Using same method as staging for convenience. The current route (cio form) is just for
  // internal purposes anyway, so the naming is not critical. Use another method or specialize
  // this for production use if needed for future features.
  if (import.meta.env.VITE_ENV === "production") {
    const nonPublicPages = ["/cio-form", "/cio-form-success"];
    const authRequired = nonPublicPages.includes(to.path);
    let loggedIn = false;
    const userCache = sessionStorage.getItem("stagingUser");
    if (userCache) {
      try {
        const stagingUser = JSON.parse(userCache) as StagingUser;
        loggedIn = !!stagingUser.authData;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    if (authRequired && !loggedIn) {
      return next({
        name: "staging-login",
        query: { returnUrl: to.path }
      });
    }
  }
  return next();
});

// router.afterEach((to, from) => {
//   if (import.meta.env.VITE_ENV === "production" || import.meta.env.VITE_ENV === "staging") {
//     (window as any).dataLayer.push({
//       event: "page_view",
//       event_params: {
//         pageview_type: from === START_LOCATION ? "hard" : "dynamic",
//         document_href: window.location.href
//       }
//     });
//   }
// });

router.onError((error, to) => {
  if (
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Importing a module script failed")
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      (window as any).location = to.fullPath;
    }
  }
});

export default router;
