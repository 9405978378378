<template>
  <side-dialog
    v-model="showDialog"
    :title="$t('rename')"
  >
    <v-form
      ref="form"
      class="rename"
      @submit.prevent="validateAndSave"
    >
      <v-text-field
        v-model="editedPortfolioName"
        :label="$t('name')"
        variant="underlined"
        :rules="[rules.required, rules.maximumCharacters]"
        :hint="$t('portfolio.overview.rename-dialog.maximumCharacters')"
        persistent-hint
        validate-on="blur"
        class="account-form__value"
        counter
        maxlength="20"
      />
      <common-button
        data-jest="button"
        class="rename__button"
        @click="validateAndSave"
      >
        {{ $t("save") }}
      </common-button>
    </v-form>
  </side-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { PortfolioAction } from "@/store/portfolio/actions";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    SideDialog,
    CommonButton
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    editedPortfolioName: ""
  }),
  computed: {
    ...mapGetters(["portfolioName"]),
    rules(): any {
      return {
        required: (name: string) => !!name || this.$t("required"),
        maximumCharacters: (name: string) =>
          name.length <= 20 || this.$t("portfolio.overview.rename-dialog.maximumCharacters")
      };
    },
    showDialog: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        if (!value) {
          // Reset the editing if the dialog is closed.
          this.editedPortfolioName = this.portfolioName;
        }
        this.$emit("update:model-value", value);
      }
    }
  },
  watch: {
    portfolioName: {
      immediate: true,
      handler(newName: string | undefined, oldName: string | undefined) {
        this.editedPortfolioName = newName || "";
      }
    }
  },
  methods: {
    async validateAndSave() {
      if ((await (this.$refs.form as any).validate()).valid) {
        if (this.portfolioName !== this.editedPortfolioName) {
          this.$store.dispatch(PortfolioAction.renamePortfolio, this.editedPortfolioName);
        }
        this.showDialog = false;
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.rename {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 2.25rem 3rem 2.25rem;
  @include medium-up {
    height: auto;
  }
  &__button {
    margin: 2rem auto;
    border-radius: 0.5rem;
    font-weight: 500;
    font-family: $heading-font-family;
    text-transform: none;
    font-size: 1.125rem;
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16);
  }
}
</style>
