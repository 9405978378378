<template>
  <div class="swedish-tax-residence-denial">
    <single-choice-template
      :alternatives="informationAlternatives"
      @click="selectInformation"
    >
      <information-text-section
        :heading="$t('denialHeading')"
        :body="$t('create-portfolio.questions.aml.swedish-tax-residence-denial.body')"
      >
        <icon />
      </information-text-section>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Icon from "@/assets/images/signup/user-lock-light.svg";
import { SingleChoice } from "@/types/signup";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    InformationTextSection,
    Icon
  },
  computed: {
    informationAlternatives(): SingleChoice[] {
      return [
        {
          text: this.$t("quit"),
          key: "quit"
        }
      ];
    }
  },
  methods: {
    selectInformation(): void {
      this.$router.push({ name: "login" });
    }
  }
});
</script>

<style lang="scss">
.swedish-tax-residence-denial {
  height: 100%;
  width: 100%;
  &__body {
    max-width: 21.25rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @include medium-up {
      max-width: 44.5rem;
    }
    &:first-of-type {
      margin-top: 1rem;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
