<template>
  <loaned-amount @next="next" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import LoanedAmount from "@/views/common-signup/questions/economy/loaned-amount.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupFlowModificationType } from "@/config/signup-modification";

export default defineComponent({
  components: { LoanedAmount },
  computed: {
    ...mapGetters(["solidity"])
  },
  methods: {
    async next() {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.LoanedAmountWarning,
        include: this.solidity < 0.15
      });
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
