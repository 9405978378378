import { PerformanceResponse } from "@/clients";

export function calculateTotalAbsoluteDevelopment(
  portfolioPerformance: PerformanceResponse
): number | undefined {
  if (
    portfolioPerformance &&
    portfolioPerformance.returnsPerformanceInAmount &&
    portfolioPerformance.returnsPerformanceInAmount.length > 0
  ) {
    return portfolioPerformance.returnsPerformanceInAmount.reduce(
      (accum, obj) => accum + Number(obj.value || 0),
      0
    );
  }

  return undefined;
}

export function calculateTotalDevelopmentPercentage(
  portfolioPerformance: PerformanceResponse
): number | undefined {
  if (
    portfolioPerformance &&
    portfolioPerformance.totalFraction &&
    portfolioPerformance.totalFraction.length > 0
  ) {
    return (
      (portfolioPerformance.totalFraction[portfolioPerformance.totalFraction.length - 1].value ||
        0) * 100
    );
  }

  return undefined;
}

export function calculateTotalPortfolioValue(
  portfolioPerformance: PerformanceResponse
): number | undefined {
  if (
    portfolioPerformance &&
    portfolioPerformance.totalAbsolute &&
    portfolioPerformance.totalAbsolute.length > 0
  ) {
    return portfolioPerformance.totalAbsolute.slice(-1)[0].value;
  }

  return undefined;
}

export function formatTotalDevelopmentPercentage(
  portfolioPerformance: PerformanceResponse
): string {
  const developmentPercentage = calculateTotalDevelopmentPercentage(portfolioPerformance);
  if (developmentPercentage === undefined) {
    return "-";
  }
  const sign = developmentPercentage >= 0 ? "+" : "-";
  const localeStringOptions = {
    maximumFractionDigits: 2
  };
  const formatter = Intl.NumberFormat("sv-SE", localeStringOptions);
  const formattedNumber = formatter.format(Math.abs(developmentPercentage));

  return `${sign} ${formattedNumber}`;
}

export function formatTotalPortfolioValue(portfolioPerformance: PerformanceResponse): string {
  const totalValue = calculateTotalPortfolioValue(portfolioPerformance);
  if (totalValue === undefined) {
    return "-";
  }
  const formatter = Intl.NumberFormat("sv-SE");
  const formattedNumber = formatter.format(Math.round(Math.abs(totalValue)));

  return `${formattedNumber}`;
}

export function formatTotalAbsoluteDevelopment(portfolioPerformance: PerformanceResponse) {
  const totalAbsoluteDevelopment = calculateTotalAbsoluteDevelopment(portfolioPerformance);
  if (totalAbsoluteDevelopment === undefined) {
    return "-";
  }
  const sign = totalAbsoluteDevelopment >= 0 ? "+" : "-";
  const formatter = Intl.NumberFormat("sv-SE");
  const formattedNumber = formatter.format(Math.round(Math.abs(totalAbsoluteDevelopment)));

  return `${sign} ${formattedNumber}`;
}
