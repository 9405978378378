<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="20000"
    color="white"
  >
    <div>
      <p class="prompt__text">
        {{ $t("components.ios-install-pwa.body1") }}
        <img
          class="prompt__icon"
          width="16"
          src="../assets/icons/arrow-square-up-edited.svg"
        >
        {{ $t("components.ios-install-pwa.body2") }}
      </p>
    </div>
    <v-btn
      class="prompt__button"
      dark
      color="black"
      variant="text"
      @click="close"
    >
      {{ $t("components.ios-install-pwa.notNow") }}
    </v-btn>
  </v-snackbar>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DateTime } from "luxon";
import { LATEST_IOS_PWA_PROMPT } from "@/config/general";
import { isSafari } from "@/clients/bowser";

export default defineComponent({
  data: () => ({
    snackbar: false,
  }),
  created() {
    const isHandheldIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    // the standalone prop only exists in iOS browsers and we only want to show this snackbar if its iOS and not standalone app
    if (
      (import.meta.env.NODE_ENV === "production" || import.meta.env.VITE_ENV === "staging")
      && isHandheldIos
      && isSafari()
      && (window.navigator as any).standalone === false
    ) {
      const latest = localStorage.getItem(LATEST_IOS_PWA_PROMPT);
      if (latest) {
        // Only prompt once every two weeks
        const date = DateTime.fromJSDate(JSON.parse(latest));
        if (date < DateTime.local().minus({ days: 14 })) {
          this.snackbar = true;
        }
      } else {
        this.snackbar = true;
      }
    }
  },
  methods: {
    close() {
      this.snackbar = false;
      localStorage.setItem(LATEST_IOS_PWA_PROMPT, JSON.stringify(DateTime.local()));
    },
  },
});
</script>

<style lang="scss" scoped>
.prompt {
  &__text {
    color: $soft-blue;
    font-family: $heading-font-family;
    margin-bottom: 0.225rem !important;
  }
  &__icon {
    margin: 0 0.5rem;
  }
  &__button {
    font-family: $heading-font-family;
    text-transform: none;
    padding: 0 !important;
  }
}
</style>
