import type { MutationTree } from "vuex";
import type { Economy } from "../../types/user";
import type { UserState } from "./types";
import { setJwtToken } from "@/clients/authentication";
import type {
  AffiliateCampaign,
  ApplicationUser,
  ArchivedPdfs,
  Banner,
  CompanyRepresentativeInfo,
  KnowYourCustomerAnswers,
  LoginStatus,
  Message,
  NotificationSettings,
  PdfListData,
  PortfolioInsuranceNumber,
  PortfolioPdfType,
  PrepareDocumentSignResponse,
  StaticAgreements,
  TiedAgent,
} from "@/clients";
import {
  CompanyKnowYourCustomerAnswers,
  CompanyPrincipal,
  LegalEntity,
  TiedAgentPortfolioTheme,
} from "@/clients";
import { identifyUser } from "@/clients/segment";

export const UserMutation = {
  setCurrentUser: "setCurrentUser",
  setLegalEntity: "setLegalEntity",
  setNotificationSettings: "setNotificationSettings",
  setKyc: "setKyc",
  increaseUnsavedEdits: "increaseUnsavedEdits",
  decreaseUnsavedEdits: "decreaseUnsavedEdits",
  resetUnsavedEdits: "resetUnsavedEdits",
  setUserState: "setUserState",
  setUserError: "setUserError",
  setLoadKycError: "setLoadKycError",
  setLoadNotificationSettingsError: "setLoadNotificationSettingsError",
  setUpdateSettingsError: "setUpdateSettingsError",
  setToken: "setToken",
  addSnackbarMessage: "addSnackbarMessage",
  removeSnackbarMessage: "removeSnackbarMessage",
  setLoginErrorStatus: "setLoginErrorStatus",
  setMessages: "setMessages",
  setLoadingMessages: "setLoadingMessages",
  setLoadingPdf: "setLoadingPdf",
  setArchivePdfList: "setArchivePdfList",
  setStaticAgreementsList: "setStaticAgreementsList",
  setUpdatedLegalEntity: "setUpdatedLegalEntity",
  editContactInformation: "editContactInformation",
  resetContactInformation: "resetContactInformation",
  editEconomy: "editEconomy",
  resetEconomy: "resetEconomy",
  setTaxBasePdfs: "setTaxBasePdfs",
  setManualPdfs: "setManualPdfs",
  updateInfoBanners: "updateInfoBanners",
  setInfoBanners: "setInfoBanners",
  setMissingOrErrorAgreements: "setMissingOrErrorAgreements",
  appendBanner: "appendBanner",
  setIsLockedBecauseOfEconomy: "setIsLockedBecauseOfEconomy",
  editKyc: "editKyc",
  resetKyc: "resetKyc",
  setLoadingCurrentUser: "setLoadingCurrentUser",
  setShowReferralsDialog: "setShowReferralsDialog",
  setAgreementsToSign: "setAgreementsToSign",
  setInsuranceNumbers: "setInsuranceNumbers",
  setSafeToReloadApp: "setSafeToReloadApp",
  setCompanyRepresentatives: "setCompanyRepresentatives",
  setLegalEntitySustainability: "setLegalEntitySustainability",
  setAffiliateCampaign: "setAffiliateCampaign",
  setEditedLegalEntity: "setEditedLegalEntity",
  setShowGetStarted: "setShowGetStarted",
  setLocale: "setLocale",
  setPortfolioTheme: "setPortfolioTheme",
  setTiedAgent: "setTiedAgent",
  setPrimaryColor: "setPrimaryColor",
  setEditedCompanyKnowYourCustomerAnswers: "setEditedCompanyKnowYourCustomerAnswers",
  setEditedCompanyPrincipal: "setEditedCompanyPrincipal",
  setHubspotChatToken: "setHubspotChatToken",
};

export const mutations: MutationTree<UserState> = {
  [UserMutation.setMissingOrErrorAgreements](
    state,
    errorAgreements: PortfolioPdfType[] | undefined,
  ) {
    state.missingOrErrorAgreements = errorAgreements;
  },
  [UserMutation.updateInfoBanners](state, infoBanners: Banner[]) {
    if (state.infoBanners) {
      state.infoBanners = state.infoBanners.every(i => i.id < 0)
        ? [...state.infoBanners, ...infoBanners]
        : infoBanners;
    } else {
      state.infoBanners = infoBanners;
    }
  },
  [UserMutation.setInfoBanners](state, infoBanners: Banner[]) {
    state.infoBanners = infoBanners;
  },
  [UserMutation.appendBanner](state, banner: Banner) {
    if (!state.infoBanners) {
      state.infoBanners = [banner];
    } else if (state.infoBanners.find(i => i.id === banner.id) === undefined) {
      state.infoBanners.push(banner);
    }
  },
  [UserMutation.setCurrentUser](state, user: ApplicationUser) {
    state.currentUser = user;
  },
  [UserMutation.setLegalEntity](state, legalEntity: LegalEntity) {
    if (state.currentUser) {
      // Set currentLegalEntity this way because for some reason the isCompany getter sometimes doesn't
      // trigger. We don't know what causes this but this workaround seems to work.
      state.currentLegalEntity = legalEntity;
      state.editedLegalEntity = legalEntity;
      if (legalEntity.companyKnowYourCustomerAnswers) {
        state.editedCompanyKnowYourCustomerAnswers = new CompanyKnowYourCustomerAnswers({
          ...legalEntity.companyKnowYourCustomerAnswers,
          companyPrincipals: legalEntity.companyKnowYourCustomerAnswers.companyPrincipals.map(
            n => new CompanyPrincipal({ ...n }),
          ),
        });
      }

      state.currentUser.legalEntityUsers.forEach((x) => {
        if (x.legalEntity?.brickId === legalEntity.brickId) {
          x.legalEntity = legalEntity;
        }
      });

      identifyUser(
        state.currentLegalEntity.trackingGuid as string,
        state.currentLegalEntity.id as number,
        state.currentLegalEntity as LegalEntity,
      );
    }
  },
  [UserMutation.setNotificationSettings](state, settings: NotificationSettings) {
    state.notificationSettings = settings;
  },
  [UserMutation.setKyc](state, kyc: KnowYourCustomerAnswers) {
    if (state.currentLegalEntity) {
      state.currentLegalEntity.knowYourCustomerAnswers = kyc;
    }
  },
  [UserMutation.increaseUnsavedEdits](state) {
    state.unsavedProfileEdits += 1;
  },
  [UserMutation.decreaseUnsavedEdits](state) {
    state.unsavedProfileEdits -= 1;
  },
  [UserMutation.resetUnsavedEdits](state) {
    state.unsavedProfileEdits = 0;
  },
  [UserMutation.setUserState](state, stateChange: UserState) {
    Object.assign(state, stateChange);
  },
  [UserMutation.setUserError](state, error: boolean) {
    state.errorStates.loadUserError = error;
  },
  [UserMutation.setLoadKycError](state, error: boolean) {
    state.errorStates.loadKycError = error;
  },
  [UserMutation.setLoadNotificationSettingsError](state, error: boolean) {
    state.errorStates.loadNotificationSettingsError = error;
  },
  [UserMutation.setToken](state, token: string | undefined) {
    setJwtToken(token);
    state.token = token;
  },
  [UserMutation.addSnackbarMessage](state, payload) {
    const value = { text: payload.text, show: true };
    state.snackBarMessages[payload.key] = value;
  },
  [UserMutation.removeSnackbarMessage](state, key: string) {
    delete state.snackBarMessages[key];
  },
  [UserMutation.setLoginErrorStatus](state, value: LoginStatus) {
    state.loginErrorStatus = value;
  },
  [UserMutation.setMessages](state, messages: Message[]) {
    state.messages = messages;
  },
  [UserMutation.setLoadingMessages](state, value: boolean) {
    state.loadingMessages = value;
  },
  [UserMutation.setLoadingPdf](state, { pdfId, loading }) {
    const { loadingPdf } = state;
    const loadingPdfCopy = loadingPdf.slice();
    loadingPdfCopy[pdfId] = loading;
    state.loadingPdf = loadingPdfCopy;
  },
  [UserMutation.setArchivePdfList](state, archivePdfList: ArchivedPdfs[]) {
    state.archivePdfList = archivePdfList;
  },
  [UserMutation.setStaticAgreementsList](state, staticAgreements: StaticAgreements) {
    state.staticAgreements = staticAgreements;
  },
  [UserMutation.editContactInformation](state, { email, phoneNumber }) {
    if (!state.editedLegalEntity) {
      throw new Error("No edited legal entity in state");
    }
    const editedLegalEntity = new LegalEntity({ ...state.editedLegalEntity, email, phoneNumber });
    state.editedLegalEntity = editedLegalEntity;
  },
  [UserMutation.resetContactInformation](state) {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.editedLegalEntity) {
      throw new Error("No edited legal entity in state");
    }
    if (!state.currentUser) {
      throw new Error("No user");
    }
    const { phoneNumber, email } = state.currentLegalEntity;

    const editedLegalEntity = new LegalEntity({ ...state.editedLegalEntity, email, phoneNumber });
    state.editedLegalEntity = editedLegalEntity;
  },
  [UserMutation.editEconomy](state, economy: Economy) {
    if (!state.editedLegalEntity) {
      throw new Error("No edited legal entity in state");
    }

    const editedLegalEntity = new LegalEntity({ ...state.editedLegalEntity, ...economy });
    state.editedLegalEntity = editedLegalEntity;
  },
  [UserMutation.resetEconomy](state) {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.editedLegalEntity) {
      throw new Error("No edited legal entity in state");
    }
    if (!state.currentUser) {
      throw new Error("No user");
    }
    const economy = {
      monthlySalary: state.currentLegalEntity.monthlySalary,
      monthlyRemaining: state.currentLegalEntity.monthlyRemaining,
      totalSavings: state.currentLegalEntity.totalSavings,
      boundCapital: state.currentLegalEntity.boundCapital,
      totalLoans: state.currentLegalEntity.totalLoans,
    };

    const editedLegalEntity = new LegalEntity({ ...state.editedLegalEntity, ...economy });
    state.editedLegalEntity = editedLegalEntity;
  },
  [UserMutation.setUpdateSettingsError](state, value: boolean) {
    state.errorStates.updateSettingsError = value;
  },
  [UserMutation.setTaxBasePdfs](state, taxBasePdfs: PdfListData[]) {
    state.taxBasePdfs = taxBasePdfs;
  },
  [UserMutation.setManualPdfs](state, manualPdfs: PdfListData[]) {
    state.manualPdfs = manualPdfs;
  },
  [UserMutation.setIsLockedBecauseOfEconomy](state, isLockedBecauseOfEconomy) {
    if (state.currentLegalEntity) {
      state.currentLegalEntity.isLockedBecauseOfEconomy = isLockedBecauseOfEconomy;
    }
    if (state.editedLegalEntity) {
      state.editedLegalEntity.isLockedBecauseOfEconomy = isLockedBecauseOfEconomy;
    }
  },
  [UserMutation.editKyc](state, kyc: KnowYourCustomerAnswers) {
    if (!state.editedLegalEntity) {
      throw new Error("No edited legal entity in state");
    }
    if (!state.editedLegalEntity.knowYourCustomerAnswers) {
      throw new Error("No edited knowYourCustomerAnswers in state");
    }
    const editedLegalEntity = new LegalEntity({
      ...state.editedLegalEntity,
      knowYourCustomerAnswers: kyc,
    });
    state.editedLegalEntity = editedLegalEntity;
  },
  [UserMutation.resetKyc](state) {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.editedLegalEntity) {
      throw new Error("No edited legal entity in state");
    }

    const editedLegalEntity = new LegalEntity({
      ...state.editedLegalEntity,
      knowYourCustomerAnswers: state.currentLegalEntity.knowYourCustomerAnswers,
    });
    state.editedLegalEntity = editedLegalEntity;
  },
  [UserMutation.setLoadingCurrentUser](state, loading: boolean) {
    state.loadingCurrentUser = loading;
  },
  [UserMutation.setShowReferralsDialog](state, value: boolean) {
    state.showReferralsDialog = value;
  },
  [UserMutation.setAgreementsToSign](state, val: PrepareDocumentSignResponse) {
    state.agreementsToSign = val;
  },
  [UserMutation.setInsuranceNumbers](state, value: PortfolioInsuranceNumber[]) {
    state.insuranceNumbers = value;
  },
  [UserMutation.setSafeToReloadApp](state, value: boolean) {
    state.safeToReloadApp = value;
  },
  [UserMutation.setCompanyRepresentatives](state, value: CompanyRepresentativeInfo[]) {
    state.companyRepresentatives = value;
  },
  [UserMutation.setLegalEntitySustainability](
    state,
    payload: {
      takeSustainabilityIntoAccount: boolean;
      minimizeNegativeImpact: boolean;
      excludeNegativeImpact: boolean;
    },
  ) {
    if (!state.editedLegalEntity) {
      throw new Error("No edited legal entity in state");
    }
    state.editedLegalEntity.takeSustainabilityImpactIntoAccount
      = payload.takeSustainabilityIntoAccount;
    state.editedLegalEntity.minimizeNegativeSustainabilityImpact = payload.minimizeNegativeImpact;
    state.editedLegalEntity.excludeNegativeSustainabilityImpact = payload.excludeNegativeImpact;
  },
  [UserMutation.setAffiliateCampaign](state, value: AffiliateCampaign) {
    if (state.currentLegalEntity) {
      state.currentLegalEntity.affiliateCampaign = value;
    }
  },
  [UserMutation.setEditedLegalEntity](state, value: LegalEntity) {
    state.editedLegalEntity = value;
  },
  [UserMutation.setEditedCompanyKnowYourCustomerAnswers](state, value: any) {
    if (!state.editedLegalEntity) {
      throw new Error("No editedLegalEntity in state");
    }
    state.editedCompanyKnowYourCustomerAnswers = new CompanyKnowYourCustomerAnswers({ ...value });
  },
  [UserMutation.setEditedCompanyPrincipal](
    state,
    payload: { index: number; value: CompanyPrincipal },
  ) {
    if (!state.editedCompanyKnowYourCustomerAnswers?.companyPrincipals) {
      throw new Error("No editedLegalEntity in state");
    }
    state.editedCompanyKnowYourCustomerAnswers.companyPrincipals[payload.index] = payload.value;
  },
  [UserMutation.setLocale](state, value: string) {
    state.locale = value;
  },
  [UserMutation.setTiedAgent](state, value: TiedAgent) {
    state.tiedAgent = value;
  },
  [UserMutation.setPrimaryColor](state, value: string) {
    state.primaryColor = value;
  },
  [UserMutation.setShowGetStarted](state, value: boolean) {
    state.showGetStarted = value;
  },
  [UserMutation.setHubspotChatToken](state, token: string) {
    state.hubspotChatToken = token;
  },
};
