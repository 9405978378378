import { CustomWindow, GtmEvent } from "@/types";
import { Lead, LegalEntity } from ".";

declare const window: CustomWindow;

export function identifyUser(trackingGuid: string, userId: number, legalEntity: LegalEntity): void {
  if (window.analytics)
    window.analytics.identify(trackingGuid, {
      id: userId,
      email: legalEntity.email,
      name: `${legalEntity.firstName} ${legalEntity.lastName}`,
      phone: legalEntity.phoneNumber
    });
}

export function identifyLead(lead: Lead): void {
  if (window.analytics)
    window.analytics.identify(lead.trackingGuid, {
      email: lead.email,
      phone: lead.phoneNumber
    });
}

export function trackPage(name: string): void {
  if (window.analytics) window.analytics.page(name);
}

export function trackEvent(event: string, options: Object = {}, asBeacon: boolean = false): void {
  if (import.meta.env.VITE_ENV === "production") {
    if (asBeacon) {
      options = { ...options, transport_type: "beacon" };
    }
    if (window.gtag) {
      window.gtag("event", event, options);
    }
    if (window.analytics) window.analytics.track(event, options);
  }
}

export function trackEventGtm(event: string, eventParams: any = {}): void {
  if (import.meta.env.VITE_ENV === "production" || import.meta.env.VITE_ENV === "staging") {
    if (window.dataLayer) {
      window.dataLayer.push({
        event,
        event_params: eventParams
      });
    }
  }
}

export function trackEventOnlyGa4(
  event: string,
  options: Object = {},
  asBeacon: boolean = false
): void {
  if (import.meta.env.VITE_ENV === "production") {
    if (asBeacon) {
      options = { ...options, transport_type: "beacon" };
    }
    if (window.gtag) window.gtag("event", event, options);
  }
}
