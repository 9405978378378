<template>
  <div class="login-se">
    <template v-if="!loading">
      <div class="login-se__body">
        <span class="login-se__body-text"> {{ $t("login") }}</span>
        <BankIdAuthInput
          :button-color="buttonColor"
          @login="login"
        />
      </div>
      <div class="login-se__footer">
        <span class="login-se__footer-text">
          {{ $t("login-se.createAccount") }}
        </span>
        <a
          class="login-se__footer-link"
          @click="goToSignup"
        >
          {{ $t("signupLink") }}
        </a>
      </div>
    </template>

    <div v-else>
      <BankId
        is-login
        @complete="authComplete"
        @canceled="authCancel"
        @failed="authFailed"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { softBlue } from "@/styles/colors";
import { AuthenticationStatus } from "@/clients";
import { BankIdAction } from "@/store/bankid/actions";
import { StartMode } from "@/store/bankid/types";
import BankId from "@/components/bank-id.vue";
import { UserMutation } from "@/store/user/mutations";
import BankIdAuthInput from "@/components/bank-id-auth-input.vue";

export default defineComponent({
  components: { BankId, BankIdAuthInput },
  props: {
    buttonColor: {
      type: String,
      default: undefined,
    },
  },
  emits: ["authComplete", "authCancel", "authFailed"],
  data() {
    return {
      softBlue,
      loading: false,
      authFailure: null as string | null,
      StartMode,
    };
  },
  head() {
    return {
      title: this.$t("login"),
    };
  },
  computed: {
    isBankIdDev(): boolean {
      return import.meta.env.VITE_ENV !== "production";
    },
  },
  methods: {
    async login({
      startMode,
      personalIdentityNumber,
    }: {
      startMode: StartMode;
      personalIdentityNumber: string;
    }) {
      this.loading = true;
      this.authFailure = null;
      try {
        await this.$store.dispatch(BankIdAction.authenticateAndLogin, {
          personalIdentityNumber: this.isBankIdDev
            ? personalIdentityNumber
            : null,
          startMode,
        });
      } catch (error) {
        this.loading = false;
        const status = this.$store.state.bankIdStore.authenticationStatus;
        if (status === AuthenticationStatus.PersonalIdentityNumberError) {
          this.authFailure = this.$t("authFailedPersonalNumber");
        } else {
          this.authFailure = this.$t("authFailed");
        }
      }
    },
    async cancel(e: any) {
      e.preventDefault();
      this.$store.dispatch(BankIdAction.cancelAuth);
    },
    async authComplete() {
      this.$emit("authComplete");
    },
    authCancel() {
      this.loading = false;
      this.$emit("authCancel");
    },
    authFailed() {
      this.loading = false;
      this.$emit("authFailed");
    },
    resetComponent() {
      this.$store.commit(UserMutation.setLoginErrorStatus, undefined);
      this.authCancel();
    },
    goToSignup(): void {
      this.$router.push({ name: "signup" });
    },
  },
});
</script>

<style lang="scss" scoped>
.login-se {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  justify-items: center;
  width: 100%;

  @include small-up {
    align-items: center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding-top: 3.25rem;
  }

  &__body-text {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: rgba($nearly-black, 0.8);
    padding-bottom: 1.5rem;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2rem;
    text-align: center;

    @include small-up {
      padding-bottom: 8rem;
    }
  }

  &__footer-text {
    margin: auto;
    max-width: 18rem;
  }

  &__footer-link {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
