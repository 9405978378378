<template>
  <div class="occupation">
    <single-choice-template
      v-if="$vuetify.display.mdAndUp"
      :alternatives="desktopItems"
      @click="select"
    >
      <question-text-section
        progress
        :heading="$t('create-portfolio.questions.aml.occupation.heading')"
      />
    </single-choice-template>
    <single-choice-template
      v-else
      :alternatives="navigationAlternatives"
      :disabled="occupation === undefined"
      @click="goToNext"
    >
      <question-text-section
        progress
        :heading="$t('create-portfolio.questions.aml.occupation.heading')"
      />
      <div class="occupation__selector-wrapper">
        <v-select
          ref="occupationSelect"
          v-model="occupation"
          :items="mobileItems"
          :label="$t('create-portfolio.questions.aml.occupation.selectOccupation')"
          variant="solo"
          menu-icon="fas fa-chevron-down"
          :bg-color="buttonColor"
          data-cy="selectOccupation"
          @update:model-value="($refs as any).occupationSelect.blur()"
        />
      </div>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { Occupation } from "@/clients";
import { Colors, softBlue } from "@/styles/colors";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import { SingleChoice } from "@/types/signup";
import { SignupState } from "@/store/signup/types";

interface VSelectItem {
  title: string;
  value: number;
}

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  data: () => ({
    softBlue
  }),
  computed: {
    colors(): Colors | undefined {
      const { colors } = this.$store.state.signupStore as SignupState;
      return colors || undefined;
    },
    buttonColor(): string | undefined {
      if (this.colors) {
        return this.colors.text;
      }
      return this.$store.state.signupStore.tiedAgent?.color ?? softBlue;
    },
    navigationAlternatives() {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    },
    mobileItems(): VSelectItem[] {
      return Object.keys(Occupation)
        .filter((k) => typeof Occupation[k as any] === "number")
        .map((x: string, index: number) => ({
          title: this.$t(`occupations.${x}`),
          value: index,
          key: index
        }));
    },
    desktopItems(): SingleChoice[] {
      return Object.keys(Occupation)
        .filter((k) => typeof Occupation[k as any] === "number")
        .map(
          (x: string, index: number) =>
            ({
              text: this.$t(`occupations.${x}`),
              key: index
            }) as unknown as SingleChoice
        );
    },
    occupation: {
      get(): string {
        return this.$store.state.signupStore.occupation;
      },
      set(value: Occupation) {
        this.$store.commit(SignupMutation.setOccupation, value);
      }
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    select(value: Occupation): void {
      this.$store.commit(SignupMutation.setOccupation, value);
      this.goToNext();
    }
  }
});
</script>

<style
  lang="scss"
  scoped
>
.occupation {
  width: 100%;
  height: 100%;

  &__selector-menu {
    border-radius: 0.5rem !important;
    box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.14) !important;
    font-family: $heading-font-family;
  }

  &__selector-wrapper {
    width: 21.75rem;
    max-width: 100%;
    margin: 0 auto;
    padding: 6vh 0;
    font-family: $heading-font-family;

    >.v-input {
      font-size: 1.125rem;
      border-radius: 0.5rem;

      :deep(.v-input__slot:hover) {
        background-color: #90a8b0 !important;
      }
    }

    .v-input--is-focused {
      :deep(.v-input__slot) {
        box-shadow:
          0 0 0.0625rem 0.0625rem $aqua,
          0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%) !important;
      }
    }

    .v-icon {
      font-size: 1rem;
    }
  }
}
</style>
