import { RouteRecordRaw } from "vue-router";
import { isLoggedIn } from "./common";
import LoggedInWrapper from "@/components/logged-in-wrapper.vue";
import Portfolio from "../views/portfolio/portfolio.vue";
import PortfolioSettings from "../views/portfolio-settings/portfolio-settings.vue";
import Overview from "../views/overview/overview.vue";
import PageNotFound from "../views/error-pages/page-not-found.vue";
import Profile from "../views/profile/profile.vue";
import CompanyProfile from "../views/company-profile/company-profile.vue";
import Transactions from "../views/transactions/transactions.vue";
import Messages from "../views/messages/messages.vue";
import ChooseLegalEntity from "../views/choose-legal-entity.vue";

export default [
  {
    path: "choose-legal-entity",
    name: "choose-legal-entity",
    component: ChooseLegalEntity,
    beforeEnter: isLoggedIn
  },
  {
    path: "portfolio/:id",
    component: LoggedInWrapper,
    beforeEnter: isLoggedIn,
    children: [
      {
        path: "",
        name: "portfolio",
        component: Portfolio
      },
      {
        path: "settings",
        name: "portfolio-settings",
        component: PortfolioSettings
      },
      {
        path: "transactions",
        name: "transactions",
        component: Transactions
      }
    ]
  },
  {
    path: "overview",
    component: LoggedInWrapper,
    beforeEnter: isLoggedIn,
    children: [
      {
        path: "",
        component: Overview,
        name: "overview"
      }
    ]
  },
  {
    path: "profile",
    component: LoggedInWrapper,
    beforeEnter: isLoggedIn,
    children: [
      {
        path: "",
        component: Profile,
        name: "profile"
      }
    ]
  },
  {
    path: "company-profile",
    component: LoggedInWrapper,
    beforeEnter: isLoggedIn,
    children: [
      {
        path: "",
        component: CompanyProfile,
        name: "company-profile"
      }
    ]
  },
  {
    path: "messages",
    component: LoggedInWrapper,
    beforeEnter: isLoggedIn,
    children: [
      {
        path: "",
        component: Messages,
        name: "messages"
      }
    ]
  }
] as RouteRecordRaw[];
