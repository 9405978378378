<template>
  <side-dialog
    :model-value="modelValue"
    :title="
      isShare === undefined
        ? $t('portfolio.holdings.detailed.side-dialog.holding_unknown_title')
        : isShare
        ? $t('portfolio.holdings.detailed.side-dialog.holding_title')
        : $t('portfolio.holdings.detailed.side-dialog.holding_etf_title')
    "
    data-jest="dialog"
    @update:model-value="(value) => $emit('update:model-value', value)"
  >
    <div class="dialog">
      <detailed-holding
        v-if="selectedHolding"
        :selected-holding="selectedHolding"
      />
      <common-button
        class="dialog__button"
        data-jest="button"
        @click="() => $emit('update:model-value', false)"
      >
        {{ $t("ok") }}
      </common-button>
    </div>
  </side-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { InstrumentType } from "@/clients";
import DetailedHolding from "./detailed-holding.vue";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    SideDialog,
    DetailedHolding,
    CommonButton
  },
  props: {
    selectedHolding: {
      type: Object,
      required: false,
      default: undefined
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isShare(): boolean | undefined {
      if (this.selectedHolding?.instrumentType === undefined) {
        return undefined;
      }
      return this.selectedHolding.instrumentType === InstrumentType.Shares;
    }
  }
});
</script>

<style lang="scss" scoped>
.dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__button {
    width: 3rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto !important;
  }
}
</style>
