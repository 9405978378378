import type { MutationTree } from "vuex";
import type {
  AuthorizedSignatory,
  CompanyPrincipal as CompanyPrincipalStore,
  CompanySignupState,
  ContactInfo,
  Economy,
} from "./types";
import type {
  CompanyEmployees,
  CompanyIndustry,
  CompanyRevenue,
  CompanySigningCombinationPerson,
  DepositFrequency,
  Market,
  RiskLevel,
  RiskWillingness,
  Role,
  Sector,
  StrategyFocus,
  TiedAgent,
  TiedAgentPortfolioTheme,
  TimeToWithdraw,
  WithdrawalFrequency,
  YearlyDepositAmount,
} from "@/clients";
import {
  CompanyForm,
  CompanyRepresentativeInfo,
} from "@/clients";
import type {
  CompanyBackgroundAlternative,
  CompanySavingAlternative,
} from "@/types/signup";

function getEconomyInitialState(): Economy {
  return {
    averageOperatingProfitAmount: undefined,
    forecastResultThisYear: undefined,
    hasLongTermDebt: undefined,
    hasShortTermDebt: undefined,
    solidityPercentage: undefined,
    liquidAssets: undefined,
    liquidityPercentage: undefined,
  };
}

export const getCompanySignupInitialState = (): CompanySignupState => ({
  orgNumber: undefined,
  companyEmail: undefined,
  companyName: undefined,
  companyAddress: undefined,
  companyCity: undefined,
  companyZipCode: undefined,
  companyPhoneNumber: undefined,
  numberOfPrincipals: undefined,
  companyRepresentatives: [
    new CompanyRepresentativeInfo({
      personalIdentityNumber: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
      role: undefined,
      actionWaitingForSignature: undefined,
      createdAt: undefined,
    }),
  ],
  insuredContactInfo: {
    personalNumber: undefined,
    email: undefined,
    phoneNumber: undefined,
  } as ContactInfo,
  hasCompanyPrincipals: undefined,
  companyPrincipals: [],
  companyForm: CompanyForm.LimitedCompany,
  industry1: undefined,
  industry2: undefined,
  industry3: undefined,
  revenue: undefined,
  employeeCount: undefined,
  savingsPurpose: {
    savingShortTerm: false,
    savingLongTerm: false,
    savingPension: false,
    savingInsurance: false,
    savingDiscretionaryManagement: false,
  },
  investmentBackground: {
    incomeExcessCapital: false,
    incomeSaleAssets: false,
    incomeDomesticSavings: false,
    incomeForeignSavings: false,
    incomeExternalCapital: false,
  },
  yearlyDepositAmount: undefined,
  yearlyDepositAmountManual: undefined,
  depositFrequency: undefined,
  withdrawalFrequency: undefined,
  hasAccountant: undefined,
  isActive: undefined,
  economy: getEconomyInitialState(),
  timeToWithdraw: undefined,
  includedSectors: {
    fossilFuel: true,
    alcoholTobacco: true,
    weaponsAndDefense: true,
    casinosAndGambling: true,
  },
  focus: undefined,
  riskWillingness: undefined,
  customRiskLevel: undefined,
  calculatedRiskLevel: undefined,
  marketFocus: undefined,
  bankName: undefined,
  clearingNumber: undefined,
  bankAccountNumber: undefined,
  firstDeposit: undefined,
  monthlySavingsAmount: undefined,
  authorizedSignatories: [],
  allowedSigningCombinations: [],
  loadingPdf: [],
  acceptedPersonalDataPolicy: true,
  trackingGuid: undefined,
  oneManCompany: false,
  affiliateCode: undefined,
  affiliateName: undefined,
  fortnoxCustomer: false,
  tiedAgent: undefined,
  portfolioTheme: undefined,
  companyTaxableOtherCountry: undefined,
  hasPreviousInsuranceExperience: undefined,
  hasSecuritiesOwnerKnowledge: undefined,
});

export const getCompanyPrincipalInitialState = () => ({
  companyPrincipalOther: false,
  companyPrincipalHasSwedishPersonalNumber: true,
  principalTitle: undefined,
  principalTitleFreeText: undefined,
  personalNumber: undefined,
  registeredSwedishResident: undefined,
  firstName: undefined,
  lastName: undefined,
  percentageShares: undefined,
  citizenship1: undefined,
  citizenship2: undefined,
  citizenship3: undefined,
  taxableSweden: undefined,
  taxableUsa: undefined,
  taxableOther: undefined,
  isPEP: undefined,
  email: undefined,
  phoneNumber: undefined,
  birthDate: undefined,
  address: undefined,
  zipCode: undefined,
  city: undefined,
  country: undefined,
  role: undefined,
});

export const CompanySignupMutation = {
  setState: "setState",
  setCompanyName: "setCompanyName",
  setCompanyAddress: "setCompanyAddress",
  setCompanyCity: "setCompanyCity",
  setCompanyZipCode: "setCompanyZipCode",
  setNumberOfPrincipals: "setNumberOfPrincipals",
  setCompanyRepresentative: "setCompanyRepresentative",
  removeCompanyRepresentative: "removeCompanyRepresentative",
  setInsuredPersonalumber: "setInsuredPersonalumber",
  setInsuredEmail: "setInsuredEmail",
  setInsuredPhoneNumber: "setInsuredPhoneNumber",
  setCompanyPrincipal: "setCompanyPrincipal",
  removeCompanyPrincipal: "removeCompanyPrincipal",
  setPrincipalPrincipalType: "setPrincipalPrincipalType",
  setPrincipalPersonalNumber: "setPrincipalPersonalNumber",
  setPrincipalRegisteredSwedishResident: "setPrincipalRegisteredSwedishResident",
  setPrincipalFirstName: "setPrincipalFirstName",
  setPrincipalLastName: "setPrincipalLastName",
  setPrincipalBirthDate: "setPrincipalBirthDate",
  setPrincipalPercentageShares: "setPrincipalPercentageShares",
  setPrincipalCitizenship1: "setPrincipalCitizenship1",
  setPrincipalCitizenship2: "setPrincipalCitizenship2",
  setPrincipalCitizenship3: "setPrincipalCitizenship3",
  setPrincipalTaxableSweden: "setPrincipalTaxableSweden",
  setPrincipalTaxableUsa: "setPrincipalTaxableUsa",
  setPrincipalTaxableOther: "setPrincipalTaxableOther",
  setPrincipalOtherTaxableCountry: "setPrincipalOtherTaxableCountry",
  setPrincipalIsPEP: "setPrincipalIsPEP",
  setPrincipalEmail: "setPrincipalEmail",
  setPrincipalPhoneNumber: "setPrincipalPhoneNumber",
  setPrincipalAddress: "setPrincipalAddress",
  setPrincipalZipCode: "setPrincipalZipCode",
  setPrincipalCity: "setPrincipalCity",
  setPrincipalCountry: "setPrincipalCountry",
  setPrincipalRole: "setPrincipalRole",
  setCompanyPrincipals: "setCompanyPrincipals",
  setCompanyForm: "setCompanyForm",
  setIndustry1: "setIndustry1",
  setIndustry2: "setIndustry2",
  setIndustry3: "setIndustry3",
  setRevenue: "setRevenue",
  setEmployeeCount: "setEmployeeCount",
  setSavingsPurpose: "setSavingsPurpose",
  setInvestmentBackground: "setInvestmentBackground",
  setYearlyDepositAmount: "setYearlyDepositAmount",
  setYearlyDepositAmountManual: "setYearlyDepositAmountManual",
  setDepositFrequency: "setDepositFrequency",
  setWithdrawalFrequency: "setWithdrawalFrequency",
  setOrgNumber: "setOrgNumber",
  setCompanyEmail: "setCompanyEmail",
  setCompanyPhoneNumber: "setCompanyPhoneNumber",
  setPrincipalOther: "setPrincipalOther",
  setPrincipalHasSwedishPersonalNumber: "setPrincipalHasSwedishPersonalNumber",
  setPrincipalTitle: "setPrincipalTitle",
  setPrincipalTitleFreeText: "setPrincipalTitleFreeText",
  setHasAccountant: "setHasAccountant",
  includeSector: "includeSector",
  setFocus: "setFocus",
  setRiskWillingness: "setRiskWillingness",
  setCustomRiskLevel: "setCustomRiskLevel",
  setCalculatedRiskLevel: "setCalculatedRiskLevel",
  setMarketFocus: "setMarketFocus",
  setBank: "setBank",
  setBankAccountNumber: "setBankAccountNumber",
  setClearingNumber: "setClearingNumber",
  setFirstDeposit: "setFirstDeposit",
  setMonthlySavingsAmount: "setMonthlySavingsAmount",
  setAuthorizedSignatories: "setAuthorizedSignatories",
  setAllowedSigningCombinations: "setAllowedSigningCombinations",
  setAuthorizedSignatoryHasToSign: "setAuthorizedSignatoryHasToSign",
  setAuthorizedSignatoryEmail: "setAuthorizedSignatoryEmail",
  setAuthorizedSignatoryEmailVerify: "setAuthorizedSignatoryEmailVerify",
  setIsActive: "setIsActive",
  resetCompanySignupEconomy: "resetCompanySignupEconomy",
  setAverageOperatingProfitAmount: "setAverageOperatingProfitAmount",
  setForecastResultThisYear: "setForecastResultThisYear",
  setHasLongTermDebt: "setHasLongTermDebt",
  setHasShortTermDebt: "setHasShortTermDebt",
  setSolidityPercentage: "setSolidityPercentage",
  setLiquidAssets: "setLiquidAssets",
  setLiquidityPercentage: "setLiquidityPercentage",
  setCompanyTimeToWithdraw: "setCompanyTimeToWithdraw",
  setHasCompanyPrincipals: "setHasCompanyPrincipals",
  setLoadingPdf: "setLoadingPdf",
  setAcceptedPersonalDataPolicy: "setAcceptedPersonalDataPolicy",
  setOneManCompany: "setOneManCompany",
  setCompanyTrackingGuid: "setCompanyTrackingGuid",
  setAffiliateCode: "setAffiliateCode",
  setAffiliateName: "setAffiliateName",
  setFortnoxCustomer: "setFortnoxCustomer",
  setPortfolioTheme: "setPortfolioTheme",
  setTiedAgent: "setTiedAgent",
  setCompanyTaxableOtherCountry: "setCompanyTaxableOtherCountry",
  setHasPreviousInsuranceExperience: "setHasPreviousInsuranceExperience",
  setHasSecuritiesOwnerKnowledge: "setHasSecuritiesOwnerKnowledge",
};
export const mutations: MutationTree<CompanySignupState> = {
  [CompanySignupMutation.setState](state, stateChange: CompanySignupState) {
    Object.assign(state, stateChange);
  },
  [CompanySignupMutation.setCompanyName](state, value: string) {
    state.companyName = value;
  },
  [CompanySignupMutation.setCompanyAddress](state, value: string) {
    state.companyAddress = value;
  },
  [CompanySignupMutation.setCompanyCity](state, value: string) {
    state.companyCity = value;
  },
  [CompanySignupMutation.setCompanyZipCode](state, value: string) {
    state.companyZipCode = value;
  },
  [CompanySignupMutation.setNumberOfPrincipals](state, value: number) {
    state.numberOfPrincipals = value;
  },
  [CompanySignupMutation.setCompanyRepresentative](
    state,
    payload: { index: number; value: CompanyRepresentativeInfo },
  ) {
    const representatives = [...state.companyRepresentatives];
    representatives[payload.index] = payload.value;
    state.companyRepresentatives = representatives;
  },
  [CompanySignupMutation.removeCompanyRepresentative](state, index: number) {
    let representatives = [...state.companyRepresentatives];
    representatives = representatives.filter((_, i) => i !== index);
    state.companyRepresentatives = representatives;
  },
  [CompanySignupMutation.setInsuredPersonalumber](state, value: string) {
    state.insuredContactInfo.personalNumber = value;
  },
  [CompanySignupMutation.setInsuredEmail](state, value: string) {
    state.insuredContactInfo.email = value;
  },
  [CompanySignupMutation.setInsuredPhoneNumber](state, value: string) {
    state.insuredContactInfo.phoneNumber = value;
  },
  // [CompanySignupMutation.setCompanyPrincipal](state, value: string) {
  //   state.numberOfPrincipals = value;
  // },
  // [CompanySignupMutation.removeCompanyPrincipal](state, value: string) {
  //   state.numberOfPrincipals = value;
  // },
  [CompanySignupMutation.setPrincipalOther](state, payload: { index: number; value: boolean }) {
    state.companyPrincipals[payload.index].companyPrincipalOther = payload.value;
  },
  [CompanySignupMutation.setPrincipalHasSwedishPersonalNumber](
    state,
    payload: { index: number; value: boolean },
  ) {
    state.companyPrincipals[payload.index].companyPrincipalHasSwedishPersonalNumber = payload.value;
  },
  [CompanySignupMutation.setPrincipalTitle](state, payload: { index: number; value: string }) {
    state.companyPrincipals[payload.index].principalTitle = payload.value;
  },
  [CompanySignupMutation.setPrincipalTitleFreeText](
    state,
    payload: { index: number; value: string },
  ) {
    state.companyPrincipals[payload.index].principalTitleFreeText = payload.value;
  },
  [CompanySignupMutation.setPrincipalPersonalNumber](
    state,
    payload: { index: number; value: string },
  ) {
    state.companyPrincipals[payload.index].personalNumber = payload.value;
  },
  [CompanySignupMutation.setPrincipalRegisteredSwedishResident](
    state,
    payload: { index: number; value: boolean },
  ) {
    state.companyPrincipals[payload.index].registeredSwedishResident = payload.value;
  },
  [CompanySignupMutation.setPrincipalFirstName](state, payload: { index: number; value: string }) {
    state.companyPrincipals[payload.index].firstName = payload.value;
  },
  [CompanySignupMutation.setPrincipalLastName](state, payload: { index: number; value: string }) {
    state.companyPrincipals[payload.index].lastName = payload.value;
  },
  [CompanySignupMutation.setPrincipalBirthDate](state, payload: { index: number; value: string }) {
    state.companyPrincipals[payload.index].birthDate = payload.value;
  },
  [CompanySignupMutation.setPrincipalPercentageShares](
    state,
    payload: { index: number; value: number },
  ) {
    state.companyPrincipals[payload.index].percentageShares = payload.value;
  },
  [CompanySignupMutation.setPrincipalCitizenship1](
    state,
    payload: { index: number; value: string },
  ) {
    state.companyPrincipals[payload.index].citizenship1 = payload.value;
  },
  [CompanySignupMutation.setPrincipalCitizenship2](
    state,
    payload: { index: number; value: string },
  ) {
    state.companyPrincipals[payload.index].citizenship2 = payload.value;
  },
  [CompanySignupMutation.setPrincipalCitizenship3](
    state,
    payload: { index: number; value: string },
  ) {
    state.companyPrincipals[payload.index].citizenship3 = payload.value;
  },
  [CompanySignupMutation.setPrincipalTaxableUsa](
    state,
    payload: { index: number; value: boolean },
  ) {
    state.companyPrincipals[payload.index].taxableUsa = payload.value;
  },
  [CompanySignupMutation.setPrincipalTaxableSweden](
    state,
    payload: { index: number; value: boolean },
  ) {
    state.companyPrincipals[payload.index].taxableSweden = payload.value;
  },
  [CompanySignupMutation.setPrincipalTaxableOther](
    state,
    payload: { index: number; value: boolean },
  ) {
    state.companyPrincipals[payload.index].taxableOther = payload.value;
  },
  [CompanySignupMutation.setPrincipalIsPEP](state, payload: { index: number; value: boolean }) {
    state.companyPrincipals[payload.index].isPEP = payload.value;
  },
  [CompanySignupMutation.setPrincipalEmail](state, payload: { index: number; value: string }) {
    state.companyPrincipals[payload.index].email = payload.value;
  },
  [CompanySignupMutation.setPrincipalPhoneNumber](
    state,
    payload: { index: number; value: string },
  ) {
    state.companyPrincipals[payload.index].phoneNumber = payload.value;
  },
  [CompanySignupMutation.setPrincipalAddress](state, payload: { index: number; value: string }) {
    state.companyPrincipals[payload.index].address = payload.value;
  },
  [CompanySignupMutation.setPrincipalZipCode](state, payload: { index: number; value: string }) {
    state.companyPrincipals[payload.index].zipCode = payload.value;
  },
  [CompanySignupMutation.setPrincipalCity](state, payload: { index: number; value: string }) {
    state.companyPrincipals[payload.index].city = payload.value;
  },
  [CompanySignupMutation.setPrincipalCountry](state, payload: { index: number; value: string }) {
    state.companyPrincipals[payload.index].country = payload.value;
  },
  [CompanySignupMutation.setPrincipalRole](state, payload: { index: number; value: Role }) {
    state.companyPrincipals[payload.index].role = payload.value;
  },
  [CompanySignupMutation.setPrincipalOtherTaxableCountry](
    state,
    payload: { index: number; value: string },
  ) {
    state.companyPrincipals[payload.index].taxableOtherCountry = payload.value;
  },
  [CompanySignupMutation.setCompanyPrincipals](state, value: CompanyPrincipalStore[]) {
    state.companyPrincipals = value;
  },
  [CompanySignupMutation.setCompanyForm](state, value: CompanyForm) {
    state.companyForm = value;
  },
  [CompanySignupMutation.setIndustry1](state, value: CompanyIndustry) {
    state.industry1 = value;
  },
  [CompanySignupMutation.setIndustry2](state, value: CompanyIndustry) {
    state.industry2 = value;
  },
  [CompanySignupMutation.setIndustry3](state, value: CompanyIndustry) {
    state.industry3 = value;
  },
  [CompanySignupMutation.setRevenue](state, value: CompanyRevenue) {
    state.revenue = value;
  },
  [CompanySignupMutation.setEmployeeCount](state, value: CompanyEmployees) {
    state.employeeCount = value;
  },
  [CompanySignupMutation.setSavingsPurpose](
    state,
    savingAlternative: { key: CompanySavingAlternative; value: boolean },
  ) {
    state.savingsPurpose[savingAlternative.key] = savingAlternative.value;
  },
  [CompanySignupMutation.setInvestmentBackground](
    state,
    savingAlternative: { key: CompanyBackgroundAlternative; value: boolean },
  ) {
    state.investmentBackground[savingAlternative.key] = savingAlternative.value;
  },
  [CompanySignupMutation.setYearlyDepositAmount](state, value: YearlyDepositAmount) {
    state.yearlyDepositAmount = value;
  },
  [CompanySignupMutation.setYearlyDepositAmountManual](state, value: number) {
    state.yearlyDepositAmountManual = value;
  },
  [CompanySignupMutation.setDepositFrequency](state, value: DepositFrequency) {
    state.depositFrequency = value;
  },
  [CompanySignupMutation.setWithdrawalFrequency](state, value: WithdrawalFrequency) {
    state.withdrawalFrequency = value;
  },
  [CompanySignupMutation.setOrgNumber](state, value: string) {
    state.orgNumber = value;
  },
  [CompanySignupMutation.setCompanyEmail](state, value: string) {
    state.companyEmail = value;
  },
  [CompanySignupMutation.setCompanyPhoneNumber](state, value: string) {
    state.companyPhoneNumber = value;
  },
  [CompanySignupMutation.setHasAccountant](state, value: boolean) {
    state.hasAccountant = value;
  },
  [CompanySignupMutation.includeSector](state, payload: { key: Sector; value: boolean }) {
    state.includedSectors[payload.key] = payload.value;
  },
  [CompanySignupMutation.setFocus](state, payload: StrategyFocus) {
    state.focus = payload;
  },
  [CompanySignupMutation.setRiskWillingness](state, payload: RiskWillingness) {
    state.riskWillingness = payload;
  },
  [CompanySignupMutation.setCustomRiskLevel](state, payload: number) {
    state.customRiskLevel = payload;
  },
  [CompanySignupMutation.setCalculatedRiskLevel](state, riskLevel: RiskLevel) {
    state.calculatedRiskLevel = riskLevel;
  },
  [CompanySignupMutation.setMarketFocus](state, market: Market) {
    state.marketFocus = market;
  },
  [CompanySignupMutation.setBank](state, chosenBank: string) {
    state.bankName = chosenBank;
  },
  [CompanySignupMutation.setBankAccountNumber](state, value: string) {
    state.bankAccountNumber = value;
  },
  [CompanySignupMutation.setClearingNumber](state, clearingNumber: string) {
    state.clearingNumber = clearingNumber;
  },
  [CompanySignupMutation.setFirstDeposit](state, firstDeposit: number) {
    state.firstDeposit = firstDeposit;
  },
  [CompanySignupMutation.setMonthlySavingsAmount](state, amount: number) {
    state.monthlySavingsAmount = amount;
  },
  [CompanySignupMutation.setAuthorizedSignatories](state, value: AuthorizedSignatory[]) {
    state.authorizedSignatories = value;
  },
  [CompanySignupMutation.setAllowedSigningCombinations](
    state,
    value: CompanySigningCombinationPerson[][],
  ) {
    state.allowedSigningCombinations = value;
  },
  [CompanySignupMutation.setAuthorizedSignatoryHasToSign](
    state,
    payload: { index: number; value: boolean },
  ) {
    state.authorizedSignatories[payload.index].hasToSign = payload.value;
  },
  [CompanySignupMutation.setAuthorizedSignatoryEmail](
    state,
    payload: { index: number; value: string },
  ) {
    state.authorizedSignatories[payload.index].email = payload.value;
  },
  [CompanySignupMutation.setAuthorizedSignatoryEmailVerify](
    state,
    payload: { index: number; value: string },
  ) {
    state.authorizedSignatories[payload.index].emailVerify = payload.value;
  },
  [CompanySignupMutation.setIsActive](state, value: boolean) {
    state.isActive = value;
  },
  [CompanySignupMutation.resetCompanySignupEconomy](state) {
    state.economy = getEconomyInitialState();
  },
  [CompanySignupMutation.setAverageOperatingProfitAmount](state, value: number) {
    state.economy.averageOperatingProfitAmount = value;
  },
  [CompanySignupMutation.setForecastResultThisYear](state, value: number) {
    state.economy.forecastResultThisYear = value;
  },
  [CompanySignupMutation.setHasLongTermDebt](state, value: boolean) {
    state.economy.hasLongTermDebt = value;
  },
  [CompanySignupMutation.setHasShortTermDebt](state, value: boolean) {
    state.economy.hasShortTermDebt = value;
  },
  [CompanySignupMutation.setSolidityPercentage](state, value: number) {
    state.economy.solidityPercentage = value;
  },
  [CompanySignupMutation.setLiquidAssets](state, value: number) {
    state.economy.liquidAssets = value;
  },
  [CompanySignupMutation.setLiquidityPercentage](state, value: number) {
    state.economy.liquidityPercentage = value;
  },
  [CompanySignupMutation.setCompanyTimeToWithdraw](state, value: TimeToWithdraw) {
    state.timeToWithdraw = value;
  },
  [CompanySignupMutation.setHasCompanyPrincipals](state, value: boolean) {
    state.hasCompanyPrincipals = value;
  },
  [CompanySignupMutation.setLoadingPdf](state, { pdfId, loading }) {
    const { loadingPdf } = state;
    const loadingPdfCopy = loadingPdf.slice();
    loadingPdfCopy[pdfId] = loading;
    state.loadingPdf = loadingPdfCopy;
  },
  [CompanySignupMutation.setAcceptedPersonalDataPolicy](state, payload: boolean) {
    state.acceptedPersonalDataPolicy = payload;
  },
  [CompanySignupMutation.setCompanyTrackingGuid](state, trackingGuid: string) {
    state.trackingGuid = trackingGuid;
  },
  [CompanySignupMutation.setOneManCompany](state, value: boolean) {
    state.oneManCompany = value;
  },
  [CompanySignupMutation.setAffiliateCode](state, value: string) {
    state.affiliateCode = value;
  },
  [CompanySignupMutation.setAffiliateName](state, value: string) {
    state.affiliateName = value;
  },
  [CompanySignupMutation.setFortnoxCustomer](state, value: boolean) {
    state.fortnoxCustomer = value;
  },
  [CompanySignupMutation.setPortfolioTheme](state, value: TiedAgentPortfolioTheme) {
    state.portfolioTheme = value;
  },
  [CompanySignupMutation.setTiedAgent](state, value: TiedAgent) {
    state.tiedAgent = value;
  },
  [CompanySignupMutation.setCompanyTaxableOtherCountry](state, value: string) {
    state.companyTaxableOtherCountry = value;
  },
  [CompanySignupMutation.setHasPreviousInsuranceExperience](state, value: boolean) {
    state.hasPreviousInsuranceExperience = value;
  },
  [CompanySignupMutation.setHasSecuritiesOwnerKnowledge](state, value: boolean) {
    state.hasSecuritiesOwnerKnowledge = value;
  },
};
