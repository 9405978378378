<template>
  <div class="company-profile">
    <section-header
      v-if="$vuetify.display.mdAndUp"
      :header="$t('companyInfo')"
      class="company-profile__header"
    >
      <template #icon>
        <icon
          :icon="['fal', 'building']"
          size="lg"
        />
      </template>
    </section-header>
    <div class="company-profile__cards">
      <v-card class="company-profile__contact-card">
        <contact />
      </v-card>
      <v-card class="company-profile__economy">
        <economy @save="openAgreementsDialog('economy')" />
      </v-card>
      <v-card class="company-profile__info">
        <info @save="openAgreementsDialog('info')" />
      </v-card>
      <v-card class="company-profile__notification-settings">
        <notification-settings />
      </v-card>
      <v-card class="company-profile__insurance-info">
        <insurance-info />
      </v-card>
      <v-card>
        <discounts />
      </v-card>
    </div>
    <representatives />
    <principals
      @save="savePrincipal"
      ref="principals"
    />
    <terminate-account-dialog v-model="showTerminateAccountDialog" />
    <update-agreements-dialog
      v-if="showUpdateAgreementsDialog"
      :model-value="showUpdateAgreementsDialog"
      :setting-to-save="settingToSave"
      :update-settings-error="updateSettingsError"
      :signing-complete="signComplete"
      :sign-location="SignLocation.PROFILE"
      @input="showUpdateAgreementsDialog = false"
      @save="handleAgreementDialogSave"
      @cancel="handleAgreementDialogCancel"
      @agreements="showAgreementsDialog = true"
      @prepare="handleAgreementDialogPrepare"
    />
    <agreements-side-dialog
      v-model="showAgreementsDialog"
      :portfolios="portfolios"
      :preview-mode="true"
    />
    <validation-change-dialog
      v-if="showValidationChangeDialog"
      :model-value="showValidationChangeDialog"
      :validation-action="validationAction"
      :portfolios="portfoliosWithNewRecommendedRiskLevels"
      :signing-complete="signComplete"
      @input="closeValidation"
    />
    <v-dialog
      v-model="showSavingWaitDialog"
      content-class="kyc-validation"
    >
      <v-card class="kyc-validation-dialog"
        ><span>{{ $t("profile.profile.savingChanges") }}</span> <loading-spinner /></v-card
    ></v-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import SectionHeader from "@/components/section-header.vue";
import Contact from "@/components/contact.vue";
import NotificationSettings from "@/components/notification-settings.vue";
import Discounts from "@/views/profile/discounts.vue";
import Economy from "./economy.vue";
import Info from "./info.vue";
import InsuranceInfo from "./insurance-info.vue";
import Principals from "./principals.vue";
import Representatives from "./representatives.vue";
import { NavigationMutation } from "@/store/navigation/mutations";
import { UserAction } from "@/store/user/action-definitions";
import { SignLocation } from "@/store/bankid/types";
import {
  CompanyPrincipal,
  LegalEntityValidationAction,
  LegalEntityValidationResponse,
  Portfolio,
  PortfolioRecommendedRiskLevel
} from "@/clients";
import { UserMutation } from "@/store/user/mutations";
import { BankIdMutation } from "@/store/bankid/mutations";
import UpdateAgreementsDialog from "@/components/update-agreements-dialog.vue";
import AgreementsSideDialog from "@/components/preview-agreements-side-dialog.vue";
import ValidationChangeDialog from "../profile/validation-change-dialog.vue";
import TerminateAccountDialog from "../profile/terminate-account/terminate-account.vue";
import { PortfolioAction } from "@/store/portfolio/actions";
import { hasForbiddenCitizenship } from "@/config/countries";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";

const EDITED_LEGAL_ENTITY_KEY = "EDITED_LEGAL_ENTITY";
const PROFILE_SETTINGS_TO_SAVE_KEY = "PROFILE_SETTINGS_TO_SAVE";
const PROFILE_VALIDATION_CHANGE_PORTFOLIOS_KEY = "PROFILE_VALIDATION_CHANGE_PORTFOLIOS";
const PROFILE_VALIDATION_ACTION_KEY = "PROFILE_VALIDATION_ACTION";

export default defineComponent({
  components: {
    SectionHeader,
    Contact,
    Economy,
    Info,
    NotificationSettings,
    InsuranceInfo,
    Discounts,
    Principals,
    Representatives,
    UpdateAgreementsDialog,
    AgreementsSideDialog,
    ValidationChangeDialog,
    TerminateAccountDialog,
    LoadingSpinner
  },
  head() {
    return {
      title: this.$t("profile")
    };
  },
  data() {
    return {
      showTerminateAccountDialog: false,
      showWarningDialog: false,
      discardEdits: false,
      nextRoute: {},
      showUpdateAgreementsDialog: false,
      settingToSave: "",
      showAgreementsDialog: false,
      showValidationChangeDialog: false,
      showSavingWaitDialog: false,
      signComplete: false,
      SignLocation,
      validationAction: LegalEntityValidationAction.NoChange,
      portfoliosWithNewRecommendedRiskLevels: [] as PortfolioRecommendedRiskLevel[],
      principalIdxToSave: 0
    };
  },
  mounted(): any {
    this.$store.commit(NavigationMutation.setBackRoute, { name: "overview" });
    this.$store.commit(NavigationMutation.setMobileHeading, this.$t("profile"));
  },
  async created() {
    this.$store.commit(UserMutation.resetUnsavedEdits);
    if (this.$store.state.portfolioStore.allPortfolios.length === 0) {
      await this.$store.dispatch(PortfolioAction.loadAllPortfolios);
    }
    if (!this.$store.state.userStore.notificationSettings) {
      this.$store.dispatch(UserAction.loadNotificationSettings);
    }
    if (!this.$store.state.userStore.companyRepresentatives) {
      this.$store.dispatch(UserAction.getCompanyRepresentatives);
    }
  },
  computed: {
    ...mapGetters(["hasUnsavedProfileEdits"]),
    updateSettingsError(): boolean {
      return this.$store.state.userStore.errorStates.updateSettingsError;
    },
    portfolios(): Portfolio[] {
      return this.$store.state.portfolioStore.allPortfolios;
    }
  },
  methods: {
    closeValidation(e: any) {
      this.showValidationChangeDialog = false;
    },
    async openDialogSaveKyc() {
      if (
        this.$store.state.userStore.editedLegalEntity.knowYourCustomerAnswers.taxableUsa ||
        !this.$store.state.userStore.editedLegalEntity.knowYourCustomerAnswers
          .swedishTaxResidence ||
        !this.$store.state.userStore.editedLegalEntity.knowYourCustomerAnswers.livingInSweden
      ) {
        this.showSavingWaitDialog = true;
        await this.$store.dispatch(UserAction.prepareUpdateKyc);
        this.showSavingWaitDialog = false;
      } else {
        this.openAgreementsDialog("kyc");
      }
    },
    async handleAgreementDialogEvent(type: string, event: string) {
      if (type === "prepare") {
        switch (event) {
          case "economy":
            await this.$store.dispatch(UserAction.prepareUpdateLegalEntityEconomyCompany);
            break;
          case "principal":
            await this.$store.dispatch(
              UserAction.prepareUpdateLegalEntityCompanyPrincipals,
              this.principalIdxToSave
            );
            break;
          default:
            break;
        }
      } else if (type === "save") {
        switch (event) {
          case "economy":
            await this.completeUpdateLegalEntityCompany();
            break;
          case "principal":
            await this.completeUpdateLegalEntityCompany();
            break;
          default:
            break;
        }
      } else if (type === "cancel") {
        this.$store.commit(UserMutation.setUpdateSettingsError, false);
        switch (event) {
          case "economy":
            this.resetKyc();
            break;
          case "principal":
            this.resetKyc();
            break;
          default:
            break;
        }
        this.$store.commit(BankIdMutation.resetBankId);
      }
    },
    resetKyc() {
      this.$store.commit(UserMutation.setEditedCompanyKnowYourCustomerAnswers, {
        ...this.$store.state.userStore.currentLegalEntity.companyKnowYourCustomerAnswers,
        companyPrincipals:
          this.$store.state.userStore.currentLegalEntity.companyKnowYourCustomerAnswers.companyPrincipals.map(
            (c: CompanyPrincipal) => new CompanyPrincipal({ ...c })
          )
      });
    },
    async handleAgreementDialogPrepare(event: string, resolve: () => void) {
      try {
        await this.handleAgreementDialogEvent("prepare", event);
      } catch (e) {
        this.$store.commit(UserMutation.setUpdateSettingsError, true);
      }
      resolve();
    },
    async handleAgreementDialogSave(event: string, resolve: () => void) {
      this.signComplete = false;
      await this.handleAgreementDialogEvent("save", event);
      resolve();
    },
    handleAgreementDialogCancel(event: string) {
      this.signComplete = false;
      this.handleAgreementDialogEvent("cancel", event);
      this.showUpdateAgreementsDialog = false;
    },
    async completeUpdateLegalEntityCompany() {
      const response: LegalEntityValidationResponse | undefined = await this.$store.dispatch(
        UserAction.completeUpdateLegalEntityCompany
      );
      if (response) {
        // economy questions update only affect poor economy or new recommended risklevels so ignore terminate customer
        if (response.validationAction !== LegalEntityValidationAction.TerminateCustomer) {
          this.handleUpdateLegalEntityUpdateResponse(response);
        }
      }
    },
    handleUpdateLegalEntityUpdateResponse(validation: LegalEntityValidationResponse) {
      this.showSavingWaitDialog = false;
      switch (validation.validationAction) {
        case LegalEntityValidationAction.TerminateCustomer:
        case LegalEntityValidationAction.VulnerableEconomy:
          this.validationAction = validation.validationAction;
          break;
        case LegalEntityValidationAction.NewRecommendedRiskLevel:
          this.validationAction = validation.validationAction;
          if (validation.portfolioRecommendedRiskLevels) {
            this.portfoliosWithNewRecommendedRiskLevels = validation.portfolioRecommendedRiskLevels;
          }
          break;
        default:
          break;
      }
      if (validation.validationAction !== LegalEntityValidationAction.NoChange) {
        sessionStorage.setItem(PROFILE_VALIDATION_ACTION_KEY, this.validationAction.toString());
        sessionStorage.setItem(
          PROFILE_VALIDATION_CHANGE_PORTFOLIOS_KEY,
          JSON.stringify(this.portfoliosWithNewRecommendedRiskLevels)
        );
        this.showValidationChangeDialog = true;
      }
    },
    async savePrincipal(index: any) {
      this.principalIdxToSave = index.index;
      const principalToSave: CompanyPrincipal =
        this.$store.state.userStore.editedCompanyKnowYourCustomerAnswers.companyPrincipals[
          this.principalIdxToSave
        ];
      if (
        principalToSave.taxableCountries.includes("US") ||
        hasForbiddenCitizenship(
          principalToSave.citizenship1,
          principalToSave.citizenship2,
          principalToSave.citizenship3
        )
      ) {
        this.showSavingWaitDialog = true;
        await this.$store.dispatch(
          UserAction.prepareUpdateLegalEntityCompanyPrincipals,
          this.principalIdxToSave
        );
        this.showSavingWaitDialog = false;
      } else {
        this.openAgreementsDialog("principal");
      }
    },
    openAgreementsDialog(type: string) {
      this.showUpdateAgreementsDialog = true;
      this.settingToSave = type;
      sessionStorage.setItem(PROFILE_SETTINGS_TO_SAVE_KEY, this.settingToSave);
      sessionStorage.setItem(
        EDITED_LEGAL_ENTITY_KEY,
        JSON.stringify(this.$store.state.userStore.editedLegalEntity)
      );
    }
  }
});
</script>
<style lang="scss" scoped>
.company-profile {
  max-width: 71rem;
  padding: 0.25rem 1rem 5rem 1rem;
  width: 100%;
  @include medium-up {
    padding: unset;
  }
  &__header {
    width: 100%;
  }
  &__cards {
    display: grid;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    grid-template-rows: auto;
    @include small-up {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto auto;
    }
    @include medium-up {
      margin-top: 2rem;
      grid-row-gap: 1.5rem;
      grid-column-gap: 1.5rem;
    }
  }
  &__contact-card {
    @include small-up {
      grid-column: 1;
      grid-row: 1;
    }
  }
  &__economy {
    @include small-up {
      grid-column: 1;
      grid-row: 2;
    }
  }
  &__info {
    @include small-up {
      grid-column: 2;
      grid-row: 1/3;
    }
  }
  &__notification-settings {
    @include small-up {
      grid-column: 1;
      grid-row: 3;
    }
  }
  &__insurance-info {
    @include small-up {
      grid-column: 2;
      grid-row: 3;
    }
  }
}
.v-menu__content {
  border-radius: 0.5rem;
  box-shadow: 0 0.1875rem 2rem rgba(0, 0, 0, 0.32);
  max-width: 15.625rem;
  @include small-down {
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
  }
}
.kyc-validation-dialog {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  &__card {
    min-height: 25rem;
    @include small-up {
      min-height: 22rem;
    }
  }
}
:deep(.kyc-validation) {
  max-width: 30rem;
}
</style>
