import { AuthenticationStatus } from "@/clients";
import { MutationTree } from "vuex";
import { BankIdState, BankIdStatus, SignLocation, StartMode } from "./types";

export const BankIdMutation = {
  setTransactionId: "setTransactionId",
  setAutoStartURL: "setAutoStartURL",
  setAutoStartToken: "setAutoStartToken",
  setStatus: "setStatus",
  setCancelBankId: "setCancelBankId",
  setActionInProgress: "setActionInProgress",
  resetBankId: "resetBankId",
  setAuthenticationStatus: "setAuthenticationStatus",
  setStartMode: "setStartMode",
  setAssentlyToken: "setAssentlyToken",
  setSignMessage: "setSignMessage",
  setSignTitle: "setSignTitle",
  setRedirectSignStatus: "setRedirectSignStatus",
  setSignLocation: "setSignLocation",
  setSignReturnPath: "setSignReturnPath",
  completeRedirectSignature: "completeRedirectSignature",
  setQrCode: "setQrCode"
};

const BANKID_TRANSACTION_ID_KEY = "BANKID_TRANSACTION_ID";
const BANKID_REDIRECT_SIGNSTATUS_KEY = "BANKID_REDIRECT_SIGNSTATUS";
const BANKID_SIGN_LOCATION_KEY = "BANKID_SIGN_LOCATION";
const BANKID_SIGN_RETURN_PATH_KEY = "BANKID_SIGN_RETURN_PATH";

export const getBankIdInitialState = (): BankIdState => ({
  transactionId: sessionStorage.getItem(BANKID_TRANSACTION_ID_KEY) ?? undefined,
  autoStartURL: undefined,
  autoStartToken: undefined,
  status: undefined,
  authenticationStatus: undefined,
  startMode: undefined,
  assentlyToken: undefined,
  signMessage: undefined,
  signTitle: undefined,
  redirectSignStatus: sessionStorage.getItem(BANKID_REDIRECT_SIGNSTATUS_KEY) ?? undefined,
  signLocation: sessionStorage.getItem(BANKID_SIGN_LOCATION_KEY)
    ? parseInt(sessionStorage.getItem(BANKID_SIGN_LOCATION_KEY)!, 10)
    : undefined,
  signReturnPath: sessionStorage.getItem(BANKID_SIGN_RETURN_PATH_KEY) ?? undefined,
  qrCode: undefined
});

export const mutations: MutationTree<BankIdState> = {
  [BankIdMutation.setSignMessage](state, value: string) {
    state.signMessage = value;
  },
  [BankIdMutation.setSignTitle](state, value: string) {
    state.signTitle = value;
  },
  [BankIdMutation.setAssentlyToken](state, value: string) {
    state.assentlyToken = value;
  },
  [BankIdMutation.setAutoStartToken](state, value: string) {
    state.autoStartToken = value;
  },
  [BankIdMutation.setStartMode](state, value: StartMode) {
    state.startMode = value;
  },
  [BankIdMutation.setTransactionId](state, value: string) {
    sessionStorage.setItem(BANKID_TRANSACTION_ID_KEY, value);
    state.transactionId = value;
  },
  [BankIdMutation.setAutoStartURL](state, value: string) {
    state.autoStartURL = value;
  },
  [BankIdMutation.setStatus](state, value: BankIdStatus) {
    state.status = value;
  },
  [BankIdMutation.setAuthenticationStatus](state, value: AuthenticationStatus) {
    state.authenticationStatus = value;
  },
  [BankIdMutation.resetBankId](state) {
    Object.assign(state, getBankIdInitialState());
  },
  [BankIdMutation.setRedirectSignStatus](state, value: string) {
    sessionStorage.setItem(BANKID_REDIRECT_SIGNSTATUS_KEY, value);
    state.redirectSignStatus = value;
  },
  [BankIdMutation.setSignLocation](state, value: SignLocation) {
    sessionStorage.setItem(BANKID_SIGN_LOCATION_KEY, value.toString());
    state.signLocation = value;
  },
  [BankIdMutation.setSignReturnPath](state, value: string) {
    sessionStorage.setItem(BANKID_SIGN_RETURN_PATH_KEY, value);
    state.signReturnPath = value;
  },
  [BankIdMutation.completeRedirectSignature](state) {
    sessionStorage.removeItem(BANKID_TRANSACTION_ID_KEY);
    sessionStorage.removeItem(BANKID_SIGN_RETURN_PATH_KEY);
    sessionStorage.removeItem(BANKID_SIGN_LOCATION_KEY);
    sessionStorage.removeItem(BANKID_REDIRECT_SIGNSTATUS_KEY);
    state.redirectSignStatus = undefined;
    state.signLocation = undefined;
    state.signReturnPath = undefined;
  },
  [BankIdMutation.setQrCode](state, qrCode: string) {
    state.qrCode = qrCode;
  }
};
