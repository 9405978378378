/* eslint-disable no-restricted-syntax */
import { SignupFlowModificationType } from "./signup-modification";

const modifications = {
  [SignupFlowModificationType.Pep]: [
    "/aml/politically-exposed-title",
    "/aml/politically-exposed-country",
    "/aml/politically-exposed-ongoing",
    "/aml/politically-exposed-savings"
  ],
  [SignupFlowModificationType.AssociatedPepRelative]: [
    "/aml/politically-exposed-associated-relationship-relative",
    "/aml/politically-exposed-associated-name",
    "/aml/politically-exposed-associated-title",
    "/aml/politically-exposed-associated-country",
    "/aml/politically-exposed-associated-ongoing",
    "/aml/politically-exposed-associated-savings"
  ],
  [SignupFlowModificationType.AssociatedPepCoworker]: [
    "/aml/politically-exposed-associated-relationship-coworker",
    "/aml/politically-exposed-associated-name",
    "/aml/politically-exposed-associated-title",
    "/aml/politically-exposed-associated-country",
    "/aml/politically-exposed-associated-ongoing",
    "/aml/politically-exposed-associated-organization",
    "/aml/politically-exposed-associated-savings"
  ],
  [SignupFlowModificationType.PepTerminated]: ["/aml/politically-exposed-terminated"],
  [SignupFlowModificationType.AssociatedPepTerminated]: [
    "/aml/politically-exposed-associated-terminated"
  ],
  [SignupFlowModificationType.ReferralIntro]: ["/referral-intro"],
  [SignupFlowModificationType.SteplerIntro]: ["/stepler-intro"],
  [SignupFlowModificationType.LeadsIntro]: ["/leads-intro"],
  [SignupFlowModificationType.FamiljekortetIntro]: ["/familjekortet-intro"],
  [SignupFlowModificationType.SustainablePortfolio]: ["/preferences/sustainable-method"],
  [SignupFlowModificationType.SustainablePortfolioTiedAgent]: ["/preferences/sustainable-method"],
  [SignupFlowModificationType.SustainablePortfolioVerify]: [
    "/preferences/sustainable-choice-verify"
  ],
  [SignupFlowModificationType.ExcludedNegativeImpact]: ["/preferences/sustainable-info"],

  [SignupFlowModificationType.MinimizedNegativeImpact]: [
    "/preferences/negative-impact",
    "/preferences/sustainable-choice"
  ],
  [SignupFlowModificationType.ExpensiveLoans]: ["/economy/loans-warning"],
  [SignupFlowModificationType.HasLoans]: ["/economy/loaned-amount"],

  [SignupFlowModificationType.TaxableCountry]: ["/aml/taxable-country"],
  [SignupFlowModificationType.PersonalIdentityNumber]: ["/account/personal-identity-number"],
  [SignupFlowModificationType.StockInformation]: ["/risk/stock-information"],
  [SignupFlowModificationType.TimeToWithdrawWarning]: ["/economy/time-to-withdraw-warning"],
  [SignupFlowModificationType.ExpensesWarning]: ["/economy/expenses-warning"],
  [SignupFlowModificationType.CurrentSavingsWarning]: ["/economy/current-savings-warning"],
  [SignupFlowModificationType.LoanedAmountWarning]: ["/economy/loaned-amount-warning"],
  [SignupFlowModificationType.WithdrawalIntervalInformationPrivate]: [
    "/aml/withdrawal-interval-information"
  ],
  [SignupFlowModificationType.CitizenshipDenial]: ["/aml/citizenship-information"],
  [SignupFlowModificationType.TaxLiableDenial]: ["/aml/tax-liable-denial"]
};

export function buildCommonModifications(signupRoutePrefix: string) {
  if (!signupRoutePrefix) {
    throw new Error("signup route prefix required");
  }

  const modCopy = { ...modifications };

  // eslint-disable-next-line prefer-const
  for (let [mod, paths] of Object.entries(modCopy)) {
    paths = paths.map((x) => `/${signupRoutePrefix}${x}`);
    modCopy[mod as unknown as keyof typeof modCopy] = paths;
  }

  return modCopy;
}
