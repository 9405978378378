<template>
  <div class="header-button__wrapper">
    <v-btn
      :v-bind="{ ...$attrs }"
      variant="flat"
      icon
      selected-class="header-button__button--active"
      class="header-button__button"
      width="3.25rem"
      height="3.25rem"
      data-jest="button"
      data-cy="headerButton"
      @click="(event: any) => $emit('click', event)"
    >
      <v-tooltip
        location="bottom"
        open-delay="700"
        transition="fade-transition"
        activator="parent"
      >
        <span data-jest="tooltip-text"> {{ tooltipText }} </span>
      </v-tooltip>
      <icon
        class="header-button__button-icon"
        :icon="icon"
        data-jest="button-icon"
      />
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    icon: {
      type: [String, Array],
      required: true,
      default: undefined
    },
    tooltipText: {
      type: String,
      default: undefined,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
.header-button {
  &__wrapper {
    width: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.1rem;
    @include small-up {
      width: 4.2rem;
    }
  }
  &__button {
    opacity: 0.8 !important;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      @include cursorHover {
        background: $whisper;
      }
    }
    &.focus-visible {
      opacity: 0.8 !important;
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%) !important;
    }
    &--active {
      opacity: 0.8 !important;
      color: $nearly-black !important;
      &::before {
        background: white;
      }
    }
  }
  &__button-icon {
    font-size: 1.25rem;
    color: $nearly-black;
    @include medium-up {
      font-size: 1.5rem;
    }
  }
}
</style>
