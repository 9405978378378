<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { softBlueDarker } from "@/styles/colors";

const props = defineProps<{
  header: NestedCheckboxHeader;
  body?: string;
  nested: NestedCheckbox[];
  indeterminate?: boolean;
}>();
const emit = defineEmits<{
  clickTop: [value: boolean | undefined];
  clickNested: [index: number, value: boolean | undefined];
}>();
defineSlots<{
  body1: (props: { text?: string }) => any;
}>();

export interface NestedCheckboxHeader {
  heading: string;
  body1?: string | undefined;
  body2?: string | undefined;
}
export interface NestedCheckbox {
  index?: number;
  heading: string;
  body1?: string;
  body2?: string;
  disabled?: boolean;
  checked?: any;
}
const topLevelChecked = ref(false);
function clickTop(value: boolean | undefined) {
  props.nested.forEach((e) => {
    if (!e.disabled) {
      emit("clickNested", e.index!, value);
    }
  });
  emit("clickTop", value);
}
watch(
  () => props.nested,
  (val) => {
    const allChecked = val.every(x => x.checked || x.disabled);
    if (allChecked) {
      topLevelChecked.value = true;
    } else {
      topLevelChecked.value = false;
    }
  },
  { deep: true, immediate: true },
);

const someSelectedButNotAll = computed(() => {
  const values = Object.values(props.nested).filter(x => !x.disabled);
  const numChecked = values.filter(x => x.checked === true).length;
  return numChecked !== values.length && numChecked !== 0;
});
</script>

<template>
  <v-expansion-panels>
    <v-expansion-panel
      elevation="0"
      style="background-color: unset !important"
    >
      <template #title>
        <div style="display: flex; flex-direction: row; align-items: center">
          <div>
            <v-checkbox-btn
              :model-value="topLevelChecked"
              :indeterminate="someSelectedButNotAll"
              indeterminate-icon="fal fa-minus-square"
              data-test="top-level"
              style="font-size: 1.125rem"
              @update:model-value="clickTop"
              @click="(e: Event) => e.stopPropagation()"
            />
          </div>
          <div style="display: flex; flex-direction: column; row-gap: 0.5rem">
            <h2 :style="{ fontWeight: 400, fontSize: '1.25rem' }">
              {{ props.header.heading }}
            </h2>
            <slot
              name="body1"
              :text="props.header.body1"
            />
            <div
              v-if="props.header.body2"
              :style="{ color: softBlueDarker, fontSize: '0.875rem', fontWeight: 300, fontStyle: 'italic' }"
            >
              {{ props.header.body2 }}
            </div>
          </div>
        </div>
      </template>
      <template #text>
        <v-checkbox-btn
          v-for="(checkbox, i) in nested"
          :key="i"
          style="margin-left: 1.8rem; margin-bottom: 0.8rem"
          :model-value="checkbox.checked"
          :data-test="`nested${i}`"
          :disabled="checkbox.disabled"
          @update:model-value="(value) => $emit('clickNested', checkbox.index!, value)"
          @click="(e: Event) => e.stopPropagation()"
        >
          <template #label>
            <div style="display: flex; flex-direction: column">
              <div :style="{ color: softBlueDarker, fontSize: '0.9375rem', fontWeight: 500, fontFamily: 'proxima-nova' }">
                {{ checkbox.heading }}
              </div>
              <div
                v-if="checkbox.body1"
                style="color: black; font-size: 0.9375rem; font-weight: 300"
              >
                {{ checkbox.body1 }}
              </div>
              <div
                v-if="checkbox.body2"
                :style="{ color: softBlueDarker, fontSize: '0.875rem', fontStyle: 'italic' }"
              >
                {{ checkbox.body2 }}
              </div>
            </div>
          </template>
        </v-checkbox-btn>
      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
