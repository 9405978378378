<template>
  <div class="move-pension">
    <div class="move-pension__wrapper">
      <img
        :src="imgUrl"
        class="move-pension__icon"
      />
      <h2 class="move-pension__title">
        {{ $t("moveOccupationalPension") }}
      </h2>
      <icon
        :icon="['fas', 'chevron-right']"
        class="move-pension__forward-icon"
      />
    </div>
    <div class="move-pension__content-wrapper">
      <span>
        {{ $t("components.move-pension.body") }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return { imgUrl: new URL("/assets/icons/pension-pyramid-black.svg", import.meta.url).href };
  }
});
</script>

<style lang="scss" scoped>
.move-pension {
  display: flex;
  flex-direction: column;
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.125rem;
    margin-right: 0.75rem;
  }
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
  }
  &__forward-icon {
    margin: 0.5rem 0 auto auto;
    color: $french-grey;
  }
  &__content-wrapper {
    font-size: 0.9rem;
    line-height: 1.2rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
}
</style>
