<template>
  <previous-experience-answer />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PreviousExperienceAnswer from "@/views/common-signup/questions/risk/previous-experience-answer.vue";

export default defineComponent({
  components: { PreviousExperienceAnswer }
});
</script>
