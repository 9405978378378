<template>
  <div class="navigation">
    <v-btn
      :disabled="leftButtonDisabled"
      icon
      variant="flat"
      class="navigation__button"
      @click="hasSelectedMonth ? $emit('decreaseMonth') : decreaseYear()"
    >
      <icon :icon="['fal', 'chevron-left']" />
    </v-btn>
    <label class="navigation__title">{{ navigationLabel }}</label>
    <v-btn
      :disabled="rightButtonDisabled"
      variant="flat"
      icon
      class="navigation__button"
      @click="hasSelectedMonth ? $emit('increaseMonth') : increaseYear()"
    >
      <icon :icon="['fal', 'chevron-right']" />
    </v-btn>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["decreaseMonth", "increaseMonth", "selectYear", "selectYear", "selectYear"],
  props: {
    years: {
      type: Array as () => number[],
      default: () => []
    },
    month: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    activeYearIndex: 0
  }),
  computed: {
    navigationLabel(): string {
      if (this.month === 0) {
        return this.years[this.activeYearIndex].toString();
      }
      return `${this.$t(`months.${this.month}`)}-${this.years[this.activeYearIndex]
        .toString()
        .slice(2)}`;
    },
    leftButtonDisabled(): boolean {
      if (!this.hasSelectedMonth) {
        return this.activeYearIndex === 0;
      }
      return this.month === 1;
    },
    rightButtonDisabled(): boolean {
      if (!this.hasSelectedMonth) {
        return this.activeYearIndex === this.years.length - 1;
      }
      return this.month === 12;
    },
    hasSelectedMonth(): boolean {
      return this.month !== 0;
    }
  },
  watch: {
    years: {
      immediate: true,
      handler(newValue: number[]) {
        if (newValue.length > 0) {
          this.activeYearIndex = newValue.length - 1;
          this.$emit("selectYear", this.years[this.activeYearIndex]);
        }
      }
    }
  },
  methods: {
    decreaseYear() {
      this.activeYearIndex = Math.max(0, this.activeYearIndex - 1);
      this.$emit("selectYear", this.years[this.activeYearIndex]);
    },
    increaseYear() {
      this.activeYearIndex = Math.min(this.years.length - 1, this.activeYearIndex + 1);
      this.$emit("selectYear", this.years[this.activeYearIndex]);
    }
  }
});
</script>
<style lang="scss" scoped>
.navigation {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__title {
    font-size: 1.375rem;
    text-align: center;
    width: 8rem;
  }
  &__button {
    background-color: transparent;
    font-size: 1.375rem;
    &.focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
      &:before {
        background-color: white;
      }
    }
    :deep(.v-btn__overlay) {
      opacity: 0;
    }
  }
}
</style>
