<template>
  <previous-experience
    :heading="$t('create-portfolio.questions.risk.previous-experience.heading')"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PreviousExperience from "@/views/common-signup/questions/risk/previous-experience.vue";

export default defineComponent({
  components: { PreviousExperience }
});
</script>
