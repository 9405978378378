<template>
  <v-btn-toggle
    v-model="toggleValue"
    class="holding-segment-toggle"
    mandatory="force"
    density="default"
    style="height: 3.375rem !important"
  >
    <header-button
      :icon="['fal', 'tools']"
      :tooltip-text="`${$t('sort_by')} ${$t(
        'portfolio.holdings.holding-segment-toggle.sector'
      ).toLowerCase()}`"
      value="sector"
    />
    <header-button
      :icon="['fal', 'sort-alpha-up']"
      :tooltip-text="`${$t('sort_by')} ${$t('assetType').toLowerCase()}`"
      value="stock"
    />
    <header-button
      :icon="['fal', 'globe-americas']"
      :tooltip-text="`${$t('sort_by')} ${$t(
        'portfolio.holdings.holding-segment-toggle.market'
      ).toLowerCase()}`"
      value="market"
    />
  </v-btn-toggle>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HeaderButton from "@/components/header-button.vue";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    HeaderButton
  },
  data() {
    return {
      toggleValue: 1
    };
  },

  watch: {
    toggleValue: {
      immediate: true,
      handler(newVal: number, _: number) {
        let valToString: string;
        switch (newVal) {
          case 0: {
            valToString = "sector";
            break;
          }
          case 1: {
            valToString = "stock";
            break;
          }
          case 2: {
            valToString = "market";
            break;
          }
          default: {
            valToString = "stock";
            break;
          }
        }
        this.$emit("update:model-value", valToString);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.holding-segment-toggle {
  margin: 0 1px;
}
:deep(.v-btn) {
  border-radius: 50% !important;
}
</style>
