<template>
  <v-select
    :clearable="clearable"
    :model-value="modelValue"
    :items="items"
    :class="[
      'select-list__selector',
      {
        'select-list__selector--disabled': !edit || disabled
      }
    ]"
    :disabled="!edit || disabled"
    :menu-props="{
      contentClass: 'select-list__selector-menu',
      maxHeight: 'auto'
    }"
    variant="underlined"
    :label="label"
    :menu-icon="showAppendIcon && !disabled ? 'fas fa-chevron-down' : ''"
    :rules="rules"
    :prefix="prefix"
    persistent-clear
    @update:model-value="(value: any) => $emit('change', value)"
  />
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

export default defineComponent({
  emits: ["change"],
  props: {
    // This can be either boolean or number, so easier to just not
    // declare type. It doesn't matter for the functionality.
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      required: true
    },
    items: {
      type: Array as () => Array<any>,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    rules: {
      type: Array as PropType<readonly any[]>,
      default: []
    },
    clearable: {
      type: Boolean,
      required: false
    },
    showAppendIcon: {
      type: Boolean,
      default: true
    },
    prefix: {
      type: String,
      required: false
    }
  }
});
</script>

<style lang="scss" scoped>
.select-list {
  &__selector {
    :deep(label) {
      color: #2b2b2b;
      opacity: 63%;
      font-size: 1.2rem;
    }
    &--disabled {
      :deep(.v-field__append-inner) {
        opacity: 0;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-icon.v-icon) {
      font-size: 1rem;
    }
    :deep(.v-label) {
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
    :deep(.v-field__clearable svg) {
      font-size: 1.5rem;
    }
  }
  &__selector-menu {
    border-radius: 0.5rem !important;
    box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.14) !important;
    font-family: $heading-font-family;
  }
}
:deep(.v-list-item__title) {
  color: black !important;
}
</style>
