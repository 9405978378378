<template>
  <div class="savings-display">
    <div class="savings-display__section-wrapper">
      <h3
        class="savings-display__title savings-display__title--total"
        data-jest="portfolio-name"
      >
        {{ portfolioName }}
      </h3>
      <p class="savings-display__value">
        <span
          class="savings-display__value--larger"
          data-jest="total-value"
        >
          {{ totalValueString }} </span
        >&nbsp;
        <span class="savings-display__value--large"> {{ $t("currency") }} </span>
      </p>
    </div>
    <div
      class="savings-display__section-wrapper--clickable"
      @click="clickSavings"
    >
      <div class="savings-display__development">
        <h3 class="savings-display__title savings-display__title--development">
          <span v-if="$vuetify.display.mdAndUp">{{
            $t("portfolio.savings.development_percentage")
          }}</span>
        </h3>
        <percentage-development
          style="font-size: 1.25rem"
          :portfolio-id="activePortfolioId"
          :performance="performance"
        />
      </div>
      <div class="savings-display__development">
        <h3 class="savings-display__title savings-display__title--development">
          <span v-if="$vuetify.display.mdAndUp">
            {{ $t("portfolio.savings.development_currency") }}</span
          >
        </h3>
        <p
          :class="[
            'savings-display__value',
            'savings-display__value--development',
            'savings-display__value--end',
            positiveAbsoluteDevelopment
              ? 'savings-display__value--blue'
              : 'savings-display__value--red'
          ]"
          data-jest="development-percentage"
        >
          {{ absoluteDevelopmentString }} {{ $t("currency") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { OverviewState } from "@/store/overview/types";
import {
  calculateTotalAbsoluteDevelopment,
  formatTotalAbsoluteDevelopment,
  formatTotalPortfolioValue
} from "@/utils/development";
import PercentageDevelopment from "@/components/percentage-development.vue";
import { PerformanceResponse } from "@/clients";

export default defineComponent({
  emits: ["clickSavings"],
  components: {
    PercentageDevelopment
  },
  props: {
    performance: { type: Object as () => PerformanceResponse, required: false }
  },
  computed: {
    ...mapGetters(["portfolioName"]),
    activePortfolioId(): string | undefined {
      return this.$store.state.portfolioStore.activePortfolioBase?.id;
    },
    performanceWithDataPoints(): PerformanceResponse | undefined {
      if (this.performance && Object.keys(this.performance).length > 0) {
        return this.performance;
      }
      return undefined;
    },
    absoluteDevelopmentString(): string {
      if (!this.performanceWithDataPoints) {
        return "-";
      }
      return formatTotalAbsoluteDevelopment(this.performanceWithDataPoints);
    },
    totalValueString(): string {
      if (!this.performance) {
        return "-";
      }
      return formatTotalPortfolioValue(this.performance);
    },
    positiveAbsoluteDevelopment(): boolean {
      if (this.performance) {
        const developmentAbsolute = calculateTotalAbsoluteDevelopment(this.performance);
        if (developmentAbsolute) {
          return developmentAbsolute >= 0;
        }
      }
      return true;
    }
  },
  methods: {
    clickSavings(): any {
      this.$emit("clickSavings");
    }
  }
});
</script>

<style lang="scss" scoped>
.savings-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &__section-wrapper {
    display: flex;
    flex-direction: column;
    &--clickable {
      padding: 0.5rem;
      border-radius: 0.5rem;
      transition: background-color 0.2s ease-in-out;
      &:hover {
        background-color: $whisper;
      }
      cursor: pointer;
    }
  }
  &__development {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 200;
    color: rgba($nearly-black, 0.63);
    &--total {
      grid-row: 1;
      grid-column: 1;
    }
    &--development {
      grid-row: 1;
      grid-column: 2;
      justify-self: flex-end;
    }
  }
  &__value {
    margin: 0;
    line-height: 1.5rem;
    color: $nearly-black;
    grid-row: 2;
    grid-column: 1;
    font-weight: 600;
    font-family: $heading-font-family;
    &--blue {
      color: $grayish-blue;
    }
    &--red {
      color: $deep-red;
    }
    &--large {
      font-size: 1.25rem;
      @include medium-up {
        font-size: 1.5rem;
      }
    }
    &--development {
      grid-row: 2;
      grid-column: 2;
      @include medium-up {
        font-size: 1.3rem;
        margin-bottom: 0.1rem;
      }
    }
    &--larger {
      font-size: 1.875rem;
      @include medium-up {
        font-size: 2.375rem;
      }
    }
    &--end {
      justify-self: flex-end;
      padding-left: 1rem;
    }
  }
}
</style>
