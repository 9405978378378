<template>
  <div id="bankid-container" class="bankid">
    <loading-spinner v-if="loading" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { BankIdAction } from "@/store/bankid/actions";
import { SignLocation, StartMode } from "@/store/bankid/types";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { BankIdMutation } from "@/store/bankid/mutations";

interface SignParameters {
  title: string;
  data: string;
  type: string;
}

export default defineComponent({
  components: { LoadingSpinner },
  props: {
    isLogin: {
      type: Boolean,
      default: undefined
    },
    signLocation: {
      type: Number as () => SignLocation,
      default: undefined
    }
  },
  data: () => ({
    StartMode,
    loading: true
  }),
  computed: {
    signMessage(): string {
      return this.$store.state.bankIdStore.signMessage;
    },
    signTitle(): string {
      return this.$store.state.bankIdStore.signTitle;
    },
    assentlyToken(): string {
      return this.$store.state.bankIdStore.assentlyToken;
    }
  },
  async created() {
    this.loading = true;
    let signParameters: SignParameters | null = null;
    if (!this.isLogin) {
      await this.$store.dispatch(BankIdAction.norwaySignStart, this.$route.path);
      this.$store.commit(BankIdMutation.setSignLocation, this.signLocation);
      this.$store.commit(BankIdMutation.setSignReturnPath, this.$route.path);

      signParameters = {
        title: this.signTitle,
        data: this.signMessage,
        type: "text"
      };
    } else {
      await this.$store.dispatch(BankIdAction.norwayLoginStart);
    }
    this.loading = false;
    const self = this;
    (window as any).coreid_client.init({
      injectIntoNode: document.querySelector("#bankid-container"),
      config: {
        showTitle: false,
        allowedEids: ["no-bankid"], // list enabled eID providers
        mode: this.isLogin ? "auth" : "sign", // sets the mode to authentication
        language: "no", // set the client to use swedish language
        provider: "no-bankid-oidc", // default provider to show on start
        sign: signParameters
      },
      token: this.assentlyToken,
      callback: (data: any) => {
        if (data.success) {
          (window as any).coreid_client.close();
          if (self.isLogin) {
            self.$store.dispatch(BankIdAction.norwayLoginComplete, data.token).then((r: any) => {
              self.$emit("complete");
            });
          }
        } else {
          (window as any).coreid_client.close();
          self.$emit("canceled");
        }
      }
    });

    (window as any).coreid_client.start();
  }
});
</script>
<style lang="scss">
.bankid > iframe {
  min-height: 250px;
}
</style>
<style lang="scss" scoped>
.bankid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3rem;
  height: 100%;
  width: 100%;
}
</style>
