<template>
  <div class="company-info-details">
    <card-header
      class="company-info-details__header"
      :title="$t('companyInfo')"
      :icon="['fal', 'building']"
    >
      <!-- <edit-button :edit="edit" @click="toggleEdit"/> -->
    </card-header>
    <v-form ref="form">
      <v-text-field
        ref="organizationNumberInput"
        v-model="displayOrganizationNumber"
        :class="['company-info-details__input', { 'company-info-details__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('organizationNumber')"
        :rules="[rules.required]"
      />
      <v-text-field
        ref="companyNameInput"
        v-model="displayCompanyName"
        :class="['company-info-details__input', { 'company-info-details__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('companyName')"
      />
      <v-text-field
        ref="companyFormInput"
        v-model="displayCompanyForm"
        :class="['company-info-details__input', { 'company-info-details__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        validate-on="blur"
        :label="$t('company-profile.info.companyForm')"
      />
      <v-text-field
        ref="industryInput"
        v-model="displayIndustry"
        :class="['company-info-details__input', { 'company-info-details__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('company-profile.info.industry')"
        :rules="[rules.required, rules.number]"
      />
      <v-text-field
        ref="employeesInput"
        v-model="displayEmployees"
        :class="['company-info-details__input', { 'company-info-details__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('employees')"
        :rules="[rules.required]"
      />
      <v-text-field
        ref="accountantInput"
        v-model="displayHasAccountant"
        :class="['company-info-details__input', { 'company-info-details__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('company-profile.info.accountant')"
        :rules="[rules.required]"
      />
      <v-text-field
        ref="addressInput"
        v-model="displayAddress"
        :class="['company-info-details__input', { 'company-info-details__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('address')"
        :rules="[rules.required]"
      />
      <v-text-field
        ref="zipCodeInput"
        v-model="displayZipCode"
        :class="['company-info-details__input', { 'company-info-details__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('zipCodeAndCity')"
        :rules="[rules.required]"
      />
    </v-form>
  </div>
</template>
<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { mapGetters } from "vuex";
import { UserMutation } from "@/store/user/mutations";
import CardHeader from "@/components/card-header.vue";
import {
  CompanyEmployees,
  CompanyForm,
  CompanyIndustry,
  CompanyKnowYourCustomerAnswers,
  CompanyRevenue,
  LegalEntity
} from "@/clients";
import { RevenueAlterantive } from "@/types/user";
import { addDashToOrganizationNumber } from "@/utils/formatters";

export default defineComponent({
  components: { CardHeader },
  data: () => ({
    edit: false
  }),
  computed: {
    ...mapGetters(["errorLoadingUser", "editedEconomy"]),
    currentLegalEntity(): LegalEntity {
      return this.$store.state.userStore.currentLegalEntity as LegalEntity;
    },
    kyc(): CompanyKnowYourCustomerAnswers | undefined {
      const legalEntity: LegalEntity = this.$store.state.userStore.currentLegalEntity;
      if (legalEntity) {
        return legalEntity.companyKnowYourCustomerAnswers;
      }
      return undefined;
    },
    aggregatedInputs(): string[] {
      return [
        this.displayOrganizationNumber,
        this.displayCompanyName,
        this.displayCompanyForm,
        this.displayActive,
        this.displayIndustry
      ];
    },
    displayOrganizationNumber: {
      get(): string {
        if (this.organizationNumber) {
          const formatted = addDashToOrganizationNumber(this.organizationNumber as string);
          if (formatted) {
            return formatted;
          }
          return this.organizationNumber as string;
        }
        return "-";
      },
      set(value: string) {
        this.organizationNumber = this.removeWhiteSpace(value);
      }
    },
    organizationNumber: {
      get(): string | undefined {
        return this.currentLegalEntity.organizationNumber;
      },
      set(organizationNumber: string) {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          organizationNumber
        });
      }
    },
    displayCompanyName: {
      get(): string {
        if (this.companyName !== undefined) {
          return this.companyName;
        }
        return "-";
      },
      set(value: string) {
        // value = this.removeWhiteSpace(value);
        // this.companyName = parseInt(value, 10);
      }
    },
    companyName: {
      get(): string | undefined {
        return this.currentLegalEntity.firstName;
      },
      set(companyName: string) {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          companyName
        });
      }
    },
    displayCompanyForm: {
      get(): string {
        if (this.companyForm !== undefined) {
          // TODO: Use enum when company form is stored as enum
          return "Aktiebolag";
        }
        return "-";
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        if (value === "") {
          value = "0";
        }
        this.companyForm = parseInt(value, 10);
      }
    },
    companyForm: {
      get(): CompanyForm | undefined {
        return this.currentLegalEntity.companyKnowYourCustomerAnswers?.companyForm;
      },
      set(hasLongTermDebt: number) {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          hasLongTermDebt
        });
      }
    },
    displayActive: {
      get(): string {
        if (this.active !== undefined) {
          return this.active
            ? this.$t("company-profile.info.isActive")
            : this.$t("company-profile.info.isNotActive");
        }
        return "-";
      },
      set(value: string) {
        // value = this.removeWhiteSpace(value);
        // if (value === "") {
        //   value = "0";
        // }
        // this.active = parseInt(value, 10);
      }
    },
    active: {
      get(): boolean | undefined {
        return this.currentLegalEntity.companyKnowYourCustomerAnswers?.isActive;
      },
      set(active: boolean) {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          active
        });
      }
    },
    displayIndustry: {
      get(): string {
        const industries = [
          {
            industryType: CompanyIndustry.ConstructionInfrastructure,
            text: this.$t("industry.constructionInfrastructure")
          },
          {
            industryType: CompanyIndustry.DataTechnologyIt,
            text: this.$t("industry.dataTechnologyIt")
          },
          { industryType: CompanyIndustry.Maintenance, text: this.$t("industry.maintenance") },
          {
            industryType: CompanyIndustry.EconomyFinance,
            text: this.$t("industry.economyFinance")
          },
          { industryType: CompanyIndustry.RealEstate, text: this.$t("industry.realEstate") },
          {
            industryType: CompanyIndustry.FinancialActivities,
            text: this.$t("industry.financialActivities")
          },
          {
            industryType: CompanyIndustry.ResearchRdScience,
            text: this.$t("industry.researchRdScience")
          },
          { industryType: CompanyIndustry.Sales, text: this.$t("industry.sales") },
          {
            industryType: CompanyIndustry.HotelRestaurantTourism,
            text: this.$t("industry.hotelRestaurantTourism")
          },
          { industryType: CompanyIndustry.StaffHr, text: this.$t("industry.staffHr") },
          {
            industryType: CompanyIndustry.ImportExportTrade,
            text: this.$t("industry.importExportTrade")
          },
          { industryType: CompanyIndustry.Law, text: this.$t("industry.law") },
          {
            industryType: CompanyIndustry.SupportServices,
            text: this.$t("industry.supportServices")
          },
          {
            industryType: CompanyIndustry.AgricultureForestry,
            text: this.$t("industry.agricultureForestry")
          },
          { industryType: CompanyIndustry.Management, text: this.$t("industry.management") },
          {
            industryType: CompanyIndustry.LogisticsTransport,
            text: this.$t("industry.logisticsTransport")
          },
          { industryType: CompanyIndustry.Marketing, text: this.$t("industry.marketing") },
          { industryType: CompanyIndustry.HealthCare, text: this.$t("industry.healthCare") },
          {
            industryType: CompanyIndustry.WriterPublishing,
            text: this.$t("industry.writerPublishing")
          },
          {
            industryType: CompanyIndustry.TheologyPsychologyPhilosophy,
            text: this.$t("industry.theologyPsychologyPhilosophy")
          },
          {
            industryType: CompanyIndustry.SecurityRescueServices,
            text: this.$t("industry.securityRescueServices")
          },
          {
            industryType: CompanyIndustry.ManufacturingProduction,
            text: this.$t("industry.manufacturingProduction")
          },
          { industryType: CompanyIndustry.Education, text: this.$t("industry.education") }
        ];

        if (this.industry !== undefined) {
          const matchingAlt = industries.filter((x) => this.industry?.includes(x.industryType));
          if (matchingAlt) {
            return matchingAlt.map((x) => x.text).join(", ");
          }
        }
        return "-";
      },
      set(value: string) {
        // value = this.removeWhiteSpace(value);
        // if (value === "") {
        //   value = "0";
        // }
        // this.industry = parseInt(value, 10);
      }
    },
    industry: {
      get(): CompanyIndustry[] | undefined {
        const result: CompanyIndustry[] = [];
        const kyc = this.currentLegalEntity.companyKnowYourCustomerAnswers;
        if (kyc?.industry1) result.push(kyc.industry1);
        if (kyc?.industry2) result.push(kyc.industry2);
        if (kyc?.industry3) result.push(kyc.industry3);
        return result;
      },
      set(industry: CompanyIndustry[]) {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          industry
        });
      }
    },
    displayEmployees: {
      get(): string | undefined {
        const employeeAlternatives = [
          {
            text: this.$t("employeeRanges.noEmployees"),
            key: CompanyEmployees.NoEmployees
          },
          {
            text: this.$t("employeeRanges.betweenOneAndFive"),
            key: CompanyEmployees.BetweenOneAndFive
          },
          {
            text: this.$t("employeeRanges.betweenFiveAndTwenty"),
            key: CompanyEmployees.BetweenFiveAndTwenty
          },
          {
            text: this.$t("employeeRanges.betweenTwentyAndFifty"),
            key: CompanyEmployees.BetweenTwentyAndFifty
          },
          {
            text: this.$t("employeeRanges.overFifty"),
            key: CompanyEmployees.OverFifty
          }
        ];

        if (this.employees !== undefined) {
          const matchingAlt = employeeAlternatives.find((x) => x.key === this.employees);
          if (matchingAlt) {
            return matchingAlt.text;
          }
          return undefined;
        }
        return "-";
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        if (value === "") {
          value = "0";
        }
        this.employees = parseInt(value, 10);
      }
    },
    employees: {
      get(): CompanyEmployees | undefined {
        return this.currentLegalEntity.companyKnowYourCustomerAnswers?.employeeCount;
      },
      set(revenue: CompanyEmployees) {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          revenue
        });
      }
    },
    displayHasAccountant: {
      get(): string {
        if (this.hasAccountant !== undefined) {
          return this.hasAccountant ? this.$t("yes") : this.$t("no");
        }
        return "-";
      },
      set(value: string) {
        // value = this.removeWhiteSpace(value);
        // if (value === "") {
        //   value = "0";
        // }
        // this.hasAccountant = parseInt(value, 10);
      }
    },
    hasAccountant: {
      get(): boolean | undefined {
        return this.currentLegalEntity.companyKnowYourCustomerAnswers?.hasAccountant;
      },
      set(hasAccountant: boolean) {
        //     this.$store.commit(UserMutation.editEconomy, {
        //       ...this.editedEconomy,
        //       hasLongTermDebt
        //     });
        //   }
      }
    },
    displayAddress: {
      get(): string | undefined {
        if (this.address !== undefined) {
          return this.address;
        }
        return "-";
      },
      set(value: string) {
        // value = this.removeWhiteSpace(value);
        // if (value === "") {
        //   value = "0";
        // }
        // this.active = parseInt(value, 10);
      }
    },
    address: {
      get(): string | undefined {
        return this.currentLegalEntity.address;
      },
      set(address: string) {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          address
        });
      }
    },
    displayZipCode: {
      get(): string {
        if (this.zipCode && this.city) {
          return `${this.zipCode}, ${this.city}`;
        }
        return "-";
      },
      set(value: string) {
        // value = this.removeWhiteSpace(value);
        // if (value === "") {
        //   value = "0";
        // }
        // this.active = parseInt(value, 10);
      }
    },
    zipCode: {
      get(): string | undefined {
        return this.currentLegalEntity.zipCode;
      },
      set(active: number) {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          active
        });
      }
    },
    city: {
      get(): string | undefined {
        return this.currentLegalEntity.city;
      },
      set(active: string) {
        this.$store.commit(UserMutation.editEconomy, {
          ...this.editedEconomy,
          active
        });
      }
    },
    rules(): any {
      return {
        required: (value: string) => (!!value && value !== "") || this.$t("required"),
        number: (value: string) => {
          if (value) {
            value = value.toString().replace(/\s/g, "");
            const pattern = /^\d+$/;
            return pattern.test(value) || this.$t("notANumber");
          }
          return true;
        }
      };
    }
  },
  watch: {
    // I dont like this at all but i seem to not found any other solution.
    // Need to watch all inputs to resize them and not duplicate same watcher five times to get the suffix to append properly instead of anchored to the right
    aggregatedInputs: {
      immediate: true,
      handler() {
        nextTick().then(() => {
          const refs = [
            "organizationNumberInput",
            "companyNameInput",
            "activeInput",
            "industryInput",
            "companyFormInput"
          ];
          refs.forEach((r: string) => {
            this.resizeInputToCharacters((this.$refs[r] as any).$el.querySelector("input"));
          });
        });
      }
    }
  },

  methods: {
    resizeInputToCharacters(input: HTMLInputElement | null): void {
      if (input) {
        input.style.width = `${input.value.length}ch`;
      }
    },
    removeWhiteSpace(value: string): string {
      return value.toString().replace(/\s/g, "");
    }
  }
});
</script>
<style lang="scss" scoped>
.company-info-details {
  padding: 1.5rem 1.5rem 1.625rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__description {
    padding: 0.5rem 0;
  }
  &__input {
    color: black;

    :deep(.v-text-field__slot) {
      max-width: 100%;
    }
    :deep(input) {
      min-width: unset;
      flex: unset;
      max-width: 100%;
    }
    :deep(.v-text-field__suffix) {
      flex: 1;
      text-align: left;
      cursor: text;
    }
    :deep(.v-text-field__prefix) {
      align-self: flex-end;
    }

    :deep(label) {
      color: #707070 !important;
      font-size: 1.2rem;
      font-weight: 300;
    }
    :deep(.v-text-field) {
      margin-top: 0;
    }
    &--bottom {
      grid-column: 1 / 3;
      grid-row: 3;
    }
    &--disabled {
      :deep(.v-text-field__suffix) {
        color: inherit !important;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-label) {
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
  }
}
</style>
