<template>
  <div class="login">
    <div class="login__logo-container">
      <div class="welcome__logo-wrapper">
        <main-logo :tied-agent="tiedAgent" />
      </div>
    </div>
    <p
      v-if="bankIdAuthComplete"
      class="login__login-text"
    >
      {{ $t("loggingIn") }}
    </p>
    <template v-if="loginErrorStatusStrings">
      <div class="login__noaccount">
        <p class="login__noaccount--header">
          {{ loginErrorStatusStrings.header }}
        </p>
        <p class="login__noaccount--body">
          {{ loginErrorStatusStrings.body }}
        </p>
        <common-button @click="resetComponent">
          {{ loginFailedBackButtonText }}
        </common-button>
      </div>
    </template>

    <login-no
      v-if="locale === 'no' && !loginErrorStatusStrings"
      @authComplete="authComplete"
    />
    <login-se
      v-else-if="!loginErrorStatusStrings"
      :button-color="tiedAgentColor"
      @authComplete="authComplete"
      @authCancel="authCancel"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { softBlue } from "@/styles/colors";
import { LoginStatus, TiedAgentsClient, TiedAgent } from "@/clients";
import { StartMode } from "@/store/bankid/types";
import { HIDE_BANNER_KEY, HIDE_SIGN_AGREEMENTS_DIALOG_KEY } from "@/config/general";
import { trackEvent } from "@/clients/segment";
import CommonButton from "@/components/button.vue";
import { UserMutation } from "@/store/user/mutations";
import { UserAction } from "@/store/user/action-definitions";
import { OverviewActions } from "@/store/overview/actions";
import { TokenUserInfo } from "@/types/user";
import { baseUrl } from "@/clients/config";
import MainLogo from "@/components/main-logo.vue";
import store from "@/store";
import LoginSe from "./login-se.vue";
import LoginNo from "./login-no.vue";

const tiedAgentClient = new TiedAgentsClient(baseUrl);

export default defineComponent({
  components: {
    MainLogo,
    CommonButton,
    LoginSe,
    LoginNo
  },
  head(): any {
    return {
      title: this.$t("login")
    };
  },
  async beforeRouteEnter(to, from, next) {
    const { tiedAgent } = to.query;
    if (tiedAgent) {
      const tiedAgentInfo = await tiedAgentClient.getTiedAgent(tiedAgent as string);
      store.commit(UserMutation.setTiedAgent, tiedAgentInfo);
      store.commit(UserMutation.setPrimaryColor, tiedAgentInfo?.color as string);
    }
    next();
  },
  data(): any {
    return {
      softBlue,
      buttonColor: softBlue,

      loading: false,
      authFailure: null as string | null,
      StartMode,
      bankIdAuthComplete: false,
      logoUrl: new URL("/assets/images/logos/sigmastocks-logo-text.svg", import.meta.url).href
    };
  },
  computed: {
    locale(): string {
      return this.$store.state.userStore.locale;
    },
    tiedAgent(): TiedAgent {
      return this.$store.state.userStore.tiedAgent;
    },
    tiedAgentColor(): string | undefined {
      return this.$store.state.userStore.tiedAgent?.color;
    },
    loginErrorStatusStrings(): any {
      const { loginErrorStatus } = this.$store.state.userStore;
      if (loginErrorStatus) {
        if (loginErrorStatus === LoginStatus.NoAccount) {
          return {
            header: this.$t("noAccountHeader"),
            body: this.$t("noAccountBody")
          };
        }
        if (loginErrorStatus === LoginStatus.NotAllowed) {
          return {
            header: this.$t("notAllowedHeader"),
            body: this.$t("notAllowedBody")
          };
        }
        if (loginErrorStatus === LoginStatus.Processing) {
          return {
            header: this.$t("processingHeader"),
            body: this.$t("processingBody")
          };
        }
        if (loginErrorStatus === LoginStatus.HasNotSigned) {
          return {
            header: this.$t("notSignedHeader"),
            body: this.$t("notSignedBody")
          };
        }
      }
      return loginErrorStatus;
    },
    loginFailedBackButtonText(): string {
      const { loginErrorStatus } = this.$store.state.userStore;
      if (
        loginErrorStatus === LoginStatus.NotAllowed ||
        loginErrorStatus === LoginStatus.Processing
      ) {
        return this.$t("back");
      }
      return this.$t("tryAgain");
    },
    isBankIdDev(): boolean {
      return import.meta.env.VITE_ENV !== "production";
    }
  },
  async created(): Promise<any> {
    const { isLoggedIn } = this.$store.getters;
    if (isLoggedIn(this.$store.state) === true) {
      this.$router.replace({ name: "choose-legal-entity", query: this.$route.query });
    }
  },
  methods: {
    goToHomepage() {
      window.open("https://sigmastocks.com", "_blank");
    },
    async authComplete(): Promise<void> {
      if (!this.errorStatusStrings) {
        this.bankIdAuthComplete = true;
        const tokenUserInfo: TokenUserInfo = this.$store.getters.tokenUserInfo();
        this.$store.dispatch(OverviewActions.loadPerformances, {
          legalEntitiesPortfolioIds: tokenUserInfo.legalEntitiesPortfolioIds,
          forceReload: true
        });
        await this.$store.dispatch(UserAction.loadCurrentUser);
        trackEvent("login", { method: "bankid" });
        sessionStorage.removeItem(HIDE_BANNER_KEY);
        sessionStorage.removeItem(HIDE_SIGN_AGREEMENTS_DIALOG_KEY);
        this.$router.replace({ name: "choose-legal-entity", query: this.$route.query });
      }
      this.loading = false;
    },
    authCancel(): void {
      this.bankIdAuthComplete = false;
      this.loading = false;
    },
    authFailed(): void {
      this.bankIdAuthComplete = false;
      this.loading = false;
      this.authFailure = this.$t("authFailed");
    },
    resetComponent(): void {
      this.$store.commit(UserMutation.setLoginErrorStatus, undefined);
      this.authCancel();
    }
  }
});
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 2.5625rem;
  align-items: center;
  text-align: center;
  height: 100%;
  @include small-up {
    padding-top: 5rem;
  }
  &__button {
    margin-bottom: 0.75rem;
  }
  &__loading {
    display: flex;
    justify-content: center;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__logo-container {
    width: 100%;
    text-align: center;
    height: 4.0625rem;
  }
  &__logo {
    path {
      fill: $soft-blue;
      opacity: 1;
    }
  }
  &__text {
    font-size: 1.5rem;
    font-weight: 600;
    color: rgba($nearly-black, 0.8);
    padding-bottom: 1.5rem;
  }

  &__checkbox {
    &--text {
      color: $nearly-black;
    }
  }
  &__actions-container {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  &__signup-button {
    text-transform: none !important;
    color: $nearly-black;
    font-weight: 300;
    font-size: 1rem;
    font-family: $body-font-family;
    padding-top: 0.5rem;
  }
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    height: 100%;
  }
  &__link-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    @include small-up {
      padding-bottom: 8rem;
    }
  }
  &__create-account-text {
    margin: auto;
    max-width: 18rem;
  }
  &__link {
    text-decoration: underline;
  }
  &__error {
    color: red;
    text-align: center;
    font-size: 0.9rem;
    margin-top: -0.3rem;
  }
  &__noaccount {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    &--header {
      font-weight: bold;
      font-size: 1.125rem;
      @include medium-up {
        font-size: 1.375rem;
      }
    }
    &--body {
      width: 14rem;
      text-align: center;
      font-size: 1rem;
      @include medium-up {
        width: 24rem;
        font-size: 1.125rem;
      }
    }
  }
  &__login-text {
    padding-top: 2rem;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
