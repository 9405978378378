<template>
  <v-list class="settings-list">
    <v-list-item @click="() => $emit('click:terminateAccount')">
      <template v-slot:prepend>
        <div>
          <icon
            fixed-width
            :icon="['fal', 'trash-alt']"
            size="lg"
          />
        </div>
      </template>
      <v-list-item-title>{{ $t("terminateAccount") }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({ emits: ["click:terminateAccount"] });
</script>
<style lang="scss" scoped>
.settings-list {
  min-width: 15.625rem;
}
:deep(.v-list-item) {
  padding: 0 1.75rem;
}
</style>
