<script setup lang="ts">
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import type { InfoSectionMultiple } from "@/types";

defineProps<{ modelValue: boolean; infoSections: InfoSectionMultiple[]; title: string }>();
defineEmits(["update:model-value"]);

function isLastBullet(bullets: string[], currentIndex: number) {
  if (bullets.length < currentIndex)
    return false;

  const currentIsBullet = bullets[currentIndex].startsWith("·");
  if (currentIndex === bullets.length - 1 && currentIsBullet) {
    return true;
  }
  return currentIsBullet && !bullets[currentIndex + 1].startsWith("·");
}
</script>

<template>
  <SideDialog
    :model-value="modelValue"
    :title="title"
    show-back-button
    @update:model-value="(e) => $emit('update:model-value', e)"
    @back="$emit('update:model-value', false)"
  >
    <div class="side-dialog">
      <div
        v-for="(section, i) in infoSections"
        :key="i"
      >
        <h2 class="side-dialog__heading">
          {{ section.heading }}
        </h2>
        <template
          v-for="(subSection, i) in section.subSections"
          :key="i"
        >
          <h2
            v-if="subSection.subHeading"
            class="side-dialog__subHeading"
          >
            {{ subSection.subHeading }}
          </h2>
          <template
            v-for="(text, i) in subSection.texts"
            :key="i"
          >
            <template v-if="text.startsWith('·')">
              <div
                class="side-dialog__text"
                :style="{ marginBottom: isLastBullet(subSection.texts!, i) ? '1rem' : '0' }"
              >
                {{ text }}
              </div>
            </template>
            <p
              v-else
              class="side-dialog__text"
            >
              {{ text }}
            </p>
          </template>
        </template>
      </div>
      <div
        style="text-align: center; padding: 2rem;"
      >
        <CommonButton
          size-large
          @click="$emit('update:model-value', false)"
        >
          {{ $t("ok") }}
        </CommonButton>
      </div>
    </div>
  </SideDialog>
</template>

<style lang="scss" scoped>
.side-dialog {
  padding: 1.2rem;
  font-family: sofia-pro;
  font-weight: 300;
  &__heading {
    font-size: 1.125rem;
    font-weight: 500;
  }
  &__subHeading {
    font-size: 1rem;
    font-weight: 500;
  }
  &__description {
    font-weight: 300;
  }
}
</style>
