<template>
  <SideDialog
    :show-back-button="false"
    :model-value="modelValue"
    :title="$t('agreementsTitle')"
    data-jest="dialog"
    @update:model-value="(value) => {
      $emit('update:model-value', value);
    }
    "
  >
    <div class="preview-agreements">
      <div>
        <v-expansion-panels
          v-model="portfolioPanels"
          :multiple="true"
          variant="accordion"
        >
          <v-expansion-panel
            v-for="(portfolio, key) in agreementsToSign"
            :key="key"
            elevation="0"
          >
            <v-expansion-panel-title
              expand-icon="fas fa-chevron-down"
            >
              <span class="preview-agreements__portfolio-header-text">{{ portfolio.name }}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-expansion-panels
                :multiple="true"
                variant="accordion"
              >
                <v-expansion-panel
                  v-for="(ownerAgreements, key2) in portfolio.ownerWithAgreements"
                  :key="key2"
                  :model-value="true"
                  elevation="0"
                >
                  <v-expansion-panel-title
                    expand-icon="fas fa-chevron-down"
                    class="preview-agreements__archive-header-icon"
                  >
                    <span class="preview-agreements__company-header-text">{{
                      ownerAgreements.pdfOwnerName
                    }}</span>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="pe-side-dialog__paragraph">
                    <div
                      v-for="(pdf, pdfKey) in ownerAgreements.agreements"
                      :key="pdfKey"
                      class="preview-agreements__row"
                      @click="loadPdf(pdf, portfolio, pdfKey)"
                    >
                      <div>{{ pdf.displayName }}</div>
                      <div class="preview-agreements__icon">
                        <LoadingSpinner
                          v-if="isLoading[pdfKey]"
                          data-jest="loading-spinner"
                          class="preview-agreements__loading"
                        />
                        <icon
                          v-else
                          fixed-width
                          :icon="['fal', 'arrow-to-bottom']"
                        />
                      </div>
                    </div>
                    <a
                      v-for="pdf in ownerAgreements.staticAgreements"
                      :key="pdf.path"
                      :href="baseUrl + encodeURI(pdf.path ? pdf.path : '')"
                      class="preview-agreements__row preview-agreements__row--no-hyperlink"
                      target="_blank"
                    >{{ pdf.name }}</a>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </SideDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import type { Agreement, GroupedPortfolioAgreements } from "@/clients";
import { UserAction } from "@/store/user/action-definitions";
import { baseUrl } from "@/clients/config";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";

export default defineComponent({
  components: { SideDialog, LoadingSpinner },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:model-value"],
  data: () => ({
    baseUrl,
    panels: [],
    portfolioPanels: [] as number[],
  }),
  computed: {
    isLoading(): Array<boolean> {
      const { loadingPdf } = this.$store.state.userStore;
      return loadingPdf;
    },
    agreementsToSign(): GroupedPortfolioAgreements[] {
      if (this.$store.state.userStore.agreementsToSign) {
        return this.$store.state.userStore.agreementsToSign.portfolioAgreements;
      }
      return [];
    },
  },
  created() {
    this.portfolioPanels = [];
    for (let i = 0; i < this.agreementsToSign.length; i += 1) {
      this.portfolioPanels.push(i);
    }
  },
  methods: {
    loadPdf(pdf: Agreement, portfolio: GroupedPortfolioAgreements, pdfId: number) {
      this.$store.dispatch(UserAction.getPreviewDocument, {
        pdfType: pdf.pdfType,
        pdfId,
        portfolioExternalReference: portfolio.externalReference,
      });
    },
  },
});
</script>

<style
  lang="scss"
  scoped
>
.preview-agreements {
  display: flex;
  flex-direction: column;
  padding: 2rem;

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  &__portfolio-header-text {
    font-weight: bold;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 0 1.5rem;
    cursor: pointer;

    &--no-hyperlink {
      text-decoration: none;
      color: inherit !important;
    }
  }

  &__icon {
    margin-left: 0.6rem;
  }

  &__panel {
    font-weight: bold;

    &--header-icon {
      font-size: 0.8rem !important;
    }

    &-header-text {
      font-weight: bold;
    }
  }

  &__loading {
    height: 1rem !important;
    width: 1rem !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0 0rem !important;
}

:deep(.v-expansion-panel-title__overlay) {
  opacity: 0 !important;
}
</style>
