<template>
  <div class="holdings">
    <v-card v-if="$vuetify.display.smAndDown">
      <savings
        class="holdings__card"
        :performance="performance"
      />
      <v-divider />
      <div class="segment holdings__card">
        <span class="segment__label">
          {{ $t("sort_by") }}
        </span>
        <holding-segment-toggle @update:model-value="(val) => (holdingSortBy = val)" />
      </div>
    </v-card>
    <section-header
      v-else
      :header="$t('holding')"
      :sub-header="$store.getters.portfolioName || ''"
      :alternatives="alternatives"
      data-jest="section-header"
    >
      <template #icon>
        <icon :icon="['fal', 'briefcase']" />
      </template>
      <holding-segment-toggle @update:model-value="(val) => (holdingSortBy = val)" />
    </section-header>
    <div
      v-if="!holdingsError"
      class="holdings__list-wrapper"
    >
      <holdings-list
        :holding-sort-by="holdingSortBy"
        data-jest="holdings-list"
      />
      <empty-holding-overlay v-if="$vuetify.display.smAndUp" />
    </div>
    <div
      v-else
      class="holdings__list-wrapper holdings__error"
    >
      <error-loading :body="$t('portfolio.holdings.holdings.cannotShowHoldings')" />
    </div>
    <empty-holding-overlay v-if="$vuetify.display.smAndDown && !holdingsError" />
    <loading-spinner-overlay :model-value="showLoadingSpinner" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import SectionHeader from "@/components/section-header.vue";
import ErrorLoading from "@/components/error-loading.vue";
import Savings from "../savings.vue";
import HoldingsList from "./holdings-list.vue";
import EmptyHoldingOverlay from "./empty-holding-overlay.vue";
import HoldingSegmentToggle from "./holding-segment-toggle.vue";
import { OverviewState } from "@/store/overview/types";

export default defineComponent({
  components: {
    Savings,
    HoldingsList,
    SectionHeader,
    HoldingSegmentToggle,
    EmptyHoldingOverlay,
    LoadingSpinnerOverlay,
    ErrorLoading
  },
  data() {
    return { holdingSortBy: "stock" };
  },
  computed: {
    ...mapGetters(["holdingsError"]),
    performance(): any {
      const portfolioId = this.$store.state.portfolioStore.activePortfolioBase.id;
      const performance = (this.$store.state.overviewStore as OverviewState).portfolioPerformances[
        portfolioId
      ];
      return performance;
    },
    alternatives() {
      return [
        {
          key: "holdings-list"
        }
      ];
    },
    showLoadingSpinner(): boolean {
      const { isLoadingHoldingsSummary } = this.$store.state.portfolioStore;
      return isLoadingHoldingsSummary;
    }
  }
});
</script>

<style lang="scss" scoped>
.holdings {
  display: grid;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  position: relative;
  @include medium-up {
    grid-row-gap: 2rem;
  }
  &__card {
    padding: 0.75rem 1.25rem !important;
  }
  &__list-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }
  &__error {
    margin-top: 2rem;
    @include medium-up {
      margin-top: 0rem;
    }
  }
}
.segment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0 0 0;
  &__label {
    font-family: $heading-font-family;
    font-size: 0.875rem;
    font-weight: 300;
  }
}
</style>
