<template>
  <div class="goal">
    <icon
      fixed-width
      :icon="['fal', 'trophy']"
      class="goal__icon"
    />
    <h2 class="goal__title">
      {{ $t("portfolio.overview.savings-goal.goal.savings_goal") }}
    </h2>
    <template v-if="savingsGoal">
      <span
        class="goal__sum"
        data-jest="sum"
      >
        {{ sum }}
      </span>
      <chart
        class="goal__chart"
        :fraction="animatedFraction"
      />
    </template>

    <p
      v-else
      class="goal__add-goal-text"
    >
      {{ $t("portfolio.overview.savings-goal.goal.addGoal") }}
      <icon
        fixed-width
        :icon="['fas', 'chevron-right']"
        class="goal__add-goal-icon"
      />
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { SavingsGoal } from "@/clients";
import SideDialog from "./side-dialog.vue";
import Chart from "./goal-chart.vue";

export default defineComponent({
  components: { Chart, SideDialog },
  data() {
    return {
      animatedFraction: undefined as undefined | number
    };
  },
  computed: {
    savingsGoal(): SavingsGoal {
      const { savingsGoal } = this.$store.state.portfolioStore;
      return savingsGoal;
    },
    sum(): string {
      const translatedCurrency = this.$t("currency");
      if (!this.savingsGoal) {
        return `- ${translatedCurrency}`;
      }
      const percentage = this.savingsGoal.goalAmount;
      const formattedSavingsGoal = Math.round(percentage).toLocaleString("sv-SE");
      return `${formattedSavingsGoal} ${translatedCurrency}`;
    },
    ...mapGetters(["goalFraction"])
  },
  watch: {
    goalFraction: {
      immediate: true,
      handler(newVal: number | undefined, oldVal: number | undefined) {
        this.animatedFraction = this.animatedFraction || 0;
        (this as any).$animejs({
          targets: this,
          animatedFraction: newVal || 0,
          round: 1000,
          duration: 300,
          easing: "easeInOutSine"
        });
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.goal {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: min-content auto;
  height: 100%;
  row-gap: 1.25rem;
  align-items: center;
  &__add-goal-text {
    grid-column: 1 / 4;
    // color: $nearly-black;
    justify-self: center;
    font-weight: 300;
    font-family: $heading-font-family;
    text-transform: none;
    font-size: 1.125rem;
  }
  &__add-goal-icon {
    font-size: 0.9rem;
    margin-left: 0.5rem;
    color: $french-grey;
  }
  &__chart {
    grid-column: 1 / 4;
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.125rem;
    margin-right: 0.75rem;
  }
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
  }
  &__sum {
    font-family: $heading-font-family;
    place-self: flex-end;
    font-weight: 500;
    font-size: 1.125rem;
    white-space: nowrap;
  }
}
</style>
