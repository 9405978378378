<template>
  <div class="risk-level">
    <savings-header
      :title="$t('riskLevel')"
      :icon="['fal', 'heart-rate']"
    >
      <template
        v-if="!isLockedBecauseOfEconomy"
        #action
      >
        <edit-button
          :disabled="disabled"
          :edit="edit"
          data-cy="editRiskLevelButton"
          @click="toggleEdit"
        />
      </template>
    </savings-header>
    <div class="risk-level__body-wrapper">
      <div class="risk-level__picker-wrapper">
        <div
          class="risk-level__risk-level-text"
          data-jest="risk-level-text"
        >
          {{ $t("level") }} {{ editedRiskNumber !== 0 ? editedRiskNumber : "-" }}
        </div>
        <div
          v-if="!edit"
          class="risk-level__risk-level-sub-text"
          data-jest="risk-level-sub-text"
        >
          {{ formatRiskLevel(editedRisk) }}
        </div>
        <v-select
          v-else
          v-model="editedRiskNumber"
          class="risk-level__select"
          :items="items"
          variant="underlined"
          menu-icon="fas fa-chevron-down"
          :item-props="true"
          hide-details
          :placeholder="formatRiskLevel(editedRisk)"
          data-cy="riskLevelSelect"
        >
          <template #selection="{ item }">
            <span>{{ item.props.text }}</span>
          </template>
          <template #item="{ props, index }">
            <v-list-item
              :data-cy="`riskLevelSelectItem${index}`"
              v-bind="props"
            >{{ $t("level") }} {{ props.value }}: {{ props.text }}</v-list-item>
          </template>
        </v-select>
      </div>
      <div
        class="risk-level__disclaimer-text"
        data-jest="risk-disclaimer-text"
      >
        {{ formattedRiskDisclaimer }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { Strategy, RiskLevel } from "@/clients";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import {
  calculateRisk,
  MAXIMUM_RISK_LEVEL,
  MINIMUM_RISK_LEVEL,
  numberToRiskLevelKey
} from "@/config/risk-levels";
import EditButton from "@/components/edit-button.vue";
import SavingsHeader from "./header.vue";

interface RiskLevelAlternative {
  title: string;
  text: string;
  value: RiskLevel;
}

export default defineComponent({
  emits: ["save"],
  components: { EditButton, SavingsHeader },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    edit: false,
    items: [] as Array<RiskLevelAlternative>
  }),
  computed: {
    minimumPortfolioRiskLevelKey(): RiskLevel {
      return MINIMUM_RISK_LEVEL as RiskLevel;
    },
    maximumPortfolioRiskLevelKey(): RiskLevel {
      return MAXIMUM_RISK_LEVEL as RiskLevel;
    },
    buttonText(): string {
      return this.edit ? this.$t("save") : this.$t("change_risk_level");
    },
    formattedRiskDisclaimer(): string {
      if (this.recommendedRisk) {
        return `${this.$t("level")} ${this.recommendedRisk as number} ${this.$t(
          "portfolio-settings.risk-level.continuedDisclaimer"
        )}`;
      }
      return "-";
    },
    editedStrategy(): Strategy | undefined {
      return this.$store.state.portfolioStore.editedStrategy;
    },
    recommendedRisk(): RiskLevel {
      if (this.editedStrategy) {
        return this.editedStrategy.recommendedRiskLevel;
      }
      return RiskLevel.Invalid;
    },
    editedRiskNumber: {
      get(): number {
        return this.editedRisk as number;
      },
      set(level: number) {
        this.editedRisk = numberToRiskLevelKey(level);
      }
    },
    editedRisk: {
      get(): RiskLevel {
        if (this.editedStrategy) {
          return this.editedStrategy.chosenRiskLevel;
        }
        return RiskLevel.Invalid;
      },
      set(level: RiskLevel) {
        this.$store.commit(PortfolioMutation.editChosenRiskLevel, level);
      }
    },
    isLockedBecauseOfEconomy(): boolean {
      if (this.$store.state.userStore.currentLegalEntity) {
        return this.$store.state.userStore.currentLegalEntity.isLockedBecauseOfEconomy;
      }
      return false;
    }
  },
  created() {
    // MINIMUM_RISK_LEVEL is read only that's why copy of variable is needed.
    const minimumRiskLevel = MINIMUM_RISK_LEVEL;
    for (let index = minimumRiskLevel; index <= MAXIMUM_RISK_LEVEL; index += 1) {
      const convertedNumber = numberToRiskLevelKey(index);
      if (convertedNumber !== 0) {
        const formattedRiskLevel = this.formatRiskLevel(numberToRiskLevelKey(index));
        // Dummy title prop because Vuetify tries to render it even though we replace
        // the #item slot. If we place the text we want in title, it will render the text
        // twice for some reason.
        this.items.push({ title: "", text: formattedRiskLevel, value: convertedNumber });
      }
    }
  },
  methods: {
    formatRiskLevel(riskLevel: RiskLevel) {
      if (riskLevel !== RiskLevel.Invalid) {
        const { interestFraction, stocksFraction } = calculateRisk(riskLevel);
        if (!Number.isNaN(stocksFraction) && !Number.isNaN(interestFraction)) {
          return `${Math.round(stocksFraction * 100)} % ${this.$t("stocksLower")}, ${Math.round(
            interestFraction * 100
          )} % ${this.$t("interests")}`;
        }
      }
      return "-";
    },
    async toggleEdit() {
      if (this.edit) {
        if (this.editedRisk !== this.$store.state.portfolioStore.strategy.chosenRiskLevel) {
          this.$emit("save", { riskLevel: this.editedRisk });
        }
      } else {
        this.$store.commit(PortfolioMutation.increaseUnsavedEdits);
      }
      this.edit = !this.edit;
    },
    reset() {
      const { strategy } = this.$store.state.portfolioStore;
      this.$store.commit(PortfolioMutation.editChosenRiskLevel, strategy.chosenRiskLevel);
    }
  }
});
</script>

<style lang="scss">
.risk-level {
  padding: 1.5rem 1.5rem 1.625rem;

  &__body-wrapper {
    margin-top: 1.125rem;
    display: grid;
    text-align: center;
  }

  &__picker-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;
  }

  &__select {
    padding-top: 0;
    max-width: 19.25rem;
    font-size: 1rem;
    margin-top: -0.3rem;

    .v-icon.v-icon {
      font-size: 1rem;
    }
  }

  &__select-menu {
    border-radius: 0.5rem !important;
    box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.14) !important;
    font-size: 1rem;
  }

  &__risk-level-text {
    font-size: 1rem;
    user-select: none;
    margin-right: 0.5rem;
    min-width: 3rem;
    text-align: left;
    align-self: end;
  }

  &__risk-level-sub-text {
    font-weight: 300;
  }

  &__disclaimer-text {
    text-align: left;
    font-size: 0.875rem;
    font-weight: 300;
    color: rgba($nearly-black, 0.63);
  }
}
</style>
