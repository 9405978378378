<template>
  <v-dialog
    persistent
    content-class="validation"
    :model-value="modelValue"
  >
    <v-card class="validation-dialog__card">
      <legal-entity-validation-change
        :validation-action="validationAction"
        :portfolios="portfolios"
        :signing-complete="signingComplete"
        @update:model-value="close"
      />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { LegalEntityValidationAction, PortfolioRecommendedRiskLevel } from "@/clients";
import LegalEntityValidationChange from "@/components/legal-entity-validation-change.vue";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    LegalEntityValidationChange
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    validationAction: {
      type: Number,
      required: true,
      default: LegalEntityValidationAction.NoChange
    },
    portfolios: {
      type: Array as () => PortfolioRecommendedRiskLevel[],
      required: true,
      default: () => [] as PortfolioRecommendedRiskLevel[]
    },
    signingComplete: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    close(e: any): void {
      nextTick().then(() => {
        this.$emit("update:model-value", false);
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.validation-dialog {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  &__card {
    min-height: 25rem;
    @include small-up {
      min-height: 22rem;
    }
  }
}
:deep(.validation) {
  max-width: 30rem;
}
</style>
