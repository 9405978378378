<template>
  <div class="months-overview">
    <v-list>
      <v-list-item
        v-for="month in data"
        :key="month.key"
        class="months-overview__list-item"
        @click="() => $emit('selectMonth', month.key)"
      >
        <bar-chart-list-item
          :text="month.label"
          :data-label="`${month.amount.toLocaleString('sv-SE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })} ${$t('currency')}`"
          :relative-value="month.relativeValue"
          class="months-overview__bar-wrapper"
        />
      </v-list-item>
    </v-list>
    <div class="months-overview__total">
      <p>
        {{ $t("total") }}
      </p>
      <p>{{ toLocaleNumber(totalDividend) }} {{ $t("currency") }}</p>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { BarChartItem } from "@/types";
import BarChartListItem from "../../bar-chart-list-item.vue";

export default defineComponent({
	emits: ["selectMonth"],
  components: { BarChartListItem },
  props: {
    data: {
      type: Array as () => BarChartItem[],
      default: () => []
    },
    year: {
      type: Number,
      required: true,
      default: -1
    }
  },
  computed: {
    totalDividend(): number {
      if (this.data.length > 0) {
        return this.data.reduce((sum, current) => sum + current.amount, 0);
      }
      return 0;
    }
  },
  methods: {
    toLocaleNumber(value: number): string {
      if (value) {
        return value.toLocaleString("sv-SE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        });
      }
      if (value === 0) {
        return value.toString();
      }
      return "";
    }
  }
});
</script>
<style lang="scss" scoped>
.months-overview {
  &__list-item {
    border-radius: 0.5rem;
    padding: 0 0.5rem 0.3rem 0.5rem;
    &::before {
      border-radius: 0.5rem;
    }
  }
  &__total {
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    &--light {
      font-weight: 200;
    }
  }
  &__bar-wrapper {
    width: 100%;
  }
}
</style>
