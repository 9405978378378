<template>
  <div class="month-dividend">
    <div v-if="totalDividend > 0">
      <v-data-table
        :headers="headers"
        :items="data"
        :items-per-page="data.length"
        mobile-breakpoint="0"
      >
        <template #bottom />
        <template #item="props">
          <tr class="month-dividend__table-header-row">
            <td class="month-dividend__flag-container">
              <img :src="getFlagUrl(props.item.marketPlace)">
            </td>
            <td class="month-dividend__table-data">
              {{ props.item.instrumentName || "Okänd" }}
            </td>
            <td class="month-dividend__table-data--right-aligned">
              {{ toLocaleNumber(props.item.dividendAmount) }} {{ $t("currency") }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider />
      <div class="month-dividend__total">
        <p>
          {{ $t("total") }}
        </p>
        <p>{{ toLocaleNumber(totalDividend) }} {{ $t("currency") }}</p>
      </div>
    </div>
    <div
      v-else
      class="month-dividend__no-dividend-text"
    >
      <p>{{ $t("portfolio.overview.dividend.month-dividends.noDividend") }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { Dividend } from "@/clients";
import { MarketPlace } from "@/clients";

export default defineComponent({
  props: {
    data: {
      type: Array as () => Dividend[],
      default: () => [],
    },
  },
  data() {
    return { MarketPlace };
  },
  computed: {
    totalDividend(): number {
      if (this.data.length > 0) {
        return this.data.reduce((sum, current) => sum + current.dividendAmount, 0);
      }
      return 0;
    },
    headers(): any[] {
      return [
        {
          title: "",
          sortable: false,
          class: "month-dividend__table-header",
        },
        {
          title: this.$t("portfolio.overview.dividend.month-dividends.company"),
          value: "instrumentName",
          sortable: false,
          class: "month-dividend__table-header",
        },
        {
          title: this.$t("amount"),
          value: "dividendAmount",
          sortable: false,
          align: "end",
          class: "month-dividend__table-header",
        },
      ];
    },
  },
  methods: {
    toLocaleNumber(value: number): string {
      if (value) {
        return value.toLocaleString("sv-SE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
      }
      if (value === 0) {
        return value.toString();
      }
      return "";
    },

    getFlagName(marketPlace: MarketPlace): string {
      // Fallback on "Unknown" flag name.
      const marketPlaceKey = MarketPlace[marketPlace] ?? MarketPlace[0];
      return marketPlaceKey.toLowerCase();
    },

    getFlagUrl(marketPlace: MarketPlace): string {
      const flagName = this.getFlagName(marketPlace);
      return new URL(`/assets/images/flags/flag-${flagName}.svg`, import.meta.url).href;
    },
  },
});
</script>

<style lang="scss" scoped>
.month-dividend {
  &__total {
    padding: 1rem 0 0 1.6rem;
    display: flex;
    justify-content: space-between;
    &--light {
      font-weight: 200;
    }
  }

  &__table-data {
    font-weight: 300;
    &--right-aligned {
      text-align: right;
    }
  }

  &__table-header-row {
    &:hover {
      background: transparent !important;
    }
  }

  &__flag-container {
    padding-top: 0.4rem !important;
  }

  &__no-dividend-text {
    padding: 2rem;
    text-align: center;
    font-weight: 300;
    font-size: 1rem;
  }

  :deep(&__table-header) {
    font-weight: 300;
    &:nth-child(1) {
      width: 7%;
    }
    &:nth-child(2) {
      width: 55%;
    }
    &:nth-child(3) {
      width: 10%;
    }
  }

  :deep(.v-data-table td) {
    padding: 0;
    font-size: 0.875rem;
  }

  :deep(.v-data-table th) {
    padding: 0;
    font-size: 0.875rem;
  }

  :deep(.text-end) {
    text-align: right !important;
  }
}
</style>
