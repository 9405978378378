import { getFirstPortfolioRequestBase } from "./register-user-parameters";
import { paiFromState } from "./pai";
import type {
  Market,
  PensionRiskProfile,
  StrategyFocus,
} from "@/clients";
import {
  CreatePensionPortfolios,
  CreatePensionStrategy,
  CreatePensionsFirst,
  PensionMoveRequest,
} from "@/clients";
import type { PensionMap, PensionSignupState } from "@/store/pension/types";
import type { SignupState } from "@/store/signup/types";

export function getCreatePensionParameters(state: PensionSignupState, getters: any, signupStore: SignupState) {
  const pensions = Object.values(state.selectablePensions as PensionMap).filter(
    v => v.selected && !v.confirmedActivePayment,
  );

  const request = new CreatePensionPortfolios({
    name: "TJPension",
    strategy: new CreatePensionStrategy({
      defenseDiscarded: !state.includedSectors.weaponsAndDefense,
      alcoholTobaccoDiscarded: !state.includedSectors.alcoholTobacco,
      gamblingDiscarded: !state.includedSectors.casinosAndGambling,
      fossilFuelsDiscarded: !state.includedSectors.fossilFuel,
      esgBiodiversityFilter: !!signupStore.esgFilter?.esgBiodiversityFilter,
      esgEmissionsFilter: !!signupStore.esgFilter?.esgEmissionsFilter,
      esgGovernanceFilter: !!signupStore.esgFilter?.esgGovernanceFilter,
      esgHumanRightsFilter: !!signupStore.esgFilter?.esgHumanRightsFilter,
      esgResourcesFilter: !!signupStore.esgFilter?.esgResourcesFilter,
      focus: state.focus as StrategyFocus,
      marketFocus: state.marketFocus as Market,
      pensionAge: state.payoutAge,
      pensionPayoutYears: state.payoutTime,
      chosenPensionRiskProfile: state.customRiskProfile as PensionRiskProfile,
      recommendedPensionRiskProfile: state.calculatedRiskProfile as PensionRiskProfile,
      tiedAgentPortfolioThemeType: state.portfolioTheme?.tiedAgentPortfolioThemeType,
      esgPais: paiFromState(signupStore.esgPais),
    }),
    pensionMoveRequest: pensions.map(
      x =>
        new PensionMoveRequest({
          approximateValue: getters.pensionTotalValue(x.pension.externalId),
          insuranceHolderName: x.pension.insuranceHolderName as string,
          insuranceHolderOrganizationNumber: x.pension.insuranceHolderOrgNumber as string,
          insuranceCompanyName: x.pension.insuranceCompany as string,
          insuranceNumber: x.pension.insuranceNumber as string,
          soleOwner:
            x.pension.insuranceHolderName !== undefined
            && x.pension.insuranceHolderName in state.soleOwnerships
            && state.soleOwnerships[x.pension.insuranceHolderName] === true,
          insuranceHolderEmail: x.insuranceHolderEmail,
        }),
    ),
    shouldMerge: state.shouldMerge,
    hasOtherOccupationalPensionSavings: !!state.hasOtherOccupationalPensionSavings,
    hasPrivatePensionSavings: !!state.hasPrivatePensionSavings,
  });
  return request;
}

export function getCreatePensionParametersFirst(
  pensionStore: PensionSignupState,
  signupStore: SignupState,
  getters: any,
): CreatePensionsFirst {
  const createPensionsParams = getCreatePensionParameters(pensionStore, getters, signupStore);

  return new CreatePensionsFirst({
    firstPortfolioRequestBase: getFirstPortfolioRequestBase(signupStore, getters),
    createPensionPortfolios: createPensionsParams,
  });
}
