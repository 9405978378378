<template>
  <div class="portfolio-confirmation">
    <question-text-section
      :heading="heading"
      :body="body"
    />

    <div class="portfolio-confirmation__content-wrapper">
      <v-card class="portfolio-confirmation__chart-wrapper">
        <risk-chart
          :selected-series="riskWillingness"
          :number-of-years="numberOfYears"
        />
      </v-card>

      <v-card class="portfolio-confirmation__selection">
        {{ $t("common-signup.questions.risk.portfolio-confirmation.your_choice") }}
        <span
          :class="`portfolio-confirmation__risk-level
           portfolio-confirmation__risk-level--${riskWillingness}`"
        >
          {{ $t(`riskWillingnessOptions.${riskWillingness}`) }}
          {{ $t("risk").toLowerCase() }}
        </span>
      </v-card>
      <div class="portfolio-confirmation__button portfolio-confirmation__change-button">
        <signup-button
          style="min-width: 100%"
          size-large
          secondary
          data-cy="buttonchange"
          :color="buttonColor"
          block
          @click="goBack()"
        >
          {{ $t("change_risk_level") }}
        </signup-button>
      </div>
      <div class="portfolio-confirmation__button portfolio-confirmation__continue-button">
        <signup-button
          style="min-width: 100%"
          size-large
          block
          data-cy="buttonnext"
          :color="buttonColor"
          @click="goForward()"
        >
          {{ $t("continue") }}
        </signup-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import SideDialog from "@/components/dialog/side-dialog.vue";
import { RiskEvaluation, TiedAgent } from "@/clients";
import SignupButton from "@/components/button.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import RiskChart from "./risk-chart.vue";

export default defineComponent({
  emits: ["evaluateRisk"],
  components: {
    QuestionTextSection,
    SideDialog,
    SignupButton,
    RiskChart
  },
  extends: Question,
  props: {
    riskWillingness: { type: Number },
    heading: { type: String },
    body: { type: String }
  },
  computed: {
    tiedAgent(): TiedAgent {
      return this.$store.state.signupStore.tiedAgent;
    },
    buttonColor(): string | undefined {
      return this.$store.state.signupStore.tiedAgent?.color;
    },
    numberOfYears(): number {
      switch (this.riskWillingness) {
        case 0:
          return 16;
        case 1:
          return 16;
        default:
          return 2;
      }
    }
  },
  methods: {
    goBack(): void {
      this.$router.go(-1);
    },
    async goForward(): Promise<void> {
      this.$emit("evaluateRisk");
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
$text-max-width: 14.25rem;
.portfolio-confirmation {
  &__button {
    margin-bottom: 1rem;
    width: 100%;
    max-width: $text-max-width;
  }
  &__change-button {
    grid-row: 3;
    grid-column: 1;
    @include xsmall-down {
      margin-top: 0.625rem;
    }
    @include medium-up {
      grid-row: 2;
      grid-column: 2;
    }
  }
  &__continue-button {
    grid-row: 4;
    grid-column: 1;
    @include medium-up {
      grid-row: 3;
      grid-column: 2;
    }
  }
  &__chart-wrapper {
    width: 100%;
    padding: 0.5rem;
    grid-column: 1 / -1;
    @include medium-up {
      position: relative;
      grid-row: 1/-1;
      grid-column: 1;
      margin-bottom: 0;
      height: 18rem;
    }
  }
  &__content-wrapper {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    margin: 6vh auto;
    justify-items: center;
    @include medium-up {
      width: 49rem;
      grid-template-columns: 34rem auto;
      grid-template-rows: repeat(4, auto);
      grid-column-gap: 3rem;
    }
  }
  &__risk-level {
    text-align: center;
    font-weight: 600;
    &--0 {
      color: $soft-orange;
    }
    &--1 {
      color: $aqua;
    }
    &--2 {
      color: $soft-blue;
    }
    &--3 {
      color: $grayish-blue;
    }
  }
  &__selection {
    font-family: $heading-font-family;
    margin: 2rem 0;
    font-size: 1.25rem;
    height: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.625rem;
    width: 11rem;
    grid-column: 1 / -1;
    @include medium-up {
      margin-top: 0;
      grid-row: 1;
      grid-column: 2;
    }
  }
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
}
</style>
