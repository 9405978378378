<template>
  <v-dialog
    v-model="showDialog"
    content-class="autogiro"
    @click:outside="close"
  >
    <v-card class="autogiro__card">
      <template v-if="state === CancelledAutogiroDialogState.Info">
        <p class="autogiro__header">
          {{ $t("components.cancelled-autogiro-sign-dialog.header") }}
        </p>
        <p class="autogiro__body">
          {{ $t("components.cancelled-autogiro-sign-dialog.infoBody") }}
        </p>
        <v-card-actions class="autogiro__actions">
          <CommonButton
            secondary
            class="autogiro__button"
            @click="close"
          >
            {{ $t("components.cancelled-autogiro-sign-dialog.noNotNow") }}
          </CommonButton>
          <CommonButton
            class="autogiro__button"
            @click="approve"
          >
            {{ $t("components.cancelled-autogiro-sign-dialog.yesApprove") }}
          </CommonButton>
        </v-card-actions>
      </template>
      <template v-if="state === CancelledAutogiroDialogState.Sign && preparingDocuments">
        <div class="autogiro__prepare-agreements">
          <p>{{ $t("preparingDocuments") }}</p>
          <LoadingSpinner />
        </div>
      </template>
      <template v-else-if="state === CancelledAutogiroDialogState.Sign">
        <p class="autogiro__header">
          {{ $t("components.cancelled-autogiro-sign-dialog.header") }}
        </p>
        <p class="autogiro__body">
          {{ $t("components.cancelled-autogiro-sign-dialog.signInfoBody") }}
        </p>
        <div class="autogiro__pdf-wrapper">
          <div class="autogiro__link-wrapper">
            <p
              class="autogiro__link"
              @click="() => getPdf(activePortfolio.externalReference)"
            >
              {{ $t("autogiroPdfName") }}
            </p>
            <icon :icon="['fal', 'chevron-right']" />
          </div>
        </div>
        <v-card-actions class="autogiro__actions">
          <CommonButton
            class="autogiro__button"
            @click="sign"
          >
            {{ $t("signBankId") }}
          </CommonButton>
        </v-card-actions>
      </template>
      <template v-if="state === CancelledAutogiroDialogState.Signing">
        <BankId
          @complete="signComplete"
          @canceled="signCancel"
          @failed="signFailed"
        />
      </template>
      <template v-if="state === CancelledAutogiroDialogState.SignComplete">
        <p class="autogiro__header">
          {{
            signSettingsFailed
              ? $t("signCompletionHeaderFailed")
              : $t("components.cancelled-autogiro-sign-dialog.signCompletionHeader")
          }}
        </p>
        <p class="autogiro__body">
          {{
            signSettingsFailed
              ? $t("signCompletionBodyFailed")
              : $t("components.cancelled-autogiro-sign-dialog.signCompletionBody")
          }}
        </p>
      </template>
      <template v-if="state === CancelledAutogiroDialogState.Complete">
        <p class="autogiro__header">
          Klart!
        </p>
        <p class="autogiro__body">
          Ditt autogiro är återupprättat.
        </p>
        <v-card-actions class="autogiro__actions">
          <CommonButton
            class="autogiro__cancel-button"
            data-cy="closeDialogButton"
            @click="close"
          >
            {{ $t("close") }}
          </CommonButton>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BankId from "./bank-id.vue";
import CommonButton from "@/components/button.vue";
import type { ExtRef, Portfolio, PortfolioBase } from "@/clients";
import { AutogiroStatus, PdfType } from "@/clients";
import { BankIdAction } from "@/store/bankid/actions";
import { HIDE_CANCELLED_AUTOGIRO_DIALOG_KEY } from "@/config/general";
import { UserAction } from "@/store/user/action-definitions";
import { PortfolioAction } from "@/store/portfolio/actions";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import type { RootState } from "@/types";

enum CancelledAutogiroDialogState {
  Info,
  Sign,
  Signing,
  SignComplete,
  Complete,
}

export default defineComponent({
  components: {
    CommonButton,
    BankId,
    LoadingSpinner,
  },
  emits: ["update:model-value"],
  data: () => ({
    CancelledAutogiroDialogState,
    state: CancelledAutogiroDialogState.Info,
    signSettingsFailed: false,
    hiddenDialog: false,
    preparingDocuments: false,
    showDialog: false,
  }),
  computed: {
    hasCancelledPortfolios(): boolean {
      if (this.$store.state.navigationStore.portfolios) {
        return this.$store.state.navigationStore.portfolios?.filter(
          (p: Portfolio) => p.autogiroStatus === AutogiroStatus.Cancelled,
        ).length > 0;
      }
      return false;
    },
    activePortfolio(): PortfolioBase {
      return (this.$store.state as RootState).portfolioStore.activePortfolioBase!;
    },
  },
  created() {
    this.hiddenDialog = sessionStorage.getItem(HIDE_CANCELLED_AUTOGIRO_DIALOG_KEY) === "true";
    this.showDialog = this.hasCancelledPortfolios && !this.hiddenDialog;
  },
  methods: {
    async approve() {
      this.preparingDocuments = true;
      this.state = CancelledAutogiroDialogState.Sign;
      await this.$store.dispatch(PortfolioAction.prepareRecreateCancelledTransferReceivers);
      this.preparingDocuments = false;
    },
    cancelSign(): void {
      this.$store.dispatch(BankIdAction.cancelSign);
    },
    cancel(): void {
      this.cancelSign();
    },
    close() {
      this.resetState();
      this.showDialog = false;
      sessionStorage.setItem(HIDE_CANCELLED_AUTOGIRO_DIALOG_KEY, "true");
    },
    async getPdf(externalReference: ExtRef | undefined) {
      this.$store.dispatch(UserAction.getPreviewDocument, {
        pdfType: PdfType.Autogiro,
        pdfId: 0,
        portfolioExternalReference: externalReference,
      });
    },
    async sign() {
      this.state = CancelledAutogiroDialogState.Signing;
      this.signSettingsFailed = false;
      try {
        await this.$store.dispatch(BankIdAction.sign);
      } catch (error) {
        this.signSettingsFailed = true;
      }
    },
    async signComplete() {
      this.state = CancelledAutogiroDialogState.SignComplete;
      try {
        await this.$store.dispatch(PortfolioAction.completeRecreateCancelledTransferReceivers);
        this.state = CancelledAutogiroDialogState.Complete;
      } catch (e) {
        this.signSettingsFailed = true;
        throw e;
      }
    },
    signCancel() {
      this.state = CancelledAutogiroDialogState.Info;
    },
    signFailed() {
      this.state = CancelledAutogiroDialogState.Complete;
      this.signSettingsFailed = true;
    },
    resetState() {
      this.state = CancelledAutogiroDialogState.Info;
    },
  },
});
</script>

<style lang="scss" scoped>
.autogiro {
  &__card {
    padding: 1.5rem 1rem 0rem 1rem;
    min-height: 25rem;
    @include small-up {
      min-height: 22rem;
    }
  }
  &__prepare-agreements {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
  }
  &__header {
    font-family: $heading-font-family;
    font-size: 1.125rem;
    font-weight: 500;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;
  }
  &__body {
    font-family: $heading-font-family;
    font-size: 1rem;
    font-weight: 300;
    color: $nearly-black;
    text-align: center;
  }
  &__actions {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center !important;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    @include small-up {
      bottom: 2rem;
      position: absolute;
      flex-wrap: unset;
      flex-direction: row;
    }
  }
  &__cancel-button {
    margin-top: 0.5rem;
    @include xsmall-up {
      margin-top: 0rem;
    }
  }
  &__pdf-wrapper {
    padding: 1rem;
    margin-bottom: 0.5rem;
  }
  &__link-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 80%;
    svg {
      margin-top: 0.3rem;
    }
  }
  &__link {
    cursor: pointer;
    text-decoration: underline;
  }
  &__button {
    width: 100%;
    @include small-up {
      max-width: 12rem;
    }
  }
}
:deep(.autogiro) {
  max-width: 30rem;
}
.v-card-actions .v-btn ~ .v-btn:not(.v-btn-toggle .v-btn) {
  margin-left: 0;
}
</style>
