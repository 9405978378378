<template>
  <withdrawal-interval @next="next" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WithdrawalInterval from "@/views/common-signup/questions/aml/withdrawal-interval.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { WithdrawalFrequency } from "@/clients";
import { SignupFlowModificationType } from "@/config/signup-modification";

export default defineComponent({
  components: { WithdrawalInterval },
  methods: {
    async next(key: WithdrawalFrequency) {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.WithdrawalIntervalInformationPrivate,
        include: key !== WithdrawalFrequency.OncePerYear
      });
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
