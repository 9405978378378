import type { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { CURRENT_COMPANY_SIGNUP_PATH_KEY } from "@/config/company-signup";
import store from "@/store";

const CompanyCheck = () =>
  import("../views/company-signup/questions/evida/introduction/company-check.vue");
const CapitalInsuranceWarning = () =>
  import("../views/company-signup/questions/evida/introduction/capital-insurance-warning.vue");

const FortnoxIntro = () =>
  import("../views/company-signup/questions/introduction/fortnox-intro.vue");
const CompanyAccountInfo = () =>
  import("../views/company-signup/questions/introduction/company-account-info.vue");
const NecessaryInfo = () =>
  import("../views/company-signup/questions/introduction/necessary-info.vue");
const TimeInfo = () => import("../views/company-signup/questions/introduction/time-info.vue");
const PreviousInsuranceExperience = () =>
  import("../views/company-signup/questions/evida/introduction/previous-insurance-experience.vue");
const InsuranceInfo = () =>
  import("../views/company-signup/questions/evida/introduction/insurance-info.vue");

const RepresentantInfo = () =>
  import("../views/company-signup/questions/evida/introduction/representant-info.vue");
const OneManCompany = () =>
  import("../views/company-signup/questions/evida/introduction/one-man-company.vue");
const RepresentativeWarning = () =>
  import("../views/company-signup/questions/evida/introduction/representative-warning.vue");
const RepresentantRole = () =>
  import("../views/company-signup/questions/evida/introduction/representant-role.vue");
const MultipleRepresentatives = () =>
  import("../views/company-signup/questions/evida/introduction/multiple-representatives.vue");
const NumberRepresentatives = () =>
  import("../views/company-signup/questions/evida/introduction/number-representatives.vue");
const NewCompanyRepresentative = () =>
  import("../views/company-signup/questions/evida/introduction/new-company-representative.vue");
const FortnoxCompanyRepresentative = () =>
  import("../views/company-signup/questions/evida/introduction/fortnox-company-representative.vue");
const SecuritiesOwnerInfo = () =>
  import("../views/company-signup/questions/evida/introduction/securities-owner-info.vue");
const SecuritiesOwner = () =>
  import("../views/company-signup/questions/evida/introduction/securities-owner.vue");
const SustainabilityInfo = () =>
  import("../views/company-signup/questions/evida/introduction/sustainability-info.vue");
const CompanyRepresentant = () =>
  import("../views/company-signup/questions/evida/introduction/company-representant.vue");

const OneManPrincipalInfo = () =>
  import("../views/company-signup/questions/evida/one-man-principal/principal-info.vue");
const SwedishResident = () =>
  import("../views/company-signup/questions/evida/one-man-principal/swedish-resident.vue");
const SwedishTaxResidence = () =>
  import("../views/company-signup/questions/evida/one-man-principal/swedish-tax-residence.vue");
const TaxLiableUsa = () =>
  import("../views/company-signup/questions/evida/one-man-principal/tax-liable-usa.vue");
const TaxLiableOther = () =>
  import("../views/company-signup/questions/evida/one-man-principal/tax-liable-other.vue");
const Citizenship = () =>
  import("../views/company-signup/questions/evida/one-man-principal/citizenship.vue");
const TaxLiableCountry = () =>
  import("../views/company-signup/questions/evida/one-man-principal/tax-liable-other-country.vue");
const PoliticallyExposed = () =>
  import("../views/company-signup/questions/evida/one-man-principal/politically-exposed.vue");

const InsuredContact = () =>
  import("../views/company-signup/questions/evida/application/insured-contact.vue");

const KycInfo = () => import("../views/company-signup/questions/evida/principals/kyc-info.vue");
const CompanyPrincipalInfo = () =>
  import("../views/company-signup/questions/evida/principals/company-principal-info.vue");
const OtherPrincipalInfo = () =>
  import("../views/company-signup/questions/evida/principals/other-principal-info.vue");

const CompanyPrincipalsSummary = () =>
  import("../views/company-signup/questions/evida/principals/company-principals-summary.vue");
const UpdateCompanyRegistrationWarning = () =>
  import(
    "../views/company-signup/questions/evida/principals/update-company-registration-warning.vue"
  );
const CompanyPrincipalName = () =>
  import("../views/company-signup/questions/evida/principals/company-principal-name.vue");
const CompanyPrincipalDetails = () =>
  import("../views/company-signup/questions/evida/principals/company-principal-details.vue");
const CompanyPrincipalSwedishResident = () =>
  import(
    "../views/company-signup/questions/evida/principals/company-principal-swedish-resident.vue"
  );

const CompanyPrincipalForeignAddress = () =>
  import(
    "../views/company-signup/questions/evida/principals/company-principal-foreign-address.vue"
  );

const CompanyPrincipalShares = () =>
  import("../views/company-signup/questions/evida/principals/company-principal-shares.vue");
const CompanyPrincipalCitizenship = () =>
  import("../views/company-signup/questions/evida/principals/company-principal-citizenship.vue");
const OtherPrincipalCitizenship = () =>
  import("../views/company-signup/questions/evida/principals/other-principal-citizenship.vue");
const CompanyPrincipalForbidden = () =>
  import("../views/company-signup/questions/evida/principals/company-principal-forbidden.vue");
const CompanyPrincipalTaxable = () =>
  import("../views/company-signup/questions/evida/principals/company-principal-taxable.vue");
const CompanyPrincipalTaxableWarning = () =>
  import(
    "../views/company-signup/questions/evida/principals/company-principal-taxable-warning.vue"
  );
const CompanyPrincipalPep = () =>
  import("../views/company-signup/questions/evida/principals/company-principal-pep.vue");
const CompanyPrincipalPepContact = () =>
  import("../views/company-signup/questions/evida/principals/company-principal-contact.vue");
const CompanyPrincipalTaxableCountry = () =>
  import(
    "../views/company-signup/questions/evida/principals/company-principal-taxable-country.vue"
  );
const CompanyPrincipalTaxableCountryWarning = () =>
  import(
    "../views/company-signup/questions/evida/principals/company-principal-taxable-country-warning.vue"
  );

const Industry = () => import("../views/company-signup/questions/evida/aml/industry.vue");
const Revenue = () => import("../views/company-signup/questions/evida/aml/revenue.vue");
const Employees = () => import("../views/company-signup/questions/evida/aml/employees.vue");
const SavingsPurpose = () =>
  import("../views/company-signup/questions/evida/aml/savings-purpose.vue");
const InvestmentBackground = () =>
  import("../views/company-signup/questions/evida/aml/investment-background.vue");
const DepositInterval = () =>
  import("../views/company-signup/questions/evida/aml/deposit-interval.vue");
const YearlyDeposit = () =>
  import("../views/company-signup/questions/evida/aml/yearly-deposit.vue");
const YearlyDepositManual = () =>
  import("../views/company-signup/questions/evida/aml/yearly-deposit-manual.vue");
const WithdrawalInterval = () =>
  import("../views/company-signup/questions/evida/aml/withdrawal-interval.vue");
const WithdrawalIntervalWarning = () =>
  import("../views/company-signup/questions/evida/aml/withdrawal-interval-warning.vue");
const Accountant = () => import("../views/company-signup/questions/evida/aml/accountant.vue");
const Done = () => import("../views/company-signup/questions/evida/done.vue");
const SummaryAgreements = () =>
  import("../views/company-signup/questions/evida/aml/summary-agreements.vue");
const CompanyTaxableOther = () =>
  import("../views/company-signup/questions/evida/aml/company-taxable-other.vue");
const CompanyTaxableCountry = () =>
  import("../views/company-signup/questions/evida/aml/company-taxable-country.vue");

const Profile = () => import("../views/company-signup/questions/risk/profile.vue");
const CompanySignup = () => import("../views/company-signup/company-signup.vue");

const Welcome = () => import("../views/company-signup/questions/welcome.vue");
const EvidaWelcome = () =>
  import("../views/company-signup/questions/evida/introduction/welcome.vue");

const Introduction = () => import("../views/company-signup/questions/introduction.vue");
const Inactive = () => import("../views/company-signup/questions/economy/inactive.vue");
const AverageOperatingProfit = () =>
  import("../views/company-signup/questions/economy/average-operating-profit.vue");
const Solidity = () => import("../views/company-signup/questions/economy/solidity.vue");
const SolidityWarning = () =>
  import("../views/company-signup/questions/economy/solidity-warning.vue");
const LiquidAssets = () => import("../views/company-signup/questions/economy/liquid-assets.vue");
const Liquidity = () => import("../views/company-signup/questions/economy/liquidity.vue");
const LiquidityWarning = () =>
  import("../views/company-signup/questions/economy/liquidity-warning.vue");
const LiquidAssetsWarning = () =>
  import("../views/company-signup/questions/economy/liquid-assets-warning.vue");
const ForecastResult = () =>
  import("../views/company-signup/questions/economy/forecast-result.vue");
const LongTermDebt = () => import("../views/company-signup/questions/economy/long-term-debt.vue");
const ShortTermDebt = () => import("../views/company-signup/questions/economy/short-term-debt.vue");

const TimeToWithdraw = () =>
  import("../views/company-signup/questions/economy/time-to-withdraw.vue");
const TimeToWithdrawWarning = () =>
  import("../views/company-signup/questions/economy/time-to-withdraw-warning.vue");
const RiskIntroduction = () => import("../views/company-signup/questions/risk/introduction.vue");
const PreviousExperience = () =>
  import("../views/company-signup/questions/risk/previous-experience.vue");
const PreviousExperienceAnswer = () =>
  import("../views/company-signup/questions/risk/previous-experience-answer.vue");
const StockInformation = () =>
  import("../views/common-signup/questions/risk/stock-information.vue");
const PortfolioManagement = () =>
  import("../views/common-signup/questions/risk/portfolio-management.vue");
const PortfolioSelection = () =>
  import("../views/company-signup/questions/risk/portfolio-selection.vue");
const PortfolioConfirmation = () =>
  import("../views/company-signup/questions/risk/portfolio-confirmation.vue");

const PreferencesIntroduction = () =>
  import("../views/company-signup/questions/preferences/introduction.vue");
const Sectors = () => import("../views/company-signup/questions/preferences/sectors.vue");
const MarketFocus = () => import("../views/company-signup/questions/preferences/market-focus.vue");
const Focus = () => import("../views/company-signup/questions/preferences/focus.vue");
const Sustainability = () =>
  import("../views/common-signup/questions/preferences/sustainability.vue");
const SustainableInvestment = () =>
  import("../views/common-signup/questions/preferences/sustainable-investment.vue");
const SustainableMethod = () =>
  import("../views/common-signup/questions/preferences/sustainable-method.vue");
const SustainableInfo = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice-info.vue");
const SustainableChoice = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice.vue");
const SustainableChoiceVerify = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice-verify.vue");
const NegativeImpact = () => import("../views/common-signup/questions/preferences/negative-impact.vue");

const AccountIntroduction = () =>
  import("../views/company-signup/questions/account/introduction.vue");
const ChooseTransactionAccount = () =>
  import("../views/company-signup/questions/account/choose-transaction-account.vue");
const AddAccount = () => import("../views/company-signup/questions/account/add-account.vue");
const FirstDeposit = () => import("../views/company-signup/questions/account/first-deposit.vue");
const MonthlySavings = () =>
  import("../views/company-signup/questions/account/monthly-savings.vue");

const SummaryAgreementsSigmastocks = () =>
  import("../views/company-signup/questions/summary/summary-agreements.vue");
const DoneSigmastocks = () => import("../views/company-signup/questions/summary/done.vue");

const AgreementsIntroduction = () =>
  import("../views/company-signup/questions/agreements/introduction.vue");
const AgreementsCommunication = () =>
  import("../views/company-signup/questions/agreements/communication.vue");
const AuthorizedSignatories = () =>
  import("../views/company-signup/questions/agreements/authorized-signatories.vue");
const AuthorizedSignatoryContact = () =>
  import("../views/company-signup/questions/agreements/authorized-signatory-contact.vue");
const Summary = () => import("../views/company-signup/questions/agreements/summary.vue");
const EvidaCongratulations = () =>
  import("../views/company-signup/questions/agreements/evida-congratulations.vue");
const Congratulations = () =>
  import("../views/company-signup/questions/agreements/congratulations.vue");

const ThemeInfo = () => import("../views/company-signup/questions/preferences/theme-info.vue");
const Theme = () => import("../views/company-signup/questions/preferences/theme.vue");

export default [
  {
    path: "company-signup",
    name: "company-signup",
    component: CompanySignup,

    // Always allow the user to go to return to a page they have previously visited.
    // If they try to go to any other question, put them on the last visited path.
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (path?: RouteLocationRaw) => void,
    ) => {
      const { getters } = store as any;
      if (to.path.endsWith("/company-signup")) {
        // This check is here to avoid infinite recursion.
        next();
      } else {
        const latestVisitedPath = sessionStorage.getItem(CURRENT_COMPANY_SIGNUP_PATH_KEY);

        if (latestVisitedPath) {
          const latestVisitedQuestion = getters.questionProgress(latestVisitedPath);
          const toQuestion = getters.questionProgress(to.path);
          const hasVisitedToQuestion
            = latestVisitedQuestion
            && toQuestion
            && (toQuestion.partNumber < latestVisitedQuestion.partNumber
            || (toQuestion.partNumber === latestVisitedQuestion.partNumber
            && toQuestion.questionNumber <= latestVisitedQuestion.questionNumber));

          if (hasVisitedToQuestion) {
            next();
          } else if (to.path.includes("tink-verification")) {
            // We need to forward the query specifically because a simple call to next() strips the query.
            next({ params: to.params, query: to.query });
          } else {
            // The user tries to access a question or introduction which doesn't exist or which
            // they haven't reached using the normal flow. Put them on the latest visited path.
            next({ params: to.params, path: latestVisitedPath, replace: true });
          }
        } else if (to.path.includes("tink-verification")) {
          // Allow user to jump straight to tink verification to let the tink redirect work
          // We need to forward the query specifically because a simple call to next() strips the query.
          next({ params: to.params, query: to.query });
        } else {
          // The user has not yet started the signup flow. Put them on the signup start page.
          next({ params: to.params, name: "company-signup", replace: true });
        }
      }
    },
    children: [
      {
        path: "introduction/company-account-info",
        component: CompanyAccountInfo,
      },
      {
        path: "introduction/fortnox-intro",
        component: FortnoxIntro,
      },
      {
        path: "introduction/necessary-info",
        component: NecessaryInfo,
      },
      {
        path: "introduction/time-info",
        component: TimeInfo,
      },
      {
        path: "evida/introduction/previous-insurance-experience",
        component: PreviousInsuranceExperience,
      },
      {
        path: "evida/introduction/welcome",
        component: EvidaWelcome,
      },
      {
        path: "evida/introduction/insurance-info",
        component: InsuranceInfo,
      },
      {
        path: "evida/introduction/company-representant",
        component: CompanyRepresentant,
      },
      {
        path: "evida/introduction/company-check",
        component: CompanyCheck,
      },
      {
        path: "evida/introduction/capital-insurance-warning",
        component: CapitalInsuranceWarning,
      },
      {
        path: "evida/introduction/fortnox-company-representative",
        component: FortnoxCompanyRepresentative,
      },
      {
        path: "evida/introduction/representant-info",
        component: RepresentantInfo,
      },
      {
        path: "evida/introduction/multiple-representatives",
        component: MultipleRepresentatives,
      },
      {
        path: "evida/introduction/number-representatives",
        component: NumberRepresentatives,
      },
      {
        path: "evida/introduction/new-company-representative/:index",
        component: NewCompanyRepresentative,
      },
      {
        path: "evida/introduction/one-man-company",
        component: OneManCompany,
      },
      {
        path: "evida/introduction/securities-owner-info",
        component: SecuritiesOwnerInfo,
      },
      {
        path: "evida/introduction/securities-owner",
        component: SecuritiesOwner,
      },
      {
        path: "evida/introduction/sustainability-info",
        component: SustainabilityInfo,
      },
      {
        path: "evida/one-man-principal/kyc-info",
        component: KycInfo,
      },
      {
        path: "evida/one-man-principal/principal-info",
        component: OneManPrincipalInfo,
      },
      {
        path: "evida/one-man-principal/swedish-resident",
        component: SwedishResident,
      },
      {
        path: "evida/one-man-principal/citizenship",
        component: Citizenship,
      },
      {
        path: "evida/one-man-principal/swedish-tax-residence",
        component: SwedishTaxResidence,
      },
      {
        path: "evida/one-man-principal/tax-liable-usa",
        component: TaxLiableUsa,
      },
      {
        path: "evida/one-man-principal/tax-liable-other",
        component: TaxLiableOther,
      },
      {
        path: "evida/one-man-principal/tax-liable-country",
        component: TaxLiableCountry,
      },
      {
        path: "evida/one-man-principal/politically-exposed",
        component: PoliticallyExposed,
      },
      {
        path: "evida/introduction/representative-warning",
        component: RepresentativeWarning,
      },
      {
        path: "evida/introduction/representant-role",
        component: RepresentantRole,
      },
      {
        path: "evida/application/insured-contact",
        component: InsuredContact,
      },
      {
        path: "evida/principals/kyc-info",
        component: KycInfo,
      },
      {
        path: "evida/principals/company-principal-info",
        component: CompanyPrincipalInfo,
      },

      {
        path: "evida/principals/company-principals-summary",
        component: CompanyPrincipalsSummary,
      },
      {
        path: "evida/principals/company-principal-shares/:index",
        component: CompanyPrincipalShares,
      },
      {
        path: "evida/principals/company-principal-foreign-address/:index",
        component: CompanyPrincipalForeignAddress,
      },
      {
        path: "evida/principals/company-principal-citizenship/:index",
        component: CompanyPrincipalCitizenship,
      },
      {
        path: "evida/principals/other-principal-citizenship",
        component: OtherPrincipalCitizenship,
      },
      {
        path: "evida/principals/company-principal-forbidden",
        component: CompanyPrincipalForbidden,
      },
      {
        path: "evida/principals/company-principal-taxable/:index",
        component: CompanyPrincipalTaxable,
      },
      {
        path: "evida/principals/taxable-usa-warning",
        component: CompanyPrincipalTaxableWarning,
      },
      {
        path: "evida/principals/update-company-registration-warning",
        component: UpdateCompanyRegistrationWarning,
        name: "update-company-registration-warning",
      },
      {
        path: "evida/principals/company-principal-pep/:index",
        component: CompanyPrincipalPep,
      },
      {
        path: "evida/principals/company-principal-contact/:index",
        component: CompanyPrincipalPepContact,
      },
      {
        path: "evida/principals/company-principal-name/:index",
        component: CompanyPrincipalName,
      },
      {
        path: "evida/principals/company-principal-details/:index",
        component: CompanyPrincipalDetails,
      },
      {
        path: "evida/principals/company-principal-swedish-resident/:index",
        component: CompanyPrincipalSwedishResident,
      },
      {
        path: "evida/principals/company-principal-taxable-country/:index",
        component: CompanyPrincipalTaxableCountry,
      },
      {
        path: "evida/principals/company-principal-taxable-country-warning",
        component: CompanyPrincipalTaxableCountryWarning,
      },
      {
        path: "evida/principals/other-principal-info",
        component: OtherPrincipalInfo,
      },
      {
        path: "evida/principals/other-principal-details",
        component: CompanyPrincipalDetails,
      },
      {
        path: "evida/principals/other-principal-name",
        component: CompanyPrincipalName,
      },
      {
        path: "evida/principals/other-principal-swedish-resident",
        component: CompanyPrincipalSwedishResident,
      },
      {
        path: "evida/principals/other-principal-citizenship",
        component: CompanyPrincipalCitizenship,
      },
      {
        path: "evida/principals/other-principal-taxable",
        component: CompanyPrincipalTaxable,
      },
      {
        path: "evida/principals/other-principal-pep",
        component: CompanyPrincipalPep,
      },
      {
        path: "evida/principals/other-principal-contact",
        component: CompanyPrincipalPepContact,
      },
      {
        path: "evida/principals/other-principal-foreign-address",
        component: CompanyPrincipalForeignAddress,
      },
      {
        path: "evida/principals/other-principal-taxable-country",
        component: CompanyPrincipalTaxableCountry,
      },
      {
        path: "evida/aml/industry",
        component: Industry,
      },
      {
        path: "evida/aml/revenue",
        component: Revenue,
      },
      {
        path: "evida/aml/employees",
        component: Employees,
      },
      {
        path: "evida/aml/savings-purpose",
        component: SavingsPurpose,
      },
      {
        path: "evida/aml/investment-background",
        component: InvestmentBackground,
      },
      {
        path: "evida/aml/deposit-interval",
        component: DepositInterval,
      },
      {
        path: "evida/aml/yearly-deposit",
        component: YearlyDeposit,
      },
      {
        path: "evida/aml/yearly-deposit-manual",
        component: YearlyDepositManual,
      },
      {
        path: "evida/aml/withdrawal-interval",
        component: WithdrawalInterval,
      },
      {
        path: "evida/aml/withdrawal-interval-warning",
        component: WithdrawalIntervalWarning,
      },
      {
        path: "evida/aml/summary-agreements",
        component: SummaryAgreements,
      },
      {
        path: "evida/aml/company-taxable-other",
        component: CompanyTaxableOther,
      },
      {
        path: "evida/aml/company-taxable-country",
        component: CompanyTaxableCountry,
      },
      {
        path: "evida/aml/accountant",
        component: Accountant,
      },
      {
        path: "evida/aml/done",
        component: Done,
      },
      {
        path: "welcome",
        component: Welcome,
      },
      {
        path: "introduction",
        component: Introduction,
      },
      {
        path: "economy/inactive",
        component: Inactive,
      },
      {
        path: "economy/average-operating-profit",
        component: AverageOperatingProfit,
      },
      {
        path: "economy/forecast-result",
        component: ForecastResult,
      },
      {
        path: "economy/long-term-debt",
        component: LongTermDebt,
      },
      {
        path: "economy/solidity",
        component: Solidity,
      },
      {
        path: "economy/solidity-warning",
        component: SolidityWarning,
      },
      {
        path: "economy/liquid-assets",
        component: LiquidAssets,
      },
      {
        path: "economy/short-term-debt",
        component: ShortTermDebt,
      },
      {
        path: "economy/liquidity",
        component: Liquidity,
      },
      {
        path: "economy/liquidity-warning",
        component: LiquidityWarning,
      },
      {
        path: "economy/liquid-assets-warning",
        component: LiquidAssetsWarning,
      },
      {
        path: "economy/time-to-withdraw",
        component: TimeToWithdraw,
      },
      {
        path: "economy/time-to-withdraw-warning",
        component: TimeToWithdrawWarning,
        name: "company-time-to-withdraw-warning",
      },
      {
        path: "preferences/introduction",
        component: PreferencesIntroduction,
      },
      {
        path: "preferences/sectors",
        component: Sectors,
      },
      {
        path: "preferences/market-focus",
        component: MarketFocus,
      },
      { path: "preferences/sustainability", component: Sustainability },
      {
        path: "preferences/sustainable-investment",
        component: SustainableInvestment,
        name: "company-sustainable-investment",
      },
      { path: "preferences/sustainable-method", component: SustainableMethod },
      { path: "preferences/sustainable-info", component: SustainableInfo },
      { path: "preferences/sustainable-choice", component: SustainableChoice },
      { path: "preferences/sustainable-choice-verify", component: SustainableChoiceVerify },
      { path: "preferences/negative-impact", component: NegativeImpact },
      {
        path: "preferences/focus",
        component: Focus,
      },
      {
        path: "risk/introduction",
        component: RiskIntroduction,
      },
      {
        path: "risk/previous-experience",
        component: PreviousExperience,
      },
      {
        path: "risk/previous-experience-answer",
        component: PreviousExperienceAnswer,
      },
      {
        path: "risk/stock-information",
        component: StockInformation,
      },
      {
        path: "risk/portfolio-management",
        component: PortfolioManagement,
      },
      {
        path: "risk/portfolio-selection",
        component: PortfolioSelection,
      },
      {
        path: "risk/portfolio-confirmation",
        component: PortfolioConfirmation,
      },
      {
        path: "risk/profile",
        component: Profile,
      },
      { path: "preferences/theme-info", component: ThemeInfo },
      { path: "preferences/theme", component: Theme },
      { path: "account/introduction", component: AccountIntroduction },
      { path: "account/choose-transaction-account", component: ChooseTransactionAccount },
      { path: "account/add-account", component: AddAccount },
      { path: "account/first-deposit", component: FirstDeposit },
      { path: "account/monthly-savings", component: MonthlySavings },

      { path: "summary/summary-agreements", component: SummaryAgreementsSigmastocks },
      { path: "summary/done", component: DoneSigmastocks },

      { path: "agreements/introduction", component: AgreementsIntroduction },
      { path: "agreements/communication", component: AgreementsCommunication },
      { path: "agreements/authorized-signatories", component: AuthorizedSignatories },
      {
        path: "agreements/authorized-signatory-contact/:index",
        component: AuthorizedSignatoryContact,
      },
      { path: "agreements/summary", component: Summary },
      { path: "agreements/evida-congratulations", component: EvidaCongratulations },
      { path: "agreements/congratulations", component: Congratulations },
      {
        // Catch-all
        path: "*",
      },
    ],
  },
] as RouteRecordRaw[];
