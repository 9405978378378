<template>
  <div class="finshark">
    <template v-if="!error">
      <iframe v-if="paymentId && showIframe" :src="iframeSrc" style="width: 100%; height: 640px" />
      <loading-spinner v-else />
    </template>
    <template v-else>
      <div class="finshark__error-wrapper">
        <p class="finshark__error-title">{{ $t("couldNotDoTransaction") }}</p>
        <p class="finshark__error-body">{{ $t("pleaseTryAgain") }}</p>
        <common-button class="finshark__button" @click="reset">{{ $t("back") }}</common-button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import CommonButton from "@/components/button.vue";
import { PortfolioAction } from "@/store/portfolio/actions";
import { createAutoStartURL } from "@/store/bankid/actions";

export default defineComponent({
	emits: ["complete", "cancel", "reset"],
  components: {
    LoadingSpinner,
    CommonButton
  },
  props: {
    amount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showIframe: true,
      error: false
    };
  },
  computed: {
    iframeSrc(): string {
      if (import.meta.env.VITE_ENV === "production") {
        return `https://flows.finshark.io?clientId=c88e7d72-f443-7482-2402-92ea268bcd5c&region=SE&locale=SE&accountType=personal&type=payments&iframe=true&ssn=${
          this.personalIdentityNumber
        }&paymentId=${this.paymentId}&callbackUri=${this.getCallbackUri()}`;
      }
      return `https://flows.finshark.io?clientId=3a1fcb48-06ff-6d8a-7241-3471a0ff5d58&region=SE&locale=SE&accountType=personal&type=payments&iframe=true&ssn=${
        this.personalIdentityNumber
      }&paymentId=${this.paymentId}&sandbox=true&callbackUri=${this.getCallbackUri()}`;
    },
    personalIdentityNumber(): string | undefined {
      const { currentLegalEntity } = this.$store.state.userStore;
      if (currentLegalEntity) {
        return currentLegalEntity.personalIdentityNumber;
      }
      return undefined;
    },
    paymentId(): string | undefined {
      if (this.$store.state.portfolioStore.finsharkPaymentId) {
        return this.$store.state.portfolioStore.finsharkPaymentId;
      }
      return undefined;
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(PortfolioAction.createFinsharkPaymentRequest, this.amount);
      this.showIframe = true;
    } catch (error: any) {
      this.error = true;
    }
    window.addEventListener("message", async (message: any) => {
      if (message && message.data) {
        if (message.data.event === "session-complete") {
          this.showIframe = false;
          if (message.data.result === "Success") {
            this.$emit("complete");
          } else if (message.data.result === "Error") {
            this.error = true;
          } else if (message.data.result === "Canceled") {
            this.$emit("cancel");
          }
        } else if (message.data.event === "deeplink") {
          const deeplink = message.data.deeplink as string;
          // Finshark does have a redirect in its deeplink, but it redirects to the non-iframe one, not good.
          // We snatch the autostart token and use our homebrew bankid redirect URL which is tried and true
          const autostartToken = [...deeplink.matchAll(/autostarttoken=(.*?)&/g)][0][1];
          if (autostartToken) {
            const bankIdRedirectUrl = createAutoStartURL(autostartToken);
            if (bankIdRedirectUrl) {
              window.location.href = bankIdRedirectUrl;
            } else {
              // eslint-disable-next-line no-console
              console.error("Autostart URL was empty, exiting");
              this.error = true;
            }
          } else {
            // eslint-disable-next-line no-console
            console.error("Could not find autostarttoken, exiting");
            this.error = true;
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(`Unknown finshark data event: ${JSON.stringify(message.data)}`);
        }
      }
    });
  },
  methods: {
    reset() {
      this.$emit("reset");
    },
    getCallbackUri(): string {
      return `${import.meta.env.VITE_API_URL}/api/legal_entities/${
        this.$store.state.userStore.currentLegalEntity.brickId
      }/portfolios/${this.$store.state.portfolioStore.activePortfolioBase.id}/finshark/callback`;
    }
  }
});
</script>

<style lang="scss">
.finshark {
  width: 100%;
  height: 100%;
  &__error-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
    height: 100%;
  }
  &__error-title {
    font-size: 1.125rem;
    font-family: $heading-font-family;
  }
  &__error-body {
    font-size: 1rem;
    font-family: $heading-font-family;
    opacity: 0.7;
  }
  &__button {
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto !important;
    width: 100%;
  }
}
</style>
