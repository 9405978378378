<template>
  <div class="citizenship">
    <single-choice-template :alternatives="alternatives" :two-columns="true" @click="select">
      <question-text-section
        :heading="$t('create-portfolio.questions.aml.politically-exposed-ongoing.heading')"
        progress
      />
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupFlowModificationType } from "@/config/signup-modification";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  data() {
    return {
      associated: this.$router.currentRoute.value.path.includes("associated")
    };
  },
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("yes"),
          key: "yes"
        },
        {
          text: this.$t("no"),
          key: "no",
          secondary: true
        }
      ];
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    select(key: string): void {
      const terminated = key === "no";
      this.associated
        ? this.$store.commit(SignupMutation.setAssociatedPepTerminated, terminated)
        : this.$store.commit(SignupMutation.setPepTerminated, terminated);
      this.associated
        ? this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
            modification: SignupFlowModificationType.AssociatedPepTerminated,
            include: terminated
          })
        : this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
            modification: SignupFlowModificationType.PepTerminated,
            include: terminated
          });
      this.goToNext();
    }
  }
});
</script>

<style lang="scss" scoped>
.citizenship {
  height: 100%;
  width: 100%;
}
</style>
