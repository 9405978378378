import type { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from "vue-router";
import CreatePortfolio from "../views/create-portfolio/create-portfolio.vue";

import Welcome from "../views/create-portfolio/introduction/welcome.vue";

import PreviousExperience from "../views/create-portfolio/questions/risk/previous-experience.vue";
import PreviousExperienceAnswer from "../views/create-portfolio/questions/risk/previous-experience-answer.vue";
import StockInformation from "../views/common-signup/questions/risk/stock-information.vue";
import PortfolioManagement from "../views/common-signup/questions/risk/portfolio-management.vue";

import EconomyIntroduction from "../views/create-portfolio/questions/economy/introduction.vue";
import TimeToWithdraw from "../views/create-portfolio/questions/economy/time-to-withdraw.vue";
import TimeToWithdrawWarning from "../views/create-portfolio/questions/economy/time-to-withdraw-warning.vue";
import IncomeWarning from "../views/create-portfolio/questions/economy/income-warning.vue";
import Income from "../views/create-portfolio/questions/economy/income.vue";
import MoneyLeft from "../views/create-portfolio/questions/economy/money-left.vue";
import ExpensesWarning from "../views/create-portfolio/questions/economy/expenses-warning.vue";
import MonthlyRemainder from "../views/create-portfolio/questions/economy/remainder.vue";
import CurrentSavings from "../views/create-portfolio/questions/economy/current-savings.vue";
import CurrentSavingsWarning from "../views/create-portfolio/questions/economy/current-savings-warning.vue";

import BoundCapital from "../views/create-portfolio/questions/economy/bound-capital.vue";
import Loans from "../views/create-portfolio/questions/economy/loans.vue";
import LoansWarning from "../views/create-portfolio/questions/economy/loans-warning.vue";
import LoanedAmount from "../views/create-portfolio/questions/economy/loaned-amount.vue";
import LoanedAmountWarning from "../views/create-portfolio/questions/economy/loaned-amount-warning.vue";

import PreferencesIntroduction from "../views/create-portfolio/questions/preferences/introduction.vue";
import Sectors from "../views/create-portfolio/questions/preferences/sectors.vue";
import MarketFocus from "../views/create-portfolio/questions/preferences/market-focus.vue";
import Sustainability from "../views/common-signup/questions/preferences/sustainability.vue";
import SustainableInvestment from "../views/common-signup/questions/preferences/sustainable-investment.vue";
import NegativeImpact from "../views/common-signup/questions/preferences/negative-impact.vue";
import SustainableMethod from "../views/common-signup/questions/preferences/sustainable-method.vue";
import SustainableInfo from "../views/common-signup/questions/preferences/sustainable-choice-info.vue";
import SustainableChoice from "../views/common-signup/questions/preferences/sustainable-choice.vue";
import SustainableChoiceVerify from "../views/common-signup/questions/preferences/sustainable-choice-verify.vue";

import Focus from "../views/create-portfolio/questions/preferences/focus.vue";

import ThemeInfo from "../views/create-portfolio/questions/preferences/theme-info.vue";
import Theme from "../views/create-portfolio/questions/preferences/theme.vue";

import RiskIntroduction from "../views/create-portfolio/questions/risk/introduction.vue";
import PortfolioSelection from "../views/create-portfolio/questions/risk/portfolio-selection.vue";
import PortfolioConfirmation from "../views/create-portfolio/questions/risk/portfolio-confirmation.vue";

import LawAbout from "../views/create-portfolio/questions/aml/law-about.vue";
import Occupation from "../views/create-portfolio/questions/aml/occupation.vue";
import Interval from "../views/create-portfolio/questions/aml/interval.vue";
import WithdrawalInterval from "../views/create-portfolio/questions/aml/withdrawal-interval.vue";
import WithdrawalIntervalInformation from "../views/create-portfolio/questions/aml/withdrawal-interval-information.vue";
import Amount from "../views/create-portfolio/questions/aml/amount.vue";
import Purpose from "../views/create-portfolio/questions/aml/purpose.vue";
import InvestmentBackground from "../views/create-portfolio/questions/aml/investment-background.vue";

import PoliticallyExposed from "../views/create-portfolio/questions/aml/politically-exposed.vue";
import PoliticallyExposedAssociated from "../views/create-portfolio/questions/aml/politically-exposed-associated.vue";
import PoliticallyExposedAssociatedName from "../views/create-portfolio/questions/aml/politically-exposed-associated-name.vue";
import PoliticallyExposedAssociatedRelationshipRelative from "../views/create-portfolio/questions/aml/politically-exposed-associated-relationship-relative.vue";
import PoliticallyExposedAssociatedRelationshipCoworker from "../views/create-portfolio/questions/aml/politically-exposed-associated-relationship-coworker.vue";
import PoliticallyExposedAssociatedOrganization from "../views/create-portfolio/questions/aml/politically-exposed-associated-organization.vue";

import PoliticallyExposedCountry from "../views/create-portfolio/questions/aml/politically-exposed-country.vue";
import PoliticallyExposedOngoing from "../views/create-portfolio/questions/aml/politically-exposed-ongoing.vue";
import PoliticallyExposedSavings from "../views/create-portfolio/questions/aml/politically-exposed-savings.vue";
import PoliticallyExposedTitle from "../views/create-portfolio/questions/aml/politically-exposed-title.vue";
import PoliticallyExposedTerminated from "../views/create-portfolio/questions/aml/politically-exposed-terminated.vue";

import SwedishCitizenship from "../views/create-portfolio/questions/aml/swedish-citizenship.vue";
import CitizenShipDenial from "../views/create-portfolio/questions/aml/citizenship-denial.vue";
import TaxLiableUsa from "../views/create-portfolio/questions/aml/tax-liable-usa.vue";
import TaxLiableDenial from "../views/create-portfolio/questions/aml/tax-liable-denial.vue";
import TaxLiableOther from "../views/create-portfolio/questions/aml/tax-liable-other.vue";

import Citizenship from "../views/create-portfolio/questions/aml/citizenship.vue";
import SwedishTaxResidence from "../views/create-portfolio/questions/aml/swedish-tax-residence.vue";
import SwedishTaxResidenceDenial from "../views/create-portfolio/questions/aml/swedish-tax-residence-denial.vue";
import TaxableCountry from "../views/create-portfolio/questions/aml/taxable-country.vue";

import TinkInformation from "../views/create-portfolio/questions/account/tink-info.vue";
import TinkVerification from "../views/create-portfolio/questions/account/tink-verification.vue";
import FirstDeposit from "../views/create-portfolio/questions/account/first-deposit.vue";
import MonthlySavings from "../views/create-portfolio/questions/account/monthly-savings.vue";
import Summary from "../views/create-portfolio/questions/account/summary.vue";
import Communication from "../views/create-portfolio/questions/account/communication.vue";
import UseSameAccount from "../views/create-portfolio/questions/account/use-same-account.vue";
import ConnectBank from "../views/create-portfolio/questions/account/connect-bank.vue";

import SummarySign from "../views/create-portfolio/questions/account/summary-sign.vue";
import Congratulations from "../views/create-portfolio/questions/account/congratulations.vue";
import VerifyPersonalData from "../views/common-signup/questions/account/verify-personal-data.vue";

import Proposal from "../views/create-portfolio/questions/profile/proposal.vue";
import Profile from "../views/create-portfolio/questions/profile/profile.vue";
import YourMoney from "@/views/common-signup/questions/aml/your-money.vue";
import { CURRENT_SIGNUP_PATH_KEY_NEW } from "@/config/create-portfolio";
import store from "@/store";

export default [
  {
    path: "create-portfolio",
    name: "create-portfolio",
    component: CreatePortfolio,

    // Always allow the user to go to return to a page they have previously visited.
    // If they try to go to any other question, put them on the last visited path.
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (path?: RouteLocationRaw) => void,
    ) => {
      const { getters } = store as any;
      if (to.path.endsWith("/create-portfolio")) {
        // This check is here to avoid infinite recursion.
        next();
      } else {
        const latestVisitedPath = sessionStorage.getItem(CURRENT_SIGNUP_PATH_KEY_NEW);

        if (latestVisitedPath) {
          const latestVisitedQuestion = getters.questionProgress(latestVisitedPath);
          const toQuestion = getters.questionProgress(to.path);
          const hasVisitedToQuestion
            = latestVisitedQuestion
            && toQuestion
            && (toQuestion.partNumber < latestVisitedQuestion.partNumber
            || (toQuestion.partNumber === latestVisitedQuestion.partNumber
            && toQuestion.questionNumber <= latestVisitedQuestion.questionNumber));

          if (hasVisitedToQuestion) {
            next();
          } else if (to.path.includes("tink-verification")) {
            // We need to forward the query specifically because a simple call to next() strips the query.
            next({ params: to.params, query: to.query });
          } else {
            // The user tries to access a question or introduction which doesn't exist or which
            // they haven't reached using the normal flow. Put them on the latest visited path.
            next({ params: to.params, path: latestVisitedPath, replace: true });
          }
        } else if (to.path.includes("tink-verification")) {
          // Allow user to jump straight to tink verification to let the tink redirect work
          // We need to forward the query specifically because a simple call to next() strips the query.
          next({ params: to.params, query: to.query });
        } else {
          // The user has not yet started the signup flow. Put them on the signup start page.
          next({ params: to.params, name: "create-portfolio", replace: true });
        }
      }
    },
    children: [
      { path: "welcome", component: Welcome },
      { path: "previous-experience", component: PreviousExperience },
      { path: "previous-experience-answer", component: PreviousExperienceAnswer },
      { path: "risk/stock-information", component: StockInformation },
      { path: "portfolio-management", component: PortfolioManagement },
      { path: "economy/time-to-withdraw", component: TimeToWithdraw },
      {
        path: "economy/time-to-withdraw-warning",
        component: TimeToWithdrawWarning,
        name: "time-to-withdraw-warning-new",
      },
      { path: "economy/income", component: Income },
      { path: "economy/income-warning", component: IncomeWarning },
      { path: "economy/money-left", component: MoneyLeft },
      {
        path: "economy/expenses-warning",
        component: ExpensesWarning,
        name: "expenses-warning-new",
      },
      { path: "economy/remainder", component: MonthlyRemainder },
      { path: "economy/introduction", component: EconomyIntroduction },
      { path: "economy/current-savings", component: CurrentSavings },
      {
        path: "economy/current-savings-warning",
        component: CurrentSavingsWarning,
        name: "current-savings-warning-new",
      },
      { path: "economy/bound-capital", component: BoundCapital },
      { path: "economy/loans", component: Loans },
      {
        path: "economy/loans-warning",
        component: LoansWarning,
        name: "loans-warning-new",
      },
      { path: "economy/loaned-amount", component: LoanedAmount },
      {
        path: "economy/loaned-amount-warning",
        component: LoanedAmountWarning,
        name: "loaned-amount-warning-new",
      },
      { path: "preferences/introduction", component: PreferencesIntroduction },
      { path: "preferences/sectors", component: Sectors },
      { path: "preferences/market", component: MarketFocus },
      { path: "preferences/theme-info", component: ThemeInfo },
      { path: "preferences/theme", component: Theme },
      { path: "preferences/sustainability", component: Sustainability },
      {
        path: "preferences/sustainable-investment",
        component: SustainableInvestment,
        name: "sustainable-investment",
      },
      { path: "preferences/sustainable-method", component: SustainableMethod },
      { path: "preferences/sustainable-info", component: SustainableInfo },
      { path: "preferences/negative-impact", component: NegativeImpact },
      { path: "preferences/sustainable-choice", component: SustainableChoice },
      { path: "preferences/sustainable-choice-verify", component: SustainableChoiceVerify },
      { path: "preferences/focus", component: Focus },
      { path: "risk/introduction", component: RiskIntroduction },
      { path: "risk/portfolio-selection", component: PortfolioSelection },
      { path: "risk/portfolio-confirmation", component: PortfolioConfirmation },
      { path: "proposal", component: Proposal },
      { path: "risk-level", component: Profile },
      { path: "aml/law-about", component: LawAbout },
      { path: "aml/occupation", component: Occupation },
      { path: "aml/interval", component: Interval },
      { path: "aml/amount", component: Amount },
      { path: "aml/purpose", component: Purpose },
      { path: "aml/investment-background", component: InvestmentBackground },
      { path: "aml/withdrawal-interval", component: WithdrawalInterval },
      {
        path: "aml/withdrawal-interval-information",
        component: WithdrawalIntervalInformation,
        name: "withdrawal-interval-information-new",
      },
      { path: "aml/politically-exposed", component: PoliticallyExposed },
      { path: "aml/politically-exposed-title", component: PoliticallyExposedTitle },
      { path: "aml/politically-exposed-country", component: PoliticallyExposedCountry },
      { path: "aml/politically-exposed-ongoing", component: PoliticallyExposedOngoing },
      { path: "aml/politically-exposed-savings", component: PoliticallyExposedSavings },
      {
        path: "aml/politically-exposed-terminated",
        component: PoliticallyExposedTerminated,
      },
      {
        path: "aml/politically-exposed-associated",
        component: PoliticallyExposedAssociated,
      },
      {
        path: "aml/politically-exposed-associated-name",
        component: PoliticallyExposedAssociatedName,
      },
      {
        path: "aml/politically-exposed-associated-relationship-relative",
        component: PoliticallyExposedAssociatedRelationshipRelative,
      },
      {
        path: "aml/politically-exposed-associated-relationship-coworker",
        component: PoliticallyExposedAssociatedRelationshipCoworker,
      },
      { path: "aml/politically-exposed-associated-title", component: PoliticallyExposedTitle },
      { path: "aml/politically-exposed-associated-country", component: PoliticallyExposedCountry },
      { path: "aml/politically-exposed-associated-ongoing", component: PoliticallyExposedOngoing },
      { path: "aml/politically-exposed-associated-savings", component: PoliticallyExposedSavings },
      {
        path: "aml/politically-exposed-associated-organization",
        component: PoliticallyExposedAssociatedOrganization,
      },
      {
        path: "aml/politically-exposed-associated-terminated",
        component: PoliticallyExposedTerminated,
      },
      {
        path: "aml/your-money",
        component: YourMoney,
      },
      { path: "aml/swedish-citizenship", component: SwedishCitizenship },
      {
        path: "aml/citizenship-information",
        component: CitizenShipDenial,
        name: "citizenship-denial-new",
      },
      { path: "aml/tax-liable-usa", component: TaxLiableUsa },
      { path: "aml/tax-liable-other", component: TaxLiableOther },
      { path: "aml/tax-liable-denial", component: TaxLiableDenial, name: "tax-liable-denial-new" },
      { path: "aml/citizenship", component: Citizenship },
      { path: "aml/swedish-tax-residence", component: SwedishTaxResidence },
      {
        path: "aml/swedish-tax-residence-denial",
        component: SwedishTaxResidenceDenial,
        name: "swedish-tax-residence-denial-new",
      },
      { path: "aml/taxable-country", name: "taxable-country-new", component: TaxableCountry },
      { path: "account/connect-bank", component: ConnectBank },
      { path: "account/use-same-account", component: UseSameAccount },
      { path: "account/tink-info", component: TinkInformation },
      { path: "account/tink-verification", component: TinkVerification },
      { path: "account/first-deposit", component: FirstDeposit },
      { path: "account/monthly-savings", component: MonthlySavings },
      { path: "account/verify-personal-data", component: VerifyPersonalData },
      { path: "account/summary", component: Summary },
      { path: "account/communication", component: Communication },
      { path: "account/summary-sign", component: SummarySign },
      { path: "account/congratulations", component: Congratulations },
      {
        // Catch-all
        path: "*",
      },
    ],
  },
] as RouteRecordRaw[];
