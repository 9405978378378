<template>
  <div class="page-not-found">
    <div class="page-not-found__header-text">
      {{ $t("error-pages.page-not-found.opsPageNotFound") }}
    </div>
    <div class="page-not-found__description-text">
      {{ $t("error-pages.page-not-found.pageNotFoundDescription") }}
    </div>
    <div class="page-not-found__image">
      <img :src="imgUrl" />
    </div>
    <v-btn
      type="button"
      size="large"
      min-width="11.75rem"
      min-height="3.5rem"
      max-width="33.5rem"
      class="page-not-found__button"
      :color="softBlue"
      @click="() => $router.push({ name: 'login' })"
    >
      {{ $t("error-pages.page-not-found.backToStart") }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { softBlue } from "@/styles/colors";

export default defineComponent({
  head() {
    return {
      title: this.$t("error-pages.page-not-found.opsPageNotFound")
    };
  },
  data() {
    return {
      softBlue,
      imgUrl: new URL("/assets/images/signup/piggy-bank-light.svg", import.meta.url).href
    };
  },
  created(): void {
    document.documentElement.classList.add("not-found-responsive-rem");
  },
  unmounted(): void {
    document.documentElement.classList.remove("not-found-responsive-rem");
  }
});
</script>

<style lang="scss" scoped>
.page-not-found {
  max-width: 71rem;
  padding: 5.875rem 2.5rem 5rem 2.5rem;
  width: 100%;
  text-align: center;
  margin: 0 auto;

  @include small-only {
    padding: 7.25rem 2.5rem 7.25rem 2.5rem;
  }
  @include xlarge-up {
    padding: 8.75rem 2.5rem 8.75rem 2.5rem;
  }

  &__header-text {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    font-family: $heading-font-family;
    font-weight: bold;

    @include small-only {
      font-size: 2.125rem;
    }

    @include medium-up {
      font-size: 1.375rem;
    }

    @include xlarge-up {
      font-size: 2.125rem;
    }
  }
  &__description-text {
    font-weight: 300;
  }

  &__image {
    padding: 3rem 0 3.5rem 0;
  }

  &__button {
    color: $soft-blue !important;
    border-radius: 2rem;
    text-transform: none !important;
    font-size: 1.125rem !important;
    font-family: $heading-font-family;
    font-weight: 500;
    width: 100%;
    box-shadow: 0 0.1875rem 0.75rem #00000014;
    color: #fff !important;
    @include small-only {
      width: 24rem;
    }
  }
}

.not-found-responsive-rem {
  @include xsmall-up {
    font-size: 112.5%;
  }

  @include small-up {
    font-size: 140%;
  }

  @include medium-up {
    font-size: 100%;
  }

  @include xlarge-up {
    font-size: 150%;
  }
}
</style>
