<template>
  <div class="phone">
    <v-autocomplete
      v-model="selectedCountry"
      class="phone__autocomplete"
      :items="countries"
      return-object
      :no-data-text="$t('components.phone-number-input.noDataText')"
      :custom-filter="filterObject"
      :menu-props="menuProps"
      :disabled="disableLang"
      item-title="title"
      item-value="value"
      menu-icon=""
      item-props
      variant="underlined"
    >
      <template #selection="{ item }">
        <div>{{ item?.raw?.title }} (+{{ item?.raw?.prefixx }})</div>
      </template>
      <template #item="{ props, item }">
        <v-list-item v-bind="props">(+{{ item?.raw?.prefixx }})</v-list-item>
      </template>
      <template #append-inner>
        <icon
          style="font-size: 1.25rem"
          :icon="['fal', 'search']"
        /> </template
    ></v-autocomplete>
    <v-text-field
      v-model="phoneNumber"
      ref="phoneNumberInput"
      variant="underlined"
      style="max-width: 50%; width: 50%"
      :label="hidePhoneNumberLabel ? '' : $t('phoneNumber')"
      :rules="required ? [rules.phone, rules.required] : [rules.phone]"
      type="tel"
      data-cy="phone-number-input"
      validate-on="blur"
      @update:model-value="(val) => onChange(val)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import phoneMetadata from "./phone-metadata.json";
import {
  CountryCode,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber
} from "libphonenumber-js";

interface CountryItem {
  text: string;
  title: string;
  value: number;
  prefixx: number;
  code: string;
}

export default defineComponent({
  emits: ["update:model-value"],
  props: {
    initialPhoneNumber: {
      type: String,
      default: ""
    },
    disableLang: {
      type: Boolean,
      default: false,
      required: false
    },
    formName: {
      type: String,
      default: undefined,
      required: false
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    hidePhoneNumberLabel: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      phoneNumber: undefined as string | undefined,
      selectedCountry: undefined as CountryItem | undefined,
      rules: {
        phone: (value: string) => {
          if (value) {
            const selectedCountry = (this.$data as any).selectedCountry as CountryItem | undefined;
            let number: string;
            try {
              let phone = parsePhoneNumber(value, selectedCountry?.code as CountryCode);
              number = phone.format("E.164");
            } catch (error) {
              return this.$t("components.phone-number-input.invalidPhone");
            }
            return (
              isValidPhoneNumber(number, selectedCountry?.code as CountryCode) ||
              this.$t("components.phone-number-input.invalidPhone")
            );
          }
          return true;
        },
        required: (value: string) => !!value || this.$t("mandatory")
      }
    };
  },
  computed: {
    countries(): CountryItem[] {
      const list = phoneMetadata.map(
        (obj, index) =>
          ({
            code: obj.code,
            prefixx: Number(obj.dial_code.split("+")[1]),
            value: index,
            title: this.$t(`countries.${obj.code}`),
            text: ""
          }) as CountryItem
      );
      return list.sort((a, b) => (a.text > b.text ? 1 : -1));
    },
    menuProps() {
      return {
        contentClass: "autocomplete__menu",
        nudgeBottom: 8
      };
    }
  },
  watch: {
    initialPhoneNumber: {
      immediate: true,
      handler(newVal: string | undefined, oldVal: string | undefined) {
        if (!newVal && oldVal) {
          return;
        }
        if (!newVal) {
          this.selectedCountry = this.countries.find((c) => c.code === "SE");
        } else {
          // If the number is f.ex. 0708xxxxxx the library have no idea how to parse it and crashes. So default to sweden and let the form format the number again.
          // This will only happen until we have transformed all numbers
          try {
            const number = parsePhoneNumber(newVal);
            const prefix = number.countryCallingCode;
            if (prefix === "1") {
              this.selectedCountry = this.countries.find((c) => c.code === "US");
            } else {
              this.selectedCountry = this.countries.find((c) => c.prefixx.toString() === prefix);
            }
          } catch (error) {
            this.selectedCountry = this.countries.find((c) => c.code === "SE");
          }
        }
        this.phoneNumber = newVal;
      }
    },
    selectedCountry: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal !== undefined && oldVal !== null && newVal?.code !== oldVal?.code) {
          this.phoneNumber = undefined;
          this.$emit("update:model-value", undefined);
        }
      }
    }
  },
  methods: {
    onChange(val: string) {
      if (val && this.selectedCountry) {
        try {
          const selectedCountry = (this.$data as any).selectedCountry as CountryItem | undefined;

          let phone = parsePhoneNumber(val, selectedCountry?.code as CountryCode);
          const number = phone.format("E.164");
          if (isValidPhoneNumber(phone.number, selectedCountry?.code as CountryCode)) {
            this.phoneNumber = number;
            this.$emit("update:model-value", number);
          }
        } catch (error) {
          // Ignore
        }
      } else {
        this.$emit("update:model-value", this.phoneNumber);
      }
    },
    filterObject(value: string, query: string, item?: any) {
      if (!item) {
        return false;
      }
      return (
        item.raw?.code.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1 ||
        item.raw?.title.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1 ||
        item.raw?.prefixx.toString().toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1
      );
    }
  }
});
</script>
<style lang="scss" scoped>
.phone {
  display: flex;
  column-gap: 1rem;
  &__autocomplete {
    width: 2rem;
  }
}
</style>
