<template>
  <div class="leads-intro">
    <single-choice
      :alternatives="alternatives"
      @click="buttonClick"
    >
      <information-text-section
        :heading="$t('hello')"
        :body="$t('signup.introduction.leads-intro.body')"
      >
        <icon />
      </information-text-section>
    </single-choice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Icon from "@/assets/images/logos/sigmastocks-logo-single.svg";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    Icon
  },
  extends: Question,
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("createAccount"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    buttonClick() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.leads-intro {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
