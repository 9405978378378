<template>
  <div class="current-savings-warning">
    <single-choice
      :alternatives="informationAlternatives"
      @click="selectInformation"
    >
      <information-text-section
        :heading="
          $t('create-portfolio.questions.economy.current-savings-warning.informationHeading')
        "
        :body="$t('create-portfolio.questions.economy.current-savings-warning.informationBody')"
      >
        <icon />
      </information-text-section>
    </single-choice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Icon from "@/assets/images/signup/piggy-bank-light.svg";
import { SingleChoice as SingleChoiceType } from "@/types/signup";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    Icon
  },
  computed: {
    informationAlternatives(): SingleChoiceType[] {
      return [
        {
          text: this.$t("quit"),
          key: "quit"
        }
      ];
    }
  },
  methods: {
    selectInformation(key: string): void {
      if (key === "quit") {
        this.$router.push({ name: "login" });
      } else {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.current-savings-warning {
  height: 100%;
  width: 100%;
}
</style>
