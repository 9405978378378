<template>
  <v-autocomplete
    v-model="selectedCompany"
    @update:search="(val) => (search = val)"
    class="autocomplete__input"
    :items="items"
    variant="outlined"
    item-title="name"
    item-value="infrontCompanyCode"
    item-props
    :label="$t('portfolio-settings.exluded-companies.search.search')"
    :loading="isLoading"
    return-object
    menu-icon=""
    :menu-props="menuProps"
    :hide-no-data="hideNoData"
  >
    <template #no-data>
      <div class="autocomplete__no-data">
        <p class="autocomplete__no-data__title">
          {{ $t("portfolio-settings.exluded-companies.search.noResult") }}
        </p>
        <p class="autocomplete__no-data__body">
          {{ $t("portfolio-settings.exluded-companies.search.noResultBody") }}
        </p>
      </div>
    </template>
    <template #item="{ item, props }">
      <v-list-item v-bind="props">
        <v-list-item-title> {{ item.name }}</v-list-item-title>
        <template #prepend>
          <img
            v-if="item.raw.countryFullName"
            style="margin-right: 1rem; width: 1.2rem"
            :src="getFlagUrl(item.raw.countryFullName)"
          />
        </template>
      </v-list-item>
    </template>
    <template #append-inner>
      <icon
        style="font-size: 1.25rem"
        :icon="['fal', 'search']"
      />
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { defineComponent, nextTick, PropType } from "vue";
import { Company, ExcludedCompany, PortfolioCompaniesClient } from "@/clients";
import { baseUrl } from "@/clients/config";
import { getFlagUrl } from "@/config/flags";

const strategiesClient = new PortfolioCompaniesClient(baseUrl);

export default defineComponent({
  emits: ["selected"],
  props: {
    filteredCompanies: {
      type: Array as PropType<ExcludedCompany[]>,
      default: () => []
    }
  },
  data: () => ({
    selectedCompany: null as Company | null,
    isLoading: false,
    search: null as string | null,
    items: [] as Company[],
    searchTimerId: -1,
    getFlagUrl
  }),
  computed: {
    hideNoData(): boolean {
      if (!this.search) return true;
      return this.search?.length < 3 || this.items.length === 0 || this.isLoading;
    },
    menuProps() {
      return {
        contentClass: "autocomplete__menu",
        nudgeBottom: 8
      };
    }
  },
  watch: {
    search(newVal, oldVal) {
      if (!newVal || newVal.length < 3) {
        this.items = [];
        this.isLoading = false;
        return;
      }
      // If we type BOLI and then start typing BOLIDEN we dont make new request for the remainder of what you write but if you remove a character e.g to BOL then we want to query Mongstad again
      if (oldVal.length < 3 || newVal?.length < oldVal?.length) {
        this.isLoading = true;
        this.fetchWithDebounce();
      }
    },
    selectedCompany: {
      immediate: true,
      handler(newValue: Company) {
        if (newValue) {
          this.$emit("selected", newValue);
          nextTick(() => {
            this.reset();
          });
        }
      }
    }
  },
  methods: {
    reset() {
      this.selectedCompany = null;
      this.items = [];
    },
    fetchWithDebounce() {
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getCompanies();
      }, 500) as unknown as number; // There is two implementations of this function so typescript goes bananas
    },
    async getCompanies() {
      // Since we are debouncing, the input could have changed while waiting
      if (this.search && this.search?.length > 2) {
        await strategiesClient
          .searchCompanies(this.search)
          .then((res) => {
            if (res) {
              this.items = res.filter(
                (e) => this.filteredCompanies.map((e) => e.name).indexOf(e.name as string) === -1
              );
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.autocomplete {
  &__input {
    border-radius: 0.5rem;
    padding: 0 1rem;
  }
  &__no-data {
    padding: 1rem;
    &__title {
      font-weight: 600 !important;
      font-size: 1rem;
    }
    &__body {
      font-size: 0.875rem;
    }
  }
}
:deep(.v-list-item__mask) {
  font-weight: 700 !important;
  color: $nearly-black !important;
  background: inherit !important;
}
:deep(.v-list-item__title) {
  font-size: 1rem !important;
}
:deep(.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon) {
  transform: none;
}
:deep(.theme--light.v-list-item.v-list-item--highlighted::before) {
  opacity: 0.03 !important;
}
</style>
