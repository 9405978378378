<template>
  <div class="politically-exposed-title">
    <single-choice
      :alternatives="navigationAlternatives"
      :disabled="false"
      @click="goToNext"
    >
      <question-text-section
        :heading="$t('create-portfolio.questions.aml.politically-exposed-associated-name.heading')"
        progress
      />
      <div class="politically-exposed-title__form">
        <v-form ref="form">
          <v-text-field
            v-model="firstName"
            :label="$t('firstName')"
            :rules="[rules.required]"
            variant="underlined"
            data-cy="email-input"
            validate-on="blur"
          />
          <v-text-field
            v-model="lastName"
            :label="$t('lastName')"
            :rules="[rules.required]"
            variant="underlined"
            data-cy="email-input"
            validate-on="blur"
          />
          <v-text-field
            v-model="dateOfBirthOrSsn"
            :label="
              $t(
                'create-portfolio.questions.aml.politically-exposed-associated-name.dateOfBirthOrSsn'
              )
            "
            :rules="[rules.required]"
            variant="underlined"
            data-cy="email-input"
            validate-on="blur"
          />
          <p style="opacity: 0.66">
            {{
              $t(
                "create-portfolio.questions.aml.politically-exposed-associated-name.dateOfBirthOrSsnDescription"
              )
            }}
          </p>
        </v-form>
      </div>
    </single-choice>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { NavigationAlternative } from "@/types/signup";
import { softBlue } from "@/styles/colors";
import Question from "@/views/signup/templates/question";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice
  },
  extends: Question,
  data() {
    return {
      date: null,

      menu: false,
      rules: {
        required: (value: string) => !!value || this.$t("required")
      },
      showErrorMessage: false,
      softBlue,
      activePicker: undefined as string | undefined
    };
  },
  computed: {
    firstName: {
      get(): string | undefined {
        return this.$store.state.signupStore.associatedPepInfo.firstName;
      },
      set(value: any) {
        this.$store.commit(SignupMutation.setAssociatedPepFirstName, value);
      }
    },
    lastName: {
      get(): string | undefined {
        return this.$store.state.signupStore.associatedPepInfo.lastName;
      },
      set(value: any) {
        this.$store.commit(SignupMutation.setAssociatedPepLastName, value);
      }
    },
    dateOfBirthOrSsn: {
      get(): string | undefined {
        return this.$store.state.signupStore.associatedPepInfo.dateOfBirthOrSsn;
      },
      set(value: string) {
        this.$store.commit(SignupMutation.setAssociatedPepDateOfBirthOrSsn, value);
      }
    },
    navigationAlternatives(): Array<NavigationAlternative> {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    async goToNext(): Promise<void> {
      if ((await (this.$refs.form as any).validate()).valid) {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    }
  }
});
</script>
<style lang="scss">
.politically-exposed-title {
  &__form {
    max-width: 21.25rem;
    margin: 2rem auto 0 auto;
    display: flex;
    flex-direction: column;
  }
}
</style>
