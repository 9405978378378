<template>
  <div class="notification">
    <card-header
      class="notification__header"
      :title="$t('components.notification-settings.notifications')"
      :icon="['fal', 'bell']"
    >
      <edit-button
        v-if="editable && !errorLoadingNotificationSettings"
        :edit="edit"
        data-jest="edit-button"
        @click="toggleEdit"
      />
    </card-header>
    <v-list
      v-if="!errorLoadingNotificationSettings"
      variant="flat"
      :disabled="!edit"
      data-jest="notifications-list"
      v-model:selected="item"
    >
      <v-list-item
        v-for="(setting, i) in editedSettings"
        :key="i"
        class="notification__list-item"
        :active="!edit ? false : undefined"
        @click="handleClick(setting)"
      >
        <template v-slot:prepend>
          <div
            :class="[
              'notification__check-icon',
              {
                'notification__check-icon--hidden': !setting.enabled && !edit,
                'notification__check-icon--faded': !setting.enabled && setting.editable && edit
              }
            ]"
          >
            <icon
              fixed-width
              :icon="['fal', 'check']"
            />
          </div>
        </template>
        <v-list-item-title class="notification__item-title">{{ setting.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <div
      v-else
      class="notification__error-text"
      data-jest="notifications-error-text"
    >
      {{ $t("couldNotGetData") }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import EditButton from "@/components/edit-button.vue";
import { NotificationSettings } from "@/clients";
import { UserAction } from "@/store/user/action-definitions";
import { UserMutation } from "@/store/user/mutations";
import CardHeader from "@/components/card-header.vue";

interface NotificationAlternative {
  key: string;
  title: string;
  enabled: boolean;
  editable: boolean;
}

export default defineComponent({
  components: { EditButton, CardHeader },
  props: {
    editable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    edit: false,
    item: undefined,
    editedSettings: [] as NotificationAlternative[]
  }),
  computed: {
    ...mapGetters(["errorLoadingNotificationSettings"]),
    settings(): NotificationSettings | undefined {
      const { notificationSettings } = this.$store.state.userStore;
      if (notificationSettings) {
        return notificationSettings as NotificationSettings;
      }
      return undefined;
    }
  },
  watch: {
    settings: {
      handler() {
        this.editedSettings = this.settingsToNotificationAlternatives(this.settings);
      }
    }
  },
  mounted() {
    this.editedSettings = this.settingsToNotificationAlternatives(this.settings);
  },
  methods: {
    settingsToNotificationAlternatives(
      settings: NotificationSettings | undefined
    ): NotificationAlternative[] {
      if (settings) {
        return [
          {
            key: "newsLetterEnabled",
            title: this.$t("components.notification-settings.newsLetter"),
            enabled: settings.newsLetterEnabled,
            editable: true
          },
          {
            key: "savingsNotificationEnabled",
            title: this.$t("components.notification-settings.savingsNotification"),
            enabled: settings.savingsNotificationEnabled,
            editable: true
          },
          {
            key: "savingsReportEnabled",
            title: this.$t("components.notification-settings.savingsReport"),
            enabled: settings.savingsReportEnabled,
            editable: false
          }
        ];
      }
      return [];
    },
    handleClick(item: NotificationAlternative) {
      if (item.editable) item.enabled = !item.enabled;
    },
    async toggleEdit() {
      if (this.edit && this.settings) {
        const updatedSettings = this.editedSettings.reduce(
          (map, setting) => {
            map[setting.key] = setting.enabled;
            return map;
          },
          { id: this.settings.id }
        );
        await this.$store
          .dispatch(UserAction.updateNotificationSettings, updatedSettings)
          .catch((_) => {
            this.editedSettings = this.settingsToNotificationAlternatives(this.settings);
            this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("couldNotSaveChanges"));
          });
        this.$store.commit(UserMutation.decreaseUnsavedEdits);
        this.edit = false;
      } else {
        this.edit = true;
        this.$store.commit(UserMutation.increaseUnsavedEdits);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.notification {
  padding: 1.5rem 1.5rem 0.825rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__check-icon {
    width: 1.875rem;
    font-size: 1.5rem;
    color: rgba($nearly-black, 0.8);
    margin: 0 1rem 0 0 !important;
    align-self: center;
    &--faded {
      color: rgba($nearly-black, 0.15);
    }
    &--hidden {
      opacity: 0;
    }
  }
  &__item-title {
    font-size: 1rem;
    font-family: $heading-font-family;
    line-height: 1.3em;
    font-weight: 300;
  }
  &__list-item {
    padding: 0;
    border-radius: 0.5rem;
    &:hover {
      background-color: #f7f7f7;
    }
  }
  &__error-text {
    padding-top: 1rem;
    text-align: center;
  }
}
:deep(.v-list-item--disabled) {
  color: $nearly-black;
  opacity: 1;
}
</style>
