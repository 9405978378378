import type { UserState } from "./types";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";
import { softBlue } from "@/styles/colors";
import { getJwtToken } from "@/clients/authentication";

export const getUserInitialState = (): UserState => ({
  token: getJwtToken(),
  currentUser: undefined,
  notificationSettings: undefined,
  snackBarMessages: {},
  unsavedProfileEdits: 0,
  errorStates: {
    loadNotificationSettingsError: false,
    loadUserError: false,
    loadKycError: false,
    updateSettingsError: false,
  },
  loginErrorStatus: undefined,
  messages: undefined,
  loadingMessages: false,
  loadingPdf: [],
  archivePdfList: undefined,
  staticAgreements: undefined,
  editedLegalEntity: undefined,
  taxBasePdfs: undefined,
  manualPdfs: undefined,
  infoBanners: undefined,
  hideBanner: false,
  missingOrErrorAgreements: undefined,
  loadingCurrentUser: false,
  showReferralsDialog: false,
  agreementsToSign: undefined,
  insuranceNumbers: undefined,
  safeToReloadApp: true,
  companyRepresentatives: undefined,
  locale: "se",
  showGetStarted: false,
  currentLegalEntity: undefined,
  primaryColor: softBlue,
  tiedAgent: undefined,
  editedCompanyKnowYourCustomerAnswers: undefined,
  hubspotChatToken: undefined,
});

const state = getUserInitialState();

export default {
  state,
  mutations,
  getters,
  actions,
};
