<template>
  <div class="header">
    <div class="header__btn-wrapper">
      <icon-button
        v-show="!hideButtons && !shouldHideBackButton"
        :icon="['fal', 'chevron-left']"
        data-cy="button-back"
        @click="goBack"
      />
    </div>
    <div :class="['header__progress']">
      <transition
        name="fade"
        mode="out-in"
      >
        <img
          :src="image"
          v-if="image"
          width="100px"
        />
        <p
          v-else-if="$store.state.signupNavigationStore.questionProgress"
          data-jest="progress-heading"
          class="header__heading"
          :style="{ color: colors?.text }"
        >
          {{ progressHeading }}
        </p>
      </transition>
      <transition
        name="fade"
        mode="out-in"
      >
        <progress-bar
          v-if="$store.state.signupNavigationStore.questionProgress"
          v-show="$store.state.signupStore.showProgressBar"
          data-jest="progress-bar"
        />
      </transition>
    </div>
    <div class="header__btn-wrapper">
      <icon-button
        v-show="!hideButtons"
        :icon="['fal', 'times']"
        @click="closeSignup"
      />
    </div>
    <v-dialog
      v-model="confirmExitDialog"
      max-width="32.5rem"
      max-height="19.5rem"
      content-class="header__exit-dialog"
    >
      <v-card class="header__exit-dialog-card">
        <div class="header__exit-dialog-header">
          {{ $t("exitDialogHeader") }}
        </div>
        <div class="header__exit-dialog-body">
          {{ exitDialogText }}
        </div>
        <v-card-actions class="header__exit-dialog-actions">
          <common-button
            secondary
            @click="() => (confirmExitDialog = false)"
          >
            {{ $t("no") }}
          </common-button>
          <common-button
            :color="primaryColor"
            @click="confirmedExit"
          >
            {{ $t("yes") }}
          </common-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";

import CommonButton from "@/components/button.vue";
import { SignupNavigationState } from "@/store/signup-navigation/types";
import IconButton from "./icon-button.vue";
import ProgressBar from "./progress-bar.vue";
import { SignupState } from "@/store/signup/types";
import { Colors } from "@/styles/colors";

export default defineComponent({
  emits: ["exit"],
  components: {
    ProgressBar,
    IconButton,
    CommonButton
  },
  props: {
    hideButtons: {
      type: Boolean,
      default: false
    },
    exitDialogText: {
      type: String,
      default: ""
    },
    hasStartedSignup: {
      type: Boolean,
      default: false
    },
    progressHeadingKey: {
      type: String,
      default: ""
    },
    progressHeadings: {
      type: Array as () => string[] | undefined,
      default: undefined
    }
  },
  data: () => ({ confirmExitDialog: false }),
  computed: {
    colors(): Colors | undefined {
      const { colors } = this.$store.state.signupStore as SignupState;
      return colors || undefined;
    },
    primaryColor(): string {
      return this.$store.state.signupStore.primaryColor;
    },
    completedSignUp(): boolean {
      return this.$store.state.signupStore.completedSignUp;
    },
    shouldHideBackButton(): boolean {
      if (this.$store.state.signupNavigationStore.questionProgress) {
        return (
          this.$store.state.signupNavigationStore.questionProgress.partNumber === 1 &&
          this.$store.state.signupNavigationStore.questionProgress.questionNumber === 1
        );
      }
      return true;
    },
    progressHeading(): string {
      const { progressHeadings } = this.$store.state.signupNavigationStore as SignupNavigationState;
      if (progressHeadings) {
        return progressHeadings[
          this.$store.state.signupNavigationStore.questionProgress.partNumber - 1
        ];
      }
      return "";
    },
    image() {
      const { showEvidaLogoHeader } = this.$store.state.signupStore as SignupState;
      if (showEvidaLogoHeader) {
        return new URL("/assets/images/logos/evida-logo-orange.svg", import.meta.url).href;
      }
      return undefined;
    }
  },
  methods: {
    closeSignup(): void {
      // Only prompt dialog if user has made any changes to the state.

      if (this.hasStartedSignup) {
        this.confirmExitDialog = true;
      } else {
        this.confirmedExit();
      }
    },
    confirmedExit(): void {
      this.$emit("exit");
      this.$store.dispatch(SignupNavigationAction.resetCurrentPath);
    },
    goBack(): void {
      if (!this.completedSignUp) {
        const { name } = this.$router.currentRoute.value;
        // Only prompt dialog if user has made any changes to the state.
        if (name === "signup" && this.hasStartedSignup) {
          this.closeSignup();
        } else if (name && name.toString().includes("warning")) {
          this.$router.go(-1);
        } else {
          this.$store.dispatch(SignupNavigationAction.goToPreviousStep, this.$router);
        }
      } else {
        this.closeSignup();
      }
    }
  }
});
</script>

<style lang="scss" scoped>
$icon-opacity: 0.15;
.header {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  height: 2rem;
  &__heading {
    font-family: sofia-pro, sans-serif;
    font-weight: lighter;
    font-size: 1rem;
    margin: 0 0.2rem;
    text-align: center;
    @include medium-up {
      margin: 0 1rem;
    }
  }
  &__close-button {
    font-size: 1.125rem;
  }
  &__progress {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @include medium-up {
      width: 100%;
    }
  }
  &__exit-dialog-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.875rem 1rem 1.5rem 1rem;
  }
  &__exit-dialog-header {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    font-family: $heading-font-family;
  }
  &__exit-dialog-body {
    margin-bottom: 3.25rem;
  }
  &__exit-dialog-actions {
    column-gap: 1rem;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
