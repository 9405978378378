<template>
  <single-choice-template
    class="swedish-tax-residence"
    :alternatives="alternatives"
    @click="select"
  >
    <question-text-section
      :heading="$t('common-signup.questions.aml.swedish-tax-residence.heading')"
      :body="$t('taxableBody')"
      progress
    />
  </single-choice-template>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";

import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";

export default defineComponent({
  emits: ["next"],
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("no"),
          key: "noSwedishTaxResidence",
          secondary: true
        },
        {
          text: this.$t("yes"),
          key: "hasSwedishTaxResidence"
        }
      ];
    }
  },
  methods: {
    select(key: string): void {
      this.$store.commit(
        SignupMutation.setHasSwedishTaxResidence,
        key === "hasSwedishTaxResidence"
      );
      this.$emit("next", key);
    }
  }
});
</script>

<style lang="scss" scoped>
.swedish-tax-residence {
  height: 100%;
  width: 100%;
}
</style>
