<template>
  <div class="politically-exposed-organization">
    <single-choice
      :alternatives="navigationAlternatives"
      :disabled="false"
      @click="goToNext"
    >
      <question-text-section
        :heading="
          $t('create-portfolio.questions.aml.politically-exposed-associated-organization.heading')
        "
        progress
      />
      <div class="politically-exposed-organization__form">
        <v-form ref="form">
          <v-text-field
            v-model="organizationName"
            variant="underlined"
            :label="
              $t(
                'create-portfolio.questions.aml.politically-exposed-associated-organization.organizationName'
              )
            "
            :rules="[rules.required]"
            data-cy="email-input"
            validate-on="blur"
          />
          <v-text-field
            v-model="organizationNumber"
            variant="underlined"
            :label="$t('organizationNumber')"
            :rules="[rules.required]"
            data-cy="email-input"
            validate-on="blur"
          />
        </v-form>
      </div>
    </single-choice>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { NavigationAlternative } from "@/types/signup";
import { softBlue } from "@/styles/colors";
import Question from "@/views/signup/templates/question";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice
  },
  extends: Question,
  data() {
    return {
      rules: {
        required: (value: string) => !!value || this.$t("required")
      },
      showErrorMessage: false,
      softBlue
    };
  },
  computed: {
    organizationName: {
      get(): string | undefined {
        return this.$store.state.signupStore.associatedPepInfo.organization;
      },
      set(value: any) {
        this.$store.commit(SignupMutation.setAssociatedPepOrganization, value);
      }
    },
    organizationNumber: {
      get(): string | undefined {
        return this.$store.state.signupStore.associatedPepInfo.organizationNumber;
      },
      set(value: any) {
        this.$store.commit(SignupMutation.setAssociatedPepOrganizationNumber, value);
      }
    },
    navigationAlternatives(): Array<NavigationAlternative> {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    async goToNext(): Promise<void> {
      if ((await (this.$refs.form as any).validate()).valid) {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    }
  }
});
</script>
<style lang="scss">
.politically-exposed-organization {
  &__form {
    max-width: 21.25rem;
    margin: 2rem auto 0 auto;
    display: flex;
    flex-direction: column;
  }
}
.v-menu__content {
  max-width: 100%;
}
</style>
