import { GetterTree } from "vuex";
import { formatPersonalIdentityNumber } from "@/utils/formatters";
import {
  CreatePersonLegalEntity,
  DepositFrequency,
  DepositSize,
  KnowYourCustomerAnswers,
  LegalEntity,
  Occupation,
  WithdrawalFrequency
} from "@/clients";
import { NumberMap } from "@/types/signup";
import { QuestionProgress, SignupNavigationState } from "./types";
import { RootState } from "../../types";

export const getters: GetterTree<SignupNavigationState, RootState> = {
  numberOfParts(state): number {
    return state.signupPaths.questionPaths.length;
  },

  /**
   * Returns the current question progress based on the path. Returns undefined if the path
   * cannot be found in the question path array.
   */
  questionProgress:
    (state) =>
    (path: string): QuestionProgress | undefined => {
      if (path.match("^/(se|no)/")) {
        path = path.substring(3);
      }
      const { questionPaths } = state.signupPaths;
      for (let i = 0; i < questionPaths.length; i += 1) {
        for (let j = 0; j < questionPaths[i].length; j += 1) {
          if (questionPaths[i][j] === path) {
            return {
              partNumber: i + 1,
              questionNumber: j + 1
            };
          }
        }
      }
      return undefined;
    },
  /**
   * Returns a map of the number of questions, based per part.
   */
  numberOfQuestionsPerPart(state): NumberMap {
    const { questionPaths } = state.signupPaths;
    const numberOfQuestionsPerPart: NumberMap = {};
    for (let i: number = 0; i < Object.keys(questionPaths).length; i += 1) {
      numberOfQuestionsPerPart[i + 1] = questionPaths[i].length;
    }
    return numberOfQuestionsPerPart;
  },

  /**
   * Returns undefined if there is no further step, otherwise the next question
   */
  nextQuestion(state, getters): QuestionProgress | undefined {
    const { questionProgress } = state;
    if (questionProgress === undefined) {
      return {
        partNumber: 1,
        questionNumber: 1
      };
    }

    const { numberOfParts } = getters;
    const numberOfQuestionsInCurrentPart =
      getters.numberOfQuestionsPerPart[questionProgress.partNumber];

    // If the user is already at the final question we can't go any further
    if (
      questionProgress.partNumber === numberOfParts &&
      questionProgress.questionNumber === numberOfQuestionsInCurrentPart
    ) {
      return undefined;
    }

    // If the current part hasn't been completed, go to the next question in the part
    if (questionProgress.questionNumber < numberOfQuestionsInCurrentPart) {
      return {
        partNumber: questionProgress.partNumber,
        questionNumber: questionProgress.questionNumber + 1
      };
    }

    // The user is on the last question of a part. Go to the first question of the next part.
    return {
      partNumber: questionProgress.partNumber + 1,
      questionNumber: 1
    };
  },

  /**
   * Returns undefined if there is no  step, otherwise the next question
   */
  previousQuestion(state, getters): QuestionProgress | undefined {
    const { questionProgress } = state;
    if (questionProgress === undefined) {
      return undefined;
    }
    const { numberOfParts } = getters;
    const numberOfQuestionsInCurrentPart =
      getters.numberOfQuestionsPerPart[questionProgress.partNumber];
    const numberOfQuestionsInPreviousPart =
      getters.numberOfQuestionsPerPart[questionProgress.partNumber - 1];

    // If the user is at the first question, instead go to a previous question
    if (
      questionProgress.partNumber === numberOfParts &&
      questionProgress.questionNumber === numberOfQuestionsInCurrentPart
    ) {
      return undefined;
    }

    // If the current question isn't the first, go to the previous question in the part
    if (questionProgress.questionNumber !== 1) {
      return {
        partNumber: questionProgress.partNumber,
        questionNumber: questionProgress.questionNumber - 1
      };
    }

    // The user is on the first question of a part. Go to the last question of the previous part.
    return {
      partNumber: questionProgress.partNumber - 1,
      questionNumber: numberOfQuestionsInPreviousPart
    };
  }
};
