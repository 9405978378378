<template>
  <div class="profile">
    <SectionHeader
      v-if="$vuetify.display.mdAndUp"
      :header="$t('profile')"
      class="profile__header"
    >
      <template #icon>
        <icon
          :icon="['fal', 'user-cog']"
          size="lg"
        />
      </template>
      <v-menu location="bottom">
        <template #activator="{ props }">
          <span>
            <HeaderButton
              v-bind="props"
              :icon="['fal', 'cog']"
              :tooltip-text="$t('profile.profile.showSettings')"
              :input-value="true"
            />
          </span>
        </template>
        <SettingsList
          @click:terminate-account="() => (showTerminateAccountDialog = true)"
        />
      </v-menu>
    </SectionHeader>
    <div class="profile__cards">
      <v-card class="profile__contact-card">
        <Contact
          editable
          @save="openAgreementsDialog('contact')"
        />
      </v-card>
      <v-card class="profile__personaldata-card">
        <PersonalData />
      </v-card>
      <v-card class="profile__notifications-card">
        <NotificationSettings editable />
      </v-card>
      <v-card
        v-if="portfolios && portfolios.length > 0"
        class="profile__economy-card"
      >
        <Economy @save="openAgreementsDialog('economy')" />
      </v-card>
      <v-card
        v-if="portfolios && portfolios.length > 0"
        class="profile__personalinfo-card"
      >
        <PersonalInfo @save="openDialogSaveKyc()" />
      </v-card>
      <v-card class="settings__discounts-card">
        <Discounts />
      </v-card>
    </div>
    <WarningDialog
      :model-value="showWarningDialog"
      @cancel="showWarningDialog = false"
      @discard="discardEditsAndRoute"
    />
    <v-menu location="top">
      <template #activator="{ props }">
        <v-btn
          v-show="$vuetify.display.smAndDown"
          class="profile__settings-button"
          icon
          color="white"
          width="3rem"
          height="3rem"
          v-bind="props"
        >
          <icon
            class="profile__settings-button-icon"
            :icon="['fal', 'cog']"
          />
        </v-btn>
      </template>
      <SettingsList
        @click:terminate-account="() => (showTerminateAccountDialog = true)"
      />
    </v-menu>
    <TerminateAccountDialog v-model="showTerminateAccountDialog" />
    <UpdateAgreementsDialog
      v-if="showUpdateAgreementsDialog"
      :model-value="showUpdateAgreementsDialog"
      :setting-to-save="settingToSave"
      :update-settings-error="updateSettingsError"
      :signing-complete="signComplete"
      :sign-location="SignLocation.PROFILE"
      @update:model-value="showUpdateAgreementsDialog = false"
      @save="handleAgreementDialogSave"
      @cancel="handleAgreementDialogCancel"
      @agreements="showAgreementsDialog = true"
      @prepare="handleAgreementDialogPrepare"
    />
    <AgreementsSideDialog
      v-model="showAgreementsDialog"
      :portfolios="portfolios"
      :preview-mode="true"
    />
    <ValidationChangeDialog
      v-if="showValidationChangeDialog"
      :model-value="showValidationChangeDialog"
      :validation-action="validationAction"
      :portfolios="portfoliosWithNewRecommendedRiskLevels"
      :signing-complete="signComplete"
      @update:model-value="closeValidation"
    />
    <v-dialog
      v-model="showSavingWaitDialog"
      content-class="kyc-validation"
    >
      <v-card class="kyc-validation-dialog">
        <span>{{ $t("profile.profile.savingChanges") }}</span> <LoadingSpinner />
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import PersonalData from "./personal-data.vue";
import Economy from "./economy.vue";
import PersonalInfo from "./personal-info.vue";
import SettingsList from "./settings-list.vue";
import TerminateAccountDialog from "./terminate-account/terminate-account.vue";
import ValidationChangeDialog from "./validation-change-dialog.vue";
import Discounts from "./discounts.vue";
import SectionHeader from "@/components/section-header.vue";
import { NavigationMutation } from "@/store/navigation/mutations";
import { UserAction } from "@/store/user/action-definitions";
import { UserMutation } from "@/store/user/mutations";
import WarningDialog from "@/components/unsaved-edits-dialog.vue";
import HeaderButton from "@/components/header-button.vue";
import UpdateAgreementsDialog from "@/components/update-agreements-dialog.vue";
import AgreementsSideDialog from "@/components/preview-agreements-side-dialog.vue";
import type {
  LegalEntityValidationResponse,
  Portfolio,
} from "@/clients";
import {
  LegalEntity,
  LegalEntityValidationAction,
  PdfType,
  PortfolioRecommendedRiskLevel,
} from "@/clients";
import clearStore from "@/utils/clear-store";
import Contact from "@/components/contact.vue";
import NotificationSettings from "@/components/notification-settings.vue";
import { PortfolioAction } from "@/store/portfolio/actions";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { BankIdMutation } from "@/store/bankid/mutations";

import { SignLocation } from "@/store/bankid/types";

const EDITED_LEGAL_ENTITY_KEY = "EDITED_LEGAL_ENTITY";
const PROFILE_SETTINGS_TO_SAVE_KEY = "PROFILE_SETTINGS_TO_SAVE";
const PROFILE_VALIDATION_CHANGE_PORTFOLIOS_KEY = "PROFILE_VALIDATION_CHANGE_PORTFOLIOS";
const PROFILE_VALIDATION_ACTION_KEY = "PROFILE_VALIDATION_ACTION";

export default defineComponent({
  components: {
    TerminateAccountDialog,
    SectionHeader,
    Contact,
    NotificationSettings,
    PersonalData,
    Economy,
    WarningDialog,
    SettingsList,
    HeaderButton,
    PersonalInfo,
    UpdateAgreementsDialog,
    AgreementsSideDialog,
    ValidationChangeDialog,
    LoadingSpinner,
    Discounts,
  },
  beforeRouteLeave(to, from, next) {
    this.nextRoute = to;
    if (this.hasUnsavedProfileEdits && !this.discardEdits) {
      this.showWarningDialog = true;
      // Abort the route change
      next(false);
    }
 else {
      // Let the user continue
      next();
    }
  },
  data: () => ({
    showTerminateAccountDialog: false,
    showWarningDialog: false,
    discardEdits: false,
    nextRoute: {},
    showUpdateAgreementsDialog: false,
    settingToSave: "",
    showAgreementsDialog: false,
    showValidationChangeDialog: false,
    showSavingWaitDialog: false,
    signComplete: false,
    SignLocation,
    validationAction: LegalEntityValidationAction.NoChange,
    portfoliosWithNewRecommendedRiskLevels: [] as PortfolioRecommendedRiskLevel[],
  }),
  head() {
    return {
      title: this.$t("profile"),
    };
  },
  computed: {
    ...mapGetters(["hasUnsavedProfileEdits"]),
    updateSettingsError(): boolean {
      return this.$store.state.userStore.errorStates.updateSettingsError;
    },
    portfolios(): Portfolio[] {
      return this.$store.state.portfolioStore.allPortfolios;
    },
    phone: {
      set(value: string) {
        let { contactInformation } = this.$store.state.userStore;
        contactInformation = { ...contactInformation, phoneNumber: value };
        this.$store.commit(UserMutation.editContactInformation, contactInformation);
      },
      get(): string {
        return this.$store.state.userStore.contactInformation.phone;
      },
    },
    email: {
      set(value: string) {
        let { contactInformation } = this.$store.state.userStore;
        contactInformation = { ...contactInformation, email: value };
        this.$store.commit(UserMutation.editContactInformation, contactInformation);
      },
      get(): string {
        return this.$store.state.userStore.contactInformation.email;
      },
    },
  },
  async created() {
    this.$store.commit(UserMutation.resetUnsavedEdits);
    if (this.$store.state.portfolioStore.allPortfolios.length === 0) {
      await this.$store.dispatch(PortfolioAction.loadAllPortfolios);
    }

    if (!this.$store.state.userStore.currentUser) {
      await this.$store.dispatch(UserAction.loadCurrentUser);
    }
    if (!this.$store.state.userStore.notificationSettings) {
      await this.$store.dispatch(UserAction.loadNotificationSettings);
    }
    if (
      this.$store.getters.isCompletingSignatureSuccess(SignLocation.PROFILE)
      || this.$store.getters.isCompletingSignatureSuccess(SignLocation.LEGAL_ENTITY_VALIDATION_CHANGE)
    ) {
      this.settingToSave = sessionStorage.getItem(PROFILE_SETTINGS_TO_SAVE_KEY)!;
      this.$store.commit(
        UserMutation.setEditedLegalEntity,
        LegalEntity.fromJS(JSON.parse(sessionStorage.getItem(EDITED_LEGAL_ENTITY_KEY)!)),
      );
      this.signComplete = true;
      if (this.$store.getters.isCompletingSignatureSuccess(SignLocation.PROFILE)) {
        this.showUpdateAgreementsDialog = true;
      } else {
        const portfolios = JSON.parse(
          sessionStorage.getItem(PROFILE_VALIDATION_CHANGE_PORTFOLIOS_KEY)!,
        );
        this.portfoliosWithNewRecommendedRiskLevels = portfolios.map(
          (p: any) => new PortfolioRecommendedRiskLevel({ ...p }),
        );
        this.validationAction = Number.parseInt(
          sessionStorage.getItem(PROFILE_VALIDATION_ACTION_KEY)!,
          10,
        ) as LegalEntityValidationAction;
        this.showValidationChangeDialog = true;
      }

      this.$store.commit(BankIdMutation.completeRedirectSignature);
    } else if (
      this.$store.getters.isCompletingSignatureError(SignLocation.PORTFOLIO_SETTINGS)
      || this.$store.getters.isCompletingSignatureError(SignLocation.LEGAL_ENTITY_VALIDATION_CHANGE)
    ) {
      this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("signError"));
      this.signComplete = false;
      this.$store.commit(BankIdMutation.completeRedirectSignature);
    }
  },
  mounted() {
    this.$store.commit(NavigationMutation.setBackRoute, { name: "overview" });
    this.$store.commit(NavigationMutation.setMobileHeading, this.$t("profile"));
  },
  methods: {
    closeValidation(e: any) {
      this.showValidationChangeDialog = false;
    },
    discardEditsAndRoute() {
      this.showWarningDialog = false;
      this.discardEdits = true;
      this.$router.push(this.nextRoute);
      this.resetContactInformation();
      this.resetEconomy();
      this.resetKyc();
    },
    async openDialogSaveKyc() {
      if (
        this.$store.state.userStore.editedLegalEntity.knowYourCustomerAnswers.taxableUsa
        || !this.$store.state.userStore.editedLegalEntity.knowYourCustomerAnswers
          .swedishTaxResidence
          || !this.$store.state.userStore.editedLegalEntity.knowYourCustomerAnswers.livingInSweden
      ) {
        this.showSavingWaitDialog = true;
        await this.$store.dispatch(UserAction.prepareUpdateKyc);
        this.showSavingWaitDialog = false;
      } else {
        this.openAgreementsDialog("kyc");
      }
    },
    openAgreementsDialog(type: string) {
      this.showUpdateAgreementsDialog = true;
      this.settingToSave = type;
      sessionStorage.setItem(PROFILE_SETTINGS_TO_SAVE_KEY, this.settingToSave);
      sessionStorage.setItem(
        EDITED_LEGAL_ENTITY_KEY,
        JSON.stringify(this.$store.state.userStore.editedLegalEntity),
      );
    },
    resetContactInformation() {
      this.$store.commit(UserMutation.resetContactInformation);
    },
    resetEconomy() {
      this.$store.commit(UserMutation.resetEconomy);
    },
    resetKyc() {
      this.$store.commit(UserMutation.resetKyc);
    },
    async handleAgreementDialogPrepare(event: string, resolve: () => void) {
      try {
        await this.handleAgreementDialogEvent("prepare", event);
      } catch (e) {
        this.$store.commit(UserMutation.setUpdateSettingsError, true);
      }
      resolve();
    },
    async handleAgreementDialogSave(event: string, resolve: () => void) {
      this.signComplete = false;
      await this.handleAgreementDialogEvent("save", event);
      resolve();
    },
    handleAgreementDialogCancel(event: string) {
      this.signComplete = false;
      this.handleAgreementDialogEvent("cancel", event);
      this.showUpdateAgreementsDialog = false;
    },
    async handleAgreementDialogEvent(type: string, event: string) {
      if (type === "prepare") {
        switch (event) {
          case "contact":
            await this.$store.dispatch(UserAction.prepareUpdateLegalEntityContact);
            break;
          case "economy":
            await this.$store.dispatch(UserAction.prepareUpdateLegalEntityEconomy);
            break;
          case "kyc":
            await this.$store.dispatch(UserAction.prepareUpdateKyc);
            break;
          default:
            break;
        }
      } else if (type === "save") {
        switch (event) {
          case "contact":
            await this.$store.dispatch(UserAction.completeUpdateLegalEntityContact);
            break;
          case "economy":
            await this.completeUpdateLegalEntityEconomy();
            break;
          case "kyc":
            await this.completeUpdateLegalEntityKyc();
            break;
          default:
            break;
        }
      } else if (type === "cancel") {
        this.$store.commit(UserMutation.setUpdateSettingsError, false);
        switch (event) {
          case "contact":
            this.resetContactInformation();
            break;
          case "economy":
            this.resetEconomy();
            break;
          case "kyc":
            this.resetKyc();
            break;
          default:
            break;
        }
        this.$store.commit(BankIdMutation.resetBankId);
      }
    },
    async completeUpdateLegalEntityEconomy() {
      const response: LegalEntityValidationResponse | undefined = await this.$store.dispatch(
        UserAction.completeUpdateLegalEntityEconomy,
      );
      if (response) {
        // economy questions update only affect poor economy or new recommended risklevels so ignore terminate customer
        if (response.validationAction !== LegalEntityValidationAction.TerminateCustomer) {
          this.handleUpdateLegalEntityUpdateResponse(response);
        }
      }
    },
    async completeUpdateLegalEntityKyc() {
      const response: LegalEntityValidationResponse | undefined = await this.$store.dispatch(
        UserAction.completeUpdateKyc,
      );
      if (response) {
        // pep questions update only affect if we should terminate the customer so ignore the other recommendations
        if (response.validationAction === LegalEntityValidationAction.TerminateCustomer) {
          this.handleUpdateLegalEntityUpdateResponse(response);
        }
      }
    },
    handleUpdateLegalEntityUpdateResponse(validation: LegalEntityValidationResponse) {
      this.showSavingWaitDialog = false;
      switch (validation.validationAction) {
        case LegalEntityValidationAction.TerminateCustomer:
        case LegalEntityValidationAction.VulnerableEconomy:
          this.validationAction = validation.validationAction;
          break;
        case LegalEntityValidationAction.NewRecommendedRiskLevel:
          this.validationAction = validation.validationAction;
          if (validation.portfolioRecommendedRiskLevels) {
            this.portfoliosWithNewRecommendedRiskLevels = validation.portfolioRecommendedRiskLevels;
          }
          break;
        default:
          break;
      }
      if (validation.validationAction !== LegalEntityValidationAction.NoChange) {
        sessionStorage.setItem(PROFILE_VALIDATION_ACTION_KEY, this.validationAction.toString());
        sessionStorage.setItem(
          PROFILE_VALIDATION_CHANGE_PORTFOLIOS_KEY,
          JSON.stringify(this.portfoliosWithNewRecommendedRiskLevels),
        );
        this.showValidationChangeDialog = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.profile {
  max-width: 71rem;
  padding: 0.25rem 1rem 5rem 1rem;
  width: 100%;
  @include medium-up {
    padding: unset;
  }
  &__header {
    width: 100%;
  }
  &__cards {
    display: grid;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    grid-template-rows: auto;
    @include small-up {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto auto;
    }
    @include medium-up {
      margin-top: 2rem;
      grid-row-gap: 1.5rem;
      grid-column-gap: 1.5rem;
    }
  }
  &__contact-card {
    max-height: 15rem;
    @include small-up {
      grid-column: 1;
      grid-row: 1/2;
    }
  }
  &__personaldata-card {
    @include small-up {
      grid-column: 1;
      grid-row: 2/5;
    }
  }
  &__notifications-card {
    max-height: 15rem;
    @include small-up {
      grid-column: 1;
      grid-row: 5;
    }
  }
  &__economy-card {
    @include small-up {
      grid-row: 1/4;
      grid-column: 2;
    }
  }
  &__personalinfo-card {
    max-height: 44rem;
    @include small-up {
      grid-row: 4/8;
    }
  }
  &__settings-button {
    display: flex;
    margin: 1rem auto 0 auto;
    box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.1);
    &:before {
      background: transparent;
    }
    &.focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
    &:hover {
      @include cursorHover {
        box-shadow: 0 0.1875rem 0.825rem rgba(0, 0, 0, 0.15);
      }
    }
  }
  &__settings-button-icon {
    font-size: 1.25rem;
  }
}
.v-menu__content {
  border-radius: 0.5rem;
  box-shadow: 0 0.1875rem 2rem rgba(0, 0, 0, 0.32);
  max-width: 15.625rem;
  @include small-down {
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
  }
}
.kyc-validation-dialog {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  &__card {
    min-height: 25rem;
    @include small-up {
      min-height: 22rem;
    }
  }
}
:deep(.kyc-validation) {
  max-width: 30rem;
}
</style>
