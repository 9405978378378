<template>
  <v-dialog
    content-class="create-portfolio-wrapper"
    :model-value="modelValue"
    @update:model-value="inputChange"
  >
    <v-card>
      <div class="create-portfolio-dialog">
        <v-btn
          icon
          fixed-width
          data-test="close"
          variant="flat"
          class="create-portfolio-dialog__close-button"
          @click="close"
        >
          <icon
            fixed-width
            :icon="['fal', 'times']"
            class="create-portfolio-dialog__close-button--icon"
          />
        </v-btn>
        <div class="create-portfolio-dialog__icons-wrapper">
          <icon
            v-if="fontAwesomeLogo"
            class="create-portfolio-dialog__icon"
            :icon="fontAwesomeLogo"
          />
          <img
            v-else
            class="create-portfolio-dialog__icon"
            :src="defaultLogoUrl"
          >
        </div>
        <div class="create-portfolio-dialog__header-text">
          {{ heading }}
        </div>
        <div
          v-for="(section, i) in bodySections"
          :key="i"
          class="create-portfolio-dialog__body-text"
          style="margin-top: 0.5rem"
        >
          {{ section }}
        </div>
        <div class="create-portfolio-dialog__button-wrapper">
          <CommonButton
            :color="primaryColor"
            @click="$emit('button-1-click')"
          >
            {{ button1Text }}
          </CommonButton>
          <CommonButton
            v-if="button2Text"
            :color="primaryColor"
            @click="$emit('button-2-click')"
          >
            {{ button2Text }}
          </CommonButton>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";

export default defineComponent({
  components: {
    CommonButton,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    bodySections: {
      type: Array,
      required: true,
    },
    button1Text: {
      type: String,
      required: true,
    },
    button2Text: {
      type: String,
      required: false,
    },
    primaryColor: {
      type: String,
      required: false,
    },
    fontAwesomeLogo: {
      type: Array,
      required: false,
    },
  },
  emits: ["button-1-click", "button-2-click", "update:model-value"],
  data() {
    return {
      defaultLogoUrl: new URL("../assets/images/signup/rocket-launch-light.svg", import.meta.url)
        .href,
    };
  },
  methods: {
    inputChange(value: boolean) {
      if (!value) {
        this.close();
      }
    },
    close(): void {
      this.$emit("update:model-value", false);
    },
  },
});
</script>

<style lang="scss" scoped>
.create-portfolio-dialog {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__close-button {
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    &--icon {
      font-size: 1.375rem;
    }
  }
  &__icons-wrapper {
    margin-top: 2rem;
  }
  &__bold {
    font-weight: 600;
  }
  &__icon {
    margin: 0 0.35rem 0 0.35rem;
    color: $soft-blue;
    height: 3rem;
    width: 3rem;
  }

  &__header-text {
    font-size: 1.25rem;
    color: black;
    font-weight: 600;
    margin-top: 1.5rem;
  }
  &__body-text {
    padding-top: 0.5rem;
    text-align: center;
    font-weight: 300;
  }
  &__button-wrapper {
    display: grid;
    grid-row-gap: 1rem;
    width: 100%;
    margin-top: 2rem;

    @include medium-up {
      display: flex;
      justify-content: center;
      flex-direction: column;
      column-gap: 1rem;
      width: 60%;
    }
    @include small-only {
      button {
        width: 100%;
      }
    }
  }
}
:deep(.create-portfolio-wrapper) {
  max-width: 30rem;
}
</style>
