<template>
  <div class="communication">
    <single-choice
      :alternatives="alternatives"
      :footer-message="$t('create-portfolio.questions.account.communication.footer')"
      @click="buttonClick"
    >
      <information-text-section
        :heading="$t('communication')"
        :body="$t('create-portfolio.questions.account.communication.body')"
      />
    </single-choice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Question from "@/views/signup/templates/question";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice
  },
  extends: Question,
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("approve"),
          key: "approve"
        }
      ];
    }
  },
  methods: {
    buttonClick() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss">
.communication {
  width: 100%;
  height: 100%;
}
</style>
