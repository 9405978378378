<template>
  <side-dialog
    :model-value="showDialog"
    :title="$t('portfolio.overview.savings-goal.side-dialog.dialogTitle')"
    @update:model-value="showDialog = false"
  >
    <div class="goal__dialog">
      <p>
        {{ $t("portfolio.overview.savings-goal.side-dialog.enterValue") }}
      </p>
      <v-form
        ref="form"
        class="goal__dialog-form"
        @submit.prevent="addSavingsGoal"
      >
        <v-text-field
          v-model="displayGoalAmount"
          type="tel"
          variant="underlined"
          :label="$t('sum')"
          :suffix="$t('currency')"
          :rules="[rules.number, rules.leadingZeros, rules.min]"
          :disabled="error"
          validate-on="blur"
          data-jest="text-field"
          class="goal__dialog-text-field"
        />
      </v-form>
      <div class="goal__dialog-button-wrapper">
        <common-button
          :disabled="!savingsGoal"
          secondary
          @click="removeSavingsGoal()"
        >
          {{ $t("delete") }}
        </common-button>
        <common-button
          :disabled="error"
          @click="addSavingsGoal()"
        >
          {{ $t("save") }}
        </common-button>
      </div>
    </div>
  </side-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { PortfolioAction } from "@/store/portfolio/actions";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { SavingsGoal } from "@/clients";
import { UserAction } from "@/store/user/action-definitions";
import { PortfolioState } from "@/store/portfolio/types";

export default defineComponent({
  emits: ["update:model-value"],
  components: { SideDialog, CommonButton },
  data() {
    return {
      error: false,
      rules: {
        leadingZeros: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^(0|[1-9][0-9]*)$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        number: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        min: (value: string) => {
          value = value.replace(/\s/g, "");
          const valueNumber = parseInt(value, 10);
          return valueNumber > 0 || this.$t("numberGreaterThanZero");
        }
      },
      goalAmount: undefined as number | undefined
    };
  },
  computed: {
    showDialog: {
      get(): boolean {
        return (this.$store.state.portfolioStore as PortfolioState).showSavingsGoalSideDialog;
      },
      set(value: boolean): void {
        this.$store.commit(PortfolioMutation.setShowSavingsGoalSideDialog, value);
      }
    },
    savingsGoal(): SavingsGoal | undefined {
      const { savingsGoal } = this.$store.state.portfolioStore;
      return savingsGoal;
    },
    displayGoalAmount: {
      get(): string {
        if (this.goalAmount) {
          return this.goalAmount.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string) {
        if (value) {
          value = this.removeWhiteSpace(value);
          this.goalAmount = parseInt(value, 10);
        }
      }
    }
  },
  created() {
    if (this.savingsGoal) {
      this.goalAmount = this.savingsGoal.goalAmount;
    }
  },
  methods: {
    resetAndClose(): void {
      this.showDialog = false;
    },
    async addSavingsGoal(): Promise<any> {
      if ((await (this.$refs.form as any).validate()).valid) {
        try {
          const formattedRequest = new SavingsGoal({
            goalAmount: this.goalAmount as number
          });
          const response = await this.$store.dispatch(
            this.savingsGoal ? PortfolioAction.updateSavingsGoal : PortfolioAction.addSavingsGoal,
            formattedRequest
          );
          if (response) {
            this.$store.commit(PortfolioMutation.setSavingsGoal, formattedRequest);
            this.showDialog = false;
          }
        } catch (e) {
          this.$store.dispatch(
            UserAction.addSnackbarMessage,
            this.$t("portfolio.overview.savings-goal.side-dialog.addGoalError")
          );
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    },
    async removeSavingsGoal(): Promise<any> {
      if (this.savingsGoal && this.savingsGoal.goalAmount !== undefined) {
        try {
          const response = await this.$store.dispatch(PortfolioAction.removePortfolioSavingsGoal);
          if (response) {
            this.$store.commit(PortfolioMutation.setSavingsGoal, undefined);
            this.showDialog = false;
          }
        } catch (e) {
          this.$store.dispatch(
            UserAction.addSnackbarMessage,
            this.$t("portfolio.overview.savings-goal.side-dialog.removeGoalError")
          );
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    },
    removeWhiteSpace(value: string): string {
      return value.replace(/\s/g, "");
    },
    setDialog(value: boolean) {
      this.$emit("update:model-value", value);
    }
  }
});
</script>

<style lang="scss" scoped>
.goal {
  &__dialog {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 300;
    font-family: $heading-font-family;
    text-align: center;
    height: 100%;
  }
  &__dialog-text-field {
    width: "100%";
    font-size: 1.25rem;
    font-weight: 500;
  }
  &__dialog-form {
    width: 100%;
  }
  &__dialog-button-wrapper {
    display: grid;
    justify-content: center;
    grid-column-gap: 1rem;
    width: 100%;
    margin-top: 17.5rem;
    grid-template-columns: 8.125rem 8.125rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto !important;
  }
  &__text {
    &--white {
      color: $white;
    }
  }
}
:deep(.v-input .v-label) {
  font-weight: 300;
}
:deep(.v-text-field__suffix) {
  font-weight: 300;
  font-size: 1rem !important;
}
</style>
