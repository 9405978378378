<template>
  <div class="portfolio">
    <v-tabs
      v-if="$vuetify.display.smAndDown"
      v-model="currentTab"
      class="portfolio__tabs"
      height="3rem"
      hide-slider
      align-tabs="center"
    >
      <div
        v-for="(item, index) in tabs"
        :key="item.key"
        class="portfolio__tab-wrapper"
      >
        <v-tab
          class="portfolio__tab"
          selected-class="portfolio__tab--active"
          :ripple="false"
        >
          <icon
            fixed-width
            class="portfolio__tab-icon"
            :icon="item.icon"
          />
        </v-tab>
        <div
          v-if="index < 2"
          class="portfolio__tab-dot"
        />
      </div>
      <v-menu
        v-if="$vuetify.display.smAndDown"
        v-model="mobileMenuActive"
        location="bottom"
      >
        <template #activator="{ props }">
          <div class="portfolio__tab-wrapper">
            <div class="portfolio__tab-dot" />
            <icon
              v-bind="props"
              fixed-width
              class="portfolio__tab portfolio__tab-icon-cog"
              :class="[
                { 'portfolio__tab--active': mobileMenuActive },
              ]"
              :icon="['fal', 'cog']"
              data-cy="portfolioSettingsButton"
            />
          </div>
        </template>
        <SettingsList
          @click:delete="showDeleteAccountDialog = true"
          @click:withdraw="navigateToWithdrawal()"
          @click:rename="showRenameDialog = true"
          @click:agreements="showAgreementsDialog = true"
          @click:settings="showSettings"
          @click:transactions="showTransactions"
          @click:reports="showReportsDialog = true"
        />
      </v-menu>
    </v-tabs>
    <v-window
      v-if="$vuetify.display.smAndDown"
      v-model="currentTab"
    >
      <v-window-item
        v-for="item in tabs"
        :key="item.key"
      >
        <component
          :is="item.content"
          class="portfolio__tab-component"
        />
      </v-window-item>
    </v-window>
    <div v-if="$vuetify.display.mdAndUp">
      <PortfolioOverview
        class="portfolio__portfolio"
        @click:delete="showDeleteAccountDialog = true"
        @click:withdraw="navigateToWithdrawal()"
        @click:rename="showRenameDialog = true"
        @click:agreements="showAgreementsDialog = true"
        @click:settings="showSettings"
        @click:transactions="showTransactions"
        @click:reports="showReportsDialog = true"
      />
      <Analysis class="portfolio__analysis" />
      <Holdings class="portfolio__holdings" />
    </div>
    <DeletePortfolio
      v-if="isTestUser"
      v-model="showDeleteAccountDialog"
    />
    <ManualDeletePortfolio
      v-else
      v-model="showDeleteAccountDialog"
    />
    <RenameDialog v-model="showRenameDialog" />
    <AgreementsSideDialog
      v-if="showAgreementsDialog"
      v-model="showAgreementsDialog"
      :portfolio="activePortfolio"
    />
    <ReportsSideDialog
      v-if="showReportsDialog"
      v-model="showReportsDialog"
    />
    <CancelledAutogiroSignDialog />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Analysis from "./analysis/analysis.vue";
import Holdings from "./holdings/holdings.vue";
import PortfolioOverview from "./overview/portfolio-overview.vue";
import RenameDialog from "./overview/rename-dialog.vue";
import ReportsSideDialog from "./overview/reports/reports-side-dialog.vue";
import DeletePortfolio from "./overview/delete-portfolio/user-delete-portfolio.vue";
import ManualDeletePortfolio from "./overview/delete-portfolio/manual-delete-portfolio.vue";
import AgreementsSideDialog from "./overview/current-agreements-side-dialog.vue";
import SettingsList from "./overview/settings-list.vue";
import { InProgressPension } from "@/clients";
import type { PortfolioBase } from "@/clients";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { NavigationMutation } from "@/store/navigation/mutations";
import { PortfolioAction } from "@/store/portfolio/actions";
import CancelledAutogiroSignDialog from "@/components/cancelled-autogiro-sign-dialog.vue";

type TabKey = "portfolio" | "holdings" | "analysis";

interface Tab {
  icon: string[];
  key: TabKey;
  content: any;
}

export default defineComponent({
  components: {
    Analysis,
    Holdings,
    PortfolioOverview,
    RenameDialog,
    ReportsSideDialog,
    DeletePortfolio,
    AgreementsSideDialog,
    SettingsList,
    ManualDeletePortfolio,
    CancelledAutogiroSignDialog,
  },
  // Called on portfolio route change, eg from portfolio/3 to portfolio/2.
  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch(PortfolioAction.setActivePortfolio, to.params.id);
    next();
  },
  data: () => ({
    tab: null as number | null,
    showRenameDialog: false,
    showDeleteAccountDialog: false,
    showDividendDialog: false,
    showAgreementsDialog: false,
    showReportsDialog: false,
    mobileMenuActive: false,
  }),
  head() {
    return {
      title: this.$store.getters.portfolioName || "Portfölj",
    };
  },
  computed: {
    ...mapGetters(["isCompany", "isTestUser"]),
    currentTab: {
      get(): number | undefined {
        return this.$store.state.portfolioStore.currentTab;
      },
      set(tabIndex: number | undefined) {
        this.$store.commit(PortfolioMutation.setCurrentTab, tabIndex);
      },
    },
    activePortfolio(): PortfolioBase {
      return this.$store.state.portfolioStore.activePortfolioBase;
    },
    tabs(): Tab[] {
      return [
        {
          icon: ["fal", "chart-line"],
          key: "portfolio",
          content: PortfolioOverview,
        },
        {
          icon: ["fal", "briefcase"],
          key: "holdings",
          content: Holdings,
        },
        {
          icon: ["fal", "chart-pie"],
          key: "analysis",
          content: Analysis,
        },
      ];
    },
    mobileHeading(): string {
      switch (this.tab) {
        case 2:
          return this.$t("analysis");
        case 1:
          return this.$t("holding");
        default:
          return this.$store.getters.portfolioName || "";
      }
    },
    showWithdrawalDialog(): boolean {
      const { showWithdrawalDialog } = this.$store.state.portfolioStore;
      return showWithdrawalDialog;
    },
  },
  watch: {
    mobileHeading: {
      immediate: true,
      handler(newHeading: string, oldHeading: string) {
        this.$store.commit(NavigationMutation.setMobileHeading, newHeading);
      },
    },
  },

  mounted() {
    this.$store.dispatch(PortfolioAction.setActivePortfolio, this.$route.params.id as string);
    this.$store.commit(NavigationMutation.setBackRoute, { name: "overview" });
  },
  methods: {
    showSettings(): void {
      this.$router.push({ name: "portfolio-settings" });
    },
    showTransactions(): void {
      this.$router.push({ name: "transactions" });
    },
    navigateToEvents(): void {
      this.$store.commit(PortfolioMutation.setShowEventsDialog, true);
    },
    navigateToWithdrawal(): void {
      this.$store.commit(PortfolioMutation.setShowWithdrawalDialog, true);
    },
  },
});
</script>

<style lang="scss" scoped>
.portfolio {
  max-width: 71rem;
  width: 100%;
  &__holdings {
    margin-top: 4rem;
  }
  &__analysis {
    margin-top: 4rem;
  }
  &__tab {
    color: rgba($nearly-black, 0.3) !important;
    min-width: unset;
    width: 5rem;
    height: 100%;
    height: 3rem;
    &--active {
      color: rgba($nearly-black, 0.9) !important;
    }
  }
  &__tab-dot {
    height: 0.25rem;
    width: 0.25rem;
    background-color: rgba($nearly-black, 0.3);
    border-radius: 50%;
  }
  &__tab-icon {
    font-size: 1.25rem;
  }
  &__tab-icon-cog {
    height: 50%;
  }
  &__tab-wrapper {
    display: flex;
    align-items: center;
  }
  &__tab-component {
    // A minor top padding is needed for the box shadow used in the cards of the components.
    margin: 0.25rem 1rem 2rem 1rem;
  }
  &__settings-button {
    box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.1);
    &:before {
      background: transparent;
    }
    &.focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
    &:hover {
      @include cursorHover {
        box-shadow: 0 0.1875rem 0.825rem rgba(0, 0, 0, 0.15);
      }
    }
  }
  &__settings-button-icon {
    font-size: 1.25rem;
  }
  &__settings-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.v-menu__content {
  border-radius: 0.5rem;
  box-shadow: 0 0.1875rem 2rem rgba(0, 0, 0, 0.32);
  max-width: 15.625rem;
  @include small-down {
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
  }
}
// Can't use the proper background color prop, see https://github.com/vuetifyjs/vuetify/issues/10633
:deep(.v-tabs-bar) {
  background-color: transparent !important;
}

:deep(.v-window) {
  background-color: transparent !important;
}
</style>
