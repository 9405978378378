<template>
  <v-btn
    data-jest="icon-button"
    v-bind="$attrs"
    class="icon-button"
    color="white"
    size="small"
    @click="buttonClick"
    variant="flat"
    icon
  >
    <icon fixed-width :icon="icon" class="icon-button__icon" />
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    icon: {
      type: [String, Array],
      default: undefined
    }
  },
  methods: {
    buttonClick() {
      this.$emit("click");
    }
  }
});
</script>

<style lang="scss" scoped>
.icon-button {
  font-size: 1rem;
  box-shadow: 0 0.1875rem 0.75rem #00000024;
  transition: box-shadow 0.1s ease-in-out;
  &:hover {
    @include cursorHover {
      box-shadow: 0 0.1875rem 0.75rem #0000001c;
    }
  }
  &__icon {
    color: #000;
    opacity: 0.25;
  }
  &:before {
    background: transparent;
    transition: all 0.1s ease-in-out;
  }
  &:hover:before {
    @include cursorHover {
      background: black;
    }
  }
  &.focus-visible {
    box-shadow:
      0 0 0.0625rem 0.0625rem $aqua,
      0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
  }
}
</style>
