<template>
  <v-sheet
    style="
      width: 15rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 1.3rem 1.5rem 1.3rem;
      row-gap: 0.5rem;
      text-align: center;
      color: #2f2f2f;
      font-weight: 300;
      line-height: 1.25;
    "
  >
    <div>
      <icon
        fixed-width
        style="font-size: 1.5rem; margin-bottom: 0.5rem"
        :icon="['fal', 'globe-americas']"
      />
      <slot />
    </div>
  </v-sheet>
</template>
