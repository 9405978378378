<template>
  <div
    v-if="sparError"
    class="spar"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <single-choice
        v-if="pageProgress === 'info'"
        :alternatives="alternatives"
        @click="buttonClick"
      >
        <information-text-section
          :heading="$t('common-signup.questions.account.verify-personal-data.heading1')"
          :body="$t('common-signup.questions.account.verify-personal-data.body1')"
        />
      </single-choice>
    </transition>
    <transition
      name="fade"
      mode="out-in"
    >
      <single-choice
        v-if="pageProgress === 'name'"
        :alternatives="alternatives"
        @click="buttonClick"
      >
        <information-text-section
          :heading="$t('name')"
          :body="$t('common-signup.questions.account.verify-personal-data.body2')"
        />
        <v-form
          ref="form"
          class="spar__form"
          @submit.prevent="buttonClick"
        >
          <v-text-field
            v-model="firstName"
            :label="$t('firstName')"
            :rules="[rules.required]"
            variant="underlined"
            type="text"
            name="firstName"
            validate-on="blur"
          />
          <v-text-field
            v-model="lastName"
            :label="$t('lastName')"
            :rules="[rules.required]"
            variant="underlined"
            type="text"
            name="lastName"
            validate-on="blur"
          />
        </v-form>
      </single-choice>
    </transition>
    <transition
      name="fade"
      mode="out-in"
    >
      <single-choice
        v-if="pageProgress === 'address'"
        :alternatives="alternatives"
        @click="buttonClick"
      >
        <information-text-section
          :heading="$t('address')"
          :body="$t('common-signup.questions.account.verify-personal-data.body3')"
        />
        <v-form
          ref="form"
          class="spar__form"
          @submit.prevent="buttonClick"
        >
          <v-text-field
            v-model="address"
            :label="$t('address')"
            :rules="[rules.required]"
            variant="underlined"
            type="text"
            name="address"
            validate-on="blur"
          />
          <v-text-field
            v-model="zipCode"
            :label="$t('zipCode')"
            :rules="[rules.required, rules.zipCode]"
            variant="underlined"
            type="tel"
            name="zipCode"
            validate-on="blur"
          />
          <v-text-field
            v-model="city"
            :label="$t('city')"
            :rules="[rules.required]"
            variant="underlined"
            type="text"
            name="city"
            validate-on="blur"
          />
        </v-form>
      </single-choice>
    </transition>
  </div>
  <div v-else><!-- hej --></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SparData } from "@/clients";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupAction } from "@/store/signup/actions";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import InformationTextSection from "@/views/signup/information-text-section.vue";

type PAGE_PROGRESS = "info" | "name" | "address";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice
  },
  extends: Question,
  data() {
    return {
      sparError: false,
      pageProgress: "info" as PAGE_PROGRESS,
      rules: {
        required: (value: string) => !!value || this.$t("required"),
        zipCode: (value: string) => {
          if (value) {
            const pattern = /^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/;
            return (
              pattern.test(value) ||
              this.$t("common-signup.questions.account.verify-personal-data.invalidZipCode")
            );
          }
          return true;
        }
      },
      firstName: undefined as string | undefined,
      lastName: undefined as string | undefined,
      address: undefined as string | undefined,
      zipCode: undefined as string | undefined,
      city: undefined as string | undefined
    };
  },
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    },
    personalIdentityNumber(): string {
      return this.$store.state.signupStore.personalIdentityNumber;
    }
  },
  async mounted(): Promise<void> {
    try {
      await this.$store.dispatch(SignupAction.getSparData);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    } catch (error: any) {
      this.sparError = true;
    }
  },
  methods: {
    async buttonClick() {
      switch (this.pageProgress) {
        case "info":
          this.pageProgress = "name";
          break;
        case "name":
          // we can validate the entire form here since the last part hasnt rendered yet
          if ((await (this.$refs.form as any).validate()).valid) {
            this.pageProgress = "address";
          }
          break;
        case "address":
          if ((await (this.$refs.form as any).validate()).valid) {
            this.$store.commit(
              SignupMutation.setSparData,
              new SparData({
                firstName: this.firstName,
                lastName: this.lastName,
                address: this.address,
                zipCode: (this.zipCode as string).replace(/\s/g, ""),
                city: this.city,
                personalIdentityNumber: undefined,
                postAddress: undefined,
                postCity: undefined,
                postCountry: undefined,
                postZipCode: undefined
              })
            );
            this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
          }

          break;
        default:
          break;
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.spar {
  width: 100%;
  height: 100%;
  overflow: hidden;
  &__form {
    max-width: 21.25rem;
    margin: 2rem auto 0 auto;
    display: flex;
    flex-direction: column;
    @include medium-up {
      max-width: 31rem;
    }
  }
}
</style>
