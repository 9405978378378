import type { NavigationState } from "./types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const getNavigationInitialState = (): NavigationState => ({
  backRoute: undefined,
  mobileHeading: undefined,
  portfolios: undefined,
});

const state = getNavigationInitialState();

export default {
  actions,
  getters,
  mutations,
  state,
};
