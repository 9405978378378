<template>
  <div class="principal">
    <card-header
      class="principal__header"
      :title="$t('principal')"
      :icon="['fal', 'user']"
    >
      <edit-button
        v-if="!errorLoadingUser"
        :edit="edit"
        @click="toggleEdit"
      />
    </card-header>
    <v-form
      ref="form"
      class="principal__form"
    >
      <v-text-field
        v-model="editedFirstName"
        :class="['principal__input', { 'principal__input--disabled': !edit }]"
        validate-on="blur"
        variant="underlined"
        disabled
        :label="$t('name')"
        :rules="[rules.required]"
      />
      <v-text-field
        v-model="editedLastName"
        :class="['principal__input', { 'principal__input--disabled': !edit }]"
        disabled
        validate-on="blur"
        variant="underlined"
        :label="$t('lastName')"
        :rules="[rules.required]"
      />
      <select-list
        v-model="editedTaxableUs"
        :class="['company-info-details__input', { 'company-info-details__input--disabled': !edit }]"
        :edit="edit"
        :items="yesNoOptions"
        :label="$t('profile.personal-info.taxableUsa')"
        @change="(value) => (editedTaxableUs = value)"
      />
      <div class="principal__citizenship">
        <v-autocomplete
          v-model="citizenship1"
          :menu-props="menuProps"
          variant="underlined"
          :items="countryItems"
          :class="[
            'principal__autocomplete',
            {
              'principal__autocomplete--disabled': !edit
            }
          ]"
          :disabled="!edit"
          :label="$t('citizenship')"
          menu-icon=""
        >
          <template #append-inner>
            <icon
              style="font-size: 1.25rem"
              :icon="['fal', 'search']"
            /> </template
        ></v-autocomplete>
        <v-btn
          v-if="edit && citizenshipCount < 3"
          class="principal__add-btn"
          variant="text"
          @click="citizenshipCount += 1"
          >{{ $t("profile.personal-info.addNew") }}</v-btn
        >
      </div>
      <div
        v-if="citizenshipCount > 1"
        class="principal__citizenship"
      >
        <v-autocomplete
          v-model="citizenship2"
          :menu-props="menuProps"
          :items="countryItems"
          :class="[
            'principal__autocomplete',
            {
              'principal__autocomplete--disabled': !edit
            }
          ]"
          :disabled="!edit"
          :label="$t('citizenship') + ' 2'"
        >
          <template #append>
            <icon
              style="font-size: 1.25rem"
              :icon="['fal', 'search']"
            /> </template
        ></v-autocomplete>
        <v-btn
          v-if="edit"
          class="principal__add-btn"
          variant="text"
          @click="
            citizenshipCount -= 1;
            citizenship2 = citizenship3;
            citizenship3 = undefined;
          "
          >{{ $t("delete") }}</v-btn
        >
      </div>
      <div
        v-if="citizenshipCount == 3"
        class="principal__citizenship"
      >
        <v-autocomplete
          v-model="citizenship3"
          :menu-props="menuProps"
          :items="countryItems"
          :class="[
            'principal__autocomplete',
            {
              'principal__autocomplete--disabled': !edit
            }
          ]"
          :disabled="!edit"
          :label="$t('citizenship') + ' 3'"
        >
          <template #append>
            <icon
              style="font-size: 1.25rem"
              :icon="['fal', 'search']"
            /> </template
        ></v-autocomplete>
        <v-btn
          v-if="edit"
          variant="text"
          class="principal__add-btn"
          @click="
            citizenshipCount -= 1;
            citizenship3 = undefined;
          "
          >{{ $t("delete") }}</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { CompanyPrincipal, LegalEntity } from "@/clients";
import { UserMutation } from "@/store/user/mutations";
import CardHeader from "@/components/card-header.vue";
import SelectList from "../profile/select.vue";
import EditButton from "@/components/edit-button.vue";
import { CountryCode } from "@/types/signup";
import { countryCodes } from "@/config/countries";

interface VSelectItem {
  title: string;
  value: string;
}

export default defineComponent({
  emits: ["save"],
  components: { CardHeader, SelectList, EditButton },
  props: {
    index: {
      required: true,
      type: Number
    }
  },
  data: () => ({
    edit: false,

    citizenshipCount: 1,
    menuProps: {
      contentClass: "autocomplete__menu",
      nudgeBottom: 8
    }
  }),
  created() {
    if (this.citizenship2) this.citizenshipCount += 1;
    if (this.citizenship3) this.citizenshipCount += 1;
  },
  computed: {
    ...mapGetters(["errorLoadingUser"]),
    countryItems(): VSelectItem[] {
      return countryCodes.map((x: CountryCode) => ({
        title: this.$t(`countries.${x}`),
        value: x
      }));
    },
    yesNoOptions(): any[] {
      return [
        {
          value: true,
          key: true,
          title: this.$t("yes")
        },
        {
          value: false,
          key: false,
          title: this.$t("no")
        }
      ];
    },
    editedPrincipal(): CompanyPrincipal {
      return this.$store.state.userStore.editedCompanyKnowYourCustomerAnswers.companyPrincipals[
        this.index
      ];
    },
    editedFirstName: {
      get(): string | undefined {
        return this.editedPrincipal.firstName;
      },
      set(phoneNumber: string) {
        // this.$store.commit(UserMutation.editContactInformation, {
        //   phoneNumber,
        //   email: this.editedEmail
        // });
      }
    },
    editedLastName: {
      get(): string | undefined {
        return this.editedPrincipal.lastName;
      },
      set(email: string) {
        // this.$store.commit(UserMutation.editContactInformation, {
        //   phoneNumber: this.editedPhoneNumber,
        //   email
        // });
      }
    },
    editedTaxableUs: {
      get(): boolean {
        return this.editedPrincipal.taxableCountries.includes("US");
      },
      set(value: boolean) {
        const taxableCountries = this.editedPrincipal.taxableCountries.filter((n) => n !== "US");
        if (value) {
          taxableCountries.push("US");
        }
        this.$store.commit(UserMutation.setEditedCompanyPrincipal, {
          index: this.index,
          value: new CompanyPrincipal({ ...this.editedPrincipal, taxableCountries })
        });
      }
    },
    citizenship1: {
      get(): string | undefined {
        return this.editedPrincipal.citizenship1;
      },
      set(value: string) {
        this.$store.commit(UserMutation.setEditedCompanyPrincipal, {
          index: this.index,
          value: new CompanyPrincipal({ ...this.editedPrincipal, citizenship1: value })
        });
      }
    },
    citizenship2: {
      get(): string | undefined {
        return this.editedPrincipal.citizenship2;
      },
      set(value: string) {
        this.$store.commit(UserMutation.setEditedCompanyPrincipal, {
          index: this.index,
          value: new CompanyPrincipal({ ...this.editedPrincipal, citizenship2: value })
        });
      }
    },
    citizenship3: {
      get(): string | undefined {
        return this.editedPrincipal.citizenship3;
      },
      set(value: string) {
        this.$store.commit(UserMutation.setEditedCompanyPrincipal, {
          index: this.index,
          value: new CompanyPrincipal({ ...this.editedPrincipal, citizenship3: value })
        });
      }
    },
    rules(): any {
      return {
        required: (value: string) => (!!value && value !== "") || this.$t("required"),
        email: (value: string) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("invalidEmail");
        }
      };
    }
  },
  methods: {
    async toggleEdit() {
      if (this.edit) {
        if ((await (this.$refs.form as any).validate()).valid) {
          this.$emit("save", { index: this.index });
          this.$store.commit(UserMutation.decreaseUnsavedEdits);
          this.edit = false;
        }
      } else {
        this.edit = true;
        this.$store.commit(UserMutation.increaseUnsavedEdits);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.principal {
  padding: 1.5rem 1.5rem 0.825rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__form {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
  }
  &__input {
    color: black;
    @include small-down {
      width: 100%;
    }
    :deep(label) {
      color: #707070 !important;
      font-size: 1.2rem;
      font-weight: 300;
    }
    :deep(.v-text-field) {
      margin-top: 0;
    }
    &--bottom {
      grid-column: 1 / 3;
      grid-row: 3;
    }
    &--disabled {
      :deep(.v-text-field__suffix) {
        color: inherit !important;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-label) {
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
  }
  &__citizenship {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .v-input {
      max-width: 21rem;
    }
  }
  &__add-btn {
    text-transform: none;
  }
}
</style>
