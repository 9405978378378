import { buildCommonModifications } from "./common-modifications";
import { SignupFlowModificationType } from "./signup-modification";

export const companyPrincipalForeignPaths = [
  "/company-signup/evida/principals/company-principal-foreign-address",
];
export const companyPrincipalsTaxableCountryPaths = [
  "/company-signup/evida/principals/company-principal-taxable-country",
];
export const companyPrincipalPaths = [
  "/company-signup/evida/principals/company-principal-details",
  "/company-signup/evida/principals/company-principal-citizenship",
  "/company-signup/evida/principals/company-principal-taxable",
  "/company-signup/evida/principals/company-principal-pep",
  "/company-signup/evida/principals/company-principal-contact",
];

export const multipleRepresentativePaths = [
  "/company-signup/evida/introduction/new-company-representative",
];

export const authorizedSignatoriesToSignPaths = [
  "/company-signup/agreements/authorized-signatory-contact",
];

export const additionalQuestions = {
  ...buildCommonModifications("company-signup"),

  [SignupFlowModificationType.CompanyPrincipalForeign]: companyPrincipalForeignPaths,
  [SignupFlowModificationType.OtherCompanyPrincipalForeign]: [
    "/company-signup/evida/principals/other-principal-foreign-address",
  ],
  [SignupFlowModificationType.ForbiddenCountryWarning]: [
    "/company-signup/evida/principals/company-principal-forbidden",
  ],
  [SignupFlowModificationType.PrincipalTaxableCountryWarning]: [
    "/company-signup/evida/principals/company-principal-taxable-country-warning",
  ],
  [SignupFlowModificationType.HasCompanyPrincipals]: [
    "/company-signup/evida/principals/company-principals-summary",
  ],
  [SignupFlowModificationType.WantsMultipleRepresentatives]: [
    "/company-signup/evida/introduction/number-representatives",
  ],
  [SignupFlowModificationType.MultipleRepresentatives]: multipleRepresentativePaths,
  [SignupFlowModificationType.CompanyPrincipal]: companyPrincipalPaths,
  [SignupFlowModificationType.AlternativeCompanyPrincipal]: [
    "/company-signup/evida/principals/other-principal-info",
    "/company-signup/evida/principals/other-principal-details",
    "/company-signup/evida/principals/other-principal-swedish-resident",
    "/company-signup/evida/principals/other-principal-citizenship",
    "/company-signup/evida/principals/other-principal-taxable",
    "/company-signup/evida/principals/other-principal-pep",
    "/company-signup/evida/principals/other-principal-contact",
  ],
  [SignupFlowModificationType.AuthorizedSignatoriesToSign]: authorizedSignatoriesToSignPaths,
  [SignupFlowModificationType.CompanyActive]: [
    "/company-signup/economy/average-operating-profit",
    "/company-signup/economy/forecast-result",
    "/company-signup/economy/long-term-debt",
    "/company-signup/economy/solidity",
    "/company-signup/economy/liquid-assets",
    "/company-signup/economy/short-term-debt",
  ],
  [SignupFlowModificationType.CapitalInsuranceWarning]: [
    "/company-signup/evida/introduction/capital-insurance-warning",
  ],
  [SignupFlowModificationType.RepresentativeWarning]: [
    "/company-signup/evida/introduction/representative-warning",
  ],
  [SignupFlowModificationType.WithdrawalIntervalWarningCompany]: [
    "/company-signup/evida/aml/withdrawal-interval-warning",
  ],
  [SignupFlowModificationType.PrincipalTaxableUsaWarning]: [
    "/company-signup/evida/principals/taxable-usa-warning",
  ],
  [SignupFlowModificationType.LowSolidity]: ["/company-signup/economy/solidity-warning"],
  [SignupFlowModificationType.LowLiquidity]: ["/company-signup/economy/liquidity-warning"],
  [SignupFlowModificationType.LowLiquidAssets]: ["/company-signup/economy/liquid-assets-warning"],
  [SignupFlowModificationType.HighDepositAmount]: [
    "/company-signup/evida/aml/yearly-deposit-manual",
  ],
  [SignupFlowModificationType.ShortTermDebt]: ["/company-signup/economy/liquidity"],
  [SignupFlowModificationType.OneManCompany]: [
    "/company-signup/evida/application/insured-contact",
    "/company-signup/evida/one-man-principal/kyc-info",
    "/company-signup/evida/one-man-principal/principal-info",
    "/company-signup/evida/one-man-principal/swedish-resident",
    "/company-signup/evida/one-man-principal/citizenship",
    "/company-signup/evida/one-man-principal/swedish-tax-residence",
    "/company-signup/evida/one-man-principal/tax-liable-usa",
    "/company-signup/evida/one-man-principal/tax-liable-other",
    "/company-signup/evida/one-man-principal/politically-exposed",
  ],
  [SignupFlowModificationType.MultipleManCompany]: [
    "/company-signup/evida/introduction/representant-info",
    "/company-signup/evida/introduction/representant-role",
    "/company-signup/evida/introduction/multiple-representatives",
    "/company-signup/evida/application/insured-contact",
    "/company-signup/evida/principals/kyc-info",
    "/company-signup/evida/principals/company-principal-info",
  ],
  [SignupFlowModificationType.OneManPrincipalTaxableOtherCountry]: [
    "/company-signup/evida/one-man-principal/tax-liable-country",
  ],
  [SignupFlowModificationType.CompanyTaxableOtherCountry]: [
    "/company-signup/evida/aml/company-taxable-country",
  ],
  [SignupFlowModificationType.PrincipalTaxableOtherCountry]: companyPrincipalsTaxableCountryPaths,
  [SignupFlowModificationType.OtherPrincipalTaxableOtherCountry]: [
    "/company-signup/evida/principals/other-principal-taxable-country",
  ],
  [SignupFlowModificationType.ChooseOneManCompany]: [
    "/company-signup/evida/introduction/one-man-company",
  ],
  [SignupFlowModificationType.MultipleManCompanySigning]: [
    "/company-signup/agreements/authorized-signatories",
  ],
  [SignupFlowModificationType.StockInformation]: ["/company-signup/risk/stock-information"],
  [SignupFlowModificationType.TimeToWithdrawWarning]: [
    "/company-signup/economy/time-to-withdraw-warning",
  ],
  [SignupFlowModificationType.FortnoxMultipleManCompany]: [
    "/company-signup/evida/introduction/multiple-representatives",
    "/company-signup/evida/application/insured-contact",
    "/company-signup/evida/principals/kyc-info",
    "/company-signup/evida/principals/company-principal-info",
  ],
  [SignupFlowModificationType.SustainablePortfolioTiedAgent]: [
    "/company-signup/preferences/sustainable-method",
  ],
};

export const regularIntroPaths = [
  [
    "/company-signup/introduction/company-account-info",
    "/company-signup/introduction/necessary-info",
    "/company-signup/introduction/time-info",
  ],
  [
    "/company-signup/evida/introduction/welcome",
    "/company-signup/evida/introduction/previous-insurance-experience",
    "/company-signup/evida/introduction/insurance-info",
    "/company-signup/evida/introduction/securities-owner",
    "/company-signup/evida/introduction/securities-owner-info",
    "/company-signup/evida/introduction/sustainability-info",
  ],
  [
    "/company-signup/evida/introduction/company-representant",
    "/company-signup/evida/introduction/company-check",
  ],
];
export const fortnoxIntroPaths = [
  [
    "/company-signup/introduction/fortnox-intro",
    "/company-signup/introduction/necessary-info",
    "/company-signup/introduction/time-info",
  ],
  [
    "/company-signup/evida/introduction/welcome",
    "/company-signup/evida/introduction/insurance-info",
  ],
  [
    "/company-signup/evida/introduction/representant-info",
    "/company-signup/evida/introduction/fortnox-company-representative",
    "/company-signup/evida/introduction/company-check",
  ],
];

const preferencesPaths = [
  "/company-signup/preferences/introduction",
  "/company-signup/preferences/market-focus",
  "/company-signup/preferences/sectors",
  "/company-signup/preferences/sustainability",
  "/company-signup/preferences/sustainable-investment",
  "/company-signup/preferences/focus",
];

const esgPaths = [
  "/company-signup/preferences/sustainability",
  "/company-signup/preferences/sustainable-investment",
];

const themePaths = ["/company-signup/preferences/theme-info", "/company-signup/preferences/theme"];

const riskPaths = [
  "/company-signup/risk/introduction",
  "/company-signup/risk/previous-experience",
  "/company-signup/risk/previous-experience-answer",
  "/company-signup/risk/portfolio-management",
  "/company-signup/risk/portfolio-selection",
  "/company-signup/risk/portfolio-confirmation",
  "/company-signup/risk/profile",
];

const evidaQuestionPaths = [
  "/company-signup/evida/aml/industry",
  "/company-signup/evida/aml/revenue",
  "/company-signup/evida/aml/employees",
  "/company-signup/evida/aml/savings-purpose",
  "/company-signup/evida/aml/investment-background",
  "/company-signup/evida/aml/deposit-interval",
  "/company-signup/evida/aml/yearly-deposit",
  "/company-signup/evida/aml/withdrawal-interval",
  "/company-signup/evida/aml/accountant",
  "/company-signup/evida/aml/company-taxable-other",
  "/company-signup/evida/aml/summary-agreements",
  "/company-signup/evida/aml/done",
];

const accountQuestionPaths = [
  "/company-signup/account/introduction",
  "/company-signup/account/choose-transaction-account",
  "/company-signup/account/add-account",
  "/company-signup/account/first-deposit",
  "/company-signup/account/monthly-savings",
];

const summaryPaths = ["/company-signup/summary/summary-agreements", "/company-signup/summary/done"];

const agreementsQuestionPaths = [
  "/company-signup/agreements/introduction",
  "/company-signup/agreements/communication",
  "/company-signup/agreements/summary",
  "/company-signup/agreements/evida-congratulations",
  "/company-signup/agreements/congratulations",
];

export const questionPaths = [
  evidaQuestionPaths,
  ["/company-signup/welcome", "/company-signup/introduction"],
  ["/company-signup/economy/inactive", "/company-signup/economy/time-to-withdraw"],
  preferencesPaths,
  riskPaths,
  accountQuestionPaths,
  summaryPaths,
  agreementsQuestionPaths,
];

export const tiedAgentQuestionPaths = [
  evidaQuestionPaths,
  ["/company-signup/evida/done"],
  ["/company-signup/welcome", "/company-signup/introduction"],
  ["/company-signup/economy/inactive", "/company-signup/economy/time-to-withdraw"],
  riskPaths,
  esgPaths,
  themePaths,
  accountQuestionPaths,
  summaryPaths,
  agreementsQuestionPaths,
];

export const CURRENT_COMPANY_SIGNUP_PATH_KEY = "current_company_signup_path";
