<template>
  <div class="market-column-chart-legend">
    <div
      v-for="legend in legends"
      :key="legend.legendName"
      class="market-column-chart-legend__pair"
    >
      <span
        v-if="$vuetify.display.lgAndUp"
        class="market-column-chart-legend__value"
        data-jest="value"
        :style="{
          backgroundColor: legend.color
        }"
      />
      <span
        class="market-column-chart-legend__key"
        data-jest="key"
      >
        {{ legend.market }}
        <span
          v-if="$vuetify.display.lgAndUp"
          class="market-column-chart-legend__key-percentage"
          >{{ toPercentage(legend.value) }}</span
        >
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    legends: {
      type: Array as any,
      default: () => []
    }
  },
  methods: {
    toPercentage(value: number) {
      if (value) {
        const localeStringOptions = {
          maximumFractionDigits: 1,
          minimumFractionDigits: 1
        };
        return `${(value * 100).toLocaleString("sv-SE", localeStringOptions)} %`;
      }
      return "- %";
    }
  }
});
</script>

<style lang="scss" scoped>
.market-column-chart-legend {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @include large-up {
    margin-top: auto;
    grid-template-columns: 1fr;
    max-width: 14rem;
  }

  &__key {
    width: 100%;
    font-family: $heading-font-family;

    @include large-up {
      font-weight: 300;
      width: 70%;
    }

    &-percentage {
      font-weight: 600;
      float: right;
    }
  }

  &__pair {
    margin: 0 auto;

    @include large-up {
      margin: unset;
      margin-top: 1.25rem;
      display: flex;
      align-items: baseline;
      padding-left: 1rem;
    }
  }

  &__value {
    margin: 0 0.5rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
  }
}
</style>
