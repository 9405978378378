<template>
  <div class="ethical">
    <SavingsHeader
      class="ethical__header"
      :title="
        edit
          ? $t('portfolio-settings.strategy.ethical.makeSelection')
          : $t('ethicalSelection')
      "
      :icon="['fal', 'heart']"
      :edit="edit"
    >
      <template #action>
        <EditButton
          :disabled="disabled"
          :edit="edit"
          @click="toggleEdit"
        />
      </template>
    </SavingsHeader>
    <v-divider />
    <v-list
      v-model:selected="item"
      variant="flat"
      :disabled="!edit"
    >
      <v-list-item
        v-for="(setting, i) in ethicalSettings"
        :key="i"
        class="ethical__list-item"
        @click="() => toggleItem(setting.key)"
      >
        <template #prepend>
          <div
            class="ethical__ban-icon"
            :class="[
              {
                'ethical__ban-icon--hidden': !setting.discarded && !edit,
                'ethical__ban-icon--faded': !setting.discarded && edit,
              },
            ]"
          >
            <icon
              fixed-width
              :icon="['fal', 'ban']"
            />
          </div>
        </template>
        <v-list-item-title class="ethical__item-title">
          {{
            setting.title
          }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import SavingsHeader from "../header.vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import EditButton from "@/components/edit-button.vue";

type EthicalArea =
  | "defenseDiscarded"
  | "alcoholTobaccoDiscarded"
  | "gamblingDiscarded"
  | "fossilFuelsDiscarded";

export default defineComponent({
  components: { EditButton, SavingsHeader },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["save"],
  data: () => ({
    item: undefined,
    edit: false,
  }),
  computed: {
    ...mapGetters(["editedDiscardedSectors"]),
    ethicalSettings(): any {
      if (!this.editedDiscardedSectors) {
        return [];
      }
      return [
        {
          key: "defenseDiscarded",
          title: this.$t("defense"),
          discarded: this.editedDiscardedSectors.defenseDiscarded,
        },
        {
          key: "alcoholTobaccoDiscarded",
          title: this.$t("ethicalAlcoholTobaccoTitle"),
          discarded: this.editedDiscardedSectors.alcoholTobaccoDiscarded,
        },
        {
          key: "gamblingDiscarded",
          title: this.$t("portfolio-settings.strategy.ethical.casinoAndGambling"),
          discarded: this.editedDiscardedSectors.gamblingDiscarded,
        },
        {
          key: "fossilFuelsDiscarded",
          title: this.$t("ethicalFossilTitle"),
          discarded: this.editedDiscardedSectors.fossilFuelsDiscarded,
        },
      ];
    },
  },
  methods: {
    toggleItem(item: EthicalArea) {
      const newDiscardedSectors = this.editedDiscardedSectors;
      newDiscardedSectors[item] = !this.editedDiscardedSectors[item];
      this.$store.commit(PortfolioMutation.editDiscardedSectors, newDiscardedSectors);
    },
    async toggleEdit(): Promise<void> {
      if (this.edit) {
        const { editedStrategy, strategy } = this.$store.state.portfolioStore;
        const hasChanged
          = editedStrategy.defenseDiscarded !== strategy.defenseDiscarded
          || editedStrategy.alcoholTobaccoDiscarded !== strategy.alcoholTobaccoDiscarded
          || editedStrategy.fossilFuelsDiscarded !== strategy.fossilFuelsDiscarded
          || editedStrategy.gamblingDiscarded !== strategy.gamblingDiscarded;
        if (hasChanged) {
          this.$emit("save");
        }
      } else {
        this.$store.commit(PortfolioMutation.increaseUnsavedEdits);
      }
      this.edit = !this.edit;
    },
  },
});
</script>

<style lang="scss" scoped>
.ethical {
  &__ban-icon {
    width: 1.875rem;
    font-size: 1.5rem;
    color: rgba($nearly-black, 0.8);
    margin: 0 1rem 0 0 !important;
    align-self: center;
    &--faded {
      color: rgba($nearly-black, 0.15);
    }
    &--hidden {
      opacity: 0;
    }
  }
  &__header {
    padding: 1.125rem 1.25rem;
    height: 3.75rem;
  }
  &__item-title {
    font-size: 1rem;
    font-family: $heading-font-family;
    line-height: 1.3em;
    font-weight: 300;
  }
  &__list-item {
    padding: 0 1.25rem;
    border-radius: 0.5rem;
    &:hover {
      background-color: #f7f7f7;
    }
  }
}
:deep(.v-list-item--disabled) {
  color: $nearly-black;
  opacity: 1;
}
</style>
