<template>
  <div class="representatives">
    <section-header
      :header="
        $vuetify.display.mdAndUp
          ? $t('company-profile.representatives.representatives')
          : $t('company-profile.representatives.representativesMobile')
      "
      class="representatives__header"
    >
      <template #icon>
        <icon
          :icon="['fal', 'user']"
          size="lg"
        />
      </template>

      <v-btn
        :disabled="hasPendingActions"
        icon
        variant="flat"
        class="representatives__icon-wrapper"
        @click="() => (showDialog = true)"
      >
        <icon :icon="['fal', 'user-plus']" />
      </v-btn>
    </section-header>
    <div class="representatives__cards">
      <v-card
        v-for="(rep, i) in representatives"
        :key="i"
      >
        <representative
          :index="i"
          :company-representative="rep"
          @click:outside="() => (showDialog = false)"
        />
      </v-card>
    </div>
    <side-dialog
      v-model="showDialog"
      :title="$t('company-profile.representatives.addRepresentative')"
      @update:model-value="resetAndClose"
    >
      <v-form
        v-if="dialogState === DialogState.EnterInformation"
        ref="form"
        class="representatives__dialog-form"
        @submit.prevent="sendForSign"
      >
        <v-text-field
          v-model="personalIdentityNumber"
          type="text"
          :label="$t('personalNumber')"
          validate-on="blur"
          variant="underlined"
          :rules="[rules.required, rules.personalIdentityNumber]"
        />
        <v-text-field
          v-model="role"
          validate-on="blur"
          variant="underlined"
          type="text"
          :rules="[rules.required]"
          :label="$t('companyRole')"
        />
        <phone-number-input @update:model-value="(val) => (phoneNumber = val)" />
        <v-text-field
          v-model="email"
          :rules="[rules.required, rules.email]"
          type="text"
          :label="$t('email')"
          validate-on="blur"
          variant="underlined"
        />
      </v-form>
      <div
        v-if="dialogState === DialogState.EnterInformation"
        class="representatives__dialog-info-text"
      >
        <p>{{ $t("company-profile.representatives.info1") }}</p>
        <p>{{ $t("company-profile.representatives.info2") }}</p>
      </div>
      <div
        v-if="dialogState === DialogState.EnterInformation"
        class="representatives__dialog-button-wrapper"
      >
        <common-button
          secondary
          @click="resetAndClose"
        >
          {{ $t("cancel") }}
        </common-button>
        <common-button @click="sendForSign()">
          {{ $t("company-profile.representatives.send") }}
        </common-button>
      </div>
      <div
        v-else-if="dialogState === DialogState.Sending"
        class="representatives__dialog-sending"
      >
        <p>{{ $t("company-profile.representatives.sending") }}</p>
        <loading-spinner />
      </div>
      <div
        v-else
        class="representatives__dialog-sending"
      >
        {{ $t("company-profile.representatives.completed") }}
      </div>
      <div
        v-if="dialogState === DialogState.Complete"
        class="representatives__dialog-button-wrapper"
      >
        <common-button @click="resetAndClose()">
          {{ $t("ok") }}
        </common-button>
      </div>
    </side-dialog>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SectionHeader from "@/components/section-header.vue";
import { UserAction } from "@/store/user/action-definitions";
import {
  ActionWaitingForSignature,
  CompanyRepresentativeInfo,
  CreateCompanyRepresentativeUser
} from "@/clients";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import PhoneNumberInput from "@/components/phone-number-input.vue";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import Representative from "./representative.vue";

enum DialogState {
  EnterInformation,
  Sending,
  Complete
}
export default defineComponent({
  components: {
    SectionHeader,
    Representative,
    SideDialog,
    CommonButton,
    LoadingSpinner,
    PhoneNumberInput
  },
  data() {
    return {
      DialogState,
      showDialog: false,
      dialogState: DialogState.EnterInformation,
      personalIdentityNumber: "",
      role: "",
      phoneNumber: "",
      email: "",
      rules: {
        email: (value: string) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("company-profile.representatives.invalidEmail");
        },
        required: (value: string) => !!value || this.$t("mandatory"),
        personalIdentityNumber: (value: string) => {
          const pattern = /^(19|20)?(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/;
          return pattern.test(value) || this.$t("invalidPersonalIdentityNumber");
        }
      }
    };
  },
  computed: {
    hasPendingActions(): boolean {
      return this.representatives?.some(
        (r) => r.actionWaitingForSignature === ActionWaitingForSignature.Add
      );
    },
    representatives(): CompanyRepresentativeInfo[] {
      if (this.$store.state.userStore.companyRepresentatives) {
        return [...this.$store.state.userStore.companyRepresentatives].sort(
          (a, b) => a.createdAt - b.createdAt
        );
      }
      return [];
    }
  },
  methods: {
    resetAndClose() {
      this.showDialog = false;
      this.dialogState = DialogState.EnterInformation;
      this.personalIdentityNumber = "";
      this.role = "";
      this.phoneNumber = "";
      this.email = "";
    },
    async sendForSign() {
      if ((await (this.$refs.form as any).validate()).valid) {
        this.dialogState = DialogState.Sending;
        try {
          await this.$store.dispatch(UserAction.addCompanyRepresentative, {
            legalEntityId: this.$store.state.userStore.currentLegalEntity.brickId,
            request: new CreateCompanyRepresentativeUser({
              personalIdentityNumber: this.personalIdentityNumber,
              email: this.email,
              phoneNumber: this.phoneNumber,
              role: this.role
            })
          });
          this.dialogState = DialogState.Complete;
        } catch {
          this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("errorContactSupport"));
          this.dialogState = DialogState.EnterInformation;
        }
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.representatives {
  max-width: 71rem;
  padding-top: 1rem;
  width: 100%;
  @include medium-up {
    padding: unset;
  }
  &__header {
    width: 100%;
  }
  &__cards {
    padding-top: 1rem;
    display: grid;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    grid-template-rows: auto;
    @include small-up {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto auto;
    }
    @include medium-up {
      margin-top: 2rem;
      grid-row-gap: 1.5rem;
      grid-column-gap: 1.5rem;
    }
  }
  &__icon-wrapper {
    font-size: 1.125rem;
    margin: 0.25rem 0.5rem 0.5rem 0.5rem;
  }
  &__dialog-info-text {
    padding: 4rem 2rem 0 2rem;
    text-align: center;
    font-weight: 300;
  }
  &__dialog-form {
    padding: 2rem 3rem 0 3rem;
    display: flex;
    flex-direction: column;
  }
  &__dialog-sending {
    padding: 4rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &__dialog-button-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    width: 100%;
    &--divided {
      grid-template-columns: 8.125rem 8.125rem;
    }
  }
}
.v-menu__content {
  border-radius: 0.5rem;
  box-shadow: 0 0.1875rem 2rem rgba(0, 0, 0, 0.32);
  max-width: 15.625rem;
  @include small-down {
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto !important;
  }
}
</style>
