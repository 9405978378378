<template>
  <div class="income-warning">
    <SingleChoice
      :alternatives="informationAlternatives"
      @click="click"
    >
      <information-text-section
        :heading="$t('create-portfolio.questions.economy.income-warning.heading')"
        :body="$t('create-portfolio.questions.economy.income-warning.body')"
        :body2="$t('create-portfolio.questions.economy.income-warning.body2')"
        imagePadding="1.125rem 1.625rem 1.125rem 1.625rem"
      >
        <icon />
      </information-text-section>
    </SingleChoice>
  </div>
</template>

<script setup lang="ts">
import InformationTextSection from "@/views/signup/information-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Icon from "@/assets/images/signup/file-invoice-dollar-light.svg";
import { SingleChoice as SingleChoiceType } from "@/types/signup";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const i18n = useI18n();
const router = useRouter();

const informationAlternatives: SingleChoiceType[] = [
  {
    text: i18n.t("quit"),
    key: "quit"
  }
];
function click(): void {
  router.push({ name: "overview" });
}
</script>

<style lang="scss" scoped>
.income-warning {
  height: 100%;
  width: 100%;
}
</style>
