<template>
  <v-dialog
    v-model="showDialog"
    content-class="warn-dialog"
    @click:outside="cancel"
  >
    <v-card class="warn-dialog__warning-card">
      <p class="warn-dialog__warning-text">
        {{ $t("unsavedEditsWarning") }}
      </p>
      <v-card-actions class="warn-dialog__warning-actions">
        <common-button
          secondary
          @click="discard"
        >
          {{ $t("exitWithoutSaving") }}
        </common-button>
        <common-button
          class="warn-dialog__cancel-button"
          @click="cancel"
        >
          {{ $t("cancel") }}
        </common-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";

export default defineComponent({
  emits: ["update:model-value", "discard", "cancel"],
  components: {
    CommonButton
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit("update:model-value", value);
      }
    }
  },
  methods: {
    discard() {
      this.$emit("discard");
    },
    cancel() {
      this.$emit("cancel");
    }
  }
});
</script>

<style lang="scss" scoped>
.warn-dialog {
  &__warning-card {
    padding: 2rem 2rem 1rem 2rem;
  }
  &__warning-text {
    font-family: $heading-font-family;
    font-size: 1.125rem;
    font-weight: 500;
    color: $nearly-black;
    margin-bottom: 2rem;
    text-align: center;
    @include medium-up {
      text-align: left;
    }
  }
  &__warning-actions {
    justify-content: center;
    flex-wrap: wrap;
    @include small-up {
      flex-wrap: unset;
    }
  }
  &__cancel-button {
    margin-top: 0.5rem;
    @include xsmall-up {
      margin-top: 0rem;
    }
  }
}
:deep(.warn-dialog) {
  max-width: 30rem;
}
.v-card-actions .v-btn ~ .v-btn:not(.v-btn-toggle .v-btn) {
  @include xsmall-down {
    margin-left: 0;
  }
}
</style>
