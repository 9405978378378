<template>
  <portfolio-confirmation
    :body="$t(`create-portfolio.questions.risk.portfolio-confirmation.body.${riskWillingness}`)"
    :risk-willingness="riskWillingness"
    :heading="$t('create-portfolio.questions.risk.portfolio-confirmation.heading')"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PortfolioConfirmation from "@/views/common-signup/questions/risk/portfolio-confirmation.vue";

export default defineComponent({
  components: { PortfolioConfirmation },
  computed: {
    riskWillingness() {
      return this.$store.state.signupStore.riskWillingness;
    }
  }
});
</script>

<!-- eslint-disable -->
