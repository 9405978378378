import { library } from "@fortawesome/fontawesome-svg-core";
// Official documentation available at: https://github.com/FortAwesome/vue-fontawesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faStepBackward,
  faStepForward,
  faCaretDown as fasCaretDown,
  faCheckCircle as fasCheckCircle,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faCircle as fasCircle,
  faEnvelope as fasEnvelope,
  faGift as fasGift,
  faInfo as fasInfo,
} from "@fortawesome/free-solid-svg-icons";
import {
  faDotCircle,
  faCircle as farCircle,
  faFrownOpen as farFrownOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAddressBook,
  faAngleRight,
  faArrowToBottom,
  faArrowToLeft,
  faArrowToRight,
  faBadgePercent,
  faBan,
  faBell,
  faBriefcase,
  faBroadcastTower,
  faBuilding,
  faBusinessTime,
  faCalendar,
  faCalendarDay,
  faCar,
  faCaretDown,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCloud,
  faCog,
  faCoins,
  faComments,
  faDice,
  faEnvelope,
  faExclamationSquare,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFighterJet,
  faFileAlt,
  faFileSignature,
  faFilter,
  faGasPump,
  faGlobeAfrica,
  faGlobeAmericas,
  faGrinBeam,
  faHeart,
  faHeartRate,
  faHeartbeat,
  faHistory,
  faIndustry,
  faInfo,
  faInfoCircle,
  faKiwiBird,
  faLaptop,
  faLeaf,
  faLightbulb,
  faMinus,
  faMinusSquare,
  faMoneyBillWave,
  faPalette,
  faPause,
  faPen,
  faPiggyBank,
  faPlus,
  faReceipt,
  faRecycle,
  faRedo,
  faSave,
  faSearch,
  faShoppingCart,
  faSignOut,
  faSlidersH,
  faSmoking,
  faSortAlphaUp,
  faSquare,
  faSync,
  faThumbsUp,
  faTimes,
  faTools,
  faTrashAlt,
  faTree,
  faTreeAlt,
  faTriangle,
  faTrophy,
  faUniversity,
  faUser,
  faUserCog,
  faUserFriends,
  faUserPlus,
  faUserTie,
  faUsers,
  faWallet,
  faWineBottle,
  faBars as falBars,
  faBriefcase as falBriefcase,
  faGift as falGift,
  faHeart as falHeart,
  faPlusCircle as falPlusCircle,
  faUser as falUser,
  faUserCircle as falUserCircle,
} from "@fortawesome/pro-light-svg-icons";

import { faTrashClock } from "@fortawesome/pro-solid-svg-icons";

// If not present, it won't be visible within the application. Considering that you
// don't want all the icons for no reason. This is a good way to avoid importing too many
// unnecessary things.
library.add(
  faCalendar,
  faSquare,
  faThumbsUp,
  faCheckSquare,
  faHistory,
  faChartPie,
  faFighterJet,
  faWineBottle,
  faSmoking,
  faRedo,
  faDice,
  faHeartRate,
  faGasPump,
  faTimes,
  faChevronLeft,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  fasChevronRight,
  faAngleRight,
  faInfo,
  faChartLine,
  faCoins,
  faGlobeAmericas,
  faSortAlphaUp,
  faArrowToBottom,
  faArrowToLeft,
  faArrowToRight,
  faTools,
  faLaptop,
  faBuilding,
  faIndustry,
  faHeartbeat,
  faTree,
  faShoppingCart,
  faCar,
  faLightbulb,
  faSlidersH,
  faTrophy,
  faCaretDown,
  faInfoCircle,
  faCog,
  faReceipt,
  faPen,
  faMoneyBillWave,
  faTrashAlt,
  faBriefcase,
  faCheckCircle,
  faCircle,
  faHeart,
  faPalette,
  faBan,
  faCheck,
  faUser,
  falBriefcase,
  falHeart,
  falPlusCircle,
  falUser,
  falBars,
  faUserCog,
  faBell,
  faSignOut,
  faAddressBook,
  faBusinessTime,
  faSave,
  faInfo,
  fasInfo,
  faFilter,
  faEye,
  faSearch,
  faEyeSlash,
  faComments,
  farFrownOpen,
  faEnvelope,
  faFileSignature,
  falUserCircle,
  faFileAlt,
  faBroadcastTower,
  faExclamationSquare,
  faSync,
  fasEnvelope,
  falGift,
  fasGift,
  faPlus,
  faExternalLinkAlt,
  faGrinBeam,
  faBadgePercent,
  faLeaf,
  faCloud,
  faGlobeAfrica,
  faRecycle,
  faUsers,
  faUserTie,
  faTreeAlt,
  faUserFriends,
  faMinus,
  faCalendarDay,
  faTriangle,
  faUserPlus,
  faWallet,
  fasCheckCircle,
  faPiggyBank,
  faUniversity,
  faPause,
  fasCheckCircle,
  faDotCircle,
  farCircle,
  fasCircle,
  fasChevronUp,
  fasChevronDown,
  faArrowUp,
  faArrowDown,
  fasCaretDown,
  faStepBackward,
  faStepForward,
  fasChevronLeft,
  faTrashClock,
  faMinusSquare,
  faKiwiBird,
);

export { FontAwesomeIcon };
