<template>
  <div
    class="bank-id-icon"
    :style="{ width: size + 'rem', height: size + 'rem' }"
  >
    <img
      :src="imgUrl"
      class="bank-id-icon__image"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 1
    }
  },
  data() {
    return { imgUrl: new URL("/assets/images/signup/bankid.svg", import.meta.url).href };
  }
});
</script>

<style lang="scss" scoped>
.bank-id-icon {
  box-shadow: 0 0.1875rem 1rem rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__image {
    height: 80%; // Icon looks centered better when a bit smaller in relation to box shadow
    vertical-align: middle;
    display: inline-block;
  }
}
</style>
