<template>
  <single-choice-template
    :alternatives="alternatives"
    @click="select"
  >
    <question-text-section
      :heading="heading"
      progress
    />
  </single-choice-template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { TimeToWithdraw } from "@/clients";
import { SignupFlowModificationType } from "@/config/signup-modification";
import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import { SingleChoice } from "../../../../types/signup";

export default defineComponent({
  emits: ["select"],
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  props: { warningName: { type: String }, heading: { type: String } },
  computed: {
    alternatives(): SingleChoice[] {
      return [
        {
          text: this.$t("timeToWithdrawOptions.LessThanOneYear"),
          key: TimeToWithdraw.LessThanOneYear
        },
        {
          text: this.$t("timeToWithdrawOptions.OneToFiveYears"),
          key: TimeToWithdraw.OneToFiveYears
        },
        {
          text: this.$t("timeToWithdrawOptions.FiveToTenYears"),
          key: TimeToWithdraw.FiveToTenYears
        },
        {
          text: this.$t("timeToWithdrawOptions.MoreThanTenYears"),
          key: TimeToWithdraw.MoreThanTenYears
        }
      ];
    }
  },
  methods: {
    async select(key: TimeToWithdraw): Promise<void> {
      this.$emit("select", key);
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.TimeToWithdrawWarning,
        include: key === TimeToWithdraw.LessThanOneYear
      });
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped></style>
