<template>
  <single-choice-template
    class="previous-experience-answer"
    :alternatives="questionAlternatives"
    :two-columns="false"
    @click="select"
  >
    <question-text-section
      progress
      :heading="$t('yesCorrect')"
      :body="$t('common-signup.questions.risk.previous-experience-answer.body1')"
    />
    <question-text-section
      :body="$t('common-signup.questions.risk.previous-experience-answer.body2')"
    />
    <div class="previous-experience-answer__link-wrapper">
      <span
        class="previous-experience-answer__link"
        @click="select('stock-information')"
        >{{ $t("common-signup.questions.risk.previous-experience-answer.readMore") }}</span
      >
    </div>
  </single-choice-template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupFlowModificationType } from "@/config/signup-modification";
import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    questionAlternatives() {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    async select(key: string): Promise<void> {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.StockInformation,
        include: key === "stock-information"
      });
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.previous-experience-answer {
  height: 100%;
  width: 100%;
  &__link-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 21.25rem;
    margin: 0 auto;
    @include medium-up {
      max-width: 44.5rem;
    }
  }
  &__link {
    text-decoration: underline;
    cursor: pointer;
    margin-top: 2.425rem;
  }
}
</style>
