<template>
  <v-btn
    v-if="!addCode && !affiliateCode"
    class="affiliate-code-input__code-btn"
    :style="{
      color: colors?.text ? colors.text : '$nearly-black'
    }"
    variant="text"
    @click="addCode = true"
  >
    <icon
      fixed-width
      :icon="['fal', 'plus']"
    />{{ $t("addDiscountCode") }}</v-btn
  >
  <v-text-field
    v-else
    v-model="affiliateCode"
    :label="$t('discountCode')"
    type="text"
    validate-on="blur"
    variant="underlined"
    :error-messages="codeErrorMessage ? [codeErrorMessage] : undefined"
    :loading="loadingVerification"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { validateAffiliateCode } from "@/clients/services";
import { SignupState } from "@/store/signup/types";
import { Colors } from "@/styles/colors";

export default defineComponent({
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    isNaturalPerson: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    addCode: false,
    codeErrorMessage: undefined as string | undefined,
    loadingVerification: false
  }),
  computed: {
    colors(): Colors | undefined {
      const { colors } = this.$store.state.signupStore as SignupState;
      return colors || undefined;
    },
    affiliateCode: {
      get(): string {
        return this.modelValue;
      },
      set(value: string): void {
        this.$emit("update:model-value", value);
      }
    }
  },
  methods: {
    async validate(): Promise<boolean> {
      if (!this.affiliateCode) {
        return true;
      }

      this.loadingVerification = true;
      let valid = false;
      try {
        valid = await validateAffiliateCode(this.affiliateCode, undefined, this.isNaturalPerson);
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      this.loadingVerification = false;
      if (!valid) {
        this.codeErrorMessage = this.$t("codeInvalid");
      }

      return valid;
    }
  }
});
</script>

<style scoped lang="scss">
.affiliate-code-input {
  &__code-btn {
    text-transform: none;
    padding: 0 0.2rem 0rem 0 !important;
    opacity: 0.66;
    margin-bottom: 1rem;
  }
}
</style>
