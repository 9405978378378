<script setup lang="ts">
import { downloadFileResponse } from "@/utils/downloader";

const props = defineProps<{ transactions: CsvTransactionRow[] | undefined }>();

export interface CsvTransactionRow {
  date: string;
  value: number;
  quantity: number;
  sum: number;
  marketPlace: string;
  name: string | undefined;
  transactionType: string;
  currency: string;
}

function downloadCsv(): void {
  if (!props.transactions || props.transactions.length === 0) {
    return;
  }
  const sortedKeys = Object.keys(props.transactions[0]);
  let result = `${sortedKeys.join(";")}\n`;
  props.transactions.forEach((row) => {
    const sortedValues = sortedKeys.map(key => row[key]);
    result += `${sortedValues.join(";")}\n`;
  });

  downloadFileResponse({
    data: new Blob([result], {
      type: "text/csv",
    }),
    status: 0,
    fileName: "Transaktioner.csv",
    headers: undefined,
  });
}
</script>

<template>
  <v-btn
    v-if="$vuetify.display.mdAndUp"
    prepend-icon="fal fa-arrow-to-bottom"
    height="3rem"
    color="white"
    class="button"
    @click="downloadCsv"
  >
    Ladda ned
  </v-btn>
</template>

<style lang="scss" scoped>
.button {
  box-shadow: 0 0.125rem 0.625rem rgba($nearly-black, 0.16);
  font-size: 1rem !important;
  font-family: sofia-pro;
  letter-spacing: normal;
  text-transform: none;
  width: 8.375rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
</style>
