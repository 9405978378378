import { buildCommonModifications } from "./common-modifications";
import { SignupFlowModificationType } from "./signup-modification";

export const additionalQuestions = {
  ...buildCommonModifications("esg"),
  [SignupFlowModificationType.SustainablePortfolio]: [
    "/esg/sustainable-method"
    // "/esg/sustainable-info",
    // "/esg/sustainable-choice"
  ],
  [SignupFlowModificationType.MinimizedNegativeImpact]: [
    "/esg/negative-impact",
    "/esg/sustainable-choice"
  ],
  [SignupFlowModificationType.SustainablePortfolioVerify]: ["/esg/sustainable-choice-verify"]
};

export const questionPaths = [
  {
    header: "Hållbarhet",
    paths: [
      "/esg/sustainability",
      "/esg/sustainable-investment",
      "/esg/summary-sign",
      "/esg/congratulations"
    ]
  }
];

export const CURRENT_ESG_PATH_KEY = "current_esg_path";
