<template>
  <div class="form">
    <h2>Succé!</h2>
    <p>Event har skickats</p>
    <common-button @click="$router.push({ path: '/cio-form' })"
      >Tillbaka till formulär</common-button
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";

export default defineComponent({
  components: {
    CommonButton
  }
});
</script>

<style lang="scss" scoped>
.form {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  margin: auto;
  width: 27rem;
  flex-direction: column;
  row-gap: 2rem;
}
</style>
