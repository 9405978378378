<template>
  <div class="browser">
    <v-btn
      icon
      fixed-width
      class="browser__close-button"
      @click="() => ignoreWarning()"
    >
      <icon
        fixed-width
        :icon="['fal', 'times']"
        class="browser__close-button-icon"
      />
    </v-btn>
    <h1 class="browser__header">
      {{ $t("error-pages.unsupported-browser.header") }}
    </h1>
    <p class="browser__sub-header">
      {{ $t("error-pages.unsupported-browser.subHeader") }}
    </p>
    <div class="browser__link-wrapper">
      <h4 class="browser__download-header">
        {{ $t("error-pages.unsupported-browser.downloadHeader") }}
      </h4>
      <p class="browser__download-sub-header">
        {{ $t("error-pages.unsupported-browser.downloadSubHeader") }}
      </p>
      <div class="browser__links">
        <a
          v-for="browser in browsers"
          :key="browser.name"
          class="browser__link"
          :href="browser.link"
        >
          <img
            width="40"
            height="40"
            :src="getBrowserLogoUrl(browser.name.toLowerCase())"
          />
          <p>{{ browser.name }}</p>
        </a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

interface BrowserLink {
  name: string;
  link: string;
}

export default defineComponent({
  emits: ["ignoreWarning"],
  head() {
    return {
      title: this.$t("error-pages.unsupported-browser.header")
    };
  },
  computed: {
    browsers(): BrowserLink[] {
      return [
        {
          name: "Chrome",
          link: "https://www.google.com/chrome/"
        },
        {
          name: "Firefox",
          link: "https://getfirefox.com/"
        },
        {
          name: "Safari",
          link: "https://www.apple.com/safari/"
        }
      ];
    }
  },
  methods: {
    getBrowserLogoUrl(browserName: string): string {
      return new URL(`/assets/images/logos/${browserName}-logo.svg`, import.meta.url).href;
    },
    ignoreWarning() {
      this.$emit("ignoreWarning");
    }
  }
});
</script>
<style lang="scss" scoped>
.browser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 35rem;
  padding: 2rem 1rem;
  text-align: center;
  @include small-up {
    padding: 0;
    padding-top: 5rem;
  }
  &__close-button {
    position: absolute;
    right: 0.1rem;
    top: 0.1rem;
    @include medium-up {
      right: 2rem;
      top: 2rem;
    }
    &-icon {
      font-size: 1.375rem;
    }
  }
  &__header {
    font-size: 1.375rem;
    @include large-up {
      font-size: 2rem;
    }
  }
  &__sub-header {
    font-size: 1.125rem;
    @include large-up {
      font-size: 1.5rem;
    }
  }
  &__link-wrapper {
    background-color: rgba($nearly-black, 0.04);
    padding: 1.125rem;
  }
  &__download-header {
    font-size: 1rem;
    font-weight: 500;
    @include medium-up {
      font-size: 1.125rem;
    }
    @include large-up {
      font-size: 1.375rem;
    }
  }
  &__download-sub-header {
    font-size: 0.9rem;
    margin-top: 0.5rem;
    @include medium-up {
      font-size: 1rem;
    }
    @include large-up {
      font-size: 1.125rem;
    }
  }
  &__links {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__link {
    text-decoration: none;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    transition: background-color 0.2s ease-in-out;
    color: $nearly-black;
    &:hover {
      background-color: $whisper;
    }
    &:focus {
      outline: none;
    }
    &.focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
  }
}
</style>
