<template>
  <div class="loader">
    <div class="loader__line" />
    <div class="loader__line" />
    <div class="loader__line" />
  </div>
</template>

<style lang="scss" scoped>
@mixin animation($string) {
  -webkit-animation: #{$string};
  -moz-animation: #{$string};
  -ms-animation: #{$string};
  -o-animation: #{$string};
  animation: #{$string};
}
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@include keyframes(loadingAnimation) {
  0% {
    width: 0.5rem;
  }
  50% {
    width: 1.125rem;
  }
  100% {
    width: 0.5rem;
  }
}
.loader {
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  &__line {
    display: inline-block;
    width: 0.5rem;
    height: 0.375rem;
    border-radius: 0.375rem;
    margin: 0 0.125rem;
    background-color: $dusty-green;
    &:nth-last-child(1) {
      @include animation("loadingAnimation 1.5s 1s infinite");
    }
    &:nth-last-child(2) {
      @include animation("loadingAnimation 1.5s 0.5s infinite");
    }
    &:nth-last-child(3) {
      @include animation("loadingAnimation 1.5s 0s infinite");
    }
  }
}
</style>
