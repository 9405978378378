<template>
  <div class="time-to-withdraw-warning">
    <single-choice-template
      :alternatives="informationAlternatives"
      @click="quit"
    >
      <information-text-section
        :heading="$t('informationHeading')"
        :body="$t('common-signup.questions.economy.time-to-withdraw-warning.informationBody')"
      >
        <icon />
      </information-text-section>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import Icon from "@/assets/images/signup/business-time-light.svg";
import { SingleChoice } from "@/types/signup";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import InformationTextSection from "../../../signup/information-text-section.vue";

export default defineComponent({
  emits: ["quit"],
  components: {
    SingleChoiceTemplate,
    InformationTextSection,
    Icon
  },
  computed: {
    informationAlternatives(): SingleChoice[] {
      return [
        {
          text: this.$t("quit"),
          key: "quit"
        }
      ];
    }
  },
  methods: {
    quit() {
      this.$emit("quit");
    }
  }
});
</script>
<style lang="scss" scoped>
.time-to-withdraw-warning {
  height: 100%;
  width: 100%;
}
</style>
