<template>
  <div class="development">
    <div class="development__savings-wrapper">
      <Savings
        :performance="chartDataForRange"
        class="development__savings"
        @click-savings="() => (showDialog = true)"
      />
      <div
        class="development__savings-icon-wrapper"
        @click="() => (showDialog = true)"
      >
        <icon
          class="development__savings-icon"
          :icon="['fa', 'info']"
        />
      </div>
    </div>
    <div class="development__development-wrapper">
      <div
        v-if="!performanceError"
        class="development__chart-wrapper"
      >
        <div
          v-if="showEmptyState"
          data-jest="empty-state"
          class="development__empty-state"
        >
          <icon
            :icon="['fal', 'info-circle']"
            class="development__empty-state-icon"
          />
          <h2 class="development__empty-state-heading">
            {{ $t("portfolio.overview.development.development.empty_state_heading") }}
          </h2>
          <p class="development__empty-state-description">
            {{ $t("portfolio.overview.development.development.empty_state_description") }}
          </p>
        </div>
        <transition name="fade-chart">
          <Chart
            v-if="showChart"
            data-jest="chart"
            :chart-range="range"
            :chart-data="chartDataForRange"
            :min-time="chartMinTime"
            :max-time="chartMaxTime"
            class="development__chart"
          />
        </transition>
      </div>
      <div
        v-else
        class="development__chart-wrapper"
      >
        <ErrorLoading :body="$t('portfolio.overview.development.development.couldNotLoadChart')" />
      </div>
      <RangeSelector
        v-model="range"
        class="development__range-selector"
        :development-percentages="rangeDevelopmentPercentages"
        :available-ranges="ranges"
      />
    </div>
    <LoadingSpinnerOverlay
      persistent
      :model-value="showChartLoadingSpinner"
    />
    <SideDialog
      v-model="showDialog"
      :title="$t('performance')"
    >
      <div class="development__side-dialog">
        <div
          v-for="(section, i) in infoSections"
          :key="i"
        >
          <p class="development__side-dialog--heading">
            {{ section.heading }}
          </p>
          <p class="development__side-dialog--text">
            {{ section.text }}
          </p>
        </div>
      </div>
    </SideDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import type { DateObjectUnits } from "luxon";
import { DateTime } from "luxon";
import Savings from "../../savings.vue";
import RangeSelector from "./range-selector.vue";
import Chart from "./chart.vue";
import type {
  DevelopmentPercentageMap,
  RangeToChartDataMap,
} from "@/types/portfolio";
import { ChartRange } from "@/enums/overview";
import type { DataPoint } from "@/clients";
import { PerformanceResponse } from "@/clients";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import ErrorLoading from "@/components/error-loading.vue";
import SideDialog from "@/components/dialog/side-dialog.vue";

const defaultTimeSet = { hour: 0, minute: 0, millisecond: 0 } as DateObjectUnits;

export default defineComponent({
  components: {
    RangeSelector,
    LoadingSpinnerOverlay,
    Savings,
    Chart,
    ErrorLoading,
    SideDialog,
  },
  props: {
    performanceError: {
      type: Boolean,
    },
    performance: {
      type: Object as () => PerformanceResponse,
      required: false,
    },
    isLoadingPerformance: {
      type: Boolean,
    },
    portfolioStartDate: {
      type: Object as () => Date,
      required: false,
    },
  },
  data: (): any => ({
    range: ChartRange.FromStart,
    showDialog: false,
  }),
  computed: {
    ...mapGetters(["isTestUser"]),
    infoSections() {
      return [
        {
          text: this.$t("portfolio.overview.development.development.infoBodyTop1"),
        },
        {
          text: this.$t("portfolio.overview.development.development.infoBodyTop2"),
        },
        {
          heading: this.$t("portfolio.overview.development.development.infoHeadingAbsolute"),
          text: this.$t("portfolio.overview.development.development.infoBodyAbsolute"),
        },
        {
          heading: this.$t("portfolio.overview.development.development.infoHeadingPercent"),
          text: this.$t("portfolio.overview.development.development.infoBodyPercent1"),
        },
        { text: this.$t("portfolio.overview.development.development.infoBodyPercent2") },
        { text: this.$t("portfolio.overview.development.development.infoBodyPercent3") },
        {
          heading: this.$t("portfolio.overview.development.development.infoHeadingPercentSimple"),
          text: this.$t("portfolio.overview.development.development.infoBodyPercentSimple"),
        },
        {
          heading: this.$t("portfolio.overview.development.development.infoHeadingDifference"),
          text: this.$t("portfolio.overview.development.development.infoBodyDifference"),
        },
      ];
    },
    startDate(): number {
      if (
        this.performance
        && this.performance.totalFraction
        && this.performance.totalFraction.length > 0
      ) {
        return DateTime.fromJSDate(this.performance.totalFraction[0].date)
          .set(defaultTimeSet)
          .valueOf();
      }
      return Date.now();
    },
    chartMinTime(): number {
      if (
        this.chartDataForRange
        && this.chartDataForRange.totalFraction
        && this.chartDataForRange.totalFraction.length > 0
      ) {
        return DateTime.fromJSDate(this.chartDataForRange.totalFraction[0].date)
          .set(defaultTimeSet)
          .valueOf();
      }
      return this.startDate;
    },
    chartMaxTime(): number {
      return DateTime.local().valueOf();
    },
    rangeDevelopmentPercentages(): DevelopmentPercentageMap {
      const valueMap = new Map() as DevelopmentPercentageMap;

      if (this.ranges) {
        this.ranges.forEach((range: ChartRange) => {
          const percentageIncreaseInRange = this.getPercentageIncreaseInRange(
            this.rangeToChartData,
            range,
          );
          if (percentageIncreaseInRange !== 0) {
            valueMap.set(range, percentageIncreaseInRange);
          } else {
            valueMap.set(range, undefined);
          }
        });
        return valueMap;
      }
      return valueMap;
    },
    rangeToChartData(): RangeToChartDataMap | undefined {
      if (this.performance && Object.keys(this.performance).length > 0) {
        const rangeToChartData = new Map() as RangeToChartDataMap;
        this.ranges.forEach((range: ChartRange) => {
          rangeToChartData.set(
            range,
            this.calculatePercentageDevelopmentInRange(this.performance, range),
          );
        });
        return rangeToChartData;
      }
      return undefined;
    },
    chartDataForRange(): PerformanceResponse | undefined {
      if (this.range && this.rangeToChartData) {
        return this.rangeToChartData.get(this.range);
      }
      return undefined;
    },
    showChart(): boolean {
      return !this.showEmptyState && this.rangeToChartData !== undefined;
    },
    showChartLoadingSpinner(): boolean {
      return this.isLoadingPerformance || this.rangeToChartData === undefined;
    },
    showEmptyState(): boolean {
      // TODO make this more robust but it's easier when everyone is using the new way of fetching performance
      // Only looking at performance instead of total holdings as well makes it so we don't have to show a loading spinner when performance is loaded but not holdings which happens alot.
      // For now this should work to show empty state before you have purchased any instruments because the backend skips all the first data points where fraction development is 0
      if (
        this.isLoadingPerformance
        || (this.performance.totalFraction !== undefined && this.performance.totalFraction.length > 1)
      ) {
        return false;
      }
      return true;
    },
    ranges(): ChartRange[] {
      const items: ChartRange[] = [];
      const portfolioAge = DateTime.local().diff(DateTime.fromJSDate(this.portfolioStartDate), [
        "years",
        "months",
      ]);

      if (this.$vuetify.display.smAndDown) {
        if (portfolioAge.years < 1) {
          items.push(
            ChartRange.ThisYear,
            ChartRange.ThreeMonths,
            ChartRange.SixMonths,
            ChartRange.FromStart,
          );
        } else if (portfolioAge.years >= 1 && portfolioAge.years < 3) {
          items.push(
            ChartRange.ThisYear,
            ChartRange.ThreeMonths,
            ChartRange.OneYear,
            ChartRange.FromStart,
          );
        } else if (portfolioAge.years >= 3 && portfolioAge.years < 5) {
          items.push(
            ChartRange.ThisYear,
            ChartRange.ThreeMonths,
            ChartRange.ThreeYears,
            ChartRange.FromStart,
          );
        } else if (portfolioAge.years >= 5) {
          items.push(
            ChartRange.ThisYear,
            ChartRange.ThreeMonths,
            ChartRange.FiveYears,
            ChartRange.FromStart,
          );
        }
      } else if (this.$vuetify.display.smAndUp) {
        if (portfolioAge.years < 1) {
          items.push(
            ChartRange.ThisYear,
            ChartRange.ThreeMonths,
            ChartRange.SixMonths,
            ChartRange.OneYear,
            ChartRange.FromStart,
          );
        } else if (portfolioAge.years >= 1 && portfolioAge.years < 3) {
          items.push(
            ChartRange.ThisYear,
            ChartRange.ThreeMonths,
            ChartRange.SixMonths,
            ChartRange.OneYear,
            ChartRange.FromStart,
          );
        } else if (portfolioAge.years >= 3 && portfolioAge.years < 5) {
          items.push(
            ChartRange.ThisYear,
            ChartRange.ThreeMonths,
            ChartRange.OneYear,
            ChartRange.ThreeYears,
            ChartRange.FromStart,
          );
        } else if (portfolioAge.years >= 5) {
          items.push(
            ChartRange.ThisYear,
            ChartRange.ThreeMonths,
            ChartRange.ThreeYears,
            ChartRange.FiveYears,
            ChartRange.FromStart,
          );
        }
      }
      return items;
    },
  },
  watch: {
    ranges() {
      // If user changes from e.g. a portfolio with 3yr range to a portfolio with 6mo range,
      // the 3yr range will still be pre-selected, i.e. this.range will be set to 3yr.
      // We solve this by always setting range to FromStart since this is available for all
      // portfolios, no matter its age.
      this.range = ChartRange.FromStart;
    },
  },
  methods: {
    getPercentageIncreaseInRange(
      rangeToChartData: RangeToChartDataMap,
      chartRange: ChartRange,
    ): number | undefined {
      if (rangeToChartData && chartRange) {
        const data = rangeToChartData.get(chartRange)?.totalFraction;
        if (data && data.length > 0) {
          return (data[data.length - 1].value ?? 0) * 100;
        }
      }
      return undefined;
    },
    calculatePercentageDevelopmentInRange(
      portfolioPerformance: PerformanceResponse,
      range: ChartRange,
    ): PerformanceResponse | undefined {
      if (!portfolioPerformance || !portfolioPerformance.totalFraction || range === undefined) {
        return undefined;
      }
      const response = new PerformanceResponse({
        ...portfolioPerformance,
        returnsPerformanceInAmount: this.isTestUser
          ? portfolioPerformance.returnsPerformanceInAmount?.filter(x => x.date > this.minTimeForRange(range))
          : portfolioPerformance.returnsPerformanceInAmount,
        totalFraction: this.calculateFractionsInRange(portfolioPerformance.totalFraction, range),
      });
      return response;
    },
    calculateFractionsInRange(fractions: DataPoint[], range: ChartRange): DataPoint[] {
      if (fractions.length === 0) {
        return [];
      }

      const minTimeForRange = new Date(this.minTimeForRange(range));
      minTimeForRange.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const scaledFractions = fractions
        .map((f) => {
          f.date.setHours(0, 0, 0, 0);
          return { ...f } as DataPoint;
        })
        .filter(
          (p: DataPoint) =>
            p.date < today // Dont show price for today since we dont have that data
            && p.date >= minTimeForRange,
        )
        .sort((a: DataPoint, b: DataPoint) => (a.date >= b.date ? 1 : -1))
        .filter(
          (p: DataPoint, index: number) =>
            // Filter in two steps because if we get index 0 without first filtering, we will
            // get the first datapoint of the account
            // We always include the first datapoint since it is the day before the interval
            index === 0 || (p.date.getDay() !== 6 && p.date.getDay() !== 0),
        )
        .map(
          (o: any, _: number, a: Array<any>) =>
            // Scale fractions in selected range to start at 0
            ({
              date: o.date,
              value: (o.value + 1) / (a[0].value + 1) - 1,
              missing: false,
            }) as DataPoint,
        );
      return scaledFractions;
    },
    minTimeForRange(chartRange: ChartRange): number {
      // Generally include day before interval start because we want the development
      // on the first day of interval, i.e. the development from the day before the
      // interval until the first day that is actually in the interval
      switch (chartRange) {
        case ChartRange.ThisYear:
          return DateTime.fromObject({
            day: 1,
            month: 1,
            year: DateTime.local().year,
          })
            .minus({ days: 1 })
            .set(defaultTimeSet)
            .valueOf();
        case ChartRange.ThreeMonths:
          return DateTime.local()
            .minus({ months: 3 })
            .minus({ days: 1 })
            .set(defaultTimeSet)
            .valueOf();
        case ChartRange.SixMonths:
          return DateTime.local()
            .minus({ months: 6 })
            .minus({ days: 1 })
            .set(defaultTimeSet)
            .valueOf();
        case ChartRange.OneYear:
          return DateTime.local()
            .minus({ years: 1 })
            .minus({ days: 1 })
            .set(defaultTimeSet)
            .valueOf();
        case ChartRange.ThreeYears:
          return DateTime.local()
            .minus({ years: 3 })
            .minus({ days: 1 })
            .set(defaultTimeSet)
            .valueOf();
        case ChartRange.FiveYears:
          return DateTime.local()
            .minus({ years: 5 })
            .minus({ days: 1 })
            .set(defaultTimeSet)
            .valueOf();
        case ChartRange.FromStart:
          return this.startDate;
        default:
          return this.startDate;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.fade-chart-enter-active,
.fade-chart-leave-active {
  transition: opacity 0.8s;
}
.fade-chart-enter,
.fade-chart-leave-to {
  opacity: 0;
}
.development {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__chart {
    // height: 100%;
    width: 100%;
    @include small-up {
      padding-left: 0.75rem;
      padding-right: 1.5rem;
    }
  }
  &__chart-bar {
    padding-top: 0.375rem;
  }
  &__chart-wrapper {
    height: 18rem;
    display: flex;
    flex-direction: column;
    @include small-up {
      height: 26rem;
    }
  }
  &__development-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__empty-state {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    @include medium-up {
      padding-top: 5rem;
    }
  }
  &__empty-state-heading {
    color: $soft-blue;
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  &__empty-state-icon {
    color: $aqua;
    font-size: 1.5rem;
    margin-bottom: 0.375rem;
  }
  &__empty-state-description {
    font-weight: 300;
  }
  &__savings {
    padding: 1rem 1.25rem 0.75rem 1.25rem;
    border-bottom: 0.0625rem solid #eaeaea;
    flex: 0 0 100%;
  }
  &__savings-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &__savings-icon-wrapper {
    position: absolute;
    right: 0px;
    border-top: 2.5rem solid $soft-blue;
    border-left: 2.5rem solid transparent;
    cursor: pointer;
  }
  &__savings-icon {
    position: absolute;
    right: 0.4rem;
    top: -2.25rem;
    color: #fff;
  }
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
}
</style>
