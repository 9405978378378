<template>
  <div class="filter">
    <v-menu
      v-model="menu"
      allow-overflow
      :close-on-content-click="false"
    >
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          class="filter__button"
          height="3rem"
          color="white"
        >
          <icon
            fixed-width
            :icon="['fal', 'filter']"
          />
          <span class="filter__button-text">{{
            $t("transactions.transaction-filter.filter")
          }}</span>
          <icon
            class="filter__button-icon--right"
            fixed-width
            :icon="['fal', 'chevron-down']"
          />
        </v-btn>
      </template>
      <v-card class="filter__menu">
        <div class="filter__search">
          <v-text-field
            v-if="$vuetify.display.smAndDown"
            v-model="query"
            variant="underlined"
            :color="nearlyBlack"
            :placeholder="$t('search')"
            hide-details
            single-line
            @update:model-value="(value) => emitSearch(value)"
          >
            <template #append-inner>
              <icon :icon="['fal', 'search']" />
            </template>
          </v-text-field>
        </div>
        <v-checkbox
          v-model="includeDepositAndWithdraws"
          :color="nearlyBlack"
          hide-details
          :label="$t('transactions.transaction-filter.depositWithdraw')"
        />
        <v-checkbox
          v-model="includeBuySell"
          :color="nearlyBlack"
          hide-details
          :label="$t('transactions.transaction-filter.buySell')"
        />
        <v-checkbox
          v-model="includeDividend"
          :color="nearlyBlack"
          hide-details
          :label="$t('transactions.transaction-filter.dividends')"
        />
        <v-checkbox
          v-model="includeFeesAndTaxes"
          :color="nearlyBlack"
          hide-details
          :label="$t('transactions.transaction-filter.taxes')"
        />
        <v-checkbox
          v-model="includeBonus"
          :color="nearlyBlack"
          hide-details
          :label="$t('bonus')"
        />
        <v-checkbox
          v-model="includeCurrencyExchanges"
          :color="nearlyBlack"
          hide-details
          :label="$t('transactions.transaction-filter.currencyExchanges')"
        />
        <date-range
          v-if="$vuetify.display.smAndDown"
          v-model="internalDateRange"
        />
        <v-btn
          height="3rem"
          width="8.125rem"
          color="white"
          class="filter__menu-button"
          @click="clickFilterButton"
        >
          {{ $t("transactions.transaction-filter.filter") }}
        </v-btn>
      </v-card>
    </v-menu>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { TransactionFilter, DateTimeRange } from "@/types/transaction";
import { nearlyBlack } from "@/styles/colors";
import DateRange from "@/components/date-range.vue";

interface TransactionFilterTypes {
  includeBuySellType: boolean;
  includeDepositAndWithdrawsType: boolean;
  includeDividendType: boolean;
  includeFeesAndTaxesType: boolean;
  includeBonusType: boolean;
  includeCurrencyExchanges: boolean;
}

export default defineComponent({
  emits: ["updateDateRange", "update:search-query", "updateFilter"],
  components: { DateRange },
  props: {
    dateRange: {
      type: Object as () => DateTimeRange,
      required: true
    },
    searchQuery: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    menu: false,
    appliedFilter: false,
    nearlyBlack,
    filterOnOpen: {} as TransactionFilterTypes,
    includeBuySell: true,
    includeDepositAndWithdraws: true,
    includeDividend: true,
    includeFeesAndTaxes: true,
    includeCurrencyExchanges: false,
    internalDateRange: { fromDate: undefined, toDate: undefined } as DateTimeRange,
    includeBonus: true,
    query: null as string | null
  }),
  watch: {
    menu: {
      handler(value) {
        if (value) {
          this.appliedFilter = false;
          this.filterOnOpen = {
            includeBuySellType: this.includeBuySell,
            includeDepositAndWithdrawsType: this.includeDepositAndWithdraws,
            includeDividendType: this.includeDividend,
            includeFeesAndTaxesType: this.includeFeesAndTaxes,
            includeBonusType: this.includeBonus,
            includeCurrencyExchanges: this.includeCurrencyExchanges
          };
        } else if (!this.appliedFilter && this.filtersChanged()) {
          this.resetRecentFilterChange();
        }
        this.resetDateRange();
      }
    },
    dateRange: {
      immediate: true,
      handler(newDateRange: DateTimeRange) {
        this.internalDateRange = newDateRange;
      }
    },
    searchQuery: {
      immediate: true,
      handler(newValue: string) {
        this.query = newValue;
      }
    }
  },
  mounted() {
    // Makes filtering apply on loading table initially
    this.emitFilter();
  },
  methods: {
    emitInternalDateRange() {
      this.$emit("updateDateRange", this.internalDateRange);
    },
    emitSearch(searchQuery: string) {
      this.$emit("update:search-query", searchQuery);
    },
    clickFilterButton() {
      this.menu = false;
      this.emitFilter();
      this.emitInternalDateRange();
    },
    emitFilter() {
      this.appliedFilter = true;
      const filter: TransactionFilter = {
        includeBuySellType: this.includeBuySell,
        includeDepositAndWithdrawsType: this.includeDepositAndWithdraws,
        includeDividendType: this.includeDividend,
        includeFeesAndTaxesType: this.includeFeesAndTaxes,
        includeCurrencyExchanges: this.includeCurrencyExchanges,
        includeBonusType: this.includeBonus
      };
      this.$emit("updateFilter", filter);
    },
    filtersChanged(): boolean {
      if (
        this.filterOnOpen.includeBuySellType !== this.includeBuySell ||
        this.filterOnOpen.includeDepositAndWithdrawsType !== this.includeDepositAndWithdraws ||
        this.filterOnOpen.includeDividendType !== this.includeDividend ||
        this.filterOnOpen.includeFeesAndTaxesType !== this.includeFeesAndTaxes ||
        this.filterOnOpen.includeBonusType !== this.includeBonus ||
        this.filterOnOpen.includeCurrencyExchanges !== this.includeCurrencyExchanges
      ) {
        return true;
      }
      return false;
    },
    resetRecentFilterChange(): void {
      this.includeBuySell = this.filterOnOpen.includeBuySellType;
      this.includeDepositAndWithdraws = this.filterOnOpen.includeDepositAndWithdrawsType;
      this.includeDividend = this.filterOnOpen.includeDividendType;
      this.includeFeesAndTaxes = this.filterOnOpen.includeFeesAndTaxesType;
      this.includeBonus = this.filterOnOpen.includeBonusType;
      this.includeCurrencyExchanges = this.filterOnOpen.includeCurrencyExchanges;
    },
    resetDateRange(): void {
      this.internalDateRange = this.dateRange;
    },
    resetToInitial(): void {
      this.includeBuySell = true;
      this.includeDepositAndWithdraws = true;
      this.includeDividend = true;
      this.includeFeesAndTaxes = true;
      this.includeBonus = true;
      this.includeCurrencyExchanges = false;
    }
  }
});
</script>
<style lang="scss" scoped>
.filter {
  display: grid;
  grid-template-rows: 1;
  grid-column-gap: 0.5rem;
  height: 3rem;
  margin: 1rem 0;
  padding-left: 1.125rem;
  max-width: 32rem;
  @include medium-up {
    grid-template-columns: 15rem;
    padding: 0;
  }
  @include large-up {
    grid-template-columns: 25.875rem;
  }
  &__menu {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    justify-content: center;
    padding: 1.5rem 1.125rem 2rem 1.125rem;
  }
  &__search {
    margin-bottom: 1rem;
    :deep(.v-input) {
      padding-top: 0;
    }
  }
  &__search-label {
    color: rgba($nearly-black, 0.8);
  }
  &__menu-button {
    margin: 2rem auto 0 auto;
    color: $nearly-black;
    font-weight: 500;
    font-family: $heading-font-family;
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16);
    font-size: 1.125rem;
    text-transform: none !important;
  }
  &__button {
    border-radius: 0.5rem;
    text-transform: none !important;
    font-size: 1.125rem;
    justify-content: left;
    color: $nearly-black;
    font-weight: 300;
    font-family: $heading-font-family;
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16);
  }
  &__button-icon {
    &--right {
      font-size: 0.875rem;
      position: absolute;
      right: 0.9rem;
    }
  }
  &__button-text {
    padding-left: 1rem;
  }
}
:deep(.v-label) {
  color: $nearly-black;
}
</style>
