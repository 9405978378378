<template>
  <div class="invalid-referral">
    <div class="invalid-referral__logo-container">
      <div class="invalid-referral__logo-wrapper">
        <img
          class="invalid-referral__logo"
          :src="logoUrl"
        />
      </div>
    </div>
    <single-choice
      :alternatives="alternatives"
      @click="buttonClick"
    >
      <information-text-section
        invisible-image
        :heading="$t('signup.introduction.invalid-referral.heading')"
        :body="$t('signup.introduction.invalid-referral.body')"
      />
    </single-choice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import InformationTextSection from "@/views/signup/information-text-section.vue";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice
  },
  extends: Question,
  data(): any {
    return {
      logoUrl: new URL("/assets/images/logos/sigmastocks-logo-text.svg", import.meta.url).href
    };
  },
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("toStartPage"),
          key: "exit"
        }
      ];
    }
  },
  methods: {
    buttonClick() {
      (window as any).location = "https://www.sigmastocks.com";
    }
  }
});
</script>

<style lang="scss" scoped>
.invalid-referral {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__logo-container {
    margin-top: 1rem;
    height: 3rem;
    display: block;
    margin-top: 1rem;
  }
  &__logo {
    path {
      fill: $soft-blue;
      opacity: 1;
    }
  }
  &__logo-wrapper {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
}
</style>
