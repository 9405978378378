<template>
  <div class="question-text-section">
    <h3
      v-if="progress"
      class="question-text-section__progress"
      data-jest="progress"
      :style="{ color: colors?.text }"
    >
      {{ currentProgress }}
    </h3>
    <h1
      v-if="heading"
      class="question-text-section__heading"
      data-jest="heading"
    >
      {{ heading }}
    </h1>
    <p
      v-if="body"
      class="question-text-section__body question-text-section__body--information"
      data-jest="body"
    >
      {{ body }}
    </p>
    <p
      v-if="body2"
      class="question-text-section__body question-text-section__body--information"
      data-jest="body"
    >
      {{ body2 }}
    </p>
    <p
      v-else
      class="question-text-section__body question-text-section__body--information"
    >
      <slot />
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import type { SignupState } from "@/store/signup/types";
import type { Colors } from "@/styles/colors";

export default defineComponent({
  props: {
    heading: {
      type: String,
      default: undefined,
    },
    body: {
      type: String,
      default: undefined,
    },
    body2: {
      type: String,
      default: undefined,
    },
    progress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["numberOfParts", "numberOfQuestionsPerPart"]),
    colors(): Colors | undefined {
      const { colors } = this.$store.state.signupStore as SignupState;
      return colors || undefined;
    },
    currentPart(): number {
      return this.$store.state.signupNavigationStore.questionProgress.partNumber;
    },
    percentageOfCurrentPart(): number {
      return (
        100
        * (this.$store.state.signupNavigationStore.questionProgress.questionNumber
        / this.numberOfQuestionsPerPart[this.currentPart])
      );
    },
    currentProgress(): string {
      return this.$t("progress", {
        question: this.$store.state.signupNavigationStore.questionProgress.questionNumber,
        totalQuestions: this.numberOfQuestionsPerPart[this.currentPart],
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.question-text-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 21.25rem;
  margin: 0 auto;
  @include medium-up {
    max-width: 44.5rem;
  }
  &__body {
    margin: 0.75rem 0 0 0;
    font-size: 1rem;
    font-weight: 300;
    &--information {
      margin: 1rem 0 0 0;
    }
  }
  &__heading {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
    @include medium-up {
      font-size: 1.5rem;
    }
  }
  &__progress {
    font-family: $heading-font-family;
    font-weight: 300;
    opacity: 0.7;
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
  }
}
</style>
