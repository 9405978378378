<template>
  <single-choice
    :alternatives="navigationAlternatives"
    :error-message="$t('chooseAlterantive')"
    :error="showErrorMessage"
    @click="goToNext"
  >
    <question-text-section
      :heading="$t('create-portfolio.questions.account.use-same-account.heading')"
      progress
    />
    <loading-spinner v-if="loadingAccountDetails" />
    <v-radio-group
      v-else
      v-model="selectedBankAccount"
      class="use-same-account__alternatives"
    >
      <v-radio
        v-for="(alternative, index) in alternatives"
        :key="index"
        :true-value="alternative"
        class="use-same-account__account"
      >
        <template #label>
          <div>
            <div class="use-same-account__account-bank">
              {{ alternative.bankName }}
            </div>
            <div class="use-same-account__account-sublabel">
              {{ alternative.accountName }}
            </div>
            <div class="use-same-account__account-sublabel">
              {{ alternative.clearingNumber + "-" + alternative.accountNumber }}
            </div>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </single-choice>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { NavigationAlternative } from "@/types/signup";
import { BankAccountAlternative } from "@/store/signup/types";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { SignupFlowModificationType } from "@/config/signup-modification";
import Question from "@/views/signup/templates/question";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice,
    LoadingSpinner
  },
  extends: Question,
  data() {
    return {
      showErrorMessage: false,
      selected: undefined,
      heading: undefined
    };
  },
  computed: {
    loadingAccountDetails(): boolean {
      return this.$store.state.signupStore.loadingAccountDetails;
    },
    navigationAlternatives(): NavigationAlternative[] {
      return [
        {
          text: this.$t("create-portfolio.questions.account.use-same-account.newAccount"),
          key: "newAccount",
          secondary: true
        },
        {
          text: this.$t("create-portfolio.questions.account.use-same-account.sameAccount"),
          key: "sameAccount"
        }
      ];
    },
    selectedBankAccount: {
      get(): BankAccountAlternative {
        return this.$store.state.signupStore.selectedBankAccountAlternative;
      },
      set(account: BankAccountAlternative | undefined) {
        this.$store.commit(SignupMutation.setSelectedBankAccountAlternative, account);
      }
    },
    alternatives(): BankAccountAlternative[] {
      return this.$store.state.signupStore.bankAccountAlternatives;
    }
  },
  methods: {
    async goToNext(key: string) {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.Tink,
        include: key === "newAccount"
      });
      if (key === "newAccount") {
        this.$store.commit(SignupMutation.setSelectedBankAccountAlternative, undefined);
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      } else if (this.selectedBankAccount) {
        this.$store.commit(SignupMutation.setBank, this.selectedBankAccount.bankName);
        this.$store.commit(
          SignupMutation.setFullBankAccountNumber,
          `${this.selectedBankAccount.clearingNumber}, ${this.selectedBankAccount.accountNumber}`
        );
        this.$store.commit(SignupMutation.setBankAccountName, this.selectedBankAccount.bankName);
        this.$store.commit(
          SignupMutation.setBankAccountNumber,
          this.selectedBankAccount.accountNumber
        );
        this.$store.commit(
          SignupMutation.setClearingNumber,
          this.selectedBankAccount.clearingNumber
        );
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      } else {
        this.showErrorMessage = true;
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.use-same-account {
  &__alternatives {
    max-width: 20em;
    margin: 0 auto;
    padding-top: 2rem;
    @include medium-up {
      padding-top: 3rem;
    }
  }
  &__account {
    padding-left: 15%;
    padding-top: 1rem;
    @include medium-up {
      padding-top: 2rem;
    }
  }
  &__account-bank {
    font-size: 1.125rem;
    font-family: $heading-font-family;
    color: black;
    padding-left: 1.5rem;
    @include medium-up {
      padding-left: 2rem;
      font-size: 1.25rem;
    }
  }
  &__account-sublabel {
    padding-left: 1.5rem;
    @include medium-up {
      padding-left: 2rem;
    }
  }
}
</style>
