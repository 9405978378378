<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref } from "vue";
import SustainabilityCarouselCard from "./sustainability-carousel-card.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import { useQuestion } from "@/views/signup/templates/question-comp";
import InfoCarousel from "@/components/info-carousel.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import SectionedSideDialog from "@/components/dialog/sectioned-side-dialog.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";

const store = useStore();
const router = useRouter();
const { t } = useI18n();

useQuestion(store, router);

const showDialog = ref(false);
const seenAllInfo = ref(false);

const alternatives = [
  {
    text: t("next"),
    key: "next",
  },
];

const infoSections = [
  {
    heading: "Hur går urvalet till?",
    subSections: [
      {
        texts: [
          "För de kategorier (eller enskilda PAI) du vill ta hänsyn till utesluts de 5 % sämsta bolagen inom området, samt de bolag som inte har rapporterat alls. Fler hållbarhetsområden att ta hänsyn till leder alltså till färre möjliga bolag att handla. ",
        ],
      },
    ],
  },
  {
    heading: "Hur många bolag väljs bort?",
    subSections: [
      {
        texts: [
          "För varje hållbarhetsområde du väljer att ta hänsyn till kommer de 5 % sämst presterande bolagen inom det området att uteslutas. Hur många bolag som utesluts totalt om man väljer flera områden beror helt på hur stor del av bolagen som är bland de sämsta inom fler än ett område.",
          "Om många av de bolag som är bland de sämsta inom en av faktorerna också är bland de sämsta inom någon av de andra faktorerna, så kommer färre bolag uteslutas totalt om du väljer att ta hänsyn till flera av faktorerna. ",
          "Om man väljer att ta hänsyn till alla områden kommer ungefär hälften av alla aktier vi har att välja på uteslutas.",
        ],
      },
    ],
  },
  {
    heading: "Hur påverkar valet min avkastning?",
    subSections: [
      {
        texts: [
          "Det går inte att säga på förhand, det kan påverka avkastningen såväl  positivt som negativt. Det vi kan säga på förhand är att det kommer att påverka din avkastning på ett eller annat sätt. ",
          "Om du väljer att ta hänsyn till många hållbarhetsfaktorer försvinner en stor del av aktieurvalet för dig. Ju större andel du väljer bort desto större påverkan kommer valet att ha för din förvaltning och avkastningen av ditt sparande.",
          "Det är viktigt att du är okej med ditt val även om det skulle innebära att du får sämre avkastning än någon som har valt annorlunda.",
        ],
      },
    ],
  },
];

function goToNext() {
  store.dispatch(SignupNavigationAction.goToNextStep, router);
}
</script>

<template>
  <SingleChoice
    information
    :alternatives="alternatives"
    :disabled="!seenAllInfo"
    @show-information="showDialog = true"
    @click="goToNext"
  >
    <QuestionTextSection
      heading="Minskade negativa konsekvenser"
      body="Hur går det till?"
      progress
    />
    <InfoCarousel
      :items="3"
      @done="seenAllInfo = true"
    >
      <v-carousel-item class="sustainability__carousel_item">
        <SustainabilityCarouselCard>
          <div>
            EU har definierat 14 olika områden inom hållbarhet som du kan ta ställning till. Dessa
            områden kallas PAI:s (Principal Adverse Impact).
          </div>
          <br>
          <div>
            För att det ska bli lättare för dig att välja har vi delat in alla PAI:s i fyra grupper.
          </div>
        </SustainabilityCarouselCard>
      </v-carousel-item>
      <v-carousel-item class="sustainability__carousel_item">
        <SustainabilityCarouselCard>
          <div>· Koldioxid & Utsläpp</div>
          <div>· Resurseffektivitet</div>
          <div>· Biologisk mångfald</div>
          <div>· Socialt ansvar & Rättigheter</div>
          <br>
          <div>
            Du får välja vilka kategorier (eller till och med enskilda PAI:s) du vill ta hänsyn till
            i nästa steg.
          </div>
        </SustainabilityCarouselCard>
      </v-carousel-item>
      <v-carousel-item class="sustainability__carousel_item">
        <SustainabilityCarouselCard>
          <div>
            För de kategorier (eller enskilda PAI) du vill ta hänsyn till utesluts de 5 % sämsta
            bolagen inom området, samt de bolag som inte har rapporterat alls. Ditt aktieurval blir
            något mindre, men håller högre nivå inom de områden du väljer.
          </div>
        </SustainabilityCarouselCard>
      </v-carousel-item>
    </InfoCarousel>
    <SectionedSideDialog
      v-model="showDialog"
      title="Hållbarhetsfaktorer"
      :info-sections="infoSections"
    />
  </SingleChoice>
</template>

<style lang="scss" scoped></style>
