import { buildCommonModifications } from "./common-modifications";
import { SignupFlowModificationType } from "./signup-modification";

export const questionPaths = [
  [
    "/kyc/economy/income",
    "/kyc/economy/money-left",
    "/kyc/economy/remainder",
    "/kyc/economy/current-savings",
    "/kyc/economy/bound-capital",
    "/kyc/economy/loans",
  ],
  ["/kyc/preferences/sustainability", "/kyc/preferences/sustainable-investment"],
  [
    "/kyc/aml/occupation",
    "/kyc/aml/interval",
    "/kyc/aml/amount",
    "/kyc/aml/investment-background",
    "/kyc/aml/purpose",
    "/kyc/aml/withdrawal-interval",
    "/kyc/aml/swedish-citizenship",
    "/kyc/aml/swedish-tax-residence",
    "/kyc/aml/tax-liable-usa",
    "/kyc/aml/tax-liable",
    "/kyc/aml/citizenship",
    "/kyc/aml/politically-exposed",
    "/kyc/aml/politically-exposed-associated",
  ],
  ["/kyc/sign", "/kyc/validation"],
];

export const additionalQuestions = {
  ...buildCommonModifications("kyc"),
  [SignupFlowModificationType.KycHasLoans]: ["/kyc/economy/loaned-amount"],
  [SignupFlowModificationType.Pep]: [
    "/kyc/aml/politically-exposed-title",
    "/kyc/aml/politically-exposed-country",
    "/kyc/aml/politically-exposed-ongoing",
    "/kyc/aml/politically-exposed-savings",
  ],
  [SignupFlowModificationType.AssociatedPepRelative]: [
    "/kyc/aml/politically-exposed-associated-relationship-relative",
    "/kyc/aml/politically-exposed-associated-name",
    "/kyc/aml/politically-exposed-associated-title",
    "/kyc/aml/politically-exposed-associated-country",
    "/kyc/aml/politically-exposed-associated-ongoing",
    "/kyc/aml/politically-exposed-associated-savings",
  ],
  [SignupFlowModificationType.AssociatedPepCoworker]: [
    "/kyc/aml/politically-exposed-associated-relationship-coworker",
    "/kyc/aml/politically-exposed-associated-name",
    "/kyc/aml/politically-exposed-associated-title",
    "/kyc/aml/politically-exposed-associated-country",
    "/kyc/aml/politically-exposed-associated-ongoing",
    "/kyc/aml/politically-exposed-associated-organization",
    "/kyc/aml/politically-exposed-associated-savings",
  ],
  [SignupFlowModificationType.PepTerminated]: ["/kyc/aml/politically-exposed-terminated"],
  [SignupFlowModificationType.AssociatedPepTerminated]: [
    "/kyc/aml/politically-exposed-associated-terminated",
  ],
  [SignupFlowModificationType.TaxableCountry]: ["/kyc/aml/taxable-country"],
  [SignupFlowModificationType.KycKeepSustainabilityPreference]: [
    "/kyc/preferences/sustainable-method",
  ],
  [SignupFlowModificationType.KycWarnChangeSustinabilityPreference]: [
    "/kyc/preferences/warn-change-sustainability-preference",
  ],
  [SignupFlowModificationType.SustainablePortfolioVerify]: [
    "/kyc/preferences/sustainable-choice-verify",
  ],
  [SignupFlowModificationType.KycTaxLiableUsa]: ["/kyc/aml/tin"],
};

export const CURRENT_KYC_PATH_KEY = "current_kyc_path";
