<template>
  <single-choice>
    <div>
      <question-text-section
        :heading="$t('identifyWithBankId')"
        :body="body"
      />
      <div
        v-if="registrationState === RegistrationState.NOT_STARTED"
        style="
          width: 21.75rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 5rem auto 0 auto;
        "
      >
        <bank-id-auth-input
          :button-color="primaryColor"
          @login="login"
        />
      </div>
      <div
        v-else-if="registrationState === RegistrationState.OPEN_BANKID"
        class="bankid__loading"
      >
        <bank-id
          style="width: 100%"
          is-login
          @complete="() => (bankIdComplete = true)"
          @canceled="authCancel"
          @failed="authFailed"
        />
      </div>
      <div v-else-if="registrationState === RegistrationState.PENDING">
        <loading-spinner />
      </div>
    </div>
  </single-choice>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { softBlue } from "@/styles/colors";
import { AuthenticationStatus, RegisterUserError, SparData } from "@/clients";
import { BankIdAction } from "@/store/bankid/actions";
import { StartMode } from "@/store/bankid/types";
import BankId from "@/components/bank-id.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import BankIdAuthInput from "@/components/bank-id-auth-input.vue";
import { SignupAction } from "@/store/signup/actions";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupFlowModificationType } from "@/config/signup-modification";
import { UserAction } from "@/store/user/action-definitions";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";

enum RegistrationState {
  NOT_STARTED,
  OPEN_BANKID,
  PENDING,
  FAILED
}

export default defineComponent({
  components: {
    SingleChoice,
    QuestionTextSection,
    BankId,
    BankIdAuthInput,
    LoadingSpinner
  },
  extends: Question,
  data() {
    return {
      softBlue,
      personalIdentityNumber: "",
      authFailure: null as string | null,
      StartMode,
      RegistrationState,
      registrationState: RegistrationState.NOT_STARTED,
      RegisterUserError,
      registerUserError: undefined as RegisterUserError | undefined,
      bankIdComplete: false
    };
  },
  computed: {
    primaryColor(): string {
      return this.$store.state.signupStore.primaryColor;
    },
    body(): string {
      if (this.registrationState === RegistrationState.NOT_STARTED) {
        return this.$t("signup.questions.bankid.body");
      }
      return "";
    },
    isBankIdDev(): boolean {
      return import.meta.env.VITE_ENV !== "production";
    }
  },
  async created() {
    if (this.$route.query.identityToken) {
      this.registrationState = RegistrationState.PENDING;
      const response = await this.$store.dispatch(BankIdAction.norwayLoginComplete, {
        identityToken: this.$route.query.identityToken,
        isSignup: true
      });
      this.$store.commit(SignupMutation.setPersonalIdentityNumber, response.personalIdentityNumber);
      // TODO dummy data for norway
      this.$store.commit(
        SignupMutation.setSparData,
        new SparData({
          address: "address",
          city: "city",
          firstName: "firstName",
          lastName: "lastName",
          zipCode: "zipCode",
          personalIdentityNumber: undefined,
          postAddress: undefined,
          postCity: undefined,
          postCountry: undefined,
          postZipCode: undefined
        })
      );
      this.$store.commit(SignupMutation.setLivingInSweden, true);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    } else if (this.$route.query.errorMessage) {
      this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("authFailed"));
      this.$router.replace({ path: this.$route.path });
    } else if (this.$store.state.userStore.locale === "no") {
      this.registrationState = RegistrationState.OPEN_BANKID;
    } else if (this.$store.state.userStore.locale === "se") {
      this.registrationState = RegistrationState.NOT_STARTED;
    }
  },
  methods: {
    async login({
      startMode,
      personalIdentityNumber
    }: {
      startMode: StartMode;
      personalIdentityNumber: string;
    }) {
      this.personalIdentityNumber = personalIdentityNumber;
      this.registrationState = RegistrationState.OPEN_BANKID;
      this.authFailure = null;
      try {
        const loginResponse = await this.$store.dispatch(BankIdAction.authenticateAndLogin, {
          personalIdentityNumber: this.isBankIdDev ? this.personalIdentityNumber : null,
          startMode,
          isSignup: true
        });
        this.$store.commit(
          SignupMutation.setPersonalIdentityNumber,
          loginResponse.personalIdentityNumber
        );
        if (this.bankIdComplete) {
          this.authComplete();
        }
      } catch (error: any) {
        this.registrationState = RegistrationState.NOT_STARTED;
        const status = this.$store.state.bankIdStore.authenticationStatus;
        if (status === AuthenticationStatus.PersonalIdentityNumberError) {
          this.authFailure = this.$t("authFailedPersonalNumber");
        } else {
          this.authFailure = this.$t("authFailed");
        }
      }
    },
    async authComplete() {
      try {
        await this.$store.dispatch(SignupAction.getSparData);
        await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
          modification: SignupFlowModificationType.NoAddressFound,
          include: false
        });
        this.$store.commit(SignupMutation.setLivingInSweden, true);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
          modification: SignupFlowModificationType.NoAddressFound,
          include: true
        });
      }
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    authCancel() {
      this.registrationState = RegistrationState.NOT_STARTED;
      if (this.$store.state.userStore.locale === "no") {
        // Hacky: Force recreate BankID component to recreate assently client
        nextTick().then(() => {
          this.registrationState = RegistrationState.OPEN_BANKID;
        });
      }
    },
    authFailed() {
      this.registrationState = RegistrationState.NOT_STARTED;
      this.authFailure = this.$t("authFailed");
    }
  }
});
</script>
<style lang="scss" scoped>
.bankid {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 2.5625rem;
  align-items: center;
  text-align: center;
  height: 100%;
  @include small-up {
    padding-top: 5rem;
  }
  &__loading {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
</style>
