<template>
  <div class="expenses-warning">
    <single-choice-template
      :alternatives="informationAlternatives"
      @click="selectInformation"
    >
      <information-text-section
        :heading="$t('create-portfolio.questions.economy.expenses-warning.informationHeading')"
        :body="$t('create-portfolio.questions.economy.expenses-warning.informationBody')"
      >
        <icon />
      </information-text-section>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Icon from "@/assets/images/signup/shopping-cart-coins.svg";
import { SingleChoice } from "@/types/signup";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    InformationTextSection,
    Icon
  },
  computed: {
    informationAlternatives(): SingleChoice[] {
      return [
        {
          text: this.$t("quit"),
          key: "quit"
        }
      ];
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    selectInformation(key: string): void {
      if (key === "quit") {
        this.$router.push({ name: "login" });
      } else {
        this.goToNext();
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.expenses-warning {
  height: 100%;
  width: 100%;
}
</style>
