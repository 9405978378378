<template>
  <div>
    <div
      v-for="(message, key) in messages"
      :key="key"
    >
      <v-snackbar
        :model-value="message.show"
        :timeout="5000"
      >
        <div class="snackbar__text">
          {{ message.text }}
        </div>
      </v-snackbar>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    messages(): any {
      return this.$store.state.userStore.snackBarMessages;
    }
  },
  created() {}
});
</script>
<style lang="scss" scoped>
.snackbar__text {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  margin: 0.5rem 0;
  width: 100%;
}
</style>
