<template>
  <div class="autogiro">
    <div v-if="depositState === DepositState.CHOOSING_AMOUNT">
      <label class="autogiro__subheader">{{ $t("bankTransfer") }}</label>
      <account-input
        v-for="(alternative, index) in userAccounts"
        :key="index"
        :title="alternative.title"
        :account-name="alternative.accountName"
        :account-number="alternative.accountNumber"
      />

      <v-form
        ref="form"
        @submit.prevent="signAmount"
      >
        <v-text-field
          v-model="displayDepositAmount"
          type="tel"
          variant="underlined"
          :label="$t('sum')"
          :suffix="$t('currency')"
          :rules="[rules.number, rules.leadingZeros, rules.depositMinimum]"
          name="deposit"
          :disabled="error || isLockedBecauseOfEconomy"
          validate-on="blur"
          data-cy="depositInput"
          data-jest="text-field"
          class="autogiro__text-field"
        />
      </v-form>
      <Transition>
        <div
          v-if="depositAmount >= 150000"
          style="text-align: center; font-size: 0.875rem"
        >
          {{ $t("portfolio.overview.deposit.autogiro-deposit.highAmountInfo") }}
        </div>
      </Transition>

      <div class="autogiro__button-wrapper">
        <common-button
          data-jest="button-cancel"
          secondary
          @click="resetAndClose"
        >
          {{ $t("cancel") }}
        </common-button>
        <common-button
          :disabled="error || isLockedBecauseOfEconomy"
          data-jest="button-sign"
          data-cy="depositSignButton"
          @click="signAmount()"
        >
          {{ $t("portfolio.overview.deposit.autogiro-deposit.sign") }}
        </common-button>
      </div>
    </div>
    <div v-if="depositState === DepositState.SIGNING">
      <bank-id
        @complete="signComplete"
        @canceled="signCancel"
        @failed="signFailed"
      />
    </div>
    <div
      v-else-if="depositState === DepositState.SIGN_COMPLETE"
      class="autogiro__spinner-wrapper"
    >
      <span>{{ $t("creatingTransaction") }}</span>
      <loading-spinner />
    </div>
    <div
      v-else-if="depositState === DepositState.DEPOSIT_COMPLETE"
      class="autogiro__done-wrapper"
    >
      <span class="autogiro__done-header-label">{{
        $t("portfolio.overview.deposit.autogiro-deposit.done")
      }}</span>
      <icon
        fixed-width
        :icon="['fal', 'thumbs-up']"
        class="autogiro__done-icon"
      />
      <span class="autogiro__done-body-of-copy">{{
        $t("portfolio.overview.deposit.autogiro-deposit.bodyOfCopy")
      }}</span>

      <common-button
        data-jest="button"
        data-cy="depositDoneButton"
        @click="resetAndClose"
      >
        {{ $t("ok") }}
      </common-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { PortfolioAction } from "@/store/portfolio/actions";
import { UserAction } from "@/store/user/action-definitions";
import { PortfolioBase, PortfolioSettings, CreateDepositResponse, ErrorType } from "@/clients";
import { softBlue, nearlyBlack } from "@/styles/colors";
import CommonButton from "@/components/button.vue";
import { BankIdAction } from "@/store/bankid/actions";
import { trackEvent } from "@/clients/segment";
import BankId from "@/components/bank-id.vue";
import { DepositState } from "@/types/portfolio";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { PortfolioState } from "@/store/portfolio/types";
import AccountInput from "./account-input.vue";

interface FormattedAccount {
  title: string;
  accountName: string;
  accountNumber?: string;
}
export default defineComponent({
  emits: ["close"],
  components: {
    BankId,
    AccountInput,
    CommonButton,
    LoadingSpinner
  },
  data() {
    return {
      DepositState,
      softBlue,
      nearlyBlack,
      error: false,
      rules: {
        leadingZeros: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^(0|[1-9][0-9]*)$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        number: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        depositMinimum: (value: string) => {
          value = value.replace(/\s/g, "");
          const numericValue = Number(value);
          return numericValue >= 500 || this.$t("numberNotLargeEnough");
        }
      },
      depositAmount: 0
    };
  },
  computed: {
    depositState: {
      set(value: DepositState) {
        this.$store.commit(PortfolioMutation.setDepositState, value);
      },
      get(): DepositState {
        return this.$store.state.portfolioStore.depositState;
      }
    },
    showDepositDialog: {
      set(value: boolean) {
        this.$store.commit(PortfolioMutation.setShowDepositDialog, value);
      },
      get(): boolean {
        return this.$store.state.portfolioStore.showDepositDialog;
      }
    },
    displayDepositAmount: {
      get(): string {
        if (this.depositAmount) {
          return this.depositAmount.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        this.depositAmount = parseInt(value, 10);
      }
    },
    portfolioSettings(): PortfolioSettings {
      const { portfolioSettings } = this.$store.state.portfolioStore;
      return portfolioSettings;
    },
    userAccounts(): Array<FormattedAccount> {
      const { activePortfolioBase } = this.$store.state.portfolioStore;
      if (activePortfolioBase && this.portfolioSettings) {
        const mappedAccounts = this.mapAccounts(activePortfolioBase);
        return mappedAccounts;
      }
      const emptyAccounts = this.getEmptyAccounts();
      return emptyAccounts;
    },
    isLockedBecauseOfEconomy(): boolean {
      if (this.$store.state.userStore.currentLegalEntity) {
        return this.$store.state.userStore.currentLegalEntity.isLockedBecauseOfEconomy;
      }
      return false;
    }
  },
  methods: {
    async signAmount(): Promise<any> {
      if ((await (this.$refs.form as any).validate()).valid) {
        this.depositState = DepositState.SIGNING;
        try {
          await this.$store.dispatch(PortfolioAction.prepareDeposit, this.depositAmount);
        } catch (e) {
          this.signFailed();
          throw e;
        }
        await this.$store.dispatch(BankIdAction.sign);
      }
    },
    getEmptyAccounts(): Array<FormattedAccount> {
      this.error = true;
      return [
        { title: this.$t("from"), accountName: "-" },
        { title: this.$t("to"), accountName: "-" }
      ];
    },
    mapAccounts(userAccounts: PortfolioBase): Array<FormattedAccount> {
      this.error = false;
      const formattedBankAccount = this.formatUserAccount(this.portfolioSettings);
      return [
        formattedBankAccount,
        {
          title: this.$t("to"),
          accountName: userAccounts.name
        }
      ];
    },
    formatUserAccount(userPortfolioSettings: PortfolioSettings): FormattedAccount {
      const { accountName, accountNumber, clearingNumber } = userPortfolioSettings;
      return {
        title: this.$t("from"),
        accountName: accountName !== undefined ? `${accountName}` : "",
        accountNumber: `${clearingNumber}, ${accountNumber}`
      };
    },
    removeWhiteSpace(value: string): string {
      return value.replace(/\s/g, "");
    },
    resetAndClose(): void {
      this.$store.dispatch(BankIdAction.cancelSign);
      this.depositState = DepositState.CHOOSING_AMOUNT;
      this.displayDepositAmount = "";
      this.showDepositDialog = false;
      this.$emit("close");
    },
    async signComplete(): Promise<void> {
      this.depositState = DepositState.SIGN_COMPLETE;
      try {
        const result: CreateDepositResponse = await this.$store.dispatch(PortfolioAction.deposit, {
          depositAmount: this.depositAmount
        });
        if (!result) {
          this.$store.dispatch(
            UserAction.addSnackbarMessage,
            this.$t("portfolio.overview.deposit.autogiro-deposit.depositError")
          );
        } else if (result.deposit) {
          this.depositState = DepositState.DEPOSIT_COMPLETE;
          trackEvent("deposit", { deposit_amount: result.deposit.amount });
        } else if (result.error) {
          if (result.error.type === ErrorType.MissingTransferReceiver) {
            this.$store.dispatch(UserAction.addSnackbarMessageWithDuration, {
              text: this.$t("errors.missingTransferReceiver"),
              duration: 10000
            });
            this.depositState = DepositState.CHOOSING_AMOUNT;
          } else {
            // Unhandled error
            throw new Error();
          }
        }
      } catch {
        this.$store.dispatch(
          UserAction.addSnackbarMessage,
          this.$t("portfolio.overview.deposit.autogiro-deposit.depositError")
        );
        this.depositState = DepositState.CHOOSING_AMOUNT;
      }
      this.$store.dispatch(
        PortfolioAction.loadPortfolioEvents,
        (this.$store.state.portfolioStore as PortfolioState).activePortfolioBase?.id
      );
    },
    signCancel(): void {
      this.depositState = DepositState.CHOOSING_AMOUNT;
    },
    signFailed(): void {
      this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("signError"));
      this.depositState = DepositState.CHOOSING_AMOUNT;
    }
  }
});
</script>

<style lang="scss" scoped>
.autogiro {
  &__button-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-column-gap: 1rem;
    width: 100%;
    grid-template-columns: 8.125rem 8.125rem;
  }
  &__button {
    background: $white;
    color: $white;
    margin: 2rem auto;
    border-radius: 0.5rem;
    font-weight: 500;
    font-family: $heading-font-family;
    text-transform: none;
    font-size: 1.125rem;
    box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16);
    &--secondary {
      color: $nearly-black;
    }
  }
  &__header-label {
    font-size: 1.125rem;
  }
  &__done-wrapper {
    display: grid;
    margin: 0 auto;
    max-width: 16rem;
    grid-template-columns: auto;
    text-align: center;
    grid-row-gap: 2.25rem;
  }
  &__done-body-of-copy {
    font-weight: 300;
  }
  &__done-icon {
    font-size: 3rem;
    align-self: center;
    justify-self: center;
    color: $aqua;
  }
  &__text-field {
    font-size: 1.25rem;
    font-weight: bold;
  }
  &__subheader {
    font-size: 1.125rem;
    text-align: center;
    font-family: $heading-font-family;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  :deep(.v-input .v-label) {
    font-weight: 300;
  }
  :deep(.v-text-field__suffix) {
    font-weight: 300;
    font-size: 1rem !important;
  }
  &__spinner-wrapper {
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  :deep(.v-input .v-label) {
    font-weight: 300;
  }
}
.v-move,
.v-enter-active,
.v-leave-active {
  transition: 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
