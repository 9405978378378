<template>
  <div class="representative">
    <card-header
      class="representative__header"
      :title="`${$t('company-profile.representative.representative')} ${index + 1}`"
      :icon="['fal', 'user']"
    >
      <!-- <edit-button v-if="!errorLoadingUser" :edit="edit" /> -->
    </card-header>

    <div
      v-if="companyRepresentative.actionWaitingForSignature === ActionWaitingForSignature.Add"
      class="representative__header--text-red"
    >
      {{ $t("company-profile.representative.waitingForSignature") }}
    </div>
    <div
      v-else
      style="margin-bottom: 1rem; font-weight: 300"
    >
      {{ $t("company-profile.representative.access") }}
    </div>
    <v-form
      ref="form"
      class="representative__form"
    >
      <v-text-field
        v-model="name"
        :class="['representative__input', { 'representative__input--disabled': !edit }]"
        disabled
        validate-on="blur"
        variant="underlined"
        :label="$t('name')"
      />
      <v-text-field
        v-model="personalIdentityNumber"
        :class="['representative__input', { 'representative__input--disabled': !edit }]"
        disabled
        validate-on="blur"
        variant="underlined"
        :label="$t('personalNumber')"
      />
      <v-text-field
        v-model="phone"
        :class="['representative__input', { 'representative__input--disabled': !edit }]"
        disabled
        validate-on="blur"
        variant="underlined"
        :label="$t('phoneNumber')"
      />
      <v-text-field
        v-model="email"
        :class="['representative__input', { 'representative__input--disabled': !edit }]"
        disabled
        validate-on="blur"
        variant="underlined"
        :label="$t('email')"
      />
      <v-text-field
        v-model="role"
        :class="['representative__input', { 'representative__input--disabled': !edit }]"
        disabled
        validate-on="blur"
        variant="underlined"
        :label="$t('company-profile.representative.role')"
      />
    </v-form>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import EditButton from "@/components/edit-button.vue";
import { ActionWaitingForSignature, CompanyRepresentativeInfo, LegalEntity } from "@/clients";
import CardHeader from "@/components/card-header.vue";

export default defineComponent({
  components: {
    CardHeader
    // EditButton
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    companyRepresentative: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    edit: false,
    ActionWaitingForSignature
  }),
  computed: {
    ...mapGetters(["errorLoadingUser"]),
    currentLegalEntity(): LegalEntity {
      return this.$store.state.userStore.currentLegalEntity;
    },
    name(): string | undefined {
      if (this.companyRepresentative) {
        return `${this.companyRepresentative.firstName} ${this.companyRepresentative.lastName}`;
      }
      return "-";
    },
    personalIdentityNumber(): string | undefined {
      if (this.companyRepresentative) {
        return this.addDashToPersonalIdentityNumber(
          this.companyRepresentative.personalIdentityNumber as string
        );
      }
      return "-";
    },
    phone(): string {
      if (this.companyRepresentative) {
        return this.companyRepresentative.phoneNumber as string;
      }
      return "-";
    },
    email(): string {
      if (this.companyRepresentative) {
        return this.companyRepresentative.email as string;
      }
      return "-";
    },
    role(): string {
      if (this.companyRepresentative) {
        return this.companyRepresentative.role as string;
      }
      return "-";
    }
  },
  methods: {
    addDashToPersonalIdentityNumber(personalIdentityNumber: string) {
      if (personalIdentityNumber.length > 0) {
        return `${personalIdentityNumber.substring(0, 8)}-${personalIdentityNumber.substring(8)}`;
      }
      return undefined;
    }
  }
});
</script>
<style lang="scss" scoped>
.representative {
  padding: 1.5rem 1.5rem 0.825rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
    &--text-red {
      margin-bottom: 1rem;
      font-weight: 300;
      color: red;
    }
  }
  &__form {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
  }
  &__input {
    color: black;
    @include small-down {
      width: 100%;
    }
    :deep(label) {
      color: #707070 !important;
      font-size: 1.2rem;
      font-weight: 300;
    }
    :deep(.v-text-field) {
      margin-top: 0;
    }
    &--bottom {
      grid-column: 1 / 3;
      grid-row: 3;
    }
    &--disabled {
      :deep(.v-text-field__suffix) {
        color: inherit !important;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-label) {
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
  }
}
</style>
