<template>
  <div class="close-portfolio-flow">
    <template v-if="state === State.CONFIRM">
      <div class="close-portfolio-flow__title">
        {{ $t("component.closePortfolioFlow.confirmClose") }}
      </div>
      <div class="close-portfolio-flow__confirm-close">
        <div class="close-portfolio-flow__want">
          {{ $t("iWant") }}
        </div>
        <ul class="close-portfolio-flow__confirm-list">
          <li>
            {{ $t("component.closePortfolioFlow.point1") }}
          </li>
          <li>
            {{ $t("component.closePortfolioFlow.point2") }}
          </li>
          <li>
            {{ $t("component.closePortfolioFlow.point3") }}
          </li>
          <li>
            {{ $t("component.closePortfolioFlow.point4") }}
          </li>
        </ul>
      </div>

      <div class="close-portfolio-flow__button-wrapper">
        <CommonButton
          size-large
          @click="() => signClosePortfolio()"
        >
          {{ $t("signBankId") }}
        </CommonButton>
      </div>
    </template>
    <template v-else-if="state === State.SIGN">
      <BankId
        style="padding-top: 2rem;"
        @complete="signComplete"
        @canceled="signCancel"
        @failed="signFailed"
      />
    </template>
    <div
      v-else-if="state === State.COMPLETION_INFO"
      class="close-portfolio-flow__done-wrapper"
    >
      <span class="close-portfolio-flow__title">{{
        $t("component.closePortfolioFlow.doneTitle")
      }}</span>
      <span class="close-portfolio-flow__done-body-of-copy">{{ withdrawalCompleteText() }}</span>
      <span
        v-if="createdWithdrawalStatus !== undefined"
        class="close-portfolio-flow__done-body-of-copy"
      >{{ withdrawalCompleteSecondText() }}</span>

      <div
        v-if="createdWithdrawalStatus !== undefined"
        class="close-portfolio-flow__detail-row"
      >
        <template v-if="createdWithdrawalCompletionDate">
          <span>
            {{ $t("earliestInAccount") }}</span>
          <span
            v-if="createdWithdrawalCompletionDate"
            data-jest="transactionAmount"
          >
            {{
              DateTime.fromISO(createdWithdrawalCompletionDate.toISOString()).toFormat(
                "yyyy-MM-dd",
              )
            }}
          </span>
        </template>
        <template v-else>
          <span>
            {{ $t("component.closePortfolioFlow.doneBodyEarliestDefault") }}
          </span>
        </template>
      </div>
      <div class="close-portfolio-flow__button-wrapper">
        <CommonButton
          size-large
          @click="() => state = State.QUESTION"
        >
          {{ $t("ok") }}
        </CommonButton>
      </div>
    </div>
    <div
      v-else-if="state === State.QUESTION"
      class="close-portfolio-flow__question-wrapper"
    >
      <span style="font-size: 1rem;padding-top:1rem;">{{ t("component.closePortfolioFlow.doneQuestionHeader") }}</span>
      <span
        style="font-size: 1.25rem;font-weight: 500;"
      >{{
        $t("component.closePortfolioFlow.doneQuestionTitle")
      }}</span>
      <span class="close-portfolio-flow__done-question-text">{{ t("component.closePortfolioFlow.doneQuestionChoose") }}</span>
      <v-checkbox
        v-for="(k, i) in questionnaireAlternatives"
        :key="i"
        v-model="k.value"
        density="default"
        class="close-portfolio-flow__checkbox"
        :label="k.label"
        @submit.prevent="() => sendQuestionnaire"
      />

      <div class="close-portfolio-flow__button-wrapper">
        <CommonButton
          size-large
          @click="sendQuestionnaire"
        >
          {{ $t("component.closePortfolioFlow.send") }}
        </CommonButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { reactive, ref } from "vue";
import { DateTime, Duration } from "luxon";
import type { IClosePortfolioQuestionnaire, Withdrawal } from "@/clients";
import { WithdrawalStatus } from "@/clients";
import { trackEvent } from "@/clients/segment";
import BankId from "@/components/bank-id.vue";
import { PortfolioAction } from "@/store/portfolio/actions";
import type { PortfolioState } from "@/store/portfolio/types";
import { UserAction } from "@/store/user/action-definitions";
import CommonButton from "@/components/button.vue";
import { BankIdAction } from "@/store/bankid/actions";

const emit = defineEmits(["close", "abort"]);
enum State {
  CONFIRM,
  SIGN,
  COMPLETION_INFO,
  QUESTION,
}
const store = useStore();
const state = ref(State.CONFIRM);
const { t } = useI18n();

const createdWithdrawalStatus = ref<WithdrawalStatus>();
const createdWithdrawalCompletionDate = ref<Date>();

const questionnaireAlternatives = reactive({
  unhappyPerformance: {
    value: false,
    label: t("portfolio.overview.delete-portfolio.user-delete-portfolio.question1"),
  },
  missingFeature: {
    value: false,
    label: t("portfolio.overview.delete-portfolio.user-delete-portfolio.question2"),
  },
  feeToHigh: {
    value: false,
    label: t("portfolio.overview.delete-portfolio.user-delete-portfolio.question3"),
  },
  needTheMoney: {
    value: false,
    label: t("portfolio.overview.delete-portfolio.user-delete-portfolio.question4"),
  },
  technicalDifficulties: {
    value: false,
    label: t("portfolio.overview.delete-portfolio.user-delete-portfolio.question5"),
  },
  other: {
    value: false,
    label: t("portfolio.overview.delete-portfolio.user-delete-portfolio.question6"),
  },
});

async function sendQuestionnaire() {
  const questionnaire: IClosePortfolioQuestionnaire = {
    unhappyPerformance: questionnaireAlternatives.unhappyPerformance.value,
    missingFeature: questionnaireAlternatives.missingFeature.value,
    feeToHigh: questionnaireAlternatives.feeToHigh.value,
    needTheMoney: questionnaireAlternatives.needTheMoney.value,
    technicalDifficulties: questionnaireAlternatives.technicalDifficulties.value,
    other: questionnaireAlternatives.other.value,
  };
  await store.dispatch(PortfolioAction.saveClosePortfolioQuestionnaire, { portfolioId: store.state.portfolioStore.activePortfolioBase.id, questionnaire });
  emit("close");
}

async function signClosePortfolio() {
  state.value = State.SIGN;
  await store.dispatch(
    PortfolioAction.prepareStartClosingPortfolio,
  );
  await store.dispatch(BankIdAction.sign);
}

function withdrawalCompleteText(): string {
  if (createdWithdrawalStatus.value === undefined) {
    return t("component.closePortfolioFlow.doneEmailText");
  }
  if (createdWithdrawalStatus.value === WithdrawalStatus.WithdrawalOrderSentToBricknode) {
    return t("portfolio.overview.withdraw.side-dialog.withdrawCompleteDirectBody");
  }
  return t("portfolio.overview.withdraw.side-dialog.withdrawalCompleteDelayedText");
}
function withdrawalCompleteSecondText(): string {
  if (createdWithdrawalStatus.value === WithdrawalStatus.WithdrawalOrderSentToBricknode) {
    return t("component.closePortfolioFlow.doneBodyAfterCompletion");
  }
  return t("component.closePortfolioFlow.doneBodyRemember");
}
async function signComplete() {
  try {
    const withdrawal = await store.dispatch(PortfolioAction.startClosingPortfolio);
    state.value = State.COMPLETION_INFO;
    if (withdrawal) {
      createdWithdrawalCompletionDate.value = withdrawal.expectedCompletionDate;
      createdWithdrawalStatus.value = withdrawal.status;
      trackEvent("withdrawal", { withdrawal_amount: withdrawal.amount });
    }
  } catch (e) {
    store.dispatch(
      UserAction.addSnackbarMessage,
      t("component.closePortfolioFlow.withdrawError"),
    );
  }
  store.dispatch(
    PortfolioAction.loadPortfolioEvents,
    (store.state.portfolioStore as PortfolioState).activePortfolioBase?.id,
  );
  store.dispatch(PortfolioAction.getOngoingWithdrawals);
}
function signCancel() {
  emit("abort");
}
function signFailed() {
  store.dispatch(UserAction.addSnackbarMessage, t("signError"));
}
</script>

<style lang="scss" scoped>
.close-portfolio-flow {
  display: flex;
  flex-direction: column;
  text-align: center;

  margin: 0 auto;
  max-width: 18rem;

  &__done-wrapper {
    font-family: $heading-font-family;
    display: grid;
    margin: 0 auto;
    max-width: 18rem;
    grid-template-columns: auto;
    text-align: center;
    grid-row-gap: 2.25rem;
  }

  &__want {
    padding-top: 1.5rem;
    font-weight: 400;
  }

  &__done-body-of-copy {
    font-weight: 300;
  }

  &__confirm-close {
    font-family: $heading-font-family;
    text-align: left;
    font-weight: 300;
  }

  &__confirm-list {
    padding-left: 1rem;
  }

  &__button-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-row-gap: 1rem;
    width: 100%;
  }

  &__title {
    padding-top: 2rem;
    font-family: $heading-font-family;
    font-size: 1.25rem;
    font-weight: 500;
  }
  &__body {
    &--bold {
      font-weight: 600;
    }
  }
  &__detail-row {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 0.875rem;
  }
  &__checkbox {
    font-weight: 400;
    height: 2rem;
    margin: 0 0 0 0.5rem !important;
  }
  &__question-wrapper {
    font-family: $heading-font-family;
    font-weight: 300;
    height: 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }
}
:deep(.v-label) {
  padding-left: 1rem;
}
</style>
