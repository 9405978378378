<template>
  <div class="logo__container">
    <div
      v-if="tiedAgent"
      class="logo__tied-agent-wrapper"
    >
      <div>
        <span class="logo__tied-agent-sigmastocks">Sigmastocks</span>
        {{ $t("component.main-logo.cooperation") }}
      </div>
      <img :src="tiedAgentLogoUrl" />
    </div>
    <img
      v-else
      class="logo_default-logo"
      :src="mainLogoUrl"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    tiedAgent: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      mainLogoUrl: new URL("/assets/images/logos/sigmastocks-logo-text.svg", import.meta.url).href,
      tiedAgentLogoUrl: this.tiedAgent
        ? new URL(`/assets/images/logos/tiedagents/${this.tiedAgent.key}.svg`, import.meta.url).href
        : undefined
    };
  }
});
</script>
<style lang="scss" scoped>
.logo {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 2.5625rem;
  align-items: center;
  text-align: center;
  height: 100%;
  @include small-up {
    padding-top: 5rem;
  }
  &__tied-agent-sigmastocks {
    font-weight: 600;
  }
  &__container {
    width: 100%;
    text-align: center;
    height: 4.0625rem;
  }
  &__logo {
    path {
      fill: $soft-blue;
      opacity: 1;
    }
  }
}
</style>
