<template>
  <tax-liable-denial @next="next" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TaxLiableDenial from "@/views/common-signup/questions/aml/tax-liable-denial.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { WithdrawalFrequency } from "@/clients";

export default defineComponent({
  components: { TaxLiableDenial },
  methods: {
    next(key: WithdrawalFrequency) {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
