<template>
  <single-choice
    :alternatives="navigationAlternatives"
    :error="showErrorMessage"
    :error-message="$t('errorAtLeastOneAlternative')"
    @click="goToNext"
  >
    <question-text-section
      :heading="$t('create-portfolio.questions.aml.investment-background.heading')"
      progress
    />
    <multiple-choice-section
      :alternatives="alternatives"
      :error="showErrorMessage === true"
      @select="setInvestmentBackground"
      @freeText="freeTextChange"
    />
  </single-choice>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import {
  MultipleChoiceAlternative,
  NavigationAlternative,
  BackgroundAlternative
} from "@/types/signup";
import Question from "@/views/signup/templates/question";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice
  },
  extends: Question,
  data() {
    return {
      showErrorMessage: false
    };
  },
  computed: {
    investmentBackground() {
      const { investmentBackground } = this.$store.state.signupStore;
      const passed = Object.keys(investmentBackground).some((key) => investmentBackground[key]);
      if (passed) {
        if (
          investmentBackground.incomeFromOther &&
          !this.$store.state.signupStore.incomeFromOtherText
        ) {
          return false;
        }
      }
      return passed;
    },
    navigationAlternatives(): Array<NavigationAlternative> {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    },
    alternatives(): MultipleChoiceAlternative[] {
      return [
        {
          label: this.$t("create-portfolio.questions.aml.investment-background.investmentIncome"),
          key: "incomeFromInvestment",
          checked: this.toggleInvestmentBackground("incomeFromInvestment")
        },
        {
          label: this.$t(
            "create-portfolio.questions.aml.investment-background.salaryPensionAllowance"
          ),
          key: "incomeFromSalaryPensionAllowance",
          checked: this.toggleInvestmentBackground("incomeFromSalaryPensionAllowance")
        },
        {
          label: this.$t("create-portfolio.questions.aml.investment-background.gamblingProfit"),
          key: "incomeFromGambling",
          checked: this.toggleInvestmentBackground("incomeFromGambling")
        },
        {
          label: this.$t("create-portfolio.questions.aml.investment-background.residenceSale"),
          key: "incomeFromResidence",
          checked: this.toggleInvestmentBackground("incomeFromResidence")
        },
        {
          label: this.$t("create-portfolio.questions.aml.investment-background.companySale"),
          key: "incomeFromCompanySale",
          checked: this.toggleInvestmentBackground("incomeFromCompanySale")
        },
        {
          label: this.$t("create-portfolio.questions.aml.investment-background.giftOrHeritage"),
          key: "incomeFromGiftOrHeritage",
          checked: this.toggleInvestmentBackground("incomeFromGiftOrHeritage")
        },
        {
          label: this.$t("Other"),
          key: "incomeFromOther",
          checked: this.toggleInvestmentBackground("incomeFromOther"),
          asFreeText: true,
          freeTextValue: this.$store.state.signupStore.incomeFromOtherText
        }
      ];
    }
  },
  methods: {
    goToNext(): void {
      if (!this.investmentBackground) {
        this.showErrorMessage = true;
      } else {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    },
    setInvestmentBackground(key: BackgroundAlternative, value: boolean): void {
      this.showErrorMessage = false;
      this.$store.commit(SignupMutation.setInvestmentBackground, {
        key,
        value
      });
    },
    toggleInvestmentBackground(backgroundAlternative: BackgroundAlternative): boolean {
      return this.$store.state.signupStore.investmentBackground[backgroundAlternative];
    },
    freeTextChange({ key, value }: { key: string; value: string }) {
      switch (key) {
        case "incomeFromOther":
          this.$store.commit(SignupMutation.setIncomeFromOtherText, value);
          break;
        default:
          break;
      }
    }
  }
});
</script>
