<template>
  <div style="display: flex; flex-direction: column; row-gap: 0.5rem">
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div style="display: flex; flex-direction: row; align-items: center">
        <img
          v-if="isPension"
          style="color: $nearly-black; font-size: 1.125rem; margin-right: 0.75rem"
          :src="loadImageByName('pension-pyramid-black.svg')"
        />
        <img
          v-else
          style="color: $nearly-black; font-size: 1.125rem; margin-right: 0.75rem"
          :src="loadImageByName('briefcase_filled.svg')"
        />
        <icon
          v-if="portfolio?.isClosing"
          class="portfolios-list__icon"
          :icon="['fa', 'trash-clock']"
        />
        <h2 style="font-size: 1.125rem; font-weight: 600">{{ portfolioName }}</h2>
      </div>
      <icon
        style="color: #c1c0c2"
        :icon="['fas', 'chevron-right']"
      />
    </div>
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div style="font-size: 1.5rem; font-weight: 500">
        {{ totalValueString }} <span style="font-size: 1.125rem">kr</span>
      </div>
      <percentage-development
        :performance="performance"
        :portfolio-id="portfolioId"
      />
    </div>
    <loading-spinner-overlay :model-value="performanceLoading" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PerformanceResponse, PortfolioBase, PortfolioType } from "@/clients";
import { NavigationState } from "@/store/navigation/types";
import { OverviewState } from "@/store/overview/types";
import { formatTotalPortfolioValue, formatTotalDevelopmentPercentage } from "@/utils/development";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import PercentageDevelopment from "@/components/percentage-development.vue";

export default defineComponent({
  components: {
    LoadingSpinnerOverlay,
    PercentageDevelopment
  },
  props: {
    portfolioId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showEmptyState: true
    };
  },
  computed: {
    isPension(): boolean | undefined {
      if (this.portfolio) {
        return this.portfolio.portfolioType === PortfolioType.OccupationalPension;
      }
      return undefined;
    },
    performanceLoading(): boolean | undefined {
      const loading = (this.$store.state.overviewStore as OverviewState).performanceLoading[
        this.portfolioId
      ];
      return loading;
    },
    portfolio(): PortfolioBase | undefined {
      return (this.$store.state.navigationStore as NavigationState).portfolios?.find(
        (x) => x.id === this.portfolioId
      );
    },
    performance(): PerformanceResponse | undefined {
      if (this.portfolio) {
        return (this.$store.state.overviewStore as OverviewState).portfolioPerformances[
          this.portfolio.id
        ];
      }
      return undefined;
    },
    totalValueString(): string {
      if (this.$store.state.portfolioStore.isLoadingHoldingSummary || !this.performance) {
        return "-";
      }
      return formatTotalPortfolioValue(this.performance);
    },
    portfolioName(): string {
      if (this.portfolio) {
        return this.portfolio.name;
      }
      return "-";
    }
  },
  methods: {
    loadImageByName(name: string) {
      return new URL(`/assets/icons/${name}`, import.meta.url).href;
    }
  }
});
</script>

<style lang="scss" scoped>
.portfolios-list {
  &__empty-state {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    @include medium-up {
      padding-top: 5rem;
    }
  }
  &__empty-state-heading {
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  &__empty-state-icon {
    font-size: 1.5rem;
  }
  &__empty-state-description {
    font-weight: 300;
    width: 80%;
  }
  &__icon {
    color: $alert-red;
    margin-right: 0.5rem;
  }
}
</style>
