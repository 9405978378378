<template>
  <div class="portfolios-list__empty-state">
    <icon
      :icon="['fal', 'info-circle']"
      class="portfolios-list__empty-state-icon"
    />
    <h2 class="portfolios-list__empty-state-heading">
      {{ $t("yourPortfolios") }}
    </h2>
    <p class="portfolios-list__empty-state-description">
      {{ $t("overview.portfolio-list-empty-state.emptyStateBody") }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.portfolios-list {
  &__empty-state {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    @include medium-up {
      padding-top: 5rem;
    }
  }
  &__empty-state-heading {
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  &__empty-state-icon {
    font-size: 1.5rem;
  }
  &__empty-state-description {
    font-weight: 300;
    width: 80%;
  }
}
</style>
