import type { ChartRange } from "@/enums/overview";
import type {
  DataPoint,
  HoldingSummary,
  PerformanceResponse,
  Quarter,
} from "@/clients";

export type MarketShareKey = "Sweden" | "Us" | "Europe";
export interface MarketDistribution {
  market: MarketShareKey;
  share: number;
}

export interface DateTimeData {
  // Timestamp in milliseconds.
  x: number;
  y: number;
}

export interface DateTimeCollection {
  worldIndex: DateTimeData[];
  swedishIndex: DateTimeData[];
  mixedIndex: DateTimeData[];
  portfolioMixed: DateTimeData[];
  portfolioStocks: DateTimeData[];
  predictionMixedUpper: DateTimeData[];
  predictionMixedLower: DateTimeData[];
  predictionStocksUpper: DateTimeData[];
  predictionStocksLower: DateTimeData[];
}

export interface HoldingDetails {
  [id: string]: any;
  industry: string;
  description: string;
  name: string;
  country: string;
  bookKeepValue: Object | undefined;
  cashFlow: Object | undefined;
  stockMarketValue: Object | undefined;
  debt: Object | undefined;
  peNumber: Object | undefined;
  momentum: Object | undefined;
  profitMargins: Object | undefined;
  profitGrowth: Object | undefined;
  directRevenue: Object | undefined;
  approved: boolean;
}

export type DevelopmentPercentageMap = Map<ChartRange, number | undefined>;

export interface HoldingSummaries { [key: number]: HoldingSummary }

export interface DiscardedSectors {
  defenseDiscarded: boolean;
  alcoholTobaccoDiscarded: boolean;

  gamblingDiscarded: boolean;
  fossilFuelsDiscarded: boolean;
}

export interface EsgFilters {
  esgGovernanceFilter: boolean;
  esgEmissionsFilter: boolean;
  esgResourcesFilter: boolean;
  esgBiodiversityFilter: boolean;
  esgHumanRightsFilter: boolean;
}

export type EsgPais = { [key: string]: boolean } | undefined;

export interface UpdateAgreementsData {
  payload: any;
  action: string;
}

export interface Savings {
  monthlySaving: number;
  accountNumber: string;
  clearingNumber: string;
  bankName: string;
}

export type YearQuarterMap = Map<number, Quarter[]>;
export type RangeToChartDataMap = Map<ChartRange, PerformanceResponse>;

export interface RangeSelectionItem {
  label: string;
  key: ChartRange;
  value: string;
}

export enum DepositState {
  CHOOSING_AMOUNT,
  SIGNING,
  SIGN_COMPLETE,
  DEPOSIT_COMPLETE,
}

export interface TinkAccount {
  bank: string | undefined;
  accountName: string | undefined;
  accountNumber: string | undefined;
  clearingNumber: string | undefined;
  personalIdentityNumber: string | undefined;
  fullAccountNumber: string | undefined;
}

export interface Bank {
  bankName: string;
  clearingNumber: string;
  accountNumber: string;
  valid: boolean;
}
