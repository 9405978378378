<template>
  <div class="citizenship">
    <single-choice-template
      key="1"
      :alternatives="citizenshipAlternatives"
      @click="goToNext"
    >
      <question-text-section
        :heading="$t('whereCitizen')"
        :body="$t('create-portfolio.questions.aml.citizenship.citizenship_subHeading')"
        progress
      />
      <div class="citizenship__citizenship-wrapper">
        <div class="citizenship__selector-wrapper">
          <country-selector
            v-model="citizenship1"
            preselect-sweden
            class="citizenship__selector"
          />
        </div>
        <div class="citizenship__selector-wrapper">
          <div
            v-if="showSecondText"
            class="citizenship__add-new"
            @click="() => (showSecondSelection = true)"
          >
            + {{ $t("add_citizenship") }}
          </div>
          <country-selector
            v-else
            v-model="citizenship2"
            class="citizenship__selector"
          />
        </div>
        <div class="citizenship__selector-wrapper">
          <div
            v-if="showThirdText"
            class="citizenship__add-new"
            @click="() => (showThirdSelection = true)"
          >
            + {{ $t("add_citizenship") }}
          </div>
          <country-selector
            v-if="showThirdSelection"
            v-model="citizenship3"
            class="citizenship__selector"
          />
        </div>
      </div>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SingleChoice } from "@/types/signup";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import CountrySelector from "@/components/country-selector.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    InformationTextSection,
    QuestionTextSection,
    CountrySelector
  },
  extends: Question,
  data: () => ({
    showOtherCitizenship: false,
    showInformation: false,
    citizenship1: undefined,
    citizenship2: undefined,
    citizenship3: undefined,
    showSecondSelection: false,
    showThirdSelection: false
  }),
  computed: {
    showSecondText(): boolean {
      return this.citizenship1 !== undefined && !this.showSecondSelection;
    },
    showThirdText(): boolean {
      return (
        this.showSecondSelection === true &&
        this.citizenship2 !== undefined &&
        !this.showThirdSelection
      );
    },
    alternatives() {
      return [
        {
          text: this.$t("no"),
          key: "no"
        },
        {
          text: this.$t("yes"),
          key: "yes"
        }
      ];
    },
    citizenshipAlternatives() {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    },
    informationAlternatives(): SingleChoice[] {
      return [
        {
          text: this.$t("quit"),
          key: "quit"
        }
      ];
    },
    totalCitizenship() {
      const result: any[] = [];
      if (this.citizenship1 !== undefined) result.push(this.citizenship1);
      if (this.citizenship2 !== undefined) result.push(this.citizenship2);
      if (this.citizenship3 !== undefined) result.push(this.citizenship3);
      const unique = [...new Set(result)];
      return unique;
    }
  },
  methods: {
    goToNext(): void {
      this.$store.commit(SignupMutation.setCitizenship1, this.citizenship1);
      this.$store.commit(SignupMutation.setCitizenship2, this.citizenship2);
      this.$store.commit(SignupMutation.setCitizenship3, this.citizenship3);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.citizenship {
  height: 100%;
  width: 100%;
  &__citizenship-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 21.25rem;
    margin: 0 auto;
    padding: 0 2rem;
  }
  &__selector-wrapper {
    margin-top: 2rem;
  }
  &__add-new {
    cursor: pointer;
  }
}
</style>
