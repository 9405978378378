<script setup lang="ts">
import { ref, watch } from "vue";
import { softBlue } from "@/styles/colors";

const props = defineProps<{ items: number }>();

const emit = defineEmits(["done"]);

const carouselIndex = ref<number>(0);

function increment() {
  carouselIndex.value += 1;
}
function decrement() {
  carouselIndex.value -= 1;
}

watch(carouselIndex, () => {
  if (carouselIndex.value === props.items - 1) {
    emit("done");
  }
});
</script>

<template>
  <div class="info-carousel__content">
    <v-btn
      variant="text"
      icon
      :color="softBlue"
      aria-label="Föregående informationskort"
      class="info-carousel__stepper"
      :disabled="carouselIndex === 0"
      data-test="stepper-left"
      @click="decrement"
    >
      <icon :icon="['fal', 'chevron-left']" />
    </v-btn>
    <v-card style="width: 15rem; box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16) !important">
      <v-carousel
        v-model="carouselIndex"
        class="info-carousel__carousel"
        :show-arrows="false"
        :continuous="false"
        hide-delimiter-background
        height="16.875rem"
      >
        <slot />
      </v-carousel>
    </v-card>
    <v-btn
      variant="text"
      icon
      :color="softBlue"
      :disabled="carouselIndex === items - 1"
      class="info-carousel__stepper"
      aria-label="Nästa informationskort"
      @click="increment"
    >
      <icon :icon="['fal', 'chevron-right']" />
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.info-carousel {
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 21rem;
    margin: auto;
    margin-top: 1rem;
  }
  &__carousel {
    width: 15rem;
    :deep(.v-carousel__controls) {
      color: $soft-blue;
      bottom: -0.5rem;
      .v-btn {
        width: 0.75rem;
        height: 0.75rem;
        opacity: 0.5;
        &--active {
          opacity: 1;
        }
      }
      .v-icon {
        font-size: 0.5rem !important;
        width: 0.75rem !important;
        height: 0.75rem !important;
      }
    }
  }
  &__stepper {
    font-size: 1.5rem;
    outline: none;
    &--right {
      justify-self: flex-end;
    }
    &:before {
      background: transparent;
      transition: all 0.1s ease-in-out;
    }
    &:hover:before {
      @include cursorHover {
        background: black;
      }
    }
    &.focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
  }
}
</style>
