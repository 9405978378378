import VuexPersistence from "vuex-persist";
import signupStore from "./signup";
import companySignupStore from "./company-signup";
import signupNavigationStore from "./signup-navigation";
import { createStore as _createStore } from 'vuex';

import portfolioStore from "./portfolio";
import navigationStore from "./navigation";
import adminStore from "./admin";
import userStore from "./user";
import bankIdStore from "./bankid";
import pensionSignupStore from "./pension";
import overviewStore from "./overview";
import { RootState } from "../types";

const vuexSession = new VuexPersistence<RootState>({
  storage: window.sessionStorage,
  modules: ["signupStore", "companySignupStore", "signupNavigationStore", "pensionSignupStore"]
});

export default _createStore({ // TODO
  // Only enabled in dev. See https://vuex.vuejs.org/guide/strict.html
  // strict: import.meta.env.VITE_ENV === "development", // TODO Turn off

  modules: {
    signupStore,
    companySignupStore,
    signupNavigationStore,
    portfolioStore,
    navigationStore,
    userStore,
    adminStore,
    bankIdStore,
    pensionSignupStore,
    overviewStore
  },
  plugins: [vuexSession.plugin]

});
