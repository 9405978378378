import type { ActionTree, Commit } from "vuex";
import { AdminMutation } from "./mutations";
import type {
  AdminApplicationUser,
  AdminLegalEntity,
  AdminMifidAlert,
  AdminPortfolio,
  AdminWithdrawalRequest,
  ApplicationUser,
  BannerType,
  ExtRef,
  LegalEntity,
  MifidAlertJobLogEntity,
  PortfolioAgreement,
  Withdrawal,
} from "@/clients";
import {
  AdminClient,
  AdminUpdatePortfolioAgreements,
  AdminUpdatePortfolioSettings,
  BannerClient,
  CreateBanner,
  CreateTradingDay,
  UpdatePortfolioAgreement,
} from "@/clients";
import type { RootState } from "@/types";
import type { AdminState } from "@/store/admin/types";
import { baseUrl } from "@/clients/config";

const adminClient = new AdminClient(baseUrl);
const bannerClient = new BannerClient(baseUrl);

export const AdminAction = {
  loadLegalEntities: "loadLegalEntities",
  loadCompanies: "loadCompanies",
  loadUser: "loadUser",
  loadUserAndDependencies: "loadUserAndDependencies",
  loadLegalEntityAndDependencies: "loadLegalEntityAndDependencies",
  loadPortfolios: "loadPortfolios",
  loadPortfolioDependencies: "loadPortfolioDependencies",
  loadPortfolioTransactions: "loadPortfolioTransactions",
  adminUpdatePortfolioSettings: "adminUpdatePortfolioSettings",
  createBanner: "createBanner",
  deleteBanner: "deleteBanner",
  getTradingDays: "getTradingDays",
  addTradingDay: "addTradingDay",
  removeTradingDay: "removeTradingDay",
  updatePortfolioAgreements: "updatePortfolioAgreements",
  getUserMessages: "getUserMessages",
  addUserMessage: "addUserMessage",
  adminToggleDeletePortfolio: "adminToggleDeletePortfolio",
  softDeleteLegalEntityAndUser: "softDeleteLegalEntityAndUser",
  loadWithdrawals: "loadWithdrawals",
  getAffiliateCampaigns: "getAffiliateCampaigns",
  createAffiliateCampaign: "createAffiliateCampaign",
  removeAffiliateCampaign: "removeAffiliateCampaign",
  updateAffiliateCampaign: "updateAffiliateCampaign",
  loadMifidAlerts: "loadMifidAlerts",
  loadMifidJobLogToday: "loadMifidJobLogToday",
  sendAlerts: "sendAlerts",
  setDeleted: "setDeleted",
  loadMifidAccountHoldingsValue: "loadMifidAccountHoldingsValue",
  createWithdrawal: "createWithdrawal",
};

async function loadUser(
  commit: Commit,
  userId: number,
): Promise<AdminApplicationUser | null | undefined> {
  return adminClient
    .getUser(userId)
    .then((user) => {
      commit(AdminMutation.setActiveUser, user);
      return user;
    })
    .catch(error => undefined);
}

async function loadLegalEntity(
  commit: Commit,
  legalEntityId: number,
): Promise<LegalEntity | null | undefined> {
  return adminClient
    .getLegalEntity(legalEntityId)
    .then((legalEntity) => {
      commit(AdminMutation.setActiveLegalEntity, legalEntity);
      return legalEntity;
    })
    .catch(error => undefined);
}

async function loadPortfolios(
  commit: Commit,
  legalEntityEntityId: number,
): Promise<ApplicationUser | null | undefined> {
  const portfoliosList = await adminClient.getPortfolios(legalEntityEntityId).catch((error) => {});

  if (!portfoliosList || portfoliosList.length === 0) {
    return;
  }
  commit(AdminMutation.setActivePortfolios, portfoliosList);
}

async function loadPortfolioSettings(commit: Commit, portfolioId: string): Promise<void> {
  const settings = await adminClient.getPortfolioSettings(portfolioId).catch((error) => {});
  commit(AdminMutation.setActivePortfolioSettings, { settings, portfolioId });
}

async function loadPortfolioPerformance(commit: Commit, portfolioId: string): Promise<void> {
  commit(AdminMutation.setLoadingPerformance, { loading: true, portfolioId });
  const performance = await adminClient
    .getPerformance(portfolioId, undefined, undefined, true, true)
    .catch((error) => {});
  commit(AdminMutation.setLoadingPerformance, { loading: false, portfolioId });
  commit(AdminMutation.setPerformance, { performance, portfolioId });
}
async function loadPortfolioPerformanceBricknode(
  commit: Commit,
  portfolioId: string,
): Promise<void> {
  commit(AdminMutation.setLoadingPerformanceBricknode, { loading: true, portfolioId });
  const performance = await adminClient
    .getPerformance(portfolioId, undefined, undefined, true, false)
    .catch((error) => {});
  commit(AdminMutation.setLoadingPerformanceBricknode, { loading: false, portfolioId });
  commit(AdminMutation.setPerformanceBricknode, { performance, portfolioId });
}
async function loadStrategy(commit: Commit, portfolioId: string): Promise<void> {
  const strategy = await adminClient.getStrategy(portfolioId).catch((error) => {});
  commit(AdminMutation.setActiveStrategy, { strategy, portfolioId });
}

async function loadPortfolioTransactions(commit: Commit, portfolioId: string): Promise<void> {
  const transactions = await adminClient.getPortfolioTransactions(portfolioId).catch((error) => {
    console.error(error);
  });
  commit(AdminMutation.setPortfolioTransactions, { transactions, portfolioId });
}

async function loadPortfolioSummary(commit: Commit, portfolioId: string): Promise<void> {
  const summary = await adminClient.getPortfolioSummary(portfolioId).catch((error) => {});
  commit(AdminMutation.setActivePortfolioSummaries, { portfolioId, summary });
}

async function loadPortfolioAgreements(commit: Commit, legalEntityEntityId: number): Promise<void> {
  const agreements = await adminClient
    .getPortfolioAgreements(legalEntityEntityId)
    .catch((error) => {});
  commit(AdminMutation.setActivePortfolioAgreements, agreements);
}

export const actions: ActionTree<AdminState, RootState> = {
  async [AdminAction.loadLegalEntities](
    { commit },
    { firstName, lastName, email, customerNumber, pin, includeDeleted },
  ): Promise<void> {
    commit(AdminMutation.loadingUsers, true);
    await adminClient
      .searchUsers(firstName, lastName, email, customerNumber, pin, includeDeleted)
      .then((users) => {
        commit(AdminMutation.loadingUsers, false);
        commit(AdminMutation.setLegalEntities, users);
      })
      .catch((error) => {
        commit(AdminMutation.loadingUsers, false);
      });
  },

  async [AdminAction.loadCompanies](
    { commit },
    { firstName, lastName, email, customerNumber, pin, includeDeleted },
  ): Promise<void> {
    commit(AdminMutation.loadingUsers, true);
    await adminClient
      .searchCompanies(firstName, lastName, email, customerNumber, pin, includeDeleted)
      .then((users) => {
        commit(AdminMutation.loadingUsers, false);
        commit(AdminMutation.setCompanies, users);
      })
      .catch((error) => {
        commit(AdminMutation.loadingUsers, false);
      });
  },

  async [AdminAction.loadUserAndDependencies]({ commit }, userId: number): Promise<void> {
    const user = await loadUser(commit, userId);
    if (!user)
      return;
    commit(AdminMutation.setActiveUser, user);
    // TODO: HACK until we decide how to handle multiple legal entities in admin
    const legalEntityEntityId = user.legalEntityUsers[0].legalEntity?.id;
    if (legalEntityEntityId !== undefined) {
      loadPortfolios(commit, legalEntityEntityId);
      loadPortfolioAgreements(commit, legalEntityEntityId);
    }
  },

  async [AdminAction.loadLegalEntityAndDependencies](
    { commit },
    legalEntityId: number,
  ): Promise<void> {
    commit(AdminMutation.setActiveUserId, legalEntityId.toString());
    const legalEntity = await loadLegalEntity(commit, legalEntityId);
    if (!legalEntity)
      return;
    commit(AdminMutation.setActiveLegalEntity, legalEntity);

    loadPortfolios(commit, legalEntity.id);
    loadPortfolioAgreements(commit, legalEntity.id);
  },

  async [AdminAction.loadPortfolioDependencies]({ commit }, portfolioId: string): Promise<void> {
    const promises = [
      loadPortfolioSettings(commit, portfolioId),
      loadStrategy(commit, portfolioId),
      loadPortfolioSummary(commit, portfolioId),
      loadPortfolioTransactions(commit, portfolioId),
      loadPortfolioPerformance(commit, portfolioId),
      loadPortfolioPerformanceBricknode(commit, portfolioId),
    ];
    Promise.all(promises);
  },
  async [AdminAction.loadPortfolioTransactions]({ commit }, portfolioId: string): Promise<void> {
    await loadPortfolioTransactions(commit, portfolioId);
  },
  async [AdminAction.adminUpdatePortfolioSettings](
    { commit },
    {
      portfolioId,
      portfolioSettingsId,
      canEditBankDetails,
    }: { portfolioId: string; portfolioSettingsId: number; canEditBankDetails: boolean },
  ): Promise<void> {
    const request = new AdminUpdatePortfolioSettings({
      id: portfolioSettingsId,
      canEditBankDetails,
    });
    await adminClient
      .updatePortfolioSettings(portfolioId, request)
      .then((users) => {
        commit(AdminMutation.loadingUsers, false);
        commit(AdminMutation.setUsers, users);
      })
      .catch((error) => {
        commit(AdminMutation.loadingUsers, false);
      });
  },
  async [AdminAction.createBanner](
    { commit },
    { text, bannerType, isins }: { text: string; bannerType: BannerType; isins: string },
  ): Promise<void> {
    const request = new CreateBanner({
      bannerType,
      text,
      isins,
    });
    await bannerClient.add(request);
  },
  async [AdminAction.deleteBanner]({ commit }, id): Promise<void> {
    await bannerClient.delete(id);
  },
  async [AdminAction.getTradingDays]({ commit }): Promise<void> {
    const tradingDays = await adminClient.getTradingDays();
    commit(AdminMutation.setTradingDays, tradingDays);
  },
  async [AdminAction.addTradingDay]({ commit }, dateString): Promise<void> {
    const date = new Date(dateString);
    date.setHours(12);

    const tradingDay = await adminClient.addTradingDay(new CreateTradingDay({ atDate: date }));
    commit(AdminMutation.addTradingDay, tradingDay);
  },
  async [AdminAction.removeTradingDay]({ commit }, id): Promise<void> {
    const removed = await adminClient.removeTradingDay(id);
    if (removed) {
      commit(AdminMutation.removeTradingDay, id);
    }
  },
  async [AdminAction.adminToggleDeletePortfolio](
    { commit },
    { portfolio }: { portfolio: AdminPortfolio },
  ): Promise<void> {
    try {
      const updatedPortfolio = await adminClient.deletePortfolio(
        portfolio.id,
        !portfolio.isDeleted,
      );
      if (updatedPortfolio) {
        commit(AdminMutation.updateActivePortfolio, updatedPortfolio);
      } else {
        throw new Error(`Failed to delete portfolio with ID: ${portfolio.id}`);
      }
    } catch {
      throw new Error(`Failed to delete portfolio with ID: ${portfolio.id}`);
    }
  },
  async [AdminAction.updatePortfolioAgreements](
    { commit },
    agreements: PortfolioAgreement[],
  ): Promise<void> {
    const request = new AdminUpdatePortfolioAgreements();
    request.agreements = agreements.map(
      a => new UpdatePortfolioAgreement({ id: a.id, error: a.error }),
    );
    await adminClient.updatePortfolioAgreements(0, request);
  },
  async [AdminAction.getUserMessages]({ commit }, legalEntityEntityId): Promise<void> {
    const messages = await adminClient.getUserMessages(legalEntityEntityId);
    commit(AdminMutation.setActiveMessages, messages);
  },
  async [AdminAction.addUserMessage]({ commit }, { legalEntityEntityId, message }): Promise<void> {
    await adminClient
      .addUserMessage(legalEntityEntityId, message)
      .then((response) => {
        if (response) {
          commit(AdminMutation.addMessage, response);
          return Promise.resolve();
        }

        return Promise.reject();
      })
      .catch(() => Promise.reject());
  },
  async [AdminAction.softDeleteLegalEntityAndUser](
    { getters, commit, dispatch },
    legalEntity: AdminLegalEntity,
  ): Promise<void> {
    try {
      await adminClient.softDeleteLegalEntityAndUser(legalEntity.id);
    } catch {
      throw new Error("Failed to delete user and legalEntity");
    }
  },
  async [AdminAction.loadWithdrawals](
    { commit },
    { fromDate, toDate, status, fullWithdrawal, bricknodeCustomerNumber },
  ): Promise<void> {
    commit(AdminMutation.loadingWithdrawals, true);
    const withdrawals = await adminClient.getWithdrawals(
      new Date(Date.parse(fromDate)),
      new Date(Date.parse(toDate)),
      status,
      fullWithdrawal,
      bricknodeCustomerNumber,
    );
    commit(AdminMutation.setWithdrawals, withdrawals);
    commit(AdminMutation.loadingWithdrawals, false);
  },
  async [AdminAction.getAffiliateCampaigns]({ commit }): Promise<void> {
    const affiliateCampaigns = await adminClient.getAffiliateCampaigns();
    commit(AdminMutation.setAffiliateCampaigns, affiliateCampaigns);
  },
  async [AdminAction.createAffiliateCampaign]({ commit }, request): Promise<void> {
    try {
      const affiliateCampaign = await adminClient.createAffiliateCampaign(request);
      if (affiliateCampaign) {
        commit(AdminMutation.appendAffiliateCampaign, affiliateCampaign);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (error) {
      console.error(error);
      return Promise.reject((error as any)?.response);
    }
  },
  async [AdminAction.updateAffiliateCampaign]({ commit }, request): Promise<void> {
    try {
      const affiliateCampaign = await adminClient.updateAffiliateCampaign(request);
      if (affiliateCampaign) {
        commit(AdminMutation.updateAffiliateCampaign, affiliateCampaign);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (error) {
      console.error(error);
      return Promise.reject((error as any)?.response);
    }
  },
  async [AdminAction.removeAffiliateCampaign]({ commit }, id): Promise<void> {
    const affiliateCampaign = await adminClient.removeAffiliateCampaign(id);
    if (affiliateCampaign) {
      commit(AdminMutation.removeAffiliateCampaign, id);
    }
  },
  async [AdminAction.loadMifidAlerts](
    { commit },
    { startDate, endDate }: { startDate: Date; endDate: Date },
  ): Promise<void> {
    const mifidAlerts = await adminClient.getMifidAlerts(startDate, endDate);
    commit(AdminMutation.setMifidAlerts, mifidAlerts);
  },
  async [AdminAction.loadMifidJobLogToday](): Promise<MifidAlertJobLogEntity | null> {
    return adminClient.getMifidJobLogToday();
  },
  async [AdminAction.sendAlerts](
    { commit },
    { ids }: { ids: number[] },
  ): Promise<AdminMifidAlert[]> {
    try {
      const alerts = await adminClient.sendMifidAlerts(ids);
      if (alerts) {
        commit(AdminMutation.updateMifidAlerts, alerts);
        return Promise.resolve(alerts);
      }
      return Promise.reject();
    } catch (error) {
      console.error(error);
      return Promise.reject((error as any)?.response);
    }
  },
  async [AdminAction.setDeleted]({ commit }, { ids }: { ids: number[] }): Promise<void> {
    try {
      const alerts = await adminClient.setMifidDeleted(ids);
      if (alerts) {
        commit(AdminMutation.updateMifidAlerts, alerts);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (error) {
      console.error(error);
      return Promise.reject((error as any)?.response);
    }
  },
  async [AdminAction.loadMifidAccountHoldingsValue]({ state, commit }): Promise<void> {
    const externalReferences = state.mifidAlerts
      .filter(x => !x.sentMessage)
      .map((x: AdminMifidAlert) => x.portfolioExternalReference);
    try {
      const values = await adminClient.getAccountHoldingsValue(externalReferences as ExtRef[]);
      if (values) {
        commit(AdminMutation.setHoldingsValueOnAlerts, values);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (error) {
      console.error(error);
      return Promise.reject((error as any)?.response);
    }
  },
  async [AdminAction.createWithdrawal](
    _,
    payload: {
      portfolioId: string;
      withdrawalRequest: AdminWithdrawalRequest;
    },
  ): Promise<Withdrawal> {
    try {
      const withdrawal = await adminClient.createWithdrawal(
        payload.portfolioId,
        payload.withdrawalRequest,
      );
      return Promise.resolve(withdrawal);
    } catch (error) {
      console.error(error);
      return Promise.reject((error as any)?.response);
    }
  },
};
