import { DataPoint } from "@/clients";
import { DateTime } from "luxon";

/**
 * Apex time series chart has horrible performance over 300 datapoints.
 * This function only keeps the first datapoint of the week (if between 400 and 2800)
 * or the first day of the month (if over 2800) making the time series either 1/7 or 1/30 of the size
 */
export default function optimizeTimeSeries(timeSeries: Array<DataPoint>): Array<DataPoint> {
  // If below "1 year" option in graph then display all datapoints. Reason for 260 is that the array excluded weekends
  if (timeSeries.length < 260) {
    return timeSeries;
  }

  let output: Array<DataPoint> = [];
  if (timeSeries.length > 2800) {
    // TODO: Should be improved and use for loop with a delta since filter takes alot of memory performance-wise.
    // Also shouldnt use .day === 1 since then we dont start at 0 unless we request it on the first of every month
    const perMonths = timeSeries.filter((v) => DateTime.fromJSDate(v.date).day === 1);
    output = perMonths;
  } else {
    const delta = 7;
    for (let i = 0; i < timeSeries.length; i += delta) {
      output.push(timeSeries[i]);
    }
    if (timeSeries.length % delta !== 0) {
      output.push(timeSeries[timeSeries.length - 1]);
    }
  }

  // Add the last datapoint to the time series
  const lastDay = timeSeries[timeSeries.length - 1];

  if (output[output.length - 1] !== lastDay) {
    output.push(lastDay);
  }

  return output;
}
