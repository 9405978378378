import type { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { CURRENT_COMPANY_SIGNUP_PATH_KEY } from "@/config/company-signup";
import store from "@/store";

const FortnoxIntro = () => import("../views/company-signup/questions/introduction/fortnox-intro.vue");
const CompanyAccountInfo = () => import("../views/company-signup/questions/introduction/company-account-info.vue");
const NecessaryInfo = () => import("../views/company-signup/questions/introduction/necessary-info.vue");
const TimeInfo = () => import("../views/company-signup/questions/introduction/time-info.vue");
const InsuranceInfo = () => import("../views/company-signup/questions/evida/introduction/insurance-info.vue");

const KycInfo = () => import("../views/company-signup/questions/evida/principals/kyc-info.vue");
const CompanyPrincipalInfo = () => import("../views/company-signup/questions/evida/principals/company-principal-info.vue");
const OtherPrincipalInfo = () => import("../views/company-signup/questions/evida/principals/other-principal-info.vue");

const CompanyPrincipalsSummary = () => import("../views/company-kyc/company-principals-summary.vue");
const CompanyPrincipalName = () => import("../views/company-signup/questions/evida/principals/company-principal-name.vue");
const CompanyPrincipalDetails = () => import("../views/company-kyc/company-principal-details.vue");
const CompanyPrincipalSwedishResident = () => import("../views/company-signup/questions/evida/principals/company-principal-swedish-resident.vue");

const CompanyPrincipalForeignAddress = () => import("../views/company-signup/questions/evida/principals/company-principal-foreign-address.vue");

const CompanyPrincipalShares = () => import("../views/company-signup/questions/evida/principals/company-principal-shares.vue");
const CompanyPrincipalCitizenship = () => import("../views/company-kyc/company-principal-citizenship.vue");
const OtherPrincipalCitizenship = () => import("../views/company-kyc/other-principal-citizenship.vue");
const CompanyPrincipalForbidden = () => import("../views/company-signup/questions/evida/principals/company-principal-forbidden.vue");
const CompanyPrincipalTaxable = () => import("../views/company-kyc/company-principal-taxable.vue");
const CompanyPrincipalTaxableWarning = () => import("../views/company-signup/questions/evida/principals/company-principal-taxable-warning.vue");
const CompanyPrincipalPep = () => import("../views/company-signup/questions/evida/principals/company-principal-pep.vue");
const CompanyPrincipalPepContact = () => import("../views/company-signup/questions/evida/principals/company-principal-contact.vue");
const CompanyPrincipalTaxableCountry = () => import("../views/company-signup/questions/evida/principals/company-principal-taxable-country.vue");
const CompanyPrincipalTaxableCountryWarning = () => import("../views/company-signup/questions/evida/principals/company-principal-taxable-country-warning.vue");

const Industry = () => import("../views/company-signup/questions/evida/aml/industry.vue");
const Revenue = () => import("../views/company-signup/questions/evida/aml/revenue.vue");
const Employees = () => import("../views/company-signup/questions/evida/aml/employees.vue");
const SavingsPurpose = () => import("../views/company-signup/questions/evida/aml/savings-purpose.vue");
const InvestmentBackground = () => import("../views/company-signup/questions/evida/aml/investment-background.vue");
const DepositInterval = () => import("../views/company-signup/questions/evida/aml/deposit-interval.vue");
const YearlyDeposit = () => import("../views/company-signup/questions/evida/aml/yearly-deposit.vue");
const YearlyDepositManual = () => import("../views/company-signup/questions/evida/aml/yearly-deposit-manual.vue");
const WithdrawalInterval = () => import("../views/company-kyc/withdrawal-interval.vue");
const WithdrawalIntervalWarning = () => import("../views/company-signup/questions/evida/aml/withdrawal-interval-warning.vue");
const Accountant = () => import("../views/company-signup/questions/evida/aml/accountant.vue");
const Done = () => import("../views/company-signup/questions/evida/done.vue");

const CompanyKyc = () => import("../views/company-kyc/company-kyc.vue");

const Welcome = () => import("../views/company-signup/questions/welcome.vue");
const EvidaWelcome = () => import("../views/company-signup/questions/evida/introduction/welcome.vue");

const Introduction = () => import("../views/company-signup/questions/introduction.vue");
const Inactive = () => import("../views/company-signup/questions/economy/inactive.vue");
const AverageOperatingProfit = () => import("../views/company-signup/questions/economy/average-operating-profit.vue");
const Solidity = () => import("../views/company-kyc/solidity.vue");
const LiquidAssets = () => import("../views/company-signup/questions/economy/liquid-assets.vue");
const Liquidity = () => import("../views/company-kyc/liquidity.vue");
const ForecastResult = () => import("../views/company-signup/questions/economy/forecast-result.vue");
const LongTermDebt = () => import("../views/company-signup/questions/economy/long-term-debt.vue");
const ShortTermDebt = () => import("../views/company-signup/questions/economy/short-term-debt.vue");

const TimeToWithdraw = () => import("../views/company-signup/questions/economy/time-to-withdraw.vue");
const AuthorizedSignatoryContact = () => import("../views/company-signup/questions/agreements/authorized-signatory-contact.vue");
const Summary = () => import("../views/company-signup/questions/agreements/summary.vue");
const Congratulations = () => import("../views/company-signup/questions/agreements/congratulations.vue");

const KycSign = () => import("../views/company-kyc/kyc-sign.vue");
const KycValidation = () => import("../views/company-kyc/kyc-validation.vue");

const Intro = () => import("../views/company-kyc/intro.vue");

export default [
  {
    path: "company-kyc",
    name: "company-kyc",
    component: CompanyKyc,

    // Always allow the user to go to return to a page they have previously visited.
    // If they try to go to any other question, put them on the last visited path.
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: (path?: RouteLocationRaw) => void) => {
      const { getters } = store as any;
      if (to.path.endsWith("/company-kyc")) {
        // This check is here to avoid infinite recursion.
        next();
      } else {
        const latestVisitedPath = sessionStorage.getItem(CURRENT_COMPANY_SIGNUP_PATH_KEY);

        if (latestVisitedPath) {
          const latestVisitedQuestion = getters.questionProgress(latestVisitedPath);
          const toQuestion = getters.questionProgress(to.path);
          const hasVisitedToQuestion
            = latestVisitedQuestion
            && toQuestion
            && (toQuestion.partNumber < latestVisitedQuestion.partNumber
            || (toQuestion.partNumber === latestVisitedQuestion.partNumber
            && toQuestion.questionNumber <= latestVisitedQuestion.questionNumber));

          if (hasVisitedToQuestion) {
            next();
          }
        } else {
          // The user has not yet started the signup flow. Put them on the signup start page.
          next({ params: to.params, name: "company-kyc", replace: true });
        }
      }
    },
    children: [
      {
        path: "introduction/intro",
        component: Intro,
      },
      {
        path: "introduction/company-account-info",
        component: CompanyAccountInfo,
      },
      {
        path: "introduction/fortnox-intro",
        component: FortnoxIntro,
      },
      {
        path: "introduction/necessary-info",
        component: NecessaryInfo,
      },
      {
        path: "introduction/time-info",
        component: TimeInfo,
      },
      {
        path: "evida/introduction/welcome",
        component: EvidaWelcome,
      },
      {
        path: "evida/introduction/insurance-info",
        component: InsuranceInfo,
      },
      {
        path: "evida/principals/kyc-info",
        component: KycInfo,
      },
      {
        path: "evida/principals/company-principal-info",
        component: CompanyPrincipalInfo,
      },

      {
        path: "evida/principals/company-principals-summary",
        component: CompanyPrincipalsSummary,
      },
      {
        path: "evida/principals/company-principal-shares/:index",
        component: CompanyPrincipalShares,
      },
      {
        path: "evida/principals/company-principal-foreign-address/:index",
        component: CompanyPrincipalForeignAddress,
      },
      {
        path: "evida/principals/company-principal-citizenship/:index",
        component: CompanyPrincipalCitizenship,
      },
      {
        path: "evida/principals/other-principal-citizenship",
        component: OtherPrincipalCitizenship,
      },
      {
        path: "evida/principals/company-principal-forbidden",
        component: CompanyPrincipalForbidden,
      },
      {
        path: "evida/principals/company-principal-taxable/:index",
        component: CompanyPrincipalTaxable,
      },
      {
        path: "evida/principals/taxable-usa-warning",
        component: CompanyPrincipalTaxableWarning,
      },
      {
        path: "evida/principals/company-principal-pep/:index",
        component: CompanyPrincipalPep,
      },
      {
        path: "evida/principals/company-principal-contact/:index",
        component: CompanyPrincipalPepContact,
      },
      {
        path: "evida/principals/company-principal-name/:index",
        component: CompanyPrincipalName,
      },
      {
        path: "evida/principals/company-principal-details/:index",
        component: CompanyPrincipalDetails,
      },
      {
        path: "evida/principals/company-principal-swedish-resident/:index",
        component: CompanyPrincipalSwedishResident,
      },
      {
        path: "evida/principals/company-principal-taxable-country/:index",
        component: CompanyPrincipalTaxableCountry,
      },
      {
        path: "evida/principals/company-principal-taxable-country-warning",
        component: CompanyPrincipalTaxableCountryWarning,
      },
      {
        path: "evida/principals/other-principal-info",
        component: OtherPrincipalInfo,
      },
      {
        path: "evida/principals/other-principal-details",
        component: CompanyPrincipalDetails,
      },
      {
        path: "evida/principals/other-principal-name",
        component: CompanyPrincipalName,
      },
      {
        path: "evida/principals/other-principal-swedish-resident",
        component: CompanyPrincipalSwedishResident,
      },
      {
        path: "evida/principals/other-principal-citizenship",
        component: CompanyPrincipalCitizenship,
      },
      {
        path: "evida/principals/other-principal-taxable",
        component: CompanyPrincipalTaxable,
      },
      {
        path: "evida/principals/other-principal-pep",
        component: CompanyPrincipalPep,
      },
      {
        path: "evida/principals/other-principal-contact",
        component: CompanyPrincipalPepContact,
      },
      {
        path: "evida/principals/other-principal-foreign-address",
        component: CompanyPrincipalForeignAddress,
      },
      {
        path: "evida/principals/other-principal-taxable-country",
        component: CompanyPrincipalTaxableCountry,
      },
      {
        path: "evida/aml/industry",
        component: Industry,
      },
      {
        path: "evida/aml/revenue",
        component: Revenue,
      },
      {
        path: "evida/aml/employees",
        component: Employees,
      },
      {
        path: "evida/aml/savings-purpose",
        component: SavingsPurpose,
      },
      {
        path: "evida/aml/investment-background",
        component: InvestmentBackground,
      },
      {
        path: "evida/aml/deposit-interval",
        component: DepositInterval,
      },
      {
        path: "evida/aml/yearly-deposit",
        component: YearlyDeposit,
      },
      {
        path: "evida/aml/yearly-deposit-manual",
        component: YearlyDepositManual,
      },
      {
        path: "evida/aml/withdrawal-interval",
        component: WithdrawalInterval,
      },
      {
        path: "evida/aml/withdrawal-interval-warning",
        component: WithdrawalIntervalWarning,
      },
      {
        path: "evida/aml/accountant",
        component: Accountant,
      },
      {
        path: "evida/done",
        component: Done,
      },
      {
        path: "welcome",
        component: Welcome,
      },
      {
        path: "introduction",
        component: Introduction,
      },
      {
        path: "economy/inactive",
        component: Inactive,
      },
      {
        path: "economy/average-operating-profit",
        component: AverageOperatingProfit,
      },
      {
        path: "economy/forecast-result",
        component: ForecastResult,
      },
      {
        path: "economy/long-term-debt",
        component: LongTermDebt,
      },
      {
        path: "economy/solidity",
        component: Solidity,
      },
      {
        path: "economy/liquid-assets",
        component: LiquidAssets,
      },
      {
        path: "economy/short-term-debt",
        component: ShortTermDebt,
      },
      {
        path: "economy/liquidity",
        component: Liquidity,
      },
      {
        path: "economy/time-to-withdraw",
        component: TimeToWithdraw,
      },
      {
        path: "agreements/authorized-signatory-contact/:index",
        component: AuthorizedSignatoryContact,
      },
      { path: "agreements/summary", component: Summary },
      { path: "agreements/congratulations", component: Congratulations },
      {
        path: "sign",
        component: KycSign,
      },
      {
        path: "validation",
        component: KycValidation,
      },
      {
        // Catch-all
        path: "*",
      },
    ],
  },
] as RouteRecordRaw[];
