<template>
  <div class="strategy">
    <div v-if="tiedAgent">
      <Focus
        :disabled="disabled"
        @save="$emit('save', 'focus')"
      />
    </div>
    <template v-else>
      <LoadingSpinnerOverlay
        v-if="!strategyLoaded"
        :model-value="true"
        persistent
      />
      <v-window
        v-model="tab"
        class="strategy__tabs-items"
      >
        <v-window-item
          v-if="!tiedAgent"
          value="strategy"
        >
          <Overview :disabled="disabled" />
        </v-window-item>
        <v-window-item
          v-if="!tiedAgent"
          value="ethical"
        >
          <StrategyEthical
            :disabled="disabled"
            @save="$emit('save', 'ethicalChoices')"
          />
        </v-window-item>
        <v-window-item value="theme">
          <Focus
            :disabled="disabled"
            @save="$emit('save', 'focus')"
          />
        </v-window-item>
        <v-window-item
          v-if="!tiedAgent"
          value="market"
        >
          <Market
            :disabled="disabled"
            @save="$emit('save', 'market')"
          />
        </v-window-item>
      </v-window>
      <v-divider />
      <v-tabs
        v-model="tab"
        :color="softBlue"
        class="strategy__tabs"
        height="3.5rem"
        align-tabs="center"
        show-arrows
      >
        <v-tab
          v-for="item in tabs"
          :key="item.key"
          :value="item.key"
          :data-cy="`strategyTab${item.key}`"
          class="strategy__tab"
          selected-class="strategy__tab--active"
        >
          <icon
            fixed-width
            class="strategy__tab-icon"
            :icon="item.icon"
          />
        </v-tab>
      </v-tabs>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import StrategyEthical from "./strategy-ethical.vue";
import Focus from "./focus.vue";
import Market from "./market.vue";
import Overview from "./overview.vue";
import type { TiedAgent } from "@/clients";
import type { UserState } from "@/store/user/types";
import { softBlue } from "@/styles/colors";
import type { PortfolioState } from "@/store/portfolio/types";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";

type TabKey = "strategy" | "ethical" | "theme" | "market";

interface Tab {
  icon: string[];
  key: TabKey;
}

export default defineComponent({
  components: {
    Overview,
    StrategyEthical,
    Focus,
    Market,
    LoadingSpinnerOverlay,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["save"],
  data: () => ({
    showDialog: false,
    tab: "strategy",
    softBlue,
  }),
  computed: {
    strategyLoaded() {
      return (this.$store.state.portfolioStore as PortfolioState).strategy;
    },
    tiedAgent(): TiedAgent | undefined {
      const { tiedAgent } = this.$store.state.userStore.currentLegalEntity as UserState;
      if (tiedAgent) {
        return tiedAgent;
      }
      return undefined;
    },
    tabs(): Tab[] {
      if (this.tiedAgent) {
        return [
          {
            icon: ["fal", "palette"],
            key: "theme",
          },
        ];
      }
      return [
        {
          icon: ["fal", "sliders-h"],
          key: "strategy",
        },
        {
          icon: ["fal", "heart"],
          key: "ethical",
        },
        {
          icon: ["fal", "palette"],
          key: "theme",
        },
        {
          icon: ["fal", "globe-americas"],
          key: "market",
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.strategy {
  &__tabs {
    width: 100%;
  }
  &__tab {
    font-size: 1.5rem;
    &--active {
      .strategy__tab-icon {
        color: $soft-blue;
        filter: drop-shadow(0 0.1875rem 0.375rem rgba($soft-blue, 0.4));
      }
    }
  }
  &__tabs-items {
    height: 22rem;
  }
  &__tab-icon {
    color: rgba($nearly-black, 0.2);
  }
}

// Can't use the proper background color prop, see https://github.com/vuetifyjs/vuetify/issues/10633
:deep(.v-tabs-bar) {
  background-color: transparent !important;
}

:deep(.v-window) {
  background-color: transparent !important;
}
:deep(.v-list-item--disabled) {
  color: $nearly-black;
  opacity: 1;
}
</style>
