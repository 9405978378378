const localeStringOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

export function numberFormat(value: number, currency: string) {
  let formattedNumber = "-";
  if (!Number.isNaN(Number(value))) {
    formattedNumber = `${value.toLocaleString("sv-SE", localeStringOptions)} ${currency}`;
  }
  return formattedNumber;
}
export function roundedNumberFormat(value: number, unit: string) {
  let formattedNumber = "-";
  if (!Number.isNaN(Number(value))) {
    formattedNumber = `${value.toLocaleString("sv-SE", { maximumFractionDigits: 0 })} ${unit}`;
  }
  return formattedNumber;
}
export function fractionToPercentage(value: number) {
  let formattedNumber = "-";
  if (!Number.isNaN(Number(value))) {
    const valueInPercent = value * 100;
    if (valueInPercent > 1000) {
      // Values over 1000 (arbitrarily chosen) percent are very likely errors, for example Aker after
      // spinoff from Lundin
      formattedNumber = "- %";
    } else {
      formattedNumber = `${valueInPercent.toLocaleString("sv-SE", localeStringOptions)} %`;
    }
  }
  return formattedNumber;
}
