import { PortfolioBase, PortfolioPerformanceClient } from "@/clients";
import { baseUrl } from "@/clients/config";
import { RootState } from "@/types";
import { ActionTree } from "vuex";
import { LegalEntityPortfolioIds } from "@/types/user";
import { OverviewState } from "./types";
import { OverviewMutation } from "./mutations";

const performanceClient = new PortfolioPerformanceClient(baseUrl);

export const OverviewActions = {
  loadCurrentLegalEntityPerformances: "loadCurrentLegalEntityPerformances",
  loadPerformances: "loadPerformances"
};

function loadPerformance(
  state: OverviewState,
  legalEntityId: string,
  portfolioId: string,
  commit: any,
  forceReload: boolean = false
) {
  if (
    (!state.portfolioPerformances[portfolioId] || forceReload) &&
    !state.performanceLoading[portfolioId]
  ) {
    commit(OverviewMutation.setPerformanceLoading, {
      portfolioId,
      loading: true
    });
    performanceClient
      .get(legalEntityId, portfolioId, undefined, undefined, true)
      .then((x) => {
        commit(OverviewMutation.setPerformanceLoading, {
          portfolioId,
          loading: false
        });
        commit(OverviewMutation.setPerformance, {
          portfolioId,
          performance: x
        });
      })
      .catch((_) => {
        commit(OverviewMutation.setPerformanceLoading, {
          portfolioId,
          loading: false
        });
      });
  }
}

export const actions: ActionTree<OverviewState, RootState> = {
  async [OverviewActions.loadCurrentLegalEntityPerformances]({
    state,
    commit,
    getters,
    rootState
  }) {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const portfolio of rootState.navigationStore.portfolios as PortfolioBase[]) {
      loadPerformance(
        state,
        rootState.userStore.currentLegalEntity.brickId,
        portfolio.id,
        commit,
        getters.tokenUserInfo.testNewFeatures
      );
    }
  },
  async [OverviewActions.loadPerformances](
    { state, commit, rootState },
    payload: {
      legalEntitiesPortfolioIds: LegalEntityPortfolioIds[];
      forceReload: boolean;
    }
  ) {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const legalEntityPortfolioIds of payload.legalEntitiesPortfolioIds) {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const portfolioId of legalEntityPortfolioIds.portfolioIds) {
        loadPerformance(
          state,
          legalEntityPortfolioIds.legalEntityId,
          portfolioId,
          commit,
          payload.forceReload
        );
      }
    }
  }
};
