import { NavigationGuardNext, RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { CURRENT_SIGNUP_PATH_KEY } from "@/config/signup";
import Signup from "../views/signup/signup.vue";

import Welcome from "../views/signup/questions/welcome.vue";
import ReferralIntro from "../views/signup/introduction/referral-intro.vue";
import SteplerIntro from "../views/signup/introduction/stepler-intro.vue";
import LeadsIntro from "../views/signup/introduction/leads-intro.vue";
import FamiljekortetIntro from "../views/signup/introduction/familjekortet-intro.vue";
import InvalidReferral from "../views/signup/introduction/invalid-referral.vue";

import Contact from "../views/signup/questions/contact.vue";
import Checklist from "../views/signup/questions/checklist.vue";
import BankId from "../views/signup/questions/bankid.vue";
import Register from "../views/signup/questions/register.vue";
import SwedishResident from "../views/signup/questions/swedish-resident.vue";
import CitizenshipDenial from "../views/signup/questions/citizenship-denial.vue";
import VerifyPersonalData from "../views/common-signup/questions/account/verify-personal-data.vue";
import Congratulations from "../views/signup/questions/congratulations.vue";

export default [
  {
    path: "signup",
    name: "signup",
    component: Signup,

    // Always allow the user to go to return to a page they have previously visited.
    // If they try to go to any other question, put them on the last visited path.
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: (path?: RouteLocationRaw) => void): void => {
      const { getters } = store as any;
      if (to.path.endsWith("/signup")) {
        // This check is here to avoid infinite recursion.
        next();
        // next({ params: to.params, query: to.query }); // TODO?
      } else {
        const latestVisitedPath = sessionStorage.getItem(CURRENT_SIGNUP_PATH_KEY);
        if (latestVisitedPath) {
          const latestVisitedQuestion = getters.questionProgress(latestVisitedPath);
          const toQuestion = getters.questionProgress(to.path);
          const hasVisitedToQuestion =
            latestVisitedQuestion &&
            toQuestion &&
            (toQuestion.partNumber < latestVisitedQuestion.partNumber ||
              (toQuestion.partNumber === latestVisitedQuestion.partNumber &&
                toQuestion.questionNumber <= latestVisitedQuestion.questionNumber));
          if (hasVisitedToQuestion) {
            next();
          } else if (toQuestion) {
            // The user tries to access a question or introduction which doesn't exist or which
            // they haven't reached using the normal flow. Put them on the latest visited path.
            next({ params: to.params, path: latestVisitedPath, replace: true });
          } else {
            next({ params: to.params, path: "signup", replace: true });
          }
        } else {
          // The user has not yet started the signup flow. Put them on the signup start page.
          next({ params: to.params, path: "signup", replace: true });
        }
      }
    },
    children: [
      { path: "invalid-referral", component: InvalidReferral },
      { path: "referral-intro", component: ReferralIntro },
      { path: "stepler-intro", component: SteplerIntro },
      { path: "leads-intro", component: LeadsIntro },
      { path: "familjekortet-intro", component: FamiljekortetIntro },
      {
        path: "welcome",
        component: Welcome
      },
      { path: "checklist", component: Checklist },
      { path: "contact", component: Contact },
      { path: "bankid", component: BankId },
      { path: "register", component: Register },
      { path: "swedish-resident", component: SwedishResident },
      { path: "swedish-resident-error", component: CitizenshipDenial },
      { path: "verify-personal-data", component: VerifyPersonalData },
      { path: "congratulations", component: Congratulations },
      {
        // Catch-all
        path: "*"
      }
    ]
  }
] as RouteRecordRaw[];
