<template>
  <side-dialog
    v-if="modelValue"
    :model-value="modelValue"
    :title="$t('portfolio-settings.strategy.focus-side-dialog.title')"
    @update:model-value="() => $emit('update:model-value', false)"
  >
    <div class="dialog">
      <div class="dialog__body">
        {{ $t("portfolio-settings.strategy.focus-side-dialog.body") }}
      </div>
      <div
        v-for="(theme, index) in themes"
        :key="index"
        class="dialog__body"
      >
        <div style="font-weight: bold">
          {{ theme.title }}
        </div>
        <div>
          {{ theme.body }}
        </div>
      </div>
      <div class="dialog__button-wrapper">
        <common-button @click="() => $emit('update:model-value', false)">
          {{ $t("ok") }}
        </common-button>
      </div>
    </div>
  </side-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { TiedAgentPortfolioThemeType } from "@/clients";
import { UserState } from "@/store/user/types";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    SideDialog,
    CommonButton
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    themes(): any {
      const { tiedAgent } = this.$store.state.userStore.currentLegalEntity as UserState;
      if (tiedAgent && tiedAgent.portfolioThemes) {
        return tiedAgent.portfolioThemes.map((x) => ({
          title: this.$t(
            `tiedAgentPortfolioThemes.${
              TiedAgentPortfolioThemeType[x.tiedAgentPortfolioThemeType]
            }.description`
          ),
          body: this.$t(
            `tiedAgentPortfolioThemes.${
              TiedAgentPortfolioThemeType[x.tiedAgentPortfolioThemeType]
            }.infoText`
          )
        }));
      }
      return undefined;
    }
  }
});
</script>

<style lang="scss" scoped>
.dialog {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__body {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 300;
  }
  &__button-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-column-gap: 1rem;
    width: 100%;
  }
}
</style>
