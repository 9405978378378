<template>
  <div class="connect-bank">
    <div class="connect-bank__wrapper">
      <icon
        :icon="['fal', 'wallet']"
        class="connect-bank__icon"
      />
      <h2 class="connect-bank__title">
        {{ $t("connectBankAccount") }}
      </h2>
      <icon
        :icon="['fas', 'chevron-right']"
        class="connect-bank__forward-icon"
      />
    </div>
    <div class="connect-bank__content-wrapper">
      <span class="connect-bank__message">
        {{ $t("components.connect-bank.body") }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.connect-bank {
  display: flex;
  flex-direction: column;
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.125rem;
    margin-right: 0.75rem;
  }
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
  }
  &__forward-icon {
    margin: 0.5rem 0 auto auto;
    color: $french-grey;
  }
}
</style>
