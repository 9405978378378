import { RiskLevel } from "@/clients";

// TODO: Calculate real stock fraction. Task: https://trello.com/c/1RjsX0HF
export const STOCKFRACTION = 0.2;
export const MINIMUM_RISK_LEVEL = RiskLevel.Three;
export const MAXIMUM_RISK_LEVEL = RiskLevel.Five;

function calculateInterestsPercentage(stocksPercentage: number): number {
  return 1 - stocksPercentage;
}

export function numberToRiskLevelKey(riskNumber: number | undefined): RiskLevel {
  switch (riskNumber) {
    case 3:
      return RiskLevel.Three;
    case 4:
      return RiskLevel.Four;
    case 5:
      return RiskLevel.Five;
    default:
      return RiskLevel.Invalid;
  }
}

export function calculateRisk(riskLevel: RiskLevel | undefined): {
  stocksFraction: number;
  interestFraction: number;
} {
  const level = riskLevel as number;
  if (Number.isNaN(level)) {
    return { stocksFraction: NaN, interestFraction: NaN };
  }
  const stocksFraction = level === 1 ? 0 : level * STOCKFRACTION;
  const interestFraction = calculateInterestsPercentage(stocksFraction);
  return { stocksFraction, interestFraction };
}

export function nextRiskLevel(currentRiskLevel: number, increment: boolean): number {
  if (increment) {
    return Math.min(MAXIMUM_RISK_LEVEL, currentRiskLevel + 1);
  }
  return Math.max(MINIMUM_RISK_LEVEL, currentRiskLevel - 1);
}
