<template>
  <div class="economy">
    <card-header
      class="economy__header"
      :title="$t('economy')"
      :icon="['fal', 'coins']"
    >
      <edit-button
        :edit="edit"
        @click="toggleEdit"
      />
    </card-header>
    <v-form ref="form">
      <select-list
        v-model="revenue"
        :edit="edit"
        :items="alternatives"
        :label="$t('revenue')"
        :rules="[rules.required]"
        @change="(value) => (revenue = value)"
      />
      <select-list
        v-model="active"
        :edit="edit"
        :items="activeOptions"
        :label="$t('company-profile.info.active')"
        @change="(value) => (active = value)"
      />
      <v-text-field
        ref="averageOperatingProfitInput"
        v-model="displayAverageOperatingProfit"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        variant="underlined"
        :disabled="!edit || !active"
        type="tel"
        validate-on="blur"
        :label="$t('averageOperatingProfit')"
        :rules="[rules.required, rules.number]"
        :suffix="displayAverageOperatingProfit !== undefined && active ? $t('currency') : ''"
      />
      <v-text-field
        ref="forecastResultThisYearInput"
        v-model="displayForecastResultThisYear"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        variant="underlined"
        :disabled="!edit || !active"
        type="tel"
        validate-on="blur"
        :label="$t('forecastResultThisYear')"
        :rules="[rules.required, rules.number]"
        :suffix="displayForecastResultThisYear !== undefined && active ? $t('currency') : ''"
      />
      <select-list
        v-model="hasLongTermDebt"
        class="economy__select"
        :disabled="!active"
        :edit="edit"
        :items="yesNoOptions"
        variant="underlined"
        :rules="[rules.requiredSelect]"
        validate-on="blur"
        :label="$t('hasLongTermDebt')"
        @change="(value) => (hasLongTermDebt = value)"
      />

      <v-text-field
        ref="solidityPercentageInput"
        v-model="displaySolidityPercentage"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        variant="underlined"
        :disabled="!edit || !active"
        type="tel"
        validate-on="blur"
        :label="$t('solidityPercentage')"
        :rules="[rules.required, rules.number]"
        :suffix="
          displaySolidityPercentage !== undefined ? $t('company-profile.economy.percent') : ''
        "
      />
      <select-list
        v-model="hasShortTermDebt"
        class="economy__select"
        :edit="edit"
        :disabled="!edit || !active"
        :items="yesNoOptions"
        :label="$t('hasShortTermDebt')"
        :rules="[rules.requiredSelect]"
        @change="(value) => (hasShortTermDebt = value)"
      />
      <v-text-field
        v-if="hasShortTermDebt"
        ref="liquidityPercentageInput"
        v-model="displayLiquidityPercentage"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        :disabled="!edit"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :label="$t('liquidityPercentage')"
        :rules="[rules.required, rules.number]"
        :suffix="
          displayLiquidityPercentage !== undefined ? $t('company-profile.economy.percent') : ''
        "
      />
      <v-text-field
        ref="liquidAssetsInput"
        v-model="displayLiquidAssets"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        variant="underlined"
        type="tel"
        validate-on="blur"
        :disabled="!edit || !active"
        :label="$t('company-profile.economy.liquidAssets')"
        :rules="[rules.required, rules.number]"
        :suffix="displayLiquidAssets !== undefined && active ? $t('currency') : ''"
      />
    </v-form>
  </div>
</template>
<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { mapGetters } from "vuex";
import { UserMutation } from "@/store/user/mutations";
import CardHeader from "@/components/card-header.vue";
import { CompanyKnowYourCustomerAnswers, CompanyRevenue, LegalEntity } from "@/clients";
import { RevenueAlterantive } from "@/types/user";
import EditButton from "@/components/edit-button.vue";
import SelectList from "../profile/select.vue";

export default defineComponent({
  emits: ["save"],
  components: { CardHeader, EditButton, SelectList },
  data: () => ({
    edit: false
  }),
  computed: {
    ...mapGetters(["legalEntity", "errorLoadingUser"]),
    editedKyc() {
      return this.$store.state.userStore.editedCompanyKnowYourCustomerAnswers;
    },
    isActive() {
      return this.$store.state.userStore.editedCompanyKnowYourCustomerAnswers.isActive;
    },
    yesNoOptions(): any[] {
      return [
        {
          value: true,
          key: true,
          title: this.$t("yes")
        },
        {
          value: false,
          key: false,
          title: this.$t("no")
        }
      ];
    },
    activeOptions(): any[] {
      return [
        {
          value: true,
          key: true,
          title: this.$t("no")
        },
        {
          value: false,
          key: false,
          title: this.$t("yes")
        }
      ];
    },
    nullOptions(): any[] {
      return [
        {
          value: false,
          key: false,
          title: "-"
        }
      ];
    },
    kyc(): CompanyKnowYourCustomerAnswers | undefined {
      const legalEntity: LegalEntity = this.$store.state.userStore.currentLegalEntity;
      if (legalEntity) {
        return legalEntity.companyKnowYourCustomerAnswers;
      }
      return undefined;
    },
    aggregatedInputs(): (string | undefined)[] {
      return [
        this.displayAverageOperatingProfit,
        this.displayForecastResultThisYear,
        this.displaySolidityPercentage,
        this.displayLiquidityPercentage
      ];
    },
    active: {
      get(): boolean | undefined {
        return this.editedKyc?.isActive;
      },
      async set(active: boolean) {
        this.$store.commit(UserMutation.setEditedCompanyKnowYourCustomerAnswers, {
          ...this.editedKyc,
          isActive: active
        });
        if (!active) {
          await (this.$refs.form as any).validate();
        }
      }
    },
    displayAverageOperatingProfit: {
      get(): string | undefined {
        if (!this.active) {
          return "";
        }
        if (this.averageOperatingProfit !== undefined && this.averageOperatingProfit !== null) {
          return this.averageOperatingProfit.toLocaleString("sv-SE");
        }
        return undefined;
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        this.averageOperatingProfit = parseInt(value, 10);
      }
    },
    displayLiquidAssets: {
      get(): string | undefined {
        if (!this.active) {
          return "";
        }
        if (this.liquidAssets !== undefined && this.liquidAssets !== null) {
          return this.liquidAssets.toLocaleString("sv-SE");
        }
        return undefined;
      },
      set(value: string) {
        if (value === "") {
          value = "0";
        }
        value = this.removeWhiteSpace(value);
        this.liquidAssets = parseInt(value, 10);
      }
    },
    averageOperatingProfit: {
      get(): number | string | undefined {
        if (this.editedKyc) {
          return this.editedKyc.averageOperatingProfit;
        }
        return undefined;
      },
      set(averageOperatingProfit: number) {
        this.$store.commit(UserMutation.setEditedCompanyKnowYourCustomerAnswers, {
          ...this.editedKyc,
          averageOperatingProfit
        });
      }
    },
    displayForecastResultThisYear: {
      get(): string | undefined {
        if (!this.active) {
          return "";
        }
        if (this.forecastResultThisYear !== undefined && this.forecastResultThisYear !== null) {
          return this.forecastResultThisYear.toLocaleString("sv-SE");
        }
        return undefined;
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        this.forecastResultThisYear = parseInt(value, 10);
      }
    },
    forecastResultThisYear: {
      get(): number | undefined {
        if (this.editedKyc && this.editedKyc.forecastResultThisYear) {
          return this.editedKyc.forecastResultThisYear;
        }
        return undefined;
      },
      set(forecastResultThisYear: number) {
        this.$store.commit(UserMutation.setEditedCompanyKnowYourCustomerAnswers, {
          ...this.editedKyc,
          forecastResultThisYear
        });
      }
    },
    hasLongTermDebt: {
      get(): boolean | undefined {
        if (!this.active) {
          return undefined;
        }
        return this.editedKyc?.hasLongTermDebt;
      },
      set(hasLongTermDebt: boolean | undefined) {
        this.$store.commit(UserMutation.setEditedCompanyKnowYourCustomerAnswers, {
          ...this.editedKyc,
          hasLongTermDebt
        });
      }
    },
    hasShortTermDebt: {
      get(): boolean | undefined {
        if (!this.active) {
          return undefined;
        }
        return this.editedKyc?.hasShortTermDebt;
      },
      set(hasShortTermDebt: number) {
        this.$store.commit(UserMutation.setEditedCompanyKnowYourCustomerAnswers, {
          ...this.editedKyc,
          hasShortTermDebt
        });
      }
    },
    displaySolidityPercentage: {
      get(): string | undefined {
        if (!this.active) {
          return undefined;
        }
        if (this.solidityPercentage !== undefined && this.solidityPercentage !== null) {
          return this.solidityPercentage.toLocaleString("sv-SE");
        }
        return undefined;
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        if (value === "") {
          value = "0";
        }
        this.solidityPercentage = parseInt(value, 10);
      }
    },
    solidityPercentage: {
      get(): number | undefined | null {
        return this.editedKyc?.solidityPercentage;
      },
      set(solidityPercentage: number) {
        this.$store.commit(UserMutation.setEditedCompanyKnowYourCustomerAnswers, {
          ...this.editedKyc,
          solidityPercentage
        });
      }
    },
    displayLiquidityPercentage: {
      get(): string | undefined {
        if (this.liquidityPercentage !== undefined && this.liquidityPercentage !== null) {
          return this.liquidityPercentage.toLocaleString("sv-SE");
        }
        return undefined;
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        if (value === "") {
          value = "0";
        }
        this.liquidityPercentage = parseInt(value, 10);
      }
    },
    liquidityPercentage: {
      get(): number | undefined | null {
        return this.editedKyc?.liquidityPercentage;
      },
      set(liquidityPercentage: number) {
        this.$store.commit(UserMutation.setEditedCompanyKnowYourCustomerAnswers, {
          ...this.editedKyc,
          liquidityPercentage
        });
      }
    },
    displayRevenue: {
      get(): any {
        if (this.revenue !== undefined && this.revenue !== null) {
          const matchingAlt = this.alternatives.find((x) => x.key === this.revenue);
          if (matchingAlt) {
            return matchingAlt.title;
          }
          return undefined;
        }
        return undefined;
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        if (value === "") {
          value = "0";
        }
        this.revenue = parseInt(value, 10);
      }
    },
    revenue: {
      get(): number | undefined {
        return this.editedKyc?.revenue;
      },
      set(revenue: number) {
        this.$store.commit(UserMutation.setEditedCompanyKnowYourCustomerAnswers, {
          ...this.editedKyc,
          revenue
        });
      }
    },
    liquidAssets: {
      get(): number | undefined {
        return this.editedKyc?.liquidAssets;
      },
      set(liquidAssets: number) {
        this.$store.commit(UserMutation.setEditedCompanyKnowYourCustomerAnswers, {
          ...this.editedKyc,
          liquidAssets
        });
      }
    },
    alternatives() {
      return [
        {
          title: this.$t("revenueOptions.NewlyCreated"),
          key: CompanyRevenue.NewlyCreated,
          value: CompanyRevenue.NewlyCreated
        },
        {
          title: this.$t("revenueOptions.LessThanThreeMillion") as string,
          key: CompanyRevenue.LessThanThreeMillion,
          value: CompanyRevenue.LessThanThreeMillion
        },
        {
          title: this.$t("revenueOptions.BetweenThreeAndTwentyMillion") as string,
          key: CompanyRevenue.BetweenThreeAndTwentyMillion,
          value: CompanyRevenue.BetweenThreeAndTwentyMillion
        },
        {
          title: this.$t("revenueOptions.BetweenTwentyAndFiftyMillion") as string,
          key: CompanyRevenue.BetweenTwentyAndFiftyMillion,
          value: CompanyRevenue.BetweenTwentyAndFiftyMillion
        },
        {
          title: this.$t("revenueOptions.MoreThanFiftyMillion") as string,
          key: CompanyRevenue.MoreThanFiftyMillion,
          value: CompanyRevenue.MoreThanFiftyMillion
        }
      ];
    },
    rules(): any {
      return {
        required: (value: string) =>
          (!!value && value !== "") || !this.active || this.$t("required"),
        requiredSelect: (value: boolean) =>
          value || value === false || !this.active || this.$t("required"),
        number: (value: string) => {
          if (value) {
            value = value.toString().replace(/\s/g, "");
            const pattern = /^\d+$/;
            return pattern.test(value) || !this.active || this.$t("notANumber");
          }
          return true;
        }
      };
    }
  },
  watch: {
    // I dont like this at all but i seem to not found any other solution.
    // Need to watch all inputs to resize them and not duplicate same watcher five times to get the suffix to append properly instead of anchored to the right
    aggregatedInputs: {
      immediate: true,
      handler() {
        nextTick().then(() => {
          const refs = [
            "averageOperatingProfitInput",
            "forecastResultThisYearInput",
            "solidityPercentageInput",
            "liquidityPercentageInput",
            "totalSavingInput",
            "liquidAssetsInput"
          ];
          refs.forEach((r: string) => {
            if (this.$refs[r]) {
              this.resizeInputToCharacters((this.$refs[r] as any).$el.querySelector("input"));
            }
          });
        });
      }
    }
  },

  methods: {
    async toggleEdit() {
      if (this.edit) {
        if ((await (this.$refs.form as any).validate()).valid) {
          this.$emit("save");
          this.$store.commit(UserMutation.decreaseUnsavedEdits);
          this.edit = false;
        }
      } else {
        this.edit = true;
        this.$store.commit(UserMutation.increaseUnsavedEdits);
      }
    },
    resizeInputToCharacters(input: HTMLInputElement | null): void {
      if (input) {
        input.style.width = `${input.value.length}ch`;
      }
    },
    removeWhiteSpace(value: string): string {
      return value.toString().replace(/\s/g, "");
    }
  }
});
</script>
<style lang="scss" scoped>
.economy {
  padding: 1.5rem 1.5rem 1.625rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__description {
    padding: 0.5rem 0;
  }
  &__input {
    color: black;

    :deep(.v-text-field__slot) {
      max-width: 100%;
    }
    :deep(input) {
      min-width: unset;
      flex: unset;
      max-width: 100%;
    }
    :deep(.v-text-field__suffix) {
      flex: 1;
      text-align: left;
      cursor: text;
    }
    :deep(.v-text-field__prefix) {
      align-self: flex-end;
    }

    :deep(label) {
      color: #707070 !important;
      font-size: 1.2rem;
      font-weight: 300;
    }
    :deep(.v-text-field) {
      margin-top: 0;
    }
    &--bottom {
      grid-column: 1 / 3;
      grid-row: 3;
    }
    &--disabled {
      :deep(.v-text-field__suffix) {
        color: inherit !important;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-label) {
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
  }
}
</style>
