<template>
  <single-choice
    :alternatives="alternatives"
    @click="goToNext"
  >
    <div>
      <question-text-section
        :heading="$t('signup.questions.checklist.heading')"
        :body="$t('signup.questions.checklist.body')"
      />
      <v-list
        class="checklist__list"
        variant="flat"
        density="comfortable"
        lines="two"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          class="checklist__list-item"
        >
          <template v-slot:prepend>
            <div :class="['checklist__ban-icon']">
              <icon
                fixed-width
                :icon="['fal', 'check']"
              />
            </div>
          </template>

          <v-list-item-title class="checklist__item-title">
            {{ item.text }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="item.subtitle"
            style="white-space: wrap"
          >
            {{ item.subtitle }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </div>
  </single-choice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoice from "@/views/signup/templates/single-choice.vue";

export default defineComponent({
  components: {
    SingleChoice,
    QuestionTextSection
  },
  extends: Question,
  data() {
    return {
      items: [
        {
          text: this.$t("signup.questions.checklist.bullet1")
        },
        {
          text: this.$t("signup.questions.checklist.bullet2"),
          subtitle: this.$t("signup.questions.checklist.bullet2Subtitle")
        },
        {
          text: this.$t("signup.questions.checklist.bullet3"),
          subtitle: this.$t("signup.questions.checklist.bullet3Subtitle")
        }
      ],
      alternatives: [
        {
          text: this.$t("noExit"),
          key: "exit",
          secondary: true
        },
        {
          text: this.$t("yesContinue"),
          key: "next"
        }
      ]
    };
  },
  methods: {
    goToNext(key: string) {
      if (key === "next") {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      } else {
        this.$router.push({ name: "login" });
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.checklist {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 2.5625rem;
  align-items: center;
  text-align: center;
  height: 100%;
  @include small-up {
    padding-top: 5rem;
  }
  &__ban-icon {
    width: 1.875rem;
    font-size: 1.5rem;
    color: $soft-blue;
    margin: 0 1rem 0 0 !important;
    align-self: center;
  }
  &__list {
    background: transparent;
    max-width: 22rem;
    margin: 0 auto;
    padding-top: 1rem;
    @include medium-up {
      padding-top: 2rem;
    }
  }
  &__list-item {
    background-color: transparent;
  }
  &__item-title {
    font-weight: bold;
    font-family: $heading-font-family;
    line-height: 1.3em;
  }
}
</style>
