<template>
  <span>{{ formatValue(Number(animatedValue)) }}</span>
</template>

<script lang="ts">
import anime from "animejs";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    formatValue: {
      type: Function,
      default: (value: any) => value
    },
    easing: {
      type: String,
      default: "linear"
    },
    duration: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      animatedValue: 0
    };
  },
  watch: {
    modelValue(value) {
      this.animateValue(value);
    }
  },
  mounted() {
    this.animateValue(this.modelValue);
  },
  methods: {
    animateValue(value: any) {
      const tl = anime.timeline({});
      tl.add({
        targets: this,
        animatedValue: value,
        delay: 0,
        round: true
      });

      tl.play();
    }
  }
});
</script>
