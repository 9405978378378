<template>
  <div class="discounts">
    <card-header :title="$t('profile.discounts.discounts')" :icon="['fal', 'badge-percent']">
      <add-button v-if="!discounts" :edit="edit" :disabled="loadingValidation" @click="click" />
    </card-header>
    <div class="discounts__content">
      <v-text-field
        v-if="edit"
        v-model="editedDiscountCode"
        :placeholder="$t('profile.discounts.enterCode')"
        :class="['economy__input', { 'economy__input--disabled': !edit }]"
        type="text"
        variant="underlined"
        validate-on="blur"
        :label="$t('discountCode')"
        :rules="[rules.required]"
        :error-messages="errorMessage"
        :loading="loadingValidation"
      />
      <div class="discounts__list">
        <div v-if="!discounts && !edit">
          {{ $t("profile.discounts.noDiscounts") }}
        </div>
        <template v-for="discount in discounts" v-else :key="discount.code">
          <div>{{ discount.code }}</div>
          <div
            v-if="
              discount.managementFeePercentageDiscount !== undefined &&
              discount.managementFeePercentageDiscount !== null
            "
            :key="discount.managementFeePercentageDiscount"
            class="discounts__row--right"
          >
            - {{ discount.managementFeePercentageDiscount }} %
          </div>
          <div
            v-else-if="discount.bonusAmount !== undefined && discount.bonusAmount !== null"
            :key="discount.bonusAmount"
            class="discounts__row--right"
          >
            {{ discount.bonusAmount }} kr
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AddDiscountResponse, AffiliateCampaign, DiscountValidation, LegalEntity } from "@/clients";
import CardHeader from "@/components/card-header.vue";
import { UserAction } from "@/store/user/action-definitions";
import AddButton from "./add-button.vue";

export default defineComponent({
  components: { CardHeader, AddButton },
  data: () => ({
    edit: false,
    editedDiscountCode: undefined,
    validation: undefined as DiscountValidation | undefined,
    loadingValidation: false
  }),
  computed: {
    rules(): any {
      return {
        required: (value: string) => !!value || "Obligatorisk"
      };
    },
    discounts(): AffiliateCampaign[] | undefined {
      const { currentLegalEntity }: { currentLegalEntity: LegalEntity } =
        this.$store.state.userStore;
      if (
        currentLegalEntity &&
        currentLegalEntity.affiliateCampaign?.managementFeePercentageDiscount !== undefined
      ) {
        return [currentLegalEntity.affiliateCampaign as AffiliateCampaign];
      }
      return undefined;
    },
    errorMessage(): string | undefined {
      switch (this.validation) {
        case DiscountValidation.Invalid:
          return "Ogiltig kod";
        case DiscountValidation.AlreadyAdded:
          return "Rabatten finns redan registrerad";
        default:
          return undefined;
      }
    }
  },
  methods: {
    async click() {
      if (this.edit) {
        this.loadingValidation = true;
        const response = (await this.$store.dispatch(
          UserAction.addAffiliateCode,
          this.editedDiscountCode
        )) as AddDiscountResponse;
        this.loadingValidation = false;

        if (response) {
          this.validation = response.type;
          if (this.validation === DiscountValidation.Ok) {
            this.edit = false;
          }
        }
      } else {
        this.edit = true;
      }
    }
  }
});
</script>
<style scoped lang="scss">
.discounts {
  padding: 1.5rem 1.5rem 1.625rem 1.5rem;
  &__content {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
  }
  &__list {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 2fr 1fr;
    grid-row-gap: 0.25rem;
  }
  &__row {
    &--right {
      text-align: right;
    }
  }
}
</style>
