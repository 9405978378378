<template>
  <div>
    <div
      v-if="showLabels"
      class="date-range__labels"
    >
      <span>{{ $t("from") }}</span>
      <span>{{ $t("to") }}</span>
    </div>
    <div class="date-range">
      <v-dialog
        ref="dialog"
        v-model="showFromDatePicker"
        width="18.125rem"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="white"
            height="3rem"
            class="date-range__button"
          >
            {{ displayFromDate }}
          </v-btn>
        </template>
        <v-locale-provider locale="sv">
          <v-date-picker
            v-model="isoFromDate"
            first-day-of-week="1"
            :min="isoPortfolioStartDate"
            :max="isoToDate"
            :color="softBlue"
            :title="$t('components.date-range.title')"
            @update:model-value="showFromDatePicker = false"
            ><v-btn
              v-if="clearable"
              variant="text"
              color="primary"
              @click="isoFromDate = undefined"
            >
              Rensa
            </v-btn></v-date-picker
          >
        </v-locale-provider>
      </v-dialog>
      <v-divider
        class="date-range__divider"
        vertical
      />
      <v-dialog
        ref="dialog"
        v-model="showToDatePicker"
        width="18.125rem"
      >
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="white"
            height="3rem"
            class="date-range__button"
          >
            {{ displayToDate }}
          </v-btn>
        </template>
        <v-date-picker
          v-model="isoToDate"
          locale="sv"
          first-day-of-week="1"
          :min="isoFromDate"
          :max="isoCurrentDate"
          :color="softBlue"
          :title="$t('components.date-range.title')"
          @update:model-value="showToDatePicker = false"
          ><v-btn
            v-if="clearable"
            variant="text"
            color="primary"
            @click="isoToDate = undefined"
          >
            Rensa
          </v-btn></v-date-picker
        >
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DateTime } from "luxon";
import { softBlue } from "@/styles/colors";
import { DateTimeRange } from "@/types/transaction";

export default defineComponent({
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: Object as () => DateTimeRange,
      required: true
    },
    showLabels: {
      type: Boolean,
      required: false,
      default: false
    },
    ignoreMinMax: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      softBlue,
      showFromDatePicker: false,
      showToDatePicker: false
    };
  },
  computed: {
    isoCurrentDate(): Date | undefined {
      if (this.ignoreMinMax) return undefined;
      return DateTime.local().toJSDate();
    },
    isoPortfolioStartDate(): Date | undefined {
      if (this.ignoreMinMax) return undefined;
      const creationDate = this.$store.getters.portfolioCreatedDate as DateTime | undefined;
      return creationDate ? creationDate.toJSDate() : undefined;
    },
    isoFromDate: {
      set(isoDate: Date | undefined) {
        this.$emit("update:model-value", {
          fromDate: DateTime.fromJSDate(isoDate as Date),
          toDate: this.modelValue.toDate
        });
      },
      get(): Date | undefined {
        return this.modelValue.fromDate ? this.modelValue.fromDate.toJSDate() : undefined;
      }
    },
    isoToDate: {
      set(isoDate: Date | undefined) {
        this.$emit("update:model-value", {
          fromDate: this.modelValue.fromDate,
          toDate: DateTime.fromJSDate(isoDate as Date)
        });
      },
      get(): Date | undefined {
        return this.modelValue.toDate ? this.modelValue.toDate.toJSDate() : undefined;
      }
    },
    displayFromDate(): string {
      return this.modelValue.fromDate ? this.modelValue.fromDate.toFormat("yyyy.MM.dd") : "-";
    },
    displayToDate(): string {
      return this.modelValue.toDate ? this.modelValue.toDate.toFormat("yyyy.MM.dd") : "-";
    }
  }
});
</script>

<style lang="scss" scoped>
.date-range {
  border-radius: 20px;
  overflow: hidden;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  box-shadow: 0 0.125rem 0.625rem rgba(0, 0, 0, 0.16);
  @include small-up {
    width: 14rem;
  }
  &__button {
    font-size: 1rem;
    font-family: $heading-font-family;
    letter-spacing: normal;
    text-transform: none;
    font-weight: 300;
    box-shadow: none;
    border-radius: 0;
    width: 50%;
    @include small-up {
      width: 7rem;
    }
  }
  &__labels {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.25rem;
    font-size: 1rem;
    font-weight: 100;
    :nth-child(2) {
      margin-right: 0.5rem;
    }
  }
  &__divider {
    margin: 0;
  }
}
</style>
