<template>
  <div class="portfolio-performance">
    <performance-header
      class="portfolio-performance__header"
      :title="$t('portfolio-settings.portfolio-performance.portfolioPerformanceTitle')"
      :icon="['fal', 'history']"
    >
      <template #action>
        <information-button @click="() => (showDialog = true)" />
      </template>
    </performance-header>
    <div class="portfolio-performance__body-wrapper">
      <div class="portfolio-performance__description-text">
        {{ $t("portfolio-settings.portfolio-performance.portfolioPerformanceDescription") }}
      </div>
      <div class="portfolio-performance__value-body-wrapper">
        <div class="portfolio-performance__body-title">
          {{ $t("portfolio-settings.portfolio-performance.strategy") }}
        </div>
        <div class="portfolio-performance__value-wrapper">
          <div class="portfolio-performance__header-sub-title">
            {{ `${backcastYears} ${$t("years")}` }}
          </div>
          <div
            class="portfolio-performance__percentage-title"
            :class="{
              'portfolio-performance__percentage-title--negative':
                backcastReturnPercentage !== undefined &&
                backcastReturnPercentage !== null &&
                backcastReturnPercentage < 0
            }"
          >
            <span v-if="backcastReturnPercentage"
              >{{ backcastReturnPercentage > 0 ? "+ " : "" }}
              <animated-number
                :model-value="backcastReturnPercentage"
                :format-value="formatPercentage"
              />
              %</span
            >
            <span v-else>-</span>

            <loader v-if="showLoader" />
          </div>
        </div>
      </div>
    </div>
    <side-dialog
      v-if="showDialog"
      v-model="showDialog"
      :title="$t('portfolio-settings.portfolio-performance.portfolioPerformanceTitle')"
    >
      <div class="portfolio-performance__dialog">
        <div class="portfolio-performance__dialog-body">
          {{ $t("portfolio-settings.portfolio-performance.informationSection1") }}
        </div>
        <div class="portfolio-performance__dialog-body">
          {{ $t("portfolio-settings.portfolio-performance.informationSection2") }}
        </div>
        <div class="portfolio-performance__dialog-body">
          {{ $t("portfolio-settings.portfolio-performance.informationSection3") }}
        </div>
      </div>
      <div class="portfolio-performance__dialog-button-wrapper">
        <common-button
          class="portfolio-performance__dialog-button"
          @click="() => (showDialog = false)"
        >
          {{ $t("ok") }}
        </common-button>
      </div>
    </side-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import { PortfolioAction } from "@/store/portfolio/actions";
import { BackcastRequest, Strategy } from "@/clients";
import InformationButton from "@/components/information-button.vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import AnimatedNumber from "@/components/animated-number.vue";
import PerformanceHeader from "./header.vue";
import Loader from "./loader.vue";

export default defineComponent({
  components: {
    PerformanceHeader,
    Loader,
    InformationButton,
    SideDialog,
    CommonButton,
    AnimatedNumber
  },
  filters: {
    formatPercentage(percentage: number): string {
      const localeStringOptions = {
        maximumFractionDigits: 2
      };
      const formatter = Intl.NumberFormat("sv-SE", localeStringOptions);
      return formatter.format(percentage);
    }
  },
  data: () => ({
    backcastYears: 5,
    showDialog: false
  }),
  computed: {
    showLoader(): boolean {
      const { isLoadingBackcast } = this.$store.state.portfolioStore;
      return isLoadingBackcast;
    },
    ...mapGetters(["backcastReturn"]),
    backcastReturnPercentage(): number | undefined {
      if (this.backcastReturn) {
        return this.backcastReturn * 100;
      }
      return undefined;
    },
    editedStrategy(): Strategy | undefined {
      return this.$store.state.portfolioStore.editedStrategy;
    },
    backcastRequest(): BackcastRequest | undefined {
      const today = DateTime.local();
      const startDate = today.minus({ years: this.backcastYears });
      const { portfolioSettings } = this.$store.state.portfolioStore;
      if (this.editedStrategy && portfolioSettings) {
        return new BackcastRequest({
          start: startDate.toJSDate(),
          strategy: this.editedStrategy,
          portfolioSettings
        });
      }
      return undefined;
    }
  },
  watch: {
    backcastRequest: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (oldVal !== undefined || this.backcastReturn === undefined) {
            this.loadBackcast();
          }
        }
      }
    }
  },
  methods: {
    loadBackcast() {
      if (this.backcastRequest) {
        this.$store.dispatch(PortfolioAction.loadBackcast, this.backcastRequest);
      }
    },
    formatPercentage(percentage: number): string {
      const localeStringOptions = {
        maximumFractionDigits: 2
      };
      const formatter = Intl.NumberFormat("sv-SE", localeStringOptions);
      return formatter.format(percentage);
    }
  }
});
</script>

<style lang="scss" scoped>
.portfolio-performance {
  padding: 1.5rem 1.5rem 1.625rem 1.5rem;
  &__header {
    max-height: 1.5rem;
  }
  &__header-sub-title {
    font-weight: 300;
    font-size: 1.125rem;
    color: $nearly-black;
    margin-right: 0.25rem;
  }
  &__body-wrapper {
    padding-top: 1.125rem;
  }
  &__description-text {
    font-weight: 300;
  }
  &__value-body-wrapper {
    margin-top: 1.375rem;
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
  }
  &__value-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
  }
  &__body-title {
    font-weight: 300;
    font-size: 1.125rem;
    color: $nearly-black;
  }
  &__percentage-title {
    position: relative;
    min-width: 4rem;
    height: 1.5rem;
    font-size: 1.25rem;
    font-family: $heading-font-family;
    font-weight: bold;
    text-align: center;
    color: #4f637b;
    &--negative {
      color: $deep-red;
    }
  }
  &__dialog {
    padding: 2rem;
    height: 100%;
  }
  &__dialog-body {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 300;
  }
  &__dialog-button-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-column-gap: 1rem;
    width: 100%;
  }
}
</style>
