<template>
  <single-choice
    :alternatives="alternatives"
    @click="buttonClick"
  >
    <question-text-section
      progress
      :heading="$t('common-signup.questions.economy.current-savings.heading')"
      :body="$t('common-signup.questions.economy.current-savings.body')"
    />
    <range-template
      v-model="modelValue"
      :values="values"
      :label="$t('currency')"
    />
  </single-choice>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import RangeTemplate from "../../../signup/templates/range.vue";

export default defineComponent({
  emits: ["next"],
  components: {
    RangeTemplate,
    QuestionTextSection,
    SingleChoice
  },
  extends: Question,
  computed: {
    values() {
      const values: number[] = [];
      for (let i = 0; i < 200000; i += 10000) {
        values.push(i);
      }
      for (let i = 200000; i < 700000; i += 50000) {
        values.push(i);
      }
      for (let i = 700000; i < 2000000; i += 100000) {
        values.push(i);
      }
      for (let i = 2000000; i < 10000000; i += 500000) {
        values.push(i);
      }
      for (let i = 10000000; i <= 20000000; i += 1000000) {
        values.push(i);
      }
      for (let i = 20000000; i <= 100000000; i += 5000000) {
        values.push(i);
      }
      return values;
    },
    modelValue: {
      get(): number {
        return this.$store.state.signupStore.totalSavings;
      },
      set(value: number) {
        this.$store.commit(SignupMutation.setTotalSavings, value);
      }
    },
    alternatives(): any {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    buttonClick() {
      this.$emit("next", this.$store.state.signupStore.totalSavings);
    }
  }
});
</script>

<style lang="scss" scoped></style>
