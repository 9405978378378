<template>
  <single-choice
    :alternatives="[
      {
        text: $t('next'),
        key: 'next'
      }
    ]"
    information
    @showInformation="() => (showDialog = true)"
    @click="buttonClick"
  >
    <question-text-section progress />
    <div class="sectors__heading">
      <h1
        class="sectors__heading--title"
        data-jest="heading"
      >
        {{ headingStart }} <span class="sectors__heading--underline">{{ $t("not") }}</span>
        {{ headingEnd }}
      </h1>
    </div>

    <v-list
      variant="flat"
      density="comfortable"
      class="sectors__list-wrapper"
    >
      <v-list-item
        v-for="(sector, i) in alternatives"
        :key="i"
        ripple
        :data-cy="sector.key"
        class="sectors__list-item"
        :style="{ color: colors?.text }"
        @click="() => toggleIncluded(sector.key as Sector, !sector.checked)"
      >
        <template #prepend>
          <icon
            style="margin-right: 1rem"
            fixed-width
            :icon="sector.icon"
            :style="{ fill: colors?.text }"
          />
        </template>

        <v-list-item-title
          :class="['sectors__item-title', { 'sectors__item-title--inactive': !sector.checked }]"
          >{{ sector.label }}</v-list-item-title
        >
        <template #append>
          <div
            :class="[
              'sectors__ban-icon',
              {
                'sectors__ban-icon--faded': sector.checked
              }
            ]"
            :style="{ fill: colors?.text || undefined }"
          >
            <icon
              fixed-width
              :icon="['fal', 'ban']"
            />
          </div>
        </template>
      </v-list-item>
    </v-list>
    <side-dialog
      v-model="showDialog"
      :title="$t('ethicalSelection')"
    >
      <div class="sectors__side-dialog">
        <div
          v-for="(section, i) in infoSections"
          :key="i"
        >
          <p class="sectors__side-dialog--heading">
            {{ section.heading }}
          </p>
          <p class="sectors__side-dialog--text">
            {{ section.text }}
          </p>
        </div>
      </div>
    </side-dialog>
  </single-choice>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { MultipleChoiceAlternative, Sector } from "@/types/signup";
import SideDialog from "@/components/dialog/side-dialog.vue";
import InformationLink from "@/views/signup/information-link.vue";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import SingleChoice from "../../../signup/templates/single-choice.vue";
import { InfoSection } from "../../../../types";
import { SignupState } from "@/store/signup/types";
import { Colors } from "@/styles/colors";

export default defineComponent({
  emits: ["toggleIncluded"],
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice,
    InformationLink,
    SideDialog
  },
  extends: Question,
  props: {
    includedSectors: { type: Object },
    headingStart: { type: String },
    headingEnd: { type: String }
  },
  data: () => ({
    showDialog: false
  }),
  computed: {
    colors(): Colors | undefined {
      const { colors } = this.$store.state.signupStore as SignupState;
      return colors || undefined;
    },
    alternatives(): MultipleChoiceAlternative[] {
      const alternatives = [
        {
          label: this.$t("defense"),
          key: "weaponsAndDefense",
          icon: ["fal", "fighter-jet"],
          checked: this.isIncluded("weaponsAndDefense")
        },
        {
          label: this.$t("ethicalAlcoholTobaccoTitle"),
          key: "alcoholTobacco",
          icon: ["fal", "wine-bottle"],
          checked: this.isIncluded("alcoholTobacco")
        },
        {
          label: this.$t("common-signup.questions.preferences.sectors.gambling"),
          key: "casinosAndGambling",
          icon: ["fal", "dice"],
          checked: this.isIncluded("casinosAndGambling")
        },
        {
          label: this.$t("ethicalFossilTitle"),
          key: "fossilFuel",
          icon: ["fal", "gas-pump"],
          checked: this.isIncluded("fossilFuel")
        }
      ];
      return alternatives;
    },
    infoSections(): InfoSection[] {
      return [
        {
          text: this.$t("ethical.ethicalSelectionIntro")
        },
        {
          heading: this.$t("ethical.ethicalDefenseTitle") as string,
          text: this.$t("ethical.ethicalDefenseBody")
        },
        {
          heading: this.$t("ethical.ethicalAlcoholTobaccoTitle") as string,
          text: this.$t("ethical.ethicalAlcoholTobaccoBody")
        },
        {
          heading: this.$t("ethical.ethicalGamblingTitle") as string,
          text: this.$t("ethical.ethicalGamblingBody")
        },
        {
          heading: this.$t("ethical.ethicalFossilTitle") as string,
          text: this.$t("ethical.ethicalFossilBody")
        }
      ];
    }
  },
  methods: {
    buttonClick(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    toggleIncluded(key: Sector, value: boolean) {
      this.$emit("toggleIncluded", { key, value });
    },
    isIncluded(sector: Sector): boolean {
      if (this.includedSectors && Object.keys(this.includedSectors).length > 0) {
        return this.includedSectors[sector];
      }
      return false;
    }
  }
});
</script>

<style lang="scss" scoped>
.sectors {
  &__list-wrapper {
    max-width: 20rem;
    background: transparent !important;
    margin: 0 auto;
    padding-top: 1rem;
    @include medium-up {
      padding-top: 2rem;
    }
  }
  &__information-link-wrapper {
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
    display: flex;
  }
  &__ban-icon {
    font-size: 1.5rem;
    // color: rgba($nearly-black, 0.8);
    opacity: 0.8;
    margin: 0 1rem 0 0 !important;
    &--faded {
      opacity: 0.15;
      // color: rgba($nearly-black, 0.15);
    }
  }
  &__header {
    padding: 1.125rem 1.25rem;
  }
  &__item-title {
    font-size: 1.105rem;
    font-family: $heading-font-family;
    line-height: 1.3em;
    font-weight: 300;
    &--inactive {
      text-decoration: line-through;
    }
  }
  &__list-item {
    border-radius: 0.5rem;
    background-color: transparent;
  }
  &__heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 21.25rem;
    margin: 0 auto;
    @include medium-up {
      max-width: 44.5rem;
    }
    &--title {
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0;
      @include medium-up {
        font-size: 1.5rem;
      }
    }
    &--progress {
      font-family: $heading-font-family;
      font-weight: 300;
      color: rgba($nearly-black, 0.63);
      font-size: 0.875rem;
      margin-bottom: 0.75rem;
    }
    &--underline {
      text-decoration: underline;
    }
  }
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
}
.information__image-wrapper :deep(svg path) {
  fill: inherit;
}
</style>
