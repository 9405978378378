<template>
  <single-choice-template
    :alternatives="alternatives"
    :information="true"
    @click="select"
    @showInformation="() => (showDialog = true)"
  >
    <question-text-section
      :heading="heading"
      :body="body"
      progress
    />
    <side-dialog
      v-model="showDialog"
      :title="$t('theme')"
    >
      <div class="focus__side-dialog">
        <div
          v-for="(section, i) in informationAlternatives"
          :key="i"
        >
          <p class="focus__side-dialog--heading">
            {{ section.heading }}
          </p>
          <p class="focus__side-dialog--text">
            {{ section.text }}
          </p>
        </div>
      </div>
    </side-dialog>
  </single-choice-template>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SideDialog from "@/components/dialog/side-dialog.vue";
import { InfoSection } from "@/types";
import { RiskEvaluation, StrategyFocus } from "@/clients";
import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";

interface FocusItem {
  key: StrategyFocus;
  text: string;
  image?: any;
  icon?: string[];
}

export default defineComponent({
  emits: ["select"],
  components: {
    SingleChoiceTemplate,
    QuestionTextSection,
    SideDialog
  },
  extends: Question,
  props: {
    heading: { type: String },
    body: { type: String }
  },
  data: () => ({
    showDialog: false
  }),
  computed: {
    alternatives(): FocusItem[] {
      return [
        {
          text: this.$t("focusStandardTitle"),
          key: StrategyFocus.Standard,
          // eslint-disable-next-line global-require
          image: new URL("/assets/images/logos/sigmastocks-logo-outlined.svg", import.meta.url).href
        },
        {
          text: this.$t("focusGrowthTitle"),
          key: StrategyFocus.Growth,
          icon: ["fal", "chart-line"]
        },
        {
          text: this.$t("focusDividendTitle"),
          key: StrategyFocus.Dividend,
          icon: ["fal", "coins"]
        }
      ];
    },
    informationAlternatives(): InfoSection[] {
      return [
        {
          heading: this.$t("theme"),
          text: this.$t("focusExplanations.focusIntro")
        },
        {
          heading: this.$t("focusExplanations.focusStandardTitle"),
          text: this.$t("focusExplanations.focusStandardBody")
        },
        {
          heading: this.$t("focusExplanations.focusGrowthTitle"),
          text: this.$t("focusExplanations.focusGrowthBody")
        },
        {
          heading: this.$t("focusExplanations.focusDividendTitle"),
          text: this.$t("focusExplanations.focusDividendBody")
        }
      ];
    }
  },
  methods: {
    async select(key: StrategyFocus) {
      this.$emit("select", key);
    }
  }
});
</script>

<style lang="scss" scoped>
.focus {
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
}
</style>
