<template>
  <SideDialog
    :model-value="modelValue"
    :title="$t('terminateAccount')"
    @update:model-value="() => resetAndClose()"
  >
    <div
      v-if="hasPortfolios"
      class="terminate-dialog"
    >
      <div class="terminate-dialog__header-text">
        {{ t("profile.terminate-account.terminate-account.activePortfolios") }}
      </div>
      <div class="terminate-dialog__body-text">
        {{ t("profile.terminate-account.terminate-account.activePortfoliosBody") }}
      </div>
      <div class="terminate-dialog__button-wrapper">
        <CommonButton
          data-jest="cancel-button"
          @click="() => resetAndClose()"
        >
          {{ $t("ok") }}
        </CommonButton>
      </div>
    </div>
    <div
      v-else-if="isDeleting"
      class="terminate-dialog"
    >
      <div class="terminate-dialog__body-text">
        {{ t("profile.terminate-account.terminate-account.terminatingAccount") }}
      </div>
      <LoadingSpinner />
    </div>
    <div
      v-else
      class="terminate-dialog"
    >
      <div>
        <div class="terminate-dialog__header-text">
          {{ t("profile.terminate-account.terminate-account.terminateAccountQuestion") }}
        </div>
        <div class="terminate-dialog__body-text">
          {{ t("profile.terminate-account.terminate-account.startAgain") }}
        </div>
        <div class="terminate-dialog__button-wrapper">
          <CommonButton
            size-large
            secondary
            @click="resetAndClose"
          >
            {{ $t("no") }}
          </CommonButton>
          <CommonButton
            size-large
            @click="terminateAccount"
          >
            {{ $t("profile.terminate-account.terminate-account.yesTerminate") }}
          </CommonButton>
        </div>
      </div>
    </div>
  </SideDialog>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import { UserAction } from "@/store/user/action-definitions";
import { UserMutation } from "@/store/user/mutations";

import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";

const { t } = useI18n();
const model = defineModel<boolean>({ required: true });

const store = useStore();
const hasPortfolios = computed(() => store.state.navigationStore.portfolios.length > 0);

const router = useRouter();

const isDeleting = ref(false);

async function terminateAccount(): Promise<void> {
  isDeleting.value = true;
  await store.dispatch(UserAction.terminateAccount);
  store.commit(UserMutation.setToken, undefined);
  await router.push({ name: "login" });
  store.dispatch(
    UserAction.addSnackbarMessage,
    t("profile.terminate-account.terminate-account.terminated"),
  );
}
function resetAndClose(): void {
  model.value = false;
}
</script>

<style lang="scss" scoped>
.terminate-dialog {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  &__header-text {
    font-size: 1.125rem;
    color: rgba($nearly-black, 0.8);
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  &__body-text {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    font-weight: 300;
    &--underline {
      text-decoration: underline;
    }
  }
  &__email {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: 600;
    color: rgba($nearly-black, 0.8);
  }
  &__button-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-row-gap: 1rem;
    width: 100%;
  }
}
</style>
