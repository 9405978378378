import { MutationTree } from "vuex";

import Vue from "vue";
import { questionPaths } from "@/config/signup";
import {
  PdfMode,
  QuestionProgress,
  SignupFlowModification,
  SignupNavigationState,
  SignupQuestionPaths
} from "./types";

import { SignupFlowModificationType } from "../../config/signup-modification";

export const getSignupNavigationInitialState = (): SignupNavigationState => ({
  trackingGuid: undefined,
  questionProgress: { partNumber: 1, questionNumber: 0 },
  showProgressBar: false,
  signupPaths: { questionPaths: questionPaths.map((x) => x.paths) },
  signupFlowModifications: [],
  currentPathKey: undefined,
  additionalQuestions: undefined,
  pdfMode: PdfMode.Signup,
  progressHeadings: undefined
});

export const SignupNavigationMutation = {
  setQuestionProgress: "setQuestionProgress",
  showProgressBar: "showProgressBar",
  setMode: "setMode",
  setQuestionPaths: "setQuestionPaths",
  setAdditionalQuestionPaths: "setAdditionalQuestionPaths",
  setQuestionPart: "setQuestionPart",
  addSignupFlowModification: "addSignupFlowModification",
  setNestedSignupFlowModification: "setNestedSignupFlowModification",
  removeSignupFlowModification: "removeSignupFlowModification",
  setCurrentPathKey: "setCurrentPathKey",
  setPdfMode: "setPdfMode",
  setNavigationState: "setNavigationState",
  setProgressHeadings: "setProgressHeadings"
};
export const mutations: MutationTree<SignupNavigationState> = {
  [SignupNavigationMutation.setNavigationState](state, payload: SignupNavigationState) {
    Object.assign(state, payload);
  },
  [SignupNavigationMutation.setPdfMode](state, payload: PdfMode) {
    state.pdfMode = payload;
  },
  [SignupNavigationMutation.setCurrentPathKey](state, payload: string) {
    state.currentPathKey = payload;
  },
  [SignupNavigationMutation.setQuestionProgress](state, payload: QuestionProgress) {
    state.questionProgress = payload;
  },
  [SignupNavigationMutation.showProgressBar](state, payload: boolean) {
    state.showProgressBar = payload;
  },
  [SignupNavigationMutation.setQuestionPaths](state, value: SignupQuestionPaths) {
    const valueCopy = { questionPaths: value.questionPaths.map((x) => [...x]) };
    state.signupPaths = valueCopy;
  },
  [SignupNavigationMutation.setAdditionalQuestionPaths](state, value: any) {
    state.additionalQuestions = value;
  },
  [SignupNavigationMutation.setQuestionPart](state, value: { index: number; paths: string[] }) {
    state.signupPaths.questionPaths[value.index] = value.paths;
  },
  [SignupNavigationMutation.addSignupFlowModification](state, value: SignupFlowModification) {
    state.signupFlowModifications = [...state.signupFlowModifications, value];
  },
  [SignupNavigationMutation.removeSignupFlowModification](
    state,
    value: { type: SignupFlowModificationType; index: string | undefined }
  ) {
    const index = state.signupFlowModifications.findIndex(
      (x) => x.modification === value.type && (value.index === undefined || x.index === value.index)
    );
    if (index !== -1) {
      state.signupFlowModifications.splice(index, 1);
    }
  },
  [SignupNavigationMutation.setNestedSignupFlowModification](
    state,
    payload: {
      modification: SignupFlowModificationType;
      nestedModification: SignupFlowModification;
    }
  ) {
    const modification = state.signupFlowModifications.find(
      (x) => x.modification === payload.modification
    );
    if (modification) {
      if (modification.nestedModifications === undefined) {
        modification.nestedModifications = [];
      }
      modification.nestedModifications.push(payload.nestedModification);
    }
  },
  [SignupNavigationMutation.setProgressHeadings](state, headings: string[]) {
    state.progressHeadings = headings;
  }
};
