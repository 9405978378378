<template>
  <div class="mobile-menu__placeholder">
    <div class="mobile-menu">
      <div
        v-if="$store.getters.isTopLevel"
        class="mobile-menu__back-button"
      />
      <v-btn
        v-else-if="backRoute && backRoute.name !== 'overview'"
        :to="backRoute"
        icon
        exact
        variant="flat"
        size="x-large"
        class="mobile-menu__back-button"
      >
        <icon :icon="['fal', 'chevron-left']" />
      </v-btn>
      <div
        v-else
        class="mobile-menu__back-button"
      />
      <v-btn
        v-if="$store.getters.isTopLevel"
        class="mobile-menu__user-button"
        color="#fff"
        variant="text"
      >
        <icon :icon="['fal', 'user']" />
        <span class="mobile-menu__user-name"> {{ $store.getters.firstName }} </span>
      </v-btn>

      <h1
        v-else
        class="mobile-menu__heading"
      >
        {{ $store.state.navigationStore.mobileHeading }}
      </h1>
      <v-menu
        v-if="$vuetify.display.smAndDown"
        v-model="showDropdown"
        :close-on-content-click="false"
        :z-index="14"
        transition="fade-transition"
      >
        <template #activator="{ props }">
          <v-btn
            icon
            variant="flat"
            size="x-large"
            class="mobile-menu__hamburger-button"
            data-cy="mobileMenuButton"
            v-bind="props"
          >
            <v-badge
              :model-value="unreadMessagesCount > 0"
              color="#FF0000"
              dot
            >
              <icon :icon="['fal', 'bars']"
            /></v-badge>
          </v-btn>
        </template>
        <dropdown
          @click-referrals="
            () => {
              showDropdown = false;
              showReferralsDialog();
            }
          "
          @click-signout="$emit('click-signout')"
          @close="showDropdown = false"
        />
      </v-menu>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RouteLocation } from "vue-router";
import { mapGetters } from "vuex";
import { UserMutation } from "@/store/user/mutations";
import Dropdown from "./dropdown.vue";

export default defineComponent({
  emits: ["click-signout"],
  components: {
    Dropdown
  },
  data: () => ({
    showDropdown: false
  }),
  computed: {
    ...mapGetters(["unreadMessagesCount"]),
    backRoute(): RouteLocation | undefined {
      const { backRoute } = this.$store.state.navigationStore;
      return backRoute;
    }
  },
  methods: {
    showReferralsDialog() {
      this.$store.commit(UserMutation.setShowReferralsDialog, true);
    }
  }
});
</script>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  background-color: $nearly-black;
  color: white;
  box-shadow: 0 0.1875rem 0.5rem rgba(0, 0, 0, 0.16);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 3.75rem;
  align-items: center;
  margin-bottom: 5rem;
  z-index: 13;
  &__back-button {
    background-color: transparent;
    color: #959595;
    font-size: 1.25rem;
  }
  &__hamburger-button {
    font-size: 1.5rem;
    color: #808080;
    justify-self: flex-end;
    background-color: transparent;
  }
  &__heading {
    font-size: 1.25rem;
    font-weight: 500;
  }
  &__logo {
    width: 5.375rem;
    height: 5.375rem;
    transform: translate(-1rem);
    path {
      fill: #fff;
      opacity: 0.25;
    }
  }
  &__placeholder {
    height: 3.75rem;
  }
  &__user-button {
    font-size: 1rem;
    font-family: $heading-font-family;
    letter-spacing: normal;
    text-transform: none;
  }
  &__user-name {
    margin-left: 0.75rem;
    font-size: 1.125rem;
  }
}
</style>
