<template>
  <tax-liable-usa @next="next" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TaxLiableUsa from "@/views/common-signup/questions/aml/tax-liable-usa.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupFlowModificationType } from "@/config/signup-modification";

export default defineComponent({
  components: { TaxLiableUsa },
  methods: {
    async next(key: string) {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.TaxLiableDenial,
        include: key === "isTaxLiable"
      });
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
