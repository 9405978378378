import type { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from "vue-router";
import store from "@/store";

import { CURRENT_ESG_PATH_KEY } from "@/config/esg";

const Sustainability = () =>
  import("../views/common-signup/questions/preferences/sustainability.vue");
const SustainableInvestment = () =>
  import("../views/common-signup/questions/preferences/sustainable-investment.vue");
const SustainableMethod = () =>
  import("../views/common-signup/questions/preferences/sustainable-method.vue");
const SustainableInfo = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice-info.vue");
const SustainableChoice = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice.vue");
const SustainableChoiceVerify = () =>
  import("../views/common-signup/questions/preferences/sustainable-choice-verify.vue");
const NegativeImpact = () => import("../views/common-signup/questions/preferences/negative-impact.vue");
const SummarySign = () => import("../views/esg/summary-sign.vue");

const Congratulations = () => import("../views/esg/congratulations.vue");

const Esg = () => import("../views/esg/esg.vue");

export default [
  {
    path: "esg",
    name: "esg",
    component: Esg,

    // Always allow the user to go to return to a page they have previously visited.
    // If they try to go to any other question, put them on the last visited path.
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (path?: RouteLocationRaw) => void,
    ) => {
      const { getters } = store as any;
      if (to.path.endsWith("/esg")) {
        // This check is here to avoid infinite recursion.
        next();
      } else {
        const latestVisitedPath = sessionStorage.getItem(CURRENT_ESG_PATH_KEY);
        if (latestVisitedPath) {
          const latestVisitedQuestion = getters.questionProgress(latestVisitedPath);
          const toQuestion = getters.questionProgress(to.path);
          const hasVisitedToQuestion
            = latestVisitedQuestion
            && toQuestion
            && (toQuestion.partNumber < latestVisitedQuestion.partNumber
            || (toQuestion.partNumber === latestVisitedQuestion.partNumber
            && toQuestion.questionNumber <= latestVisitedQuestion.questionNumber));

          if (hasVisitedToQuestion) {
            next();
          } else {
            // The user tries to access a question which doesn't exist or which
            // they haven't reached using the normal flow. Put them on the latest visited path.
            next({ params: to.params, path: latestVisitedPath, replace: true });
          }
        }
      }
    },
    children: [
      { path: "sustainability", component: Sustainability },
      {
        path: "sustainable-investment",
        component: SustainableInvestment,
        name: "esg-sustainable-investment",
      },
      { path: "sustainable-method", component: SustainableMethod },
      { path: "negative-impact", component: NegativeImpact },
      { path: "sustainable-info", component: SustainableInfo },
      { path: "sustainable-choice", component: SustainableChoice },
      { path: "sustainable-choice-verify", component: SustainableChoiceVerify },
      { path: "summary-sign", component: SummarySign },
      { path: "congratulations", component: Congratulations },
      {
        // Catch-all
        path: "*",
      },
    ],
  },
] as RouteRecordRaw[];
