<template>
  <div style="display: flex; align-items: center; width: 100%">
    <div
      v-if="bar"
      style="width: 0.1875rem; height: 2rem; background: #2b2b2b; margin-right: 0.5rem"
    ></div>
    <div class="pension-listing__wrapper">
      <div class="pension-listing__row">
        <div>{{ insuranceHolderName ? insuranceHolderName : insuranceName }}</div>
        <div style="font-size: 0.875rem; font-weight: lighter">
          {{ totalValue.toLocaleString("sv-SE") }} kr
        </div>
      </div>
      <div class="pension-listing__row">
        <div style="font-size: 0.8125rem; font-weight: lighter">
          {{ insuranceNumber ? insuranceNumber : "-" }}
        </div>
        <div
          v-if="showCompany"
          style="font-size: 0.8125rem; font-weight: lighter"
        >
          {{ insuranceCompany ? name : "-" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import insuranceCompanies from "@/config/insurance-companies";

export default defineComponent({
  props: {
    insuranceHolderName: {
      type: String,
      required: false,
      default: "-"
    },
    insuranceName: {
      type: String,
      required: false,
      default: "-"
    },
    insuranceNumber: {
      required: false,
      default: "-"
    },
    insuranceCompany: {
      type: String,
      required: false,
      default: "-"
    },
    totalValue: {
      type: Number,
      required: true
    },
    showCompany: {
      type: Boolean,
      required: false,
      default: true
    },
    bar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    name(): string | undefined {
      if (this.insuranceCompany) {
        return insuranceCompanies[this.insuranceCompany];
      }
      return "";
    }
  }
});
</script>

<style lang="scss" scoped>
.pension-listing {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
}
</style>
