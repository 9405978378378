export const UserAction = {
  loadCurrentUser: "loadCurrentUser",
  prepareUpdateLegalEntityContact: "prepareUpdateLegalEntityContact",
  completeUpdateLegalEntityContact: "completeUpdateLegalEntityContact",
  prepareUpdateLegalEntityEconomy: "prepareUpdateLegalEntityEconomy",
  completeUpdateLegalEntityEconomy: "completeUpdateLegalEntityEconomy",
  updateLegalEntitySpar: "updateLegalEntitySpar",
  loadNotificationSettings: "loadNotificationSettings",
  updateNotificationSettings: "updateNotificationSettings",
  completeUpdateKyc: "completeUpdateKyc",
  authenticateAndLogIn: "authenticateAndLogIn",
  addSnackbarMessage: "addSnackbarMessage",
  addSnackbarMessageWithDuration: "addSnackbarMessageWithDuration",
  loadMessages: "loadMessages",
  updateMessagesRead: "updateMessagesRead",
  loadPdfList: "loadPdfList",
  loadPortfolioAgreements: "loadPortfolioAgreements",
  loadPdf: "loadPdf",
  createTokenTimeoutListener: "createTokenTimeoutListener",
  getQuarterlyReport: "getQuarterlyReport",
  loadTaxBaseAndManualPdfs: "loadTaxBaseAndManualPdfs",
  loadBanner: "loadBanner",
  getMissingOrErrorAgreements: "getMissingOrErrorAgreements",
  signAgreements: "signAgreements",
  prepareSignAgreements: "prepareSignAgreements",
  createCustomReport: "createCustomReport",
  prepareUpdateLegalEntityYearly: "prepareUpdateLegalEntityYearly",
  completeUpdateLegalEntityYearly: "completeUpdateLegalEntityYearly",
  updateLegalEntityYearly: "updateLegalEntityYearly",
  loadInsuranceNumbers: "loadInsuranceNumbers",
  getCompanyRepresentatives: "getCompanyRepresentatives",
  hidePensionDialog: "hidePensionDialog",
  addAffiliateCode: "addAffiliateCode",
  addCompanyRepresentative: "addCompanyRepresentative",
  getPreviewDocument: "getPreviewDocument",
  prepareUpdateKyc: "prepareUpdateKyc",
  getCurrentAgreementPdf: "getCurrentAgreementPdf",
  loadStaticAgreements: "loadStaticAgreements",
  prepareUpdateLegalEntityYearlyCompany: "prepareUpdateLegalEntityYearlyCompany",
  completeUpdateLegalEntityYearlyCompany: "completeUpdateLegalEntityYearlyCompany",
  updateLegalEntityYearlyCompany: "updateLegalEntityYearlyCompany",
  prepareUpdateLegalEntityEconomyCompany: "prepareUpdateLegalEntityEconomyCompany",
  completeUpdateLegalEntityCompany: "completeUpdateLegalEntityCompany",
  prepareUpdateLegalEntityCompanyPrincipals: "prepareUpdateLegalEntityCompanyPrincipals",
  terminateAccount: "terminateAccount",
  getHubspotChatToken: "getHubspotChatToken",
  signOut: "signOut",
};
