<template>
  <single-choice
    :alternatives="alternatives"
    :information="true"
    @click="buttonClick"
    @showInformation="() => (showDialog = true)"
  >
    <information-text-section :heading="heading" :body="body">
      <icon />
    </information-text-section>
    <side-dialog v-model="showDialog" :title="$t('riskHeading')">
      <div class="risk-introduction__side-dialog">
        <p class="risk-introduction__side-dialog--heading">
          {{ $t("riskInformationHeading") }}
        </p>
        <p class="risk-introduction__side-dialog--text">
          {{ $t("riskInformation1") }}
        </p>
        <p class="risk-introduction__side-dialog--text">
          {{ $t("riskInformation2") }}
        </p>
      </div>
    </side-dialog>
  </single-choice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SideDialog from "@/components/dialog/side-dialog.vue";
import InformationLink from "@/views/signup/information-link.vue";
import Icon from "@/assets/images/signup/heart-rate-light.svg";
import Question from "@/views/signup/templates/question";
import SingleChoice from "../../../signup/templates/single-choice.vue";
import InformationTextSection from "../../../signup/information-text-section.vue";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    InformationLink,
    SideDialog,
    Icon
  },
  extends: Question,
  props: { heading: { type: String, required: true }, body: { type: String, required: true } },

  data: () => ({
    showDialog: false
  }),
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    buttonClick() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss">
.risk-introduction {
  &__information-link-wrapper {
    justify-content: center;
    margin-top: 1.75rem;
    width: 100%;
    display: flex;
  }
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
}
</style>
