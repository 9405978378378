import { GetterTree } from "vuex";
import { RootState } from "../../types";
import { PensionInfo, PensionMap, PensionSignupState } from "./types";

// Source: https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
function groupBy<T>(data: T[], key: string, key2: string): Record<string, T[]> {
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  return data.reduce((storage, item) => {
    // get the first instance of the key by which we're grouping
    const group = item[key][key2];

    // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
    storage[group] = storage[group] || [];

    // add this item to its group within `storage`
    storage[group].push(item);

    // return the updated storage to the reduce function, which will then loop through the next
    return storage;
  }, {}); // {} is the initial value of the storage
}

export const getters: GetterTree<PensionSignupState, RootState> = {
  pensionTotalValue:
    (state) =>
      (externalId: string): number =>
        state.selectablePensions[externalId].pension.currentValue,
  mergeablePensions(state) {
    const selectablePensions = state.selectablePensions as PensionMap;
    const selectedPensions = (Object.values(selectablePensions) as PensionInfo[]).filter(
      (x) => x.selected && !x.confirmedActivePayment
    );
    const grouped = groupBy(selectedPensions, "pension", "insuranceHolderOrgNumber");
    // Not sure why Object.fromEntries is needed, but using only Object.entries returns a
    // weird structure
    return Object.fromEntries(Object.entries(grouped).filter((x) => x[1].length > 1));
  },
  uniqueInsuraceHolderNames(state, getters): (string | undefined)[] {
    const uniqueInsuranceHolders = [
      ...new Set(
        Object.values(getters.selectedPensionsInfo as PensionInfo[]).map(
          (x: PensionInfo) => x.pension.insuranceHolderName as string
        )
      )
    ];
    return uniqueInsuranceHolders;
  },
  selectedPensionsInfo(state): PensionInfo[] | undefined {
    const { selectablePensions } = state;
    if (selectablePensions) {
      return Object.values(selectablePensions).filter((x: any) => x.selected);
    }
    return undefined;
  }
};
