<template>
  <div class="politically-exposed-country">
    <single-choice
      :alternatives="navigationAlternatives"
      :disabled="!chosenCountry"
      @click="goToNext"
    >
      <question-text-section
        :heading="$t('create-portfolio.questions.aml.politically-exposed-country.heading1')"
      />
      <div class="politically-exposed-title__dropdown">
        <country-selector v-model="chosenCountry" />
      </div>
    </single-choice>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { NavigationAlternative } from "@/types/signup";
import { softBlue } from "@/styles/colors";
import Question from "@/views/signup/templates/question";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import CountrySelector from "@/components/country-selector.vue";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice,
    CountrySelector
  },
  extends: Question,
  data() {
    return {
      showErrorMessage: false,
      associated: this.$router.currentRoute.value.path.includes("associated"),
      softBlue
    };
  },
  computed: {
    chosenCountry: {
      get(): string | undefined {
        return this.associated
          ? this.$store.state.signupStore.associatedPepInfo.country
          : this.$store.state.signupStore.pepInfo.country;
      },
      set(value: string) {
        this.associated
          ? this.$store.commit(SignupMutation.setAssociatedPepCountry, value)
          : this.$store.commit(SignupMutation.setPepCountry, value);
      }
    },
    navigationAlternatives(): Array<NavigationAlternative> {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
<style lang="scss">
.politically-exposed-country {
  height: 100%;
  width: 100%;
  &__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 18.75rem;
    width: 100%;
  }
}
</style>
