import vue from "vue";
import type { MutationTree } from "vuex";
import type { PortfolioState } from "./types";
import type {
  Backcast,
  DateEvent,
  Deposit,
  DetailedHolding,
  ExcludedCompany,
  GroupedPortfolioAgreements,
  HoldingSummary,
  Market,
  MongstadPaiStats,
  PensionRiskProfile,
  Portfolio,
  PortfolioBase,
  PortfolioDividend,
  RiskLevel,
  SavingsGoal,
  StrategyFocus,
  SwishTransactionStatus,
  TiedAgentPortfolioThemeType,
  TimeToWithdraw,
  TradingDay,
  Transaction,
  TransactionEvent,
  WithdrawalResponse,
} from "@/clients";
import {
  PerformanceResponse,
  PortfolioSettings,
  Strategy,
} from "@/clients";
import type { DepositState, DiscardedSectors, EsgFilters, EsgPais, Savings } from "@/types/portfolio";

export const PortfolioMutation = {
  setDeposits: "setDeposits",
  setWithdrawAmount: "setWithdrawAmount",
  setHoldingOverlay: "setHoldingOverlay",
  setIsLoadingChartData: "setIsLoadingChartData",
  setActivePortfolioBase: "setActivePortfolioBase",
  setIsLoadingPortfolioPerformance: "setIsLoadingPortfolioPerformance",
  setSavingsGoal: "setSavingsGoal",
  setTransactionList: "setTransactionList",
  setPortfolioSettings: "setPortfolioSettings",
  setEditedPortfolioSettings: "setEditedPortfolioSettings",
  setEditedSavings: "setEditedSavings",
  setHoldingSummary: "setHoldingSummary",
  isLoadingHoldingSummary: "isLoadingHoldingSummary",
  isLoadingBackcast: "isLoadingBackcast",
  setStrategy: "setStrategy",
  setEditedStrategy: "setEditedStrategy",
  editFocus: "editFocus",
  editTiedAgentPortfolioThemeType: "editTiedAgentPortfolioThemeType",
  editMarket: "editMarket",
  editDiscardedSectors: "editDiscardedSectors",
  editChosenRiskLevel: "editChosenRiskLevel",
  editChosenPensionRiskProfile: "editChosenPensionRiskProfile",
  resetStrategy: "resetStrategy",
  increaseUnsavedEdits: "increaseUnsavedEdits",
  decreaseUnsavedEdits: "decreaseUnsavedEdits",
  resetUnsavedEdits: "resetUnsavedEdits",
  setDividend: "setDividend",
  resetDividend: "resetDividend",
  setIsLoadingPortfolioDividend: "setIsLoadingPortfolioDividend",
  setIsLoadingTransactions: "setIsLoadingTransactions",
  setBackcast: "setBackcast",
  setPortfolioState: "setPortfolioState",
  setTransactionEvents: "setTransactionEvents",
  setNextDateEvent: "setNextDateEvent",
  editTimeToWithdraw: "editTimeToWithdraw",
  setPerformanceError: "setPerformanceError",
  setHoldingsError: "setHoldingsError",
  setTransactionsError: "setTransactionsError",
  setDividendError: "setDividendError",
  setUpdateAgreementsDialogCallback: "setUpdateAgreementsDialogCallback",
  resetDiscardedSectors: "resetDiscardedSectors",
  resetFocus: "resetFocus",
  resetMarket: "resetMarket",
  setSaveSettingsError: "setSaveSettingsError",
  setOngoingWithdrawalSum: "setOngoingWithdrawalSum",
  setShowDepositDialog: "setShowDepositDialog",
  setShowWithdrawalDialog: "setShowWithdrawalDialog",
  setShowDividendDialog: "setShowDividendDialog",
  setShowSavingsGoalSideDialog: "setShowSavingsGoalSideDialog",
  setHasSellWithdrawal: "setHasSellWithdrawal",
  setHasWithdrawalOrderSent: "setHasWithdrawalOrderSent",
  setShowEventsDialog: "setShowEventsDialog",
  setDetailedHolding: "setDetailedHolding",
  setIsLoadingDetailedHolding: "setIsLoadingDetailedHolding",
  setLoadingDetailedHoldingError: "setLoadingDetailedHoldingError",
  setNextTradingDay: "setNextTradingDay",
  setCurrentTab: "setCurrentTab",
  editExludedCompanies: "editExludedCompanies",
  resetExcludedCompanies: "resetExcludedCompanies",
  setYesterdaysTradingDay: "setYesterdaysTradingDay",
  removeDeposit: "removeDeposit",
  removeWithdrawal: "removeWithdrawal",
  setFinsharkPaymentId: "finsharkPaymentId",
  setSwishPaymentReference: "setSwishPaymentReference",
  setSwishTransactionStatus: "setSwishTransactionStatus",
  setSwishToken: "setSwishToken",
  setIndexOverTime: "setIndexOverTime",
  setLoadingIndex: "setLoadingIndex",
  setDepositState: "setDepositState",
  editEsgFilters: "editEsgFilters",
  editEsgPais: "editEsgPais",
  resetEsgFilters: "resetEsgFilters",
  setAllPortfolios: "setAllPortfolios",
  setAvailableCashForWithdrawal: "setAvailableCashForWithdrawal",
  setAmountCanBeWithdrawn: "setAmountCanBeWithdrawn",
  setAgreements: "setAgreements",
  setShowPortfolioCreatedDialog: "setShowPortfolioCreatedDialog",
  resetPortfolioSettings: "resetPortfolioSettings",
  setShowConnectBankSideDialog: "setShowConnectBankSideDialog",
  setLoadingEvents: "setLoadingEvents",
  setOngoingWithdrawals: "setOngoingWithdrawals",
  setLoadingOngoingWithdrawals: "setLoadingOngoingWithdrawals",
  setPaiStats: "setPaiStats",
};

function createEditablePortfolioSettings(portfolioSettings) {
  return portfolioSettings
    ? new PortfolioSettings({
      ...portfolioSettings,
      // Ensure `pauseMonthlySavingsMonths` reflects remaining paused months for the update form.
      // This prevents unintended carryover of old values when changing the monthly amount,
      // as `pauseMonthlySavingsMonths` does not reset after resuming monthly savings.
      pauseMonthlySavingsMonths: portfolioSettings.monthlySavingsPauseMonthsRemaining ?? 0,
    })
    : undefined;
}

interface HoldingsPayload {
  portfolioId: number;
  holdingSummary: HoldingSummary;
}

export const mutations: MutationTree<PortfolioState> = {
  [PortfolioMutation.setLoadingEvents](state, loading: boolean) {
    state.loadingEvents = loading;
  },
  [PortfolioMutation.setTransactionList](state, transactions: Array<Transaction>) {
    state.transactionList = transactions;
  },
  [PortfolioMutation.setIsLoadingPortfolioPerformance](state, isLoading: boolean) {
    state.isLoadingPortfolioPerformance = isLoading;
  },
  [PortfolioMutation.setDeposits](state, deposits: Deposit[]) {
    state.deposits = deposits;
  },
  [PortfolioMutation.setIsLoadingTransactions](state, isLoading: boolean) {
    state.isLoadingTransactions = isLoading;
  },
  [PortfolioMutation.setActivePortfolioBase](state, portfolioBase: PortfolioBase) {
    state.activePortfolioBase = portfolioBase;
  },
  [PortfolioMutation.setPortfolioSettings](state, portfolioSettings: PortfolioSettings) {
    state.portfolioSettings = portfolioSettings;
    state.editedPortfolioSettings = createEditablePortfolioSettings(portfolioSettings);
  },
  [PortfolioMutation.setEditedSavings](state, savings?: Savings) {
    if (!state.editedPortfolioSettings) {
      throw new Error("No edited portfolio settings in state");
    }
    if (state.portfolioSettings) {
      const editedPortfolioSettings = new PortfolioSettings({
        ...state.editedPortfolioSettings,
        ...savings,
      });
      state.editedPortfolioSettings = editedPortfolioSettings;
    }
  },
  [PortfolioMutation.setEditedPortfolioSettings](
    state,
    editedPortfolioSettings?: PortfolioSettings,
  ) {
    state.editedPortfolioSettings = editedPortfolioSettings;
  },
  [PortfolioMutation.setSavingsGoal](state, savingsGoal: SavingsGoal) {
    state.savingsGoal = savingsGoal;
  },
  [PortfolioMutation.setWithdrawAmount](state, value: number) {
    state.withdrawAmount = value;
  },
  [PortfolioMutation.setHoldingOverlay](state, value: boolean) {
    state.dismissedHoldingOverlay = value;
  },
  [PortfolioMutation.setHoldingSummary](state, payload: HoldingsPayload) {
    if (payload) {
      const { holdingSummary, portfolioId } = payload;
      state.holdingSummaries[portfolioId] = holdingSummary;
    }
  },
  [PortfolioMutation.isLoadingHoldingSummary](state, isLoading: boolean) {
    state.isLoadingHoldingSummary = isLoading;
  },
  [PortfolioMutation.isLoadingBackcast](state, isLoading: boolean) {
    state.isLoadingBackcast = isLoading;
  },
  [PortfolioMutation.resetStrategy](state) {
    state.editedStrategy = state.strategy;
  },
  [PortfolioMutation.resetPortfolioSettings](state) {
    state.editedPortfolioSettings = createEditablePortfolioSettings(state.portfolioSettings);
  },
  [PortfolioMutation.setStrategy](state, strategy: Strategy) {
    state.strategy = strategy;
    if (!state.editedStrategy) {
      state.editedStrategy = strategy;
    }
  },
  [PortfolioMutation.setEditedStrategy](state, strategy: Strategy) {
    state.editedStrategy = strategy;
  },
  [PortfolioMutation.editFocus](state, focus: StrategyFocus) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    const editedStrategy = new Strategy({ ...state.editedStrategy });
    editedStrategy.focus = focus;
    state.editedStrategy = editedStrategy;
  },
  [PortfolioMutation.editTiedAgentPortfolioThemeType](state, theme: TiedAgentPortfolioThemeType) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    const editedStrategy = new Strategy({ ...state.editedStrategy });
    editedStrategy.tiedAgentPortfolioThemeType = theme;
    state.editedStrategy = editedStrategy;
  },
  [PortfolioMutation.editMarket](state, market: Market) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    const editedStrategy = new Strategy({ ...state.editedStrategy });
    editedStrategy.marketFocus = market;
    state.editedStrategy = editedStrategy;
  },
  [PortfolioMutation.editDiscardedSectors](state, discardedSectors: DiscardedSectors) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    state.editedStrategy = new Strategy({ ...state.editedStrategy, ...discardedSectors });
  },
  [PortfolioMutation.editEsgFilters](state, esgFilters: EsgFilters) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    state.editedStrategy = new Strategy({ ...state.editedStrategy, ...esgFilters });
  },
  [PortfolioMutation.editEsgPais](state, esgPais: EsgPais) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    state.editedStrategy = new Strategy({ ...state.editedStrategy, esgPais });
  },
  [PortfolioMutation.editChosenRiskLevel](state, level: RiskLevel) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    const editedStrategy = new Strategy({ ...state.editedStrategy });
    editedStrategy.chosenRiskLevel = level;
    state.editedStrategy = editedStrategy;
  },
  [PortfolioMutation.editChosenPensionRiskProfile](state, profile: PensionRiskProfile) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    const editedStrategy = new Strategy({ ...state.editedStrategy });
    editedStrategy.chosenPensionRiskProfile = profile;
    state.editedStrategy = editedStrategy;
  },
  [PortfolioMutation.increaseUnsavedEdits](state) {
    state.unsavedEdits += 1;
  },
  [PortfolioMutation.decreaseUnsavedEdits](state) {
    state.unsavedEdits -= 1;
  },
  [PortfolioMutation.resetUnsavedEdits](state) {
    state.unsavedEdits = 0;
  },
  [PortfolioMutation.setDividend](
    state,
    { year, dividend }: { year: number; dividend: PortfolioDividend },
  ) {
    state.dividend[year] = dividend;
  },
  [PortfolioMutation.resetDividend](state) {
    state.dividend = [];
  },
  [PortfolioMutation.setIsLoadingPortfolioDividend](state, loading: boolean) {
    state.isLoadingPortfolioDividend = loading;
  },
  [PortfolioMutation.setBackcast](state, backcast: Backcast) {
    state.backcast = backcast;
  },
  [PortfolioMutation.setPortfolioState](state, stateChange: PortfolioState) {
    Object.assign(state, stateChange);
  },
  [PortfolioMutation.editTimeToWithdraw](state, timeToWithdraw: TimeToWithdraw) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    const editedStrategy = new Strategy({ ...state.editedStrategy });
    editedStrategy.timeToWithdraw = timeToWithdraw;
    state.editedStrategy = editedStrategy;
  },
  [PortfolioMutation.setTransactionEvents](state, events: TransactionEvent[]) {
    state.transactionEvents = events;
  },
  [PortfolioMutation.setNextDateEvent](state, event: DateEvent) {
    state.nextDateEvent = event;
  },
  [PortfolioMutation.setPerformanceError](state, value: boolean) {
    state.performanceError = value;
  },
  [PortfolioMutation.setHoldingsError](state, value: boolean) {
    state.holdingsError = value;
  },
  [PortfolioMutation.setTransactionsError](state, value: boolean) {
    state.transactionsError = value;
  },
  [PortfolioMutation.setDividendError](state, value: boolean) {
    state.dividendError = value;
  },
  [PortfolioMutation.setSaveSettingsError](state, value: boolean) {
    state.saveSettingsError = value;
  },
  [PortfolioMutation.setUpdateAgreementsDialogCallback](state, value: any) {
    state.updateAgreementsDialogCallback = value;
  },
  [PortfolioMutation.resetDiscardedSectors](state) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (state.strategy) {
      const discardedSectors = state.strategy as DiscardedSectors;
      state.editedStrategy = new Strategy({ ...state.editedStrategy, ...discardedSectors });
    }
  },
  [PortfolioMutation.resetFocus](state) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (state.strategy) {
      const { focus } = state.strategy;
      const editedStrategy = new Strategy({ ...state.editedStrategy });
      editedStrategy.focus = focus;
      state.editedStrategy = editedStrategy;
    }
  },
  [PortfolioMutation.resetMarket](state) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (state.strategy) {
      const { marketFocus } = state.strategy;
      const editedStrategy = new Strategy({ ...state.editedStrategy });
      editedStrategy.marketFocus = marketFocus;
      state.editedStrategy = editedStrategy;
    }
  },
  [PortfolioMutation.resetEsgFilters](state) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (state.strategy) {
      const esgFilters = state.strategy as EsgFilters;
      state.editedStrategy = new Strategy({ ...state.editedStrategy, ...esgFilters });
    }
  },
  [PortfolioMutation.setOngoingWithdrawalSum](state, val: number) {
    state.ongoingWithdrawalSum = val;
  },
  [PortfolioMutation.setOngoingWithdrawals](state, val: WithdrawalResponse[]) {
    state.ongoingWithdrawals = val;
  },
  [PortfolioMutation.setHasSellWithdrawal](state, val: boolean) {
    state.hasSellWithdrawal = val;
  },
  [PortfolioMutation.setHasWithdrawalOrderSent](state, val: boolean) {
    state.hasWithdrawalOrderSent = val;
  },
  [PortfolioMutation.setShowDepositDialog](state, val: boolean) {
    state.showDepositDialog = val;
  },
  [PortfolioMutation.setShowWithdrawalDialog](state, val: boolean) {
    state.showWithdrawalDialog = val;
  },
  [PortfolioMutation.setShowDividendDialog](state, val: boolean) {
    state.showDividendDialog = val;
  },
  [PortfolioMutation.setShowSavingsGoalSideDialog](state, val: boolean) {
    state.showSavingsGoalSideDialog = val;
  },
  [PortfolioMutation.setShowEventsDialog](state, val: boolean) {
    state.showEventsDialog = val;
  },
  [PortfolioMutation.setDetailedHolding](state, val: DetailedHolding) {
    state.holdingDetails = [...(state.holdingDetails as DetailedHolding[]), val];
  },
  [PortfolioMutation.setIsLoadingDetailedHolding](state, val: boolean) {
    state.isLoadingHoldingDetails = val;
  },
  [PortfolioMutation.setLoadingDetailedHoldingError](state, val: boolean) {
    state.loadingHoldingDetailsError = val;
  },
  [PortfolioMutation.setNextTradingDay](state, val: TradingDay) {
    state.nextTradingDay = val;
  },
  [PortfolioMutation.setCurrentTab](state, tab: number) {
    state.currentTab = tab;
  },
  [PortfolioMutation.editExludedCompanies](state, companies: ExcludedCompany[]) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    const editedStrategy = new Strategy({ ...state.editedStrategy });
    editedStrategy.excludedCompanies = [...companies];
    state.editedStrategy = editedStrategy;
  },
  [PortfolioMutation.resetExcludedCompanies](state) {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (state.strategy) {
      const { excludedCompanies } = state.strategy;
      const editedStrategy = new Strategy({ ...state.editedStrategy });
      editedStrategy.excludedCompanies = excludedCompanies;
      state.editedStrategy = editedStrategy;
    }
  },
  [PortfolioMutation.setYesterdaysTradingDay](state, val: TradingDay) {
    state.yesterdaysTradingDay = val;
  },
  [PortfolioMutation.removeDeposit](state, depositExternalId: string) {
    state.transactionEvents = [
      ...(state.transactionEvents as TransactionEvent[]).filter(
        t => t.depositExternalId !== depositExternalId,
      ),
    ];
  },
  [PortfolioMutation.removeWithdrawal](state, withdrawalId: number) {
    state.transactionEvents = [
      ...(state.transactionEvents as TransactionEvent[]).filter(
        t => t.withdrawalId !== withdrawalId,
      ),
    ];
  },
  [PortfolioMutation.setFinsharkPaymentId](state, val: string) {
    state.finsharkPaymentId = val;
  },
  [PortfolioMutation.setSwishPaymentReference](state, reference: string) {
    state.swishPaymentReference = reference;
  },
  [PortfolioMutation.setSwishTransactionStatus](state, transactionStatus: SwishTransactionStatus) {
    state.swishTransactionStatus = transactionStatus;
  },
  [PortfolioMutation.setSwishToken](state, token: string | undefined) {
    state.swishToken = token;
  },
  [PortfolioMutation.setDepositState](state, val: DepositState) {
    state.depositState = val;
  },
  [PortfolioMutation.setAllPortfolios](state, portfolios: Portfolio[]) {
    state.allPortfolios = portfolios;
  },
  [PortfolioMutation.setAvailableCashForWithdrawal](state, cash: number) {
    state.availableCashForWithdrawal = cash;
  },
  [PortfolioMutation.setAmountCanBeWithdrawn](state, cash: number) {
    state.amountCanBeWithdrawn = cash;
  },
  [PortfolioMutation.setAgreements](state, agreements: GroupedPortfolioAgreements) {
    state.agreements = agreements;
  },
  [PortfolioMutation.setShowPortfolioCreatedDialog](state, value: boolean) {
    state.showPortfolioCreatedDialog = value;
  },
  [PortfolioMutation.setShowConnectBankSideDialog](state, value: boolean) {
    state.showConnectBankSideDialog = value;
  },
  [PortfolioMutation.setLoadingOngoingWithdrawals](state, value: boolean) {
    state.loadingOngoingWithdrawals = value;
  },
  [PortfolioMutation.setPaiStats](state, value: MongstadPaiStats) {
    state.paiStats = value;
  },
};
