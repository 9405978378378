import { FeeType, Market } from "@/clients";

const managementFeeConfig = {
  [FeeType.OriginalFees]: {
    [Market.Standard]: 0.35,
    [Market.Sweden]: 0.35,
    [Market.Europe]: 0.35,
    [Market.Us]: 0.35,
    [Market.SwedenOld]: 0.35,
  },
  [FeeType.SwedenPortfolioFees]: {
    [Market.Standard]: 0.47,
    [Market.Sweden]: 0.35,
    [Market.Europe]: 0.47,
    [Market.Us]: 0.47,
    [Market.SwedenOld]: 0.35,
  },
  [FeeType.Q22024Fees]: {
    [Market.Standard]: 0.55,
    [Market.Sweden]: 0.4,
    [Market.Europe]: 0.59,
    [Market.Us]: 0.59,
    [Market.SwedenOld]: 0.4,
  },
};

const transactionFeeConfig = {
  [FeeType.OriginalFees]: 0.1,
  [FeeType.SwedenPortfolioFees]: 0.22,
  [FeeType.Q22024Fees]: 0.25,
};

const fxFeeConfig = {
  [FeeType.OriginalFees]: 0,
  [FeeType.SwedenPortfolioFees]: 0,
  [FeeType.Q22024Fees]: 0.25,
};

const idunInsuranceFee = 0.2;

export function getIdunInsuranceFee() {
  return idunInsuranceFee.toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getManagementFeeForMarketFocus(feeType: FeeType | undefined, marketFocus: Market | undefined): string {
  if (feeType !== undefined && marketFocus !== undefined) {
    return managementFeeConfig[feeType][marketFocus].toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return "";
}

export function getManagementFeeForMarketFocusWithoutVat(feeType: FeeType | undefined, marketFocus: Market | undefined): string {
  if (feeType !== undefined && marketFocus !== undefined) {
    return (managementFeeConfig[feeType][marketFocus] / 1.25).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return "";
}

export function getTransactionFeeWithoutVat(feeType: FeeType | undefined): string {
  if (feeType !== undefined) {
    return (transactionFeeConfig[feeType] / 1.25).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return "";
}

export function getTransactionFee(feeType: FeeType | undefined): string {
  if (feeType !== undefined) {
    return (transactionFeeConfig[feeType]).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return "";
}

export function shouldShowFxFee(feeType: FeeType | undefined) {
  return feeType !== undefined && feeType === FeeType.Q22024Fees;
}

export function getFxFee(feeType: FeeType | undefined): string {
  if (feeType !== undefined) {
    return (fxFeeConfig[feeType]).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return "";
}

export function getFxFeeWithoutVat(feeType: FeeType | undefined): string {
  if (feeType !== undefined) {
    return (fxFeeConfig[feeType] / 1.25).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return "";
}

export function getPriceGroup(feeType: FeeType | undefined): string {
  switch (feeType) {
    case FeeType.OriginalFees:
      return "pricegroup_A";
    case FeeType.SwedenPortfolioFees:
      return "pricegroup_B";
    case FeeType.Q22024Fees:
      return "pricegroup_C";
    default:
      throw new Error(`bad feetype ${feeType}`);
  }
}
