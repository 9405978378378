<template>
  <single-choice-template
    :alternatives="alternatives"
    @click="select"
  >
    <question-text-section
      progress
      :heading="heading"
      :body="$t('considerAllPortfolios')"
    />
  </single-choice-template>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { DepositFrequency, DepositSize } from "@/clients";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

interface DepositSizeChoice {
  text: string;
  key: DepositSize;
}

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    interval(): string {
      const { depositFrequency } = this.$store.state.signupStore;
      switch (depositFrequency) {
        case DepositFrequency.OncePerWeek:
          return this.$t("create-portfolio.questions.aml.amount.week");
        case DepositFrequency.OncePerMonth:
          return this.$t("create-portfolio.questions.aml.amount.month");
        case DepositFrequency.OncePerHalfYear:
          return this.$t("create-portfolio.questions.aml.amount.halfYear");
        case DepositFrequency.OncePerYear:
          return this.$t("years");
        default:
          return this.$t("time");
      }
    },
    heading(): string {
      return `${this.$t("create-portfolio.questions.aml.amount.heading")} ${this.interval}?`;
    },
    alternatives(): DepositSizeChoice[] {
      return [
        {
          text: this.$t("create-portfolio.questions.aml.amount.lessThanTenThousand"),
          key: DepositSize.LessThanTenThousand
        },
        {
          text: this.$t("create-portfolio.questions.aml.amount.betweenTenThousandAndFiftyThousand"),
          key: DepositSize.BetweenTenThousandAndFiftyThousand
        },
        {
          text: this.$t(
            "create-portfolio.questions.aml.amount.betweenFiftyThousandAndOneHundredThousand"
          ),
          key: DepositSize.BetweenFiftyThousandAndOneHundredThousand
        },
        {
          text: this.$t("create-portfolio.questions.aml.amount.moreThanOneHundredThousand"),
          key: DepositSize.MoreThanOneHundredThousand
        }
      ];
    }
  },
  methods: {
    select(key: DepositSize): void {
      this.$store.commit(SignupMutation.setDepositAmount, key);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
