<template>
  <Transition
    name="fade"
    mode="out-in"
    :duration="duration"
  >
    <slot />
    <!-- pass down slot content -->
  </Transition>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { TRANSITION_DURATION_MILLIS } from "@/config/general";

const duration = ref<number>(150);

const storedNavigationDuration = localStorage.getItem(TRANSITION_DURATION_MILLIS);
if (storedNavigationDuration) {
  duration.value = Number(storedNavigationDuration);
} else {
  duration.value = 150;
}
</script>
