import type { RouteRecordRaw } from "vue-router";
import { isLoggedInAdmin } from "./common";
import { AdminLegalEntity, ApplicationUser } from "@/clients";

const LoggedInWrapper = () => import("@/views/admin/logged-in-wrapper.vue");
const AdminAppSettings = () => import("@/views/admin/app-settings.vue");
const AdminWithdrawals = () => import("@/views/admin/withdrawals-table.vue");
const AdminLegalEntities = () => import("@/views/admin/users-table.vue");
const AdminCampaigns = () => import("@/views/admin/affiliates/affiliates.vue");
const UserDetails = () => import("@/views/admin/user/user-details.vue");
const AdminMifid = () => import("@/views/admin/mifid.vue");
const AdminCompanies = () => import("@/views/admin/company/companies-table.vue");
const CompanyDetails = () => import("@/views/admin/company/company-details.vue");

const defaultUserData = new AdminLegalEntity();

const adminRoutes = [
  {
    path: "admin",
    component: LoggedInWrapper,
    beforeEnter: isLoggedInAdmin,
    children: [
      {
        path: "app-settings",
        name: "app-settings",
        component: AdminAppSettings,
      },
      {
        path: "withdrawals",
        name: "withdrawals",
        component: AdminWithdrawals,
      },
      {
        path: "campaigns",
        name: "campaigns",
        component: AdminCampaigns,
      },
      {
        path: "persons",
        name: "persons",
        component: AdminLegalEntities,
      },
      {
        path: "companies",
        name: "companies",
        component: AdminCompanies,
      },
      {
        path: "mifid",
        name: "mifid",
        component: AdminMifid,
      },
      {
        path: "",
        name: "users-default",
        component: AdminLegalEntities,
      },
    ],
  },
  {
    path: "admin/person/:id",
    component: LoggedInWrapper,
    beforeEnter: isLoggedInAdmin,
    children: [
      {
        path: "",
        name: "person",
        component: UserDetails,
        props: route => ({
          user: defaultUserData,
          ...route.params,
        }),
      },
    ],
  },
  {
    path: "admin/company/:id",
    component: LoggedInWrapper,
    beforeEnter: isLoggedInAdmin,
    children: [
      {
        path: "",
        name: "company",
        component: CompanyDetails,
        props: route => ({
          user: defaultUserData,
          ...route.params,
        }),
      },
    ],
  },
] as RouteRecordRaw[];

export default adminRoutes;
