<template>
  <div class="congratulations">
    <single-choice
      v-if="!needsManualCheck"
      :alternatives="alternatives"
      @click="goToPortfolio"
    >
      <information-text-section
        :heading="$t('congratulationsHeader')"
        :body="
          hasBankAccount
            ? $t('create-portfolio.questions.account.congratulations.body')
            : $t('create-portfolio.questions.account.congratulations.noBankAccountBody')
        "
      >
        <icon />
      </information-text-section>
      <p class="congratulations__second-body">
        {{
          hasBankAccount
            ? $t("create-portfolio.questions.account.congratulations.body2")
            : $t("create-portfolio.questions.account.congratulations.noBankAccountBody2")
        }}
      </p>
    </single-choice>
    <single-choice
      v-else
      :alternatives="alternatives"
      @click="goToPortfolio"
    >
      <information-text-section
        :heading="$t('congratulationsHeader')"
        :body="$t('manualRegistration')"
      >
        <icon />
      </information-text-section>
      <p class="congratulations__second-body">
        {{ $t("create-portfolio.questions.account.congratulations.secondBody") }}
      </p>
    </single-choice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { v4 as uuidv4 } from "uuid";
import { SignupMutation } from "@/store/signup/mutations";
import { LogLevel, PortfolioBase } from "@/clients";
import { CustomWindow } from "@/types";
import log from "@/clients/log";
import getCookieByName from "@/utils/cookie";
import { PortfolioAction } from "@/store/portfolio/actions";
import { SignupNavigationMutation } from "@/store/signup-navigation/mutations";
import { NavigationAction } from "@/store/navigation/actions";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Question from "@/views/signup/templates/question";
import Icon from "@/assets/images/signup/rocket-launch-light.svg";
import { SignupState } from "@/store/signup/types";
import trackAdRecord from "./adrecord-snippet";
import trackAdTraction from "./adtraction-snippet";

declare const window: CustomWindow;
const logger = "congratulations.vue";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    Icon
  },
  extends: Question,
  data: () => ({
    adtractionScriptLoadInterval: undefined as number | undefined
  }),
  computed: {
    alternatives(): { text: string; key: string }[] {
      return [
        {
          text: this.$t("create-portfolio.questions.account.congratulations.toPortfolio"),
          key: "toPortfolio"
        }
      ];
    },
    needsManualCheck(): boolean {
      return this.$store.getters.needsManualCheck;
    },
    hasBankAccount(): boolean {
      const { bankAccountNumber } = this.$store.state.signupStore as SignupState;
      if (bankAccountNumber) {
        return true;
      }
      return false;
    }
  },
  created() {
    this.$store.commit(SignupMutation.setCompletedSignUp, true);
    this.$store.commit(SignupMutation.showProgressBar, false);
  },
  mounted() {
    if (import.meta.env.VITE_ENV === "production") {
      const { currentUser } = this.$store.state.userStore;
      const userId = currentUser.id;
      // If for some reason we don't get a registered user id we just default to some random number so there is no
      // conflict in Adrecord.
      const orderId = currentUser.id || uuidv4();

      // The latest_utm_source cookie is set by google tag manager when a user visits sigmastocks.com with the query parameter utm_source set.
      // This is done so that we use last click atribution for ad tracking.
      const latestAdNetworkVisit = getCookieByName("latest_utm_source");
      if (latestAdNetworkVisit === "adrecord") {
        trackAdRecord(document, "script", "https://t.adii.io/convert.js", orderId);
        log(logger, `Adrecord referral (userId=${userId})`, LogLevel.Information);
      } else {
        // Always pass to Adtraction if no utm source is set. This is by request from Adtraction with the reasoning that this gives Adtraction
        // the chance to cross-device match the customer. Cross-device matching can possibly happen e.g. if the customer clicked an ad
        // in Instagram and then finished the registration in another browser (i.e. not the Instagram browser) or on their computer.
        // Since we pass the md5'ed email and this can be matched with e.g. an Instagram email, the attribution can correctly be made to
        // Adtraction in this case.
        // This has the obvious caveat of possibly attributing false positives to Adtraction, which they say in an email to Jakob should not happen.
        // In the forecoming future we will monitor how many attributions are made to Adtraction.
        log(
          logger,
          `Adtraction script loading (userId=${userId};latest_utm_source=${latestAdNetworkVisit})`,
          LogLevel.Information
        );
        const adtractionScript = document.createElement("script");
        // We load from go.sigmastocks.com because according to the method Adtraction uses we have set up go.sigmastocks.com to redirect
        // to the adtraction script. This is to avoid adblockers I believe.
        // See instructions at https://adtraction.com/jstag.htm?tp=1603955778
        adtractionScript.setAttribute("src", "https://go.sigmastocks.com/jsTag?ap=1603951932");
        document.head.appendChild(adtractionScript);
        this.adtractionScriptLoadInterval = window.setInterval(() => {
          if ((window as any).ADT) {
            window.clearInterval(this.adtractionScriptLoadInterval);
            trackAdTraction(orderId, this.$store.state.signupStore.contactDetails.email);
            log(
              logger,
              `Adtraction referral tracked (userId=${userId};latest_utm_source=${latestAdNetworkVisit})`,
              LogLevel.Information
            );
          }
        }, 100);
      }
    }
  },
  methods: {
    async goToPortfolio(): Promise<void> {
      this.$store.commit(SignupNavigationMutation.setProgressHeadings, undefined);

      await this.$store.dispatch(NavigationAction.loadPortfolios, {
        noCache: true,
        forceReload: true
      });
      const { portfolios } = this.$store.state.navigationStore;
      const latestPortfolio = portfolios.reduce((a: PortfolioBase, b: PortfolioBase) =>
        a.createdAt > b.createdAt ? a : b
      );
      await this.$store.dispatch(PortfolioAction.setActivePortfolio, latestPortfolio.id);
      this.$router.push({ name: "portfolio", params: { id: latestPortfolio.id } });
    }
  }
});
</script>

<style lang="scss" scoped>
.congratulations {
  height: 100%;
  &__information-body {
    max-width: 44.5rem;
    margin-top: 1rem;
    font-weight: 300;
  }
  &__second-body {
    text-align: center;
    margin-top: 1rem;
    font-weight: 300;
  }
}
</style>
