import { useStore } from "vuex";
import { ref } from "vue";
import { PortfolioAction } from "@/store/portfolio/actions";
import type { RootState } from "@/types";
import type { MongstadPaiStats } from "@/clients";

export function usePaiStats() {
  const paiStats = ref<MongstadPaiStats>();
  const store = useStore();
  store.dispatch(PortfolioAction.loadPaiStats)
    .then(() => paiStats.value = (store.state as RootState).portfolioStore.paiStats);

  return { paiStats };
}
