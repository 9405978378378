<template>
  <VueApexCharts
    v-show="showChart"
    ref="developmentChart"
    :height="chartHeight"
    type="line"
    :options="chartOptions"
    :series="series"
  />
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import type { ApexOptions } from "apexcharts";
import { DateTime } from "luxon";
import se from "apexcharts/dist/locales/se.json";
import VueApexCharts from "vue3-apexcharts";
import type { DateTimeData } from "@/types/portfolio";
import optimizeTimeSeries from "@/utils/chart-utils";
import { softBlue } from "@/styles/colors";

import type { DataPoint } from "@/clients";

interface Series {
  name: string;
  data: DateTimeData[];
  type?: string;
}

const defaultDecimalOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
const yLabelDecimalOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

const options = {
  chart: {
    type: "area",
    zoom: {
      enabled: false,
    },
    locales: [se],
    defaultLocale: "se",
    id: "development-chart",
    animations: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    fontFamily: "sofia-pro', sans-serif",
  },
  legend: {
    show: false,
  },
  plotOptions: {
    area: {
      fillTo: "end",
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeFormatter: {
        year: "yyyy",
        month: "MMM 'yy",
        day: "",
        hour: "",
      },
      style: {
        fontSize: "0.875rem",
        colors: "#959595",
      },
      datetimeUTC: false,
    },
    axisBorder: {
      show: true,
      color: "#E5E5E5",
    },
    axisTicks: {
      show: true,
      color: "#E5E5E5",
    },
    tooltip: {
      enabled: false,
    },
    crosshairs: {
      show: false,
    },
  },
  yaxis: [
    {
      forceNiceScale: true,
      floating: true,
      labels: {
        offsetX: 50,
        offsetY: 3,
        align: "right",
        style: {
          fontSize: "0.875rem",
          colors: "rgba(43, 43, 43, 0.3)",
        },
        formatter(valueIndex) {
          // valueIndex starts at 0, and shows the growth/decline of the portfolio or index.
          // That is, valueIndex = 25 means 25% growth, and valueIndex = -25 means a 25% decline.
          if (valueIndex > 0) {
            return `+ ${valueIndex.toLocaleString("sv-SE", yLabelDecimalOptions)} %`;
          }
          // Put a space after the minus sign.
          if (valueIndex < 0) {
            return `− ${Math.abs(valueIndex).toLocaleString("sv-SE", yLabelDecimalOptions)} %`;
          }
          return `${valueIndex.toLocaleString("sv-SE", yLabelDecimalOptions)} %`;
        },
      },

      opposite: true,
    },
  ],
  grid: {
    position: "front",
    borderColor: `${softBlue}1B`,
    padding: {
      left: 4,
      right: 4,
    },
  },
  tooltip: {
    style: {
      fontSize: "0.875rem",
      fontFamily: "sofia-pro, sans-serif",
    },
    x: {
      format: "dd MMMM yyyy",
    },
    shared: true,
    intersect: false,
  },
  fill: {
    gradient: {
      type: "vertical",
      colorStops: [
        [
          {
            offset: 0,
            color: softBlue,
            opacity: 1,
          },
          {
            offset: 100,
            color: softBlue,
            opacity: 0.11,
          },
        ],
      ],
    },
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 0,
  },
  dataLabels: {
    enabled: false,
  },
} as ApexOptions;
export default defineComponent({
  components: {
    VueApexCharts,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    minTime: {
      type: Number,
      required: true,
    },
    maxTime: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    chartUpdatingPromise: undefined as Promise<any> | undefined,
    showChart: false,
  }),
  computed: {
    currentTab(): number | undefined {
      return this.$store.state.portfolioStore.currentTab;
    },
    chartHeight(): string {
      if (this.$vuetify.display.xs) {
        return "230px";
      }
      return "360px";
    },
    maxValue(): number {
      const maxValues = Array.from({ length: this.series.length }, (_, i) => i).map((index) => {
        const max = this.getMaxShownValue(this.series[index].data);
        return max === undefined ? 1 : max;
      });
      const trueMax = Math.max(...maxValues);
      return trueMax;
    },
    minValue(): number {
      const minValues = Array.from({ length: this.series.length }, (_, i) => i).map((index) => {
        const min = this.getMinShownValue(this.series[index].data);
        return min === undefined ? -1 : min;
      });
      const trueMin = Math.min(...minValues);
      return trueMin;
    },
    portfolioSeries(): Series {
      return {
        name: this.$t("yourPortfolio"),
        type: "area",
        data: this.optimize(this.chartData.totalFraction),
      };
    },
    series(): Series[] {
      const series = [this.portfolioSeries];
      return series;
    },
    startDate(): number {
      if (this.chartData && this.chartData.totalFraction.length > 0) {
        return DateTime.fromJSDate(this.chartData.totalFraction[0].date).valueOf();
      }
      return Date.now();
    },
    chart(): any {
      return this.$refs.developmentChart;
    },
    chartOptions(): ApexOptions {
      if (!options || !options.xaxis || !options.yaxis) {
        return {};
      }
      const xaxis = {
        ...options.xaxis,
        ...{
          max: this.maxTime,
          min: DateTime.max(
            DateTime.fromMillis(this.minTime ?? 0),
            DateTime.fromMillis(this.startDate ?? 0),
          ).valueOf(),
        },
      };

      const yaxis = [
        {
          ...(options.yaxis as Array<any>)[0],
          ...{
            max: this.maxValue,
            min: this.minValue,
            labels: {
              ...(options.yaxis as Array<any>)[0].labels,
              // formatter: this.formatYLabel
            },
          },
        },
      ];
      const tooltip = {
        ...options.tooltip,
        ...{
          y: {
            formatter: this.formatYTooltip,
          },
        },
      };
      const colors = [softBlue];
      const opacity = [0.35];
      const fillType = ["gradient"];
      const strokeWidth = [0];

      return {
        ...options,
        ...{
          xaxis,
          yaxis,
          tooltip,
          colors,
          fill: {
            ...options.fill,
            opacity,
            type: fillType,
          },
          stroke: { ...options.stroke, width: strokeWidth },
        },
      };
    },
  },
  watch: {
    chartOptions: {
      deep: true,
      immediate: true,
      handler() {
        this.chartUpdatingPromise = this.updateYAxis();
      },
    },
    currentTab: {
      deep: true,
      immediate: true,
      async handler(newTab, _) {
        if (newTab === 0) {
          if (this.chartUpdatingPromise) {
            await this.chartUpdatingPromise;
            await nextTick();
            if (this.chart) {
              this.chart.updateOptions(this.chartOptions, true);
            }
          }
        }
      },
    },
  },
  mounted() {
    /* The chart jumps a little bit if we don't wait until it's mounted
     * before showing it.
     */
    this.showChart = true;
  },
  methods: {
    optimize(timeSeries: Array<DataPoint>): Array<any> {
      if (!timeSeries)
        return [];
      const optimizedAndFilteredTimeSeries = optimizeTimeSeries(timeSeries);

      /* The values are converted from fractions to percentages in order for
       * Apex to correctly place the y axis labels. If the values are kept
       * as fractions, the labels will be inconsistently placed, for example
       * at 0%, 3%, 5%, 8%.
       */
      const apexTimeSeries = optimizedAndFilteredTimeSeries.map(v => ({
        x: v.date.getTime(),
        y: v.value ? v.value * 100 : v.value,
      }));
      return apexTimeSeries;
    },
    async updateYAxis(): Promise<any> {
      if (!this.chart) {
        return Promise.resolve();
      }
      /*
          This is a somewhat hacky solution to the issue that
          ApexCharts updates min and max values when a series is updated.
          Force the chart options to update as soon as it has re-rendered
          (after the next Vue tick).
        */
      await nextTick();
      const { chartOptions } = this;
      // The y axis is reset after the render (for unknwon reasons).
      // Update the y max and min values.
      (chartOptions.yaxis as any[])[0].max = this.maxValue;
      (chartOptions.yaxis as any[])[0].min = this.minValue;
      return this.chart.updateOptions(chartOptions);
    },
    getMaxShownValue(dataSeries: DateTimeData[]): number | undefined {
      if (!dataSeries) {
        return undefined;
      }
      const searchableData = [] as DateTimeData[];
      for (let i = 0; dataSeries[i] && dataSeries[i].x <= this.maxTime; i += 1) {
        searchableData.push(dataSeries[i]);
      }
      if (searchableData.length < 1) {
        return undefined;
      }
      const maxDataObject = searchableData.reduce((prev, current) =>
        prev.y > current.y ? prev : current,
      );
      return maxDataObject.y;
    },
    getMinShownValue(dataSeries: DateTimeData[]): number | undefined {
      if (!dataSeries) {
        return undefined;
      }
      const searchableData = [] as DateTimeData[];
      for (let i = 0; dataSeries[i] && dataSeries[i].x <= this.maxTime; i += 1) {
        searchableData.push(dataSeries[i]);
      }
      if (searchableData.length < 1) {
        return undefined;
      }
      const minDataObject = searchableData.reduce((prev, current) =>
        prev.y < current.y ? prev : current,
      );
      return minDataObject.y;
    },
    formatYLabel(valueIndex: number): string {
      // valueIndex starts at 0, and shows the growth/decline of the portfolio or index.
      // That is, valueIndex = 25 means 25% growth, and valueIndex = -25 means a 25% decline.
      if (valueIndex > 0) {
        return `+ ${valueIndex.toLocaleString("sv-SE", defaultDecimalOptions)} %`;
      }
      // Put a space after the minus sign.
      if (valueIndex < 0) {
        return `− ${Math.abs(valueIndex).toLocaleString("sv-SE", defaultDecimalOptions)} %`;
      }
      return `${valueIndex.toLocaleString("sv-SE", defaultDecimalOptions)} %`;
    },
    formatYTooltip(valueIndex: number): string {
      if (valueIndex !== undefined && valueIndex !== null) {
        if (valueIndex > 0) {
          return `+ ${valueIndex.toLocaleString("sv-SE", defaultDecimalOptions)} %`;
        }
        // Put a space after the minus sign.
        if (valueIndex < 0) {
          return `− ${Math.abs(valueIndex).toLocaleString("sv-SE", defaultDecimalOptions)} %`;
        }
        return `${valueIndex.toLocaleString("sv-SE", defaultDecimalOptions)} %`;
      }
      return "";
    },
  },
});
</script>
