import type { ActionTree, Commit } from "vuex";
import type { RootState } from "../../types";
import { PortfolioAction } from "../portfolio/actions";
import { PortfolioMutation } from "../portfolio/mutations";
import { BankIdMutation } from "../bankid/mutations";
import type { UserState } from "./types";
import { UserMutation } from "./mutations";
import { UserAction } from "./action-definitions";
import { i18n } from "@/locale/i18n";
import { parseJwt } from "@/utils/jwt";
import { baseUrl } from "@/clients/config";
import type {
  AddDiscountResponse,
  CompanyRepresentativeInfo,
  CreateCompanyRepresentativeUser,
  ExtRef,
  FileResponse,
  LegalEntityValidationAction,
  LegalEntityValidationResponse,
  Message,
  NotificationSettings,
  PdfListData,
  PortfolioPdfType,
} from "@/clients";
import {
  AddAffiliateCodeRequest,
  AgreementsClient,
  ApplicationCompaniesClient,
  ApplicationUsersClient,
  BannerClient,
  CompanyRepresentativesClient,
  CustomReportRequest,
  DiscountsClient,
  GetPreviewDocument,
  HubspotClient,
  KnowYourCustomerAnswers,
  LegalEntity,
  LoginRequest,
  MessagesClient,
  NotificationsClient,
  PdfClient,
  PdfType,
  QuarterlyReportRequest,
  SignAgreementsRequest,
  SignStartResponse,
  UpdateCompanyKnowYourCustomerYearly,
  UpdateCompanyLegalEntityYearlyRequest,
  UpdateKycRequest,
  UpdateLegalEntityContactRequest,
  UpdateLegalEntityEconomy,
  UpdateLegalEntityYearlyRequest,
} from "@/clients";
import { downloadFileResponse } from "@/utils/downloader";

import { getRegisterCompanyParameters } from "@/mappers/register-company-parameters";
import { paiFromState } from "@/mappers/pai";
import router from "@/router";
import clearStore from "@/utils/clear-store";

const applicationUsersClient = new ApplicationUsersClient(baseUrl);
const applicationCompaniesClient = new ApplicationCompaniesClient(baseUrl);
const notificationsClient = new NotificationsClient(baseUrl);
const messagesClient = new MessagesClient(baseUrl);
const pdfClient = new PdfClient(baseUrl);
const bannerClient = new BannerClient(baseUrl);
const agreementsClient = new AgreementsClient(baseUrl);
const companyRepresentativesClient = new CompanyRepresentativesClient(baseUrl);
const discountClient = new DiscountsClient(baseUrl);
const hubspotClient = new HubspotClient(baseUrl);

function handleTokenTimeout(commit: Commit) {
  commit(UserMutation.setToken, undefined);
}

export const actions: ActionTree<UserState, RootState> = {
  async [UserAction.getPreviewDocument](
    { commit, rootState },
    payload: { pdfType: PdfType; portfolioExternalReference: ExtRef; pdfId: number },
  ): Promise<void> {
    const { transactionId } = rootState.bankIdStore;
    commit(UserMutation.setLoadingPdf, { pdfId: payload.pdfId, loading: true });
    try {
      const response = await pdfClient.getPreviewDocument(
        new GetPreviewDocument({
          transactionId,
          pdfType: payload.pdfType,
          externalReference: payload.portfolioExternalReference.value,
        }),
      );

      downloadFileResponse(response);
    }
 catch (e) {
      console.error("Failed to download preview document", payload, e);
    }
    commit(UserMutation.setLoadingPdf, { pdfId: payload.pdfId, loading: false });
  },
  async [UserAction.loadBanner]({ commit }): Promise<void> {
    return bannerClient.getAll().then((banners) => {
      commit(UserMutation.updateInfoBanners, banners);
    });
  },
  async [UserAction.loadCurrentUser]({ commit }, admin: boolean = false): Promise<void> {
    commit(UserMutation.setUserError, false);
    commit(UserMutation.setLoadingCurrentUser, true);
    try {
      const user = await applicationUsersClient.getUser();
      commit(UserMutation.setCurrentUser, user);
      // HACK: need to fix admin view to handle multiple legal entities before removing this fix
      if (admin && user) {
        commit(UserMutation.setLegalEntity, user.legalEntityUsers[0].legalEntity);
      }
      commit(UserMutation.setLoadingCurrentUser, false);
    } catch (error: any) {
      console.error(error.message);
      commit(UserMutation.setUserError, true);
      commit(UserMutation.setLoadingCurrentUser, false);
    }
  },
  [UserAction.createTokenTimeoutListener]({ commit }, token: string): void {
    const decoded: string | { [key: string]: any } | null = parseJwt(token);
    if (decoded && typeof decoded === "object" && Object.keys(decoded).length > 0) {
      const { exp } = decoded;
      const timeUntilExpiration = exp * 1000 - Date.now();
      setTimeout(() => handleTokenTimeout(commit), timeUntilExpiration);
    }
  },
  async [UserAction.updateLegalEntitySpar]({ state, commit }): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { currentUser } = state;
    if (!currentUser) {
      throw new Error("No updated legal entity or current user available.");
    }
    try {
      const response = await applicationUsersClient.updateLegalEntitySpar(
        state.currentLegalEntity.brickId,
      );
      commit(UserMutation.setLegalEntity, response);
    }
 catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.prepareUpdateLegalEntityContact]({ state, commit, rootState }): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { editedLegalEntity, currentUser } = state;
    if (!editedLegalEntity || !currentUser) {
      throw new Error("No updated legal entity or current user available.");
    }
    const legalEntity = new UpdateLegalEntityContactRequest({
      phoneNumber: editedLegalEntity.phoneNumber,
      email: editedLegalEntity.email,
    });
    try {
      const response = await applicationUsersClient.prepareUpdateLegalEntityContact(
        state.currentLegalEntity.brickId,
        legalEntity,
      );
      commit(BankIdMutation.setTransactionId, response?.id);
      commit(UserMutation.setAgreementsToSign, response);
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.completeUpdateLegalEntityContact]({ state, commit, rootState }): Promise<void> {
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No current legal entity in state");
    }
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    try {
      const response = await applicationUsersClient.completeUpdateLegalEntityContact(
        state.currentLegalEntity.brickId,
        rootState.bankIdStore.transactionId,
      );
      commit(UserMutation.setLegalEntity, response);
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
    }
  },
  async [UserAction.completeUpdateLegalEntityEconomy]({
    state,
    commit,
    dispatch,
    rootState,
  }): Promise<LegalEntityValidationResponse | undefined> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { editedLegalEntity, currentUser } = state;
    if (!editedLegalEntity || !currentUser) {
      throw new Error("No updated legal entity or current user available.");
    }
    const legalEntity = new LegalEntity({
      ...state.currentLegalEntity,
      monthlySalary: editedLegalEntity.monthlySalary,
      monthlyRemaining: editedLegalEntity.monthlyRemaining,
      totalSavings: editedLegalEntity.totalSavings,
      totalLoans: editedLegalEntity.totalLoans,
      boundCapital: editedLegalEntity.boundCapital,
    });
    try {
      const response = await applicationUsersClient.completeUpdateLegalEntityEconomy(
        legalEntity.brickId,
        rootState.bankIdStore.transactionId as string,
      );
      if (response && response.legalEntity) {
        commit(
          UserMutation.setLegalEntity,
          new LegalEntity({
            ...state.currentLegalEntity,
            monthlySalary: response.legalEntity.monthlySalary,
            monthlyRemaining: response.legalEntity.monthlyRemaining,
            totalSavings: response.legalEntity.totalSavings,
            totalLoans: response.legalEntity.totalLoans,
            boundCapital: response.legalEntity.boundCapital,
          }),
        );
        if (rootState.portfolioStore.activePortfolioBase) {
          const strategy = await dispatch(PortfolioAction.getStrategy);
          commit(PortfolioMutation.setStrategy, strategy);
        }

        commit(
          UserMutation.setIsLockedBecauseOfEconomy,
          response.legalEntity.isLockedBecauseOfEconomy,
        );

        return response;
      }
      return undefined;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      return undefined;
    }
  },
  async [UserAction.prepareUpdateLegalEntityEconomy]({
    state,
    commit,
    dispatch,
    rootState,
  }): Promise<LegalEntityValidationResponse | undefined> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { editedLegalEntity, currentUser } = state;
    if (!editedLegalEntity || !currentUser) {
      throw new Error("No updated legal entity or current user available.");
    }
    try {
      const response = await applicationUsersClient.prepareUpdateLegalEntityEconomy(
        state.currentLegalEntity.brickId,
        new UpdateLegalEntityEconomy({
          monthlySalary: editedLegalEntity.monthlySalary as number,
          monthlyRemaining: editedLegalEntity.monthlyRemaining as number,
          totalSavings: editedLegalEntity.totalSavings as number,
          totalLoans: editedLegalEntity.totalLoans as number,
          boundCapital: editedLegalEntity.boundCapital as number,
        }),
      );
      commit(BankIdMutation.setTransactionId, response?.id);
      commit(UserMutation.setAgreementsToSign, response);
      return undefined;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.loadNotificationSettings]({ state, commit }): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    commit(UserMutation.setLoadNotificationSettingsError, false);
    if (state.currentUser) {
      await notificationsClient
        .getUserNotificationSettings(
          state.currentLegalEntity.brickId,
          state.currentUser.id as number,
        )
        .then((settings) => {
          if (settings) {
            commit(UserMutation.setNotificationSettings, settings);
          }
        })
        .catch((error) => {
          commit(UserMutation.setLoadNotificationSettingsError, true);
        });
    } else {
      commit(UserMutation.setLoadNotificationSettingsError, true);
    }
  },
  async [UserAction.updateNotificationSettings](
    { state, commit },
    settings: NotificationSettings,
  ): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (state.currentUser) {
      const updatedSettings = await notificationsClient.updateUserNotificationSettings(
        state.currentLegalEntity.brickId,
        state.currentUser.id as number,
        settings,
      );
      if (updatedSettings) {
        commit(UserMutation.setNotificationSettings, updatedSettings);
      }
    }
  },
  async [UserAction.prepareUpdateKyc]({ state, commit, dispatch, rootState }): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.editedLegalEntity) {
      throw new Error("No edited legal entity in state");
    }
    if (!state.editedLegalEntity.knowYourCustomerAnswers) {
      throw new Error("No edited kyc in state");
    }
    try {
      const response = await applicationUsersClient.prepareUpdateKyc(
        state.currentLegalEntity.brickId,
        new UpdateKycRequest({
          knowYourCustomerAnswers: new KnowYourCustomerAnswers({
            ...state.editedLegalEntity.knowYourCustomerAnswers,
          }),
        }),
      );
      commit(BankIdMutation.setTransactionId, response?.id);
      commit(UserMutation.setAgreementsToSign, response);
    }
 catch (error) {
      // eslint-disable-next-line ts/prefer-ts-expect-error, ts/ban-ts-comment
      // @ts-ignore
      dispatch(UserAction.addSnackbarMessage, i18n.global.t("errors.cantSave"));
      commit(UserMutation.setUpdateSettingsError, true);

      console.error(error);
    }
  },
  async [UserAction.completeUpdateKyc]({
    state,
    commit,
    dispatch,
    rootState,
  }): Promise<LegalEntityValidationResponse | undefined> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.editedLegalEntity) {
      throw new Error("No edited legal entity in state");
    }
    if (!state.editedLegalEntity.knowYourCustomerAnswers) {
      throw new Error("No edited kyc in state");
    }
    try {
      const updatedLegalEntity = await applicationUsersClient.completeUpdateKyc(
        state.currentLegalEntity.brickId,
        rootState.bankIdStore.transactionId as string,
      );

      if (updatedLegalEntity && updatedLegalEntity.legalEntity) {
        commit(UserMutation.setKyc, updatedLegalEntity.legalEntity.knowYourCustomerAnswers);
        return updatedLegalEntity;
      }
      return undefined;
    }
 catch (error) {
      dispatch(UserAction.addSnackbarMessage, i18n.global.t("errors.cantSave"));
      commit(UserMutation.setLoadKycError, true);

      console.error(error);
      return undefined;
    }
  },
  async [UserAction.addSnackbarMessage]({ dispatch }, text): Promise<void> {
    dispatch(UserAction.addSnackbarMessageWithDuration, { text, duration: 5500 });
  },
  async [UserAction.addSnackbarMessageWithDuration](
    { commit },
    { text, duration }: { text: string; duration: number },
  ): Promise<void> {
    const key = Date.now().toFixed();
    commit(UserMutation.addSnackbarMessage, { key, text });
    setTimeout(() => commit(UserMutation.removeSnackbarMessage, key), duration);
  },
  async [UserAction.loadMessages]({ state, commit }): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    commit(UserMutation.setLoadingMessages, true);
    await messagesClient
      .getAll(state.currentLegalEntity.brickId)
      .then((messages) => {
        commit(UserMutation.setLoadingMessages, false);
        if (messages) {
          commit(UserMutation.setMessages, messages);
        }
      })
      .catch((error) => {
        commit(UserMutation.setLoadingMessages, false);
        // TODO: Sätt error state https://trello.com/c/o5QiRlBk

        console.error(error);
      });
  },
  async [UserAction.updateMessagesRead](
    { state, commit },
    messages: Array<Message>,
  ): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    await messagesClient.update(state.currentLegalEntity.brickId, messages).then((_) => {
      // We don't care about return value at this time.
      // A failure in updating is not a big deal since we
      // only update read status.
    });
  },
  async [UserAction.loadPdfList]({ getters, commit }, portfolioId): Promise<void> {
    // commit(UserMutation.setLoadingPdf, { pdfId, loading: true });
    await pdfClient
      .getPdfList(portfolioId)
      .then((response: any) => {
        commit(UserMutation.setArchivePdfList, response);
        // downloadFileResponse(response);
        // commit(UserMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((error) => {
        console.error(error);
        // commit(UserMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [UserAction.loadStaticAgreements]({ getters, state, commit }, portfolioId): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    await pdfClient
      .getStaticAgreementsPdfList(state.currentLegalEntity.brickId, portfolioId)
      .then((response: any) => {
        commit(UserMutation.setStaticAgreementsList, response);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [UserAction.loadPortfolioAgreements](
    { getters, state, commit },
    portfolioId,
  ): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    await pdfClient
      .getPortfolioPdfs(state.currentLegalEntity.brickId, portfolioId)
      .then((response: any) => {
        commit(PortfolioMutation.setAgreements, response);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async [UserAction.loadPdf]({ commit }, pdf: PdfListData): Promise<void> {
    // commit(UserMutation.setLoadingPdf, { pdfId, loading: true });

    await pdfClient
      .getPdf(pdf)
      .then((response: any) => {
        // commit(UserMutation.setArchivePdfList, response);
        downloadFileResponse(response);
        // commit(UserMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((error) => {
        console.error(error);
        // commit(UserMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [UserAction.getCurrentAgreementPdf](
    { commit, state },
    { pdfId, portfolioId, pdfType },
  ): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    commit(UserMutation.setLoadingPdf, { pdfId, loading: true });
    await pdfClient
      .getCurrentAgreementPdf(state.currentLegalEntity.brickId, portfolioId, pdfType)
      .then((response: any) => {
        downloadFileResponse(response);
        commit(UserMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(UserMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [UserAction.getQuarterlyReport](
    { state, commit },
    { pdfId, portfolioBase, quarter, year },
  ): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    commit(UserMutation.setLoadingPdf, { pdfId, loading: true });
    const request = new QuarterlyReportRequest({
      portfolioId: portfolioBase.id,
      quarter,
      year,
    });
    await pdfClient
      .getQuarterlyReport(request, state.currentLegalEntity.brickId)
      .then((response: any) => {
        downloadFileResponse(response);
        commit(UserMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(UserMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [UserAction.loadTaxBaseAndManualPdfs]({ state, commit }, portfolioId): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    // commit(UserMutation.setLoadingPdf, { pdfId, loading: true });
    await pdfClient
      .getTaxBaseAndManualPdfs(portfolioId, state.currentLegalEntity.brickId)
      .then((response: PdfListData[] | null) => {
        if (response) {
          commit(
            UserMutation.setTaxBasePdfs,
            response.filter(x => x.type === PdfType.TaxBase),
          );
          commit(
            UserMutation.setManualPdfs,
            response.filter(x => x.type === PdfType.Manual),
          );
        }
        // commit(UserMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((error) => {
        console.error(error);
        // commit(UserMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [UserAction.getMissingOrErrorAgreements]({ state, commit }): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    await agreementsClient
      .getMissingOrErrorAgreements(state.currentLegalEntity.brickId)
      .then((response: PortfolioPdfType[] | null) => {
        commit(UserMutation.setMissingOrErrorAgreements, response || undefined);
      });
  },
  async [UserAction.prepareSignAgreements]({ state, commit }, agreements): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const response = await agreementsClient.prepareSignAgreements(
      state.currentLegalEntity.brickId,
      new SignAgreementsRequest({ agreements }),
    );
    commit(BankIdMutation.setTransactionId, response?.id);
    commit(UserMutation.setAgreementsToSign, response);
  },
  async [UserAction.signAgreements]({ state, rootState }): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No current legal entity in state");
    }
    await agreementsClient.completeSignAgreements(
      state.currentLegalEntity.brickId,
      rootState.bankIdStore.transactionId,
    );
  },
  [UserAction.createCustomReport](
    { state, rootState },
    { startDate, endDate },
  ): Promise<string | undefined> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!rootState.portfolioStore.activePortfolioBase) {
      throw new Error("No active portfolio in state");
    }

    return pdfClient
      .createCustomReport(
        new CustomReportRequest({
          portfolioId: rootState.portfolioStore.activePortfolioBase.id,
          startDate,
          endDate,
        }),
        state.currentLegalEntity.brickId,
      )
      .then((response: FileResponse | null) => {
        if (response) {
          downloadFileResponse(response);
          return response.fileName;
        }
        throw new Error("File response was null");
      });
  },
  async [UserAction.updateLegalEntityYearlyCompany]({
    state,
    commit,
    rootState,
    rootGetters,
    dispatch,
  }): Promise<LegalEntityValidationResponse | null> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { editedLegalEntity, currentUser } = state;
    if (!editedLegalEntity || !currentUser) {
      throw new Error("No updated legal entity or current user available.");
    }
    const signupParameters = getRegisterCompanyParameters(rootState.companySignupStore, rootState.signupStore);

    try {
      const response = await applicationCompaniesClient.updateLegalEntityYearlyCompany(
        state.currentLegalEntity.brickId,
        new UpdateCompanyLegalEntityYearlyRequest({
          updateParameters: new UpdateCompanyKnowYourCustomerYearly({
            ...signupParameters.legalEntity.companyKnowYourCustomerAnswers,
          }),
          esgPais: paiFromState(rootState.signupStore.esgPais),
        }),
      );
      return response;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.prepareUpdateLegalEntityEconomyCompany]({
    state,
    commit,
    rootState,
    rootGetters,
    dispatch,
  }): Promise<LegalEntityValidationAction | undefined> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { editedLegalEntity, currentUser } = state;
    if (!editedLegalEntity || !currentUser) {
      throw new Error("No updated legal entity or current user available.");
    }

    if (!state.editedCompanyKnowYourCustomerAnswers) {
      throw new Error("no edited companyKnowYourCustomerAnswers in state");
    }

    if (!rootState.userStore.currentLegalEntity?.companyKnowYourCustomerAnswers) {
      throw new Error("no edited companyKnowYourCustomerAnswers in state");
    }

    try {
      const response = await applicationCompaniesClient.prepareUpdateLegalEntityCompany(
        state.currentLegalEntity.brickId,
        new UpdateCompanyLegalEntityYearlyRequest({
          updateParameters: new UpdateCompanyKnowYourCustomerYearly({
            ...rootState.userStore.currentLegalEntity.companyKnowYourCustomerAnswers,
            averageOperatingProfit:
              state.editedCompanyKnowYourCustomerAnswers.averageOperatingProfit,
            forecastResultThisYear:
              state.editedCompanyKnowYourCustomerAnswers.forecastResultThisYear,
            hasShortTermDebt: state.editedCompanyKnowYourCustomerAnswers.hasShortTermDebt,
            hasLongTermDebt: state.editedCompanyKnowYourCustomerAnswers.hasLongTermDebt,
            solidityPercentage: state.editedCompanyKnowYourCustomerAnswers.solidityPercentage,
            liquidAssets: state.editedCompanyKnowYourCustomerAnswers.liquidAssets,
            liquidityPercentage: state.editedCompanyKnowYourCustomerAnswers.liquidityPercentage,
            totalAssets: state.editedCompanyKnowYourCustomerAnswers.totalAssets,
            isActive: state.editedCompanyKnowYourCustomerAnswers.isActive,
          }),
          esgPais: {},
        }),
      );
      commit(BankIdMutation.setTransactionId, response?.prepareDocumentSignResponse?.id);
      commit(UserMutation.setAgreementsToSign, response?.prepareDocumentSignResponse);

      return response?.validationAction;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.prepareUpdateLegalEntityCompanyPrincipals](
    { state, commit, rootState, rootGetters, dispatch },
    index,
  ): Promise<LegalEntityValidationAction | undefined> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { editedLegalEntity, currentUser } = state;
    if (!editedLegalEntity || !currentUser) {
      throw new Error("No updated legal entity or current user available.");
    }

    if (!state.editedCompanyKnowYourCustomerAnswers) {
      throw new Error("no edited companyKnowYourCustomerAnswers in state");
    }

    if (!rootState.userStore.currentLegalEntity?.companyKnowYourCustomerAnswers) {
      throw new Error("no edited companyKnowYourCustomerAnswers in state");
    }

    const companyPrincipals = [

      ...rootState.userStore.currentLegalEntity?.companyKnowYourCustomerAnswers.companyPrincipals,
    ];
    companyPrincipals[index] = state.editedCompanyKnowYourCustomerAnswers.companyPrincipals[index];

    try {
      const response = await applicationCompaniesClient.prepareUpdateLegalEntityCompany(
        state.currentLegalEntity.brickId,
        new UpdateCompanyLegalEntityYearlyRequest({
          updateParameters: new UpdateCompanyKnowYourCustomerYearly({
            ...rootState.userStore.currentLegalEntity.companyKnowYourCustomerAnswers,
            companyPrincipals,
          }),
          esgPais: {},
        }),
      );
      commit(BankIdMutation.setTransactionId, response?.prepareDocumentSignResponse?.id);
      commit(UserMutation.setAgreementsToSign, response?.prepareDocumentSignResponse);

      return response?.validationAction;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.prepareUpdateLegalEntityYearlyCompany]({
    state,
    commit,
    rootState,
    rootGetters,
    dispatch,
  }): Promise<LegalEntityValidationAction | undefined> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { editedLegalEntity, currentUser } = state;
    if (!editedLegalEntity || !currentUser) {
      throw new Error("No updated legal entity or current user available.");
    }
    const signupParameters = getRegisterCompanyParameters(rootState.companySignupStore, rootState.signupStore);

    try {
      const response = await applicationCompaniesClient.prepareUpdateLegalEntityYearlyCompany(
        state.currentLegalEntity.brickId,
        new UpdateCompanyLegalEntityYearlyRequest({
          updateParameters: new UpdateCompanyKnowYourCustomerYearly({
            ...signupParameters.legalEntity.companyKnowYourCustomerAnswers,
          }),
          esgPais: paiFromState(rootState.signupStore.esgPais),
        }),
      );
      commit(BankIdMutation.setTransactionId, response?.prepareDocumentSignResponse?.id);
      commit(UserMutation.setAgreementsToSign, response?.prepareDocumentSignResponse);

      return response?.validationAction;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.completeUpdateLegalEntityYearlyCompany]({
    state,
    commit,
    dispatch,
    rootState,
  }): Promise<LegalEntityValidationResponse | undefined> {
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No current legal entity in state");
    }
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    try {
      const response = await applicationCompaniesClient.completeUpdateLegalEntityYearlyCompany(
        state.currentLegalEntity.brickId,
        rootState.bankIdStore.transactionId,
      );
      if (response && response.legalEntity) {
        commit(UserMutation.setLegalEntity, response.legalEntity);
        commit(UserMutation.setUpdateSettingsError, false);
        return response;
      }
      return undefined;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.completeUpdateLegalEntityCompany]({
    state,
    commit,
    dispatch,
    rootState,
  }): Promise<LegalEntityValidationResponse | undefined> {
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No current legal entity in state");
    }
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    try {
      const response = await applicationCompaniesClient.completeUpdateLegalEntityCompany(
        state.currentLegalEntity.brickId,
        rootState.bankIdStore.transactionId,
      );
      if (response && response.legalEntity) {
        commit(UserMutation.setLegalEntity, response.legalEntity);
        commit(UserMutation.setUpdateSettingsError, false);
        return response;
      }
      return undefined;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.prepareUpdateLegalEntityYearly]({
    state,
    commit,
    rootState,
    rootGetters,
    dispatch,
  }): Promise<LegalEntityValidationAction | undefined> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { editedLegalEntity, currentUser } = state;
    if (!editedLegalEntity || !currentUser) {
      throw new Error("No updated legal entity or current user available.");
    }

    const legalEntity = rootGetters.yearlyKycLegalEntity;

    try {
      const response = await applicationUsersClient.prepareUpdateLegalEntityYearly(
        legalEntity.brickId,
        new UpdateLegalEntityYearlyRequest({
          legalEntity,
          esgPais: paiFromState(rootState.signupStore.esgPais),
        }),
      );
      commit(BankIdMutation.setTransactionId, response?.prepareDocumentSignResponse?.id);
      commit(UserMutation.setAgreementsToSign, response?.prepareDocumentSignResponse);
      commit(UserMutation.setUpdateSettingsError, false);
      return response?.validationAction;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.completeUpdateLegalEntityYearly]({
    state,
    commit,
    dispatch,
    rootState,
  }): Promise<LegalEntityValidationResponse | undefined> {
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No current legal entity in state");
    }
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    try {
      const response = await applicationUsersClient.completeUpdateLegalEntityYearly(
        state.currentLegalEntity.brickId,
        rootState.bankIdStore.transactionId,
      );
      if (response && response.legalEntity) {
        commit(UserMutation.setLegalEntity, response.legalEntity);
        if (rootState.portfolioStore.activePortfolioBase) {
          const strategy = await dispatch(PortfolioAction.getStrategy);
          commit(PortfolioMutation.setStrategy, strategy);
        }
        return response;
      }
      return undefined;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.updateLegalEntityYearly]({
    state,
    commit,
    rootState,
    rootGetters,
    dispatch,
  }): Promise<LegalEntityValidationResponse | undefined> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { editedLegalEntity, currentUser } = state;
    if (!editedLegalEntity || !currentUser) {
      throw new Error("No updated legal entity or current user available.");
    }

    const legalEntity = rootGetters.yearlyKycLegalEntity;

    try {
      const response = await applicationUsersClient.updateLegalEntityYearly(
        legalEntity.brickId,
        new UpdateLegalEntityYearlyRequest({
          legalEntity,
          esgPais: paiFromState(rootState.signupStore.esgPais),
        }),
      );
      if (response && response.legalEntity) {
        commit(UserMutation.setLegalEntity, response.legalEntity);
        if (rootState.portfolioStore.activePortfolioBase) {
          const strategy = await dispatch(PortfolioAction.getStrategy);
          commit(PortfolioMutation.setStrategy, strategy);
        }
        return response;
      }
      return undefined;
    } catch (error) {
      commit(UserMutation.setUpdateSettingsError, true);
      throw error;
    }
  },
  async [UserAction.hidePensionDialog]({ state }): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    await applicationUsersClient.hidePensionDialog(state.currentLegalEntity.brickId);
  },
  async [UserAction.loadInsuranceNumbers]({ state, commit }): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    await applicationUsersClient
      .getInsuranceNumbers(state.currentLegalEntity.brickId)
      .then((result) => {
        if (result) {
          commit(UserMutation.setInsuranceNumbers, result);
        }
      });
  },
  async [UserAction.getCompanyRepresentatives]({ state, commit }): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    await companyRepresentativesClient
      .getCompanyRepresentatives(state.currentLegalEntity.brickId)
      .then((result) => {
        if (result) {
          commit(UserMutation.setCompanyRepresentatives, result);
        }
      });
  },
  async [UserAction.addAffiliateCode](
    { state, commit },
    discountCode: string,
  ): Promise<AddDiscountResponse | null> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    let response: AddDiscountResponse | null = null;
    try {
      response = await discountClient.addDiscount(
        state.currentLegalEntity.brickId,
        new AddAffiliateCodeRequest({ discountCode }),
      );
      if (response) {
        commit(UserMutation.setAffiliateCampaign, response.affiliateCampaign);
      }
    }
 catch (error: any) {
      console.error(error);
    }
    return response;
  },
  async [UserAction.addCompanyRepresentative](
    { state, commit },
    payload: { legalEntityId: string; request: CreateCompanyRepresentativeUser },
  ): Promise<void> {
    const addedRepresentative = await companyRepresentativesClient.add(
      payload.legalEntityId,
      payload.request,
    );
    if (addedRepresentative) {
      let currentRepresentatives: CompanyRepresentativeInfo[] = [];
      if (state.companyRepresentatives) {
        currentRepresentatives = [...state.companyRepresentatives];
      }
      currentRepresentatives.push(addedRepresentative);
      commit(UserMutation.setCompanyRepresentatives, currentRepresentatives);
    }
  },
  async [UserAction.terminateAccount](
    { state },
  ): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    await applicationUsersClient.softDeleteLegalEntity(state.currentLegalEntity.brickId);
  },
  async [UserAction.getHubspotChatToken](
    { state, commit },
  ): Promise<void> {
    if (!state.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    try {
      const response = await hubspotClient.chatAuth(state.currentLegalEntity.brickId);
      if (response?.token) {
        commit(UserMutation.setHubspotChatToken, response.token);
      } else {
        throw new Error("dummy");
      }
    } catch (error: any) {
      throw new Error("Failed to get hubspot chat token");
    }
  },
  async [UserAction.signOut](
    { commit },
  ): Promise<void> {
    commit(UserMutation.setToken, undefined);
    if ((window as any).HubSpotConversations) {
      (window as any).HubSpotConversations.widget.remove();
    }
    // Wait with clearing store until we reach login so that components that expect portfolios
    // and user don't throw
    await router.push({ name: "login" });
    clearStore();
  },
};
