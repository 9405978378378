import { LegalEntityCountryCode } from "@/clients";

// Reloads the page instead of using vue router to make sure that scripts loaded based on locale in index.html actually gets loaded
export function reloadIfWrongLocale(
  pathLocale: string,
  currentPath: string,
  userCountryCode: LegalEntityCountryCode
) {
  let countryCodeString = "se";
  if (userCountryCode === LegalEntityCountryCode.No) {
    countryCodeString = "no";
  }
  if (pathLocale !== countryCodeString) {
    window.location.href = `/${countryCodeString}${currentPath.substring(3)}`;
  }
}
