<template>
  <div class="sign-redirector">
    <loading-spinner />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { softBlue, nearlyBlack } from "@/styles/colors";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { BankIdMutation } from "@/store/bankid/mutations";
import { UserAction } from "@/store/user/action-definitions";

export default defineComponent({
  components: {
    LoadingSpinner
  },
  data: () => ({}),
  computed: {},
  mounted() {
    this.$store.commit(
      BankIdMutation.setRedirectSignStatus,
      this.$route.query.signStatus as string
    );
    if (this.$store.state.bankIdStore.signReturnPath) {
      this.$router.replace(this.$store.state.bankIdStore.signReturnPath);
    } else {
      this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("pleaseTryAgain"));
      this.$router.replace({ name: "login" });
    }
  },
  methods: {}
});
</script>

<style lang="scss" scoped>
.sign-redirector {
  height: 100%;
  background: white;
}
</style>
