<template>
  <div class="etf">
    <div class="etf__description">
      {{ etfItem.description }}
    </div>
    <div class="etf__link-container">
      <icon
        fixed-width
        :icon="['fal', 'file-alt']"
      />
      <a
        class="etf__link"
        :href="baseUrl + encodeURI(etfItem.pdfPath)"
        target="_blank"
        >{{ $t("portfolio.holdings.detailed.etf-static-information.factSheet") }} (pdf)</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { baseUrl } from "@/clients/config";

interface EtfItem {
  pdfPath: string;
  description: string;
}
const xactObligationName = "XACT Obligation";

export default defineComponent({
  props: {
    assetName: {
      type: String,
      required: true,
      default: undefined
    }
  },
  data: () => ({
    baseUrl
  }),
  computed: {
    etfItem(): EtfItem {
      if (this.assetName === xactObligationName) {
        return {
          pdfPath: "/pdf/etf/Kiid_XACT_Obligation_(UCITS_ETF)_shb0000241.pdf",
          description: this.$t(
            "portfolio.holdings.detailed.etf-static-information.xact_description"
          )
        };
      }
      return {
        pdfPath: "",
        description: ""
      };
    }
  }
});
</script>

<style lang="scss" scoped>
.etf {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__description {
    font-weight: 300;
  }
  &__link-container {
    padding-top: 1rem;
  }
  &__link {
    color: inherit;
    font-weight: 300;
    margin-left: 0.5rem;
  }
}
</style>
