<!-- eslint-disable vuetify/no-deprecated-props -->
<template>
  <div class="portfolio-footer">
    <v-footer class="portfolio-footer__footer-component" :color="nearlyBlack">
      <template v-if="$vuetify.display.mdAndUp">
        <div class="portfolio-footer__desktop-wrapper">
          <v-list
            v-for="(menu, index) in footerMenus"
            :key="index"
            justify="center"
            align="center"
            class="portfolio-footer__menu"
          >
            <v-list-subheader class="portfolio-footer__menu-title">
              {{ menu.title }}
            </v-list-subheader>
            <v-list-item v-for="listItem in menu.links" :key="listItem.title">
              <v-list-item-title class="portfolio-footer__menu-item">
                <a
                  v-if="listItem.link"
                  class="portfolio-footer__link-button"
                  :href="listItem.link"
                  target="_blank"
                >
                  {{ listItem.title }}
                </a>
                <p
                  v-else
                  class="portfolio-footer__link-button portfolio-footer__link"
                  @click="listItem.action"
                >
                  {{ listItem.title }}
                </p>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </template>
      <v-expansion-panels v-else variant="accordion" theme="dark">
        <v-expansion-panel
          v-for="(menu, index) in footerMenus"
          :key="index"
          elevation="0"
        >
          <v-expansion-panel-title>{{ menu.title }}</v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list-item v-for="listItem in menu.links" :key="listItem.title">
              <v-list-item-title class="portfolio-footer__menu-item">
                <a
                  v-if="listItem.link"
                  class="portfolio-footer__link-button"
                  :href="listItem.link"
                  target="_blank"
                >
                  {{ listItem.title }}
                </a>
                <p
                  v-else
                  class="portfolio-footer__link-button portfolio-footer__link"
                  @click="listItem.action"
                >
                  {{ listItem.title }}
                </p>
              </v-list-item-title>
            </v-list-item>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-footer>
    <div class="portfolio-footer__alt-wrapper">
      <div class="portfolio-footer__alt">
        <span class="portfolio-footer__copyright-wrapper">
          <span class="portfolio-footer__copyright-label">Copyright ©</span>
          <span class="portfolio-footer__copyright-brand">Sigmastocks AB {{ currentYear }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { nearlyBlack } from "@/styles/colors";

interface Item {
  title: string;
  link?: string;
  action?: () => void;
}
interface MenuItem {
  title: string;
  links: Item[];
}

export default defineComponent({
  components: {},
  data: (): any => ({ nearlyBlack }),
  computed: {
    currentYear(): number {
      return new Date().getFullYear();
    },
    footerMenus(): MenuItem[] {
      const sigmastocksBaseURL = "https://www.sigmastocks.com";
      return [
        {
          title: this.$t("components.footer.aboutTheService"),
          links: [
            {
              title: this.$t("components.footer.getStarted"),
              link: `${sigmastocksBaseURL}/om-tjansten/sa-fungerar-det/`,
            },
            {
              title: this.$t("components.footer.howItWorks"),
              link: `${sigmastocksBaseURL}/om-tjansten/portfoljer/`,
            },
            {
              title: this.$t("pricing"),
              link: `${sigmastocksBaseURL}/om-tjansten/avgifter/`,
            },
            {
              title: this.$t("components.footer.commonQuestions"),
              link: `${sigmastocksBaseURL}/om-tjansten/vanliga-fragor/`,
            },
            {
              title: "Cookies",
              action: () => (window as any).Cookiebot.show(),
            },
          ],
        },
        {
          title: this.$t("components.footer.aboutSaving"),
          links: [
            {
              title: this.$t("stocks"),
              link: `${sigmastocksBaseURL}/om-sparande/spara-i-aktier/`,
            },
            {
              title: this.$t("pension"),
              link: `${sigmastocksBaseURL}/om-sparande/pension/`,
            },
            {
              title: this.$t("components.footer.saveForChildren"),
              link: `${sigmastocksBaseURL}/om-sparande/barnsparande/`,
            },
          ],
        },
        {
          title: this.$t("components.footer.aboutSigmastocks"),
          links: [
            {
              title: this.$t("components.footer.aboutUs"),
              link: `${sigmastocksBaseURL}/om-oss/sigmastocks/`,
            },
            {
              title: this.$t("components.footer.podcast"),
              link: `${sigmastocksBaseURL}/om-oss/sigmastocks-podcast/`,
            },
            {
              title: this.$t("components.footer.permissions"),
              link: `${sigmastocksBaseURL}/om-oss/tillstand-och-avtal/`,
            },
            {
              title: this.$t("components.footer.press"),
              link: `${sigmastocksBaseURL}/om-oss/press/`,
            },
            {
              title: this.$t("contact"),
              link: `${sigmastocksBaseURL}/om-oss/kontakt/`,
            },
            {
              title: this.$t("components.footer.work"),
              link: `${sigmastocksBaseURL}/om-oss/jobba-hos-oss/`,
            },
          ],
        },
        {
          title: this.$t("components.footer.socialMedia"),
          links: [
            {
              title: this.$t("components.footer.facebook"),
              link: "https://www.facebook.com/sigmastocks",
            },
            {
              title: this.$t("components.footer.instagram"),
              link: "https://www.instagram.com/sigmastocks/",
            },
            {
              title: this.$t("components.footer.linkedin"),
              link: "https://www.linkedin.com/company/sigmastocks/",
            },
          ],
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.portfolio-footer {
  padding-top: 4rem;
  width: 100vw;

  &__wave {
    margin-bottom: -0.5rem;
    width: 100%;
  }

  &__footer-component {
    padding: 4rem 1.625rem;
    justify-content: space-between;
    align-items: flex-start;

    @include small-down {
      min-height: 21rem;
    }

    @include medium-up {
      padding: 6.25rem 9.375rem 0rem 9.375rem;
    }
  }

  &__desktop-wrapper {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    width: 100%;
    max-width: 86rem;
    padding: 0;
    margin: 0 auto;
  }

  &__menu {
    background-color: transparent;
  }

  &__menu-title {
    padding-left: 0;
    color: white;
    font-size: 1.125rem;
    font-weight: bold;
    font-family: $heading-font-family;
  }

  &__menu-item {
    text-align: left;
  }

  &__link {
    cursor: pointer;
  }

  &__link-button {
    color: white;
    font-family: $heading-font-family;
    opacity: 0.8;
    text-decoration: none;
    @include small-down {
      font-weight: 300;
    }
  }

  &__alt-wrapper {
    width: 100%;
    background-color: $nearly-black;
    @include medium-up {
      padding: 1rem 9.375rem 8rem 9.375rem;
    }
  }

  &__alt {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    max-width: 86rem;
    margin: 0 auto;
    @include small-down {
      padding-bottom: 1.875rem;
    }
    @include medium-up {
      flex-direction: row;
    }
  }

  &__copyright-wrapper {
    color: white;
    opacity: 0.3;
    font-family: $heading-font-family;
  }

  &__copyright-label {
    margin-right: 1rem;
  }

  &__copyright-brand {
    font-weight: 300;
  }
}

.v-expansion-panel-title {
  font-size: 1.125rem;
  padding: 0;
}

:deep(.v-expansion-panel) {
  background-color: transparent !important;
}

:deep(.v-list-item) {
  padding: 0;
}

:deep(.v-icon) {
  font-size: 1rem !important;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding-left: 0;
  padding-right: 0;
}
</style>
