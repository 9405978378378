<template>
  <single-choice-template
    :alternatives="alternatives"
    @click="select"
  >
    <question-text-section
      :heading="$t('create-portfolio.questions.aml.interval.heading')"
      :body="$t('considerAllPortfolios')"
      progress
    />
  </single-choice-template>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { DepositFrequency, Deposit } from "@/clients";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

interface FrequencyChoice {
  text: string;
  key: DepositFrequency;
}

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    alternatives(): FrequencyChoice[] {
      return [
        {
          text: this.$t("everyWeek"),
          key: DepositFrequency.OncePerWeek
        },
        {
          text: this.$t("everyMonth"),
          key: DepositFrequency.OncePerMonth
        },
        {
          text: this.$t("everyHalfYear"),
          key: DepositFrequency.OncePerHalfYear
        },
        {
          text: this.$t("moreSeldom"),
          key: DepositFrequency.OncePerYear
        }
      ];
    }
  },
  methods: {
    select(key: DepositFrequency): void {
      this.$store.commit(SignupMutation.setDepositFrequency, key);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
