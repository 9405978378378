<template>
  <money-left @next="next" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MoneyLeft from "@/views/common-signup/questions/economy/money-left.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupFlowModificationType } from "@/config/signup-modification";

export default defineComponent({
  components: { MoneyLeft },
  methods: {
    async next(key: string): Promise<void> {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.ExpensesWarning,
        include: key === "no"
      });
      this.$store.commit(SignupMutation.setHasHighExpenses, key === "no");
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
