<template>
  <risk-introduction
    :heading="$t('create-portfolio.questions.risk.introduction.heading')"
    :body="$t('create-portfolio.questions.risk.introduction.body')"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RiskIntroduction from "@/views/common-signup/questions/risk/introduction.vue";

export default defineComponent({
  components: { RiskIntroduction }
});
</script>
