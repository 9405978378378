<template>
  <side-dialog
    v-if="modelValue"
    :model-value="modelValue"
    :title="$t('settings')"
    @update:model-value="() => $emit('update:model-value', false)"
  >
    <div class="dialog">
      <div class="dialog__body">
        {{ $t("portfolio-settings.strategy.side-dialog.settingsBody") }}
      </div>
      <v-expansion-panels variant="accordion">
        <v-expansion-panel
          v-for="(item, i) in settingDescriptions"
          elevation="0"
          :key="i"
        >
          <v-expansion-panel-title class="dialog__list-header">
            {{ item.title }}
          </v-expansion-panel-title>
          <v-expansion-panel-text class="dialog__panel-content">
            <div
              v-for="(section, j) in item.sections"
              :key="j"
            >
              <div
                v-if="section.title"
                class="dialog__panel-content--title"
              >
                {{ section.title }}
              </div>
              <p :class="section.class">
                {{ section.body }}
              </p>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </side-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    SideDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    settingDescriptions(): any {
      return [
        {
          title: this.$t("riskLevel"),
          sections: [
            { body: this.$t("portfolio-settings.strategy.side-dialog.riskLevelBody1") },
            {
              body: this.$t("portfolio-settings.strategy.side-dialog.riskLevelBody2"),
              class: "dialog__risk-level-list"
            },
            { body: this.$t("portfolio-settings.strategy.side-dialog.riskLevelBody3") }
          ]
        },
        {
          title: this.$t("ethicalSelection"),
          sections: [
            { body: this.$t("ethical.ethicalSelectionIntro") },
            {
              title: this.$t("ethical.ethicalDefenseTitle"),
              body: this.$t("ethical.ethicalDefenseBody")
            },
            {
              title: this.$t("ethical.ethicalAlcoholTobaccoTitle"),
              body: this.$t("ethical.ethicalAlcoholTobaccoBody")
            },
            {
              title: this.$t("ethical.ethicalGamblingTitle"),
              body: this.$t("ethical.ethicalGamblingBody")
            },
            {
              title: this.$t("ethical.ethicalFossilTitle"),
              body: this.$t("ethical.ethicalFossilBody")
            }
          ]
        },
        {
          title: this.$t("focus"),
          sections: [
            { body: this.$t("focusExplanations.focusIntro") },
            {
              title: this.$t("focusExplanations.focusStandardTitle"),
              body: this.$t("focusExplanations.focusStandardBody")
            },
            {
              title: this.$t("focusExplanations.focusGrowthTitle"),
              body: this.$t("focusExplanations.focusGrowthBody")
            },
            {
              title: this.$t("focusExplanations.focusDividendTitle"),
              body: this.$t("focusExplanations.focusDividendBody")
            }
          ]
        },
        {
          title: this.$t("marketFocus"),
          sections: [{ body: this.$t("marketFocusExplanation") }]
        },
        {
          title: this.$t("portfolio-settings.strategy.side-dialog.esgSelection"),
          sections: [
            { body: this.$t("portfolio-settings.strategy.side-dialog.esgBody1") },
            {
              body: this.$t("portfolio-settings.strategy.side-dialog.esgBody2"),
              class: "dialog__risk-level-list"
            },
            { body: this.$t("portfolio-settings.strategy.side-dialog.esgBody3") }
          ]
        },
        {
          title: this.$t("portfolio-settings.strategy.side-dialog.holdingUpdate"),
          sections: [{ body: this.$t("portfolio-settings.strategy.side-dialog.holdingUpdateBody") }]
        },
        {
          title: this.$t("portfolio-settings.strategy.side-dialog.profitAffect"),
          sections: [
            { body: this.$t("portfolio-settings.strategy.side-dialog.profitAffectBody1") },
            { body: this.$t("portfolio-settings.strategy.side-dialog.profitAffectBody2") }
          ]
        }
      ];
    }
  }
});
</script>

<style lang="scss" scoped>
.dialog {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__body {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 300;
  }
  &__list-header {
    font-family: $heading-font-family;
    font-size: 1rem;
  }
  &__panel-content {
    font-weight: 300;
  }
  &__panel-content--title {
    font-weight: 500;
  }
  &__risk-level-list {
    white-space: pre-line;
  }
}
.v-expansion-panel-title {
  padding: 0;
  min-height: 3rem;
}
:deep(.v-icon) {
  font-size: 1rem !important;
}
:deep(.v-expansion-panel-text__wrapper) {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
:deep(.v-expansion-panel-title__overlay) {
  opacity: 0 !important;
}
</style>
