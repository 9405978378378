import { buildCommonModifications } from "./common-modifications";
import { SignupFlowModificationType } from "./signup-modification";

export const completeInfoPaths = ["/pension/search/complete-info"];
export const organizationNumberPaths = ["/pension/search/organization-number"];

export const additionalQuestions = {
  ...buildCommonModifications("pension"),

  [SignupFlowModificationType.ActivePayments]: ["/pension/search/active-payment-warning"],
  [SignupFlowModificationType.MergePensions]: ["/pension/search/merge-pensions"],
  [SignupFlowModificationType.SoleOwner]: ["/pension/search/sole-owner-confirm"],
  [SignupFlowModificationType.ShowInsuranceHolderEmail]: ["/pension/search/insurance-email"],
  [SignupFlowModificationType.PayoutWarning]: ["/pension/insurance/payout-warning"],
  [SignupFlowModificationType.CompleteInfo]: completeInfoPaths,
  [SignupFlowModificationType.OrganizationNumber]: organizationNumberPaths,
};

const introPaths = {
  paths: ["/pension/intro"],
};

const findPensionPaths = {
  header: "Flytta pension",
  paths: [
    "/pension/move",
    "/pension/search/where",
    "/pension/search/search",
    "/pension/search/search-result",
    "/pension/search/sole-owner",
    "/pension/search/active-payment",
  ],
};

const insurancePaths = {
  header: "Pensionsuppgifter",
  paths: [
    "/pension/insurance/intro",
    "/pension/insurance/payout",
    "/pension/insurance/payout-time",
    "/pension/insurance/summary",
  ],
};

const insuranceSummary = {
  header: "Sammanfattning",
  paths: ["/pension/insurance/summary-agreements", "/pension/insurance/done"],
};

const pensionEconomyPaths = {
  header: "Din ekonomi",
  paths: [
    "/pension/economy/intro",
    "/pension/economy/private-savings",
    "/pension/economy/other-pension",
  ],
};

const preferencesPaths = {
  header: "Sparinställningar",
  paths: [
    "/pension/preferences/introduction",
    "/pension/preferences/focus",
    "/pension/preferences/market",
    "/pension/preferences/sectors",
    "/pension/preferences/sustainability",
    "/pension/preferences/sustainable-investment",
  ],
};

const preferencesPathsNewPortfolio = {
  header: "Sparinställningar",
  paths: [
    "/pension/preferences/introduction",
    "/pension/preferences/focus",
    "/pension/preferences/market",
    "/pension/preferences/sectors",
    "/pension/preferences/sustainability",
    "/pension/preferences/sustainable-choice",
  ],
};

const riskPaths = {
  header: "Risk",
  paths: [
    "/pension/risk/introduction",
    "/pension/previous-experience",
    "/pension/previous-experience-answer",
    "/pension/portfolio-management",
    "/pension/risk/portfolio-selection",
    "/pension/risk/portfolio-confirmation",
  ],
};

const economyPaths = {
  header: "Din ekonomi",
  paths: [
    "/pension/economy/introduction",
    "/pension/economy/time-to-withdraw",
    "/pension/economy/income",
    "/pension/economy/money-left",
    "/pension/economy/remainder",
    "/pension/economy/current-savings",
    "/pension/economy/bound-capital",
    "/pension/economy/loans",
  ],
};

const riskProfilePaths = {
  header: "Sparförslag",
  paths: ["/pension/savings-proposal/proposal", "/pension/savings-proposal/profile"],
};

const amlPaths = {
  header: "Kundkännedom",
  paths: [
    "/pension/aml/law-about",
    "/pension/aml/occupation",
    "/pension/aml/interval",
    "/pension/aml/amount",
    "/pension/aml/investment-background",
    "/pension/aml/purpose",
    "/pension/aml/withdrawal-interval",
    "/pension/aml/swedish-tax-residence",
    "/pension/aml/tax-liable-usa",
    "/pension/aml/tax-liable-other",
    "/pension/aml/citizenship",
    "/pension/aml/politically-exposed",
    "/pension/aml/politically-exposed-associated",
    "/pension/aml/your-money",
  ],
};

const summaryPaths = {
  header: "Sammanfattning",
  paths: ["/pension/summary/summary-agreements", "/pension/summary/done"],
};

const signPaths = {
  header: "Signera",
  paths: [
    "/pension/sign/summary-sign",
    "/pension/sign/move-started",
    "/pension/sign/congratulations",
  ],
};

const esgPaths = {
  header: "Sparinställningar",
  paths: ["/pension/preferences/sustainability", "/pension/preferences/sustainable-investment"],
};
const themePaths = {
  header: "Sparinställningar",
  paths: ["/pension/preferences/theme-info", "/pension/preferences/theme"],
};

export const firstPortfolioQuestionPaths = [
  introPaths,
  findPensionPaths,
  insurancePaths,
  amlPaths,
  insuranceSummary,
  pensionEconomyPaths,
  preferencesPaths,
  riskPaths,
  economyPaths,
  riskProfilePaths,
  summaryPaths,
  signPaths,
];

export const newPortfolioQuestionPaths = [
  introPaths,
  findPensionPaths,
  insurancePaths,
  insuranceSummary,
  pensionEconomyPaths,
  preferencesPathsNewPortfolio,
  riskProfilePaths,
  summaryPaths,
  signPaths,
];

export const firstPortfolioTiedAgentQuestionPaths = [
  introPaths,
  findPensionPaths,
  insurancePaths,
  pensionEconomyPaths,
  riskPaths,
  economyPaths,
  riskProfilePaths,
  esgPaths,
  themePaths,
  amlPaths,
  signPaths,
];

export const newPortfolioTiedAgentQuestionPaths = [
  introPaths,
  findPensionPaths,
  insurancePaths,
  pensionEconomyPaths,
  riskProfilePaths,
  esgPaths,
  themePaths,
  signPaths,
];

export const CURRENT_PENSION_PATH_KEY = "current_pension_path";
