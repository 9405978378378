import type { ActionTree, Commit, Dispatch } from "vuex";
import Kontonummer from "kontonummer";
import type { RootState } from "../../types";
import { UserAction } from "../user/action-definitions";
import { NavigationMutation } from "../navigation/mutations";
import { UserMutation } from "../user/mutations";
import { BankIdMutation } from "../bankid/mutations";
import { OverviewMutation } from "../overview/mutations";
import type { PortfolioState } from "./types";
import { PortfolioMutation } from "./mutations";
import { i18n } from "@/locale/i18n";
import { baseUrl } from "@/clients/config";
import type {
  BackcastRequest,
  ClosePortfolioQuestionnaire,
  CreateDepositResponse,
  DateEvent,
  FileResponse,
  OngoingWithdrawalsSummary,
  SavingsGoal,
  TinkAccountResponse,

  UpdatePortfolioSettingsResult,

  Withdrawal,
} from "@/clients";
import {
  Client,
  DateEventType,
  DepositClient,
  DepositRequest,
  EventsClient,
  PaiStatsClient,
  PortfolioBase,
  PortfolioPerformanceClient,
  PortfolioPredictionClient,

  PortfolioSettings,
  PortfoliosDividendClient,
  PortfoliosHoldingsClient,
  PortfoliosSettingsClient,
  SavingsGoalsClient,
  StrategiesClient,
  Strategy,
  SwishClient,
  SwishPaymentRequest,
  SwishPaymentStatus,
  SwishTransactionStatus,
  TinkAccountClient,
  TinkRequest,
  TransactionsClient,
  UpdatePortfolioSettings,
  UpdateStrategyRequest,
  UpdateStrategyRiskLevelRequest,
  WithdrawalRequest,
  WithdrawalStatus,
  WithdrawalsClient,
} from "@/clients";
import { NavigationAction } from "@/store/navigation/actions";
import { downloadFileResponse } from "@/utils/downloader";
import { createPollingPromise } from "@/utils/polling-promise";
import { constants } from "@/config/general";
import type { Bank, TinkAccount } from "@/types/portfolio";

export const PortfolioAction = {
  loadBackcast: "loadBackcast",
  loadPortfolioHoldings: "loadPortfolioHoldings",
  loadDetailedHolding: "loadDetailedHolding",
  prepareDeposit: "prepareDeposit",
  deposit: "deposit",
  deletePortfolio: "deletePortfolio",
  renamePortfolio: "renamePortfolio",
  loadActivePortfolioDependencies: "loadActivePortfolioDependencies",
  getStrategy: "getStrategy",
  updateStrategy: "updateStrategy",
  saveEditedStrategy: "saveEditedStrategy",
  prepareUpdatePortfolioSettings: "prepareUpdatePortfolioSettings",
  completeUpdatePortfolioSettings: "completeUpdatePortfolioSettings",
  prepareWithdraw: "prepareWithdraw",
  withdraw: "withdraw",
  addSavingsGoal: "addSavingsGoal",
  updateSavingsGoal: "updateSavingsGoal",
  loadPortfolioStrategy: "loadPortfolioStrategy",
  loadTransactions: "loadTransactions",
  saveEditedDiscardedSectors: "saveEditedDiscardedSectors",
  saveEditedRiskLevel: "saveEditedRiskLevel",
  saveEditedRiskProfile: "saveEditedRiskProfile",
  saveEditedFocus: "saveEditedFocus",
  saveEditedMarket: "saveEditedMarket",
  saveEditedSavings: "saveEditedSavings",
  removePortfolioSavingsGoal: "removePortfolioSavingsGoal",
  saveEditedTimeToWithdraw: "saveEditedTimeToWithdraw",
  setActivePortfolio: "setActivePortfolio",
  loadDividend: "loadDividend",
  getOngoingWithdrawals: "getOngoingWithdrawals",
  loadPortfolioEvents: "loadPortfolioEvents",
  cancelDeposit: "cancelDeposit",
  cancelWithdrawal: "cancelWithdrawal",
  getNote: "getNote",
  getExchangeNote: "getExchangeNote",
  saveEditedExcludedCompanies: "saveEditedExcludedCompanies",
  prepareRecreateCancelledTransferReceivers: "prepareRecreateCancelledTransferReceivers",
  completeRecreateCancelledTransferReceivers: "completeRecreateCancelledTransferReceivers",
  prepareUpdateStrategyRiskLevel: "prepareUpdateStrategyRiskLevel",
  createFinsharkPaymentRequest: "createFinsharkPaymentRequest",
  swishPaymentRequest: "swishPaymentRequest",
  pollSwishTransactionStatus: "pollSwishTransactionStatus",
  loadIndexOverTime: "loadIndexOverTime",
  saveEditedEsgFilters: "saveEditedEsgFilters",
  loadAllPortfolios: "loadAllPortfolios",
  prepareUpdateStrategy: "prepareUpdateStrategy",
  completeUpdateStrategy: "completeUpdateStrategy",
  loadPerformance: "loadPerformance",
  getAccountDetailsPortfolio: "getAccountDetailsPortfolio",
  loadPortfolioSettings: "loadPortfolioSettings",
  prepareStartClosingPortfolio: "prepareStartClosingPortfolio",
  startClosingPortfolio: "startClosingPortfolio",
  saveClosePortfolioQuestionnaire: "saveClosePortfolioQuestionnaire",
  loadPaiStats: "loadPaiStats",
};

const portfoliosClient = new Client(baseUrl);
const savingsGoalsClient = new SavingsGoalsClient(baseUrl);
const portfoliosHoldingsClient = new PortfoliosHoldingsClient(baseUrl);
const portfolioPerformanceClient = new PortfolioPerformanceClient(baseUrl);
const portfoliosSettingsClient = new PortfoliosSettingsClient(baseUrl);
const strategyClient = new StrategiesClient(baseUrl);
const depositClient = new DepositClient(baseUrl);
const withdrawalsClient = new WithdrawalsClient(baseUrl);
const portfolioPredictionClient = new PortfolioPredictionClient(baseUrl);
const portfolioDividendClient = new PortfoliosDividendClient(baseUrl);
const transactionsClient = new TransactionsClient(baseUrl);
const eventsClient = new EventsClient(baseUrl);
const swishClient = new SwishClient(baseUrl);
const tinkAccountClient = new TinkAccountClient(baseUrl);
const paiStatsClient = new PaiStatsClient(baseUrl);

// TODO: Hur skilja på transaction event och date event i komponenten? Ha typ för dom också? Bökigt!!!!
function getNextDateEvent(events: DateEvent[] | undefined): DateEvent | null {
  if (!events)
    return null;

  const buyEvents = events.filter(e => e.type === DateEventType.Buy);

  // Note that the order here signifies order of priority for which event to show.
  if (buyEvents.length > 0)
    return buyEvents[0];

  return null;
}

async function loadPortfolioHoldings(
  commit: Commit,
  legalEntityId: string,
  portfolioId: string,
): Promise<void> {
  commit(PortfolioMutation.isLoadingHoldingSummary, true);
  commit(PortfolioMutation.setHoldingSummary, undefined);
  await portfoliosHoldingsClient
    .get(legalEntityId, portfolioId)
    .then((holdingSummary) => {
      commit(PortfolioMutation.setHoldingSummary, {
        portfolioId,
        holdingSummary,
      });
      commit(PortfolioMutation.isLoadingHoldingSummary, false);
      commit(PortfolioMutation.setHoldingsError, false);
    })
    .catch((_: any) => {
      commit(PortfolioMutation.isLoadingHoldingSummary, false);
      commit(PortfolioMutation.setHoldingsError, true);
    });
}

async function loadSavingsGoal(
  commit: Commit,
  legalEntityId: string,
  portfolioId: string,
): Promise<void> {
  commit(PortfolioMutation.setSavingsGoal, undefined);
  await savingsGoalsClient
    .getPortfolioSavingsGoal(legalEntityId, portfolioId)
    .then((savingsGoals) => {
      commit(
        PortfolioMutation.setSavingsGoal,
        savingsGoals ? (savingsGoals as SavingsGoal) : undefined,
      );
    });
}

async function loadDividend(commit: Commit, dispatch: Dispatch): Promise<void> {
  commit(PortfolioMutation.resetDividend);
  await dispatch(PortfolioAction.loadDividend, new Date().getFullYear());
}

export async function loadPortfolioSettings(
  commit: Commit,
  legalEntityId: string,
  portfolioId: string,
): Promise<void> {
  commit(PortfolioMutation.setPortfolioSettings, undefined);
  const portfolioSettings = await portfoliosSettingsClient
    .getByPortfolioId(legalEntityId, portfolioId)
    .catch((error) => {
      // TODO Sätt error state, Task: https://trello.com/c/u0lqYvFm.

      console.error(error.message);
    });
  if (portfolioSettings) {
    commit(PortfolioMutation.setPortfolioSettings, portfolioSettings);
  }
}

async function loadDeposits(
  commit: Commit,
  legalEntityBrickId: string,
  portfolioId: string,
  quantity: number,
): Promise<void> {
  commit(PortfolioMutation.setDeposits, undefined);
  await depositClient
    .getAll(legalEntityBrickId, portfolioId, quantity)
    .then((deposits) => {
      commit(PortfolioMutation.setDeposits, deposits);
    })
    .catch((error) => {
      // TODO Sätt error state, Task: https://trello.com/c/FZ1Fyhx5

      console.error(error.message);
    });
}

async function loadPortfolioPerformance(
  commit: Commit,
  legalEntityId: string,
  portfolioId: string,
  startDate: Date,
): Promise<void> {
  commit(OverviewMutation.setPerformanceLoading, { portfolioId, loading: true });
  await portfolioPerformanceClient
    .get(legalEntityId, portfolioId, startDate, new Date(), false)
    .then((portfolioPerformance) => {
      commit(OverviewMutation.setPerformanceLoading, { portfolioId, loading: false });
      commit(OverviewMutation.setPerformance, {
        portfolioId,
        performance: portfolioPerformance,
      });
      commit(PortfolioMutation.setPerformanceError, false);
    })
    .catch((error) => {
      commit(OverviewMutation.setPerformanceLoading, { portfolioId, loading: false });
      commit(PortfolioMutation.setPerformanceError, true);
    });
}

export const actions: ActionTree<PortfolioState, RootState> = {
  async [PortfolioAction.cancelDeposit](
    { state, commit, dispatch, rootState },
    depositExternalId: string,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    return depositClient
      .cancel(
        rootState.userStore.currentLegalEntity.brickId,
        state.activePortfolioBase.id,
        depositExternalId,
      )
      .then(() => {
        commit(PortfolioMutation.removeDeposit, depositExternalId);
      })
      .catch(() => {
        // eslint-disable-next-line ts/prefer-ts-expect-error, ts/ban-ts-comment
        // @ts-ignore
        dispatch(UserAction.addSnackbarMessage, i18n.global.t("errors.cancelTransactionFailed"));
      });
  },
  async [PortfolioAction.cancelWithdrawal](
    { state, commit, dispatch, rootState },
    withdrawalId: number,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    return withdrawalsClient
      .cancel(
        rootState.userStore.currentLegalEntity.brickId,
        state.activePortfolioBase.id,
        withdrawalId,
      )
      .then(() => {
        commit(PortfolioMutation.removeWithdrawal, withdrawalId);
      })
      .catch(() => {
        // eslint-disable-next-line ts/prefer-ts-expect-error, ts/ban-ts-comment
        // @ts-ignore
        dispatch(UserAction.addSnackbarMessage, i18n.global.t("errors.cancelTransactionFailed"));
      });
  },
  async [PortfolioAction.prepareUpdatePortfolioSettings]({
    state,
    commit,
    rootState,
  }): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { editedPortfolioSettings } = state;
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }

    if (!editedPortfolioSettings) {
      throw new Error("No edited portfolio settings in state");
    }
    const updateRequest = new UpdatePortfolioSettings({
      accountName: editedPortfolioSettings.accountName,
      accountNumber: editedPortfolioSettings.accountNumber,
      clearingNumber: editedPortfolioSettings.clearingNumber,
      bankName: editedPortfolioSettings.bankName,
      monthlySaving: editedPortfolioSettings.monthlySaving as number,
      pauseMonthlySavingsMonths: editedPortfolioSettings.pauseMonthlySavingsMonths,
    });

    try {
      const response = await portfoliosSettingsClient.prepareUpdate(
        rootState.userStore.currentLegalEntity.brickId,
        state.activePortfolioBase.id,
        updateRequest,
      );
      commit(BankIdMutation.setTransactionId, response?.id);
      commit(UserMutation.setAgreementsToSign, response);
    } catch (e: any) {
      console.error(e);
      console.error(e.message);
      console.error(e.status);
      console.error(e.response);
      console.error(e.headers);
      commit(PortfolioMutation.setSaveSettingsError, true);
    }
  },

  async [PortfolioAction.completeUpdatePortfolioSettings]({
    state,
    dispatch,
    commit,
    rootState,
  }): Promise<UpdatePortfolioSettingsResult | null | undefined> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No transactionId in state");
    }
    try {
      const result = await portfoliosSettingsClient.completeUpdate(
        rootState.userStore.currentLegalEntity.brickId,
        state.activePortfolioBase.id,
        rootState.bankIdStore.transactionId,
      );

      if (result?.portfolioSettings) {
        commit(PortfolioMutation.setPortfolioSettings, result?.portfolioSettings);
        dispatch(PortfolioAction.loadPortfolioEvents, state.activePortfolioBase.id);
        loadDeposits(
          commit,
          rootState.userStore.currentLegalEntity.brickId,
          state.activePortfolioBase.id,
          1,
        );
      }

      return result;
    } catch (e: any) {
      console.error(e);
      console.error(e.message);
      console.error(e.status);
      console.error(e.response);
      console.error(e.headers);
      commit(PortfolioMutation.setSaveSettingsError, true);
    }
  },

  async [PortfolioAction.loadPortfolioStrategy](
    { commit, rootState },
    portfolioId: string,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const strategy = await strategyClient
      .get(rootState.userStore.currentLegalEntity.brickId, portfolioId)
      .catch((error) => {
        // TODO Sätt error state, task: https://trello.com/c/wx0JPKOz.

        console.error(error.message);
      });
    if (strategy) {
      commit(PortfolioMutation.setStrategy, strategy);
    }
  },

  async [PortfolioAction.setActivePortfolio](
    { state, commit, dispatch, rootGetters },
    portfolioId: string,
  ): Promise<void> {
    const { activePortfolioBase } = state;
    if (activePortfolioBase === undefined || activePortfolioBase.id !== portfolioId) {
      const newPortfolioBase = rootGetters.portfolioBase(portfolioId);
      // This problem occurs on login when loadPortfolios seems to not be resolved before that action is
      // dispatched thus resolves in the portfolioBase being undefined.
      // But this method is called at portfolio mounted and beforeRouteUpdate so it resolves itself.
      if (newPortfolioBase) {
        commit(PortfolioMutation.setActivePortfolioBase, newPortfolioBase);
        dispatch(PortfolioAction.loadActivePortfolioDependencies);
      }
    }
  },
  async [PortfolioAction.loadActivePortfolioDependencies]({
    state,
    commit,
    dispatch,
    getters,
    rootState,
  }): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const { activePortfolioBase } = state;
    if (!activePortfolioBase) {
      throw new Error("Portfolios does not exist");
    }
    const portfolioDependentLoaders = [
      loadSavingsGoal(
        commit,
        rootState.userStore.currentLegalEntity.brickId,
        activePortfolioBase.id,
      ),
      loadPortfolioHoldings(
        commit,
        rootState.userStore.currentLegalEntity.brickId,
        activePortfolioBase.id,
      ),
      loadPortfolioSettings(
        commit,
        rootState.userStore.currentLegalEntity.brickId,
        activePortfolioBase.id,
      ),
      dispatch(PortfolioAction.loadPortfolioStrategy, activePortfolioBase.id),
      //
      dispatch(PortfolioAction.loadPortfolioEvents, activePortfolioBase.id),
      loadDeposits(
        commit,
        rootState.userStore.currentLegalEntity.brickId,
        activePortfolioBase.id,
        1,
      ),
      loadDividend(commit, dispatch),
    ];
    // May already be loaded in overview
    if (!rootState.overviewStore.portfolioPerformances[activePortfolioBase.id]) {
      portfolioDependentLoaders.push(
        loadPortfolioPerformance(
          commit,
          rootState.userStore.currentLegalEntity.brickId,
          activePortfolioBase.id,
          activePortfolioBase.createdAt,
        ),
      );
    }
    await Promise.all(portfolioDependentLoaders);
  },

  async [PortfolioAction.loadPortfolioEvents](
    { commit, rootState },
    portfolioId: string,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    commit(PortfolioMutation.setTransactionEvents, undefined);
    commit(PortfolioMutation.setLoadingEvents, true);
    try {
      await eventsClient
        .getEvents(rootState.userStore.currentLegalEntity.brickId, portfolioId)
        .then((events) => {
          commit(PortfolioMutation.setLoadingEvents, false);
          if (events === null) {
            return;
          }
          commit(PortfolioMutation.setTransactionEvents, events.transactionEvents || []);
          const nextDateEvent = getNextDateEvent(events.dateEvents);
          if (nextDateEvent) {
            commit(PortfolioMutation.setNextDateEvent, nextDateEvent);
          }
          commit(PortfolioMutation.setNextTradingDay, events.nextTradingDay || null);
          commit(PortfolioMutation.setYesterdaysTradingDay, events.yesterdaysTradingDay || null);
        });
    } catch (e) {
      console.error(e);
      commit(PortfolioMutation.setLoadingEvents, false);
    }
  },

  async [PortfolioAction.loadBackcast](
    { commit, rootState },
    request: BackcastRequest,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    commit(PortfolioMutation.isLoadingBackcast, true);
    return portfolioPredictionClient
      .getBackcast(rootState.userStore.currentLegalEntity.brickId, request)
      .then((backcast) => {
        commit(PortfolioMutation.setBackcast, backcast);
        commit(PortfolioMutation.isLoadingBackcast, false);
      })
      .catch((error) => {
        // TODO Sätt error state, Task: https://trello.com/c/cQLSIn2B

        console.error(error.message);
        commit(PortfolioMutation.isLoadingBackcast, false);
      });
  },

  async [PortfolioAction.loadTransactions](
    { state, commit, rootState },
    { start, end },
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    commit(PortfolioMutation.setIsLoadingTransactions, true);
    commit(PortfolioMutation.setTransactionList, undefined);

    const { activePortfolioBase } = state;
    if (!activePortfolioBase) {
      commit(PortfolioMutation.setIsLoadingTransactions, false);
      commit(PortfolioMutation.setTransactionsError, true);
      throw new Error("No portfolio in state");
    }
    await transactionsClient
      .getAll(rootState.userStore.currentLegalEntity.brickId, activePortfolioBase.id, start, end)
      .then((transactionList) => {
        commit(PortfolioMutation.setIsLoadingTransactions, false);
        commit(PortfolioMutation.setTransactionsError, false);
        if (transactionList) {
          commit(PortfolioMutation.setTransactionList, transactionList);
        }
      })
      .catch((error) => {
        commit(PortfolioMutation.setIsLoadingTransactions, false);
        commit(PortfolioMutation.setTransactionsError, true);
      });
  },

  async [PortfolioAction.prepareDeposit](
    { state, rootState, commit },
    depositAmount,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const params = new DepositRequest({
      amount: depositAmount as number,
    });
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    const response = await depositClient.prepareCreate(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
      params,
    );
    commit(BankIdMutation.setTransactionId, response?.id);
    commit(UserMutation.setAgreementsToSign, response);
  },
  [PortfolioAction.deposit]({ state, rootState }): Promise<CreateDepositResponse | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No transactionId in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    return depositClient.completeCreate(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
      rootState.bankIdStore.transactionId,
    );
  },

  async [PortfolioAction.prepareWithdraw](
    { state, rootState, commit },
    requestedFullWithdrawal,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const params = new WithdrawalRequest({
      amount: Number(state.withdrawAmount),
      requestedFullWithdrawal,
    });

    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    const response = await withdrawalsClient.prepareCreate(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
      params,
    );
    commit(BankIdMutation.setTransactionId, response?.id);
    commit(UserMutation.setAgreementsToSign, response);
  },

  [PortfolioAction.withdraw]({ state, rootState }): Promise<Withdrawal | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No transactionId in state");
    }
    return withdrawalsClient.completeCreate(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
      rootState.bankIdStore.transactionId,
    );
  },
  async [PortfolioAction.prepareStartClosingPortfolio](
    { state, rootState, commit },
    requestedFullWithdrawal,
  ): Promise<void> {
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const response = await portfoliosClient.putApiLegalEntitiesPortfoliosStartClosingPrepare(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
    );
    commit(BankIdMutation.setTransactionId, response?.id);
    commit(UserMutation.setAgreementsToSign, response);
  },

  async [PortfolioAction.startClosingPortfolio]({ state, commit, rootState }): Promise<Withdrawal> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No transactionId in state");
    }
    const result = await portfoliosClient.putApiLegalEntitiesPortfoliosStartClosingComplete(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
      rootState.bankIdStore.transactionId,
    );
    const activePortfolioBase = { ...state.activePortfolioBase };
    activePortfolioBase.isClosing = true;
    commit(PortfolioMutation.setActivePortfolioBase, activePortfolioBase);
    return result;
  },

  // [PortfolioAction.deletePortfolio]({ state }): Promise<ErrorResponse | null> {
  //   if (!state.activePortfolioBase) {
  //     throw new Error("No portfolio in state");
  //   }
  //   return portfoliosClient.closePortfolio(state.activePortfolioBase.id);
  // },

  async [PortfolioAction.renamePortfolio](
    { state, commit, dispatch, rootState },
    portfolioName,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    const activePortfolioBase = new PortfolioBase({
      ...state.activePortfolioBase,
      name: portfolioName,
    });

    await portfoliosClient
      .putApiLegalEntitiesPortfolios(
        rootState.userStore.currentLegalEntity.brickId,
        activePortfolioBase.id,
        activePortfolioBase,
      )
      .catch((error) => {
        // TODO move string to somewhere else
        dispatch(UserAction.addSnackbarMessage, "Ändringen kunde inte sparas");
      });

    const newPortfolioBase = await portfoliosClient.getApiLegalEntitiesPortfolios(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
    );
    commit(PortfolioMutation.setActivePortfolioBase, newPortfolioBase);
    commit(NavigationMutation.updatePortfolioName, newPortfolioBase);

    // Update navigation items
    dispatch(NavigationAction.loadPortfolios);
  },

  async [PortfolioAction.saveEditedDiscardedSectors]({ state, dispatch }): Promise<void> {
    const { editedStrategy, strategy } = state;
    if (!editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (!strategy) {
      throw new Error("No strategy in state");
    }

    const updatedStrategy = new Strategy({
      ...strategy,
      ...{
        defenseDiscarded: editedStrategy.defenseDiscarded,
        alcoholDiscarded: false,
        alcoholTobaccoDiscarded: editedStrategy.alcoholTobaccoDiscarded,
        tobaccoDiscarded: false,
        fossilFuelsDiscarded: editedStrategy.fossilFuelsDiscarded,
        gamblingDiscarded: editedStrategy.gamblingDiscarded,
      },
    });
    return dispatch(PortfolioAction.prepareUpdateStrategy, updatedStrategy);
  },

  async [PortfolioAction.saveEditedRiskLevel]({ state, dispatch }): Promise<void> {
    const { editedStrategy, strategy } = state;
    if (!editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (!strategy) {
      throw new Error("No strategy in state");
    }

    const level = editedStrategy.chosenRiskLevel;

    const updatedStrategy = new Strategy({
      ...strategy,
      chosenRiskLevel: level,
    });
    return dispatch(PortfolioAction.prepareUpdateStrategy, updatedStrategy);
  },

  async [PortfolioAction.saveEditedRiskProfile]({ state, dispatch }): Promise<void> {
    const { editedStrategy, strategy } = state;
    if (!editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (!strategy) {
      throw new Error("No strategy in state");
    }

    const profile = editedStrategy.chosenPensionRiskProfile;

    const updatedStrategy = new Strategy({
      ...strategy,
      chosenPensionRiskProfile: profile,
    });
    return dispatch(PortfolioAction.prepareUpdateStrategy, updatedStrategy);
  },

  async [PortfolioAction.saveEditedMarket]({ state, dispatch }): Promise<void> {
    const { editedStrategy, strategy } = state;
    if (!editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (!strategy) {
      throw new Error("No strategy in state");
    }

    const market = editedStrategy.marketFocus;

    const updatedStrategy = new Strategy({
      ...strategy,
      marketFocus: market,
    });
    return dispatch(PortfolioAction.prepareUpdateStrategy, updatedStrategy);
  },

  async [PortfolioAction.saveEditedFocus]({ state, dispatch }): Promise<void> {
    const { editedStrategy, strategy } = state;
    if (!editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (!strategy) {
      throw new Error("No strategy in state");
    }

    const { focus, tiedAgentPortfolioThemeType } = editedStrategy;

    const updatedStrategy = new Strategy({
      ...strategy,
      ...{
        focus,
        tiedAgentPortfolioThemeType,
      },
    });
    return dispatch(PortfolioAction.prepareUpdateStrategy, updatedStrategy);
  },

  async [PortfolioAction.saveEditedEsgFilters]({ state, dispatch }): Promise<void> {
    const { editedStrategy, strategy } = state;
    if (!editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (!strategy) {
      throw new Error("No strategy in state");
    }

    const updatedStrategy = new Strategy({
      ...strategy,
      ...{
        esgBiodiversityFilter: editedStrategy.esgBiodiversityFilter,
        esgEmissionsFilter: editedStrategy.esgEmissionsFilter,
        esgGovernanceFilter: editedStrategy.esgGovernanceFilter,
        esgHumanRightsFilter: editedStrategy.esgHumanRightsFilter,
        esgResourcesFilter: editedStrategy.esgResourcesFilter,
      },
      esgPais: editedStrategy.esgPais,
    });
    return dispatch(PortfolioAction.prepareUpdateStrategy, updatedStrategy);
  },

  [PortfolioAction.getStrategy]({ state, rootState }): Promise<Strategy | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    return strategyClient.get(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
    );
  },

  async [PortfolioAction.saveEditedStrategy]({ state, dispatch }): Promise<void> {
    if (!state.editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    return dispatch(PortfolioAction.prepareUpdateStrategy, state.editedStrategy);
  },

  async [PortfolioAction.prepareUpdateStrategy](
    { state, commit, rootState },
    strategy,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    const request = new UpdateStrategyRequest({
      strategy,
    });
    const { id } = state.activePortfolioBase;

    try {
      const response = await strategyClient.prepareUpdate(
        rootState.userStore.currentLegalEntity.brickId,
        id,
        request,
      );
      commit(BankIdMutation.setTransactionId, response?.id);
      commit(UserMutation.setAgreementsToSign, response);
    } catch (e) {
      console.error("failed to prepare update strategy", e);
      const err = e as any;
      console.error(err.message);
      console.error(err.status);
      console.error(err.response);
      console.error(err.headers);
      commit(PortfolioMutation.setSaveSettingsError, true);
    }
  },

  async [PortfolioAction.completeUpdateStrategy](
    { state, commit, rootState },
    portfolioId,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No transactionId in state");
    }
    if (!state.activePortfolioBase && !portfolioId) {
      throw new Error("No portfolio in state");
    }

    const id = portfolioId || state.activePortfolioBase?.id;

    try {
      const strategy = await strategyClient.completeUpdate(
        rootState.userStore.currentLegalEntity.brickId,
        id,
        rootState.bankIdStore.transactionId,
      );
      commit(PortfolioMutation.setStrategy, strategy);
    } catch (e) {
      console.error("failed complete", e);
      commit(PortfolioMutation.setSaveSettingsError, true);
    }
  },

  async [PortfolioAction.addSavingsGoal](
    { state, rootState },
    requestObject,
  ): Promise<void | SavingsGoal | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }

    return savingsGoalsClient.create(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
      requestObject,
    );
  },
  async [PortfolioAction.updateSavingsGoal](
    { state, rootState },
    requestObject,
  ): Promise<void | SavingsGoal | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }

    return savingsGoalsClient.update(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
      requestObject,
    );
  },
  async [PortfolioAction.removePortfolioSavingsGoal]({
    state,
    rootState,
  }): Promise<void | boolean | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase || !state.savingsGoal) {
      throw new Error("No portfolio or savingsgoal in state");
    }
    return savingsGoalsClient.removePortfolioSavingsGoal(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
    );
  },
  async [PortfolioAction.saveEditedTimeToWithdraw]({ state, dispatch }): Promise<void> {
    const { editedStrategy, strategy } = state;
    if (!editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (!strategy) {
      throw new Error("No strategy in state");
    }

    const { timeToWithdraw } = editedStrategy;

    // No need to update if time to withdraw is unchanged.
    if (timeToWithdraw !== strategy.timeToWithdraw) {
      const updatedStrategy = new Strategy({
        ...strategy,
        timeToWithdraw,
      });
      return dispatch(PortfolioAction.prepareUpdateStrategy, updatedStrategy);
    }
    return Promise.resolve();
  },
  async [PortfolioAction.loadDividend]({ state, commit, rootState }, year): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      commit(PortfolioMutation.setDividendError, true);
      throw new Error("No portfolio in state");
    }
    commit(PortfolioMutation.setIsLoadingPortfolioDividend, true);
    commit(PortfolioMutation.resetDividend);
    const { id } = state.activePortfolioBase;
    await portfolioDividendClient
      .get(
        rootState.userStore.currentLegalEntity.brickId,
        id,
        new Date(year, 0, 1, 2, 0, 0),
        new Date(year, 11, 31, 23, 59, 59),
      )
      .then((dividend) => {
        commit(PortfolioMutation.setDividendError, false);
        commit(PortfolioMutation.setIsLoadingPortfolioDividend, false);
        if (dividend) {
          commit(PortfolioMutation.setDividend, { year, dividend });
        }
      })
      .catch((error: any) => {
        commit(PortfolioMutation.setDividendError, true);
        commit(PortfolioMutation.setIsLoadingPortfolioDividend, false);
      });
  },
  async [PortfolioAction.getOngoingWithdrawals]({ state, commit, rootState }): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    let portfolioId = "";
    if (state.activePortfolioBase) {
      portfolioId = state.activePortfolioBase.id;
    }
    commit(PortfolioMutation.setLoadingOngoingWithdrawals, true);
    await withdrawalsClient
      .getOngoing(rootState.userStore.currentLegalEntity.brickId, portfolioId)
      .then((response: OngoingWithdrawalsSummary | null) => {
        if (response) {
          const hasSellWithdrawal = response.ongoingWithdrawals?.some(
            x =>
              x.status === WithdrawalStatus.SellOrderSentToBricknode
              || (response.isManagePortfoliosRunning && x.status === WithdrawalStatus.CreatedLocally),
          );
          const hasWithdrawalOrderSent = response.ongoingWithdrawals?.some(
            x =>
              x.status === WithdrawalStatus.WithdrawalOrderSentToBricknode
              || x.status === WithdrawalStatus.CompanyWithdrawalAwaitingApproval,
          );
          commit(PortfolioMutation.setHasSellWithdrawal, hasSellWithdrawal);
          commit(PortfolioMutation.setHasWithdrawalOrderSent, hasWithdrawalOrderSent);
          commit(
            PortfolioMutation.setAvailableCashForWithdrawal,
            response.availableCashForWithdrawal,
          );
          commit(PortfolioMutation.setAmountCanBeWithdrawn, response.amountCanBeWithdrawn);
          commit(
            PortfolioMutation.setOngoingWithdrawalSum,
            response.ongoingWithdrawals?.reduce((a, b) => a + (b.amount || 0), 0),
          );
          commit(PortfolioMutation.setOngoingWithdrawals, response.ongoingWithdrawals);
        }
        commit(PortfolioMutation.setLoadingOngoingWithdrawals, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PortfolioMutation.setLoadingOngoingWithdrawals, false);
      });
  },
  async [PortfolioAction.getNote](
    { state, rootState },
    payload: { businessEventId: string; date: Date },
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No active portfolio base in state");
    }
    await transactionsClient
      .getTransactionNote(
        rootState.userStore.currentLegalEntity.brickId,
        state.activePortfolioBase.id,
        payload.businessEventId,
        payload.date,
      )
      .then((response: FileResponse | null) => {
        downloadFileResponse(response);
        return Promise.resolve();
      })
      .catch((err: any) => {
        console.error(err);
        return Promise.reject();
      });
  },
  async [PortfolioAction.getExchangeNote](
    { state, rootState },
    payload: { orderId: string; date: Date },
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No active portfolio base in state");
    }
    await transactionsClient
      .getExchangeTransactionNote(
        rootState.userStore.currentLegalEntity.brickId,
        state.activePortfolioBase.id,
        payload.orderId,
      )
      .then((response: FileResponse | null) => {
        downloadFileResponse(response);
        return Promise.resolve();
      })
      .catch((err: any) => {
        console.error(err);
        return Promise.reject();
      });
  },
  async [PortfolioAction.loadDetailedHolding](
    { state, commit, rootState },
    assetId,
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    const { id } = state.activePortfolioBase;
    commit(PortfolioMutation.setLoadingDetailedHoldingError, false);
    commit(PortfolioMutation.setIsLoadingDetailedHolding, true);
    await portfoliosHoldingsClient
      .getDetailedHolding(rootState.userStore.currentLegalEntity.brickId, id, assetId)
      .then((response) => {
        if (response) {
          commit(PortfolioMutation.setDetailedHolding, response);
          commit(PortfolioMutation.setIsLoadingDetailedHolding, false);
        } else {
          commit(PortfolioMutation.setLoadingDetailedHoldingError, true);
        }
        commit(PortfolioMutation.setIsLoadingDetailedHolding, false);
      })
      .catch((error) => {
        commit(PortfolioMutation.setIsLoadingDetailedHolding, false);
        commit(PortfolioMutation.setLoadingDetailedHoldingError, true);

        console.error(error.message);
      });
  },
  async [PortfolioAction.saveEditedExcludedCompanies]({ state, dispatch }): Promise<void> {
    const { editedStrategy, strategy } = state;
    if (!editedStrategy) {
      throw new Error("No edited strategy in state");
    }
    if (!strategy) {
      throw new Error("No strategy in state");
    }

    const { excludedCompanies } = editedStrategy;
    const updatedStrategy = new Strategy({
      ...strategy,
      ...{
        excludedCompanies,
      },
    });
    return dispatch(PortfolioAction.prepareUpdateStrategy, updatedStrategy);
  },
  async [PortfolioAction.prepareRecreateCancelledTransferReceivers]({
    state,
    commit,
    rootState,
  }): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    const response = await portfoliosClient.postApiLegalEntitiesPortfoliosRecreateTransferReceiversPrepare(
      rootState.userStore.currentLegalEntity.brickId,
      state.activePortfolioBase.id,
    );

    commit(BankIdMutation.setTransactionId, response?.id);
    commit(UserMutation.setAgreementsToSign, response);
  },
  async [PortfolioAction.completeRecreateCancelledTransferReceivers]({
    state,
    commit,
    rootState,
  }): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No valid sign token in state");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No portfolio in state");
    }
    await portfoliosClient
      .postApiLegalEntitiesPortfoliosRecreateTransferReceiversComplete(
        rootState.userStore.currentLegalEntity.brickId,
        state.activePortfolioBase.id,
        rootState.bankIdStore.transactionId,
      )
      .then((response: PortfolioBase | null) => {
        if (response) {
          commit(NavigationMutation.setPortfolioTransferReceiverAsCreated, response);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  },
  async [PortfolioAction.prepareUpdateStrategyRiskLevel](
    { rootState, commit },
    { portfolioId, riskLevel },
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    try {
      const response = await strategyClient.prepareUpdateStrategyRiskLevel(
        rootState.userStore.currentLegalEntity.brickId,
        portfolioId,
        new UpdateStrategyRiskLevelRequest({
          riskLevel,
        }),
      );
      commit(BankIdMutation.setTransactionId, response?.id);
      commit(UserMutation.setAgreementsToSign, response);
      if (response) {
        commit(PortfolioMutation.setStrategy, response);
      }
      if (!response) {
        throw new Error("Could not update risk level");
      }
    } catch (error: any) {
      console.error(error.message);
      throw error;
    }
  },
  async [PortfolioAction.createFinsharkPaymentRequest](
    { rootState, commit, state },
    amount: number,
  ): Promise<void> {
    // if (!rootState.userStore.currentLegalEntity) {
    //   throw new Error("No current legal entity in state");
    // }
    // if (!state.activePortfolioBase) {
    //   throw new Error("No portfolio in state");
    // }
    // try {
    //   const response = await finsharkClient.create(
    //     rootState.userStore.currentLegalEntity.brickId,
    //     state.activePortfolioBase.id,
    //     new CreateRequest({ amount })
    //   );
    //   if (response) {
    //     commit(PortfolioMutation.setFinsharkPaymentId, response.paymentId);
    //   }
    //   if (!response) {
    //     throw new Error("Could not create finshark payment request");
    //   }
    // } catch (error: any) {
    //   // eslint-disable-next-line no-console
    //   console.error(error);
    //   throw error;
    // }
  },
  async [PortfolioAction.swishPaymentRequest](
    { commit, state, rootState },
    { amount, phoneNumber }: { amount: number; phoneNumber: string },
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity brick id in store");
    }
    if (!state.activePortfolioBase) {
      throw new Error("No active portfolio in store");
    }

    commit(PortfolioMutation.setSwishToken, undefined);
    commit(PortfolioMutation.setSwishTransactionStatus, undefined);
    commit(PortfolioMutation.setSwishPaymentReference, undefined);
    try {
      const response = await swishClient.createPayment(
        rootState.userStore.currentLegalEntity.brickId,
        state.activePortfolioBase.id,
        new SwishPaymentRequest({ amount, phoneNumber }),
      );
      if (response) {
        commit(PortfolioMutation.setSwishPaymentReference, response.paymentReference);
        commit(PortfolioMutation.setSwishToken, response.token);
        commit(
          PortfolioMutation.setSwishTransactionStatus,
          new SwishTransactionStatus({
            status: SwishPaymentStatus.Pending,
            amount,
            errorCode: undefined,
            errorMessage: undefined,
          }),
        );
      } else {
        commit(
          PortfolioMutation.setSwishTransactionStatus,
          new SwishTransactionStatus({
            status: SwishPaymentStatus.Error,
            amount: 0,
            errorCode: undefined,
            errorMessage: undefined,
          }),
        );
      }
    } catch (error: any) {
      console.error(error.message);
      commit(
        PortfolioMutation.setSwishTransactionStatus,
        new SwishTransactionStatus({
          status: SwishPaymentStatus.Error,
          amount: 0,
          errorCode: undefined,
          errorMessage: undefined,
        }),
      );
    }
  },
  async [PortfolioAction.pollSwishTransactionStatus]({ commit, state, rootState }): Promise<void> {
    if (!state.swishPaymentReference) {
      throw new Error("No payment refence in state");
    }
    try {
      await createPollingPromise(async () => {
        if (!rootState.userStore.currentLegalEntity) {
          throw new Error("No current legal entity brick id in store");
        }
        if (!state.activePortfolioBase) {
          throw new Error("No active portfolio in store");
        }
        const response = await swishClient.getTransactionStatus(
          rootState.userStore.currentLegalEntity.brickId,
          state.activePortfolioBase.id,
          state.swishPaymentReference as string | null,
        );
        if (response) {
          commit(PortfolioMutation.setSwishTransactionStatus, response);
          return Promise.resolve(response.status !== SwishPaymentStatus.Pending);
        }
        commit(
          PortfolioMutation.setSwishTransactionStatus,
          new SwishTransactionStatus({
            status: SwishPaymentStatus.Error,
            amount: 0,
            errorCode: undefined,
            errorMessage: undefined,
          }),
        );
        return Promise.resolve(true);
      }, constants.swishPollingDelay);
    } catch (error: any) {
      commit(
        PortfolioMutation.setSwishTransactionStatus,
        new SwishTransactionStatus({
          status: SwishPaymentStatus.ServerUnreachable,
          amount: 0,
          errorCode: undefined,
          errorMessage: undefined,
        }),
      );
    }
  },
  async [PortfolioAction.loadAllPortfolios]({ state, commit, rootState }): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const portfolios = await portfoliosClient.getApiLegalEntitiesPortfoliosAll(
      rootState.userStore.currentLegalEntity.brickId,
    );
    commit(PortfolioMutation.setAllPortfolios, portfolios);
  },
  async [PortfolioAction.loadPerformance](
    { state, commit, getters, rootState },
    portfolioId: string,
  ) {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const portfolio = rootState.navigationStore.portfolios?.find(x => x.id === portfolioId);
    if (!portfolio) {
      throw new Error("No portfolio found");
    }
    loadPortfolioPerformance(
      commit,
      rootState.userStore.currentLegalEntity.brickId,
      portfolioId,
      portfolio.createdAt,
    );
  },
  async [PortfolioAction.getAccountDetailsPortfolio](
    { rootState },
    code: string,
  ): Promise<TinkAccount | undefined> {
    const request = new TinkRequest({
      tinkCode: code,
      personalIdentityNumber: rootState.userStore.currentLegalEntity?.personalIdentityNumber,
    });

    let response: TinkAccountResponse | null = null;
    try {
      response = await tinkAccountClient.get(request);
    } catch (error: any) {
      throw new Error("Failed to get Tink account");
    }

    let result: TinkAccount | undefined;
    if (response) {
      // Tink test environment does not return any SSN so manually set it here
      if (
        import.meta.env.VITE_ENV === "development"
          && !response.personalIdentityNumber
          && !rootState.userStore.currentLegalEntity?.personalIdentityNumber
      ) {
        response.personalIdentityNumber = "197705311467";
      }
      if (response.accountNumber) {
        // Unfortunately we need to parse the account number since Bricknode only accepts the combined
        // clearing and account number for an external account.
        const kontonummer = new Kontonummer(response.accountNumber, {
          mode: "lax",
        });
        const bank: Bank = {
          bankName: kontonummer.bankName as string,
          accountNumber: kontonummer.accountNumber,
          clearingNumber: kontonummer.sortingCode,
          valid: kontonummer.valid,
        };
        if (bank && bank.valid) {
          result = {
            bank: response.bank,
            accountNumber: bank.accountNumber,
            clearingNumber: bank.clearingNumber,
            fullAccountNumber: response.accountNumber,
            accountName: response.accountName,
            personalIdentityNumber: response.personalIdentityNumber,
          };
        } else {
          throw new Error("Failed to parse bank from account number");
        }
      } else {
        throw new Error("No account number in Tink response");
      }
    }
    return result;
  },
  async [PortfolioAction.loadPortfolioSettings](
    { commit },
    { legalEntityId, portfolioId }: { legalEntityId: string; portfolioId: string },
  ): Promise<void> {
    loadPortfolioSettings(commit, legalEntityId, portfolioId);
  },
  async [PortfolioAction.saveClosePortfolioQuestionnaire](
    { commit, rootState },
    { portfolioId, questionnaire }: { portfolioId: string; questionnaire: ClosePortfolioQuestionnaire },
  ): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity brick id in store");
    }
    portfoliosClient.putApiLegalEntitiesPortfoliosQuestionnaire(rootState.userStore.currentLegalEntity.brickId, portfolioId, questionnaire);
  },
  async [PortfolioAction.loadPaiStats](
    { commit },
  ): Promise<void> {
    const response = await paiStatsClient.getPaiStats();
    commit(PortfolioMutation.setPaiStats, response);
  },
};
