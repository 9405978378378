<template>
  <div class="deposit">
    <icon
      fixed-width
      :icon="['fal', 'money-bill-wave']"
      class="deposit__icon"
    />
    <h2 class="deposit__title">
      {{ $t("depositTitle") }}
    </h2>
    <icon
      :icon="['fas', 'chevron-right']"
      class="deposit__forward-icon"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.deposit {
  display: flex;
  width: 100%;
  align-items: center;
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.25rem;
    margin-right: 0.75rem;
  }
  &__forward-icon {
    margin: auto 0 auto auto;
    color: $french-grey;
  }
}
</style>
