import { SignupFlowModificationType } from "@/config/signup-modification";

export interface QuestionProgress {
  partNumber: number;
  questionNumber: number;
}

export interface SignupFlowModification {
  modification: SignupFlowModificationType;
  nestedModifications: SignupFlowModification[];
  parentModification: SignupFlowModification;
  index: string;
  paths: string[];
}

export interface SignupQuestionPaths {
  questionPaths: string[][];
}

export enum PdfMode {
  Preview,
  Signing,
  NewPortfolio,
  Signup
}

export interface SignupNavigationState {
  trackingGuid: string | undefined;
  questionProgress: QuestionProgress | undefined;
  showProgressBar: boolean;
  signupPaths: SignupQuestionPaths;
  signupFlowModifications: SignupFlowModification[];
  currentPathKey: string | undefined;
  additionalQuestions: any;
  pdfMode: PdfMode;
  progressHeadings: string[] | undefined;
}
