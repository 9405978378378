<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import SingleChoice from "../../../signup/templates/single-choice.vue";
import SignupTransition from "../../signup-transition.vue";
import { useSignupType } from "../../signup-type";
import SustainableChoiceInfo from "./sustainable-choice-info.vue";
import type { SustainabilityImpact } from "@/types/signup";
import type { InfoSectionMultiple } from "@/types";
import SectionedSideDialog from "@/components/dialog/sectioned-side-dialog.vue";
import type { NestedCheckbox } from "@/components/nested-checkboxes.vue";
import NestedCheckboxes from "@/components/nested-checkboxes.vue";
import { useQuestion } from "@/views/signup/templates/question-comp";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { nearlyBlack } from "@/styles/colors";
import { SignupFlowModificationType } from "@/config/signup-modification";

enum State {
  METHOD,
  EXCLUDED_INFO,
}

const router = useRouter();
const store = useStore();
const { maybeTextToThirdPerson } = useSignupType();

useQuestion(store, router);

const sustainabilityImpact = computed(() => store.state.signupStore.sustainabilityImpact);

const minimizeNegativeImpact = ref(sustainabilityImpact.value?.minimizeNegativeImpact ?? false);
const taxonomyRegulation = ref(sustainabilityImpact.value?.taxonomyRegulation);
const disclosureRegulation = ref<boolean>(sustainabilityImpact.value?.disclosureRegulation);
const showDialog = ref(false);
const state = ref(State.METHOD);

const nested = computed<NestedCheckbox[]>(() =>
  [
    {
      heading: "Enligt EU:s taxonomi.",
      body1: "Miljömässigt hållbara bolag.",
      checked: taxonomyRegulation.value,
      index: 0,
    },
    {
      heading: "Enligt SFDR (Disclosureförordningen)",
      body1: "Miljömässigt och socialt hållbara bolag.",
      checked: disclosureRegulation.value,
      index: 1,
    },
  ]);

const infoSections: InfoSectionMultiple[] = [
  {
    heading: "Vad betyder det här?",
    subSections: [
      {
        texts: [
          "EU håller just nu på att ta fram ett regelverk för hållbara investeringar med syfte att göra det enklare för folk att jämföra olika finansiella tjänster och produkter med varandra ur ett hållbarhetsperspektiv. Vissa delar av regelverket är klart, och andra delar är fortfarande under arbete.",
          "I dagsläget har EU definierat vad en hållbar investering är (enligt SFDR) samt vad en miljömässigt hållbar investering är (enligt EU:s Taxonomi).",
          "De har även definierat en rad hållbarhetsindikatorer man kan mäta för att undersöka ett bolags negativa konsekvenser för hållbarhet (PAI).",
          "Alla finansbolag är skyldiga att ta reda på hur deras kunder ställer sig till de här begreppen för att kunna ge rådgivning eller förvaltning som stämmer överens med kundens hållbarhetspreferenser.",
        ],
      },
    ],
  },
  {
    heading: "Miljömässigt hållbara bolag",
    subSections: [
      {
        texts: [
          "Ett bolag är miljömässigt hållbart om den ekonomiska verksamheten:",
          "a) bidrar väsentligt till ett eller flera av miljömålen i artikel 9*",
          "b) inte orsakar betydande skada för något av miljömålen i artikel 9*",
          "c) utförs i överensstämmelse med de minimiskyddsåtgärder som fastställs i artikel 18**",
          "d) överensstämmer med tekniska granskningskriterier som har fastställts av kommissionen.",
          "*Miljömålen i artikel 9 är följande:",
          "· Begränsning av klimatförändringar.",
          "· Anpassning till klimatförändringar.",
          "· Hållbar användning och skydd av vatten och marina resurser.",
          "· Omställning till en cirkulär ekonomi.",
          "· Förebyggande och begränsning av miljöföroreningar.",
          "· Skydd och återställande av biologisk mångfald och ekosystem. ",
          "**De minimiskyddsåtgärder som avses är att verksamheten sker i enlighet med OECD:s riktlinjer för multinationella företag och FN:s vägledande principer för företag och mänskliga rättigheter.",
        ],
      },
    ],
  },
  {
    heading: "Hållbara bolag",
    subSections: [
      {
        texts: [
          "Definition enligt EU:s förordning om hållbarhetsrelaterade upplysningar (SFDR).",
          "Ett hållbart bolag är ett bolag som: ",
        ],
      },
      {
        subHeading: "Bidrar till ett miljömål",
        texts: [
          "i enlighet med vad som mäts genom till exempel centrala resurseffektivitetsindikatorer avseende användning av energi, förnybar energi, råvaror, vatten och mark, generering av avfall och utsläpp av växthusgaser eller avseende effekter på den biologiska mångfalden och den cirkulära ekonomin eller",
        ],
      },
      {
        subHeading: "Bidrar till ett socialt mål",
        texts: [
          "i synnerhet investeringar som bidrar till att bekämpa ojämlikheter eller som främjar social sammanhållning, social integration och ett gott förhållande mellan arbetsmarknadens parter, eller en investering i humankapital eller ekonomiskt eller socialt missgynnade grupper, förutsatt att investeringarna inte orsakar betydande skada för något av dessa mål och,",
        ],
      },
      {
        subHeading: "Följer praxis för god styrning ",
        texts: [
          "särskilt med avseende på sunda förvaltningsstrukturer, relationer till arbetstagarna, löner till berörd personal och efterlevnad av skatteregler. ",
        ],
      },
    ],
  },
  {
    heading: "Ta hänsyn till negativa konsekvenser för hållbarhetsfaktorer",
    subSections: [
      {
        texts: [
          "Definition enligt Principal Adverse Impact, (PAI).",
          "Hållbarhet i en finansiell produkt kan fångas upp på olika sätt. Ett är att den undviker negativa effekter på hållbarhetsfaktorerna inom ESG - alltså gällande miljö, social hållbarhet och bolagsstyrning. Den finansiella produkt du väljer ska alltså inte ge negativa effekter inom de områden du värnar om. Investeringen behöver för den skull inte bidra till något hållbarhetmål.",
        ],
      },
      {
        subHeading: "Hållbarhetsindikatorer",
        texts: [
          "Hållbarhetsfaktorerna inom ESG delas in i hållbarhetsindikatorer med flera underkategorier. På engelska kallas hållbarhetsindikatorerna Principal Adverse Impacts (PAI).",
        ],
      },
      {
        subHeading: "Hållbarhetsindikatorerna har 6 huvudområden",
        texts: [
          "· Utsläpp och miljö",
          "· Biologisk mångfald",
          "· Vatten",
          "· Avfall",
          "· Social hållbarhet / sociala hänsyn",
          "· Arbetstagares rättigheter",
        ],
      },
      { subHeading: "Hållbarhetsindikatorer inom miljö och klimat" },
      {
        subHeading: "Utsläpp och miljö",
        texts: [
          "· Utsläpp av växthusgaser",
          "· Koldioxidavtryck",
          "· Utsläpp av växthusgaser i relation till storlek på företagets intäkter",
          "· Exponering mot företag inom sektorn för fossila bränslen",
          "· Icke-förnybar energiförbrukning och energiproduktion",
          "· Energiförbrukningsintensitet med stor klimatpåverkan",
        ],
      },
      {
        subHeading: "Biologisk mångfald",
        texts: ["· Verksamhet som negativt påverkar områden med känslig biologisk mångfald"],
      },
      { subHeading: "Avfall", texts: ["· Farligt avfall och radioaktivt avfall"] },
      { subHeading: "Vatten", texts: ["· Utsläpp i vatten"] },
      { subHeading: "Hållbarhetsindikatorer inom social hållbarhet" },
      {
        subHeading: "Sociala hänsyn",
        texts: [
          "· Brott mot FN:s globala överenskommelse och OECD:s riktlinjer för multinationella företag",
          "· Brist på styrdokument för att granska efterlevnad av bland annat mänskliga rättigheter",
          "· Exponering mot kontroversiella vapen",
        ],
      },
      {
        subHeading: "Arbetstagarens rättigheter",
        texts: [
          "· Ojusterade löneklyftor mellan könen",
          "· Jämnare könsfördelning i styrelserna",
        ],
      },
    ],
  },
];
function clickNested(index: number, val: boolean | undefined) {
  if (index === 0) {
    taxonomyRegulation.value = !!val;
  } else if (index === 1) {
    disclosureRegulation.value = !!val;
  }
}
async function goToNext(): Promise<void> {
  store.commit(SignupMutation.setSustainabilityImpact, {
    noPreference: false,
    excludeNegativeImpact: false,
    minimizeNegativeImpact: !!minimizeNegativeImpact.value,
    // Set to undefined to indicate that we did not select on this screen
    taxonomyRegulation: taxonomyRegulation.value ? true : undefined,
    disclosureRegulation: disclosureRegulation.value ? true : undefined,
  } as SustainabilityImpact);
  if (taxonomyRegulation.value || disclosureRegulation.value) {
    state.value = State.EXCLUDED_INFO;
  } else {
    await store.dispatch(SignupNavigationAction.modifySignupFlow, { modification: SignupFlowModificationType.MinimizedNegativeImpact, include: minimizeNegativeImpact.value });
    store.dispatch(SignupNavigationAction.goToNextStep, router);
  }
}
</script>

<template>
  <div class="sustainable-method">
    <SignupTransition>
      <SingleChoice
        v-if="state === State.METHOD"
        :alternatives="[
          {
            text: $t('next'),
            key: 'next',
          },
        ]"
        footer-message="Läs mer om definitionerna under infon"
        information
        :disabled="!minimizeNegativeImpact && !taxonomyRegulation && !disclosureRegulation"
        @show-information="() => (showDialog = true)"
        @click="goToNext"
      >
        <QuestionTextSection
          :heading="maybeTextToThirdPerson('På vilket sätt vill du få ett mer hållbart sparande?')"
          :body="maybeTextToThirdPerson('Du kan välja flera alternativ.')"
          progress
        />
        <div
          class="sustainable-method__content"
        >
          <h2
            style="
              margin-left: 4rem;
              margin-top: 2rem;
              font-weight: 300;
              font-size: 0.875rem;
            "
          >
            {{ maybeTextToThirdPerson('Jag vill investera i') }}
          </h2>
          <NestedCheckboxes
            :header="{ heading: 'Hållbara bolag', body1: 'Välj bolag som tydligt bidrar till ett hållbart miljö- eller socialt mål.' }"
            :nested="nested"
            :indeterminate="taxonomyRegulation || disclosureRegulation"
            @click-nested="clickNested"
            @click-top="(val) => { taxonomyRegulation = !!val; disclosureRegulation = !!val }"
          >
            <template #body1="{ text }">
              <div
                v-if="text"
                :style="{ color: nearlyBlack, fontSize: '0.9375rem', opacity: 0.87, fontWeight: 300, fontFamily: 'proxima-nova' }"
              >
                {{ text }}
              </div>
            </template>
          </NestedCheckboxes>
          <h2
            style="
              margin-left: 4rem;
              margin-bottom: 0.5rem;
              margin-top: 2rem;
              font-weight: 300;
              font-size: 0.875rem;
            "
          >
            {{ maybeTextToThirdPerson('Jag vill minska') }}
          </h2>
          <div style="margin-left: 1.4rem; display: flex; flex-direction: row; color: #2f2f2f">
            <div>
              <v-checkbox-btn
                v-model="minimizeNegativeImpact"
                style="color: #2f2f2f; opacity: 0.87;font-size: 1.125rem "
                data-test="negativeImpact"
                @click="(e: Event) => e.stopPropagation()"
              />
            </div>
            <div style="display: flex; flex-direction: column; row-gap: 0.5rem">
              <h2 style="font-weight: 400; font-size: 1.25rem; opacity: 0.87">
                Negativa konsekvenser för hållbarhet
              </h2>
              <div style="font-weight: 300; font-size: 0.875rem">
                Undvik bolag som har negativa effekter på hållbarhet.
              </div>
            </div>
          </div>
        </div>

        <SectionedSideDialog
          v-model="showDialog"
          title="Hållbarhet enligt EU"
          :info-sections="infoSections"
        />
      </SingleChoice>
      <SustainableChoiceInfo v-else-if="state === State.EXCLUDED_INFO" />
    </SignupTransition>
  </div>
</template>

<style lang="scss" scoped>
.sustainable-method {
  height: 100%;
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
  &__multiple-choice {
    padding-bottom: 2rem;
  }
  &__content {
    margin: 0 auto;
    @include medium-up() {
      width: 26rem;
    }
  }
}
</style>
