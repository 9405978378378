<template>
  <SwedishCitizenship @next="next" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SwedishCitizenship from "@/views/common-signup/questions/aml/swedish-citizenship.vue";
import { SignupFlowModificationType } from "@/config/signup-modification";

export default defineComponent({
  components: { SwedishCitizenship },
  methods: {
    async next(key: string) {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.CitizenshipDenial,
        include: key !== "hasSwedishCitizenship",
      });
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
  },
});
</script>
