import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationMutation } from "@/store/signup-navigation/mutations";
import { defineComponent } from "vue";
import { evida, sigmastocks } from "@/styles/colors";

export default defineComponent({
  created(): void {
    // Used when going back in the browser
    const progress = this.$store.getters.questionProgress(this.$router.currentRoute.value.path);
    this.$store.commit(SignupNavigationMutation.setQuestionProgress, progress);
    this.$store.commit(SignupMutation.showProgressBar, true);
    sessionStorage.setItem(
      this.$store.state.signupNavigationStore.currentPathKey,
      this.$router.currentRoute.value.path
    );
    if (
      (this.$router.currentRoute.value.path.includes("pension") && progress.partNumber < 5) ||
      (this.$router.currentRoute.value.path.includes("company-signup") && progress.partNumber < 5)
    ) {
      this.$store.commit(SignupMutation.setPrimaryColor, evida.accent);
      this.$store.commit(SignupMutation.setColors, evida);
    } else {
      this.$store.commit(SignupMutation.setPrimaryColor, sigmastocks.accent);
      this.$store.commit(SignupMutation.setColors, sigmastocks);
    }
  }
});
