<template>
  <div class="risk-profile">
    <savings-header
      :title="$t('riskProfile')"
      :icon="['fal', 'heart-rate']"
    >
      <template
        v-if="!isLockedBecauseOfEconomy"
        #action
      >
        <edit-button
          :disabled="disabled"
          :edit="edit"
          data-cy="editRiskLevelButton"
          @click="toggleEdit"
        />
      </template>
    </savings-header>
    <div class="risk-profile__body-wrapper">
      <div class="risk-profile__picker-wrapper">
        <div
          class="risk-profile__risk-profile-text"
          style="font-weight: bold"
          data-jest="risk-profile-text"
        >
          {{ riskProfileText(editedRiskProfile) }}
        </div>
        <div
          v-if="!edit"
          class="risk-profile__risk-profile-sub-text"
          data-jest="risk-profile-sub-text"
        >
          {{ riskProfileDescription(editedRiskProfile) }}
        </div>
        <v-select
          v-else
          v-model="editedRiskProfile"
          class="risk-profile__select"
          :items="items"
          menu-icon="fas fa-chevron-down"
          variant="underlined"
          :item-props="true"
          :placeholder="riskProfileDescription(editedRiskProfile)"
          data-cy="riskLevelSelect"
          hide-details
        >
          <template #selection="{ item }">
            <span>{{ item.props.text }}</span>
          </template>
          <template #item="{ props }">
            <v-list-item v-bind="props">{{ props.text }}: {{ props.description }}</v-list-item>
          </template>
        </v-select>
      </div>
      <div
        class="risk-profile__disclaimer-text"
        data-jest="risk-disclaimer-text"
      >
        {{ formattedRiskDisclaimer }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Strategy, PensionRiskProfile } from "@/clients";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import EditButton from "@/components/edit-button.vue";
import SavingsHeader from "./header.vue";

interface RiskProfileAlternative {
  text: string;
  title: string;
  description: string;
  value: PensionRiskProfile;
}

export default defineComponent({
  emits: ["save"],
  components: { EditButton, SavingsHeader },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    edit: false,
    items: [] as RiskProfileAlternative[]
  }),
  computed: {
    buttonText(): string {
      return this.edit ? this.$t("save") : this.$t("change_risk_level");
    },
    formattedRiskDisclaimer(): string {
      if (this.recommendedRiskProfile !== undefined) {
        return `${this.riskProfileText(this.recommendedRiskProfile)} ${this.$t(
          "portfolio-settings.risk-level.continuedDisclaimer"
        )}`;
      }
      return "-";
    },
    editedStrategy(): Strategy | undefined {
      return this.$store.state.portfolioStore.editedStrategy;
    },
    recommendedRiskProfile(): PensionRiskProfile | undefined {
      if (this.editedStrategy) {
        return this.editedStrategy.recommendedPensionRiskProfile;
      }
      return undefined;
    },
    editedRiskProfile: {
      get(): PensionRiskProfile | undefined {
        if (this.editedStrategy) {
          return this.editedStrategy.chosenPensionRiskProfile;
        }
        return undefined;
      },
      set(profile: any) {
        this.$store.commit(PortfolioMutation.editChosenPensionRiskProfile, profile);
      }
    },
    isLockedBecauseOfEconomy(): boolean {
      if (this.$store.state.userStore.currentLegalEntity) {
        return this.$store.state.userStore.currentLegalEntity.isLockedBecauseOfEconomy;
      }
      return false;
    }
  },
  created() {
    this.items.push(
      {
        title: "",
        text: this.riskProfileText(PensionRiskProfile.Defensive),
        description: this.riskProfileDescription(PensionRiskProfile.Defensive),
        value: PensionRiskProfile.Defensive
      },
      {
        title: "",
        text: this.riskProfileText(PensionRiskProfile.Standard),
        description: this.riskProfileDescription(PensionRiskProfile.Standard),
        value: PensionRiskProfile.Standard
      },
      {
        title: "",
        text: this.riskProfileText(PensionRiskProfile.Offensive),
        description: this.riskProfileDescription(PensionRiskProfile.Offensive),
        value: PensionRiskProfile.Offensive
      }
    );
  },
  methods: {
    riskProfileText(profile: PensionRiskProfile | undefined): string {
      switch (profile) {
        case PensionRiskProfile.Defensive:
          return this.$t("pensionRiskProfile.defensive");
        case PensionRiskProfile.Standard:
          return this.$t("pensionRiskProfile.standard");
        case PensionRiskProfile.Offensive:
          return this.$t("pensionRiskProfile.offensive");
        default:
          "-";
      }
      return "-";
    },
    calculateFractions(profile: PensionRiskProfile): {
      stocksFraction: number;
      etfFraction: number;
    } {
      switch (profile) {
        case PensionRiskProfile.Defensive:
          return { stocksFraction: 80, etfFraction: 20 };
        case PensionRiskProfile.Standard:
          return { stocksFraction: 100, etfFraction: 0 };
        case PensionRiskProfile.Offensive:
          return { stocksFraction: 100, etfFraction: 0 };
        default:
          throw new Error("Invalid risk profile");
      }
    },
    riskProfileDescription(riskProfile: PensionRiskProfile | undefined) {
      if (riskProfile !== undefined) {
        const { stocksFraction, etfFraction } = this.calculateFractions(riskProfile);
        if (!Number.isNaN(stocksFraction) && !Number.isNaN(etfFraction)) {
          return `${Math.round(stocksFraction)} % ${this.$t(
            "portfolio-settings.risk-profile.stocksText"
          )}`;
        }
      }
      return "-";
    },
    async toggleEdit() {
      if (this.edit) {
        if (
          this.editedRiskProfile !==
          this.$store.state.portfolioStore.strategy.chosenPensionRiskProfile
        ) {
          this.$emit("save", { riskProfile: this.editedRiskProfile });
        }
      } else {
        this.$store.commit(PortfolioMutation.increaseUnsavedEdits);
      }
      this.edit = !this.edit;
    },
    reset() {
      const { strategy } = this.$store.state.portfolioStore;
      this.$store.commit(
        PortfolioMutation.editChosenPensionRiskProfile,
        strategy.chosenPensionRiskProfile
      );
    }
  }
});
</script>

<style lang="scss">
.risk-profile {
  padding: 1.5rem 1.5rem 1.625rem;

  &__body-wrapper {
    margin-top: 1.125rem;
    display: grid;
    text-align: center;
  }

  &__picker-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;
  }

  &__select {
    padding-top: 0;
    height: 1.375rem;
    max-width: 19.25rem;
    font-size: 1rem;
    margin-top: -0.3rem;

    .v-icon.v-icon {
      font-size: 1rem;
    }
  }

  &__select-menu {
    border-radius: 0.5rem !important;
    box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.14) !important;
    font-size: 1rem;
  }

  &__risk-profile-text {
    font-size: 1rem;
    user-select: none;
    margin-right: 0.5rem;
    min-width: 3rem;
    text-align: left;
    align-self: end;
  }

  &__risk-profile-sub-text {
    font-weight: 300;
  }

  &__disclaimer-text {
    text-align: left;
    font-size: 0.875rem;
    font-weight: 300;
    color: rgba($nearly-black, 0.63);
  }
}
</style>
