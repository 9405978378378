import { Sector } from "@/clients";

export const sectorIcons: Map<keyof typeof Sector, string[]> = new Map([
  ["ItAndTechnology", ["fal", "laptop"]],
  ["MediaAndTelecom", ["fal", "broadcast-tower"]],
  ["Finance", ["fal", "coins"]],
  ["RealEstate", ["fal", "building"]],
  ["Industry", ["fal", "industry"]],
  ["HealthCare", ["fal", "heartbeat"]],
  ["RawMaterial", ["fal", "tree"]],
  ["Commodities", ["fal", "shopping-cart"]],
  ["ConsumerDiscretionary", ["fal", "car"]],
  ["EnergyAndPowerSupply", ["fal", "lightbulb"]]
]);
