<template>
  <div class="stock-information">
    <single-choice-template
      class="stock-information__single-choice-template"
      :alternatives="[
        {
          text: $t('iUnderstand'),
          key: 'ok'
        }
      ]"
      @click="goToNext"
    >
      <question-text-section
        :heading="$t('common-signup.questions.risk.stock-information.informationHeading')"
      />
      <p class="stock-information__information-body">
        <span class="stock-information__information-body--text-bold">{{
          $t("common-signup.questions.risk.stock-information.informationStocksFirstWord") + " "
        }}</span>
        <span>{{
          $t("common-signup.questions.risk.stock-information.informationStocksBodyFirst")
        }}</span>
      </p>
      <p class="stock-information__information-body">
        {{ $t("common-signup.questions.risk.stock-information.informationStocksBodySecond") }}
      </p>
      <p
        class="stock-information__information-body stock-information__information-body--new-paragraph"
      >
        <span class="stock-information__information-body--text-bold">{{
          $t("common-signup.questions.risk.stock-information.informationFundFirstWord") + " "
        }}</span>
        <span>{{ $t("common-signup.questions.risk.stock-information.informationFundBody") }}</span>
      </p>
      <p
        class="stock-information__information-body stock-information__information-body--new-paragraph"
      >
        <span class="stock-information__information-body--text-bold">{{
          $t("common-signup.questions.risk.stock-information.informationFixedIncomeFirstWord") + " "
        }}</span>
        <span>{{
          $t("common-signup.questions.risk.stock-information.informationFixedIncomeBody")
        }}</span>
      </p>
      <p
        class="stock-information__information-body stock-information__information-body--new-paragraph"
      >
        <span class="stock-information__information-body--text-bold">{{
          $t("common-signup.questions.risk.stock-information.informationEtfsFirstWord") + " "
        }}</span>
        <span>{{
          $t("common-signup.questions.risk.stock-information.informationEtfsBodyFirst")
        }}</span>
      </p>
      <p class="stock-information__information-body">
        {{ $t("common-signup.questions.risk.stock-information.informationEtfsBodySecond") }}
        <i>{{ $t("common-signup.questions.risk.stock-information.informationEtfsCursive") }}</i>
      </p>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.stock-information {
  height: 100%;
  width: 100%;
  &__information-body {
    max-width: 21.25rem;
    margin-left: auto;
    margin-right: auto;
    &--text-bold {
      font-weight: bold;
    }
    &--new-paragraph {
      margin-top: 3rem;
    }
    @include medium-up {
      max-width: 44.5rem;
    }
    &:first-of-type {
      margin-top: 1rem;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__single-choice-template {
    // This is a special page with a lot of text. Disable max-height to show all text.
    max-height: unset;
  }
}
</style>
