<template>
  <div class="associated-coworker">
    <single-choice-template
      :disabled="relationship === undefined"
      :alternatives="navigationAlternatives"
      @click="goToNext"
    >
      <question-text-section
        :heading="$t('pepRelation')"
        progress
      />
      <div class="associated-coworker__alternatives">
        <v-radio-group
          v-model="relationship"
          hide-details
        >
          <v-radio
            v-for="alternative in alternatives"
            :key="alternative.key"
            class="associated-coworker__alternatives--alternative"
            color="primary"
            :true-value="alternative.key"
          >
            <template #label>
              <div>
                {{ alternative.text }}
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import { SingleChoice } from "@/types/signup";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    relationship: {
      get() {
        return this.$store.state.signupStore.associatedPepInfo.relationship;
      },
      set(value: string) {
        this.$store.commit(SignupMutation.setAssociatedPepRelationship, value);
      }
    },
    navigationAlternatives(): SingleChoice[] {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    },
    alternatives(): SingleChoice[] {
      return [
        {
          text: this.$t(
            "create-portfolio.questions.aml.politically-exposed-associated-relationship-coworker.principal1"
          ),
          key: "principal1"
        },
        {
          text: this.$t(
            "create-portfolio.questions.aml.politically-exposed-associated-relationship-coworker.principal2"
          ),
          key: "principal2"
        },
        {
          text: this.$t(
            "create-portfolio.questions.aml.politically-exposed-associated-relationship-coworker.other"
          ),
          key: "other"
        }
      ];
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.associated-coworker {
  height: 100%;
  &__alternatives {
    margin: 2rem auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 21.75rem;
    &--alternative {
      padding-top: 1rem;
    }
  }
}
</style>
