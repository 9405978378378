<template>
  <div class="dividend">
    <h2 class="dividend__title">
      {{ $t("portfolio.overview.dividend.dividend.dividendTitle") }}
    </h2>
    <icon
      :icon="['fas', 'chevron-right']"
      class="dividend__forward-icon"
    />
    <div :class="{ 'dividend__content-wrapper': !dividendAmount }">
      <icon
        fixed-width
        :icon="['fal', 'coins']"
        class="dividend__icon"
      />
      <span
        :class="dividendAmount ? 'dividend__amount' : 'dividend__message'"
        data-jest="amount"
      >
        {{ formatDividend(dividendAmount) }}
      </span>
    </div>
    <loading-spinner-overlay :model-value="showLoadingSpinner" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";

export default defineComponent({
  components: { LoadingSpinnerOverlay },
  computed: {
    dividendAmount(): number | undefined {
      const { dividend } = this.$store.state.portfolioStore;
      if (dividend && dividend[new Date().getFullYear()]) {
        return dividend[new Date().getFullYear()].dividendSum;
      }
      return undefined;
    },
    showLoadingSpinner(): boolean {
      const { isLoadingPortfolioDividend } = this.$store.state.portfolioStore;
      return isLoadingPortfolioDividend && this.dividendAmount === undefined;
    }
  },
  methods: {
    formatDividend(number: number | undefined): string {
      switch (number) {
        case undefined:
          return `- ${this.$t("currency")}`;
        case 0:
          return this.$t("portfolio.overview.dividend.dividend.emptyMessage");
        default:
          return `${number.toLocaleString("sv-SE", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })} ${this.$t("currency")}`;
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.dividend {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto 1fr;
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }
  &__content-wrapper {
    display: flex;
    align-items: center;
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.25rem;
    margin-right: 0.75rem;
  }
  &__amount {
    font-family: $heading-font-family;
    font-weight: 600;
    line-height: 1;
    align-self: center;
    font-size: 1.5rem;
  }
  &__message {
    font-size: 1rem;
    font-weight: 300;
  }
  &__forward-icon {
    margin: 0.5rem 0 auto auto;
    color: $french-grey;
  }
}
:deep(.sk-fading-circle) {
  // Margin of loading spinner placed itself over the settins button,
  // making it impossible to click while dividends were loading.
  margin: 0 !important;
}
</style>
