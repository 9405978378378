import store from "@/store";
import { BankIdMutation } from "@/store/bankid/mutations";
import { getNavigationInitialState } from "@/store/navigation";
import { NavigationMutation } from "@/store/navigation/mutations";
import { getPortfolioInitialState } from "@/store/portfolio";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { getSignUpInitialState } from "@/store/signup";
import { SignupMutation } from "@/store/signup/mutations";
import { getUserInitialState } from "@/store/user";
import { UserMutation } from "@/store/user/mutations";

export default (): void => {
  store.commit(PortfolioMutation.setPortfolioState, getPortfolioInitialState());
  store.commit(UserMutation.setUserState, getUserInitialState());
  store.commit(NavigationMutation.setNavigationState, getNavigationInitialState());
  store.commit(BankIdMutation.resetBankId);
  store.commit(SignupMutation.setState, getSignUpInitialState());
};
