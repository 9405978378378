import { AuthenticationStatus } from "@/clients";

export enum BankIdStatus {
  COMPLETE,
  PENDING,
  FAILED,
  CANCELED
}

export enum StartMode {
  AUTOSTART_TOKEN,
  QR_CODE
}
export enum SignLocation {
  PROFILE,
  PORTFOLIO_SETTINGS,
  ERROR_AGREEMENTS,
  LEGAL_ENTITY_VALIDATION_CHANGE, // Note this used both in yearly kyc and in profile kyc settings
  CREATE_PORTFOLIO,
  YEARLY_KYC
}

export interface BankIdState {
  transactionId?: string;
  orderRef?: string;
  autoStartURL?: string;
  autoStartToken?: string;
  status?: BankIdStatus;
  authenticationStatus?: AuthenticationStatus;
  startMode?: StartMode;
  assentlyToken: string | undefined;
  signMessage: string | undefined;
  signTitle: string | undefined;
  signLocation: SignLocation | undefined;
  signReturnPath: string | undefined;
  redirectSignStatus: string | undefined;
  qrCode: string | undefined;
}
