<template>
  <sectors
    :heading-start="$t('markSectors1')"
    :heading-end="$t('markSectors2')"
    :included-sectors="includedSectors"
    @toggle-included="toggleIncluded"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Sector } from "@/clients";
import { SignupMutation } from "@/store/signup/mutations";
import Sectors from "@/views/common-signup/questions/preferences/sectors.vue";

export default defineComponent({
  components: { Sectors },
  computed: {
    includedSectors() {
      return this.$store.state.signupStore.includedSectors;
    }
  },
  methods: {
    toggleIncluded(evt: { key: Sector; value: boolean }) {
      this.$store.commit(SignupMutation.includeSector, evt);
    }
  }
});
</script>
