<template>
  <v-tooltip
    location="bottom"
    :disabled="!disabled || !disabledTooltipText"
    open-delay="300"
  >
    <template #activator="{ props }">
      <div
        v-bind="props"
        class="d-inline-block"
      >
        <v-btn
          icon
          :disabled="disabled"
          variant="flat"
          class="edit-button"
          :class="[{ 'edit-button__disabled': disabled }]"
          :data-cy="dataCy"
          :aria-label="ariaLabelType ? edit ? `Spara ${ariaLabelType}` : `Ändra ${ariaLabelType}` : undefined"
          @click="$emit('click')"
        >
          <icon
            class="edit-button__icon"
            :class="[
              { 'edit-button__icon--edit': edit },
              { 'edit-button__icon--disabled': disabled },
            ]"
            :icon="['fal', edit ? 'save' : icon ? icon : 'pen']"
          />
        </v-btn>
      </div>
    </template>
    <span>{{ disabledTooltipText }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledTooltipText: {
      type: String,
      default: undefined,
    },
    dataCy: {
      // used to set data-cy on tha actual button instead of tooltip overlay so e2e test can click it
      type: String,
      default: undefined,
    },
    ariaLabelType: {
      type: String,
    },
  },
  emits: ["click"],
});
</script>

<style lang="scss" scoped>
.edit-button {
  margin: -1.25rem -0.75rem -1.25rem 0;
  width: 2.75rem !important;
  height: 2.75rem !important;
  &.focus-visible {
    box-shadow:
      0 0 0.0625rem 0.0625rem $aqua,
      0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    &:before {
      background-color: white;
    }
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.25rem;
    opacity: 0.63;
    &--edit {
      opacity: 1;
    }
    &--disabled {
      opacity: 0.35;
    }
  }
  &__disabled {
    :deep(.v-btn__overlay) {
      opacity: 0 !important;
    }
  }
}
</style>
