<script setup lang="ts">
import { useRouter } from "vue-router";
import TwoActionDialog from "./two-action-dialog.vue";
import { HIDE_ESG_PAI_DIALOG_KEY } from "@/config/general";

const router = useRouter();
const modelValue = defineModel<boolean>({ required: true });

function close() {
  sessionStorage.setItem(HIDE_ESG_PAI_DIALOG_KEY, "true");
}
</script>

<template>
  <TwoActionDialog
    v-model="modelValue"
    :body-sections="[
      'Vi har uppdaterat vår tjänst för att göra det lättare för dig att spara mer hållbart.',
      'Svara på våra nya frågor och uppdatera dina inställningar. Det tar bara ett par minuter!',
      'Fram tills att vi fått dina nya svar kommer vi följa de inställningar som bäst motsvarar dina tidigare val.',
    ]"
    heading="Nya uppdateringar på hållbarhetsfronten"
    :font-awesome-logo="['fal', 'globe-americas']"
    button1-text="Starta frågorna"
    @button-1-click="router.push({ name: 'esg' })"
    @update:model-value="close"
  />
</template>

<style lang="scss" scoped></style>
