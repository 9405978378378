import type {
  CompanyPrincipal as CompanyPrincipalStore,
  CompanySignupState,
} from "../store/company-signup/types";
import { paiFromState } from "./pai";
import type {
  CompanyEmployees,
  CompanyForm,
  CompanyIndustry,
  CompanyRepresentativeInfo,
  CompanyRevenue,
  DepositFrequency,
  Market,
  StrategyFocus,
  TimeToWithdraw,
  WithdrawalFrequency,
  YearlyDepositAmount,
} from "@/clients";
import {
  AuthorizedSignatory as AuthorizedSignatoryBackend,
  CompanyKnowYourCustomerAnswers,
  CreateCompanyLegalEntity,
  CreateCompanyRepresentativeUser,
  CreatePortfolio,
  CreatePortfolioSettings,
  CreateStrategy,
  CreateUserAffiliateInfo,
  RegisterCompanyParameters,
  CompanyPrincipal as RequestCompanyPrincipal,
  TiedAgentPortfolioTheme,
} from "@/clients";
import type { SignupState } from "@/store/signup/types";

function getRequestCompanyPrincipal(state: CompanyPrincipalStore): RequestCompanyPrincipal {
  const taxableCountries: string[] = [];
  if (state.taxableSweden) {
    taxableCountries.push("SE");
  }
  if (state.taxableUsa) {
    taxableCountries.push("US");
  }
  if (state.taxableOther && state.taxableOtherCountry) {
    taxableCountries.push(state.taxableOtherCountry);
  }
  const requestPrincipal = new RequestCompanyPrincipal({
    email: state.email as string,
    principalTitle: state.principalTitle,
    principalTitleFreeText: state.principalTitleFreeText,
    personalNumber: state.personalNumber as string,
    phoneNumber: state.phoneNumber,
    citizenship1: state.citizenship1 as string,
    citizenship2: state.citizenship2,
    citizenship3: state.citizenship3,
    registeredSwedishResident: !!state.registeredSwedishResident,
    address: state.address,
    city: state.city,
    firstName: state.firstName,
    lastName: state.lastName,
    zipCode: state.zipCode,
    percentageShares: state.percentageShares as number,
    isPep: !!state.isPEP,
    principalOther: state.companyPrincipalOther as boolean,
    birthDate: state.birthDate,
    taxableCountries,
    countryResidence: state.country,
  });
  return requestPrincipal;
}

export function getPortfolioSettings(state: CompanySignupState): CreatePortfolioSettings {
  return new CreatePortfolioSettings({
    initialDeposit: state.firstDeposit as number,
    monthlySaving: state.monthlySavingsAmount as number,
    accountName: "Mitt konto",
    accountNumber: state.bankAccountNumber,
    clearingNumber: state.clearingNumber,
    bankName: state.bankName,
  });
}

export function getRegisterCompanyParameters(state: CompanySignupState, signupState: SignupState) {
  const strategy = new CreateStrategy({
    chosenRiskLevel:
      state.customRiskLevel !== undefined
        ? state.customRiskLevel
        : (state.calculatedRiskLevel as number),
    recommendedRiskLevel: state.calculatedRiskLevel as number,
    defenseDiscarded: !state.includedSectors.weaponsAndDefense,
    alcoholTobaccoDiscarded: !state.includedSectors.alcoholTobacco,
    gamblingDiscarded: !state.includedSectors.casinosAndGambling,
    fossilFuelsDiscarded: !state.includedSectors.fossilFuel,
    focus: state.focus as StrategyFocus,
    marketFocus: state.marketFocus as Market,
    timeToWithdraw: state.timeToWithdraw as any as TimeToWithdraw,
    esgBiodiversityFilter: !!signupState.esgFilter?.esgBiodiversityFilter,
    esgEmissionsFilter: !!signupState.esgFilter?.esgEmissionsFilter,
    esgGovernanceFilter: !!signupState.esgFilter?.esgGovernanceFilter,
    esgHumanRightsFilter: !!signupState.esgFilter?.esgHumanRightsFilter,
    esgResourcesFilter: !!signupState.esgFilter?.esgResourcesFilter,
    tiedAgentPortfolioThemeType: signupState.portfolioTheme?.tiedAgentPortfolioThemeType,
    esgPais: paiFromState(signupState.esgPais),
  });

  const portfolioSettings = getPortfolioSettings(state);

  const portfolio = new CreatePortfolio({
    name: "Min portfölj",
    strategy,
    portfolioSettings,
  });

  const signatoriesToSign = state.authorizedSignatories
    .filter(s => s.hasToSign)
    .map(
      s =>
        new AuthorizedSignatoryBackend({
          email: s.email as string,
          fullName: s.fullName,
          personalIdentityNumber: s.personalIdentificationNumber as string,
        }),
    );

  const knowYourCustomerAnswers = new CompanyKnowYourCustomerAnswers({
    insuredEmail: state.insuredContactInfo.email as string,
    insuredPhoneNumber: state.insuredContactInfo.phoneNumber as string,
    insuredPersonalNumber: state.insuredContactInfo.personalNumber as string,
    insuredAddress: undefined,
    insuredCity: undefined,
    insuredFirstName: undefined,
    insuredLastName: undefined,
    insuredZipCode: undefined,
    companyForm: state.companyForm as CompanyForm,
    industry1: state.industry1 as CompanyIndustry,
    industry2: state.industry2,
    industry3: state.industry3,
    revenue: state.revenue as CompanyRevenue,
    employeeCount: state.employeeCount as CompanyEmployees,
    savingShortTerm: state.savingsPurpose.savingShortTerm,
    savingLongTerm: state.savingsPurpose.savingLongTerm,
    savingPension: state.savingsPurpose.savingPension,
    savingDiscretionaryManagement: state.savingsPurpose.savingDiscretionaryManagement,
    savingInsurance: state.savingsPurpose.savingInsurance,
    incomeDomesticSavings: state.investmentBackground.incomeDomesticSavings,
    incomeForeignSavings: state.investmentBackground.incomeForeignSavings,
    incomeExcessCapital: state.investmentBackground.incomeExcessCapital,
    incomeExternalCapital: state.investmentBackground.incomeExternalCapital,
    incomeSaleAssets: state.investmentBackground.incomeSaleAssets,
    hasAccountant: !!state.hasAccountant,
    companyPrincipals: [],
    yearlyDepositAmount: state.yearlyDepositAmount as YearlyDepositAmount,
    yearlyDepositAmountManual: state.yearlyDepositAmountManual,
    withdrawalFrequency: state.withdrawalFrequency as WithdrawalFrequency,
    depositFrequency: state.depositFrequency as DepositFrequency,
    isActive: !!state.isActive,
    timeToWithdraw: state.timeToWithdraw as number as TimeToWithdraw,
    averageOperatingProfit: state.economy.averageOperatingProfitAmount,
    forecastResultThisYear: state.economy.forecastResultThisYear,
    solidityPercentage: state.economy.solidityPercentage,
    liquidAssets: state.economy.liquidAssets,
    liquidityPercentage: state.economy.liquidityPercentage,
    hasLongTermDebt: state.economy.hasLongTermDebt,
    authorizedSignatories: signatoriesToSign,
    hasShortTermDebt: state.economy.hasShortTermDebt,
    // Total assets are needed for AML but we don't ask about it in signup, so we get it in backend
    totalAssets: undefined,
    previousInsuranceExperience: state.hasPreviousInsuranceExperience as boolean,
    securitiesOwnerKnowledge: state.hasSecuritiesOwnerKnowledge as boolean,
  });

  if (state.hasCompanyPrincipals) {
    knowYourCustomerAnswers.companyPrincipals = state.companyPrincipals.map(p =>
      getRequestCompanyPrincipal(p),
    );
  } else {
    knowYourCustomerAnswers.companyPrincipals = [
      getRequestCompanyPrincipal(state.companyPrincipals[0]),
    ];
  }

  const legalEntity = new CreateCompanyLegalEntity({
    organizationNumber: state.orgNumber as string,
    email: state.companyEmail as string,
    phoneNumber: state.companyPhoneNumber as string,
    chosenRiskWillingness: state.riskWillingness as number,
    companyKnowYourCustomerAnswers: knowYourCustomerAnswers,
    companyName: state.companyName as string,
    companyAddress: state.companyAddress as string,
    companyCity: state.companyCity as string,
    companyZipCode: state.companyZipCode as string,
    minimizeNegativeSustainabilityImpact: !!signupState.sustainabilityImpact?.minimizeNegativeImpact,
    negativeImpactChanged: !!signupState.sustainabilityImpact?.minimizeNegativeImpact,
    excludeWithDisclosureRegulation: signupState.sustainabilityImpact?.disclosureRegulation,
    excludeWithTaxonomyRegulation: signupState.sustainabilityImpact?.taxonomyRegulation,
    takeSustainabilityImpactIntoAccount: !!signupState.takeSustainabilityIntoAccount,
    wantsPais: !!signupState.takeSustainabilityIntoAccount,
    tiedAgentType: state.tiedAgent?.tiedAgentType,
  });

  const companyRepresentativeUsers = state.companyRepresentatives.map(
    (x: CompanyRepresentativeInfo) =>
      new CreateCompanyRepresentativeUser({
        email: x.email as string,
        phoneNumber: x.phoneNumber as string,
        personalIdentityNumber: x.personalIdentityNumber as string,
        role: x.role as string,
      }),
  );

  const request = new RegisterCompanyParameters({
    portfolio,
    companyRepresentativeUsers,
    legalEntity,
    affiliateInfo: new CreateUserAffiliateInfo({
      affiliateCode: state.affiliateCode,
      affiliateName: state.affiliateName,
    }),
    acceptedPersonalDataPolicy: state.acceptedPersonalDataPolicy as boolean,
  });
  return request;
}
