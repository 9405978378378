<template>
  <SingleChoice
    :alternatives="
      registrationSignState === RegistrationSignState.CONFIRM_AGREEMENTS ? alternatives : []
    "
    :disabled="preparingAgreements || !confirmedInfo"
    @click="goToSigning"
  >
    <div class="summary-sign">
      <QuestionTextSection
        v-if="registrationSignState !== RegistrationSignState.FAILED"
        :heading="
          registrationSignState === RegistrationSignState.CONFIRM_AGREEMENTS
            ? $t('create-portfolio.questions.account.summary-sign.confirmHeading')
            : $t('signBankId')
        "
        :body="body"
      />
      <template v-else>
        <div class="summary-sign__error-icon">
          <icon
            v-if="registerUserError === RegisterUserError.USER_ALREADY_EXISTS"
            :icon="['fal', 'grin-beam']"
          />
          <span v-else-if="registerUserError === RegisterUserError.BELOW_AGE_18"> 18+ </span>
          <icon
            v-else
            :icon="['far', 'frown-open']"
          />
        </div>
        <div class="summary-sign__error-header">
          {{ failedHeader }}
        </div>
        <div class="summary-sign__error-body">
          {{ failedBody }}
        </div>
      </template>

      <div
        v-if="registrationSignState === RegistrationSignState.OPEN_BANKID"
        class="summary-sign__sign-dialog-wrapper"
      >
        <BankId
          :sign-location="SignLocation.CREATE_PORTFOLIO"
          without-heading
          @complete="signComplete"
          @canceled="signCancel"
          @failed="signFailed"
        />
      </div>
      <div v-else-if="registrationSignState === RegistrationSignState.CONFIRM_AGREEMENTS">
        <SummaryConfirm
          v-if="registrationSignState === RegistrationSignState.CONFIRM_AGREEMENTS"
          v-model="confirmedInfo"
          :preparing-agreements="preparingAgreements"
          :bullets="bullets"
          @agreements="() => (showAgreements = true)"
        />
      </div>
      <AgreementsSideDialog
        v-if="showAgreements"
        v-model="showAgreements"
      />
    </div>
  </SingleChoice>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type {
  Portfolio,
} from "../../../../clients";
import {
  LogLevel,
  PdfType,
  RegisterUserError,
  RegisterUserErrorResponse,
} from "../../../../clients";
import BankId from "@/components/bank-id.vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { BankIdAction } from "@/store/bankid/actions";
import { UserAction } from "@/store/user/action-definitions";
import { trackEvent, trackEventGtm, trackEventOnlyGa4 } from "@/clients/segment";
import log from "@/clients/log";
import SummaryConfirm from "@/components/summary-confirm.vue";
import type { SignupState } from "@/store/signup/types";
import { SignupAction } from "@/store/signup/actions";
import { RegistrationSignState } from "@/types/signup";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import AgreementsSideDialog from "@/components/preview-agreements-side-dialog.vue";
import Question from "@/views/signup/templates/question";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import { SignLocation } from "@/store/bankid/types";
import { BankIdMutation } from "@/store/bankid/mutations";
import type { UserState } from "@/store/user/types";

const logger = "summary-sign.vue";

export default defineComponent({
  components: {
    SingleChoice,
    BankId,
    QuestionTextSection,
    AgreementsSideDialog,
    LoadingSpinner,
    SummaryConfirm,
  },
  extends: Question,
  data() {
    return {
      RegistrationSignState,
      RegisterUserError,
      registerUserError: undefined as RegisterUserError | undefined,
      preparingAgreements: true,
      showAgreements: false,
      confirmedInfo: false,
      SignLocation,
    };
  },
  computed: {
    bullets(): any {
      return [
        this.$t("create-portfolio.questions.account.summary-sign.bullet1"),
        this.$t("create-portfolio.questions.account.summary-sign.bullet2"),
        this.$t("create-portfolio.questions.account.summary-sign.bullet3"),
      ];
    },
    registrationSignState: {
      set(value: RegistrationSignState) {
        this.$store.commit(SignupMutation.setRegistrationSignState, value);
      },
      get(): RegistrationSignState {
        return this.$store.state.signupStore.registrationSignState;
      },
    },
    body() {
      switch (this.registrationSignState) {
        case RegistrationSignState.REGISTERING:
          return this.$t("create-portfolio.questions.account.summary-sign.registering");
        default:
          return "";
      }
    },
    failedBody() {
      switch (this.registerUserError) {
        case RegisterUserError.USER_ALREADY_EXISTS:
          return this.$t("registrationConflict");
        case RegisterUserError.BELOW_AGE_18:
          return this.$t("registrationBelowAge18");
        default:
          return this.$t("registrationFailed");
      }
    },
    failedHeader() {
      switch (this.registerUserError) {
        case RegisterUserError.USER_ALREADY_EXISTS:
          return this.$t("conflictHeader");
        case RegisterUserError.BELOW_AGE_18:
          return this.$t("cannotBecomeCustomer");
        default:
          return this.$t("signCompletionHeaderFailed");
      }
    },
    alternatives() {
      return [
        {
          text: this.$t("signBankId"),
          key: "sign",
        },
      ];
    },
  },

  async created() {
    if (this.$store.getters.isCompletingSignatureSuccess(SignLocation.CREATE_PORTFOLIO)) {
      this.$store.commit(BankIdMutation.completeRedirectSignature);
      this.signComplete();
    } else if (this.$store.getters.isCompletingSignatureError(SignLocation.CREATE_PORTFOLIO)) {
      this.$store.commit(BankIdMutation.completeRedirectSignature);
      this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("signError"));
      this.$store.dispatch(SignupNavigationAction.goToPreviousStep, this.$router);
    } else {
      this.registrationSignState = RegistrationSignState.CONFIRM_AGREEMENTS;
      const hasPortfolios
        = this.$store.state.navigationStore.portfolios
        && this.$store.state.navigationStore.portfolios.length > 0;

      try {
        if (hasPortfolios) {
          await this.$store.dispatch(SignupAction.prepareCreatePortfolio);
        } else {
          await this.$store.dispatch(SignupAction.prepareCreateFirstPortfolio);
        }
      } catch (e) {
        this.registrationSignState = RegistrationSignState.FAILED;
        if (e instanceof RegisterUserErrorResponse) {
          this.registerUserError = e.registerUserError;
        }

        console.error(e);
      }
      this.preparingAgreements = false;
    }
  },
  methods: {
    async goToSigning() {
      this.registrationSignState = RegistrationSignState.OPEN_BANKID;
      try {
        await this.$store.dispatch(BankIdAction.sign);
      } catch (e) {
        console.error("signing error", e);
        throw e;
      }
    },
    cancel() {
      this.$store.dispatch(BankIdAction.cancelSign);
    },
    async signComplete() {
      this.registrationSignState = RegistrationSignState.REGISTERING;

      const hasPortfolios
        = this.$store.state.navigationStore.portfolios
        && this.$store.state.navigationStore.portfolios.length > 0;
      let response: Portfolio | null = null;
      try {
        if (hasPortfolios) {
          response = await this.$store.dispatch(SignupAction.completeCreatePortfolio);
        } else {
          response = await this.$store.dispatch(SignupAction.completeCreateFirstPortfolio);
        }

        this.registrationSignState = RegistrationSignState.COMPLETE;
      } catch (error: any) {
        this.registrationSignState = RegistrationSignState.FAILED;
        if (error instanceof RegisterUserErrorResponse) {
          this.registerUserError = error.registerUserError;
        }

        if (this.registrationSignState === RegistrationSignState.FAILED) {
          this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("registrationSnackbar"));

          // Need to completely clear tracking of the store object to make sure we dont write to the store here
          const stateCopy: SignupState = JSON.parse(JSON.stringify(this.$store.state.signupStore));
          stateCopy.personalIdentityNumber = "_SENSITIVE_DATA_";
          stateCopy.firstName = "_SENSITIVE_DATA_";
          stateCopy.lastName = "_SENSITIVE_DATA_";
          stateCopy.address = "_SENSITIVE_DATA_";
          stateCopy.contactDetails.email = "_SENSITIVE_DATA_";
          stateCopy.contactDetails.phoneNumber = "_SENSITIVE_DATA_";
          stateCopy.bankAccountNumber = "_SENSITIVE_DATA_";
          stateCopy.clearingNumber = "_SENSITIVE_DATA_";
          log(
            logger,
            `Unexpected error in registration. Error message=(${
              error.message
            }) Frontend state=(${JSON.stringify(stateCopy)})`,
            LogLevel.Error,
          );
          throw error;
        }
      }

      if (response) {
        trackEventOnlyGa4("created_new_portfolio");
        trackEventGtm("signup_flow_savings_complete", {
          affiliate_code: (this.$store.state.userStore as UserState).currentLegalEntity
            ?.affiliateCampaign?.code,
        });
        if (!hasPortfolios) {
          trackEvent("initial_deposit", {
            initial_deposit_value: response.portfolioSettings.initialDeposit,
          });
          trackEvent("initial_monthly_savings", {
            initial_monthly_savings_value: response.portfolioSettings.monthlySaving,
          });
          trackEvent("initial_chosen_risk_level", {
            initial_chosen_risk_level: response.strategy.chosenRiskLevel,
          });
          // sign_up conversion event in google ads
          const signupValue
            = response.portfolioSettings.monthlySaving !== undefined
            && response.portfolioSettings.monthlySaving > 0
              ? 5000
              : 5000 / 3;
          log(logger, `ad_signup (value=${signupValue})`, LogLevel.Information);
          trackEventOnlyGa4("conversion", {
            send_to: "AW-966604819/Z5gRCKygzqQCEJPw9MwD",
            value: signupValue,
            currency: "SEK",
          });
        }
      }

      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    signCancel() {
      this.registrationSignState = RegistrationSignState.CONFIRM_AGREEMENTS;
    },
    signFailed() {
      this.registrationSignState = RegistrationSignState.FAILED;
      this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("signError"));
    },
  },
});
</script>

<style lang="scss" scoped>
.summary-sign {
  width: 100%;
  height: 100%;
  &__sign-dialog-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &__personal-identity-number-wrapper {
    display: grid;
    grid-column-gap: 1rem;
    margin: 6vh auto 0 auto;
    max-width: 100%;
    width: 21.75rem;
    grid-template-columns: 1fr auto 1fr;
  }
  &__form {
    .v-input {
      font-size: 1.25rem;
      border-radius: 0.5rem;
    }
    :deep(input) {
      font-weight: bold;
    }
    .v-input--is-focused {
      :deep(.v-input__slot) {
        box-shadow: none !important;
      }
    }
  }
  &__error-icon {
    padding-top: 2rem;
    text-align: center;
    font-weight: 600;
    font-size: 1.6rem;
  }
  &__error-header {
    text-align: center;
    font-weight: 600;
    font-size: 1.3rem;
  }
  &__error-body {
    text-align: center;
  }
}
</style>
