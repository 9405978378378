<template>
  <current-savings @next="next" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CurrentSavings from "@/views/common-signup/questions/economy/current-savings.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupFlowModificationType } from "@/config/signup-modification";

export default defineComponent({
  components: { CurrentSavings },
  methods: {
    async next(value: number) {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.CurrentSavingsWarning,
        include: value === 0
      });
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
