<template>
  <profile
    :calculated-risk-level="calculatedRiskLevel"
    :chosen-risk-level="chosenRiskLevel"
    :stocks-fraction="stocksFraction"
    :market-focus="marketFocus"
    :focus="focus"
    :included-sectors="includedSectors"
    :heading="$t('riskProposal')"
    :body="$t('profileRecommended')"
    :body-custom="$t('customRiskInfo')"
    @setCustomRiskLevel="setCustomRiskLevel"
    @resetRiskLevel="resetRiskLevel"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { RiskWillingness } from "@/clients";
import { SignupMutation } from "@/store/signup/mutations";
import Profile from "@/views/common-signup/questions/profile/profile.vue";

export default defineComponent({
  components: { Profile },
  computed: {
    ...mapGetters(["chosenRiskLevel", "stocksFraction"]),
    calculatedRiskLevel() {
      return this.$store.state.signupStore.calculatedRiskLevel;
    },
    marketFocus() {
      return this.$store.state.signupStore.marketFocus;
    },
    focus() {
      return this.$store.state.signupStore.focus;
    },
    includedSectors() {
      return this.$store.state.signupStore.includedSectors;
    },
    riskWillingness: {
      get(): string {
        return this.$store.state.signupStore.riskWillingness;
      },
      set(value: RiskWillingness) {
        this.$store.commit(SignupMutation.setRiskWillingness, value);
      }
    }
  },
  methods: {
    setCustomRiskLevel(value: number) {
      this.$store.commit(SignupMutation.setCustomRiskLevel, value);
    },
    resetRiskLevel() {
      this.$store.commit(SignupMutation.setCustomRiskLevel, undefined);
    }
  }
});
</script>

<style lang="scss" scoped>
$text-max-width: 21.25rem;
.profile {
  width: 100%;
  height: 100%;
  &__carousel {
    display: flex;
    justify-content: flex-end;
    flex-direction: column-reverse;
    height: 12rem !important;
    margin-bottom: 3vh;
    &:deep(.v-carousel__controls__item) {
      color: $soft-blue;
      margin: 0 0.1rem;
      width: 0.75rem;
      height: 0.75rem;
      &.v-btn--active {
        .v-icon {
          opacity: 1;
        }
      }
      .v-icon {
        font-size: 0.5rem !important;
        width: 0.75rem !important;
        height: 0.75rem !important;
      }
    }
    &:deep(.v-carousel__controls) {
      position: relative;
      height: auto;
    }
    &:deep(.v-carousel__item) {
      margin: 1rem;
      box-shadow: 0 0.1875rem 1.25rem rgba(96, 96, 96, 0.1);
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__presentation {
    display: flex;
    margin-bottom: 2rem;
  }
  &__risk-carousel-item {
    width: 12rem;
    margin: auto;
  }
  &__choices-carousel-item {
    width: $text-max-width;
    max-width: 100%;
    margin: auto;
  }
}
</style>
