export function setJwtToken(token: string | undefined) {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
}

export function getJwtToken(): string | undefined {
  const token = sessionStorage.getItem("token");
  if (token) {
    return token;
  }
  return undefined;
}
