<template>
  <div class="contact">
    <card-header
      class="contact__header"
      :title="$t('contact')"
      :icon="['fal', 'comments']"
    >
      <edit-button
        v-if="editable && !errorLoadingUser"
        :edit="edit"
        @click="toggleEdit"
      />
    </card-header>
    <v-form
      ref="form"
      class="contact__form"
    >
      <v-text-field
        v-if="!edit"
        v-model="editedPhoneNumber"
        variant="underlined"
        :class="['contact__input', { 'contact__input--disabled': !edit }]"
        :disabled="!edit"
        validate-on="blur"
        :label="$t('phoneNumber')"
      />
      <phone-number-input
        v-if="edit"
        :initial-phone-number="editedPhoneNumber"
        @update:model-value="(val) => (editedPhoneNumber = val)"
      />
      <v-text-field
        v-model="editedEmail"
        :class="['contact__input', { 'contact__input--disabled': !edit }]"
        :disabled="!edit"
        type="email"
        validate-on="blur"
        :label="$t('email')"
        :rules="[rules.required, rules.email]"
        variant="underlined"
      />
    </v-form>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import EditButton from "@/components/edit-button.vue";
import { LegalEntity, LegalEntityUser } from "@/clients";
import { UserMutation } from "@/store/user/mutations";
import PhoneNumberInput from "@/components/phone-number-input.vue";
import CardHeader from "./card-header.vue";

export default defineComponent({
  emits: ["save"],
  components: { CardHeader, EditButton, PhoneNumberInput },
  props: {
    editable: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    edit: false
  }),
  computed: {
    ...mapGetters(["errorLoadingUser", "isCompany"]),
    editedLegalEntity(): LegalEntity | undefined {
      return this.$store.state.userStore.editedLegalEntity;
    },
    editedPhoneNumber: {
      get(): string | undefined {
        // A bit hacky until we figure out how to edit legal entity users
        if (this.isCompany) {
          return (
            this.$store.state.userStore.currentUser?.legalEntityUsers as LegalEntityUser[]
          ).find(
            (le: LegalEntityUser) =>
              le.legalEntity &&
              le.legalEntity.brickId === this.$store.state.userStore.currentLegalEntity.brickId
          )?.companyRepresentativePhoneNumber;
        }
        if (this.editedLegalEntity) {
          return this.editedLegalEntity.phoneNumber;
        }
        return undefined;
      },
      set(phoneNumber: string) {
        this.$store.commit(UserMutation.editContactInformation, {
          phoneNumber,
          email: this.editedEmail
        });
      }
    },
    editedEmail: {
      get(): string | undefined {
        // A bit hacky until we figure out how to edit legal entity users
        if (this.isCompany) {
          return (
            this.$store.state.userStore.currentUser?.legalEntityUsers as LegalEntityUser[]
          ).find(
            (le: LegalEntityUser) =>
              le.legalEntity &&
              le.legalEntity.brickId === this.$store.state.userStore.currentLegalEntity.brickId
          )?.companyRepresentativeEmail;
        }
        if (this.editedLegalEntity) {
          return this.editedLegalEntity.email;
        }
        return undefined;
      },
      set(email: string) {
        this.$store.commit(UserMutation.editContactInformation, {
          phoneNumber: this.editedPhoneNumber,
          email
        });
      }
    },
    rules(): any {
      return {
        required: (value: string) => (!!value && value !== "") || this.$t("required"),
        email: (value: string) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("invalidEmail");
        }
      };
    }
  },
  watch: {
    errorLoadingUser: {
      immediate: true,
      handler(newValue: boolean) {
        if (newValue) {
          this.editedPhoneNumber = "-";
          this.editedEmail = "-";
        }
      }
    }
  },
  methods: {
    async toggleEdit() {
      if (this.edit) {
        if ((await (this.$refs.form as any).validate()).valid) {
          this.$store.commit(UserMutation.editContactInformation, {
            email: this.editedEmail,
            phoneNumber: this.editedPhoneNumber
          });

          this.$emit("save");
          this.$store.commit(UserMutation.decreaseUnsavedEdits);
          this.edit = false;
        }
      } else {
        this.edit = true;
        this.$store.commit(UserMutation.increaseUnsavedEdits);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.contact {
  padding: 1.5rem 1.5rem 0.825rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__form {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
  }
  &__input {
    color: black;
    @include small-down {
      width: 100%;
    }
    :deep(label) {
      color: #707070 !important;
      font-size: 1.2rem;
      font-weight: 300;
    }
    :deep(.v-text-field) {
      background-color: transparent !important;
      margin-top: 0;
    }
    &--bottom {
      grid-column: 1 / 3;
      grid-row: 3;
    }
    &--disabled {
      :deep(.v-text-field__suffix) {
        color: inherit !important;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-label) {
      max-width: 133%;
      -webkit-transform: translateY(-1.125rem) scale(0.75);
      transform: translateY(-1.125rem) scale(0.75);
    }
  }
}
</style>
