<template>
  <two-action-dialog
    :model-value="modelValue"
    :primary-color="dialogColor"
    :heading="$t('components.portfolio-created-dialog.portfolioCreatedHeading')"
    :body-sections="[$t('components.portfolio-created-dialog.portfolioCreatedBody')]"
    :button1-text="$t('components.portfolio-created-dialog.depositSwish')"
    :button2-text="$t('components.portfolio-created-dialog.depositBankAccount')"
    @button-1-click="showDepositSideDialog"
    @button-2-click="showDepositSideDialog"
    @update:model-value="inputChange"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TwoActionDialog from "@/components/two-action-dialog.vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { softBlue } from "@/styles/colors";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    TwoActionDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dialogColor() {
      return this.$store.state.userStore.currentLegalEntity?.tiedAgent?.color ?? softBlue;
    }
  },
  methods: {
    showDepositSideDialog() {
      this.$store.commit(PortfolioMutation.setShowDepositDialog, true);
      this.$emit("update:model-value", false);
    },
    inputChange(value: boolean) {
      if (!value) {
        this.$emit("update:model-value", false);
      }
    }
  }
});
</script>

<style lang="scss" scoped></style>
