<template>
  <div class="referral">
    <div class="referral__wrapper">
      <icon
        fixed-width
        :icon="['fal', 'calendar-day']"
        class="referral__icon"
      />
      <h2 class="referral__title">
        {{ $t("portfolio.pension-move-status.title") }}
      </h2>
    </div>
    <div class="referral__content-wrapper">
      <pension-listing
        :insurance-company="inProgressPension?.insuranceCompany"
        :insurance-holder-name="inProgressPension.insuranceHolderName"
        :insurance-number="inProgressPension?.insuranceNumber"
        :total-value="inProgressPension.approximateValue"
      />
      <v-divider style="margin: 0.4rem 0" />
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 0.875rem;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            height: 3rem;
          "
        >
          <div style="font-weight: bold">{{ step }} / {{ statusOrder.length }}</div>
          <img
            v-if="step === 1"
            :src="loadImageByName('loading-1of5.svg')"
          />
          <img
            v-else-if="step === 2"
            :src="loadImageByName('loading-2of5.svg')"
          />
          <img
            v-else-if="step === 3"
            :src="loadImageByName('loading-3of5.svg')"
          />
          <img
            v-else-if="step === 4"
            :src="loadImageByName('loading-4of5.svg')"
          />
          <img
            v-else-if="step === 5"
            :src="loadImageByName('loading-5of5.svg')"
          />
        </div>
        <div style="align-self: end; flex-basis: 83%">
          {{ statusText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { InProgressPension, InProgressPensionStatus } from "@/clients";
import PensionListing from "@/components/pension-listing.vue";

export default defineComponent({
  components: {
    PensionListing
  },
  props: {
    inProgressPension: {
      type: Object as () => InProgressPension,
      required: true
    }
  },
  data: () => ({
    statusOrder: [
      InProgressPensionStatus.WaitingForInsuredSignature,
      InProgressPensionStatus.WaitingForInsuranceHolderSignature,
      InProgressPensionStatus.WaitingForIdunSignature,
      InProgressPensionStatus.WaitingForInsuranceCompanySignature,
      InProgressPensionStatus.Completed
    ]
  }),
  methods: {
    loadImageByName(name: string) {
      return new URL(`/assets/icons/${name}`, import.meta.url).href;
    }
  },
  computed: {
    step(): number {
      return Object.values(this.statusOrder).indexOf(this.inProgressPension.status) + 1;
    },
    statusText(): string {
      const text = this.$t(
        `inProgressPensionStatusDescription.${
          InProgressPensionStatus[this.inProgressPension.status]
        }`
      );
      if (!text) {
        return "Okänd";
      }
      return text;
    }
  }
});
</script>

<style lang="scss" scoped>
.referral {
  display: flex;
  flex-direction: column;
  &__grid {
    display: grid;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  &__chart {
    grid-column: 1 / 4;
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.125rem;
    margin-right: 0.75rem;
  }
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
  }
  &__forward-icon {
    margin: 0.5rem 0 auto auto;
    color: $french-grey;
  }
}
</style>
