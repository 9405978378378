<template>
  <div>
    <div class="pension-plan">
      <icon
        fixed-width
        :icon="['fal', 'calendar-day']"
        class="pension-plan__icon"
      />
      <h2 class="pension-plan__title">
        {{ $t("portfolio.pension-plan.title") }}
      </h2>
      <icon
        :icon="['fal', 'chevron-right']"
        class="pension-plan__forward-icon"
      />
    </div>
    <div class="pension-plan__wrapper">
      <div class="pension-plan__row">
        <span class="pension-plan__subheader">{{ $t("portfolio.pension-plan.pensionAge") }}</span>
        <span class="pension-plan__value">{{ pensionAge }} {{ $t("years") }}</span>
      </div>
      <div class="pension-plan__row">
        <span class="pension-plan__subheader">{{ $t("portfolio.pension-plan.payoutYears") }}</span>
        <span class="pension-plan__value">{{ payoutYears }} {{ $t("years") }}</span>
      </div>
      <v-divider class="pension-plan__divider" />
      <div>
        {{ $t("portfolio.pension-plan.description1") }} {{ payoutPerMonth }}
        {{ $t("portfolio.pension-plan.description2") }} {{ payoutYears }}
        {{ $t("portfolio.pension-plan.description3") }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { Strategy } from "@/clients";

export default defineComponent({
  computed: {
    ...mapGetters(["totalValue"]),
    pensionAge(): string {
      const strategy = this.$store.state.portfolioStore.strategy as Strategy;
      if (strategy && strategy.pensionAge) {
        return strategy.pensionAge.toString();
      }
      return "-";
    },
    payoutYears(): string {
      const strategy = this.$store.state.portfolioStore.strategy as Strategy;
      if (strategy && strategy.pensionPayoutYears) {
        return strategy.pensionPayoutYears.toString();
      }
      return "-";
    },
    payoutPerMonth(): string {
      const strategy = this.$store.state.portfolioStore.strategy as Strategy;
      if (this.totalValue !== undefined && strategy && strategy.pensionPayoutYears) {
        const payout = this.totalValue / (12 * strategy.pensionPayoutYears);
        return Math.round(payout).toLocaleString("sv-SE");
      }
      return "-";
    }
  }
});
</script>

<style lang="scss" scoped>
.pension-plan {
  font-family: $heading-font-family;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto auto 1fr;
  align-items: center;
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.25rem;
    margin-right: 0.75rem;
  }
  &__row {
    font-family: $heading-font-family;
    padding-top: 0.8125rem;
    display: flex;
    justify-content: space-between;
  }
  &__subheader {
    white-space: nowrap;
    font-weight: 300;
  }
  &__forward-icon {
    margin: auto 0 auto auto;
    color: $french-grey;
  }
  &__divider {
    margin: 0.8125rem 0;
  }
}
</style>
