import type { MutationTree } from "vuex";
import type { OverallData, PensionInfo, PensionSignupState } from "./types";
import type {
  BankIdAuthenticationData,
  CompanyStatus,
  Market,
  PensionData,
  PensionRiskProfile,
  Sector,
  StrategyFocus,
  TiedAgentPortfolioTheme,
} from "@/clients";

export const getPensionSignupInitialState = (): PensionSignupState => ({
  insurelyCollectionId: undefined,
  completedAllSearches: false,
  completedPensions: {},
  selectablePensions: {},
  selectablePensionsOriginal: {},
  companyStatuses: undefined,
  companiesToSearch: undefined,
  testUserPersonalIdentityNumber: undefined,
  companyInfo: {},
  payoutAge: 67,
  payoutTime: 10,
  hasPrivatePensionSavings: undefined,
  hasOtherOccupationalPensionSavings: undefined,
  focus: undefined,
  marketFocus: undefined,
  includedSectors: {
    fossilFuel: true,
    alcoholTobacco: true,
    weaponsAndDefense: true,
    casinosAndGambling: true,
  },
  shouldMerge: false,
  calculatedRiskProfile: undefined,
  customRiskProfile: undefined,
  soleOwnerships: {},
  portfolioTheme: undefined,
});

export const PensionSignupMutation = {
  setCompletedAllSearches: "setCompletedAllSearches",
  setPensionCollectionStatus: "setPensionCollectionStatus",
  addSelectablePension: "addSelectablePension",
  resetSelectablePensions: "resetSelectablePensions",
  setCompanyStatuses: "setCompanyStatuses",
  setCompaniesToSearch: "setCompaniesToSearch",
  setTestUserPersonalIdentityNumber: "setTestUserPersonalIdentityNumber",
  setCollectionId: "setCollectionId",
  setPensionCollectionExtraInformation: "setPensionCollectionExtraInformation",
  addCompanyInfo: "addCompanyInfo",
  resetCompanyInfo: "resetCompanyInfo",
  setPensionSelected: "setPensionSelected",
  setPensionActivePayment: "setPensionActivePayment",
  setSoleOwner: "setSoleOwner",
  setPayoutAge: "setPayoutAge",
  setPayoutTime: "setPayoutTime",
  setHasPrivatePensionSavings: "setHasPrivatePensionSavings",
  setHasOtherOccupationalPensionSavings: "setHasOtherOccupationalPensionSavings",
  includeSector: "includeSector",
  setFocus: "setFocus",
  setRiskWillingness: "setRiskWillingness",
  setMarketFocus: "setMarketFocus",
  setState: "setState",
  cancelPensionPolling: "cancelPensionPolling",
  setShouldMerge: "setShouldMerge",
  setCalculatedRiskProfile: "setCalculatedRiskProfile",
  setCustomRiskProfile: "setCustomRiskProfile",
  setPensionOrganisationNumber: "setPensionOrganisationNumber",
  setPensionOrganisationName: "setPensionOrganisationName",
  setPortfolioTheme: "setPortfolioTheme",
  setPensionEmail: "setPensionEmail",
};
export const mutations: MutationTree<PensionSignupState> = {
  [PensionSignupMutation.setState](state, stateChange: PensionSignupState) {
    Object.assign(state, stateChange);
  },
  [PensionSignupMutation.setCompletedAllSearches](state, value: boolean) {
    state.completedAllSearches = value;
  },
  [PensionSignupMutation.addSelectablePension](state, pensionData: PensionData[]) {
    pensionData.forEach((x) => {
      const info = {
        selected: false,
        pension: x,
      } as PensionInfo;
      state.selectablePensions[x.externalId as string] = info;
      // Store the original in case we need to retrieve the original data
      state.selectablePensionsOriginal[x.externalId as string] = info;
    });
  },
  [PensionSignupMutation.resetSelectablePensions](state) {
    state.selectablePensions = {};
  },
  [PensionSignupMutation.setCompanyStatuses](state, value: CompanyStatus[]) {
    state.companyStatuses = value;
  },
  [PensionSignupMutation.setCompaniesToSearch](state, value: string[]) {
    state.companiesToSearch = value;
  },
  [PensionSignupMutation.setTestUserPersonalIdentityNumber](state, value: string) {
    state.testUserPersonalIdentityNumber = value;
  },
  [PensionSignupMutation.addCompanyInfo](state, insuranceCompanyId: string) {
    state.companyInfo[insuranceCompanyId] = { status: "RUNNING" };
  },
  [PensionSignupMutation.setCollectionId](
    state,
    value: { insuranceCompanyId: string; insurelyCollectionId: string },
  ) {
    const companyInfo = {
      ...state.companyInfo[value.insuranceCompanyId],
      insurelyCollectionId: value.insurelyCollectionId,
    } as OverallData;
    state.companyInfo[value.insuranceCompanyId] = companyInfo;
  },
  [PensionSignupMutation.setPensionCollectionStatus](
    state,
    value: { insuranceCompanyId: string; status: string | undefined },
  ) {
    const companyInfo = {
      ...state.companyInfo[value.insuranceCompanyId],
      status: value?.status,
    } as OverallData;
    state.companyInfo[value.insuranceCompanyId] = companyInfo;
  },
  [PensionSignupMutation.cancelPensionPolling](state) {
    Object.keys(state.companyInfo).forEach((k) => {
      const companyInfo = {
        ...state.companyInfo[k],
        pollingCancelled: true,
      } as OverallData;
      state.companyInfo[k] = companyInfo;
    });
  },
  [PensionSignupMutation.setPensionCollectionExtraInformation](
    state,
    value: { insuranceCompanyId: string; extraInformation: BankIdAuthenticationData },
  ) {
    const companyInfo = {
      ...state.companyInfo[value.insuranceCompanyId],
      extraInformation: value?.extraInformation,
    } as OverallData;
    state.companyInfo[value.insuranceCompanyId] = companyInfo;
  },
  [PensionSignupMutation.resetCompanyInfo](state) {
    state.companyInfo = {};
  },
  [PensionSignupMutation.setPensionSelected](
    state,
    value: { externalId: string; selected: boolean },
  ) {
    const info = {
      ...state.selectablePensions[value.externalId],
      selected: value.selected,
    } as PensionInfo;
    state.selectablePensions[value.externalId] = info;
  },
  [PensionSignupMutation.setPensionActivePayment](
    state,
    value: { externalId: string; confirmedActivePayment: boolean },
  ) {
    const info = {
      ...state.selectablePensions[value.externalId],
      confirmedActivePayment: value.confirmedActivePayment,
    } as PensionInfo;
    state.selectablePensions[value.externalId] = info;
  },
  [PensionSignupMutation.setSoleOwner](
    state,
    value: { insuranceHolderName: string; soleOwner: boolean },
  ) {
    state.soleOwnerships[value.insuranceHolderName] = value.soleOwner;
  },
  [PensionSignupMutation.setPayoutAge](state, value: number) {
    state.payoutAge = value;
  },
  [PensionSignupMutation.setPayoutTime](state, value: number) {
    state.payoutTime = value;
  },
  [PensionSignupMutation.setHasPrivatePensionSavings](state, value: boolean) {
    state.hasPrivatePensionSavings = value;
  },
  [PensionSignupMutation.setHasOtherOccupationalPensionSavings](state, value: boolean) {
    state.hasOtherOccupationalPensionSavings = value;
  },
  [PensionSignupMutation.includeSector](state, payload: { key: Sector; value: boolean }) {
    state.includedSectors[payload.key] = payload.value;
  },
  [PensionSignupMutation.setFocus](state, payload: StrategyFocus) {
    state.focus = payload;
  },
  [PensionSignupMutation.setMarketFocus](state, market: Market) {
    state.marketFocus = market;
  },
  [PensionSignupMutation.setShouldMerge](state, value: boolean) {
    state.shouldMerge = value;
  },
  [PensionSignupMutation.setCalculatedRiskProfile](state, value: PensionRiskProfile) {
    state.calculatedRiskProfile = value;
  },
  [PensionSignupMutation.setCustomRiskProfile](state, value: PensionRiskProfile) {
    state.customRiskProfile = value;
  },
  [PensionSignupMutation.setPensionOrganisationNumber](
    state,
    payload: { externalId: string; value: string },
  ) {
    const info = {
      ...state.selectablePensions[payload.externalId],
      pension: {
        ...state.selectablePensions[payload.externalId].pension,
        insuranceHolderOrgNumber: payload.value,
      } as PensionData,
    } as PensionInfo;
    state.selectablePensions[payload.externalId] = info;
  },
  [PensionSignupMutation.setPensionEmail](state, payload: { externalId: string; value: string }) {
    const info = {
      ...state.selectablePensions[payload.externalId],
      insuranceHolderEmail: payload.value,
    } as PensionInfo;
    state.selectablePensions[payload.externalId] = info;
  },
  [PensionSignupMutation.setPensionOrganisationName](
    state,
    payload: { externalId: string; value: string },
  ) {
    const info = {
      ...state.selectablePensions[payload.externalId],
      pension: {
        ...state.selectablePensions[payload.externalId].pension,
        insuranceHolderName: payload.value,
      } as PensionData,
    } as PensionInfo;
    state.selectablePensions[payload.externalId] = info;
  },
  [PensionSignupMutation.setPortfolioTheme](state, value: TiedAgentPortfolioTheme) {
    state.portfolioTheme = value;
  },
};
