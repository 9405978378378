<template>
  <div class="section-header">
    <v-card class="section-header__card">
      <div class="section-header__left">
        <div class="section-header__icon-wrapper">
          <slot name="icon" />
        </div>
        <div class="section-header__text-wrapper">
          <span class="section-header__header">
            {{ header }}
          </span>
          <v-divider
            v-if="subHeader"
            vertical
            inset
          />
        </div>
      </div>
      <div class="section-header__middle">
        <div class="section-header__text-wrapper">
          <span class="section-header__sub-header">
            {{ subHeader }}
          </span>
        </div>
        <slot name="extra-sub-header" />
      </div>
      <v-divider
        v-if="!!$slots.default && !noDivider"
        vertical
      />
      <slot />
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    header: {
      type: String,
      required: true
    },
    subHeader: {
      type: String,
      required: false,
      default: undefined
    },
    noDivider: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
.section-header {
  &__card {
    display: flex;

    height: 3.5rem;
  }
  &__icon-wrapper {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    margin-right: 1.6875rem;
    margin-left: 1.5625rem;
    justify-content: center;
    color: $nearly-black;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }
  &__left {
    display: flex;
  }
  &__middle {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__text-wrapper {
    display: flex;
    align-self: center;
    align-items: baseline;
    padding: 1.125rem 0.25rem;
  }
  &__header {
    font-family: $heading-font-family;
    font-weight: 500;
    font-size: 1.375rem;
    margin-right: 1rem;
  }
  &__sub-header {
    margin-left: 1rem;
    font-family: $heading-font-family;
    font-weight: 300;
  }
  &__slot-divider {
    margin-left: auto;
  }
}
</style>
