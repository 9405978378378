<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import SettingsHeader from "../header.vue";
import EsgSettingsCheckbox from "./esg-settings-checkbox.vue";
import EsgSettingsOverlay from "./esg-settings-overlay.vue";
import { softBlue } from "@/styles/colors";
import InformationButton from "@/components/information-button.vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import type { PortfolioState } from "@/store/portfolio/types";
import type { EsgPais } from "@/types/portfolio";
import EditButton from "@/components/edit-button.vue";
import SustainableChoiceSideDialog from "@/components/sustainable-choice-side-dialog.vue";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import type { PaiCategory } from "@/config/esg-pais";
import { disabledPais, paiConfiguration } from "@/config/esg-pais";

const emit = defineEmits<{
  save: [type: string];
}>();

const store = useStore();

const answeredNewEsg = store.getters.answeredNewEsg;

const strategyLoaded = computed(() => {
  return (store.state.portfolioStore as PortfolioState).strategy;
});

const showDialog = ref(false);
const tab = ref(0);

const tabs = [
  {
    icon: ["fal", "sliders-h"],
    key: "overview",
    title: "Översikt",
  },
  ...paiConfiguration,
];

const editedEsgPais = computed<{ [key: string]: boolean } | undefined>(
  () => (store.state.portfolioStore as PortfolioState).editedStrategy?.esgPais,
);

function enabledPaisWithValue(pais: string[]): EsgPais {
  if (editedEsgPais.value) {
    return Object.fromEntries(
      Object.entries(editedEsgPais.value).filter(
        x => pais.includes(x[0]) && !disabledPais.includes(x[0]),
      ),
    );
  }
  return {};
}

function allSelected(pais: string[]) {
  const enabledPais = enabledPaisWithValue(pais);
  if (enabledPais && Object.keys(enabledPais).length > 0) {
    return Object.values(enabledPais).every(x => x === true);
  }
  return false;
}

function someSelectedButNotAll(pais: string[]) {
  const enabledPais = enabledPaisWithValue(pais);
  if (enabledPais && Object.keys(enabledPais).length > 0) {
    let matchedCount = 0;
    for (const pai of pais) {
      if (enabledPais[pai] === false) {
        matchedCount++;
      }
    }
    return matchedCount !== Object.keys(enabledPais).length && matchedCount !== 0;
  }
  return false;
}

function click(pais: string[]) {
  const enabledPais = enabledPaisWithValue(pais);
  if (enabledPais) {
    const res: EsgPais = {};
    if (allSelected(Object.keys(enabledPais))) {
      Object.entries(enabledPais).forEach(p => (res[p[0]] = false));
    } else {
      Object.entries(enabledPais).forEach(p => (res[p[0]] = true));
    }
    store.commit(PortfolioMutation.editEsgPais, { ...editedEsgPais.value, ...res });
  }
}

function formatPais(category: PaiCategory) {
  const enabledPais = enabledPaisWithValue(category.pais);

  if (enabledPais && Object.keys(enabledPais).length > 0) {
    const selected = Object.entries(enabledPais).filter(x => x[1] === true);
    if (Object.keys(selected).length > 0) {
      const result = `PAI ${selected.map(x => x[0]).join(", ")}`;
      return result;
    }
  }
  return "-";
}

const edit = ref(false);
function toggleEdit() {
  const esgPais = (store.state.portfolioStore as PortfolioState).strategy?.esgPais;
  if (edit.value && editedEsgPais.value && esgPais) {
    const somePaiChanged = paiConfiguration
      .flatMap(x => x.pais)
      .some(pai => editedEsgPais.value && editedEsgPais.value[pai] !== esgPais[pai]);
    if (somePaiChanged) {
      emit("save", "esg");
    }
  }
  edit.value = !edit.value;
}
</script>

<template>
  <div class="esg">
    <EsgSettingsOverlay
      v-if="!answeredNewEsg"
    />
    <LoadingSpinnerOverlay
      v-else-if="!strategyLoaded"
      :model-value="true"
      persistent
    />
    <VWindow
      v-model="tab"
      class="esg__tabs-items"
    >
      <VWindowItem value="overview">
        <SettingsHeader
          class="esg__header"
          :title="$t('esg.esg.esgTitle')"
          :icon="['fal', 'leaf']"
        >
          <template #action>
            <EditButton
              :edit="edit"
              :aria-label-type="edit ? 'ESG-inställningar' : 'ESG-inställningar'"
              @click="toggleEdit"
            />
          </template>
          <template #info>
            <InformationButton @click="showDialog = true" />
          </template>
        </SettingsHeader>
        <VDivider />
        <div style="padding: 1.5rem 1.5rem 0 1.5rem">
          <EsgSettingsCheckbox
            v-for="category in paiConfiguration"
            :key="category.key"
            class="checkbox"
            :disabled="!edit"
            :model-value="allSelected(category.pais) || false"
            :heading="category.title"
            :sub-heading="formatPais(category)"
            :indeterminate="someSelectedButNotAll(category.pais)"
            @click="click(category.pais)"
          />
        </div>
      </VWindowItem>
      <VWindowItem
        v-for="[pais, key] in paiConfiguration.map((x) => [x.pais, x.key])"
        :key="key"
        :value="key"
      >
        <SettingsHeader
          class="esg__header"
          :title="$t('esg.esg.esgTitle')"
          :icon="['fal', 'leaf']"
        >
          <template #action>
            <EditButton
              :edit="edit"
              :aria-label="edit ? 'Ändra ESG-inställningar' : 'Spara ESG-inställningar'"
              @click="toggleEdit"
            />
          </template>
          <template #info>
            <InformationButton @click="showDialog = true" />
          </template>
        </SettingsHeader>
        <VDivider />
        <div style="padding: 0.5rem 1.5rem 0 1.5rem">
          <EsgSettingsCheckbox
            v-for="pai in pais"
            :key="pai"
            class="checkbox"
            :disabled="!edit || disabledPais.includes(pai)"
            :model-value="(editedEsgPais && editedEsgPais[pai]) || false"
            :heading="`PAI ${pai}`"
            :sub-heading="$t(`paiDescriptions.${pai}`)"
            @click="click([pai])"
          />
        </div>
      </VWindowItem>
    </VWindow>
    <VDivider />
    <VTabs
      v-model="tab"
      :color="softBlue"
      class="esg__tabs"
      height="3.5rem"
      align-tabs="center"
    >
      <VTab
        v-for="tabButton in tabs"
        :key="tabButton.key"
        :value="tabButton.key"
        :data-cy="`strategyTab${tabButton.key}`"
        class="esg__tab"
        :aria-label="`Byt till flik ${tabButton.title}`"
        selected-class="esg__tab--active"
      >
        <icon
          fixed-width
          class="esg__tab-icon"
          :icon="tabButton.icon"
        />
      </VTab>
    </VTabs>
    <SustainableChoiceSideDialog v-model="showDialog" />
  </div>
</template>

<style lang="scss" scoped>
.esg {
  height: 100%;
  &__header {
    padding: 1.125rem 1.25rem;
    max-height: 3.75rem;
  }
  &__tabs-items {
    height: 34rem;
  }
  &__tabs {
    width: 100%;
  }
  &__tab {
    font-size: 1.5rem;
    &--active {
      .esg__tab-icon {
        color: $soft-blue;
        filter: drop-shadow(0 0.1875rem 0.375rem rgba($soft-blue, 0.4));
      }
    }
  }
  &__tab-icon {
    color: rgba($nearly-black, 0.2);
  }
  &__first-time {
    padding: 2rem;
    text-align: center;
    font-family: sofia-pro;
    width: 26rem;
    margin: 0 auto;
    &--button {
      margin-top: 2rem;
    }
  }
}

:deep(.v-tab.v-tab.v-btn) {
  width: 5rem !important;
  min-width: unset !important;
  flex: 1 0 0;
}
:deep(.v-slide-group__content) {
  width: 100%;
}
// Can't use the proper background color prop, see https://github.com/vuetifyjs/vuetify/issues/10633
:deep(.v-tabs-bar) {
  background-color: transparent !important;
}

:deep(.v-window) {
  background-color: transparent !important;
}
</style>
