<template>
  <v-dialog
    v-model="showDialog"
    :persistent="errorAgreements.length > 0"
    content-class="sign-agreements"
    @click:outside="cancel"
  >
    <v-card class="sign-agreements__card">
      <template v-if="state === SignDialogState.VIEW_AGREEMENTS">
        <p class="sign-agreements__header">
          {{
            errorAgreements
              ? $t("components.sign-agreements-dialog.errorAgreementHeader")
              : $t("components.sign-agreements-dialog.updateHeader")
          }}
        </p>
        <p class="sign-agreements__body">
          {{
            errorAgreements
              ? $t("components.sign-agreements-dialog.errorAgreementBody")
              : $t("components.sign-agreements-dialog.updateBody")
          }}
        </p>
        <div
          class="sign-agreements__agreements"
          @click="showAgreements"
        >
          <p style="color: inherit; text-decoration: underline">
            {{ $t("components.sign-agreements-dialog.signExplanationBody") }}
          </p>
          <icon
            class="sign-agreements__agreements-icon"
            :icon="['fal', 'chevron-right']"
          />
        </div>
        <v-card-actions class="sign-agreements__actions">
          <common-button
            class="sign-agreements__sign-button"
            @click="sign"
          >
            {{ $t("signBankId") }}
          </common-button>
        </v-card-actions>
      </template>
      <template v-if="state === SignDialogState.SIGNING">
        <bank-id
          :sign-location="SignLocation.ERROR_AGREEMENTS"
          @complete="signComplete"
          @canceled="signCancel"
          @failed="signFailed"
        />
      </template>
      <template v-if="state === SignDialogState.COMPLETE">
        <p class="sign-agreements__header">
          {{ signSettingsFailed ? $t("signCompletionHeaderFailed") : $t("completionHeader") }}
        </p>
        <p class="sign-agreements__body">
          {{
            signSettingsFailed
              ? $t("signCompletionBodyFailed")
              : $t("components.sign-agreements-dialog.completionBody")
          }}
        </p>
        <v-card-actions class="sign-agreements__actions">
          <common-button
            class="sign-agreements__cancel-button"
            data-cy="closeDialogButton"
            @click="closeComplete"
          >
            {{ $t("close") }}
          </common-button>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";
import { PortfolioBase, PortfolioPdfType } from "@/clients";
import { BankIdAction } from "@/store/bankid/actions";
import { HIDE_SIGN_AGREEMENTS_DIALOG_KEY } from "@/config/general";
import { UserMutation } from "@/store/user/mutations";
import { UserAction } from "@/store/user/action-definitions";
import { BankIdMutation } from "@/store/bankid/mutations";
import { SignLocation } from "@/store/bankid/types";
import BankId from "./bank-id.vue";

enum SignDialogState {
  VIEW_AGREEMENTS,
  SIGNING,
  COMPLETE
}

export default defineComponent({
  emits: ["update:model-value", "agreements", "close"],
  components: {
    CommonButton,
    BankId
  },
  data: () => ({
    SignDialogState,
    state: SignDialogState.VIEW_AGREEMENTS,
    signSettingsFailed: false,
    hiddenDialog: false,
    SignLocation
  }),
  computed: {
    showDialog: {
      get(): boolean {
        const showDialog = this.errorAgreements.length > 0;
        return showDialog;
      },
      set(value: boolean) {
        this.$emit("update:model-value", value);
      }
    },
    missingOrErrorAgreements(): PortfolioPdfType[] {
      return this.$store.state.userStore.missingOrErrorAgreements || [];
    },
    errorAgreements(): PortfolioPdfType[] {
      return this.missingOrErrorAgreements.filter((a: PortfolioPdfType) => a.error);
    },
    portfolios(): PortfolioBase[] {
      return this.$store.state.navigationStore.portfolios;
    }
  },
  created() {
    this.hiddenDialog = sessionStorage.getItem(HIDE_SIGN_AGREEMENTS_DIALOG_KEY) === "true";
    if (this.$store.getters.isCompletingSignatureSuccess(SignLocation.ERROR_AGREEMENTS)) {
      this.$store.commit(BankIdMutation.completeRedirectSignature);
      this.$router.push({ query: {} });
      this.signComplete();
    } else if (this.$store.getters.isCompletingSignatureError(SignLocation.ERROR_AGREEMENTS)) {
      this.$store.commit(BankIdMutation.completeRedirectSignature);
      this.signFailed();
    }
  },
  methods: {
    showAgreements(): void {
      const agreementsToSign =
        this.errorAgreements.length > 0 ? this.errorAgreements : this.missingOrErrorAgreements;

      this.$emit("agreements", this.portfolios);
    },
    cancelSign(): void {
      this.$store.dispatch(BankIdAction.cancelSign);
    },
    cancel(): void {
      if (this.state === SignDialogState.COMPLETE) {
        this.closeComplete();
      } else {
        this.resetState();
        this.cancelSign();
        this.hiddenDialog = true;
        sessionStorage.setItem(HIDE_SIGN_AGREEMENTS_DIALOG_KEY, "true");
      }
    },
    async closeComplete() {
      this.$emit("close");
      this.resetState();
      this.$store.commit(UserMutation.setMissingOrErrorAgreements, []);
    },
    async sign() {
      this.state = SignDialogState.SIGNING;
      this.signSettingsFailed = false;
      await this.$store.dispatch(BankIdAction.sign);
    },
    async signComplete() {
      this.state = SignDialogState.COMPLETE;
      try {
        await this.$store.dispatch(UserAction.signAgreements, {
          agreements: this.$store.state.userStore.agreementsToSign
        });
      } catch (e) {
        this.signSettingsFailed = true;
        throw e;
      }
    },
    signCancel() {
      this.state = SignDialogState.VIEW_AGREEMENTS;
    },
    signFailed() {
      this.state = SignDialogState.COMPLETE;
      this.signSettingsFailed = true;
    },
    resetState() {
      this.state = SignDialogState.VIEW_AGREEMENTS;
    }
  }
});
</script>

<style lang="scss" scoped>
.sign-agreements {
  &__card {
    padding: 1.5rem 1rem 0rem 1rem;
    min-height: 22rem;
  }
  &__header {
    font-family: $heading-font-family;
    font-size: 1.125rem;
    font-weight: 500;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;
  }
  &__body {
    font-family: $heading-font-family;
    font-size: 1rem;
    font-weight: 300;
    color: $nearly-black;
    margin-bottom: 1rem;
    text-align: center;
  }
  &__agreements {
    cursor: pointer;
    margin: 5rem 0.5rem 0;
    @include small-up {
      margin: 5rem 1.5rem 0;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__agreements-icon {
    font-size: 0.95rem;
    margin-top: 0.25rem;
  }
  &__actions {
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    @include small-up {
      flex-wrap: unset;
    }
  }
  &__cancel-button {
    margin-top: 0.5rem;
    @include xsmall-up {
      margin-top: 0rem;
    }
  }
}
:deep(.sign-agreements) {
  max-width: 30rem;
}
.v-card-actions .v-btn ~ .v-btn:not(.v-btn-toggle .v-btn) {
  margin-left: 0;
}
</style>
