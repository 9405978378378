/* Allowed formats:
 * - "YYMMDDXXXX"
 * - "YYMMDD-XXXX"
 * - "YYMMDD+XXXX"
 * - "YYYYMMDDXXXX"
 * - "YYYYMMDD-XXXX"
 * - YYMMDDXXXX
 *
 */
export function birthDateFromPersonalIdentityNumber(
  personalIdentityNumber: string | undefined
): Date | undefined {
  if (!personalIdentityNumber) {
    return undefined;
  }

  // When you turn 100, the dash in the personal identity number is replaced by a plus sign.
  // Here we disallow people over 100 from registering because it simplifies the logic and
  // will probably never happen. They can contact us if they really want to register.
  if (personalIdentityNumber.includes("+")) {
    return undefined;
  }
  personalIdentityNumber = personalIdentityNumber.replace("-", "");
  if (personalIdentityNumber.length === 12) {
    personalIdentityNumber = personalIdentityNumber.slice(2);
  }
  if (personalIdentityNumber.length !== 10) {
    return undefined;
  }
  const parsedYear = parseInt(personalIdentityNumber.substring(0, 2), 10);
  // Assume YYMMDDXXXX
  const birthdate = new Date(
    parsedYear,
    parseInt(personalIdentityNumber.substring(2, 4), 10) - 1,
    parseInt(personalIdentityNumber.substring(4, 6), 10)
  );

  const currentYearInSecondMillennium = new Date().getFullYear() - 2000;

  const secondMillennnium = parsedYear >= 0 && parsedYear < currentYearInSecondMillennium;
  if (secondMillennnium) {
    birthdate.setFullYear(birthdate.getFullYear() + 100);
  }

  return birthdate;
}
