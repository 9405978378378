import { buildCommonModifications } from "./common-modifications";
import { SignupFlowModificationType } from "./signup-modification";
import type { SignupSectionPaths } from "@/types/signup";

export const additionalQuestions = {
  ...buildCommonModifications("create-portfolio"),
  [SignupFlowModificationType.UseSameAccount]: ["/create-portfolio/account/use-same-account"],
  [SignupFlowModificationType.Tink]: [
    "/create-portfolio/account/tink-info",
    "/create-portfolio/account/tink-verification",
  ],
  [SignupFlowModificationType.Savings]: [
    "/create-portfolio/account/first-deposit",
    "/create-portfolio/account/monthly-savings",
  ],
  [SignupFlowModificationType.NoIncome]: ["/create-portfolio/economy/income-warning"],
};

const welcomePaths = {
  header: "Starta portfölj",
  paths: ["/create-portfolio/welcome"],
};

const preferencesPathsNewPortfolio = {
  header: "Sparinställningar",
  paths: [
    "/create-portfolio/preferences/introduction",
    "/create-portfolio/preferences/focus",
    "/create-portfolio/preferences/market",
    "/create-portfolio/preferences/sectors",
    "/create-portfolio/preferences/sustainability",
    "/create-portfolio/preferences/sustainable-choice",
  ],
};

const preferencesPaths = {
  header: "Sparinställningar",
  paths: [
    "/create-portfolio/preferences/introduction",
    "/create-portfolio/preferences/focus",
    "/create-portfolio/preferences/market",
    "/create-portfolio/preferences/sectors",
    "/create-portfolio/preferences/sustainability",
    "/create-portfolio/preferences/sustainable-investment",
  ],
};

const esgPaths = {
  header: "Sparinställningar",
  paths: [
    "/create-portfolio/preferences/sustainability",
    "/create-portfolio/preferences/sustainable-investment",
  ],
};
const themePaths = {
  header: "Sparinställningar",
  paths: ["/create-portfolio/preferences/theme-info", "/create-portfolio/preferences/theme"],
};

const riskPaths = {
  header: "Risk och avkastning",
  paths: [
    "/create-portfolio/risk/introduction",
    "/create-portfolio/previous-experience",
    "/create-portfolio/previous-experience-answer",
    "/create-portfolio/portfolio-management",
    "/create-portfolio/risk/portfolio-selection",
    "/create-portfolio/risk/portfolio-confirmation",
  ],
};

const riskPathsTimeToWithdraw = {
  header: "Risk och avkastning",
  paths: [
    "/create-portfolio/risk/introduction",
    "/create-portfolio/economy/time-to-withdraw",
    "/create-portfolio/previous-experience",
    "/create-portfolio/previous-experience-answer",
    "/create-portfolio/portfolio-management",
    "/create-portfolio/risk/portfolio-selection",
    "/create-portfolio/risk/portfolio-confirmation",
  ],
};

const profilePaths = {
  header: "Din risknivå",
  paths: ["/create-portfolio/proposal", "/create-portfolio/risk-level"],
};

const economyPaths = {
  header: "Din ekonomi",
  paths: [
    "/create-portfolio/economy/introduction",
    "/create-portfolio/economy/time-to-withdraw",
    "/create-portfolio/economy/income",
    "/create-portfolio/economy/money-left",
    "/create-portfolio/economy/remainder",
    "/create-portfolio/economy/current-savings",
    "/create-portfolio/economy/bound-capital",
    "/create-portfolio/economy/loans",
  ],
};

const amlPaths = {
  header: "Kundkännedom",
  paths: [
    "/create-portfolio/aml/law-about",
    "/create-portfolio/aml/occupation",
    "/create-portfolio/aml/interval",
    "/create-portfolio/aml/amount",
    "/create-portfolio/aml/investment-background",
    "/create-portfolio/aml/purpose",
    "/create-portfolio/aml/withdrawal-interval",
    "/create-portfolio/aml/swedish-tax-residence",
    "/create-portfolio/aml/tax-liable-usa",
    "/create-portfolio/aml/tax-liable-other",
    "/create-portfolio/aml/citizenship",
    "/create-portfolio/aml/politically-exposed",
    "/create-portfolio/aml/politically-exposed-associated",
    "/create-portfolio/aml/your-money",
  ],
};

const bankSigningPaths = {
  header: "Starta sparandet",
  paths: [
    "/create-portfolio/account/connect-bank",
    "/create-portfolio/account/verify-personal-data",
    "/create-portfolio/account/summary",
    "/create-portfolio/account/communication",
    "/create-portfolio/account/summary-sign",
  ],
};

const bankSigningPathsUseSameAccount = {
  header: "Starta sparandet",
  paths: [
    "/create-portfolio/account/connect-bank",
    "/create-portfolio/account/summary",
    "/create-portfolio/account/summary-sign",
  ],
};

const congratulationsPaths = {
  header: "Klart!",
  paths: ["/create-portfolio/account/congratulations"],
};

export const firstPortfolioQuestionPaths: SignupSectionPaths[] = [
  welcomePaths,
  preferencesPaths,
  riskPaths,
  economyPaths,
  profilePaths,
  amlPaths,
  bankSigningPaths,
  congratulationsPaths,
];

export const newPortfolioQuestionPathsSameAccount: SignupSectionPaths[] = [
  welcomePaths,
  preferencesPathsNewPortfolio,
  riskPathsTimeToWithdraw,
  profilePaths,
  bankSigningPathsUseSameAccount,
  congratulationsPaths,
];

export const firstPortfolioTiedAgentQuestionPaths: SignupSectionPaths[] = [
  welcomePaths,
  economyPaths,
  riskPaths,
  profilePaths,
  esgPaths,
  themePaths,
  amlPaths,
  bankSigningPaths,
  congratulationsPaths,
];

export const newPortfolioTiedAgentQuestionPathsSameAccount: SignupSectionPaths[] = [
  welcomePaths,
  riskPaths,
  profilePaths,
  esgPaths,
  themePaths,
  bankSigningPathsUseSameAccount,
  congratulationsPaths,
];

export const CURRENT_SIGNUP_PATH_KEY_NEW = "current_signup_path_new";
