<template>
  <v-overlay
    contained
    :model-value="showOverlay"
    scrim="white"
    class="align-center justify-center"
  >
    <div class="overlay">
      <p
        class="overlay__heading"
        data-jest="heading"
      >
        {{ $t("portfolio.holdings.empty-holding-overlay.title") }}
      </p>
      <p
        class="overlay__description"
        data-jest="description"
      >
        {{ $t("portfolio.holdings.empty-holding-overlay.holding_description") }}
        <br v-if="$vuetify.display.smAndUp" />
        {{ $t("portfolio.holdings.empty-holding-overlay.holding_description2") }}
      </p>

      <common-button
        class="overlay__button"
        @click="() => (dismissedOverlay = true)"
      >
        {{ $t("ok") }}
      </common-button>
    </div>
  </v-overlay>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { Holding } from "@/clients";
import CommonButton from "@/components/button.vue";

export default defineComponent({
  components: { CommonButton },
  computed: {
    dismissedOverlay: {
      get(): boolean {
        return this.$store.state.portfolioStore.dismissedHoldingOverlay;
      },
      set(value: boolean) {
        this.$store.commit(PortfolioMutation.setHoldingOverlay, value);
      }
    },
    showOverlay(): boolean {
      return (
        !this.dismissedOverlay &&
        this.holdings !== undefined &&
        this.holdings.length === 0 &&
        !this.$store.state.portfolioStore.isLoadingHoldingsSummary
      );
    },
    holdings(): Holding[] | undefined {
      const { holdings } = this.$store.getters;
      return holdings;
    }
  }
});
</script>

<style lang="scss" scoped>
.overlay {
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  @include small-up {
    padding-top: 4rem;
  }
  &__heading {
    color: $soft-blue;
    font-size: 1.25rem;
    font-weight: 500;
  }
  &__description {
    text-align: center;
    color: $nearly-black;
    font-weight: 300;
  }
  &__button {
    margin: 2rem auto;
  }
}
:deep(.v-overlay__scrim) {
  opacity: 0.9 !important;
}
</style>
