<template>
  <div class="politically-exposed-associated">
    <single-choice-template
      :alternatives="alternatives"
      :information="true"
      @showInformation="() => (showDialog = true)"
      @click="select"
    >
      <question-text-section
        :heading="$t('create-portfolio.questions.aml.politically-exposed-associated.heading')"
        :body="$t('pepExplanation')"
        progress
      />
      <side-dialog
        v-model="showDialog"
        :title="$t('create-portfolio.questions.aml.politically-exposed-associated.title')"
      />
    </single-choice-template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupFlowModificationType } from "@/config/signup-modification";
import SideDialog from "@/components/politically-exposed-side-dialog.vue";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    SideDialog,
    QuestionTextSection
  },
  extends: Question,
  data: () => ({
    showDialog: false
  }),
  computed: {
    alternatives() {
      return [
        {
          text: this.$t(
            "create-portfolio.questions.aml.politically-exposed-associated.hasPepRelative"
          ),
          key: "hasPepRelative"
        },
        {
          text: this.$t(
            "create-portfolio.questions.aml.politically-exposed-associated.hasPepCoworker"
          ),
          key: "hasPepCoworker"
        },
        {
          text: this.$t("create-portfolio.questions.aml.politically-exposed-associated.noPep"),
          key: "noPep"
        }
      ];
    }
  },
  methods: {
    async select(key: string): Promise<void> {
      if (key === "noPep") {
        this.$store.commit(SignupMutation.resetAssociatedPepInfo);
      }
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.AssociatedPepRelative,
        include: key === "hasPepRelative"
      });
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.AssociatedPepCoworker,
        include: key === "hasPepCoworker"
      });
      this.$store.commit(SignupMutation.setAssociatedPepRelative, key === "hasPepRelative");
      this.$store.commit(SignupMutation.setAssociatedPepCoworker, key === "hasPepCoworker");
      if (key === "hasPepRelative") {
        this.$store.commit(SignupMutation.setAssociatedPepOrganizationNumber, undefined);
      }

      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss">
.politically-exposed-associated {
  height: 100%;
  width: 100%;
}
</style>
