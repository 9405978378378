<template>
  <v-overlay
    :model-value="modelValue"
    :persistent="persistent"
    contained
    scrim="white"
    class="align-center justify-center"
  >
    <loading-spinner data-jest="loading-spinner" />
  </v-overlay>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "./loading-spinner.vue";

export default defineComponent({
  components: {
    LoadingSpinner
  },
  props: {
    persistent: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
:deep(.v-overlay__scrim) {
  opacity: 0.9 !important;
}
</style>
