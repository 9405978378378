<template>
  <div class="politically-exposed">
    <single-choice-template
      :information="true"
      :alternatives="alternatives"
      :two-columns="true"
      @showInformation="() => (showDialog = true)"
      @click="select"
    >
      <question-text-section
        :heading="$t('create-portfolio.questions.aml.politically-exposed.heading')"
        :body="$t('pepExplanation')"
        progress
      />
      <side-dialog
        v-model="showDialog"
        :title="$t('components.politically-exposed-side-dialog.pepTitle')"
      />
    </single-choice-template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupFlowModificationType } from "@/config/signup-modification";
import SideDialog from "@/components/politically-exposed-side-dialog.vue";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    SideDialog,
    QuestionTextSection
  },
  extends: Question,
  data: () => ({
    showDialog: false
  }),
  computed: {
    alternatives(): any {
      return [
        {
          text: this.$t("yes"),
          key: "yes",
          secondary: true
        },
        {
          text: this.$t("no"),
          key: "no"
        }
      ];
    }
  },
  methods: {
    async select(key: string): Promise<void> {
      const isPep = key === "yes";
      this.$store.commit(SignupMutation.setPep, isPep);
      if (!isPep) {
        this.$store.commit(SignupMutation.resetPepInfo);
      }
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.Pep,
        include: isPep
      });
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss">
.politically-exposed {
  height: 100%;
  width: 100%;
}
</style>
