<template>
  <div class="time-to-withdraw">
    <time-to-withdraw-header
      :title="$t('portfolio-settings.strategy.time-to-withdraw.header')"
      :icon="['fal', 'business-time']"
    >
      <template #action>
        <edit-button
          :disabled="disabled"
          :edit="edit"
          @click="toggleEdit"
        />
      </template>
    </time-to-withdraw-header>
    <v-select
      v-model="editedTimeToWithdraw"
      variant="underlined"
      :items="timeToWithdrawAlternatives"
      :class="[
        'time-to-withdraw__selector',
        {
          'time-to-withdraw__selector--disabled': !edit
        }
      ]"
      :disabled="!edit"
      :menu-props="{
        contentClass: 'time-to-withdraw__selector-menu',
        maxHeight: 'auto'
      }"
      menu-icon="fas fa-chevron-down"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import EditButton from "@/components/edit-button.vue";
import { TimeToWithdraw, Strategy } from "@/clients";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import TimeToWithdrawHeader from "../header.vue";

export default defineComponent({
  emits: ["save"],
  components: { TimeToWithdrawHeader, EditButton },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    edit: false
  }),
  computed: {
    editedStrategy(): Strategy | undefined {
      return this.$store.state.portfolioStore.editedStrategy;
    },
    editedTimeToWithdraw: {
      get(): TimeToWithdraw | undefined {
        const { editedStrategy } = this.$store.state.portfolioStore;
        if (!editedStrategy) {
          return undefined;
        }
        return editedStrategy.timeToWithdraw;
      },
      set(timeToWithdraw: TimeToWithdraw) {
        this.$store.commit(PortfolioMutation.editTimeToWithdraw, timeToWithdraw);
      }
    },
    timeToWithdrawAlternatives(): any[] {
      return [
        TimeToWithdraw.OneToFiveYears,
        TimeToWithdraw.FiveToTenYears,
        TimeToWithdraw.MoreThanTenYears
      ].map((x: TimeToWithdraw) => ({
        title: this.$t(`timeToWithdrawOptions.${TimeToWithdraw[x]}`),
        value: x, // Since we removed index 0 (LessThanOneYear) from the list we need to +1 to the indices
        key: x
      }));
    }
  },
  methods: {
    async toggleEdit() {
      if (this.edit) {
        if (
          this.editedTimeToWithdraw !== this.$store.state.portfolioStore.strategy.timeToWithdraw
        ) {
          this.$emit("save");
        }
        this.edit = false;
      } else {
        this.$store.commit(PortfolioMutation.increaseUnsavedEdits);
        this.edit = true;
      }
    },
    reset() {
      const { strategy } = this.$store.state.portfolioStore;
      this.$store.commit(PortfolioMutation.editTimeToWithdraw, strategy.timeToWithdraw);
    }
  }
});
</script>
<style
  scoped
  lang="scss"
>
.time-to-withdraw {
  padding: 1.5rem 1.5rem 1.625rem 1.5rem;

  &__selector {
    font-size: 1.1rem;
    font-weight: 300;
    height: 2rem;
    max-width: 19.25rem;
    font-family: $heading-font-family;

    &--disabled {
      :deep(.v-field__append-inner) {
        opacity: 0;
      }

      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }

      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }

    :deep(.v-icon.v-icon) {
      font-size: 1rem;
    }

    &-menu {
      border-radius: 0.5rem !important;
      box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, 0.14) !important;
      font-family: $heading-font-family;
    }
  }
}
</style>
