import { paiFromState } from "./pai";
import type { SignupState } from "@/store/signup/types";
import type {
  CreatePersonLegalEntity,
  Market,
  StrategyFocus,
  TimeToWithdraw,
} from "@/clients";
import {
  CreateApplicationUser,
  CreateIskFirst,
  CreatePortfolio,
  CreatePortfolioSettings,
  CreateStrategy,
  CreateUserAffiliateInfo,
  FirstPortfolioRequestBase,
  RegisterUserParametersOld,
  Roles,
  TiedAgentPortfolioTheme,
} from "@/clients";
import type { RiskWillingness } from "@/types/signup";

export function getFirstPortfolioRequestBase(state: SignupState, getters: any) {
  return new FirstPortfolioRequestBase({
    monthlySalary: state.monthlyIncome,
    monthlyRemaining: state.monthlyRemainder,
    totalSavings: state.totalSavings,
    boundCapital: state.boundCapital,
    totalLoans: state.totalLoans,
    chosenRiskWillingness: state.riskWillingness as RiskWillingness,
    knowYourCustomerAnswers: getters.signupLegalEntity.knowYourCustomerAnswers,
    excludeNegativeSustainabilityImpact: state.sustainabilityImpact
      ?.excludeNegativeImpact as boolean,
    minimizeNegativeSustainabilityImpact: state.sustainabilityImpact
      ?.minimizeNegativeImpact as boolean,
    negativeImpactChanged: state.sustainabilityImpact?.minimizeNegativeImpactChanged as boolean,
    wantsPais: state.takeSustainabilityIntoAccount as boolean,
    excludeWithDisclosureRegulation: state.sustainabilityImpact?.disclosureRegulation,
    excludeWithTaxonomyRegulation: state.sustainabilityImpact?.taxonomyRegulation,
    takeSustainabilityImpactIntoAccount: undefined,
  });
}

export function getRegisterUserParameters(
  state: SignupState,
  getters: any,
  legalEntity?: CreatePersonLegalEntity,
) {
  const legalEntityToUse = legalEntity !== undefined ? legalEntity : getters.signupLegalEntity;
  const { chosenRiskLevel } = getters;

  const applicationUser = new CreateApplicationUser({
    legalEntity: legalEntityToUse,
    role: Roles.User,
    highExpenses: state.highExpenses as boolean,
    acceptedPersonalDataPolicy: state.acceptedPersonalDataPolicy as boolean,
  });

  const strategy = new CreateStrategy({
    chosenRiskLevel,
    recommendedRiskLevel: state.calculatedRiskLevel as number,
    defenseDiscarded: !state.includedSectors.weaponsAndDefense,
    alcoholTobaccoDiscarded: !state.includedSectors.alcoholTobacco,
    gamblingDiscarded: !state.includedSectors.casinosAndGambling,
    fossilFuelsDiscarded: !state.includedSectors.fossilFuel,
    focus: state.focus as StrategyFocus,
    marketFocus: state.marketFocus as Market,
    timeToWithdraw: state.timeToWithdraw as TimeToWithdraw,
    esgBiodiversityFilter: !!state.esgFilter?.esgBiodiversityFilter,
    esgEmissionsFilter: !!state.esgFilter?.esgEmissionsFilter,
    esgGovernanceFilter: !!state.esgFilter?.esgGovernanceFilter,
    esgHumanRightsFilter: !!state.esgFilter?.esgHumanRightsFilter,
    esgResourcesFilter: !!state.esgFilter?.esgResourcesFilter,
    tiedAgentPortfolioThemeType: undefined,
    esgPais: paiFromState(state.esgPais),
  });

  const portfolioSettings = new CreatePortfolioSettings({
    initialDeposit: state.firstDeposit as number,
    monthlySaving: state.monthlySavingsAmount as number,
    accountName: state.bankAccountName !== undefined ? state.bankAccountName : "Mitt konto",
    accountNumber: state.bankAccountNumber,
    clearingNumber: state.clearingNumber,
    bankName: state.bankName,
  });

  const portfolio = new CreatePortfolio({
    name: "Min portfölj",
    strategy,
    portfolioSettings,
  });

  const result = new RegisterUserParametersOld({
    user: applicationUser,
    portfolio,
    referralCode: state.referralCode,
    affiliateInfo: new CreateUserAffiliateInfo({
      affiliateCode: state.affiliateReferralCode,
      affiliateName: state.affiliateReferralName,
    }),
  });

  return result;
}

export function getCreateFirstPortfolioRequest(state: SignupState, getters: any): CreateIskFirst {
  const { chosenRiskLevel } = getters;

  const strategy = new CreateStrategy({
    chosenRiskLevel,
    recommendedRiskLevel: state.calculatedRiskLevel as number,
    defenseDiscarded: !state.includedSectors.weaponsAndDefense,
    alcoholTobaccoDiscarded: !state.includedSectors.alcoholTobacco,
    gamblingDiscarded: !state.includedSectors.casinosAndGambling,
    fossilFuelsDiscarded: !state.includedSectors.fossilFuel,
    focus: state.focus as StrategyFocus,
    marketFocus: state.marketFocus as Market,
    timeToWithdraw: state.timeToWithdraw as TimeToWithdraw,
    esgBiodiversityFilter: !!state.esgFilter?.esgBiodiversityFilter,
    esgEmissionsFilter: !!state.esgFilter?.esgEmissionsFilter,
    esgGovernanceFilter: !!state.esgFilter?.esgGovernanceFilter,
    esgHumanRightsFilter: !!state.esgFilter?.esgHumanRightsFilter,
    esgResourcesFilter: !!state.esgFilter?.esgResourcesFilter,
    tiedAgentPortfolioThemeType: state.portfolioTheme?.tiedAgentPortfolioThemeType,
    esgPais: paiFromState(state.esgPais),
  });

  const portfolioSettings = new CreatePortfolioSettings({
    initialDeposit: state.firstDeposit as number,
    monthlySaving: state.monthlySavingsAmount as number,
    accountName: state.bankAccountName !== undefined ? state.bankAccountName : "Mitt konto",
    accountNumber: state.bankAccountNumber,
    clearingNumber: state.clearingNumber,
    bankName: state.bankName,
  });

  const portfolio = new CreatePortfolio({
    name: "Min portfölj",
    strategy,
    portfolioSettings,
  });

  const result = new CreateIskFirst({
    firstPortfolioRequestBase: getFirstPortfolioRequestBase(state, getters),
    portfolio,
    affiliateInfo: undefined,
    // TODO: Move affiliate campaign setting to portfolio creation, this is now entered in first signup
    // affiliateInfo: new CreateUserAffiliateInfo({
    //   affiliateCode: getters.signupLegalEntity.affiliateReferralCode,
    //   affiliateName: state.affiliateReferralName
    // })
  });

  return result;
}
