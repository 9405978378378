<template>
  <div>
    <v-tabs
      v-model="tab"
      selected-class="item--active"
      class="item__tabs"
      :color="softBlue"
      bg-color="transparent"
      slider-size="4"
      :height="$vuetify.display.mdAndUp ? '4.5rem' : '3.75rem'"
      data-jest="tabs"
      fixed-width
    >
      <!-- When changing portfolios there is something very strange happening with ordering,
      causing vuetify's v-tab to assign the wrong index to the wrong tab, thereby leading to us
      showing the wrong chart for some tabs. This is solved by using the index as key.
      See explanation of what happened: https://trello.com/c/QcfJEhje -->
      <v-tab
        v-for="(item, index) in rangeSelectionItems"
        :key="index"
      >
        <div class="item">
          <h3
            :data-label="item.label"
            class="item__label"
          >
            {{ item.label }}
          </h3>
          <p
            :data-jest="`value-${index}`"
            :data-label="item.value"
            class="item__value"
          >
            {{ item.value }}
          </p>
        </div>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ChartRange } from "@/enums/overview";
import { DevelopmentPercentageMap, RangeSelectionItem } from "@/types/portfolio";
import { softBlue } from "@/styles/colors";

type DevelopmentPercentagesStringMap = Map<ChartRange, string>;

export default defineComponent({
  emits: ["update:model-value"],
  props: {
    developmentPercentages: {
      type: Map,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    availableRanges: {
      type: Array as () => string[],
      required: true
    }
  },
  data: (): any => ({
    tab: 0,
    softBlue
  }),
  computed: {
    developmentPercentagesStrings(): DevelopmentPercentagesStringMap {
      const developmentPercentages = this.developmentPercentages as DevelopmentPercentageMap;
      const percentages = new Map() as DevelopmentPercentagesStringMap;
      developmentPercentages.forEach((value: number | undefined, key: ChartRange) => {
        if (value === undefined) {
          percentages.set(key, "-");
        } else {
          const sign = value >= 0 ? "+" : "−";
          const absPercentage = Math.abs(value);
          const formattedPercentage = absPercentage.toLocaleString("sv-SE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          percentages.set(key, `${sign} ${formattedPercentage} %`);
        }
      });
      return percentages;
    },
    rangeSelectionItems(): RangeSelectionItem[] {
      if (this.availableRanges && this.developmentPercentagesStrings) {
        const items: RangeSelectionItem[] = [
          {
            label: this.$t("portfolio.overview.development.range-selector.thisYear"),
            key: ChartRange.ThisYear,
            value: this.developmentPercentagesStrings.get(ChartRange.ThisYear)
          },
          {
            label: this.$t("portfolio.overview.development.range-selector.threeMonths"),
            key: ChartRange.ThreeMonths,
            value: this.developmentPercentagesStrings.get(ChartRange.ThreeMonths)
          },
          {
            label: this.$t("portfolio.overview.development.range-selector.sixMonths"),
            key: ChartRange.SixMonths,
            value: this.developmentPercentagesStrings.get(ChartRange.SixMonths)
          },
          {
            label: this.$t("portfolio.overview.development.range-selector.oneYear"),
            key: ChartRange.OneYear,
            value: this.developmentPercentagesStrings.get(ChartRange.OneYear)
          },
          {
            label: this.$t("portfolio.overview.development.range-selector.threeYears"),
            key: ChartRange.ThreeYears,
            value: this.developmentPercentagesStrings.get(ChartRange.ThreeYears)
          },
          {
            label: this.$t("portfolio.overview.development.range-selector.fiveYears"),
            key: ChartRange.FiveYears,
            value: this.developmentPercentagesStrings.get(ChartRange.FiveYears)
          },
          {
            label: this.$t("portfolio.overview.development.range-selector.fromStart"),
            key: ChartRange.FromStart,
            value: this.developmentPercentagesStrings.get(ChartRange.FromStart)
          }
        ];

        return items.filter((x: RangeSelectionItem) => this.availableRanges.includes(x.key));
      }
      return [];
    }
  },
  watch: {
    tab: {
      handler(newVal: number) {
        this.$emit("update:model-value", this.rangeSelectionItems[newVal].key);
      }
    },
    rangeSelectionItems: {
      immediate: true,
      handler(newVal: RangeSelectionItem[]) {
        newVal.forEach((item: RangeSelectionItem, index: number) => {
          if (item.key === this.modelValue) {
            this.tab = index;
          }
        });
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.item {
  text-transform: none;
  letter-spacing: normal;
  height: 100;

  &__tabs {
    padding: 0 1.5rem;
  }

  &__label {
    font-size: 0.875rem;
    font-weight: 300;
    color: rgba($nearly-black, 0.5);
    margin-bottom: 0.5rem;

    @include small-up {
      font-size: 1rem;
      margin-bottom: 0.125rem;
    }

    @include small-down {
      font-size: 0.85rem;
    }

    // To avoid text width change on font-weight change.
    &::before {
      display: block;
      content: attr(data-label);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  &__value {
    font-size: 0.875rem;
    font-size: 0.875rem;
    font-weight: 300;
    color: rgba($nearly-black, 0.5);
    margin: 0;

    @include small-up {
      font-size: 1rem;
    }

    // To avoid text width change on font-weight change.
    &::before {
      display: block;
      content: attr(data-label);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }

  // Since the active class is set by Vuetify we can't use BEM.
  &--active {
    .item__label {
      font-weight: 500;
      color: $nearly-black;
    }

    .item__value {
      font-weight: 600;
      color: $grayish-blue;
    }
  }
}

:deep(.v-tab) {
  align-items: flex-start;
  min-width: 0;
  width: 100%;
  flex: 1;
  padding: 0;
}
</style>
