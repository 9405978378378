<template>
  <single-choice-template
    class="tax-liable-usa"
    :alternatives="alternatives"
    @click="select"
  >
    <question-text-section
      :heading="$t('common-signup.questions.aml.tax-liable-usa.heading')"
      :body="$t('common-signup.questions.aml.tax-liable-usa.body')"
      progress
    />
    <p class="tax-liable-usa__bullets">
      <span> {{ $t("common-signup.questions.aml.tax-liable-usa.bullet1") }} </span> <br />
      <span> {{ $t("common-signup.questions.aml.tax-liable-usa.bullet2") }} </span> <br />
      <span> {{ $t("common-signup.questions.aml.tax-liable-usa.bullet3") }} </span> <br />
    </p>
  </single-choice-template>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import { SignupMutation } from "@/store/signup/mutations";

import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import InformationLink from "@/views/signup/information-link.vue";
import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";

export default defineComponent({
  emits: ["next"],
  components: {
    SingleChoiceTemplate,
    InformationLink,
    SideDialog,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    alternatives() {
      return [
        {
          text: this.$t("yes"),
          key: "isTaxLiable",
          secondary: true
        },
        {
          text: this.$t("no"),
          key: "notTaxLiable"
        }
      ];
    }
  },
  methods: {
    select(key: string): void {
      this.$emit("next", key);
      this.$store.commit(SignupMutation.setTaxableUsa, key === "isTaxLiable");
    }
  }
});
</script>

<style lang="scss">
.tax-liable-usa {
  height: 100%;
  width: 100%;
  &__bullets {
    text-align: center;
    margin: 0 auto;
    max-width: 20.25rem;
    margin: 0.75rem 0 0 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
  }
}
</style>
