import type { GetterTree } from "vuex";
import type { RootState } from "../../types";
import type { UserState } from "./types";
import type { KnowYourCustomerAnswers, LegalEntity, Message } from "@/clients";
import { Roles } from "@/clients";
import type { Economy, TokenUserInfo } from "@/types/user";
import { parseJwt } from "@/utils/jwt";

export const getters: GetterTree<UserState, RootState> = {
  fullName(state, getters): string | undefined {
    if (state.currentLegalEntity) {
      if (getters.isCompany) {
        return `${state.currentLegalEntity.firstName}`;
      }
      return `${state.currentLegalEntity.firstName} ${state.currentLegalEntity.lastName}`;
    }
    return undefined;
  },
  firstName(state): string | undefined {
    if (state.currentLegalEntity) {
      return state.currentLegalEntity.firstName;
    }
    return undefined;
  },
  personalIdentityNumber(state): string | undefined {
    if (state.currentUser) {
      return state.currentUser.personalIdentityNumber;
    }
    return undefined;
  },
  legalEntity(state): LegalEntity | undefined {
    if (state.currentLegalEntity) {
      return state.currentLegalEntity;
    }
    return undefined;
  },
  legalEntities(state): LegalEntity[] | undefined {
    if (state.currentUser) {
      return state.currentUser.legalEntityUsers.map(x => x.legalEntity as LegalEntity);
    }
    return undefined;
  },
  kyc(state): KnowYourCustomerAnswers | undefined {
    const { currentLegalEntity } = state;
    if (currentLegalEntity) {
      return currentLegalEntity.knowYourCustomerAnswers;
    }
    return undefined;
  },
  hasUnsavedProfileEdits(state): boolean {
    return state.unsavedProfileEdits > 0;
  },
  errorLoadingUser(state): boolean {
    return state.errorStates.loadUserError;
  },
  errorLoadingNotificationSettings(state): boolean {
    return state.errorStates.loadNotificationSettingsError;
  },
  isLoggedIn: state => () => {
    const { token } = state;
    if (token) {
      // We are not veryifing the signature here as we dont want to store the key in the client.
      // That is the API's responsibility. We only check if the token has expired or not
      const decoded: string | { [key: string]: any } | null = parseJwt(token);
      if (decoded && typeof decoded === "object" && Object.keys(decoded).length > 0) {
        const { exp, role } = decoded;
        // Date.now returns milliseconds and jwt object returns seconds hence * 1000
        if (exp && Date.now() < exp * 1000 && role && role !== "CompanySignup") {
          return true;
        }
      }
    }

    return false;
  },
  tokenUserInfo: state => (): TokenUserInfo => {
    const { token } = state;
    if (token) {
      const decoded: string | { [key: string]: any } | null = parseJwt(token);
      if (decoded && typeof decoded === "object" && decoded.sigmastocksLegalEntitiesPortfolioIds) {
        return {
          testNewFeatures: decoded.sigmastocksTestNewFeatures === "true",
          legalEntitiesPortfolioIds: JSON.parse(decoded.sigmastocksLegalEntitiesPortfolioIds),
        };
      }
    }

    return { testNewFeatures: false, legalEntitiesPortfolioIds: [] };
  },
  isAdmin(state): boolean | false {
    if (state.currentUser) {
      return state.currentUser.role === Roles.Admin;
    }
    return false;
  },
  errorLoadingKyc(state): boolean {
    return state.errorStates.loadKycError;
  },
  messages(state): Message[] | undefined {
    const { messages } = state;
    return messages;
  },
  loadingMessages(state): boolean {
    const { loadingMessages } = state;
    return loadingMessages;
  },
  editedEconomy(state): Economy | undefined {
    const { editedLegalEntity } = state;
    if (!editedLegalEntity) {
      return undefined;
    }
    return {
      monthlySalary: editedLegalEntity.monthlySalary,
      monthlyRemaining: editedLegalEntity.monthlyRemaining,
      totalSavings: editedLegalEntity.totalSavings,
      boundCapital: editedLegalEntity.boundCapital,
      totalLoans: editedLegalEntity.totalLoans,
    };
  },
  unreadMessagesCount(state): number {
    if (state.messages) {
      return state.messages.filter(m => m.isRead === false).length;
    }
    return 0;
  },
  isCompany(state): boolean | undefined {
    if (state.currentLegalEntity) {
      return state.currentLegalEntity.isNaturalPerson === false;
    }
    return undefined;
  },
  isTestUser(state): boolean {
    if (!state.currentUser) {
      return false;
    }
    return state.currentUser.testNewFeatures;
  },
  answeredNewEsg(state, _, rootState): boolean {
    const hasPortfolios = rootState.navigationStore.portfolios
      && rootState.navigationStore.portfolios.length > 0;
    const answeredNewEsg = state.currentLegalEntity?.wantsPais === true
      || state.currentLegalEntity?.wantsPais === false;
    return !hasPortfolios || answeredNewEsg;
  },
};
