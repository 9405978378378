<template>
  <div class="no-bank-account">
    <div class="no-bank-account__wrapper">
      <h2 class="no-bank-account__title">
        {{ $t("missingBankAccount") }}
      </h2>
      <p>{{ $t("portfolio.overview.withdraw.no-bank-account.body1") }}</p>
      <p>{{ $t("portfolio.overview.withdraw.no-bank-account.body2") }}</p>
    </div>
    <common-button @click="openConnectBankSideDialog">
      {{ $t("connectBankAccount") }}
    </common-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";

export default defineComponent({
  emits: ["done"],
  components: {
    CommonButton
  },
  methods: {
    openConnectBankSideDialog() {
      this.$store.commit(PortfolioMutation.setShowConnectBankSideDialog, true);
      this.$emit("done");
    }
  }
});
</script>

<style lang="scss" scoped>
.no-bank-account {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
}
</style>
