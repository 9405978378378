import { ActionTree } from "vuex";

import { Router } from "vue-router";
import { trackEvent, trackEventGtm } from "@/clients/segment";
import { SignupFlowModificationType } from "@/config/signup-modification";
import type { PortfolioBase } from "@/clients";

import { SignupFlowModification, SignupNavigationState, SignupQuestionPaths } from "./types";
import { RootState } from "../../types";
import { getSignupNavigationInitialState, SignupNavigationMutation } from "./mutations";

export const SignupNavigationAction = {
  goToNextStep: "goToNextStep",
  goToPreviousStep: "goToPreviousStep",
  goToQuestion: "goToQuestion",
  modifySignupFlow: "modifySignupFlow",
  initializeSignup: "initializeSignup",
  resetCurrentPath: "resetCurrentPath"
};

function buildQueryParams(questionPath: string, portfolios?: PortfolioBase[]): { [key: string]: string } | undefined {
  // Creates a query object with parameter to determine if the user is creating their first portfolio.
  // This could potentially be done in a simpler way, but it may require more significant changes.
  return questionPath.includes("/create-portfolio/welcome") && (portfolios ?? []).length === 0
    ? { first: "true" }
    : undefined;
}

export const actions: ActionTree<SignupNavigationState, RootState> = {
  [SignupNavigationAction.resetCurrentPath]({ state, commit }) {
    if (state.currentPathKey) {
      sessionStorage.removeItem(state.currentPathKey);
    }
  },
  [SignupNavigationAction.initializeSignup](
    { commit },
    payload: {
      questionPaths: SignupQuestionPaths;
      currentPathKey: string;
      additionalQuestionPaths: any;
      progressHeadingKey: string;
    }
  ) {
    commit(SignupNavigationMutation.setNavigationState, getSignupNavigationInitialState());
    commit(SignupNavigationMutation.setAdditionalQuestionPaths, {
      additionalQuestionPaths: payload.additionalQuestionPaths
    });
    commit(SignupNavigationMutation.setQuestionPaths, { questionPaths: payload.questionPaths });
    commit(SignupNavigationMutation.setCurrentPathKey, payload.currentPathKey);
    commit(SignupNavigationMutation.setQuestionProgress, { partNumber: 1, questionNumber: 0 });
  },
  [SignupNavigationAction.goToNextStep]({ state, commit, getters, rootState }, router: Router) {
    const { questionProgress } = state;
    const signupQuestionPaths = state.signupPaths.questionPaths;
    const questionPath =
      signupQuestionPaths[getters.nextQuestion.partNumber - 1][
        getters.nextQuestion.questionNumber - 1
      ];

    const { navigationStore, signupStore, userStore } = rootState as RootState;

    if (questionProgress && questionPath) {
      const eventPayload = {
        part_completed: questionProgress.partNumber,
        question_completed: questionProgress.questionNumber,
        affiliate_code: signupStore.affiliateReferralCode,
      };

      if (questionPath.includes("/signup")) {
        trackEventGtm("signup_flow_simple", eventPayload);
      } else if (questionPath.includes("/pension")) {
        trackEventGtm("signup_flow_pension", eventPayload);
      } else if (questionPath.includes("/create-portfolio")) {
        trackEventGtm("signup_flow_savings", eventPayload);
      }

      trackEvent("progressed_signup_question", {
        part: getters.nextQuestion.partNumber,
        question: getters.nextQuestion.questionNumber,
        path: questionPath,
      });
    }

    commit(SignupNavigationMutation.setQuestionProgress, getters.nextQuestion);

    // Add special query param for creating first portfolio.
    const query = buildQueryParams(questionPath, navigationStore.portfolios);

    router.push({ path: `/${userStore.locale}${questionPath}`, query });
  },

  [SignupNavigationAction.goToPreviousStep]({ state, commit, rootState, getters }, router: Router) {
    const { questionProgress } = state;
    if (
      questionProgress &&
      questionProgress.partNumber === 1 &&
      questionProgress.questionNumber === 1
    ) {
      // Hack to skip tink section if using an existing account in new-portfolio
    } else {
      const { navigationStore, userStore } = rootState as RootState;
      const signupQuestionPaths = state.signupPaths.questionPaths;
      const questionPath =
        signupQuestionPaths[getters.previousQuestion.partNumber - 1][
          getters.previousQuestion.questionNumber - 1
        ];
      commit(SignupNavigationMutation.setQuestionProgress, getters.previousQuestion);

      // Add special query param for creating first portfolio.
      const query = buildQueryParams(questionPath, navigationStore.portfolios);

      router.push({ path: `/${userStore.locale}${questionPath}`, query });
    }
  },

  // Modify flow of signup questions with SignupFlowModificationType. The parameter include sets whether to add or remove the modification.
  // It's possible to add a modification multiple times if you set the index parameter. Every time you add an instance of the same modification it needs to have a unique value for index.
  // For simple cases of adding a modification once don't use the index parameter
  [SignupNavigationAction.modifySignupFlow](
    { state, commit },
    payload: {
      modification: SignupFlowModificationType;
      include: boolean;
      index: string | undefined;
    }
  ) {
    if (state.questionProgress && state.questionProgress.partNumber) {
      const { questionPaths } = state.signupPaths;
      const questionPart = questionPaths[state.questionProgress.partNumber - 1];
      const alreadyAppliedModification = state.signupFlowModifications.find(
        (x: SignupFlowModification) =>
          x.modification === payload.modification &&
          (payload.index === undefined || x.index === payload.index)
        // || (x.nestedModifications && x.nestedModifications.some(nest => nest.modification === payload.modification)
      );
      const currentPartNumber = state.questionProgress.partNumber;
      const currentPath =
        questionPaths[currentPartNumber - 1][
          state.questionProgress.questionNumber - (payload.include ? 1 : 0)
        ];
      const currentModification = state.signupFlowModifications.find((x: SignupFlowModification) =>
        x.paths.includes(currentPath)
      );
      if (payload.include) {
        if (!alreadyAppliedModification) {
          if (!state.additionalQuestions) {
            throw new Error("additional question paths not initialized");
          }
          const start = questionPart.slice(0, state.questionProgress.questionNumber);
          const end = questionPart.slice(state.questionProgress.questionNumber);

          const modified = [
            ...start,
            ...state.additionalQuestions.additionalQuestionPaths[payload.modification],
            ...end
          ];

          commit(SignupNavigationMutation.setQuestionPart, {
            index: state.questionProgress.partNumber - 1,
            paths: modified
          });
          const addedModification = {
            modification: payload.modification,
            paths: state.additionalQuestions.additionalQuestionPaths[payload.modification],
            index: payload.index
          };
          commit(SignupNavigationMutation.addSignupFlowModification, addedModification);
          if (currentModification) {
            commit(SignupNavigationMutation.setNestedSignupFlowModification, {
              modification: currentModification.modification,
              nestedModification: addedModification
            });
          }
        }
      } else if (alreadyAppliedModification) {
        let modificationsToRemove = [alreadyAppliedModification];
        const startsAt = questionPart.indexOf(alreadyAppliedModification.paths[0]);

        let mod = alreadyAppliedModification;
        while (mod && mod.nestedModifications) {
          modificationsToRemove = modificationsToRemove.concat(mod.nestedModifications);
          // TODO this is broken, check all nested
          // eslint-disable-next-line prefer-destructuring
          mod = mod.nestedModifications[0];
        }
        const start = questionPart.slice(0, startsAt);
        const end = questionPart.slice(
          startsAt + modificationsToRemove.reduce((acc, curr) => acc + curr.paths.length, 0)
        );
        const modified = [...start, ...end];
        commit(SignupNavigationMutation.setQuestionPart, {
          index: state.questionProgress.partNumber - 1,
          paths: modified
        });
        modificationsToRemove.forEach((m) =>
          commit(SignupNavigationMutation.removeSignupFlowModification, {
            type: m.modification,
            index: payload.index
          })
        );
      }
    }
  }
};
