<template>
  <div class="withdrawal-interval">
    <single-choice-template
      :alternatives="alternatives"
      @click="select"
    >
      <question-text-section
        :heading="$t('pepRelation')"
        progress
      />
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import { SingleChoice } from "@/types/signup";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    alternatives(): SingleChoice[] {
      return [
        {
          text: this.$t(
            "create-portfolio.questions.aml.politically-exposed-associated-relationship-relative.married"
          ),
          key: "married"
        },
        {
          text: this.$t(
            "create-portfolio.questions.aml.politically-exposed-associated-relationship-relative.partner"
          ),
          key: "partner"
        },
        {
          text: this.$t(
            "create-portfolio.questions.aml.politically-exposed-associated-relationship-relative.parent"
          ),
          key: "parent"
        },
        {
          text: this.$t(
            "create-portfolio.questions.aml.politically-exposed-associated-relationship-relative.child"
          ),
          key: "child"
        },
        {
          text: this.$t(
            "create-portfolio.questions.aml.politically-exposed-associated-relationship-relative.childsPartner"
          ),
          key: "childsPartner"
        }
      ];
    }
  },
  methods: {
    select(key: any): void {
      this.$store.commit(SignupMutation.setAssociatedPepRelationship, key);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.withdrawal-interval {
  height: 100%;
}
</style>
