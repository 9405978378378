import { actions } from "./actions";
import { mutations, getPensionSignupInitialState } from "./mutations";
import { getters } from "./getters";

const initialState = getPensionSignupInitialState();

export default {
  state: initialState,
  mutations,
  actions,
  getters
};
