export function getFlagUrl(countryFullName: string): string | undefined {
  if (countryFullName.length < 4) {
    return undefined;
  }
  countryFullName = countryFullName.toLowerCase();
  if (countryFullName.includes("united kingdom")) {
    return new URL("/assets/images/flags/flag-greatbritain.svg", import.meta.url).href;
  } else if (countryFullName.includes("united states")) {
    return new URL("/assets/images/flags/flag-us.svg", import.meta.url).href;
  }
  let url: string | undefined = undefined;
  try {
    url = new URL(`/assets/images/flags/flag-${countryFullName}.svg`, import.meta.url).href;
  } catch (error) {
    console.error("Failed to get flag for country " + countryFullName);
  }
  return url;
}
