<template>
  <div class="detailed-holding">
    <div class="detailed-holding__title-description-wrapper">
      <div class="detailed-holding__header-container">
        <div class="detailed-holding__icon-wrapper">
          <img
            data-jest="icon"
            :src="iconUrl"
          />
        </div>
        <div
          class="detailed-holding__header-text-container"
          data-jest="text-container"
        >
          {{ selectedHolding.name }}
          <div
            class="detailed-holding__subheader-text"
            data-jest="subheader-text"
          >
            {{ formatSector(selectedHolding.instrumentType, selectedHolding.sector) }}
            {{ selectedHolding.subSector ? "|" : "" }}
            {{ formatSubSector(selectedHolding.subSector) }}
          </div>
          <br />
          <div
            v-if="selectedHolding.description"
            class="detailed-holding__subheader-text"
          >
            {{ selectedHolding.description }}
          </div>
          <!-- <div v-if="selectedHolding.descriptionWikiLink" class="detailed-holding__wiki-link">
            {{ $t("portfolio.holdings.detailed.detailed-holding.retrievedFrom") }}
            <a target="_blank" :href="selectedHolding.descriptionWikiLink">Wikipedia</a> under
            <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/legalcode"
              >CC-BY-SA</a
            >
          </div> -->
        </div>
      </div>
    </div>
    <error-loading
      v-if="loadingError"
      :body="$t('portfolio.holdings.detailed.detailed-holding.errorBody')"
      class="detailed-holding__error"
    />
    <div
      v-if="!loadingError"
      class="detailed-holding__holding-summary"
    >
      <div class="detailed-holding__holding-summary-row">
        <span> {{ $t("portfolio.holdings.detailed.detailed-holding.summary.amount") }}</span>
        <span class="detailed-holding__holding-summary-value">
          {{ roundedNumberFormat(selectedHolding.amount, $t("unit")) }}
        </span>
      </div>
      <div class="detailed-holding__holding-summary-row">
        <span> {{ $t("portfolio.holdings.detailed.detailed-holding.summary.value") }}</span>
        <span class="detailed-holding__holding-summary-value">
          {{ numberFormat(selectedHolding.marketValue, $t("currency")) }}
        </span>
      </div>
      <div class="detailed-holding__holding-summary-row">
        <span>
          {{ $t("portfolio.holdings.detailed.detailed-holding.summary.acqusitionPrice") }}</span
        >
        <span class="detailed-holding__holding-summary-value">
          {{ numberFormat(selectedHolding.acquisitionValuePerItem, $t("currency")) }}
        </span>
      </div>
      <div class="detailed-holding__holding-summary-row">
        <span> {{ $t("portfolio.holdings.detailed.detailed-holding.summary.price") }}</span>
        <span class="detailed-holding__holding-summary-value">
          {{ numberFormat(selectedHolding.marketValuePerItem, $t("currency")) }}
        </span>
      </div>
      <div class="detailed-holding__holding-summary-row">
        <span> {{ $t("performance") }}</span>
        <span class="detailed-holding__holding-summary-value">
          {{ fractionToPercentage(selectedHolding.developmentSinceAcquisition) }}
        </span>
      </div>
    </div>
    <div
      v-if="!loadingError && isShare"
      class="detailed-holding__key-ratio-wrapper"
    >
      {{ $t("portfolio.holdings.detailed.detailed-holding.keyRatio") }}
      <div
        v-for="{ key, data } in powerBars"
        :key="key"
      >
        <div
          v-for="{ text, dataLabel, relativeValue } in data"
          :key="text"
          class="detailed-holding__key-ratio-list-item"
        >
          <bar-chart-list-item
            :text="text"
            :data-label="dataLabel"
            :color="softBlue"
            :weighted-header="false"
            :relative-value="loading ? 0 : relativeValue"
            :loading="loading"
          />
        </div>
      </div>
    </div>
    <etf-static-information
      v-if="!isShare"
      :asset-name="selectedHolding.name"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { PortfolioAction } from "@/store/portfolio/actions";
import { DetailedHolding, InstrumentType, Sector } from "@/clients";
import { softBlue } from "@/styles/colors";
import ErrorLoading from "@/components/error-loading.vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import { numberFormat, roundedNumberFormat, fractionToPercentage } from "@/utils/holdings-format";
import BarChartListItem from "../../bar-chart-list-item.vue";
import EtfStaticInformation from "./etf-static-information.vue";
import imageUrl from "@/assets/images/logo.svg"; // => or relative path

type HoldingDetailKeys =
  | "bookToMarket"
  | "cashFlowToAsset"
  // | "currentMarketCap"
  | "debtToMarketCap"
  | "earningsToPrice"
  | "momentum"
  | "netProfitMargin"
  | "reciprocalPeg"
  | "dividendToPrice";

interface BarChartItem {
  text: string;
  dataLabel: string;
  relativeValue: number;
}

const keyRatioObject: any[] = [
  // {
  //   key: "currentMarketCap"
  //  },
  {
    key: "bookToMarket"
  },
  {
    key: "cashFlowToAsset"
  },
  {
    key: "debtToMarketCap"
  },
  {
    key: "earningsToPrice"
  },
  {
    key: "momentum"
  },
  {
    key: "netProfitMargin"
  },
  {
    key: "reciprocalPeg"
  },
  {
    key: "dividendToPrice"
  }
];

export default defineComponent({
  components: {
    BarChartListItem,
    ErrorLoading,
    EtfStaticInformation
  },
  props: {
    selectedHolding: {
      type: Object,
      default: undefined
    }
  },
  data: (): any => ({
    softBlue,
    esgFactors: ["governance", "humanRights", "resources", "biodiversity", "emissions"]
  }),
  computed: {
    ...mapGetters(["holdingDetails"]),
    iconUrl() {
      return new URL(`/assets/images/flags/${this.selectedHolding.icon}`, import.meta.url).href;
    },
    loading(): boolean {
      return this.$store.state.portfolioStore.isLoadingHoldingDetails;
    },
    details(): DetailedHolding | undefined {
      return this.holdingDetails(this.selectedHolding.asset);
    },
    loadingError(): boolean {
      return this.$store.state.portfolioStore.loadingHoldingDetailsError;
    },
    esgBars(): any {
      if (this.details) {
        return this.esgFactors.map((key: string) => ({
          text: this.$t(`portfolio.holdings.detailed.detailed-holding.esgFactors.${key}`),
          dataLabel: this.details[key] === null ? "Ingen data" : "",
          value: this.details[key] ?? 0
        }));
      }
      return this.esgFactors.map((key: string) => ({
        text: this.$t(`portfolio.holdings.detailed.detailed-holding.esgFactors.${key}`),
        dataLabel: "-",
        value: 0
      }));
    },
    powerBars(): any {
      if (this.details) {
        return [
          //   {
          //    key: "currency",
          //    data: this.keyRatioCurrencyValues
          //  },
          {
            key: "valueOnly",
            data: this.keyRatioOnlyValues
          },
          { key: "percent", data: this.keyRatioPercentValues }
        ];
      }
      return this.getUndefinedKeyRatios();
    },
    keyRatioCurrencyValues(): Array<BarChartItem> {
      const allowedKeys = ["currentMarketCap"];
      const formattedValues = this.getFormattedKeyRatios(
        allowedKeys as Array<HoldingDetailKeys>,
        this.$t("portfolio.holdings.detailed.detailed-holding.millCurrency")
      );
      return formattedValues;
    },
    keyRatioPercentValues(): Array<BarChartItem> {
      const allowedKeys = ["netProfitMargin", "reciprocalPeg", "dividendToPrice"];
      const formattedValues = this.getFormattedKeyRatios(
        allowedKeys as Array<HoldingDetailKeys>,
        "%"
      );
      return formattedValues;
    },
    keyRatioOnlyValues(): Array<BarChartItem> {
      const allowedKeys = [
        "bookToMarket",
        "debtToMarketCap",
        "cashFlowToAsset",
        "earningsToPrice",
        "momentum"
      ];
      const formattedValues = this.getFormattedKeyRatios(
        allowedKeys as Array<HoldingDetailKeys>,
        ""
      );
      return formattedValues;
    },
    undefinedKeyRatioValues() {
      return keyRatioObject.map((x) => ({
        text: this.$t(`portfolio.holdings.detailed.detailed-holding.keyRatios.${x.key}`),
        dataLabel: "-",
        relativeValue: 0
      }));
    },
    isShare(): boolean {
      return this.selectedHolding.instrumentType === InstrumentType.Shares;
    }
  },
  watch: {
    selectedHolding: {
      immediate: true,
      handler() {
        this.$store.commit(PortfolioMutation.setLoadingDetailedHoldingError, false);
        const { asset, instrumentType } = this.selectedHolding;
        if (asset && instrumentType === InstrumentType.Shares) {
          this.$store.commit(PortfolioMutation.setLoadingDetailedHoldingError, false);
          if (!this.details) {
            this.$store.dispatch(PortfolioAction.loadDetailedHolding, asset);
          }
        }
      }
    }
  },
  methods: {
    numberFormat(value: number, currency: string) {
      return numberFormat(value, currency);
    },
    roundedNumberFormat(value: number, unit: string) {
      return roundedNumberFormat(value, unit);
    },
    fractionToPercentage(value: number) {
      return fractionToPercentage(value);
    },
    convertKeyValues(allowedKeys: Array<HoldingDetailKeys>, unit: any): Object {
      const filtered = allowedKeys.map((key) => {
        const value = this.details[key];
        return {
          text: this.$t(`portfolio.holdings.detailed.detailed-holding.keyRatios.${key}`),
          // We have trouble getting correct power bars for some companies
          dataLabel: value === null ? "Ingen data" : "",
          relativeValue: value === null ? 0 : value * 100
        };
      });
      return filtered;
    },
    getFormattedKeyRatios(allowedKeys: Array<HoldingDetailKeys>, unit: string) {
      if (this.details) {
        const convertedKeyValues = this.convertKeyValues(allowedKeys, unit);
        return convertedKeyValues;
      }
      return [];
    },
    getUndefinedKeyRatios() {
      return [
        {
          key: "undefinedValues",
          data: this.undefinedKeyRatioValues
        }
      ];
    },
    formatSector(instrumentType: InstrumentType, sectorKey: string) {
      if (instrumentType === InstrumentType.Shares) {
        return sectorKey ? this.$t(`sectors.${Sector[sectorKey]}`) : "-";
      }
      return this.$t("portfolio.holdings.detailed.detailed-holding.swedishBondFond");
    },
    formatSubSector(subSector: string): string {
      const translation = this.$t(`subSectors.${subSector}`);
      // If we dont have translation for the subsector, then show the english one
      if (translation === `subSectors.${subSector}`) {
        return subSector;
      }
      return translation as string;
    }
  }
});
</script>

<style lang="scss" scoped>
.detailed-holding {
  padding: 1.5rem;
  font-family: $heading-font-family;
  display: grid;
  grid-row-gap: 1.5rem;
  &__title-description-wrapper {
    display: grid;
    grid-row-gap: 1.5rem;
  }
  &__header-container {
    position: relative;
    display: flex;
    align-items: flex-start;
  }
  &__header-text-container {
    display: flex;
    flex-direction: column;
    margin-left: 0.375rem;
    margin-right: 0.625rem;
  }
  &__subheader-text {
    font-size: 0.875rem;
    font-weight: 300;
  }
  &__key-ratio-list-item {
    padding: 0.5rem 0;
    @include medium-up {
      padding: 0.5625rem 0;
    }
  }
  &__icon-wrapper {
    margin-top: 0.1rem;
  }
  &__error {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  &__wiki-link {
    font-size: 0.65rem;
    text-align: right;
    opacity: 0.4;
  }
  &__holding-summary {
    border-top: solid 0.0625rem rgba($nearly-black, 0.2);
    border-bottom: solid 0.0625rem rgba($nearly-black, 0.2);
    display: block;
    @include medium-up {
      display: none;
    }
  }
  &__holding-summary-row {
    display: flex;
    justify-content: space-between;
  }
  &__holding-summary-value {
    font-weight: 300;
  }
}
</style>
