<template>
  <remainder
    disable-if-lower-than-income
    @next="next"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Remainder from "@/views/common-signup/questions/economy/remainder.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupFlowModificationType } from "@/config/signup-modification";

export default defineComponent({
  components: { Remainder },
  methods: {
    async next(value: number): Promise<void> {
      await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
        modification: SignupFlowModificationType.ExpensesWarning,
        include: value === 0
      });
      this.$store.commit(SignupMutation.setHasHighExpenses, value === 0);
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
