<template>
  <div class="politically-exposed-title">
    <single-choice
      :alternatives="navigationAlternatives"
      :disabled="!chosenTitle"
      @click="goToNext"
    >
      <question-text-section
        :heading="$t('create-portfolio.questions.aml.politically-exposed-title.heading')"
        progress
      />
      <div class="politically-exposed-title__dropdown">
        <v-select
          v-model="chosenTitle"
          :bg-color="softBlue"
          :items="items"
          :label="$t('selectTitle')"
          variant="solo"
          hide-details
          menu-icon="fas fa-chevron-down"
        >
        </v-select>
      </div>
    </single-choice>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { NavigationAlternative } from "@/types/signup";
import { softBlue } from "@/styles/colors";
import Question from "@/views/signup/templates/question";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import MultipleChoiceSection from "@/components/multiple-choice.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";

export default defineComponent({
  components: {
    QuestionTextSection,
    MultipleChoiceSection,
    SingleChoice
  },
  extends: Question,
  data() {
    return {
      showErrorMessage: false,
      associated: this.$router.currentRoute.value.path.includes("associated"),
      softBlue
    };
  },
  computed: {
    chosenTitle: {
      get(): string | undefined {
        return this.associated
          ? this.$store.state.signupStore.associatedPepInfo.title
          : this.$store.state.signupStore.pepInfo.title;
      },
      set(value: any) {
        this.associated
          ? this.$store.commit(SignupMutation.setAssociatedPepTitle, value)
          : this.$store.commit(SignupMutation.setPepTitle, value);
      }
    },
    navigationAlternatives(): Array<NavigationAlternative> {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    },
    items(): Object[] {
      return [
        "memberOfGovernment",
        "memberOfParliament",
        "memberOfPoliticalBoard",
        "judge",
        "seniorOfficial",
        "ambassadorOrSeniorOfficer",
        "partOfStateOwnedCompanyControl",
        "managementInternationalOrg"
      ].map((x) => ({ title: this.$t(x), value: x }));
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
<style lang="scss">
.politically-exposed-title {
  height: 100%;
  width: 100%;
}

.v-list-item {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
</style>
