<template>
  <SideDialog
    :show-back-button="showCreateReport"
    :model-value="modelValue"
    :title="dialogTitle"
    data-jest="dialog"
    @update:model-value="(value) => {
      $emit('update:model-value', value);
    }
    "
    @back="(value) => {
      showCreateReport = false;
    }
    "
  >
    <div class="agreements__wrapper">
      <div v-if="!showCreateReport">
        <v-expansion-panels
          v-model="panels"
          :multiple="true"
        >
          <v-expansion-panel
            elevation="0"
            :disabled="reportPdfs === undefined || reportPdfs.length === 0"
          >
            <v-expansion-panel-title expand-icon="fas fa-chevron-down">
              <span class="agreements__archive-header-text">{{ $t("reports") }}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="pe-side-dialog__paragraph">
              <div
                v-for="pdf in reportPdfs"
                :key="pdf.id"
                class="agreements__row"
                @click="pdf.handler(pdf.id)"
              >
                <div>{{ pdf.name }}</div>
                <div class="agreements__icon">
                  <LoadingSpinner
                    v-if="isLoading[pdf.id]"
                    data-jest="loading-spinner"
                    class="agreements__loading"
                  />
                  <icon
                    v-else
                    fixed-width
                    :icon="['fal', 'arrow-to-bottom']"
                  />
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            elevation="0"
            :disabled="taxBasePdfs === undefined || taxBasePdfs.length === 0"
          >
            <v-expansion-panel-title expand-icon="fas fa-chevron-down">
              <span class="agreements__archive-header-text">{{ $t("portfolio.overview.reports.reports-side-dialog.tax")
              }}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="pe-side-dialog__paragraph">
              <div
                v-for="pdf in taxBasePdfs"
                :key="pdf.brickId"
                class="agreements__row"
                @click="loadPdf(pdf)"
              >
                <div>
                  {{ $t("portfolio.overview.reports.reports-side-dialog.tax") }} [{{ taxBaseYear(pdf.creationDate) }}]
                </div>
                <div class="agreements__icon">
                  <LoadingSpinner
                    v-if="isLoading[pdf.brickId]"
                    data-jest="loading-spinner"
                    class="agreements__loading"
                  />
                  <icon
                    v-else
                    fixed-width
                    :icon="['fal', 'arrow-to-bottom']"
                  />
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            elevation="0"
            :disabled="manualPdfs === undefined || manualPdfs.length === 0"
          >
            <v-expansion-panel-title expand-icon="fas fa-chevron-down">
              <span class="agreements__archive-header-text">{{
                $t("portfolio.overview.reports.reports-side-dialog.statement")
              }}</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text class="pe-side-dialog__paragraph">
              <div
                v-for="pdf in manualPdfs"
                :key="pdf.brickId"
                class="agreements__row"
                @click="loadPdf(pdf)"
              >
                <div>
                  {{ $t("portfolio.overview.reports.reports-side-dialog.statement") }}
                  [{{ statementDate(pdf.creationDate) }}]
                </div>
                <div class="agreements__icon">
                  <LoadingSpinner
                    v-if="isLoading[pdf.brickId]"
                    data-jest="loading-spinner"
                    class="agreements__loading"
                  />
                  <icon
                    v-else
                    fixed-width
                    :icon="['fal', 'arrow-to-bottom']"
                  />
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            elevation="0"
            readonly
          >
            <v-expansion-panel-title
              expand-icon="fas fa-chevron-right"
              @click="() => (showCreateReport = true)"
            >
              <span class="agreements__archive-header-text">{{
                $t("portfolio.overview.reports.reports-side-dialog.createNewReport")
              }}</span>
            </v-expansion-panel-title>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <CreateReport
        v-else
        @back="() => (showCreateReport = false)"
      />
    </div>
  </SideDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CreateReport from "./create-report.vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import type { PdfListData, Quarter } from "@/clients";
import { PortfolioBase } from "@/clients";
import { UserAction } from "@/store/user/action-definitions";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import getReportQuarters from "@/utils/quarters";

interface QuarterlyReport {
  id: number;
  name: string;
  handler: (id: number) => void;
  year: number;
}

export default defineComponent({
  components: { SideDialog, LoadingSpinner, CreateReport },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    portfolio: {
      type: PortfolioBase,
      default: undefined,
    },
  },
  emits: ["update:model-value"],
  data: () => ({
    panels: [],
    portfolioPanels: [],
    showCreateReport: false,
  }),
  computed: {
    isLoading(): Array<boolean> {
      const { loadingPdf } = this.$store.state.userStore;
      return loadingPdf;
    },
    dialogTitle(): string {
      if (!this.showCreateReport)
        return this.$t("reports");
      return this.$t("createReport");
    },
    taxBasePdfs(): PdfListData[] {
      const { taxBasePdfs } = this.$store.state.userStore;
      const result: PdfListData[] = [];
      if (taxBasePdfs) {
        taxBasePdfs.forEach((pdf: PdfListData) => {
          result.push(pdf);
        });
      }
      return result;
    },
    manualPdfs(): PdfListData[] {
      const { manualPdfs } = this.$store.state.userStore;
      const result: PdfListData[] = [];
      if (manualPdfs) {
        manualPdfs.forEach((pdf: PdfListData) => {
          result.push(pdf);
        });
      }
      return result;
    },
    reportPdfs(): QuarterlyReport[] {
      let portfolio: PortfolioBase;
      if (this.portfolio) {
        portfolio = this.portfolio;
      }
      else {
        portfolio = this.$store.state.portfolioStore.activePortfolioBase;
      }
      const { createdAt } = portfolio;

      const reportQuarters = getReportQuarters(createdAt);

      const pdfs: QuarterlyReport[] = [];
      let count = 0;
      reportQuarters.forEach((quarters: Quarter[], year: number) => {
        quarters.forEach((quarter: Quarter | undefined) => {
          const displayQuarter = Number(quarter) + 1;
          pdfs.push({
            id: count,
            name: `${this.$t(
              "portfolio.overview.reports.reports-side-dialog.quarterlyReport",
            )} Q${displayQuarter} ${year}`,
            year,
            handler: (id: number) => {
              this.$store.dispatch(UserAction.getQuarterlyReport, {
                pdfId: id,
                portfolioBase: portfolio as PortfolioBase,
                quarter,
                year,
              });
            },
          });
          count += 1;
        });
      });

      return pdfs.sort((a, b) => (a.year < b.year ? 1 : -1));
    },
  },
  created() {
    const portfolio = this.$store.state.portfolioStore.activePortfolioBase;
    this.$store.dispatch(UserAction.loadTaxBaseAndManualPdfs, portfolio.id);
  },
  methods: {
    taxBaseYear(date: Date): string {
      if (date) {
        // TaxBasePdf's is created the year after of which the pdfs data is referencing
        return (date.getFullYear() - 1).toString();
      }
      return "-";
    },
    loadPdf(pdf: PdfListData) {
      this.$store.dispatch(UserAction.loadPdf, pdf);
    },
    statementDate(date: Date): string {
      return date.toLocaleString("sv-SE").split(" ")[0];
    },
  },
});
</script>

<style lang="scss">
.agreements {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  &__icon {
    margin-left: 0.6rem;
  }
}

:deep(.v-expansion-panel-title__overlay) {
  opacity: 0 !important;
}
</style>
