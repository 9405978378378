<template>
  <div class="citizenship-denial">
    <single-choice-template
      :alternatives="informationAlternatives"
      @click="selectInformation"
    >
      <information-text-section
        :heading="$t('denialHeading')"
        :body="$t('signup.questions.citizenship-denial.denialDescription')"
      >
        <icon />
      </information-text-section>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Icon from "@/assets/images/signup/user-lock-light.svg";
import { SingleChoice } from "@/types/signup";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    InformationTextSection,
    Icon
  },
  computed: {
    informationAlternatives(): SingleChoice[] {
      return [
        {
          text: this.$t("quit"),
          key: "quit"
        }
      ];
    }
  },
  methods: {
    selectInformation(): void {
      this.$router.push({ name: "login" });
    }
  }
});
</script>

<style lang="scss" scoped>
.citizenship-denial {
  height: 100%;
  width: 100%;
}
</style>
