<template>
  <single-choice
    :alternatives="alternatives"
    @click="buttonClick"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <question-text-section
        v-if="noIncomeVerify"
        progress
        :heading="$t('create-portfolio.questions.economy.income.headingVerify')"
        :body="$t('create-portfolio.questions.economy.income.bodyVerify')"
      />
      <question-text-section
        v-else
        progress
        :heading="$t('create-portfolio.questions.economy.income.heading')"
        :body="$t('create-portfolio.questions.economy.income.body')"
      />
    </transition>
    <range-template
      v-model="monthlyIncome"
      :values="values"
      :label="$t('sekPerMonth')"
    />
  </single-choice>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import Question from "@/views/signup/templates/question";
import RangeTemplate from "@/views/signup/templates/range.vue";
import { SignupFlowModificationType } from "@/config/signup-modification";

export default defineComponent({
  components: {
    RangeTemplate,
    QuestionTextSection,
    SingleChoice
  },
  extends: Question,
  props: {
    enableNoIncomeWarning: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return { noIncomeVerify: false };
  },
  computed: {
    values() {
      const values: number[] = [];
      for (let i = 0; i < 60000; i += 1000) {
        values.push(i);
      }
      for (let i = 60000; i <= 100000; i += 5000) {
        values.push(i);
      }
      for (let i = 100000; i <= 300000; i += 10000) {
        values.push(i);
      }
      return values;
    },
    monthlyIncome: {
      get(): number {
        return this.$store.state.signupStore.monthlyIncome;
      },
      set(value: number) {
        this.$store.commit(SignupMutation.setMonthlyIncome, value);
      }
    },
    alternatives(): any {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    async buttonClick(): Promise<void> {
      if (this.noIncomeVerify) {
        await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
          include: this.monthlyIncome === 0 && this.enableNoIncomeWarning,
          modification: SignupFlowModificationType.NoIncome
        });
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      } else if (!this.noIncomeVerify && this.monthlyIncome > 0) {
        await this.$store.dispatch(SignupNavigationAction.modifySignupFlow, {
          include: false,
          modification: SignupFlowModificationType.NoIncome
        });
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      } else if (this.monthlyIncome === 0) {
        this.noIncomeVerify = true;
      } else {
        this.noIncomeVerify = false;
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
