import Bowser, { BROWSER_MAP } from "bowser";

export function isValidBrowser(): boolean {
  const browser = Bowser.getParser(window.navigator.userAgent);
  // We have seen that the instagram in-app bowser fails to detect version and so it's treated as an invalid browser.
  // If we can't detect version we let the browser through to err on the side of not stopping users when we are not sure they have an old browser.
  if (browser.getBrowserVersion() === undefined) {
    return true;
  }
  const satisfied = browser.satisfies({
    chrome: ">=80",
    safari: ">=12",
    // Version 79 is Chromium version
    edge: ">=79",

    desktop: {
      firefox: ">=68",
      opera: ">=68"
    },
    ios: {
      firefox: ">=25"
    },
    android: {
      firefox: ">=68",
      opera: ">=55"
    },
    samsung_internet: ">=10"
  });
  // Can be boolean or undefined

  return !!satisfied;
}

// Workaround new iOS versions by default sets safari user agent identical to Mac OS user agent.
// https://stackoverflow.com/questions/56578799/tell-ipados-from-macos-on-the-web
function isIpad(): boolean {
  return navigator.userAgent.match(/Mac/) !== null && navigator.maxTouchPoints > 2;
}

export function isChromeMobile(): boolean {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.is("mobile") && browser.is("chrome");
}

export function isChrome(): boolean {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.is("chrome");
}

export function isFirefox(): boolean {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.is("firefox");
}

export function isIOS(): boolean {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getOSName(true) === "ios" || isIpad();
}

export function isUnknownBrowserAndIOS(): boolean {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getBrowserVersion() === undefined && isIOS();
}

export function isSafari(): boolean {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.is("safari");
}

export function isHandheld(): boolean {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getPlatform().type !== "desktop" || isIpad();
}

export function isInstagramOrFacebookIOS() {
  return (!isValidBrowser() && isIOS()) || isUnknownBrowserAndIOS();
}
