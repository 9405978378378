<template>
  <div class="swish">
    <template v-if="!swishError">
      <label class="swish__label">{{
        isHandheld ? $t("components.swish.openMobile") : $t("components.swish.openDesktop")
      }}</label>
      <loading-spinner />
    </template>
    <template v-else>
      <div class="swish__error-wrapper">
        <p class="swish__error-title">{{ swishError.title }}</p>
        <p class="swish__error-body">{{ swishError.body }}</p>
        <common-button
          class="swish__button"
          @click="reset"
          >{{ $t("back") }}</common-button
        >
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { PortfolioAction } from "@/store/portfolio/actions";
import { LogLevel, SwishPaymentStatus, SwishTransactionStatus } from "@/clients";
import CommonButton from "@/components/button.vue";
import { isHandheld } from "@/clients/bowser";
import log from "@/clients/log";
import { createBankIdRedirectParameterValue } from "@/utils/mobile-redirect";

const logger = "swish.vue";

interface SwishError {
  title: string;
  body: string;
}

export default defineComponent({
  emits: ["complete", "reset"],
  components: { LoadingSpinner, CommonButton },
  props: {
    phoneNumber: {
      type: String,
      required: false,
      default: undefined
    },
    amount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      SwishPaymentStatus,
      isHandheld: isHandheld()
    };
  },
  computed: {
    swishPaymentStatusCode(): SwishPaymentStatus | undefined {
      if (this.swishTransactionStatus) {
        return this.swishTransactionStatus.status;
      }
      return undefined;
    },
    swishTransactionStatus(): SwishTransactionStatus | undefined {
      return this.$store.state.portfolioStore.swishTransactionStatus;
    },
    swishError(): SwishError | undefined {
      if (this.swishPaymentStatusCode !== undefined && this.swishTransactionStatus) {
        switch (this.swishPaymentStatusCode) {
          case SwishPaymentStatus.Error:
            log(
              logger,
              `Something unknown went wrong with Swish. (swishTransactionStatus=${JSON.stringify(
                this.swishTransactionStatus
              )})`,
              LogLevel.Warning
            );
            return {
              title: this.$t(
                this.swishTransactionStatus.errorCode === "DS24" ? "DS24Header" : "errorHeader"
              ),
              body: this.errorCodeBody
            };
          case SwishPaymentStatus.Cancelled:
            return {
              title: this.$t("couldNotDoTransaction"),
              body: this.$t("components.swish.cancelledBody")
            };
          case SwishPaymentStatus.Declined:
            return {
              title: this.$t("couldNotDoTransaction"),
              body: this.$t("components.swish.declinedBody")
            };
          case SwishPaymentStatus.CreateTransactionInBricknodeFailed:
            return {
              title: this.$t("couldNotDoTransaction"),
              body: this.$t("components.swish.bricknodeFailedBody")
            };
          case SwishPaymentStatus.ServerUnreachable:
            return {
              title: this.$t("couldNotDoTransaction"),
              body: this.$t("components.swish.bricknodeFailedBody")
            };
          case SwishPaymentStatus.Paid:
          case SwishPaymentStatus.Pending:
          default:
            return undefined;
        }
      }
      return undefined;
    },
    errorCodeBody(): string {
      if (this.swishTransactionStatus && this.swishPaymentStatusCode === SwishPaymentStatus.Error) {
        switch (this.swishTransactionStatus.errorCode) {
          case "RF07":
            return this.$t("components.swish.RF07");
          case "BANKIDCL":
            return this.$t("components.swish.BANKIDCL");
          case "FF10":
            return this.$t("components.swish.FF10");
          case "TM01":
            return this.$t("components.swish.TM01");
          case "DS24":
            return this.$t("components.swish.DS24");
          case "BE18":
            return this.$t("components.swish.BE18");
          case "VR02":
            return this.$t("components.swish.VR02");
          case "ACMT03":
            return this.$t("components.swish.ACMT03");
          default:
            return this.$t("pleaseTryAgain");
        }
      }
      return "";
    }
  },
  watch: {
    swishPaymentStatusCode: {
      handler(newValue: SwishPaymentStatus | undefined) {
        if (newValue !== undefined) {
          if (newValue === SwishPaymentStatus.Paid) {
            this.$emit("complete");
          }
        }
      }
    }
  },
  async mounted() {
    if (this.amount > 0) {
      await this.$store.dispatch(PortfolioAction.swishPaymentRequest, {
        amount: this.amount,
        phoneNumber: this.$vuetify.display.mdAndUp ? this.phoneNumber : null
      });
      if (
        (this.$store.state.portfolioStore.swishTransactionStatus as SwishTransactionStatus)
          .status === SwishPaymentStatus.Pending
      ) {
        this.$store.dispatch(PortfolioAction.pollSwishTransactionStatus);
        if (this.$vuetify.display.smAndDown) {
          (window as any).location.href = await this.getSwishRedirectUrl(
            this.$store.state.portfolioStore.swishToken
          );
        }
      }
    }
  },
  methods: {
    reset() {
      this.$emit("reset");
    },
    async getSwishRedirectUrl(token: string): Promise<string | undefined> {
      if (token) {
        const redirectUrl = `swish://paymentrequest?token=${token}&callbackurl=${createBankIdRedirectParameterValue()}`;
        await log(logger, `redirecting to ${redirectUrl}:`, LogLevel.Information);
        return redirectUrl;
      }
      await log(logger, "token was null!", LogLevel.Information);
      return undefined;
    }
  }
});
</script>
<style lang="scss" scoped>
.swish {
  display: flex;
  flex-direction: column;

  text-align: center;
  row-gap: 1rem;
  height: 100%;
  &__label {
    font-size: 1rem;
    opacity: 0.63;
  }
  &__error-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
    height: 100%;
  }
  &__error-title {
    font-size: 1.125rem;
    font-family: $heading-font-family;
  }
  &__error-body {
    font-size: 1rem;
    font-family: $heading-font-family;
    opacity: 0.7;
  }
  &__button {
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto !important;
    width: 100%;
  }
}
</style>
