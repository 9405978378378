import { FileResponse } from "@/clients";
import { isChromeMobile } from "@/clients/bowser";
import FileSaver from "file-saver";

export function downloadFileResponse(response: FileResponse | null) {
  if (!response) throw new Error("Could not download file.");

  if (isChromeMobile()) {
    // Backend actually returns a blob, but for some reason chrome iOS needs the type as well
    // This unfortunately opens in the same tab without downloading. I tried many different ways
    // but it doesn't seem to work. There are multiple issues on the FileSaver repo that talk about this
    // as well as on stackoverflow.
    const blob = new Blob([response.data], { type: "application/pdf" });
    FileSaver.saveAs(blob, response.fileName);
  } else {
    FileSaver.saveAs(response.data, response.fileName);
  }
}
