<template>
  <div class="referral">
    <div class="referral__wrapper">
      <icon
        fixed-width
        :icon="['fal', 'briefcase']"
        class="referral__icon"
      />
      <h2 class="referral__title">
        {{ $t("createPortfolio") }}
      </h2>
      <icon
        :icon="['fas', 'chevron-right']"
        class="referral__forward-icon"
      />
    </div>
    <div class="referral__content-wrapper">
      <span class="referral__message">
        {{ $t("overview.create-portfolio.body") }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.referral {
  display: flex;
  flex-direction: column;
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  &__chart {
    grid-column: 1 / 4;
  }
  &__icon {
    color: $nearly-black;
    font-size: 1.125rem;
    margin-right: 0.75rem;
  }
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
  }
  &__forward-icon {
    margin: 0.5rem 0 auto auto;
    color: $french-grey;
  }
}
</style>
