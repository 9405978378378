<template>
  <SingleChoice
    :alternatives="alternatives"
    @click="buttonClick"
  >
    <QuestionTextSection
      :heading="$t('common-signup.questions.preferences.sustainable-choice-verify.heading')"
      :body="$t('common-signup.questions.preferences.sustainable-choice-verify.body1')"
    />
    <QuestionTextSection
      :body="$t('common-signup.questions.preferences.sustainable-choice-verify.body2')"
    />
  </SingleChoice>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import SingleChoice from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { useQuestion } from "@/views/signup/templates/question-comp";

const store = useStore();
const router = useRouter();
const { t } = useI18n();
useQuestion(store, router);

const alternatives = computed(() =>
  [
    {
      text: t("common-signup.questions.preferences.sustainable-choice-verify.change"),
      key: "change",
      secondary: true,
    },
    {
      text: t("common-signup.questions.preferences.sustainable-choice-verify.next"),
      key: "next",
    },
  ]);

function buttonClick(key: string) {
  if (key === "change") {
    store.dispatch(SignupNavigationAction.goToPreviousStep, router);
  } else {
    store.dispatch(SignupNavigationAction.goToNextStep, router);
  }
}
</script>
