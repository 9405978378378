<template>
  <div class="bankid">
    <bank-id-no
      v-if="locale === 'no'"
      :is-login="isLogin"
      :sign-location="signLocation"
      @complete="complete"
      @canceled="canceled"
      @failed="failed"
    />
    <bank-id-se
      v-else
      :is-login="isLogin"
      :without-heading="withoutHeading"
      @complete="complete"
      @canceled="canceled"
      @failed="failed"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BankIdSe from "@/components/bank-id-se.vue";
import BankIdNo from "@/components/bank-id-no.vue";
import { SignLocation } from "@/store/bankid/types";

export default defineComponent({
  components: {
    BankIdSe,
    BankIdNo
  },
  props: {
    isLogin: {
      type: Boolean,
      default: undefined
    },
    withoutHeading: {
      type: Boolean,
      default: undefined
    },
    signLocation: {
      type: Number as () => SignLocation,
      default: undefined
    }
  },
  emits: ["complete", "canceled", "failed"],
  computed: {
    locale(): string {
      return this.$store.state.userStore.locale;
    }
  },
  methods: {
    complete() {
      this.$emit("complete");
    },
    canceled(): void {
      this.$emit("canceled");
    },
    failed(): void {
      this.$emit("failed");
    }
  }
});   
</script>

<style
  lang="scss"
  scoped
>
.bankid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3rem;
  height: 100%;
}
</style>
