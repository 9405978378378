<template>
  <div
    class="bar-chart__wrapper"
    :class="{ 'bar-chart__wrapper--twocols': icon }"
  >
    <icon
      v-if="icon"
      fixed-width
      :icon="icon"
      class="bar-chart__row-icon"
      data-jest="row-icon"
    />
    <div>
      <span
        class="bar-chart__header"
        data-jest="header"
      >
        {{ text }}
      </span>
      <span
        class="bar-chart__percent-distribution-text"
        :class="{
          'bar-chart__percent-distribution-text--light': !weightedHeader
        }"
        data-jest="percent-distribution-text"
      >
        {{ dataLabel }}
      </span>
    </div>
    <v-progress-linear
      data-jest="progress-bar"
      :rounded="true"
      height="10"
      :model-value="displayValue"
      :color="color"
      bg-color="rgba(184, 192, 202, 0.85)"
      :indeterminate="loading"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { softBlue } from "@/styles/colors";

export default defineComponent({
  props: {
    icon: {
      type: [String, Array],
      required: false,
      default: undefined
    },
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: softBlue
    },
    weightedHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    dataLabel: {
      type: String,
      required: true
    },
    relativeValue: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: (): any => ({ displayValue: 0 }),
  watch: {
    relativeValue: {
      immediate: true,
      handler(newVal: number | undefined, oldVal: number | undefined) {
        if (newVal !== undefined) {
          // Animate the bar-chart distribution progress bar value.
          this.$animejs({
            targets: this,
            displayValue: this.relativeValue,
            duration: 300,
            easing: "easeInOutSine"
          });
        }
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.bar-chart {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-row-gap: 0.125rem;

    &--twocols {
      grid-template-columns: auto 1fr;
    }
  }

  &__row-icon {
    grid-row: 1 / span 2;
    font-size: 1.25rem;
    color: $nearly-black;
    margin-right: 1rem;
  }

  &__header {
    float: left;
    font-weight: 300;
  }

  &__percent-distribution-text {
    float: right;
  }

  &__percent-distribution-text--light {
    font-weight: 300;
  }
}

:deep(.v-progress-linear__determinate) {
  border-radius: 0.25rem;
}
</style>
