<template>
  <div
    key="1"
    v-if="!selectedNotPrincipal"
    class="your-money"
  >
    <single-choice-template
      :alternatives="defaultAlternatives"
      @click="selectedPrincipalOption"
    >
      <question-text-section
        progress
        :heading="$t('common-signup.questions.aml.your-money.heading')"
      ></question-text-section>
      <p class="your-money__body">
        {{ $t("common-signup.questions.aml.your-money.body") }}
      </p>
    </single-choice-template>
  </div>
  <div
    key="2"
    v-else
    class="your-money"
  >
    <single-choice-template
      :alternatives="notPrincipalAlternatives"
      @click="quit"
      progress
    >
      <information-text-section :heading="$t('denialHeading')"><icon /></information-text-section>
      <p class="your-money__body">
        {{ $t("common-signup.questions.aml.your-money.warningBody") }}
      </p>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import Icon from "@/assets/images/signup/user-lock-light.svg";
import Question from "@/views/signup/templates/question";
import { defineComponent } from "vue";

export default defineComponent({
  extends: Question,
  components: {
    InformationTextSection,
    SingleChoiceTemplate,
    Icon,
    QuestionTextSection
  },
  data() {
    return {
      selectedNotPrincipal: false,
      defaultAlternatives: [
        {
          text: this.$t("no"),
          key: "no",
          secondary: true
        },
        {
          text: this.$t("yes"),
          key: "yes"
        }
      ],
      notPrincipalAlternatives: [
        {
          text: this.$t("quit"),
          key: "quit"
        }
      ]
    };
  },
  methods: {
    selectedPrincipalOption(key: string): void {
      if (key === "yes") {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      } else {
        this.selectedNotPrincipal = true;
      }
    },
    quit() {
      this.$router.push({ name: "overview" });
    }
  }
});
</script>

<style lang="scss" scoped>
.your-money {
  height: 100%;
  &__body {
    font-size: 1rem;
    margin: 1rem 0 0 0;
    text-align: center;
    font-weight: 300;
  }
}
</style>
