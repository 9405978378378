<template>
  <div class="delete-portfolio">
    <SideDialog
      :model-value="model"
      :title="$t('deleteAccountTitle')"
      @update:model-value="resetAndClose"
    >
      <div v-if="showCloseFlow">
        <ClosePortfolioFlow
          @close="resetAndClose"
          @abort="resetAndClose"
        />
      </div>
      <NoBankAccount
        v-else-if="showConnectAccount"
      />
      <div v-else>
        <div class="delete-portfolio__dialog">
          <p class="delete-portfolio__title">
            {{ $t("portfolio.overview.delete-portfolio.user-delete-portfolio.bodyTitle") }}
          </p>
          <p class="delete-portfolio__body">
            {{ $t("portfolio.overview.delete-portfolio.user-delete-portfolio.body1") }}
          </p>
          <div class="delete-portfolio__button-wrapper">
            <CommonButton
              secondary
              class="delete-portfolio__button"
              @click="() => resetAndClose()"
            >
              {{ $t("no") }}
            </CommonButton>
            <CommonButton
              class="delete-portfolio__button"
              @click="showClosePortfolio"
            >
              {{ $t("portfolio.overview.delete-portfolio.user-delete-portfolio.closePortfolio") }}
            </CommonButton>
          </div>
        </div>
      </div>
    </SideDialog>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import NoBankAccount from "../../overview/withdraw/no-bank-account.vue";
import SideDialog from "@/components/dialog/side-dialog.vue";
import CommonButton from "@/components/button.vue";
import ClosePortfolioFlow from "@/components/close-portfolio-flow.vue";

const model = defineModel<boolean>({ required: true });
const showCloseFlow = ref(false);

function showClosePortfolio() {
  showCloseFlow.value = true;
}

const store = useStore();
const showConnectAccount = computed(() => !store.getters.hasBankAccount && store.getters.totalValue !== 0);

function resetAndClose() {
  model.value = false;
  showCloseFlow.value = false;
}
</script>

<style lang="scss" scoped>
.delete-portfolio {
  &__dialog {
    font-family: $heading-font-family;
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: 300;
    grid-template-columns: 1fr;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 500;
  }

  &__body {
    &--bold {
      font-weight: 600;
    }
  }

  &__actions {
    flex-direction: column;
    text-align: center;
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__button-wrapper {
    position: absolute;
    bottom: 2rem;
    right: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-row-gap: 1rem;
    width: 100%;
  }
}
</style>
