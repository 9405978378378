<template>
  <div class="goal-chart">
    <div class="goal-chart__inner-wrapper">
      <div
        class="goal-chart__inner"
        :style="{ width: `${barWidth}%` }"
      >
        <div class="goal-chart__main" />
        <img
          class="goal-chart__endcap"
          :src="endCapUrl"
        />
      </div>
    </div>
    <div class="goal-chart__label-wrapper">
      <div
        class="goal-chart__label"
        data-jest="label"
      >
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    fraction: {
      type: Number,
      default: undefined
    }
  },
  data(): any {
    return {
      endCapUrl: new URL("/assets/images/overview/goal-chart-endcap.svg", import.meta.url).href
    };
  },
  computed: {
    barWidth(): number {
      // A small fraction added so the chart will go all the way out to the end, despite the wave
      // endcap.
      return this.chartPercentage * 1.02;
    },
    chartPercentage(): number {
      if (this.fraction === undefined || this.fraction < 0) {
        return 0;
      }
      return Math.min(100, this.fraction * 100);
    },
    label(): string {
      if (this.fraction === undefined) {
        return "- %";
      }
      return `${Math.round(this.chartPercentage).toLocaleString("sv-SE")} %`;
    }
  }
});
</script>

<style lang="scss" scoped>
.goal-chart {
  border-radius: 1.625rem;
  height: 3.25rem;
  background: #fff;
  border: 0.125rem solid $soft-blue;
  display: flex;
  position: relative;
  &__endcap {
    height: 100%;
    path {
      fill: $soft-blue;
    }
  }
  &__inner {
    height: 100%;
    display: flex;
    overflow: hidden;
  }
  &__inner-wrapper {
    border: solid #fff;
    border-radius: 1.625rem;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
  &__label {
    font-size: 1.875rem;
    font-weight: 600;
    font-family: $heading-font-family;
    white-space: nowrap;
    padding-bottom: 0.5rem;
  }
  &__label-wrapper {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  &__main {
    background: $soft-blue;
    flex: 1;
  }
}
</style>
