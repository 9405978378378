import { actions } from "./actions";
import { getCompanySignupInitialState, mutations } from "./mutations";
import { getters } from "./getters";

const initialState = getCompanySignupInitialState();

export default {
  state: initialState,
  mutations,
  actions,
  getters,
};
