import { useRouter } from "vue-router";

export function useSignupType() {
  const router = useRouter();

  function maybeTextToThirdPerson(text: string) {
    const mapping = {
      du: "ni",
      dina: "era",
      ditt: "ert",
      din: "er",
      dig: "er",
      jag: "vi",
      mitt: "vårt",
      mig: "oss",
      mina: "våra",
      Du: "Ni",
      Dina: "Era",
      Ditt: "Ert",
      Din: "Er",
      Dig: "Er",
      Jag: "Vi",
      Mitt: "Vårt",
      Mig: "Oss",
      Mina: "Våra",
    };

    const isCompanySignup = router.currentRoute.value.path.includes("company-signup");
    if (isCompanySignup) {
      return text.replaceAll(/du|dina|ditt|din|dig|jag|mitt|mig|mina|Du|Dina|Ditt|Din|Dig|Jag|Mitt|Mig|Mina/g, match => mapping[match]);
    }
    return text;
  }
  return { maybeTextToThirdPerson };
}
