<template>
  <v-dialog
    content-class="pension-wrapper"
    :model-value="modelValue"
    @update:model-value="inputChange"
  >
    <v-card>
      <div class="pension-dialog">
        <v-btn
          icon
          fixed-width
          class="pension-dialog__close-button"
          @click="() => close()"
        >
          <icon
            fixed-width
            :icon="['fal', 'times']"
            class="pension-dialog__close-button--icon"
          />
        </v-btn>
        <div class="pension-dialog__icons-wrapper">
          <img
            class="pension-dialog__icon"
            :src="imgPensionUrl"
          >
          <img
            class="pension-dialog__icon"
            :src="imgPiggyBankUrl"
          >
        </div>
        <div class="pension-dialog__header-text">
          {{ $t("components.pension-dialog.header") }}
        </div>

        <div class="pension-dialog__body-text">
          <span class="pension-dialog__bold">{{ $t("components.pension-dialog.body1Bold") }}</span>
        </div>

        <div
          class="pension-dialog__body-text"
          style="margin-top: 1.5rem"
        >
          {{ $t("components.pension-dialog.body2Part1") }}
          <a
            style="font-style: italic; text-decoration: unset; font-weight: 400; color: black"
            href="https://sigmastocks.com/om-tjansten/tjanstepension"
            target="_blank"
          >{{ $t("occupationalPension") }}</a>
          {{ $t("components.pension-dialog.body2Part2") }}
        </div>
        <div class="pension-dialog__button-wrapper">
          <CommonButton @click="close">
            {{ $t("ok") }}
          </CommonButton>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";
import { lightGreen } from "@/styles/colors";
import { UserAction } from "@/store/user/action-definitions";
import { HIDE_PENSION_DIALOG_KEY } from "@/config/general";

export default defineComponent({
  components: {
    CommonButton,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:model-value"],
  data() {
    return {
      lightGreen,
      imgPiggyBankUrl: new URL("/assets/images/signup/piggy-bank-light.svg", import.meta.url).href,
      imgPensionUrl: new URL("/assets/icons/pension-pyramid-aqua.svg", import.meta.url).href,
    };
  },
  methods: {
    inputChange(value: boolean) {
      if (!value) {
        this.close();
      }
    },
    close(): void {
      sessionStorage.setItem(HIDE_PENSION_DIALOG_KEY, "true");
      this.$store.dispatch(UserAction.hidePensionDialog);
      this.$emit("update:model-value", false);
    },
  },
});
</script>

<style lang="scss" scoped>
.pension-dialog {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__close-button {
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    &--icon {
      font-size: 1.375rem;
    }
  }
  &__icons-wrapper {
    margin-top: 2rem;
  }
  &__bold {
    font-weight: 600;
  }
  &__icon {
    margin: 0 0.35rem 0 0.35rem;
    color: #9acfc3;
  }

  &__header-text {
    font-size: 1.625rem;
    color: black;
    font-weight: 600;
    margin-top: 1.75rem;
  }
  &__body-text {
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    text-align: center;
    font-weight: 300;
  }
  &__button-wrapper {
    display: grid;
    grid-row-gap: 1rem;
    width: 50%;
    margin-top: 5rem;

    @include medium-up {
      display: flex;
      justify-content: center;
      column-gap: 1rem;
    }
    @include small-only {
      button {
        width: 100%;
      }
    }
  }
}
:deep(.pension-wrapper) {
  max-width: 30rem;
}
</style>
