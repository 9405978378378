<template>
  <side-dialog
    :model-value="showDialog"
    :title="$t('focusDividendTitle')"
    :show-back-button="hasSelectedMonth"
    @back="() => (selectedMonth = 0)"
    @update:model-value="showDialog = false"
  >
    <div class="side-dialog">
      <p class="side-dialog__info-text">
        {{ $t("portfolio.overview.dividend.side-dialog.infoText") }}
      </p>
      <selection
        :years="years"
        :month="selectedMonth"
        @selectYear="(selectedYearValue) => (selectedYear = selectedYearValue)"
        @decreaseMonth="() => decreaseMonth()"
        @increaseMonth="() => increaseMonth()"
      />
      <template v-if="!dividendError">
        <months-overview
          v-if="!hasSelectedMonth"
          :data="yearData"
          :year="selectedYear"
          @selectMonth="(selectedMonthKey) => (selectedMonth = selectedMonthKey)"
        />
        <month-dividend
          v-else
          :data="monthData"
        />
      </template>
      <template v-else>
        <div class="side-dialog__error">
          <error-loading
            :body="$t('portfolio.overview.dividend.side-dialog.cannotLoadDividends')"
          />
        </div>
      </template>
    </div>
  </side-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { DateTime } from "luxon";
import SideDialog from "@/components/dialog/side-dialog.vue";
import ErrorLoading from "@/components/error-loading.vue";
import { PortfolioDividend, Dividend } from "@/clients";
import { BarChartItem } from "@/types";
import { PortfolioAction } from "@/store/portfolio/actions";
import { PortfolioState } from "@/store/portfolio/types";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import Selection from "./selection.vue";
import MonthsOverview from "./months-overview.vue";
import MonthDividend from "./month-dividends.vue";

export default defineComponent({
  components: { SideDialog, Selection, MonthsOverview, MonthDividend, ErrorLoading },
  data: () => ({
    selectedYear: 0,
    selectedMonth: 0,
    years: [new Date().getFullYear()] as number[]
  }),
  computed: {
    showDialog: {
      get(): boolean {
        return (this.$store.state.portfolioStore as PortfolioState).showDividendDialog;
      },
      set(value: boolean): void {
        this.$store.commit(PortfolioMutation.setShowDividendDialog, value);
      }
    },
    dividend(): PortfolioDividend | undefined {
      const { dividend } = this.$store.state.portfolioStore;
      return dividend[this.selectedYear];
    },
    portfolioCreatedDate(): DateTime {
      const { portfolioCreatedDate } = this.$store.getters;
      return portfolioCreatedDate as DateTime;
    },
    dividendError(): boolean {
      return this.$store.state.portfolioStore.dividendError;
    },
    monthKeys(): number[] {
      return Array.from({ length: 12 }, (_, i) => i + 1);
    },
    yearData(): BarChartItem[] {
      if (this.dividend && this.dividend.dividends && this.selectedYear !== 0) {
        const months = this.monthKeys.map(
          (m) =>
            ({
              label: this.$t(`months.${m}`),
              amount: this.getDividendSumByMonth(m),
              relativeValue: 0,
              key: m
            } as BarChartItem)
        );
        const maxValue = Math.max(...months.map((o) => o.amount));
        if (maxValue > 0) {
          months.forEach((m) => {
            if (m.amount > 0) {
              m.relativeValue = (m.amount / maxValue) * 100;
            }
          });
        }
        return months;
      }
      const emptyState: BarChartItem[] = [];
      for (let i = 1; i <= 12; i += 1) {
        emptyState.push({
          label: this.$t(`months.${i}`),
          amount: 0,
          relativeValue: 0,
          key: i
        });
      }
      return emptyState;
    },
    monthData(): Dividend[] {
      if (this.selectedMonth !== 0 && this.dividend && this.dividend.dividends) {
        return this.dividend.dividends.filter(
          (x) =>
            x.tradeDate.getFullYear() === this.selectedYear &&
            DateTime.fromJSDate(x.tradeDate).month === this.selectedMonth
        );
      }
      return [];
    },
    hasSelectedMonth(): boolean {
      return this.selectedMonth !== 0;
    }
  },
  watch: {
    selectedYear: {
      immediate: false,
      async handler(newValue) {
        if (!this.$store.state.portfolioStore.dividend[newValue]) {
          await this.$store.dispatch(PortfolioAction.loadDividend, newValue);
        }
      }
    }
  },
  created() {
    const availableYears: number[] = [];
    for (let { year } = this.portfolioCreatedDate; year <= new Date().getFullYear(); year += 1) {
      availableYears.push(year);
    }
    this.years = availableYears;
  },
  methods: {
    decreaseMonth() {
      this.selectedMonth = Math.max(1, this.selectedMonth - 1);
    },
    increaseMonth() {
      this.selectedMonth = Math.min(12, this.selectedMonth + 1);
    },
    getDividendSumByMonth(month: number): number {
      if (this.dividend && this.dividend.dividends) {
        return this.dividend.dividends
          .filter(
            (d) =>
              d.tradeDate.getMonth() + 1 === month &&
              d.tradeDate.getFullYear() === this.selectedYear
          )
          .map((a) => a.dividendAmount)
          .reduce((a, b) => a + b, 0);
      }
      return 0;
    }
  }
});
</script>
<style lang="scss" scoped>
.side-dialog {
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  &__error {
    // Flex here too in order to not center non-error state
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  &__info-text {
    text-align: center;
  }
}
</style>
