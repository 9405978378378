<template>
  <div class="savings">
    <SavingsHeader
      class="savings__header"
      :title="$t('investments')"
      :icon="['fal', 'coins']"
    >
      <template
        v-if="!isLockedBecauseOfEconomy || canEditBankDetails"
        #action
      >
        <EditButton
          v-if="hasBankAccount && !editing"
          :disabled="!canEditBankDetails"
          :edit="editing"
          icon="university"
          :tooltip-text="$t('close')"
          :disabled-tooltip-text="$t('portfolio-settings.savings.accountToolTip')"
          @click="clickEditAccount"
        />
      </template>
      <template
        #action2
      >
        <EditButton
          v-if="!isLockedBecauseOfEconomy && !activePortfolioBase.isClosing && hasBankAccount && !editing"
          :edit="editing"
          icon="piggy-bank"
          @click="clickEditSavings"
        />
        <EditButton
          v-if="editing"
          :edit="editing"
          @click="saveChanges"
        />
      </template>
    </SavingsHeader>
    <LoadingSpinnerOverlay
      v-if="portfolioSettings === undefined"
      :model-value="portfolioSettings === undefined"
    />
    <v-form
      v-else-if="hasBankAccount && !editing"
      class="savings__content"
    >
      <SelectList
        v-model="editedBank"
        disabled
        :edit="false"
        :items="items"
        class="savings__select"
        :label="$t('bank')"
      />
      <v-text-field
        v-model="editedClearingNumber"
        variant="underlined"
        disabled
        class="savings__input--disabled-black savings__input"
        type="tel"
        :label="$t('clearing_number')"
      />
      <v-text-field
        v-model="editedAccountNumber"
        variant="underlined"
        disabled
        class="savings__input--disabled-black savings__input"
        type="tel"
        :label="$t('accountNumber')"
      />
      <div class="savings__monthlyDisplay">
        <v-text-field
          ref="monthlySavingsInputDisplay"
          v-model="editedMonthlySavings"
          class="savings__input--left-suffix savings__input--disabled-black savings__input"
          type="tel"
          variant="underlined"
          disabled
          :label="$t('portfolio-settings.savings.monthlySavings')"
          :suffix="editedMonthlySavings !== undefined ? $t('currency') : ''"
        /><span
          v-if="isMonthlySavingPaused"
          class="savings__monthlyDisplayPaused"
        >Pausat</span>
      </div>
    </v-form>
    <v-form
      v-else-if="state === State.EditMonthlySavings"
      ref="savingsForm"
      class="savings__savingsForm"
    >
      <div class="savings__editAccount--title">
        {{ $t("portfolio-settings.savings.title") }}
      </div>
      <div class="savings__editAccount--change">
        <icon
          class="savings__editAccount--change-icon"
          :icon="['fal', 'pen']"
        /><span>Ändra</span>
      </div>
      <v-text-field
        ref="monthlySavingsInput"
        v-model="displayMonthlySavings"
        variant="underlined"
        :clearable="editedMonthlySavings !== undefined && editedMonthlySavings > 0"
        class="savings__input--left-suffix savings__input"
        type="tel"
        validate-on="blur"
        persistent-clear
        :label="$t('portfolio-settings.savings.monthlySavings')"
        :rules="[rules.required, rules.number, rules.savingsNumberAmount]"
        :suffix="
          displayMonthlySavings !== undefined ? $t('portfolio-settings.savings.savingsSuffix') : ''
        "
      />
      <div class="savings__editAccount--change">
        <icon
          class="savings__editAccount--change-icon"
          :icon="['fal', 'pause']"
        /><span>Pausa</span>
      </div>
      <SelectList
        v-model="editedPauseMonths"
        edit
        label=""
        :prefix="
          editedPauseMonths !== undefined && editedPauseMonths > 0
            ? $t('portfolio-settings.savings.pausedSavingsFor')
            : $t('portfolio-settings.savings.pauseSavingsFor')
        "
        :clearable="editedPauseMonths !== undefined && editedPauseMonths > 0"
        :show-append-icon="editedPauseMonths === 0"
        :items="pauseAlternatives"
        @change="(value) => (editedPauseMonths = value)"
      />
    </v-form>
    <v-form
      v-else-if="state === State.EditBankAccount"
      ref="accountForm"
    >
      <SelectList
        v-model="editedBank"
        edit
        :items="items"
        class="savings__select"
        :label="$t('bank')"
        @change="changeBank"
      />
      <v-text-field
        ref="clearingNumberInput"
        v-model="editedClearingNumber"
        variant="underlined"
        class="savings__input"
        type="tel"
        :error="clearingNumberErrorState"
        validate-on="blur"
        :label="$t('clearing_number')"
        :rules="[rules.required, rules.number]"
        @focus="clearingNumberErrorState = false"
        @blur="setClearingNumberErrorState"
        @update:model-value="validateAccountForm"
      />
      <v-text-field
        v-model="editedAccountNumber"
        variant="underlined"
        class="savings__input"
        type="tel"
        validate-on="blur"
        :label="$t('accountNumber')"
        :rules="[rules.required, rules.number, rules.validAccountNumber]"
        @focus="clearingNumberErrorState = false"
        @blur="setClearingNumberErrorState"
        @update:model-value="validateAccountForm"
      />
    </v-form>
    <div
      v-else
      style="display: flex; flex-direction: column; align-items: center"
    >
      <p style="text-align: center; font-weight: bold">
        {{ $t("missingBankAccount") }}
      </p>
      <p style="text-align: center">
        {{ $t("portfolio-settings.savings.connectBankBody") }}
      </p>
      <CommonButton @click="openConnectBankSideDialog">
        {{
          $t("connectBankAccount")
        }}
      </CommonButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { mapGetters } from "vuex";
import Kontonummer from "kontonummer";
import SelectList from "../profile/select.vue";
import SavingsHeader from "./header.vue";
import type { Bank } from "@/types/portfolio";
import type { PortfolioBase, PortfolioSettings } from "@/clients";
import { PortfolioAction } from "@/store/portfolio/actions";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import EditButton from "@/components/edit-button.vue";
import { UserAction } from "@/store/user/action-definitions";
import { softBlue } from "@/styles/colors";
import type { BankOptions } from "@/types/signup";
import { banksWithTransactionAccounts } from "@/types/signup";
import CommonButton from "@/components/button.vue";
import LoadingSpinnerOverlay from "@/components/loading-spinner/loading-spinner-overlay.vue";
import { UserMutation } from "@/store/user/mutations";

enum State {
  EditBankAccount,
  EditMonthlySavings,
  NotEditing,
}

export default defineComponent({
  components: {
    EditButton,
    SavingsHeader,
    SelectList,
    CommonButton,
    LoadingSpinnerOverlay,
  },
  emits: ["change-account", "change-savings"],
  data: () => ({
    softBlue,
    clearingNumberErrorState: false,
    state: State.NotEditing as State,
    State,
  }),
  computed: {
    ...mapGetters(["hasBankAccount"]),
    editing(): boolean {
      return this.state === State.EditBankAccount || this.state === State.EditMonthlySavings;
    },
    pauseAlternatives(): any[] {
      return [...Array(7).keys()].map((n) => {
        return {
          key: n,
          value: n,
          title: this.$t("portfolio-settings.savings.pauseMonths", { count: n }),
        };
      });
    },
    bankDetailsDisabled(): boolean {
      return this.state !== State.EditBankAccount || !this.portfolioSettings.canEditBankDetails;
    },
    portfolioSettings(): PortfolioSettings {
      const { portfolioSettings } = this.$store.state.portfolioStore;
      return portfolioSettings;
    },
    activePortfolioBase(): PortfolioBase {
      const { activePortfolioBase } = this.$store.state.portfolioStore;
      return activePortfolioBase;
    },
    editedPortfolioSettings(): PortfolioSettings {
      const { editedPortfolioSettings } = this.$store.state.portfolioStore;
      return editedPortfolioSettings;
    },
    editedPortfolioSettingsId(): any {
      const { editedPortfolioSettings } = this.$store.state.portfolioStore;
      return editedPortfolioSettings?.id;
    },
    canEditBankDetails(): boolean {
      if (!this.portfolioSettings) {
        return false;
      }
      return this.portfolioSettings.canEditBankDetails;
    },
    editedMonthlySavings(): number | undefined {
      return this.editedPortfolioSettings?.monthlySaving;
    },
    isMonthlySavingPaused(): boolean {
      const { monthlySavingsPauseMonthsRemaining } = this.$store.state.portfolioStore?.portfolioSettings;
      return monthlySavingsPauseMonthsRemaining && monthlySavingsPauseMonthsRemaining > 0;
    },
    editedBank: {
      get(): string | undefined {
        if (this.editedPortfolioSettings) {
          return this.editedPortfolioSettings.bankName;
        }
        return undefined;
      },
      set(value: string) {
        this.$store.commit(PortfolioMutation.setEditedPortfolioSettings, {
          ...this.$store.state.portfolioStore.editedPortfolioSettings,
          bankName: value,
        });
      },
    },
    editedAccountNumber: {
      get(): string | undefined {
        if (this.editedPortfolioSettings) {
          return this.editedPortfolioSettings.accountNumber;
        }
        return undefined;
      },
      set(value: string) {
        this.$store.commit(PortfolioMutation.setEditedPortfolioSettings, {
          ...this.$store.state.portfolioStore.editedPortfolioSettings,
          accountNumber: value,
        });
      },
    },
    editedClearingNumber: {
      get(): string | undefined {
        if (this.editedPortfolioSettings) {
          return this.editedPortfolioSettings.clearingNumber;
        }
        return undefined;
      },
      set(value: string) {
        this.$store.commit(PortfolioMutation.setEditedPortfolioSettings, {
          ...this.$store.state.portfolioStore.editedPortfolioSettings,
          clearingNumber: value,
        });
      },
    },
    displayMonthlySavings: {
      get(): string | undefined {
        if (this.editedPortfolioSettings) {
          return this.editedPortfolioSettings.monthlySaving?.toString();
        }
        return undefined;
      },
      set(value: string) {
        if (value === "" || value === null) {
          value = "0";
        }
        this.$store.commit(PortfolioMutation.setEditedPortfolioSettings, {
          ...this.$store.state.portfolioStore.editedPortfolioSettings,
          monthlySaving: Number.parseInt(value),
        });
      },
    },
    editedPauseMonths: {
      get(): number | undefined {
        if (this.editedPortfolioSettings) {
          return this.editedPortfolioSettings.pauseMonthlySavingsMonths;
        }
        return undefined;
      },
      set(value: number | null) {
        if (value === null) {
          value = 0;
        }

        this.$store.commit(PortfolioMutation.setEditedPortfolioSettings, {
          ...this.$store.state.portfolioStore.editedPortfolioSettings,
          pauseMonthlySavingsMonths: value,
        });
      },
    },
    selectedPauseMonth: {
      get(): any | undefined {
        if (this.editedPortfolioSettings) {
          return this.editedPortfolioSettings.pauseMonthlySavingsMonths.toString();
        }
        return undefined;
      },
      set(value: any) {
        this.$store.commit(PortfolioMutation.setEditedPortfolioSettings, {
          ...this.$store.state.portfolioStore.editedPortfolioSettings,
          pauseMonthlySavingsMonths: Number(value),
        });
      },
    },
    bankFromAccountNumber(): Bank | undefined {
      const strippedClearingNumber = this.removeWhiteSpace(this.editedClearingNumber);
      const strippedAccountNumber = this.removeWhiteSpace(this.editedAccountNumber);
      let bank: Kontonummer | undefined;
      try {
        bank = new Kontonummer(strippedClearingNumber, strippedAccountNumber, {
          mode: "lax",
        });
      } catch (error: any) {
        // Throws despite setting mode: lax if user hasn't finished typing yet. Just ignore this.
      }
      if (bank) {
        return {
          bankName: bank.bankName as string,
          accountNumber: bank.accountNumber,
          clearingNumber: bank.sortingCode,
          valid: bank.valid,
        };
      }
      return undefined;
    },
    rules(): any {
      return {
        required: (value: string) => !!value || this.$t("required"),
        number: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("not_valid_number");
        },
        validAccountNumber: (_: string) => {
          const valid
            = this.bankFromAccountNumber
            && this.bankFromAccountNumber.valid
            && this.editedBank === this.bankFromAccountNumber.bankName;
          return valid || this.$t("does_not_match_bank");
        },
        savingsNumber: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        savingsNumberAmount: (value: string) => {
          value = value.replace(/\s/g, "");
          const valueNumber = Number(value);
          return (
            valueNumber >= 500
            || valueNumber === 0
            || this.$t("portfolio-settings.savings.notValidAmount")
          );
        },
      };
    },
    monthlySavingsSuffix(): string {
      return this.displayMonthlySavings !== "" || this.state === State.EditMonthlySavings
        ? this.$t("sekPerMonth")
        : "";
    },
    clearingNumberInput(): HTMLInputElement | null {
      return (this.$refs.clearingNumberInput as any).$el.querySelector("input");
    },
    monthlySavingsInput(): HTMLInputElement | null {
      if (this.$refs.monthlySavingsInput) {
        return (this.$refs.monthlySavingsInput as any).$el.querySelector("input");
      }
      return null;
    },
    buttonText(): string {
      return this.state === State.EditBankAccount ? this.$t("save") : this.$t("change_risk_level");
    },
    items() {
      const bankItems = banksWithTransactionAccounts.map((x: BankOptions) => ({
        title: x,
        value: x,
        key: x,
      }));
      if (import.meta.env.VITE_ENV === "development") {
        bankItems.push({
          title: "Test BankID (successful)",
          value: "Test BankID (successful)",
          key: "Test BankID (successful)",
        });
      }
      return bankItems;
    },
    isLockedBecauseOfEconomy(): boolean {
      if (this.$store.state.userStore.currentLegalEntity) {
        return this.$store.state.userStore.currentLegalEntity.isLockedBecauseOfEconomy;
      }
      return false;
    },
  },
  watch: {
    editedPortfolioSettingsId: {
      handler() {
        this.resetAllInputs();
      },
    },
    displayMonthlySavings: {
      immediate: true,
      handler() {
        this.resizeInputs();
      },
    },
  },

  created() {
    this.resizeInputs();
  },
  methods: {
    resizeInputs() {
      nextTick().then(() => {
        if (this.$refs.monthlySavingsInput) {
          this.resizeInputToCharacters(
            (this.$refs.monthlySavingsInput as any).$el.querySelector("input"),
          );
        }
        if (this.$refs.monthlySavingsInputDisplay) {
          this.resizeInputToCharacters(
            (this.$refs.monthlySavingsInputDisplay as any).$el.querySelector("input"),
          );
        }
      });
    },
    openConnectBankSideDialog() {
      this.$store.commit(PortfolioMutation.setShowConnectBankSideDialog, true);
    },
    resetAllInputs() {
      this.state = State.NotEditing;
      this.resizeInputs();
    },
    resizeInputToCharacters(input: HTMLInputElement | null): void {
      if (input) {
        input.style.width = `${input.value.length}ch`;
      }
    },
    removeWhiteSpace(value: string | undefined): string {
      if (!value) {
        return "";
      }
      return value.replace(/\s/g, "");
    },
    async save() {
      if (this.$store.state.portfolioStore.activePortfolioBase) {
        try {
          await this.$store.dispatch(PortfolioAction.prepareUpdatePortfolioSettings);
        } catch (e) {
          this.$store.dispatch(
            UserAction.addSnackbarMessage,
            this.$t("portfolio-settings.savings.saveSettingsError"),
          );

          console.error(e);
        }
        this.$store.commit(PortfolioMutation.decreaseUnsavedEdits);
      } else {
        // TODO Sätt error state, Task: https://trello.com/c/J5xEnd3z.
      }
    },
    completedUpdate() {
      this.resetAllInputs();
      this.resizeInputs();
    },
    async clickEditAccount(): Promise<void> {
      this.$store.commit(UserMutation.increaseUnsavedEdits);
      this.state = State.EditBankAccount;
      this.resizeInputs();
    },
    async clickEditSavings(): Promise<void> {
      this.$store.commit(UserMutation.increaseUnsavedEdits);
      this.state = State.EditMonthlySavings;
      this.resizeInputs();
    },
    async saveChanges(): Promise<void> {
      const { editedPortfolioSettings, portfolioSettings } = this.$store.state.portfolioStore;
      if (this.state === State.EditBankAccount) {
        if (await this.validateAccountForm()) {
          const hasChanged
            = editedPortfolioSettings.bankName !== portfolioSettings.bankName
            || editedPortfolioSettings.clearingNumber !== portfolioSettings.clearingNumber
            || editedPortfolioSettings.accountNumber !== portfolioSettings.accountNumber;
          if (hasChanged) {
            this.$emit("change-account");
          } else {
            this.completedUpdate();
          }
          this.$store.commit(UserMutation.decreaseUnsavedEdits);
        } else {
          // Wait for inputs to be enabled, which they will be in the next tick.
          nextTick().then(() => {
            // Focus on and select all text in the first input element.
            if (this.clearingNumberInput) {
              this.clearingNumberInput.focus();
              this.clearingNumberInput.select();
            }
          });
        }
      } else {
        if ((await (this.$refs.savingsForm as any).validate()).valid) {
          const hasChanged
            = editedPortfolioSettings.monthlySaving !== portfolioSettings.monthlySaving
            || editedPortfolioSettings.pauseMonthlySavingsMonths
            !== portfolioSettings.monthlySavingsPauseMonthsRemaining;
          if (hasChanged) {
            this.$emit("change-savings");
          } else {
            this.completedUpdate();
          }
          this.$store.commit(UserMutation.decreaseUnsavedEdits);
        }
      }
    },
    changeBank(value: string): void {
      this.editedBank = value;
      this.validateAccountForm();
      this.setClearingNumberErrorState();
    },
    async validateAccountForm(): Promise<boolean> {
      if (
        this.canEditBankDetails
        && this.$refs.accountForm
        && this.editedClearingNumber
        && this.editedAccountNumber
      ) {
        const validation = await (this.$refs.accountForm as any).validate();
        return validation.valid;
      }
      return false;
    },
    async setClearingNumberErrorState() {
      if (
        this.$refs.accountForm
        && this.editedClearingNumber
        && this.editedAccountNumber
        && this.canEditBankDetails
      ) {
        const validation = await (this.$refs.accountForm as any).validate();
        this.clearingNumberErrorState = !validation.valid;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.savings {
  height: 21rem;
  padding: 1.5rem 1.5rem 1.625rem 1.5rem;
  &__header {
    margin-bottom: 0.75rem;
  }
  &__monthlyDisplay {
    display: flex;
    flex-direction: row;
  }
  &__monthlyDisplayPaused {
    padding-top: 1rem;
    font-size: 1.1rem;
    font-weight: 600;
  }
  &__editAccount {
    &--title {
      font-weight: 300;
      padding-top: 0.5rem;
    }
    &--change {
      padding-top: 1.1rem;
      font-weight: 600;
    }
    &--change-icon {
      padding-right: 0.66rem;
    }
  }
  &__savingsForm {
    :deep(.v-select__selection) {
      font-weight: 600;
    }
  }
  &__input {
    :deep(label) {
      color: #2b2b2b;
      opacity: 63%;
      font-size: 0.9rem;
    }
    &--left-suffix {
      :deep(.v-text-field__slot) {
        max-width: 100%;
      }
      :deep(input) {
        min-width: 1rem;
        flex: unset;
        max-width: 100%;
      }
      :deep(.v-text-field__suffix) {
        flex: 1;
        text-align: left;
        cursor: text;
      }
    }
    &--disabled {
      :deep(.v-text-field__suffix) {
        color: inherit !important;
      }
      :deep(.v-field--disabled) {
        color: inherit;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    &--disabled-black {
      :deep(label) {
        color: #2b2b2b;
        opacity: 63%;
      }
      :deep(.v-text-field__suffix) {
        color: black !important;
      }
      :deep(.v-field--disabled) {
        color: $nearly-black;
        opacity: 1;
      }
      :deep(.v-field__outline:before) {
        border: none !important;
        border-color: transparent !important;
      }
    }
    :deep(.v-field__clearable) {
      margin-right: 0;
    }
  }
}
</style>
