import { RouteRecordRaw } from "vue-router";
import CioForm from "@/components/cio-form/cio-form.vue";
import CioFormSuccess from "@/components/cio-form/cio-form-success.vue";

export default [
  {
    path: "cio-form",
    component: CioForm
  },
  {
    path: "cio-form-success",
    component: CioFormSuccess
  }
] as RouteRecordRaw[];
