<template>
  <div class="market">
    <SettingsHeader
      class="market__header"
      :edit="edit"
      :title="edit ? $t('portfolio-settings.strategy.market.selectMarket') : $t('marketFocus')"
      :icon="['fal', 'globe-americas']"
    >
      <template #info>
        <InformationButton @click="() => (showDialog = true)" />
      </template>
      <template #action>
        <EditButton
          :disabled="disabled"
          :edit="edit"
          data-cy="marketEditButton"
          @click="toggleEdit"
        />
      </template>
    </SettingsHeader>
    <v-divider />

    <div class="market__wrapper">
      <v-list
        v-model:selected="item"
        variant="flat"
        :disabled="!edit"
      >
        <v-list-item
          v-for="(market, i) in marketItems"
          :key="i"
          :data-cy="`marketListItem${i}`"
          class="market__list-item market__item-title-wrapper"
          :active="!edit ? false : undefined"
          @click="() => selectMarket(market.key)"
        >
          <template #prepend>
            <div
              class="market__check-icon"
              :class="[
                {
                  'market__check-icon--hidden': !market.selected && !edit,
                  'market__check-icon--faded': !market.selected && edit,
                },
              ]"
            >
              <icon
                fixed-width
                :icon="['fal', 'check']"
              />
            </div>
          </template>
          <v-list-item-title class="market__item-title">
            <div style="display: flex; flex-direction: row; justify-content: space-between">
              <div>{{ market.title }}</div>
              <div style="width: 6.5rem; text-align: left">
                {{ market.distribution }}
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <div class="market__management-fee">
        {{ $t("managementFee") }}
        <span class="market__management-fee--fee"> {{ managementFee }} </span>
        {{ `% ${$t("perYear")}` }}
      </div>
    </div>
    <MarketFocusSideDialog v-model="showDialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SettingsHeader from "../header.vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import type { LegalEntity, Strategy } from "@/clients";
import { Market } from "@/clients";
import EditButton from "@/components/edit-button.vue";
import type { PortfolioState } from "@/store/portfolio/types";
import getMarketDistribution from "@/utils/market-distribution";
import InformationButton from "@/components/information-button.vue";
import MarketFocusSideDialog from "@/components/market-focus-side-dialog.vue";
import {
  getManagementFeeForMarketFocus,
  getManagementFeeForMarketFocusWithoutVat,
} from "@/utils/fees";

interface MarketItem {
  key: Market;
  title: string;
  selected: boolean;
  distribution: any;
}

export default defineComponent({
  components: { EditButton, SettingsHeader, InformationButton, MarketFocusSideDialog },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["save"],
  data: () => ({
    item: undefined,
    edit: false,
    showDialog: false,
  }),
  computed: {
    currentStrategy(): Strategy | undefined {
      const { strategy } = this.$store.state.portfolioStore;
      if (!strategy) {
        return undefined;
      }
      return strategy;
    },
    marketItems(): MarketItem[] {
      const activePortfolio = (this.$store.state.portfolioStore as PortfolioState)
        .activePortfolioBase;
      if (!activePortfolio || !this.currentStrategy) {
        return [];
      }
      function buildMarketDistributionString(market: Market): string {
        const marketDistribution = getMarketDistribution(market);

        return `S${marketDistribution[Market.Sweden]}, E${marketDistribution[Market.Europe]}, U${
          marketDistribution[Market.Us]
        }`;
      }
      const availableMarkets = [
        {
          key: Market.Standard,
          title: this.$t("focusStandardTitle"),
          selected: this.selectedMarket === Market.Standard,
          distribution: buildMarketDistributionString(Market.Standard),
        },
        ...(this.currentStrategy.marketFocus === Market.SwedenOld
          ? [
              {
                key: Market.SwedenOld,
                title: this.$t("swedenOld"),
                selected: this.selectedMarket === Market.SwedenOld,
                distribution: buildMarketDistributionString(Market.SwedenOld),
              },
            ]
          : []),
        {
          key: Market.Sweden,
          title: this.$t("sweden"),
          selected: this.selectedMarket === Market.Sweden,
          distribution: buildMarketDistributionString(Market.Sweden),
        },
        {
          key: Market.Europe,
          title: this.$t("marketFocusEurope"),
          selected: this.selectedMarket === Market.Europe,
          distribution: buildMarketDistributionString(Market.Europe),
        },
        {
          key: Market.Us,
          title: this.$t("usa"),
          selected: this.selectedMarket === Market.Us,
          distribution: buildMarketDistributionString(Market.Us),
        },
      ];
      // if (activePortfolio.createdAt > swedenOnlyReleaseDate) {
      //   return availableMarkets.filter((x) => x.key !== Market.SwedenOld);
      // }
      return availableMarkets;
    },
    selectedMarket: {
      get(): Market | undefined {
        const { editedStrategy } = this.$store.state.portfolioStore;
        if (!editedStrategy) {
          return undefined;
        }
        return editedStrategy.marketFocus;
      },
      set(market: Market) {
        this.$store.commit(PortfolioMutation.editMarket, market);
      },
    },
    currentLegalEntity(): LegalEntity {
      return this.$store.state.userStore.currentLegalEntity;
    },
    managementFee(): string {
      if (this.currentLegalEntity.isNaturalPerson) {
        return getManagementFeeForMarketFocus(
          this.currentLegalEntity.feeType,
          this.selectedMarket as Market,
        );
      }
      return getManagementFeeForMarketFocusWithoutVat(
        this.currentLegalEntity.feeType,
        this.selectedMarket as Market,
      );
    },
  },
  methods: {
    selectMarket(market: Market) {
      this.selectedMarket = market;
    },
    async toggleEdit(): Promise<void> {
      if (this.edit) {
        if (this.selectedMarket !== this.$store.state.portfolioStore.strategy.marketFocus) {
          this.$emit("save");
        }
      } else {
        this.$store.commit(PortfolioMutation.increaseUnsavedEdits);
      }
      this.edit = !this.edit;
    },
  },
});
</script>

<style lang="scss" scoped>
.market {
  &__check-icon {
    width: 1.875rem;
    font-size: 1.5rem;
    color: rgba($nearly-black, 0.8);
    margin: 0 1rem 0 0 !important;
    align-self: left;
    &--faded {
      color: rgba($nearly-black, 0.15);
    }
    &--hidden {
      opacity: 0;
    }
  }
  &__header {
    padding: 1.125rem 1.25rem;
    height: 3.75rem;
  }
  &__item-title {
    font-size: 1rem;
    font-family: $heading-font-family;
    line-height: 1.3em;
    font-weight: 300;
  }
  &__item-title-wrapper {
    width: 20rem;
    flex: unset;
  }
  &__list-item {
    padding: 0 1.25rem;
    border-radius: 0.5rem;
    padding-right: 4rem;
    @include medium-up {
      justify-content: unset;
      padding-right: 0rem;
    }
    &:hover {
      background-color: #f7f7f7;
    }
  }
  &__management-fee {
    font-weight: 300;
    width: 100%;
    text-align: center;
    padding-bottom: 1rem;
    &--fee {
      font-weight: 550;
    }
  }
  &__wrapper {
    height: 17.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
:deep(.v-list-item--disabled) {
  color: $nearly-black;
  opacity: 1;
}
</style>
