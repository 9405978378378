import { Quarter } from "@/clients";
import { YearQuarterMap } from "@/types/portfolio";
import { DateTime } from "luxon";

function isQuarterAdded(quarter: Quarter, year: number, map: YearQuarterMap): boolean | undefined {
  // Map<T>.get usually returns T | undefined so we can do map.get(year)?.includes but for some reason
  // jest fails on AWS. Don't know why that happens. See PR for discussion.
  return (map.get(year) as Quarter[]).includes(quarter);
}

/**
 * Returns a mapping between year and quarters for which a portfolio should have quarterly reports available. These come on the format
 * {year:number => [Quarter]}.
 * A report for a given year and quarter should be available if the portfolio was created before the start of the next quarter,
 * or put in other words, if the portfolio was created inside that quarter.
 *
 * For example, a portfolio created 2020-12-31 should on 2021-01-01 have the Q4 report of 2020 available because the Q4 change date of 2021-01-01 has passed.
 * Similarly, a portfolio created 2020-04-05 should NOT have a Q2 report available on 2020-06-15 because no quarter change date has yet passed.
 */
export default function getReportQuarters(portfolioCreatedAt: Date): YearQuarterMap {
  const currentDate = new Date(portfolioCreatedAt.getTime());
  // Set starting month to one month later so we don't register the current month
  currentDate.setMonth(currentDate.getMonth() + 1);
  // Set to first of month so we don't have to deal with months being different lengths
  currentDate.setDate(1);
  // Also set time of day to 1 so datetime comparisons are only one date level
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(1);

  const yearQuarterMap = new Map() as YearQuarterMap;

  const q4ChangeDate = { month: 0, day: 1 };
  const q1ChangeDate = { month: 3, day: 1 };
  const q2ChangeDate = { month: 6, day: 1 };
  const q3ChangeDate = { month: 9, day: 1 };

  if (
    DateTime.fromMillis(Date.now()).diff(DateTime.fromMillis(currentDate.getTime()), "month")
      .months < 3
  ) {
    // If portfolio created less than three months ago, logic below kind of fails. We instead check manually if
    // none of the change dates are between the registration month and today. If any of them are,
    // the main logic will take care of it.
    const regMonth = currentDate.getMonth();
    const todayMonth = new Date(Date.now()).getMonth();
    if (
      !(regMonth <= q4ChangeDate.month && todayMonth >= q4ChangeDate.month) &&
      !(regMonth <= q1ChangeDate.month && todayMonth >= q1ChangeDate.month) &&
      !(regMonth <= q2ChangeDate.month && todayMonth >= q2ChangeDate.month) &&
      !(regMonth <= q3ChangeDate.month && todayMonth >= q3ChangeDate.month)
    ) {
      return new Map() as YearQuarterMap;
    }
  }

  while (currentDate.getTime() <= Date.now()) {
    const month = currentDate.getMonth();
    if (q4ChangeDate.month === month) {
      if (!yearQuarterMap.has(currentDate.getFullYear() - 1)) {
        yearQuarterMap.set(currentDate.getFullYear() - 1, []);
      }
      // Since we step one month at a time we need to check if the quarter we want to add has already
      // been added
      if (!isQuarterAdded(Quarter.Q4, currentDate.getFullYear() - 1, yearQuarterMap))
        (yearQuarterMap.get(currentDate.getFullYear() - 1) as Quarter[]).push(Quarter.Q4);
    } else if (q1ChangeDate.month === month) {
      if (!yearQuarterMap.has(currentDate.getFullYear())) {
        yearQuarterMap.set(currentDate.getFullYear(), []);
      }
      if (!isQuarterAdded(Quarter.Q1, currentDate.getFullYear(), yearQuarterMap))
        (yearQuarterMap.get(currentDate.getFullYear()) as Quarter[]).push(Quarter.Q1);
    } else if (q2ChangeDate.month === month) {
      if (!yearQuarterMap.has(currentDate.getFullYear())) {
        yearQuarterMap.set(currentDate.getFullYear(), []);
      }
      if (!isQuarterAdded(Quarter.Q2, currentDate.getFullYear(), yearQuarterMap))
        (yearQuarterMap.get(currentDate.getFullYear()) as Quarter[]).push(Quarter.Q2);
    } else if (q3ChangeDate.month === month) {
      if (!yearQuarterMap.has(currentDate.getFullYear())) {
        yearQuarterMap.set(currentDate.getFullYear(), []);
      }
      if (!isQuarterAdded(Quarter.Q3, currentDate.getFullYear(), yearQuarterMap))
        (yearQuarterMap.get(currentDate.getFullYear()) as Quarter[]).push(Quarter.Q3);
    }

    currentDate.setTime(currentDate.setMonth(month + 1));
  }
  return yearQuarterMap;
}
