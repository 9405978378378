<template>
  <div class="card-header">
    <div class="card-header__section">
      <icon
        v-if="icon && !edit"
        fixed-width
        class="card-header__icon"
        :icon="icon"
      />
      <h2 :class="['card-header__title', { 'card-header__title--edit': edit }]">
        {{ title }}
      </h2>
    </div>
    <div class="card-header__section">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: Array,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    edit: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  &__icon {
    color: $nearly-black;
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  &__section {
    display: flex;
    align-items: center;
  }
  &__title {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.125rem;
    &--edit {
      font-size: 1rem;
      font-weight: 300;
    }
  }
}
</style>
