<template>
  <v-form
    ref="form"
    class="bankid-login__form"
    @submit.prevent="clickLoginPrimary"
  >
    <v-text-field
      v-if="isBankIdDev && !isInstagramOrFacebookIOS"
      v-model="personalIdentityNumber"
      type="tel"
      :placeholder="$t('personalIdentityNumberPlaceholder')"
      data-cy="authTextField"
      validate-on="blur"
      variant="outlined"
    />
    <common-button
      :color="buttonColor"
      data-cy="authButton"
      class="bankid-login__button"
      @click="clickLoginPrimary"
    >
      {{ isHandheld ? $t("mobileBankId") : $t("mobileBankIdOther") }}
    </common-button>
    <common-button
      class="bankid-login__button"
      secondary
      @click="clickLoginSecondary"
    >
      {{ isHandheld ? $t("bankIdOther") : $t("openBankId") }}
    </common-button>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isHandheld, isInstagramOrFacebookIOS } from "@/clients/bowser";
import { StartMode } from "@/store/bankid/types";
import CommonButton from "@/components/button.vue";

interface LoginCache {
  personalIdentityNumber: string;
}
const cacheKey = "loginCache";
export default defineComponent({
  emits: ["login"],
  components: {
    CommonButton
  },
  props: {
    buttonColor: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      personalIdentityNumber: "",
      isHandheld: isHandheld(),
      isInstagramOrFacebookIOS: isInstagramOrFacebookIOS()
    };
  },
  computed: {
    isBankIdDev(): boolean {
      return import.meta.env.VITE_ENV !== "production";
    }
  },
  created() {
    if (this.isBankIdDev) {
      const cache = localStorage.getItem(cacheKey);
      if (cache) {
        const cacheObj: LoginCache = JSON.parse(cache);
        if (cacheObj) {
          this.personalIdentityNumber = cacheObj.personalIdentityNumber;
        }
      }
    }
  },
  methods: {
    async clickLoginPrimary() {
      if (this.isHandheld) {
        this.$emit("login", {
          startMode: StartMode.AUTOSTART_TOKEN,
          personalIdentityNumber: this.personalIdentityNumber
        });
      } else {
        this.$emit("login", {
          startMode: StartMode.QR_CODE,
          personalIdentityNumber: this.personalIdentityNumber
        });
      }

      this.storeCache();
    },
    async clickLoginSecondary() {
      if (this.isHandheld) {
        this.$emit("login", {
          startMode: StartMode.QR_CODE,
          personalIdentityNumber: this.personalIdentityNumber
        });
      } else {
        this.$emit("login", {
          startMode: StartMode.AUTOSTART_TOKEN,
          personalIdentityNumber: this.personalIdentityNumber
        });
      }
      this.storeCache();
    },
    storeCache() {
      if (this.isBankIdDev) {
        const cache: LoginCache = {
          personalIdentityNumber: this.personalIdentityNumber
        };
        localStorage.setItem(cacheKey, JSON.stringify(cache));
      }
    }
  }
});
</script>

<style scoped lang="scss">
.bankid-login {
  &__form {
    display: flex;
    flex-direction: column;
    @include small-up {
      width: 18rem;
    }
    justify-content: center;
    text-align: center;
    :deep(.v-field) {
      font-size: 1.2rem;
      border-radius: 0.6rem;
    }
    :deep(input) {
      text-align: center;
    }
  }
  &__button {
    margin-bottom: 0.75rem;
  }
}
</style>
