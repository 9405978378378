<template>
  <v-dialog
    content-class="kyc-wrapper"
    :model-value="modelValue"
    :persistent="forcedToAnswer"
    @update:model-value="inputChange"
  >
    <v-card>
      <div class="kyc-dialog">
        <div class="kyc-dialog__header-text">
          {{ $t("kyc.yearly-kyc-dialog.header") }}
        </div>
        <div class="kyc-dialog__body-text">
          {{ $t("kyc.yearly-kyc-dialog.body1") }}
        </div>
        <br>
        <div class="kyc-dialog__body-text">
          {{ $t("kyc.yearly-kyc-dialog.body2") }}
        </div>
        <div class="kyc-dialog__button-wrapper">
          <CommonButton
            v-if="!forcedToAnswer"
            data-cy="closeKycDialog"
            secondary
            @click="close"
          >
            {{ $t("kyc.yearly-kyc-dialog.later") }}
          </CommonButton>
          <CommonButton
            v-else
            secondary
            data-cy="startKycUpdate"
            @click="$emit('signOut')"
          >
            {{ $t("signOut") }}
          </CommonButton>
          <CommonButton
            data-cy="startKycUpdate"
            @click="goToKyc"
          >
            {{ $t("start") }}
          </CommonButton>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonButton from "@/components/button.vue";
import { HIDE_YEARLY_KYC_UPDATE_KEY } from "@/config/general";

export default defineComponent({
  components: {
    CommonButton,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    forcedToAnswer: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:model-value", "signOut"],
  methods: {
    async goToKyc() {
      this.$emit("update:model-value", false);
      if (this.$store.state.userStore.currentLegalEntity.isNaturalPerson) {
        this.$router.push({ name: "kyc" });
      } else {
        this.$router.push({ name: "company-kyc" });
      }
    },
    inputChange(value: boolean) {
      if (!value) {
        this.close();
      }
    },
    close(): void {
      sessionStorage.setItem(HIDE_YEARLY_KYC_UPDATE_KEY, "true");
      this.$emit("update:model-value", false);
    },
  },
});
</script>

<style lang="scss" scoped>
.kyc-dialog {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;

  &__header-text {
    font-size: 1.125rem;
    color: rgba($nearly-black, 0.8);
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  &__body-text {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    font-weight: 300;
  }
  &__button-wrapper {
    display: grid;
    grid-row-gap: 1rem;
    width: 100%;
    margin-top: 1rem;
    @include medium-up {
      display: flex;
      justify-content: center;
      column-gap: 1rem;
    }
    @include small-only {
      button {
        width: 100%;
      }
    }
  }
}
:deep(.kyc-wrapper) {
  max-width: 30rem;
}
</style>
