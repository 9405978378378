<template>
  <div class="portfolio-selection">
    <question-text-section
      progress
      :heading="heading"
      :body="body"
    />

    <div class="portfolio-selection__content-wrapper">
      <v-card class="portfolio-selection__chart-wrapper">
        <risk-chart :selected-series="modelValue" />
      </v-card>

      <v-radio-group
        :model-value="modelValue"
        class="portfolio-selection__radio-group"
        @update:model-value="(e: any) => $emit('update:model-value', e)"
      >
        <v-radio
          v-for="n in [3, 2, 1, 0]"
          :key="n"
          class="portfolio-selection__radio"
          color="primary"
          :data-cy="`risk-radio${n}`"
          :true-value="n"
        >
          <template #label>
            <div class="portfolio-selection__radio-label">
              <div
                :class="`portfolio-selection__radio-legend
            portfolio-selection__radio-legend--${n}`"
              />
              {{ $t(`riskWillingnessOptions.${n}`) }}
            </div>
          </template>
        </v-radio>
      </v-radio-group>

      <v-tooltip
        location="bottom"
        :disabled="modelValue != undefined"
      >
        <template #activator="{ props }">
          <div class="portfolio-selection__button">
            <signup-button
              size-large
              block
              :disabled="modelValue === undefined"
              :color="buttonColor"
              @click="buttonClick()"
              v-bind="props"
            >
              {{ $t("looks_good") }}
            </signup-button>
            <v-btn
              height="3rem"
              width="3rem"
              color="#fff"
              class="portfolio-selection__information-button"
              @click="() => (showDialog = true)"
            >
              <icon
                :color="buttonColor"
                class="portfolio-selection__information-button-icon"
                :icon="['fas', 'info']"
              />
            </v-btn>
          </div>
        </template>
        <span>
          {{ $t("common-signup.questions.risk.portfolio-selection.select_to_continue") }}
        </span>
      </v-tooltip>
    </div>
    <side-dialog
      v-model="showDialog"
      :title="$t('riskHeading')"
    >
      <div class="portfolio-selection__side-dialog">
        <p class="portfolio-selection__side-dialog--heading">
          {{ $t("riskInformationHeading") }}
        </p>
        <p class="portfolio-selection__side-dialog--text">
          {{ $t("riskInformation1") }}
        </p>
        <p class="portfolio-selection__side-dialog--text">
          {{ $t("riskInformation2") }}
        </p>
        <p class="portfolio-selection__side-dialog--text">
          {{ $t("common-signup.questions.risk.portfolio-selection.riskInformation3") }}
        </p>
      </div>
    </side-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import SideDialog from "@/components/dialog/side-dialog.vue";
import SignupButton from "@/components/button.vue";
import { softBlue } from "@/styles/colors";
import { RiskWillingness, TiedAgent } from "@/clients";
import Question from "@/views/signup/templates/question";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import RiskChart from "./risk-chart.vue";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    QuestionTextSection,
    SideDialog,
    SignupButton,
    RiskChart
  },
  extends: Question,
  props: {
    modelValue: { type: Number, required: false, default: undefined },
    heading: { type: String },
    body: { type: String }
  },
  data: () => ({
    showDialog: false,
    softBlue
  }),
  computed: {
    tiedAgent(): TiedAgent {
      return this.$store.state.signupStore.tiedAgent;
    },
    buttonColor(): string | undefined {
      return this.$store.state.signupStore.tiedAgent?.color ?? softBlue;
    }
  },
  methods: {
    buttonClick() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.portfolio-selection {
  $text-max-width: 21.25rem;
  &__button {
    display: flex;
    max-width: $text-max-width;
    @include medium-up {
      grid-row: 2;
      grid-column: 2;
    }
  }
  &__information-button {
    font-size: 1.125rem;
    box-shadow: 0 0.1875rem 0.75rem #00000014;
    border-radius: 0.5rem 0 0 0.5rem;
    transition: background-color 0.1s ease-in-out;
    min-width: 3rem !important;
    &--margin-bottom {
      margin-bottom: 4.25rem;
    }
    @include small-down {
      position: absolute;
      right: 0;
      bottom: 6vh;
    }
    @include medium-up {
      margin-left: 1rem;
      border-radius: 0.5rem;
    }
    &:before {
      background: transparent;
      transition: all 0.1s ease-in-out;
    }
    &:hover:before {
      @include cursorHover {
        background: black;
      }
    }
    &.focus-visible {
      box-shadow:
        0 0 0.0625rem 0.0625rem $aqua,
        0 0.125rem 0.875rem scale-color($aqua, $alpha: -60%);
    }
  }
  &__chart-wrapper {
    width: 100%;
    padding: 0.5rem;
    @include medium-up {
      position: relative;
      grid-row: 1/-1;
      grid-column: 1;
      margin-bottom: 0;
      height: 18rem;
    }
  }
  &__content-wrapper {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    margin: 6vh auto;
    justify-items: center;
    @include medium-up {
      width: 49rem;
      grid-template-columns: 34rem auto;
      grid-template-rows: 6fr auto auto;
      grid-column-gap: 3rem;
    }
  }
  &__radio {
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-right: 0;
  }
  &__radio-group {
    width: 100%;
    height: 100%;
    margin: 2rem 0 2rem 0;
    max-width: $text-max-width;
    @include medium-up {
      margin: 0 0 2rem 0;
      grid-row: 1;
      grid-column: 2;
    }
  }
  &__radio-label {
    margin-right: 0.75rem;
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    color: $nearly-black;
    font-family: $heading-font-family;
  }
  &__radio-legend {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    margin-right: 1.25rem;
    &--0 {
      background-color: $soft-orange;
    }
    &--1 {
      background-color: $aqua;
    }
    &--2 {
      background-color: $soft-blue;
    }
    &--3 {
      background-color: $grayish-blue;
    }
  }
  :deep(.v-input--selection-controls__input) {
    margin-right: 0;
  }
  :deep(.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child)) {
    margin-bottom: 1.5rem;
  }
  :deep(.v-input__control) {
    width: 100%;
  }
  :deep(.v-label) {
    flex-grow: 1;
  }
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
}
</style>
