<template>
  <single-choice-template
    class="portfolio-management"
    :alternatives="questionAlternatives"
    @click="goToNext"
  >
    <question-text-section
      progress
      :heading="$t('common-signup.questions.risk.portfolio-management.heading')"
      :body="$t('common-signup.questions.risk.portfolio-management.body1')"
    />
    <question-text-section :body="$t('common-signup.questions.risk.portfolio-management.body2')" />
  </single-choice-template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";

export default defineComponent({
  components: {
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    questionAlternatives() {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    }
  },
  methods: {
    goToNext(): void {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>

<style lang="scss" scoped>
.portfolio-management {
  height: 100%;
  width: 100%;
  &__link-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 21.25rem;
    margin: 0 auto;
    @include medium-up {
      max-width: 44.5rem;
    }
  }
  &__link {
    text-decoration: underline;
    cursor: pointer;
    margin-top: 2.425rem;
  }
}
</style>
