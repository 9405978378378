<template>
  <div class="messages">
    <section-header
      v-if="$vuetify.display.mdAndUp"
      :header="$t('messages')"
    >
      <template #icon>
        <icon
          :icon="['fal', 'envelope']"
          size="lg"
        />
      </template>
    </section-header>
    <div
      v-if="showEmptyState"
      class="messages__empty-state"
    >
      <icon
        :icon="['fal', 'info-circle']"
        class="messages__empty-state-icon"
      />
      <h2 class="messages__empty-state-heading">
        {{ $t("messages") }}
      </h2>
      <p class="messages__empty-state-description">
        {{ $t("messages.messages.emptyStateDescription") }}
      </p>
    </div>
    <template v-else>
      <v-data-table
        v-if="$vuetify.display.mdAndUp"
        ref="messagesDataTable"
        :items="messagesList"
        :headers="desktopHeaders"
        :page="currentPage"
        :loading="showMessageLoader"
        hide-default-footer
        item-key="brickId"
        @current-items="() => changePagination()"
        @update:sort-by="() => (($refs as any).paginationFooter.page = 1)"
      >
        <template #bottom></template>
        <template #body="{ items }">
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="['messages__table-row', { 'messages__table-row--bold': !item.isRead }]"
            @click="handleClick(item)"
          >
            <td class="messages__table-data">
              {{ item.subject }}
            </td>
            <td class="messages__table-data">
              {{ item.portfolio }}
            </td>
            <td class="messages__table-data">
              {{ formatDate(item.date) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-data-table
        v-else
        :items="messagesList"
        :headers="headers"
        :page="currentPage"
        :loading="showMessageLoader"
        :mobile-breakpoint="0"
        item-key="brickId"
      >
        <template #bottom></template>
        <template #body="{ items }">
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="['messages__table-row', { 'messages__table-row--bold': !item.isRead }]"
            @click="handleClick(item)"
          >
            <td class="messages__table-data">
              {{ item.subject }}
            </td>
            <td class="messages__table-data">
              {{ formatDate(item.date) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="messages__footer">
        <pagination
          v-if="messagesList"
          ref="paginationFooter"
          v-model="currentPage"
          :pagination-length="paginationLength"
        />
      </div>
    </template>
    <messages-dialog
      v-model="showDialog"
      :message="selectedRow"
      class="messages_dialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DateTime } from "luxon";
import { NavigationMutation } from "@/store/navigation/mutations";
import SectionHeader from "@/components/section-header.vue";
import { Message } from "@/clients/index";
import { UserAction } from "@/store/user/action-definitions";
import Pagination from "../transactions/pagination.vue";
import MessagesDialog from "./dialog.vue";

export default defineComponent({
  head() {
    return {
      title: this.$t("messages")
    };
  },
  components: { Pagination, SectionHeader, MessagesDialog },
  data: () => ({
    selectedRow: undefined as any,
    showDialog: false,
    currentPage: 1,
    paginationLength: 1,
    selectedRows: [] as Message[],
    tableSortBy: "date",
    tableSortDesc: true
  }),
  computed: {
    showEmptyState(): boolean {
      return this.messagesList && this.messagesList.length === 0 && !this.showMessageLoader;
    },
    showMessageLoader(): boolean {
      const { loadingMessages } = this.$store.state.userStore;
      return loadingMessages;
    },
    messagesList(): Array<Message> {
      const { messages } = this.$store.state.userStore;
      return messages;
    },
    desktopHeaders(): Array<any> {
      return [
        {
          title: this.$t("subject"),
          key: "subject",
          class: "messages__header-text",
          sortable: true
        },
        {
          title: this.$t("portfolio"),
          key: "portfolio",
          class: "messages__header-text",
          sortable: true
        },
        {
          title: this.$t("date"),
          key: "date",
          class: "messages__header-text",
          sortable: true
        }
      ];
    },
    headers(): Array<any> {
      return [
        {
          title: this.$t("subject"),
          key: "subject",
          class: "messages__header-text",
          sortable: true
        },
        {
          title: this.$t("date"),
          key: "date",
          class: "messages__header-text",
          sortable: true
        }
      ];
    }
  },
  watch: {
    messagesList: {
      immediate: true,
      handler(newValue: Array<Message> | undefined) {
        if (newValue && newValue.length > 0) {
          // Cant be used as computed prop atm since we are manipulating the value inside ChangePagination aswell
          this.paginationLength = Math.ceil(newValue.length / 10);
        }
      }
    }
  },
  mounted() {
    this.$store.commit(NavigationMutation.setBackRoute, { name: "overview" });
    this.$store.commit(NavigationMutation.setMobileHeading, this.$t("messages"));
  },
  methods: {
    formatDate(date: Date | undefined): string {
      if (!date) {
        return "";
      }
      return DateTime.fromISO(date.toISOString()).toFormat("yyyy.MM.dd");
    },
    changePagination() {
      if (this.$refs.messagesDataTable) {
        // This solution is in order to retrieve all items of the table. Existing feature request: https://github.com/vuetifyjs/vuetify/issues/8731.
        const tableLength = (this.$refs.messagesDataTable as any).$children[0].filteredItems.length;
        this.paginationLength = tableLength > 0 ? Math.ceil(tableLength / 10) : 1;
      }
    },
    closeMessages(): void {
      this.$router.push({ name: "portfolio", params: { id: this.$route.params.id as string } });
    },
    async handleClick(row: Message): Promise<void> {
      if (row.isRead === false) {
        // We only update isRead, so if we have already read the message there
        // is no need to perform any update
        const updatedMessages = this.messagesList.slice();
        const matchingMessage = updatedMessages.find((m) => m.brickId === row.brickId);
        if (matchingMessage) {
          matchingMessage.isRead = true;
          this.$store.dispatch(UserAction.updateMessagesRead, updatedMessages);
        }
      }
      this.showDialog = true;
      this.selectedRow = row;
    }
  }
});
</script>

<style lang="scss" scoped>
.messages {
  max-width: 71rem;
  width: 100%;
  @include medium-up {
    min-width: 47.1875rem;
  }
  @include small-down {
    padding: 1rem;
  }
  &__dialog {
    width: 100%;
    height: 100%;
  }
  &__data-manipulation-wrapper {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 1rem;
    align-items: center;
    @include small-up {
      grid-template-columns: auto 1fr 1fr auto;
    }
  }
  &__header-text {
    color: $nearly-black;
    border-bottom: thin solid #fafafa !important;
    font-size: 0.875rem !important;
    font-weight: 300;
  }
  &__empty-state {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem 1rem 1rem;
    text-align: center;
  }
  &__empty-state-heading {
    color: $soft-blue;
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  &__empty-state-icon {
    color: $soft-blue;
    font-size: 1.5rem;
    margin-bottom: 0.375rem;
  }
  &__empty-state-description {
    font-weight: 300;
  }
  &__footer {
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
    justify-content: center;
  }
  &__table-row {
    background-color: white;
    box-shadow: 0 0.1875rem 1.25rem rgba(96, 96, 96, 0.06);
    position: relative;
    &--bold {
      font-weight: bold;
    }
  }
  &__table-data {
    padding: 0 1rem !important;
    border-bottom: thin solid white !important;
    // & > {
    //   padding-left: 1rem !important;
    // }
    @include small-up {
      &:nth-child(1) {
        width: 80%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 5%;
      }
    }

    @include medium-up {
      &:nth-child(1) {
        width: 60%;
        max-width: 1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 5%;
      }
    }
  }
  &__title-text {
    font-weight: 500;
  }
  &__data-text {
    opacity: 0.8;
    &--underline {
      text-decoration: underline;
    }
  }
  .v-data-table-header > tr {
    background-color: transparent;
  }
  .v-data-table-header__icon {
    visibility: hidden;
  }
  .v-data-table__wrapper {
    overflow-x: hidden !important;
  }
  .v-data-table {
    background: transparent;
  }
  table {
    border-collapse: separate;
  }
  tbody {
    tr {
      cursor: pointer;
    }
    tr:nth-child(even) {
      background-color: transparent;
    }
    tr:nth-child(odd) {
      background-color: white;
    }
  }
  .v-data-table__progress {
    // Fixes progress bar not appearing for some reason.
    // Its height is set to auto with !important so we need to override it
    background-color: transparent !important;
    & > th {
      height: 3px !important;
    }
  }
}
:deep(.v-data-table-header__sort-icon) {
  visibility: hidden !important;
}
:deep(.v-data-table__th--sorted) {
  font-weight: 500 !important;
  opacity: 1 !important;
}
:deep(.v-data-table__th--sortable) {
  opacity: 0.67;
  &:hover {
    opacity: 1;
  }
}
</style>
