<template>
  <div class="choose-amount">
    <single-choice-template
      :alternatives="alternatives"
      :information="true"
      :footer-message="$t('firstDepositFooter')"
      @click="goToNext"
      @showInformation="() => (showDialog = true)"
    >
      <question-text-section
        :heading="$t('create-portfolio.questions.account.first-deposit.heading')"
        :body="$t('create-portfolio.questions.account.first-deposit.body')"
        progress
      />
      <v-form ref="form" class="choose-amount__form" @submit.prevent="goToNext">
        <v-text-field
          v-model="displayFirstDeposit"
          type="tel"
          variant="underlined"
          :label="firstDepositLabel"
          :suffix="currencyLabel"
          :rules="[rules.number, rules.leadingZeros, rules.validInitialDeposit]"
          :placeholder="$t('sum')"
          data-cy="first-deposit-value"
          class="choose-amount__form-value"
          validate-on="blur"
          persistent-placeholder
        />

        <v-slide-y-transition>
          <p v-if="tooHighFirstDeposit" class="choose-amount__form--custom-error">
            {{ $t("tooHighFirstDeposit") }}
          </p>
        </v-slide-y-transition>
        <input tabindex="-1" class="choose-amount__form--hidden" type="submit" value="" />
      </v-form>

      <side-dialog v-model="showDialog" :title="$t('howToSave')">
        <div class="choose-amount__side-dialog">
          <div v-for="(section, i) in informationAlternatives" :key="i">
            <p class="choose-amount__side-dialog--heading">
              {{ section.title }}
            </p>
            <p
              v-for="(paragraph, index) in section.paragraph"
              :key="index"
              class="choose-amount__side-dialog--text"
            >
              {{ paragraph.information }}
            </p>
          </div>
        </div>
      </side-dialog>
    </single-choice-template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupMutation } from "@/store/signup/mutations";
import SideDialog from "@/components/dialog/side-dialog.vue";
import { Market } from "@/clients";
import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import { SingleChoice } from "../../../../types/signup";

type Paragraph = {
  information: string;
};
type InformationAlternative = {
  title: string;
  paragraph: Array<Paragraph>;
};

export default defineComponent({
  components: {
    QuestionTextSection,
    InformationTextSection,
    SingleChoiceTemplate,
    SideDialog
  },
  extends: Question,
  data() {
    return {
      showDialog: false,
      rules: {
        leadingZeros: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^(0|[1-9][0-9]*)$/;
          return pattern.test(value) || this.$t("notValidDeposit");
        },
        number: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("notValidDeposit");
        },
        validInitialDeposit: (stringValue: string) => {
          stringValue = stringValue.replace(/\s/g, "");
          const value = parseInt(stringValue, 10);
          const valid = value === 0 || value >= 500;
          return valid || this.$t("notValidInitialDeposit");
        }
      }
    };
  },
  computed: {
    marketFocus(): Market {
      return this.$store.state.signupStore.marketFocus;
    },
    alternatives(): Array<SingleChoice> {
      return [
        {
          text: this.$t("next"),
          key: "next"
        }
      ];
    },
    informationAlternatives(): InformationAlternative[] {
      return [
        {
          title: this.$t("firstDeposit"),
          paragraph: [{ information: this.$t("firstDepositInformation") }]
        }
      ];
    },
    currencyLabel(): string {
      return this.$t("currency");
    },
    firstDepositLabel(): string {
      return this.$t("firstDeposit");
    },
    displayFirstDeposit: {
      get(): string {
        if (this.firstDeposit !== undefined && this.firstDeposit !== null) {
          return this.firstDeposit.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        this.firstDeposit = parseInt(value, 10);
      }
    },
    firstDeposit: {
      get(): number | undefined {
        return this.$store.state.signupStore.firstDeposit;
      },
      set(value: number) {
        let submittedValue = 0;
        if (value) {
          submittedValue = value;
        }
        this.$store.commit(SignupMutation.setFirstDeposit, submittedValue);
      }
    },
    tooHighFirstDeposit(): boolean {
      // This is separate from Vuetify's validation rules since fulfilling this rule is not
      // required to move on. Validating only specific rules is not possible currently, but
      // seems to be planned for Vuetify 3 (https://github.com/vuetifyjs/vuetify/issues/8698)
      const stringValue = this.displayFirstDeposit.replace(/\s/g, "");
      const value = parseInt(stringValue, 10);
      const invalid = value > this.$store.getters.totalAssets;
      return invalid;
    }
  },
  methods: {
    removeWhiteSpace(value: string) {
      return value.replace(/\s/g, "");
    },
    async goToNext() {
      const result = await (this.$refs.form as any).validate();
      if (result.valid) {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.choose-amount {
  width: 100%;
  height: 100%;
  &__side-dialog {
    padding: 1rem;
    &--heading {
      font-size: 1rem;
      font-weight: 600;
    }
    &--description {
      font-weight: 300;
    }
  }
  &__form-label {
    margin: 0;
    text-align: right;
    font-size: 1.25rem;
    &--align-left {
      text-align: left;
      margin-bottom: 0px;
      font-size: 1.25rem;
    }
  }
  &__form-value {
    width: 100%;
    max-width: 21rem;
    height: auto;
    font-weight: bold;
    margin: 0;
    font-family: $heading-font-family;
  }
  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;
    &--hidden {
      display: none;
    }
    :deep(.v-input input) {
      color: $soft-blue;
      min-height: 3.125rem !important;
      // padding: 0;
      // This is needed in order for the text not to clip on Safari.
      max-height: inherit !important;
      @include medium-up {
        text-align: center;
      }
    }
    :deep(.v-field__input) {
      font-size: 1.5rem;
      @include medium-up {
        font-size: 2.5rem;
        padding-top: 0.2rem !important;
      }
    }
    &--custom-error {
      grid-column: 1;
      @include medium-up {
        grid-column: 2;
      }
      color: red;
    }
    :deep(.v-input .v-label) {
      font-weight: normal;
    }
    :deep(.v-text-field__suffix) {
      opacity: 0.63;
      font-weight: normal;
      font-size: 1rem;
      @include medium-up {
        padding-top: 1.5rem !important;
      }
    }
    :deep(.v-text-field__details) {
      font-weight: normal;
    }
  }
}
</style>
