import { BoardMember, Role } from "@/clients";
import * as Sentry from "@sentry/vue";
import { SeverityLevel } from "@sentry/types";

export interface BoardMemberWith {
  role: Role;
}

export default function getAlternativePrincipalRole(board: BoardMember[]): Role | undefined {
  let roleOfAlternativePrincipal: Role | undefined;

  const hasChairman = board.some((b: BoardMember) => b.roles?.some((y) => y === Role.Chairman));
  if (hasChairman) {
    roleOfAlternativePrincipal = Role.Chairman;
  } else if (board.length > 1) {
    const hasExactlyOneCommissioner =
      board.filter((b: BoardMember) => b.roles?.some((y) => y === Role.Commissioner)).length === 1;
    if (hasExactlyOneCommissioner) {
      roleOfAlternativePrincipal = Role.Commissioner;
    }
  } else if (board.length === 1) {
    Sentry.captureMessage(
      "[Alert] No chairman and more than two board members",
      "info" as SeverityLevel
    );
  }
  return roleOfAlternativePrincipal;
}
