<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref, watchEffect } from "vue";
import { useSignupType } from "../../signup-type";
import SingleChoice from "@/views/signup/templates/single-choice.vue";
import { useQuestion } from "@/views/signup/templates/question-comp";
import QuestionTextSection from "@/views/signup/question-text-section.vue";
import type { NestedCheckbox, NestedCheckboxHeader } from "@/components/nested-checkboxes.vue";
import NestedCheckboxes from "@/components/nested-checkboxes.vue";
import type { RootState } from "@/types";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupFlowModificationType } from "@/config/signup-modification";
import SustainableChoiceSideDialog from "@/components/sustainable-choice-side-dialog.vue";
import { usePaiStats } from "@/composables/pai-stats";

const store = useStore();
const router = useRouter();
const { t } = useI18n();
useQuestion(store, router);
const { maybeTextToThirdPerson } = useSignupType();
const showDialog = ref(false);

const { paiStats } = usePaiStats();

const carbonNested = computed<NestedCheckbox[]>(() => [
  {
    index: 1,
    heading: "PAI 1",
    body1: "Utsläpp av växthusgaser.",
    body2: formatPaiDiscardedPercentage("1"),
    checked: store.state.signupStore.esgPais.pai1,
  },
  {
    index: 2,
    heading: "PAI 2",
    body1: "Koldioxidavtryck.",
    body2: formatPaiDiscardedPercentage("2"),
    checked: (store.state as RootState).signupStore.esgPais.pai2,
  },
  {
    index: 3,
    heading: "PAI 3",
    body1: "Utsläpp av västhusgaser i relation till storlek på företagets intäkter.",
    body2: formatPaiDiscardedPercentage("3"),
    checked: (store.state as RootState).signupStore.esgPais.pai3,
  },
  {
    index: 4,
    heading: "PAI 4",
    body1: "Exponering mot fossila bränslen.",
    body2: formatPaiDiscardedPercentage("4"),
    checked: (store.state as RootState).signupStore.esgPais.pai4,
  },
  {
    index: 8,
    heading: "PAI 8 (för lite data)",
    body1: "Utsläpp i vatten.",
    disabled: true,
  },
  {
    index: 9,
    heading: "PAI 9",
    body1: "Farligt avfall och radioaktivt avfall.",
    body2: formatPaiDiscardedPercentage("9"),
    checked: (store.state as RootState).signupStore.esgPais.pai9,
  },
]);
const resourceNested = computed<NestedCheckbox[]>(() => [
  {
    index: 5,
    heading: "PAI 5",
    body1: "Andel icke-förnybar energiförbrukning och energiproduktion.",
    body2: formatPaiDiscardedPercentage("5"),
    checked: (store.state as RootState).signupStore.esgPais.pai5,
  },
  {
    index: 6,
    heading: "PAI 6 (för lite data)",
    body1: "Energiförbrukningsintensitet med stor klimatpåverkan.",
    body2: formatPaiDiscardedPercentage("6"),
    disabled: true,
  },
]);

const biologicalNested = computed<NestedCheckbox[]>(() => [
  {
    index: 7,
    heading: "PAI 7",
    body1: "Verksamhet som negativt påverkar områden med känslig biologisk mångfald.",
    body2: formatPaiDiscardedPercentage("7"),
    checked: (store.state as RootState).signupStore.esgPais.pai7,
  },
]);
const governanceNested = computed<NestedCheckbox[]>(() => [
  {
    index: 10,
    heading: "PAI 10",
    body1: "Brott mot FN:s globala överenskommelse och OECD:s riktlinjer för multinationella företag.",
    body2: formatPaiDiscardedPercentage("10"),
    checked: (store.state as RootState).signupStore.esgPais.pai10,
  },
  {
    index: 11,
    heading: "PAI 11",
    body1: "Brist på styrdokument för att granska efterlevnad av bland annat mänskliga rättigheter.",
    body2: formatPaiDiscardedPercentage("11"),
    checked: (store.state as RootState).signupStore.esgPais.pai11,
  },
  {
    index: 12,
    heading: "PAI 12 (data saknas)",
    body1: "Ojusterade löneklyftor mellan könen.",
    disabled: true,
  },
  {
    index: 13,
    heading: "PAI 13",
    body1: "Jämnare könsfördelning i styrelserna.",
    body2: formatPaiDiscardedPercentage("13"),
    checked: (store.state as RootState).signupStore.esgPais.pai13,
  },
  {
    index: 14,
    heading: "PAI 14",
    body1: "Exponering mot kontroversiella vapen.",
    body2: formatPaiDiscardedPercentage("14"),
    checked: (store.state as RootState).signupStore.esgPais.pai14,
  },
]);

const carbonHeader = computed<NestedCheckboxHeader>(() => {
  return {
    heading: "Koldioxid & utsläpp",
    body1: formatPais(carbonNested.value),
    body2: formatDiscardedPercentage("carbon"),
  };
});

const resourcesHeader = computed(() => {
  return {
    heading: "Resurseffektivitet",
    body1: formatPais(resourceNested.value),
    body2: formatDiscardedPercentage("resources"),
  };
});
const biologicalHeader = computed(() => {
  return {
    heading: "Biologisk mångfald",
    body1: formatPais(biologicalNested.value),
    body2: formatDiscardedPercentage("biological"),
  };
});

const governanceHeader = computed(() => {
  return {
    heading: "Socialt ansvar & rättigheter",
    body1: formatPais(governanceNested.value),
    body2: formatDiscardedPercentage("governance"),
  };
});

function clickNested(index: number, val: boolean | undefined) {
  store.commit(SignupMutation.setEsgPai, { index, value: val ?? undefined });
}

function formatPais(nestedCheckboxes: NestedCheckbox[]) {
  const selectablePais = nestedCheckboxes.filter(x => !x.disabled);
  if (selectablePais.length > 0) {
    return `PAI ${selectablePais.map(x => x.index).join(", ")}`;
  }

  return undefined;
}
function formatPaiDiscardedPercentage(pai: string) {
  if (paiStats?.value?.pai_stats) {
    const paiStat = paiStats.value.pai_stats.find(x => x.name && x.name.split("_")[1] === pai);
    if (paiStat) {
      return `${paiStat.discarded_percentage.toLocaleString("sv-SE")} % av bolagen utesluts`;
    }
  }

  return "-";
}
function formatDiscardedPercentage(paiCategory: string) {
  if (paiStats?.value?.category_stats) {
    const paiStat = paiStats.value.category_stats.find(x => x.name === paiCategory);
    if (paiStat) {
      return `${paiStat.discarded_percentage.toLocaleString("sv-SE")} % av bolagen utesluts`;
    }
  }
  return "-";
}

async function goToNext() {
  const someSelected
    = carbonNested.value.some(x => x.checked)
    || resourceNested.value.some(x => x.checked)
    || biologicalNested.value.some(x => x.checked)
    || governanceNested.value.some(x => x.checked);

  await store.dispatch(SignupNavigationAction.modifySignupFlow, {
    include: !someSelected,
    modification: SignupFlowModificationType.SustainablePortfolioVerify,
  });
  store.dispatch(SignupNavigationAction.goToNextStep, router);
}
</script>

<template>
  <SingleChoice
    :alternatives="[{ text: t('next'), key: 'next' }]"
    information
    allow-content-overflow
    max-height="60rem"
    @click="goToNext"
    @show-information="showDialog = true"
  >
    <QuestionTextSection
      progress
      :heading="maybeTextToThirdPerson('Vilka hållbarhetsområden vill du ta hänsyn till?')"
      :body="maybeTextToThirdPerson('Du kan välja flera kategorier, eller enskilda PAI:s under varje kategori.')"
    />
    <div class="sustainable-choice__container">
      <NestedCheckboxes
        style="margin-top: 1rem"
        :header="carbonHeader"
        :nested="carbonNested"
        @click-nested="clickNested"
      >
        <template #body1="{ text }">
          <div
            v-if="text"
            class="sustainable-choice__pai-body"
          >
            {{ text }}
          </div>
        </template>
      </NestedCheckboxes>
      <NestedCheckboxes
        style="margin-top: 1rem"
        :header="resourcesHeader"
        :nested="resourceNested"
        @click-nested="clickNested"
      >
        <template #body1="{ text }">
          <div
            v-if="text"
            class="sustainable-choice__pai-body"
          >
            {{ text }}
          </div>
        </template>
      </NestedCheckboxes>
      <NestedCheckboxes
        style="margin-top: 1rem"
        :header="biologicalHeader"
        :nested="biologicalNested"
        @click-nested="clickNested"
      >
        <template #body1="{ text }">
          <div
            v-if="text"
            class="sustainable-choice__pai-body"
          >
            {{ text }}
          </div>
        </template>
      </NestedCheckboxes>
      <NestedCheckboxes
        style="margin-top: 1rem"
        :header="governanceHeader"
        :nested="governanceNested"
        @click-nested="clickNested"
      >
        <template #body1="{ text }">
          <div
            v-if="text"
            class="sustainable-choice__pai-body"
          >
            {{ text }}
          </div>
        </template>
      </NestedCheckboxes>
      <SustainableChoiceSideDialog v-model="showDialog" />
    </div>
  </SingleChoice>
</template>

<style lang="scss" scoped>
.sustainable-choice {
  height: 100%;
  &__container {
    margin: 1rem auto 0 auto;
    width: 100%;
    @include medium-up {
      width: 21.75rem;
    }
  }
  &__pai-body {
    color: $soft-blue--darker;
    font-size: "0.9375rem";
    font-weight: 500;
  }
}
</style>
