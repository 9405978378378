<template>
  <tax-liable-other @next="next" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TaxLiableOther from "@/views/common-signup/questions/aml/tax-liable-other.vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";

export default defineComponent({
  components: { TaxLiableOther },
  methods: {
    next() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    }
  }
});
</script>
