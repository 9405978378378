<template>
  <time-to-withdraw-warning @quit="quit" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TimeToWithdrawWarning from "@/views/common-signup/questions/economy/time-to-withdraw-warning.vue";

export default defineComponent({
  components: { TimeToWithdrawWarning },
  methods: {
    quit() {
      this.$router.push({ name: "login" });
    }
  }
});
</script>
