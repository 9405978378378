<script setup lang="ts">
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import QuestionTextSection from "../../../signup/question-text-section.vue";
import SingleChoice from "../../../signup/templates/single-choice.vue";
import { useSignupType } from "../../signup-type";
import { useQuestion } from "@/views/signup/templates/question-comp";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SignupFlowModificationType } from "@/config/signup-modification";
import { SignupMutation } from "@/store/signup/mutations";

const router = useRouter();
const store = useStore();
const { maybeTextToThirdPerson } = useSignupType();

useQuestion(store, router);

async function next(key: string) {
  // if (this.tiedAgent) {
  //   store.dispatch(SignupNavigationAction.modifySignupFlow, {
  //     modification: SignupFlowModificationType.SustainablePortfolioTiedAgent,
  //     include: minimizeImpact
  //   });
  // } else {

  await store.dispatch(SignupNavigationAction.modifySignupFlow, {
    modification: SignupFlowModificationType.SustainablePortfolio,
    include: key === "yes",
  });
  store.commit(
    SignupMutation.setTakeSustainabilityIntoAccount,
    key === "yes",
  );
  store.dispatch(SignupNavigationAction.goToNextStep, router);
}
</script>

<template>
  <SingleChoice
    :alternatives="[
      {
        text: $t('no'),
        key: 'no',
      },
      { text: $t('yes'), key: 'yes' },
    ]"
    @click="next"
  >
    <QuestionTextSection
      :heading="maybeTextToThirdPerson(`Vill du spara mer hållbart?`)"
      :body="maybeTextToThirdPerson('Detta innebär att det kommer finnas färre bolag att välja på till din portfölj.')"
      :body2="maybeTextToThirdPerson('Detta kan påverka din avkastning både positivt och negativt. Det är viktigt att du är okej med ditt val även om det skulle innebära att du får sämre avkastning än någon som har valt annorlunda.')"
      progress
    />
  </SingleChoice>
</template>
