type PaiKey = "overview" | "carbon" | "resources" | "bioDiversity" | "governance";

export interface PaiCategory {
  title: string;
  icon: string[];
  key: PaiKey;
  pais: string[];
}

export const paiConfiguration: PaiCategory[] = [
  {
    title: "Koldioxid & utsläpp",
    icon: ["fal", "cloud"],
    key: "carbon",
    pais: ["1", "2", "3", "4", "8", "9"],
  },
  {
    title: "Resurseffektivitet",
    icon: ["fal", "recycle"],
    key: "resources",
    pais: ["5"],
  },
  {
    title: "Biologisk mångfald",
    icon: ["fal", "kiwi-bird"],
    key: "bioDiversity",
    pais: ["7"],
  },
  {
    title: "Socialt ansvar & rättigheter",
    icon: ["fal", "users"],
    key: "governance",
    pais: ["10", "11", "12", "13", "14"],
  },
];

export const disabledPais = ["8", "12"];
