const wait = (pollingDelay: number) =>
  new Promise((resolve) => setTimeout(() => resolve(true), pollingDelay));

class TimeoutError { }

export async function createPollingPromise(
  taskToPoll: () => Promise<boolean>,
  pollingDelay: number = 2000,
  totalTimeWaited: number = 0,
  retryCount: number = 0,
  shouldRetry: boolean = true,
  maxWaitTimeInSeconds: number = 180
): Promise<boolean> {
  try {
    const finished = await taskToPoll();

    if (finished) {
      return true;
    }
    if (totalTimeWaited > maxWaitTimeInSeconds * 1000) {
      throw new TimeoutError();
    }

    await wait(pollingDelay);
    return createPollingPromise(
      taskToPoll,
      pollingDelay,
      totalTimeWaited + pollingDelay,
      0,
      shouldRetry,
      maxWaitTimeInSeconds
    );
  } catch (e) {
    if (e instanceof TimeoutError) {
      throw e;
    }
    // Retry count of three is arbitrarily chosen. Should start working in three tries if the cause is a broken HTTP connection.
    if (shouldRetry) {
      if (retryCount !== undefined && retryCount > 3) {
        throw e;
      }
    } else {
      throw e;
    }
    // If we return to browser at a bad time (not sure when this is), collect sometimes fails because it loses the
    // HTTP connection. In such cases, we want to try again.
    await wait(pollingDelay);
    return createPollingPromise(
      taskToPoll,
      pollingDelay,
      totalTimeWaited + pollingDelay,
      retryCount + 1,
      shouldRetry,
      maxWaitTimeInSeconds
    );
  }
}
