<template>
  <div class="choices">
    <h2 class="choices__heading">
      {{ $t("your_choices") }}
    </h2>

    <span class="choices__key">
      {{ $t("ethicalSelection") }}
    </span>
    <span class="choices__value">
      {{ numberOfExcludedSectors }} {{ $t("of") }} {{ numberOfSectors }}
    </span>

    <span class="choices__key">
      {{ $t("marketFocus") }}
    </span>
    <span class="choices__value">
      {{ marketFocusString }}
    </span>

    <span class="choices__key">
      {{ $t("focus") }}
    </span>
    <span class="choices__value">
      {{ strategyFocusString }}
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Market, StrategyFocus } from "@/clients";

export default defineComponent({
  props: {
    marketFocus: { type: Number, required: false },
    focus: { type: Number, required: false },
    includedSectors: { type: Object, required: true }
  },
  computed: {
    numberOfSectors(): number {
      return Object.keys(this.includedSectors).length;
    },
    numberOfExcludedSectors(): number {
      return Object.values(this.includedSectors).filter((isIncluded) => !isIncluded).length;
    },
    marketFocusString(): string {
      if (this.marketFocus !== undefined) {
        return this.$t(`marketFocuses.${Market[this.marketFocus]}`);
      }
      return "-";
    },
    strategyFocusString(): string {
      if (this.focus !== undefined) {
        return this.$t(`focuses.${StrategyFocus[this.focus]}`);
      }
      return "-";
    }
  }
});
</script>

<style lang="scss" scoped>
.choices {
  display: grid;
  align-content: space-between;
  grid-template-columns: 1fr auto;
  grid-template-rows: repeat(4, auto);
  grid-column-gap: 1rem;
  font-family: $heading-font-family;
  line-height: 1rem;
  padding: 0.75rem;
  grid-row-gap: 0.5rem;
  @include medium-up {
    grid-row-gap: 0;
    padding: 1.625rem;
    grid-column-gap: 3rem;
  }
  &__heading {
    grid-column: 1 / -1;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }
  &__key {
    font-weight: 300;
  }
  &__value {
    text-align: right;
  }
}
</style>
