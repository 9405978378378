import { birthDateFromPersonalIdentityNumber } from "./birthdate";

/* Allowed formats:
 * - "YYMMDDXXXX"
 * - "YYMMDD-XXXX"
 * - "YYMMDD+XXXX"
 * - "YYYYMMDDXXXX"
 * - "YYYYMMDD-XXXX"
 * - YYMMDDXXXX
 *
 * Returns undefined or the input formatted as "YYYYMMDDXXXX"
 */
export function formatPersonalIdentityNumber(
  personalIdentityNumber: string | undefined
): string | undefined {
  if (!personalIdentityNumber) {
    return undefined;
  }
  const birthdate = birthDateFromPersonalIdentityNumber(personalIdentityNumber);
  if (!birthdate) {
    return undefined;
  }
  const month = birthdate.getMonth() + 1;
  const day = birthdate.getDate();

  return `${birthdate.getFullYear()}${month < 10 ? `0${month}` : month}${
    day < 10 ? `0${day}` : day
  }${personalIdentityNumber.slice(-4)}`;
}

/*
  Expects a personal identity number of length 12.
*/
export function addDashToPersonalIdentityNumber(personalIdentityNumber: string) {
  if (personalIdentityNumber.length > 0) {
    return `${personalIdentityNumber.substring(0, 8)}-${personalIdentityNumber.substring(8)}`;
  }
  return undefined;
}

/*
  Expects a personal identity number of length 12.
*/
export function addDashToOrganizationNumber(organizationNumber: string) {
  if (organizationNumber.length > 0) {
    return `${organizationNumber.substring(0, 6)}-${organizationNumber.substring(6)}`;
  }
  return undefined;
}
