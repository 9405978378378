<template>
  <div class="label-new-container">
    <div class="label-new-container__arrow-left" />
    <div class="label-new-container__body">
      <span class="label-new-container__text">
        {{ label }}
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
      required: true
    }
  }
});
</script>
<style lang="scss" scoped>
.label-new-container {
  display: flex;
  align-items: center;
  &__body {
    width: 2.25rem;
    height: 1.375rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dusty-green;
    font-family: $heading-font-family;
    text-transform: none;
    font-size: 1.125rem;
    box-shadow: 0 0.125rem 0.375rem rgba(154, 207, 195, 0.5);
    border-radius: 0.25rem;
    font-weight: 500;
    color: white;
    cursor: default;
  }
  &__text {
    height: 100%;
    font-size: 0.875rem;
    font-weight: bold;
  }
  &__arrow-left {
    border-top: 0.25rem solid transparent;
    border-bottom: 0.25rem solid transparent;
    border-right: 0.3125rem solid $dusty-green;
    border-right-color: $dusty-green;
  }
}
</style>
