import vue from "vue";
import type { MutationTree } from "vuex";
import type { AdminState } from "./types";
import type {
  AdminLegalEntity,
  AdminMifidAlert,
  AdminPortfolio,
  ApplicationUser,
  ExtRef,
  LegalEntity,
  PortfolioAgreement,
} from "@/clients";
import {
  MifidAlert,
  Portfolio,
} from "@/clients";

export const AdminMutation = {
  setUsers: "setUsers",
  setLegalEntities: "setLegalEntities",
  setCompanies: "setCompanies",
  setActivePortfolios: "setActivePortfolios",
  updateActivePortfolio: "updateActivePortfolio",
  setActiveUser: "setActiveUser",
  setActiveUserId: "setActiveUserId",
  setActiveLegalEntity: "setActiveLegalEntity",
  setActivePortfolioSettings: "setActivePortfolioSettings",
  setActiveStrategy: "setActiveStrategy",
  loadingUsers: "loadingUsers",
  setActiveKyc: "setActiveKyc",
  setActivePortfolioSummaries: "setActivePortfolioSummaries",
  setTradingDays: "setTradingDays",
  addTradingDay: "addTradingDay",
  removeTradingDay: "removeTradingDay",
  setPortfolioTransactions: "setPortfolioTransactions",
  setActivePortfolioAgreements: "setActivePortfolioAgreements",
  setActiveMessages: "setActiveMessages",
  addMessage: "addMessage",
  setWithdrawals: "setWithdrawals",
  loadingWithdrawals: "loadingWithdrawals",
  setAffiliateCampaigns: "setAffiliateCampaigns",
  appendAffiliateCampaign: "appendAffiliateCampaign",
  updateAffiliateCampaign: "updateAffiliateCampaign",
  removeAffiliateCampaign: "removeAffiliateCampaign",
  setMifidAlerts: "setMifidAlerts",
  updateMifidAlerts: "updateMifidAlerts",
  setHoldingsValueOnAlerts: "setHoldingsValueOnAlerts",
  setPerformance: "setPerformance",
  setLoadingPerformance: "setLoadingPerformance",
  setPerformanceBricknode: "setPerformanceBricknode",
  setLoadingPerformanceBricknode: "setLoadingPerformanceBricknode",
};

export const mutations: MutationTree<AdminState> = {
  [AdminMutation.setUsers](state, users: Array<ApplicationUser>) {
    state.users = users;
  },
  [AdminMutation.setLegalEntities](state, legalEntities: Array<AdminLegalEntity>) {
    state.legalEntities = legalEntities;
  },
  [AdminMutation.setCompanies](state, companies: Array<LegalEntity>) {
    state.companies = companies;
  },
  [AdminMutation.setActiveUser](state, user: ApplicationUser) {
    state.activeUser = user;
  },
  [AdminMutation.setActiveUserId](state, id: string) {
    state.activeUserId = id;
  },
  [AdminMutation.setActiveLegalEntity](state, legalEntity: LegalEntity) {
    state.activeLegalEntity = legalEntity;
  },
  [AdminMutation.setActivePortfolios](state, portfolios: Array<AdminPortfolio>) {
    state.activePortfolios = portfolios;
  },
  [AdminMutation.updateActivePortfolio](state, updatedPortfolio: AdminPortfolio) {
    const currentPortfolios = state.activePortfolios;
    const withoutUpdated = currentPortfolios.filter(x => x.id !== updatedPortfolio.id);
    state.activePortfolios = [...withoutUpdated, updatedPortfolio];
  },
  [AdminMutation.setActivePortfolioSettings](state, { settings, portfolioId }) {
    if (!state.activePortfolioSettings)
      state.activePortfolioSettings = [];
    state.activePortfolioSettings[portfolioId] = settings;
  },
  [AdminMutation.setPerformance](state, { performance, portfolioId }) {
    if (!state.portfolioPerformances)
      state.portfolioPerformances = [];
    state.portfolioPerformances[portfolioId] = performance;
  },
  [AdminMutation.setPerformanceBricknode](state, { performance, portfolioId }) {
    if (!state.portfolioPerformancesBricknode)
      state.portfolioPerformances = [];
    state.portfolioPerformancesBricknode[portfolioId] = performance;
  },
  [AdminMutation.setLoadingPerformance](state, { loading, portfolioId }) {
    if (!state.loadingPortfolioPerformances)
      state.loadingPortfolioPerformances = [];
    state.loadingPortfolioPerformances[portfolioId] = loading;
  },
  [AdminMutation.setLoadingPerformanceBricknode](state, { loading, portfolioId }) {
    if (!state.loadingPortfolioPerformancesBricknode)
      state.loadingPortfolioPerformances = [];
    state.loadingPortfolioPerformancesBricknode[portfolioId] = loading;
  },
  [AdminMutation.setActiveStrategy](state, { strategy, portfolioId }) {
    state.activeStrategies[portfolioId] = strategy;
  },
  [AdminMutation.setActivePortfolioAgreements](state, agreements: Array<PortfolioAgreement>) {
    state.activePortfolioAgreements = agreements;
  },
  [AdminMutation.loadingUsers](state, value) {
    state.loadingUsers = value;
  },
  [AdminMutation.loadingWithdrawals](state, value) {
    state.loadingWithdrawals = value;
  },
  [AdminMutation.setActivePortfolioSummaries](state, { portfolioId, summary }) {
    if (!state.activePortfolioSummaries)
      state.activePortfolioSummaries = [];
    state.activePortfolioSummaries[portfolioId] = summary;
  },
  [AdminMutation.setTradingDays](state, value) {
    state.tradingDays = value;
  },
  [AdminMutation.addTradingDay](state, value) {
    state.tradingDays = [...state.tradingDays, value];
  },
  [AdminMutation.removeTradingDay](state, id) {
    state.tradingDays = [...state.tradingDays.filter(t => t.id !== id)];
  },
  [AdminMutation.setPortfolioTransactions](state, { transactions, portfolioId }) {
    state.activePortfolioTransactions[portfolioId] = transactions;
  },
  [AdminMutation.setActiveMessages](state, messages) {
    state.activeMessages = messages;
  },
  [AdminMutation.addMessage](state, message) {
    state.activeMessages = [...state.activeMessages, message];
  },
  [AdminMutation.setWithdrawals](state, withdrawals) {
    state.withdrawals = withdrawals;
  },
  [AdminMutation.setAffiliateCampaigns](state, value) {
    state.affiliateCampaigns = value;
  },
  [AdminMutation.appendAffiliateCampaign](state, value) {
    state.affiliateCampaigns = [...state.affiliateCampaigns, value];
  },
  [AdminMutation.updateAffiliateCampaign](state, value) {
    const withoutUpdatedCampaign = state.affiliateCampaigns.filter(ac => ac.id !== value.id);
    state.affiliateCampaigns = [...withoutUpdatedCampaign, value];
  },
  [AdminMutation.removeAffiliateCampaign](state, id) {
    state.affiliateCampaigns = [...state.affiliateCampaigns.filter(ac => ac.id !== id)];
  },
  [AdminMutation.setMifidAlerts](state, value: AdminMifidAlert[]) {
    state.mifidAlerts = value;
  },
  [AdminMutation.updateMifidAlerts](state, value: AdminMifidAlert[]) {
    const withoutUpdatedAlerts = state.mifidAlerts.filter(
      alert => !value.some(x => x.id === alert.id),
    );
    state.mifidAlerts = [...withoutUpdatedAlerts, ...value];
  },
  [AdminMutation.setHoldingsValueOnAlerts](state, values: { [extRef: string]: number }) {
    const alerts = state.mifidAlerts;
    Object.keys(values).forEach((externalReference) => {
      const value = values[externalReference];
      const alert = alerts.find(x => x.portfolioExternalReference && x.portfolioExternalReference.value === externalReference);
      if (alert) {
        alert.accountHoldingsValue = value;
      }
    });

    state.mifidAlerts = [...alerts];
  },
};
