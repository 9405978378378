import md5 from "@/utils/hasher";
import log from "@/clients/log";
import { LogLevel } from "@/clients";

const logger = "adtraction-snippet.ts";

export default (orderId: string, email: string) => {
  const ADT = (window as any).ADT || {};
  ADT.Tag = ADT.Tag || {};
  ADT.Tag.t = 3;
  ADT.Tag.c = "SEK";
  ADT.Tag.tp = 1603955778;
  ADT.Tag.am = 0;
  ADT.Tag.ti = orderId;
  ADT.Tag.xd = md5(email);
  ADT.Tag.cpn = "";

  log(logger, `md5_email=${ADT.Tag.xd}`, LogLevel.Information);
  ADT.Tag.doEvent();
};
