<template>
  <div class="withdrawal-interval">
    <single-choice-template :alternatives="alternatives" @click="select">
      <question-text-section
        :heading="$t('common-signup.questions.aml.withdrawal-interval.heading')"
        progress
      />
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupMutation } from "@/store/signup/mutations";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { WithdrawalFrequency } from "@/clients";

import Question from "@/views/signup/templates/question";
import SingleChoiceTemplate from "../../../signup/templates/single-choice.vue";
import InformationTextSection from "../../../signup/information-text-section.vue";
import QuestionTextSection from "../../../signup/question-text-section.vue";

interface FrequencyChoice {
  text: string;
  key: WithdrawalFrequency;
}

export default defineComponent({
	emits: ["next"],
  components: {
    InformationTextSection,
    SingleChoiceTemplate,
    QuestionTextSection
  },
  extends: Question,
  computed: {
    alternatives(): FrequencyChoice[] {
      return [
        {
          text: this.$t("everyWeek"),
          key: WithdrawalFrequency.OncePerWeek
        },
        {
          text: this.$t("everyMonth"),
          key: WithdrawalFrequency.OncePerMonth
        },
        {
          text: this.$t("everyHalfYear"),
          key: WithdrawalFrequency.OncePerHalfYear
        },
        {
          text: this.$t("moreSeldom"),
          key: WithdrawalFrequency.OncePerYear
        }
      ];
    }
  },
  methods: {
    select(key: WithdrawalFrequency): void {
      this.$store.commit(SignupMutation.setWithdrawalFrequency, key);
      this.$emit("next", key);
    }
  }
});
</script>

<style lang="scss" scoped>
.withdrawal-interval {
  height: 100%;
}
</style>
