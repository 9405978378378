import store from "@/store";
import { UserMutation } from "@/store/user/mutations";
import { RouteLocationNormalized } from "vue-router";

export const isLoggedIn = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) => {
  if (store.getters.isLoggedIn(store.state)) {
    next();
  } else {
    store.commit(UserMutation.setToken, undefined);
    // Allow user to continue to the requested page so that user can be informed
    // that they have been logged out
    next();
  }
};

export const isLoggedInAdmin = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) => {
  if (store.getters.isLoggedIn(store.state)) {
    next();
  } else {
    store.commit(UserMutation.setToken, undefined);
    if (import.meta.env.VITE_ENV !== "production") {
      next({ path: "admin/login" });
    } else {
      next({ path: "" });
    }
  }
};
