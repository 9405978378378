import { GetterTree } from "vuex";
import { DateTime } from "luxon";
import {
  Holding,
  HoldingSummary,
  Sector,
  DetailedHolding,
  Portfolio,
  Strategy,
  PortfolioSettings,
  PortfolioType,
  InProgressPensionStatus,
  PortfolioBase
} from "@/clients";
import { MarketDistribution, DiscardedSectors, EsgFilters } from "@/types/portfolio";
import { PortfolioState } from "./types";
import { RootState } from "../../types";

export const getters: GetterTree<PortfolioState, RootState> = {
  /**
   * Returns undefined if no holding summary can be found, otherwise
   * a number [0, 1]
   */
  stockFraction(_, vuexGetters): number | undefined {
    if (vuexGetters.currentHoldingSummary) {
      return vuexGetters.currentHoldingSummary.holdingTypeSharesRatio;
    }
    return undefined;
  },
  etfFraction(_, vuexGetters): number | undefined {
    if (vuexGetters.currentHoldingSummary) {
      return vuexGetters.currentHoldingSummary.holdingTypeEtfRatio;
    }
    return undefined;
  },
  hasMadeDeposits(state): boolean {
    const { deposits } = state;
    if (deposits) {
      return deposits.length > 0;
    }
    return false;
  },
  goalFraction(state, vuexGetters): number | undefined {
    if (state.savingsGoal && vuexGetters.totalValue) {
      return vuexGetters.totalValue / state.savingsGoal.goalAmount;
    }
    return undefined;
  },
  totalValue(_, portfolioGetters): number | undefined {
    const { holdings }: { holdings: Holding[] | undefined } = portfolioGetters;
    if (holdings && holdings.length > 0) {
      const holdingsValue = holdings
        .map(({ marketValue }) => marketValue)
        .reduce((accumulator, currentValue) => accumulator + currentValue);
      return holdingsValue + portfolioGetters.cash;
    }
    if (portfolioGetters.cash !== undefined) {
      return portfolioGetters.cash;
    }
    return undefined;
  },

  currentHoldingSummary(state): HoldingSummary | undefined {
    if (state.activePortfolioBase && state.holdingSummaries) {
      return state.holdingSummaries[state.activePortfolioBase.id];
    }
    return undefined;
  },
  portfolioName(state): string | undefined {
    if (state.activePortfolioBase) {
      return state.activePortfolioBase.name;
    }
    return undefined;
  },
  portfolioCreatedDate(state): DateTime | undefined {
    if (state.activePortfolioBase) {
      return DateTime.fromJSDate(state.activePortfolioBase.createdAt);
    }
    return undefined;
  },
  holdings(_, vuexGetters): Holding[] | undefined {
    if (vuexGetters.currentHoldingSummary) {
      return vuexGetters.currentHoldingSummary.holdings;
    }
    return undefined;
  },
  cash(_, vuexGetters): number | undefined {
    if (vuexGetters.currentHoldingSummary && vuexGetters.currentHoldingSummary.cash) {
      return vuexGetters.currentHoldingSummary.cash.amount;
    }
    return undefined;
  },
  holdingDetails:
    (state) =>
    (assetId: string): DetailedHolding | undefined | null => {
      const { holdingDetails } = state;
      if (holdingDetails && holdingDetails.length > 0) {
        return holdingDetails.find((holding: DetailedHolding) => holding.assetId === assetId);
      }
      return undefined;
    },
  marketShares(_, vuexGetters): Array<MarketDistribution> | undefined {
    const { currentHoldingSummary } = vuexGetters;
    if (currentHoldingSummary) {
      return [
        {
          market: "Sweden",
          share: currentHoldingSummary.marketSwedenRatio
        },
        {
          market: "Us",
          share: currentHoldingSummary.marketAmericaRatio
        },
        {
          market: "Europe",
          share: currentHoldingSummary.marketEuropeRatio
        }
      ];
    }
    return undefined;
  },
  sectorRatio(_, vuexGetters): { [key in keyof typeof Sector]?: number } | undefined {
    const { currentHoldingSummary } = vuexGetters;
    if (currentHoldingSummary && currentHoldingSummary.sectorRatio) {
      const { sectorRatio } = currentHoldingSummary;

      // If mongstad is missing data, all values will be null. Then treat data as undefined
      const found = Object.keys(sectorRatio).every((key) => sectorRatio[key] !== null);
      return found ? sectorRatio : undefined;
    }
    return undefined;
  },
  hasUnsavedEdits(state): boolean {
    return state.unsavedEdits > 0;
  },
  backcastReturn(state): number | undefined {
    if (!state.backcast) {
      return undefined;
    }
    return state.backcast.backcastReturn;
  },
  editedDiscardedSectors(state): DiscardedSectors | undefined {
    const { editedStrategy } = state;
    if (!editedStrategy) {
      return undefined;
    }
    return {
      gamblingDiscarded: editedStrategy.gamblingDiscarded,
      alcoholTobaccoDiscarded: editedStrategy.alcoholTobaccoDiscarded,
      defenseDiscarded: editedStrategy.defenseDiscarded,
      fossilFuelsDiscarded: editedStrategy.fossilFuelsDiscarded
    };
  },
  editedEsgFilters(state): EsgFilters | undefined {
    const { editedStrategy } = state;
    if (!editedStrategy) {
      return undefined;
    }
    return {
      esgGovernanceFilter: editedStrategy.esgGovernanceFilter,
      esgEmissionsFilter: editedStrategy.esgEmissionsFilter,
      esgBiodiversityFilter: editedStrategy.esgBiodiversityFilter,
      esgHumanRightsFilter: editedStrategy.esgHumanRightsFilter,
      esgResourcesFilter: editedStrategy.esgResourcesFilter
    };
  },
  holdingsError(state): boolean {
    return state.holdingsError;
  },
  transactionsError(state): boolean {
    return state.transactionsError;
  },
  editedPortfolio(state): Portfolio | undefined {
    if (state.activePortfolioBase) {
      return new Portfolio({
        ...state.activePortfolioBase,
        strategy: state.editedStrategy ?? new Strategy(),
        portfolioSettings: state.editedPortfolioSettings ?? new PortfolioSettings(),
        restartedPortfolioDate: undefined,
        savingsGoal: state.savingsGoal
      });
    }
    return undefined;
  },
  isPension(state): boolean | undefined {
    if (state.activePortfolioBase) {
      return state.activePortfolioBase.portfolioType === PortfolioType.OccupationalPension;
    }
    return undefined;
  },
  notCompletedPension:
    (state) =>
    (insuranceNumber: string): boolean | undefined => {
      if (state.activePortfolioBase) {
        return (
          state.activePortfolioBase.portfolioType === PortfolioType.OccupationalPension &&
          state.activePortfolioBase.inProgressPensions !== undefined &&
          state.activePortfolioBase.inProgressPensions.filter(
            (x) => x.insuranceNumber === insuranceNumber
          )[0].status !== InProgressPensionStatus.Completed
        );
      }
      return undefined;
    },
  pensionInProgress:
    (_) =>
    (status: InProgressPensionStatus): boolean =>
      status === InProgressPensionStatus.WaitingForInsuredSignature ||
      status === InProgressPensionStatus.WaitingForInsuranceHolderSignature ||
      status === InProgressPensionStatus.WaitingForIdunSignature ||
      status === InProgressPensionStatus.WaitingForInsuranceCompanySignature,
  pensionRequiresSupplementaryInfo: (_) => (status: InProgressPensionStatus) =>
    status === InProgressPensionStatus.InsuranceCompanyRequiresSupplementaryInfo ||
    status === InProgressPensionStatus.IdunRequiresSupplementaryInfo,
  hasBankAccount(state): boolean {
    return (
      state.portfolioSettings !== undefined &&
      state.portfolioSettings !== null &&
      state.portfolioSettings.accountNumber !== null &&
      state.portfolioSettings.accountNumber !== undefined &&
      state.portfolioSettings.clearingNumber !== null &&
      state.portfolioSettings.clearingNumber !== undefined
    );
  }
};
