<template>
  <div class="finshark-deposit">
    <label class="finshark-deposit__subheader">{{
      $t(
        state === State.Complete ? "complete" : "portfolio.overview.deposit.finshark-deposit.title"
      )
    }}</label>
    <template v-if="state === State.Form">
      <account-input
        :title="$t('to')"
        :account-name="portfolioName"
      />
      <v-form
        ref="form"
        @submit.prevent="deposit"
      >
        <v-text-field
          v-model="displayDepositAmount"
          type="tel"
          :label="$t('sum')"
          :suffix="$t('currency')"
          :rules="[rules.number, rules.leadingZeros, ...(isTestUser ? [] : [rules.depositMinimum])]"
          name="deposit"
          validate-on="blur"
          class="finshark-deposit__text-field"
        />
      </v-form>
      <common-button
        class="finshark-deposit__button"
        @click="deposit"
      >
        {{ $t("depositTitle") }}
      </common-button>
    </template>
    <template v-if="state === State.Finshark">
      <finshark
        :amount="depositAmount"
        @reset="() => (state = State.Form)"
        @complete="() => (state = State.Complete)"
        @cancel="() => (state = State.Form)"
      />
    </template>
    <template v-if="state === State.Complete">
      <div class="finshark-deposit__complete">
        <label>{{ $t("portfolio.overview.deposit.finshark-deposit.completeBody") }}</label>
        <label>{{ $t("cashInvestedNextTrade") }}</label>
      </div>
      <common-button
        class="finshark-deposit__button"
        @click="resetAndClose"
      >
        {{ $t("ok") }}
      </common-button>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import CommonButton from "@/components/button.vue";
import Finshark from "@/components/finshark.vue";
import AccountInput from "./account-input.vue";

enum State {
  Form,
  Finshark,
  Complete
}
export default defineComponent({
  emits: ["close"],
  components: {
    CommonButton,
    AccountInput,
    Finshark
  },
  data() {
    return {
      State,
      state: State.Form,
      depositAmount: 0,
      rules: {
        leadingZeros: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^(0|[1-9][0-9]*)$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        number: (value: string) => {
          value = value.replace(/\s/g, "");
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("notValidNumber");
        },
        depositMinimum: (value: string) => {
          value = value.replace(/\s/g, "");
          const numericValue = Number(value);
          return numericValue >= 500 || this.$t("numberNotLargeEnough");
        }
      }
    };
  },
  computed: {
    ...mapGetters(["portfolioName", "isTestUser"]),
    displayDepositAmount: {
      get(): string {
        if (this.depositAmount) {
          return this.depositAmount.toLocaleString("sv-SE");
        }
        return "";
      },
      set(value: string) {
        value = this.removeWhiteSpace(value);
        this.depositAmount = parseInt(value, 10);
      }
    },
    isLockedBecauseOfEconomy(): boolean {
      if (this.$store.state.userStore.currentLegalEntity) {
        return this.$store.state.userStore.currentLegalEntity.isLockedBecauseOfEconomy;
      }
      return false;
    }
  },
  methods: {
    removeWhiteSpace(value: string): string {
      return value.replace(/\s/g, "");
    },
    async deposit() {
      if ((await (this.$refs.form as any).validate()).valid) {
        this.state = State.Finshark;
      }
    },
    resetAndClose() {
      this.$emit("close");
    }
  }
});
</script>

<style lang="scss" scoped>
.finshark-deposit {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: 100%;
  &__subheader {
    font-size: 1.125rem;
    text-align: center;
    font-family: $heading-font-family;
  }
  &__text-field {
    font-size: 1.25rem;
    font-weight: bold;
  }
  &__from-number {
    font-size: 1rem;
    font-family: $heading-font-family;
    opacity: 0.63;
  }
  &__complete {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    text-align: center;
    opacity: 0.7;
    font-size: 1rem;
    font-family: $heading-font-family;
  }
  &__button {
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto !important;
    width: 100%;
  }
}
</style>
