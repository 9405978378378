<template>
  <div class="withdrawal-interval-information">
    <single-choice-template
      :alternatives="informationAlternatives"
      :two-columns="true"
      @click="selectInformation"
    >
      <information-text-section
        :heading="
          $t('create-portfolio.questions.aml.withdrawal-interval-information.informationHeading')
        "
      >
        <icon />
      </information-text-section>
      <p class="withdrawal-interval-information__body">
        {{ $t("create-portfolio.questions.aml.withdrawal-interval-information.informationBody") }}
      </p>
    </single-choice-template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import { SingleChoice } from "@/types/signup";
import SingleChoiceTemplate from "@/views/signup/templates/single-choice.vue";
import InformationTextSection from "@/views/signup/information-text-section.vue";
import Icon from "@/assets/images/signup/piggy-bank-solid.svg";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoiceTemplate,
    Icon
  },
  computed: {
    informationAlternatives(): SingleChoice[] {
      return [
        {
          text: this.$t("quit"),
          key: "quit",
          secondary: true
        },
        {
          text: this.$t("looks_good"),
          key: "continue"
        }
      ];
    }
  },
  methods: {
    selectInformation(key: string): void {
      if (key === "continue") {
        this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
      } else {
        this.$router.push({ name: "login" });
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.withdrawal-interval-information {
  height: 100%;
  &__body {
    font-size: 1rem;
    margin: 1rem 0 0 0;
    text-align: center;
  }
}
</style>
