<script setup lang="ts">
defineProps<{ heading: string; subHeading: string }>();

const modelValue = defineModel<boolean>({ required: true });
</script>

<template>
  <VCheckbox
    v-model="modelValue"
    indeterminate-icon="fal fa-minus-square"
    :class="{ disabled: $attrs.disabled }"
    hide-details
    :aria-label="`Välj ${heading} ${subHeading}`"
    style="margin-bottom: 0.5rem"
  >
    <template #label>
      <div>
        <div style="font-size: 1.125rem; font-weight: 400">
          {{ heading }}
        </div>
        <div style="opacity: 0.87; font-weight: 300">
          {{ subHeading }}
        </div>
      </div>
    </template>
  </VCheckbox>
</template>
