import { GetterTree } from "vuex";
import { Role } from "@/clients";
import { CompanySignupState } from "./types";
import { RootState } from "../../types";

export const getters: GetterTree<CompanySignupState, RootState> = {
  companyChosenRiskLevel(state): number | undefined {
    return state.customRiskLevel !== undefined ? state.customRiskLevel : state.calculatedRiskLevel;
  },
  companyStocksFraction(_, signupGetters): number {
    return signupGetters.companyChosenRiskLevel * 0.2;
  },
  insertedPrincipalRoleText(state): string {
    const { role } = state.companyPrincipals[0];
    if (role) {
      return role === Role.Chairman
        ? "company-signup.questions.evida.principals.other-principal-info.chairman"
        : "company-signup.questions.evida.principals.other-principal-info.commissoner";
    }
    return "company-signup.questions.evida.principals.other-principal-info.chairman";
  },
  otherPrincipalTitle: (state): string => {
    const { role } = state.companyPrincipals[0];
    if (role) {
      return role === Role.Chairman
        ? "company-signup.questions.evida.principals.other-principal-info.chairman"
        : "company-signup.questions.evida.principals.other-principal-info.commissioner";
    }
    // Chairman is more likely so if we for some reason don't have a role, let's guess
    return "company-signup.questions.evida.principals.other-principal-info.chairman";
  }
};
