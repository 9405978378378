<template>
  <div
    id="login-no"
    class="no-login"
  >
    <div
      v-if="state === State.ShowButton || state === State.ShowAssently"
      style="padding-top: 3.25rem; display: flex; flex-direction: column"
    >
      <span class="login-no__text"> {{ $t("login") }}</span>
    </div>
    <div class="login-no__form-wrapper">
      <common-button
        v-if="state === State.ShowButton"
        data-cy="authButton"
        class="bankid-login__button"
        @click="login"
      >
        {{ "Logga in med norskt BankID" }}
      </common-button>
      <div
        v-else-if="state === State.ShowAssently"
        class="no-login__bankid-wrapper"
      >
        <bank-id-no
          is-login
          @canceled="authCancel"
        />
      </div>
      <loading-spinner v-else-if="state === State.Loading" />
    </div>

    <div
      v-if="state === State.ShowButton"
      class="login-no__link-container"
    >
      <span class="login-no__create-account-text">{{ $t("createAccount") }}</span>
      <a
        class="login-no__link"
        @click="goToSignup"
        >{{ $t("signupLink") }}</a
      >
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { softBlue } from "@/styles/colors";
import { BankIdAction } from "@/store/bankid/actions";
import { StartMode } from "@/store/bankid/types";
import BankIdNo from "@/components/bank-id-no.vue";
import CommonButton from "@/components/button.vue";
import { UserMutation } from "@/store/user/mutations";
import LoadingSpinner from "@/components/loading-spinner/loading-spinner.vue";
import { UserAction } from "@/store/user/action-definitions";

enum State {
  ShowButton,
  ShowAssently,
  Loading
}
export default defineComponent({
  emits: ["authComplete", "authFailed"],
  components: { CommonButton, BankIdNo, LoadingSpinner },
  head() {
    return {
      title: this.$t("login")
    };
  },
  data() {
    return {
      softBlue,
      state: State.ShowButton,
      authFailure: null as string | null,
      StartMode,
      State
    };
  },
  computed: {
    isBankIdDev(): boolean {
      return import.meta.env.VITE_ENV !== "production";
    }
  },
  async mounted() {
    if (this.$route.query.identityToken) {
      this.state = State.Loading;
      await this.$store.dispatch(BankIdAction.norwayLoginComplete, {
        identityToken: this.$route.query.identityToken,
        isSignup: false
      });
      this.$emit("authComplete");
    } else if (this.$route.query.errorMessage) {
      this.$store.dispatch(UserAction.addSnackbarMessage, this.$t("authFailed"));
      this.$router.replace({ path: this.$route.path });
    }
  },
  methods: {
    async login() {
      this.state = State.ShowAssently;
    },
    async cancel(e: any) {
      e.preventDefault();
      this.$store.dispatch(BankIdAction.cancelAuth);
    },
    authCancel() {
      this.state = State.ShowButton;
    },
    authFailed() {
      this.state = State.ShowButton;
      this.$emit("authFailed");
    },
    resetComponent() {
      this.$store.commit(UserMutation.setLoginErrorStatus, undefined);
      this.authCancel();
    },
    goToSignup(): void {
      this.$router.push({ name: "signup" });
    }
  }
});
</script>
<style lang="scss" scoped>
.login-no {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  &__button {
    margin-bottom: 0.75rem;
  }
  &__bankid-wrapper {
    width: 100%;
  }
  &__loading {
    display: flex;
    justify-content: center;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__logo-container {
    width: 100%;
    text-align: center;
    height: 4.0625rem;
  }
  &__logo {
    path {
      fill: $soft-blue;
      opacity: 1;
    }
  }
  &__text {
    font-size: 1.5rem;
    font-weight: 600;
    color: rgba($nearly-black, 0.8);
    padding-bottom: 1.5rem;
  }

  &__checkbox {
    &--text {
      color: $nearly-black;
    }
  }
  &__actions-container {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  &__signup-button {
    text-transform: none !important;
    color: $nearly-black;
    font-weight: 300;
    font-size: 1rem;
    font-family: $body-font-family;
    padding-top: 0.5rem;
  }
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    height: 100%;
  }
  &__link-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    @include small-up {
      padding-bottom: 8rem;
    }
  }
  &__create-account-text {
    margin: auto;
    max-width: 18rem;
  }
  &__link {
    text-decoration: underline;
  }
}
</style>
