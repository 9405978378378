<template>
  <side-dialog
    v-if="modelValue"
    :model-value="modelValue"
    :title="$t('marketFocus')"
    @update:model-value="() => $emit('update:model-value', false)"
  >
    <div class="dialog">
      <div
        v-for="(section, i) in infoSections()"
        :key="i"
      >
        <p>
          {{ section.text }}
        </p>
      </div>
    </div>
  </side-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { InfoSection } from "@/types";
import SideDialog from "@/components/dialog/side-dialog.vue";

export default defineComponent({
  emits: ["update:model-value"],
  components: {
    SideDialog
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    infoSections(): InfoSection[] {
      return [
        {
          heading: this.$t("marketFocus"),
          text: this.$t("common-signup.questions.preferences.market-focus.sideDialogBody1")
        },
        {
          text: this.$t("common-signup.questions.preferences.market-focus.sideDialogBody2")
        },
        {
          text: this.$t("common-signup.questions.preferences.market-focus.sideDialogBody3")
        }
      ];
    }
  }
});
</script>

<style lang="scss" scoped>
.dialog {
  padding: 1rem;
}
</style>
