import sv from "./sv.json";
// import no from "./no.json";

// Hacky solution that flattens the translation file. This is needed because
// vue-i18n cannot handle nested translations when the key contains a dot.
// Since all our keys are namespaced (e.g. components.login), we cannot have a
// nested translation under this e.g. "components.login": { "login": "Logga in" }
// This works if we flatten it out to "components.login.login": "Logga in"
function flatObj(obj: any, newObj: any = {}, parentKey = ""): any {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in obj) {
    const currKey = parentKey.length > 0 ? `${parentKey}.${key}` : key;

    if (typeof obj[key] === "object") {
      flatObj(obj[key], newObj, currKey);
    } else {
      newObj[currKey] = obj[key];
    }
  }

  return newObj;
}

export const languages = {
  sv: flatObj(sv)
  // no: flatObj(no),
};
