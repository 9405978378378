<template>
  <div class="focus">
    <savings-header
      class="focus__header"
      :edit="edit"
      :title="edit ? $t('portfolio-settings.strategy.focus.selectFocus') : $t('theme')"
      :icon="['fal', 'palette']"
    >
      <template #action>
        <edit-button
          :disabled="disabled"
          :edit="edit"
          @click="toggleEdit"
        />
      </template>
      <template #info>
        <information-button @click="() => (showDialog = true)" />
      </template>
    </savings-header>
    <v-divider />
    <v-list
      variant="flat"
      :disabled="!edit"
      v-model:selected="item"
    >
      <v-list-item
        v-for="(focus, i) in focusItems"
        :key="i"
        class="focus__list-item"
        :active="!edit ? false : undefined"
        @click="
          () =>
            focus.focusKey !== undefined
              ? (selectedFocus = focus.focusKey)
              : (selectedTiedAgentTheme = focus.tiedAgentTheme)
        "
      >
        <template v-slot:prepend>
          <div
            :class="[
              'focus__check-icon',
              {
                'focus__check-icon--hidden': !focus.selected && !edit,
                'focus__check-icon--faded': !focus.selected && edit
              }
            ]"
          >
            <icon
              fixed-width
              :icon="['fal', 'check']"
            />
          </div>
        </template>
        <v-list-item-title class="focus__item-title">
          {{ focus.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <div
      v-if="tiedAgentManagementFee"
      class="focus__management-fee"
    >
      {{ $t("managementFee") }}
      <span class="focus__management-fee--fee"> {{ tiedAgentManagementFee }} </span>
      {{ `% ${$t("perYear")}` }}
    </div>
    <side-dialog
      v-model="showDialog"
      @input="(val) => (showDialog = val)"
      @update:model-value="(val) => (showDialog = val)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PortfolioMutation } from "@/store/portfolio/mutations";
import {
  StrategyFocus,
  TiedAgent,
  TiedAgentPortfolioTheme,
  TiedAgentPortfolioThemeType
} from "@/clients";
import EditButton from "@/components/edit-button.vue";
import { UserState } from "@/store/user/types";
import { PortfolioState } from "@/store/portfolio/types";
import InformationButton from "@/components/information-button.vue";
import SavingsHeader from "../header.vue";
import SideDialog from "./focus-side-dialog.vue";

interface FocusItem {
  focusKey?: StrategyFocus;
  tiedAgentTheme?: TiedAgentPortfolioThemeType;
  title: string;
  selected: boolean;
}

export default defineComponent({
  emits: ["save"],
  components: { EditButton, SavingsHeader, InformationButton, SideDialog },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    item: undefined,
    edit: false,
    showDialog: false
  }),
  computed: {
    tiedAgent(): TiedAgent | undefined {
      return this.$store.state.userStore.currentLegalEntity?.tiedAgent;
    },
    focusItems(): FocusItem[] {
      if (this.tiedAgent && this.tiedAgent.portfolioThemes) {
        return this.tiedAgent.portfolioThemes.map((x) => ({
          tiedAgentTheme: x.tiedAgentPortfolioThemeType,
          title: this.$t(
            `tiedAgentPortfolioThemes.${
              TiedAgentPortfolioThemeType[x.tiedAgentPortfolioThemeType]
            }.description`
          ),
          selected: this.selectedTiedAgentTheme === x.tiedAgentPortfolioThemeType
        }));
      }
      return [
        {
          focusKey: StrategyFocus.Standard,
          title: this.$t("focusStandardTitle"),
          selected: this.selectedFocus === StrategyFocus.Standard
        },
        {
          focusKey: StrategyFocus.Growth,
          title: this.$t("focusGrowthTitle"),
          selected: this.selectedFocus === StrategyFocus.Growth
        },
        {
          focusKey: StrategyFocus.Dividend,
          title: this.$t("focusDividendTitle"),
          selected: this.selectedFocus === StrategyFocus.Dividend
        }
      ];
    },
    selectedFocus: {
      get(): StrategyFocus | undefined {
        const { editedStrategy } = this.$store.state.portfolioStore;
        if (!editedStrategy) {
          return undefined;
        }
        return editedStrategy.focus;
      },
      set(focus: StrategyFocus) {
        this.$store.commit(PortfolioMutation.editFocus, focus);
      }
    },
    selectedTiedAgentTheme: {
      get(): TiedAgentPortfolioThemeType | undefined {
        const { editedStrategy } = this.$store.state.portfolioStore;
        if (!editedStrategy) {
          return undefined;
        }
        return editedStrategy.tiedAgentPortfolioThemeType;
      },
      set(theme: TiedAgentPortfolioThemeType) {
        this.$store.commit(PortfolioMutation.editTiedAgentPortfolioThemeType, theme);
      }
    },
    tiedAgentManagementFee(): string | undefined {
      if (this.tiedAgent !== undefined) {
        const themeInfo = this.tiedAgent.portfolioThemes!.find(
          (t: TiedAgentPortfolioTheme) =>
            t.tiedAgentPortfolioThemeType === this.selectedTiedAgentTheme
        );
        const feeFraction = this.$store.state.userStore.currentLegalEntity.isNaturalPerson
          ? themeInfo!.managementFeeFraction
          : themeInfo!.managementFeeFraction * 0.8;
        return (feeFraction * 100).toLocaleString("sv-SE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        });
      }
      return undefined;
    }
  },
  methods: {
    async toggleEdit(): Promise<void> {
      if (this.edit) {
        if (
          this.selectedFocus !==
            (this.$store.state.portfolioStore as PortfolioState).strategy?.focus ||
          this.selectedTiedAgentTheme !==
            (this.$store.state.portfolioStore as PortfolioState).strategy
              ?.tiedAgentPortfolioThemeType
        ) {
          this.$emit("save");
        }
      } else {
        this.$store.commit(PortfolioMutation.increaseUnsavedEdits);
      }
      this.edit = !this.edit;
    }
  }
});
</script>

<style lang="scss" scoped>
.focus {
  &__check-icon {
    width: 1.875rem;
    font-size: 1.5rem;
    color: rgba($nearly-black, 0.8);
    margin: 0 1rem 0 0 !important;
    align-self: center;
    &--faded {
      color: rgba($nearly-black, 0.15);
    }
    &--hidden {
      opacity: 0;
    }
  }
  &__header {
    padding: 1.125rem 1.25rem;
    height: 3.75rem;
  }
  &__item-title {
    font-size: 1rem;
    font-family: $heading-font-family;
    line-height: 1.3em;
    font-weight: 300;
  }
  &__list-item {
    padding: 0 1.25rem;
    border-radius: 0.5rem;
    &:hover {
      background-color: #f7f7f7;
    }
  }
  &__management-fee {
    font-weight: 300;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 1rem;
    &--fee {
      font-weight: 550;
    }
  }
}
:deep(.v-list-item--disabled) {
  color: $nearly-black;
  opacity: 1;
}
</style>
